<a routerLink="/" class="logoHold" routerLinkActive="active">
  <div class="logo"></div>
</a>
<div class="headContainer">
  <div class="title d-none d-md-block">
    <ng-container *ngIf="!patientName && user">
      <h1>Welcome back, {{ user.firstName || '' }}!</h1>
    </ng-container>

    <div class="announcement-bar">
      <p>
        We’ve upgraded your learning experience! Please use
        <a href="https://learn.cyberpatient.ca" target="_self">learn.cyberpatient.ca</a>
        with your current login. Access to this platform will be limited to only Educational Resources.
      </p>
    </div>
    <ng-container *ngIf="patientName && title != 'Dashboard'">
      <h1>Case: {{ patientName }}</h1>
    </ng-container>
  </div>
  <div class="d-flex mx-5 position-fixed align-items-center" style="right: 0">
    <div *ngIf="user && user.suUser" class="small alert alert-danger">Logged in from {{ user.suUser }}</div>
    <!-- <button
      [hidden]="hideButton"
      mat-raised-button
      class="upgrade"
      *ngIf="sg['membership_status'] === 'free' || this.sg['membership_status'] === 'Free'"
      (click)="openBilling()"
    >
      Upgrade
    </button> -->
    <a href="https://helphub.cyberpatient.ca/" target="_blank">
      <button mat-raised-button color="success">HelpHub</button></a
    >
    <span class="mx-0 mx-sm-3" id="google_translate_element"></span>
    <app-msg-center class="mx-3" *ngIf="loggedIn"></app-msg-center>
  </div>
</div>
