import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../_services';

@Component({
  //  moduleId: module.id,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public loading = true;
  private userSubscription: Subscription;

  constructor(private readonly userService: UserService, private readonly router: Router) {}

  ngOnInit() {
    this.userSubscription = this.userService.userChange.subscribe((user) => {
      if (!user) {
        return;
      }
      this.loading = false;
      if (user && user.admin) {
        this.router.navigateByUrl('/sudo', { replaceUrl: true });
      } else if (user && ['owner', 'admin'].includes(user.institutionRole)) {
        this.router.navigateByUrl('/lms');
      } else if (user && user.institutionRole === 'professor') {
        this.router.navigateByUrl('/teacher-dashboard', { replaceUrl: true });
      } else {
        this.router.navigateByUrl('/dashboard', { replaceUrl: true });
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
