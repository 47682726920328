import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { SaveAssignmentDataService, SessionManagerService, UserService } from '../../_services/index';
import { SetCaseId } from '../../actions/checkpoint.action';
import { ErrorMsgService } from '../../error-msg.service';
import { SuccessConfirmComponent } from '../success-confirm/success-confirm.component';


@Component({
  selector: 'app-abort-confirm',
  providers: [SessionManagerService, SaveAssignmentDataService],
  templateUrl: './abort-confirm.component.html',
  styleUrls: ['./abort-confirm.component.scss']
})
export class AbortConfirmComponent implements OnInit {
  h1text = 'You have a case in progress'
  h2text = 'Please abort the active case to update skillset';
  btn1text = 'Abort Active Case & Update Skillset ';
  btn2text = 'Cancel';
  sessionId;
  new_skillset;

  constructor(
    private store: Store,
    public errorService: ErrorMsgService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public sg: SimpleGlobal,
    private saveAssignmentDataService: SaveAssignmentDataService,
    private userService: UserService,
    private sessionManagerService: SessionManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.sessionId = this.data.session_id;
    this.new_skillset = this.data.new_skillset;
  }

  abortActiveSession() {


    this.sessionManagerService.abortSession(this.sessionId).subscribe((result => {
        if (result.message.status === 'aborted' || result.message === 'session is already aborted') {

          this.sg['session_id'] = null;
          this.sg['case_id'] = null;
          this.store.dispatch([new SetCaseId(null)]);
          const body = {'newSkillset': this.new_skillset};
          this.userService.updateSkillset(body).subscribe(result => {
            if (result === null) {
            } else {
              this.sg['skillset'] = this.new_skillset;
              this.dialog.open(SuccessConfirmComponent, {data: {new_skillset: this.new_skillset}});
            }
          });
        } else {
        }
      }
    ));
  }
}
