import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/index';

export interface FormModel {
  email?: string;
  captcha?: string;
}

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  public formModel: FormModel = {};

  loadingAndSaving = false;
  error = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  resolved(captchaResponse: string) {
  }

  submitRequest() {
    this.error = false;
    if (this.formModel.captcha) {
      delete this.formModel.captcha;

      this.loadingAndSaving = true;
      this.authService.resetPassword(this.formModel).subscribe(result => {

        this.loadingAndSaving = false;
        if (result === null) {
        } else {
          const path = '/reset-email/';
          this.router.navigate([path], {skipLocationChange: true});
        }
      }, err => {
        this.loadingAndSaving = false;
        this.error = true;
      });
    }
  }
}
