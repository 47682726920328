import { CheckPointMessage, CheckPoint } from './../_models/checkpoint.model'

export class GetCurrentCheckpoint {
    static readonly type = '[CURRETCHECKPOINT] Get'
}

export class SetCurrentCheckpoint {
    static readonly type = '[SETCURRENTCHECKPOINT] SetCurrentCheckPoint'
    constructor(public payload: CheckPoint) {}
}

export class SetCurrentCase {
    static readonly type = '[SETCURRENTSESSIONID] SetCurrentCase'
    constructor(public payload: string) {}
}

export class SetCaseId {
    static readonly type = '[CHECKPOINTMESSAGE] SetCaseId'
    constructor(public payload: string) {}
}

export class UpdateCheckpointMessage {
    static readonly type = '[CHECKPOINTMESSAGE] Update'
    constructor(public payload: CheckPointMessage) {}
}

export class AddEpaCheckPoints {
    static readonly type = '[ADDEPACHECKPOINTS] Add'
    constructor(public payload: CheckPoint[]) {}
}
