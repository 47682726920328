import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CheckPoint } from 'app/_models';
import { rpo, vital } from './../_models/rpo.model'
import { AddRpoCheckpoint, AddVital, InitRpo, RemoveRpoCheckpoint, UpdateRpo } from './../actions/rpo.actions'

export class RpoStateModel {
  myrpo: rpo;
  vitals: vital[];
  rpodays: CheckPoint[];
}

@State<RpoStateModel>({
  name: 'rpo',
  defaults: {myrpo: null, rpodays: [], vitals: []}

})
@Injectable()
export class RpoState {

  // Section 4
  @Selector()
  static getRpo(state: RpoStateModel) {
    return state;
  }
  constructor() {
  }

  // Section 5
  @Action(InitRpo)
  add({getState, patchState}: StateContext<RpoStateModel>, {payload}: InitRpo) {
    const state = getState();
    patchState({myrpo: payload, rpodays: null})
  }

  @Action(UpdateRpo)
  update({getState, patchState}: StateContext<RpoStateModel>, {payload}: UpdateRpo) {
    const state = getState();

    patchState({myrpo: payload})
  }

  @Action(AddRpoCheckpoint)
  AddCheckpoint({getState, patchState}: StateContext<RpoStateModel>, {payload}: AddRpoCheckpoint) {
    const state = getState();
    const arr = state.rpodays;
    arr.push(payload);
    patchState({
      ...state,
      rpodays: arr
    })
  }

  @Action(RemoveRpoCheckpoint)
  updaterpoday({getState, patchState}: StateContext<RpoStateModel>, {payload}: RemoveRpoCheckpoint) {
    const state = getState();
    const item = state.rpodays.filter(x => x.component !== payload.component && x.instance !== payload.instance)
    patchState({
      rpodays: item
    })
  }

  @Action(AddVital)
  addVital({getState, patchState}: StateContext<RpoStateModel>, {payload}: AddVital) {
    const state = getState();
    const arr = state.vitals;
    arr.push(payload);
    patchState({
      ...state,
      vitals: arr
    })
  }

}
