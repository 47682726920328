import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FacultyMember } from '../interfaces/faculty-member';
import { Institution } from '../interfaces/institution';
import { Invitation } from '../interfaces/Invitation';
import { CourseSummativeFeedback } from '../interfaces/course';
import { GenericMutationResponse } from '../interfaces/generic-mutation-response';
interface LogoImgResponse {
  logoImg: string;
}

@Injectable()
export class InstitutionService {
  constructor(private readonly http: HttpClient) {}

  getInstitution(): Observable<Institution> {
    return this.http.get<Institution>(environment.apiUrl.concat('/api/v1/institutions'));
  }

  getAllStudents(): Observable<FacultyMember[]> {
    return this.http.get<FacultyMember[]>(environment.apiUrl.concat('/api/v1/institutions/students'));
  }

  getAllFaculties(): Observable<FacultyMember[]> {
    return this.http.get<FacultyMember[]>(environment.apiUrl.concat('/api/v1/institutions/faculties'));
  }

  getAllSummativeFeedback(): Observable<CourseSummativeFeedback[]> {
    const url = environment.apiUrl.concat(`/api/v1/institutions/summative-feedback`);
    return this.http.get<CourseSummativeFeedback[]>(url);
  }

  inviteInstitutionMembers(invitations: Invitation[]) {
    return this.http.post(environment.apiUrl.concat('/api/v1/institutions/invitation'), {
      invitations,
    });
  }

  addInstitution(invitationToken: string): Observable<GenericMutationResponse> {
    return this.http
      .post(environment.apiUrl.concat('/api/v1/institutions/invitation/accept'), {
        invitationToken,
      })
      .pipe(
        tap((result: GenericMutationResponse) => {
          if (!result.success) {
            return throwError('Remove invitations failed');
          }
        })
      );
  }

  retrieveAllInvitations(roles: string[]): Observable<Invitation[]> {
    const encodedRoles = roles.map(encodeURIComponent);
    return this.http.get<Invitation[]>(
      environment.apiUrl.concat('/api/v1/institutions/invitation').concat('?roles=').concat(encodedRoles.join(','))
    );
  }

  deleteInvitations(tokens: string[]): Observable<any> {
    const encodedTokens = tokens.map(encodeURIComponent);
    return this.http
      .delete(
        environment.apiUrl.concat('/api/v1/institutions/invitation').concat('?tokens=').concat(encodedTokens.join(','))
      )
      .pipe(
        tap((result: any) => {
          if (!result.success) {
            return throwError('Remove invitations failed');
          }
        })
      );
  }

  resendInvitations(tokens: string[]): Observable<any> {
    const url = environment.apiUrl.concat('/api/v1/institutions/invitation/resend');

    return this.http
      .post(url, {
        tokens,
      })
      .pipe(
        tap((result: any) => {
          if (!result.success) {
            return throwError('Remove invitations failed');
          }
        })
      );
  }

  addLogo(logoImg: string): Observable<LogoImgResponse> {
    return this.http.post<LogoImgResponse>(environment.apiUrl.concat('/api/v1/institutions/logo'), {
      logoImg,
    });
  }

  retrieveLogo(): Observable<LogoImgResponse> {
    return this.http.get<LogoImgResponse>(environment.apiUrl.concat('/api/v1/institutions/logo'));
  }

  reEngage(ids: string[]): Observable<any> {
    return this.http.post(environment.apiUrl.concat('/api/v1/institutions/students/communication'), {
      template: 'reengage',
      studentIds: ids,
    });
  }
}
