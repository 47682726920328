<div>

    <app-course-header [student]="mystudent" [course]="myCourse"></app-course-header>
    <div class="dash-container">

        <div class="left-column">

            <div class="class-description">
                <h3 class="heading">
                    Summary of Performance
                </h3>

            </div>
            <div>
                <div class="list-heading">
                    <span>Review the student's performance below in order to complete a final assessment on overall
                        course performance.</span>
                </div>
            </div>
            <div class="sum1">
                <div class="sum1header">Summative Assessment </div>
                <div class="desc-sum">Assessment of the student using CP during semester or in a clinical rotation.Check
                    all that apply</div>
                <div class="sum1items">
                    <div class="sum1q" *ngIf="summativefeedback!=undefined">
                        <div *ngFor="let question of summativefeedback.checkBoxes">
                            <div class="cbq">
                                <mat-checkbox [(ngModel)]="question.value">
                                </mat-checkbox>
                                <span class="qt2">{{question.text}}</span>
                            </div>

                        </div>
                    </div>
                    <div class="desc-total">Final Assessment Result
                    </div>
                    <div class="sum1pass" *ngIf="summativefeedback!=undefined">

                        <table class="score-table">
                            <tr>
                                <th class="th1-header">Pass or Fail</th>
                                <th>Score</th>
                            </tr>
                            <tr>
                                <td>
                                    <mat-radio-group required="true" labelPosition="after" style="margin-right: 5px;"
                                        aria-label="Select an option" *ngIf="summativefeedback"
                                        [(ngModel)]="summativefeedback.grading">
                                        <mat-radio-button class="radio-button-true" [value]="true">Pass
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-false" [value]="false">Fail
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>{{getCheckedCount()}}%</td>
                            </tr>

                        </table>
                        <div>

                        </div>
                    </div>

                    <div class="sum1comment" *ngIf="summativefeedback!=undefined">
                        <span class="desc-sum-comments">Comments:</span>
                        <div class="sum1qcomment">
                            <textarea matInput [(ngModel)]="summativefeedback.text"
                                placeholder="Provide feedback to your student based on their case scores in order to help them improve their performancne"></textarea>
                        </div>
                    </div>
                </div>
                <div class="button-class">
                    <button *ngIf="summativefeedback!=undefined" class="submit-button"
                        [disabled]="summativefeedback.grading==null || summativefeedback.text.trim().length==0"
                        mat-raised-button color="primary" (click)="saveSummativeAssessment()">Submit</button>
                </div>
            </div>
            <span>
            </span>
        </div>
        <div class="right-column">
            <div class="list-conatiner">
                <div class="score-snapshot">
                    <app-course-grade [course]='myCourse' [student]='mystudent' [isStudent]='false'></app-course-grade>
                </div>
                <div class="student-list">
                    <app-course-completed-cases [data]="completedCases" [access]="false"></app-course-completed-cases>
                </div>
                <div class="formative">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="feedback-block">History of Feedback:</div>
                    <div class="fb4" *ngIf="formativeFeedbacks.length>0">
                        <div class="d21" *ngFor="let feedback of formativeFeedbacks;let j = index">
                            <span class="h45">{{feedback.created | date}}</span>
                            <span>
                                {{feedback.text}}
                            </span>
                            <mat-divider></mat-divider>
                        </div>

                    </div>
                </div>

            </div>
        </div>



    </div>