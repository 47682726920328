import { error } from 'protractor';

export interface institution {
  name: string;
  _id: string;
  email: string;
  address: string;
  country: string;
}
export interface listStudent {
  name: string;
  email: string;
  status: string;
  course_id: string;
}

export class course {
  _id: string;
  students: student[];
  cases: patientcase[];
  institution_id: string;
  instructor_id: string;
  title: string;
  name: string;
  description: string;
  recommendationLevel: string;
  requiredPassingPercent: number;
  createdBy: string;
  start: Date;
  end: Date;
  created: Date;
  __v: number;
  status: string;
  id: string;
  professorId?: string;
  professor?: string;
  nursing?: boolean;
}

export interface AttendedCours {
  hasSummative: boolean;
  course_id: string;
}

export interface instructor {
  _id: string;
  institution_id: institution;
  user_id: userid;
  createdBy: string;
  email: string;
  created: string;
}

export interface userid {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface student {
  _id: string;
  institution_id: string;
  user_id: userid;
  academicYear: number;
  createdBy: string;
  attendedCourses: AttendedCours[];
  created: Date;
  __v: number;
}

export interface invitedstudent {
  email: string;
  course_id: string;
  _id: string;
}

export interface learningobjectives {
  objectives: topic[];
}

export interface topic {
  topic: string;
  subtopic: string[];
}

export interface Demographics {
  _id: string;
  schema_version: number;
  patient_name: string;
  patient_age: number;
  patient_gender: string;
  patient_weight: number;
  patient_height: string;
  patient_hair: string;
  patient_eyes: string;
  patient_race: string;
  case_id: string;
  patient_number: number;
  nursing_info: any;
  __v: number;
}

export interface Tags {
  category: string[];
  difficulty: string;
  length: string;
  peerReviewed: boolean;
}

export interface patientcase {
  _id: string;
  demographics: Demographics;
  learning_objectives: LearningObjectives;
  tags: Tags;
}
export interface cb {
  id: number;
  text: String;
  score: number;
  value: Boolean;
}

export interface Content {
  topic: string;
  subTopic: string[];
}

export interface LearningObjectives {
  header: string;
  contents: Content[];
}
export interface SummativeFeedback {
  course_id: string;
  student_id: string;
  score: number;
  grading: boolean;
  text: String;
  checkBoxes: cb[];
  created: Date;
  createdBy: string;
}

export interface ExtraAssignment {
  case_id: patientcase;
  student: student;
  instructor: instructor;
  created: Date;
  createdBy: string;
}

export interface FormativeFeedback {
  _id: string;
  course_id: string;
  case_id: CaseId;
  student_id: string;
  text: string;
  createdBy: string;
  created: string;
}

export interface Demographics {
  _id: string;
  patient_name: string;
}

export interface CaseId {
  _id: string;
  demographics: Demographics;
}

export interface CaseRow {
  attempts: number;
  bestScore: number;
  case_id: string;
  last_completed: Date;
  name: string;
  session_id: string;
}
export class coursegrade {
  err: error.IError;
  student?: student;
  courseId?: string;
  grade: number;
  noOfCases: number;
}
export interface MistakeCounter {
  component: string;
  instance: string;
  mistakes: number;
}
export interface UserData {
  choice: string;
  delay: string;
}
export interface PatientRecordData {
  choice: string;
  delay: string;
}
export interface SendHome1 {
  knowledgeScore: number;
  sectionTime: number;
  totalCost: string;
  costDeviation: string;
  idealCost: string;
  userData: UserData[];
  patientRecordData: PatientRecordData;
}

export interface Details {
  sendHome1: SendHome1;
}

export interface Management {
  name: string;
  details: Details;
  knowledgeScore: number;
  totalCost: string;
  costDeviation: string;
  idealCost: string;
  totalTime: number;
}

export interface Feedback {
  finalScores: FinalScores;
  summary: Summary[];
  management: Management[];
}

export interface UserData2 {
  choice: string;
  delay: string;
}

export interface FollowUp {
  timestamp: Date;
  sectionTime: number;
  totalMistakes: number;
  userData: UserData2[];
  knowledgeScore: number;
}

export interface SendHome12 {
  followUp: FollowUp;
}

export interface feedBackMessage {
  mistake_counter: MistakeCounter;
  feedback: Feedback;
  schema_version: number;
  last_checkpoint: string;
  status: string;
  skillset: string;
  sendHome1: SendHome12;
  _id: string;
  case_id: string;
  start_time: Date;
  student_id: string;
  __v: number;
  end_time: Date;
  user_id: string;
  start_time_ms: number;
  id: string;
}
export interface feedback {
  message: feedBackMessage;
}
export class FinalScores {
  knowledgeScore: number;
  time: number;
  cost: number;
  costDeviation: number;
  idealCost: number;
}

export class Summary {
  name: string;
  knowledgeScore: number;
  time: number;
  cost: any;
  costDeviation: any;
  idealCost: any;
}

export class Attempt {
  completed: Date;
  session_id: string;
  finalScores: FinalScores;
  summary: Summary[];
}

export interface CaseData {
  bestScore: number;
  attempts: number;
  last_completed: Date;
  name: string;
  session_id: string;
  case_id: string;
  course_id: string;
  caseType?: string;
}

export interface PerformanceData {
  _id: string;
  highest_score: number;
  name: string;
  case_id: string;
  average: string;
}

export interface GradesIndividual {
  caseData: CaseData[];
  performanceData: PerformanceData[];
  realName: string;
}
