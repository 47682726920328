<button class="exit-btn mat-mini-fab" [mat-dialog-close]>
  x
</button>
<mat-dialog-content class="accent-color row dialogue-wrapper">
  <div class="billing-popup mat-typography">
    <div class="row">
      <div class="description">
        <h1>Select your plan type</h1>
        <h2>Select a paid plan to access the full library of patient cases. Choose a free, month-to-month, or an
          annual subscription!</h2>
        <div class="subscription-types">
          <mat-card *ngFor="let x of subscriptions" class="subscription">
            <div *ngIf="x.discount" class="ribbon"><span>{{x.discountText}}</span></div>
            <div class="head">
              <span class="name">{{x.value}}</span>
              <span class="info">{{x.info}}</span>
            </div>
            <div class="pricing">

              CAD<span class="price" [class.sale]="x.discount">${{x.price}}</span><span *ngIf="x.value != 'Free'"
                class="suffix">
                <div class="value-wrap">
                  <div class="ribbon-small" *ngIf="x.discount">
                    <div id="pointer">Best Value</div>
                  </div>
                  <span class="rate">
                    /month</span>

                </div>
              </span>

            </div>
            <div class="foot-info">{{x.description[0]}}<b>{{x.description[1]}}</b><span class="discount">{{x.description[2]}}</span><span class="noticeme">{{x.description[3]}}</span></div>
            <button class="action mat-button mat-raised-button mat-primary" mat-raised-button
              (click)="goToBilling(x.planID)" [mat-dialog-close]="x.planID">
              Select
            </button>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
