import {Injectable} from '@angular/core';


export interface myAssignmentID {

   assignment_id: string;

}
export interface myPatientID {
  case_id:string;
 
}

export interface mySessionID {
  session_id:string;
 
}

export interface currentCheckpoint {
  next_checkpoint:string;
 
}

@Injectable()
export class StuFeedService {

sharingPID: myPatientID={case_id:"caseidnotsetyet"};
sharingAID: myAssignmentID={assignment_id:"assignmentidnotsetyet"};
sharingSID: mySessionID={session_id:"sessionidnotsetyet"};
sharingCID: currentCheckpoint={next_checkpoint:"checkpointnotsetyet"};

/*
  private _data = [
    {
      id: '593ee9330cc20174d649a9cc',
      assignment: 'Case #101 - Linda Johnson',
      surname: 'Danovsky',
      deadline: '5/2/2017',
      expanded: true,
    }, {
     id: '',
      assignment: 'Case #102 - Barry White',
      deadline: '5/2/2017',
      expanded: true,
    }, {
     id: '',
      assignment: 'Case #103 - Redbull Mcgee',
      deadline: '5/2/2017',
      expanded: true,
    }, {
     id: '',
      assignment: 'Case #104 - Dr. Rubiks',
      deadline: '5/2/2017',
      expanded: true,
    }, {
     id: '',
      assignment: 'Case #105 - Sherlock Holmes',
      deadline: '5/2/2017',
      expanded: true,
    }
  ];

  getData() {
    return this._data;
  }
  */
}
