import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InstitutionRole, User } from '../../_models';
import { Institution } from '../../lms/interfaces/institution';

export interface UserUpdate {
  institutionId: string;
  role: InstitutionRole;
}

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {
  constructor(private readonly http: HttpClient) {
  }

  listInstitutions(): Observable<Institution[]> {
    return this.http
      .get<Institution[]>(environment.apiUrl.concat('/api/v1/institutions/list'));
  }

  listAllUsers(): Observable<User[]> {
    return this.http
      .get<User[]>(environment.apiUrl.concat('/api/v1/users'));
  }

  suLogin(userId) {
    return this.http.post<any>(environment.apiUrl.concat('/api/v1/auth/su-login'), {
      userId,
    }).pipe(
      tap((response) => {
        localStorage.setItem('currentUser',
          JSON.stringify({
            ...response,
            username: response.email,
            role: response.permissions,
          }),
        );
      }),
    );
  }

  updateInstitution(institutionId: string, updateBody: Partial<Institution>): Observable<Institution> {
    const url = environment.apiUrl.concat('/api/v1/institutions/').concat(institutionId);
    return this.http.patch<Institution>(url, updateBody);
  }

  searchUser(email: string): Observable<User> {
    const url = environment.apiUrl.concat('/api/v1/user?email=').concat(encodeURIComponent(email));
    return this.http.get<User>(url);
  }
  
  updateUser(userId: string, update: UserUpdate): Observable<User> {
    const url = environment.apiUrl.concat('/api/v1/user/').concat(userId).concat('/transfer');
    return this.http.patch<User>(url, update);
  }
}
