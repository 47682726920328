import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, Subject } from 'rxjs';
import { FetchAssignmentDataService } from './_services/index';
import { BloodPressureTestService } from './student-physical/services/blood-pressure-test.service';
import { ClockService } from './student-physical/services/clock-service.service';
import { CreatejsShapeService } from './student-physical/services/createjs-shape.service';
import { DetailImageService } from './student-physical/services/detail-image.service';
import { MeasurementsService } from './student-physical/services/measurements.service';
import { PalpationService } from './student-physical/services/palpation.service';
import { PatientService } from './student-physical/services/patient.service';
import { PulseService } from './student-physical/services/pulse-service.service';
import { PulsoximeteryService } from './student-physical/services/pulsoximetery.service';
import { RespirationService } from './student-physical/services/respiration-service.service';
import { ThermometerService } from './student-physical/services/thermometer-service.service';
import { LoadService } from './theme/loading/load-service.service';
import { AiService } from './_services/ai.service';

@Injectable({
  providedIn: 'root',
})
export class DirectorService {
  // Generic Variables
  stage;
  image;
  response;
  pointer;
  zoomToggle;
  nurseMouthSprite;
  inquiryMouthSprite;
  currentStage;
  stageName;
  nurseBG;
  zoomHelp;
  inquiryAnimations = {
    Idle: [0],
  };
  zoom = 'Normal';
  specialSrc;
  stopPhex;
  specialChosen = new Subject<any>();
  specialType = '';
  showSpecial = false;
  curtain;
  // Create all of the containers
  curtainSet = new createjs.Container();
  inquirySet = new createjs.Container();
  historySet = new createjs.Container();
  physicalExamSet = new createjs.Container();
  supervisorSet = new createjs.Container();
  communicationSet = new createjs.Container();
  mnmSet = new createjs.Container();
  mnmPresentationSet = new createjs.Container();
  ancillarySet = new createjs.Container();
  observationSet = new createjs.Container();
  POD1Set = new createjs.Container();
  POD2Set = new createjs.Container();
  POD3Set = new createjs.Container();
  POD4Set = new createjs.Container();
  POD5Set = new createjs.Container();
  POD6Set = new createjs.Container();
  POD7Set = new createjs.Container();
  POD8Set = new createjs.Container();
  POD9Set = new createjs.Container();
  POD10Set = new createjs.Container();
  sendHomeSet = new createjs.Container();
  altPhysicalSet = new createjs.Container();
  provisionalSet = new createjs.Container();
  finalSet = new createjs.Container();
  nurseSet = new createjs.Container();
  patientServiceCont;
  toolSelected;
  // History Taking Variables
  mouthAni = {
    frames: ['7', '3', '1', '0', '7', '3', '1', '0', '7', '3', '1', '0'],
    next: 'Idle',
    speed: '1',
  };
  mouthSprite;
  patientmouth;
  patientSprite;
  patientimg;

  // Physical Exam Variables
  bg;
  mouth;
  physicalimg;
  audio;
  backgroundImg;
  curtainTween = null;
  currentLine;
  hotSet = false;
  patientDownBackgroundImg = './assets/default/physical/Props/physicalExamDownBg.png';
  blurFilter;
  patient;
  detailImages = [
    {
      type: 'ophthalmoscope',
      src: 'ophthalmoscope',
      hitArea: {
        x: 250,
        y: 100,
        shapes: [
          {
            shape: 'Circle',
            x: 0,
            y: 100,
            r: 75,
          },
          {
            shape: 'Circle',
            x: 105,
            y: 100,
            r: 75,
          },
        ],
      },
    },
    {
      type: 'otoscope',
      src: 'otoscope',
      hitArea: {
        x: 140,
        y: 150,
        shapes: [
          {
            shape: 'Rect',
            x: 0,
            y: 5,
            w: 100,
            h: 150,
          },
          {
            shape: 'Rect',
            x: 250,
            y: 0,
            w: 100,
            h: 150,
          },
        ],
      },
    },
    {
      type: 'tonguedepressor',
      src: 'tonguedepressor',
      hitArea: {
        x: 115,
        y: 200,
        shapes: [
          {
            shape: 'Rect',
            x: 10,
            y: 10,
            w: 300,
            h: 150,
          },
        ],
      },
    },
    {
      type: 'speculum',
      src: 'speculum',
      hitArea: {
        x: 225,
        y: 230,
        shapes: [
          {
            shape: 'Rect',
            x: 0,
            y: 0,
            w: 100,
            h: 100,
          },
        ],
      },
    },
  ];
  queue = new createjs.LoadQueue(false);
  audioPath;
  docAudioPath;
  doctorMouthSprite;
  doctorAudio;
  audioCheck = {
    history: true,
    hospitalManagement: true,
    ancillary: true,
    complication: false,
    consultation: false,
    diagnosis: false,
    finalDiagnosis: true,
    generalOrder: false,
    input: false,
    inquiry: false,
    invasiveManagement: true,
    labTest: true,
    managementPlan: true,
    medications: false,
    patientManagement: true,
    physicalExam: true,
    procedures: false,
    provisionalDiagnosis: true,
    recommendation: false,
    referrals: true,
    sendHome: false,
    epaProcedureQuestion: false,
    epaCrisisQuestion: false,
    rpo: false,
    surgery: false,
    surgerPlan: false,
  };
  office;
  loading;
  progress: any = 100;
  patientHistory;
  STAGEFLAG;
  videoPlayer;
  muted = false;
  doctorHistory;
  historyRequired = true;
  fullWidth = true;
  actionName;
  examDetailImageStyle;
  physicalExamCount = 1;
  patientId;
  intro = true;
  notApplicable = false;
  pediatric = false;
  currentPersp = 'Front';
  // The Canvas
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  communicationComponent: any;
  loadAudioStatus: boolean;
  communicationSprite;
  mnmSprite;
  private rpoCounter = 0;
  constructor(
    private _router: Router,
    public loader: LoadService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private detailImageService: DetailImageService,
    public patientService: PatientService,
    private palpationService: PalpationService,
    private measurementService: MeasurementsService,
    private thermometerService: ThermometerService,
    private pulsoximeteryService: PulsoximeteryService,
    public clockService: ClockService,
    private respirationService: RespirationService,
    private pulseService: PulseService,
    private createjsShapeService: CreatejsShapeService,
    private bloodPressureTestService: BloodPressureTestService,
    private sg: SimpleGlobal,
    private aiService: AiService
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.substring(0, 11);
        const path = event.url.split('?')[0];
        if (
          url === '/assignment' &&
          path !== '/assignment/feedback' &&
          path !== '/assignment/nursing-feedback' &&
          path !== '/assignment/assesment' &&
          path !== '/assignment/epafeedback' &&
          path !== '/assignment/medications' &&
          path !== '/assignment/consentForm' &&
          path !== '/assignment/consentFormSignature' &&
          path !== '/assignment/sendHomeMedications' &&
          path !== '/assignment/triage' &&
          path !== '/assignment/surgery' &&
          path !== '/assignment/videoModule'
        ) {
          this.fullWidth = false;
        } else {
          this.fullWidth = true;
        }
      }
    });
  }
  chooseSpecial(special: string) {
    this.specialChosen.next(special);
    if (this.videoPlayer) {
      this.videoPlayer.play();
    }
  }
  getSpecial(): Observable<any> {
    return this.specialChosen.asObservable();
  }
  stageReset() {
    this.stage.removeAllChildren();
    this.stage.clear();
    this.stage.update();
  }

  setStage(stage) {
    this.stage = stage;
    //  createjs.Ticker.on("tick", this.stage);
    // Create Ticker on stage
    createjs.Touch.enable(this.stage);

    // enabled mouse over / out events
    this.stage.enableMouseOver(10);
    this.stage.mouseMoveOutside = false; // DONT keep tracking the mouse even when it leaves the canvas

    createjs.Sound.initializeDefaultPlugins();
    createjs.Sound.alternateExtensions = ['mp3'];
    this.STAGEFLAG = true;
    createjs.Ticker.on('tick', this.stage);
  }
  returnStage() {
    return this.stage;
  }

  loadComplete() {
    const menuEl = document.getElementById('ml-menu');
    if (this.historyRequired && menuEl) {
      new MLMenu(menuEl, {
        breadcrumbsCtrl: false,
        backCtrl: true,
        itemsDelayInterval: 30,
      });
      this.action('history');
    } else {
      this.newSet(this.actionName);
    }
    this.loader.stop();
  }
  readySet(patient, doctor, historyRequired): any {
    this.loader.getloadAudioStatus().subscribe((l) => {
      this.loadAudioStatus = l;
    });

    if (this.loadAudioStatus) {
      this.loader.start();
    }

    createjs.Sound.removeAllSounds();
    this.historyRequired = historyRequired;
    this.historySet = new createjs.Container();
    this.physicalExamSet = new createjs.Container();
    this.supervisorSet = new createjs.Container();
    this.communicationSet = new createjs.Container();
    this.ancillarySet = new createjs.Container();
    this.POD1Set = new createjs.Container();
    this.POD2Set = new createjs.Container();
    this.POD3Set = new createjs.Container();
    this.POD4Set = new createjs.Container();
    this.POD5Set = new createjs.Container();
    this.POD6Set = new createjs.Container();
    this.POD7Set = new createjs.Container();
    this.POD8Set = new createjs.Container();
    this.POD9Set = new createjs.Container();
    this.POD10Set = new createjs.Container();
    this.sendHomeSet = new createjs.Container();
    this.altPhysicalSet = new createjs.Container();
    this.provisionalSet = new createjs.Container();
    this.finalSet = new createjs.Container();
    this.nurseSet = new createjs.Container();
    this.doctorAudio = [
      { id: 'hospitalManagement', src: './assets/default/hospital-management/response.mp3' },
      { id: 'ancillary', src: './assets/default/ancillary/response.mp3' },
      { id: 'complication', src: './assets/default/complication/response.mp3' },
      { id: 'consultation', src: './assets/default/hospital-management/response.mp3' },
      { id: 'diagnosis', src: './assets/default/hospital-management/response.mp3' },
      { id: 'wellDone', src: './assets/default/feedback/welldone.mp3' },
      { id: 'tryAgain', src: './assets/default/feedback/tryagain.mp3' },
      { id: 'finalDiagnosis', src: './assets/default/finalDiagnosis/response.mp3' },
      { id: 'generalOrder', src: './assets/default/general-order/audio/response.mp3' },
      { id: 'goodbye', src: './assets/default/goodbye/doctor.mp3' },
      { id: 'hospitalSetting', src: './assets/default/hospital-setting/response.mp3' },
      { id: 'input', src: './assets/default/input/response.mp3' },
      { id: 'incorrect', src: './assets/default/finalDiagnosis/incorrect.mp3' },
      { id: 'inquiry', src: './assets/default/inquiry/sounds/response.mp3' },
      { id: 'responseNurse', src: './assets/patients/5ea36ce9fa90860012efb1c3/inquiry/sounds/responseNurse.mp3' },
      { id: 'invasiveManagement', src: './assets/default/invasive-management/response.mp3' },
      { id: 'investigations', src: './assets/default/labtest/lab.mp3' },
      { id: 'testSound', src: './assets/default/labtest/test.mp3' },
      { id: 'medications', src: './assets/default/medications/response.mp3' },
      { id: 'patientManagement', src: './assets/default/patient-management/response.mp3' },
      { id: 'physicalExam', src: './assets/default/physical/sounds/response.mp3' },
      { id: 'curtain', src: './assets/default/physical/sounds/curtain.mp3' },
      { id: 'auscultation', src: './assets/default/physical/sounds/auscultation.mp3' },
      { id: 'superficial', src: './assets/default/physical/sounds/superficial.mp3' },
      { id: 'deep', src: './assets/default/physical/sounds/deep.mp3' },
      { id: 'percussion', src: './assets/default/physical/sounds/percussion.mp3' },
      { id: 'respiratory', src: './assets/default/physical/sounds/respiratory.mp3' },
      { id: 'size', src: './assets/default/physical/sounds/size.mp3' },
      { id: 'turn', src: './assets/default/physical/sounds/turn.mp3' },
      { id: 'temperature', src: './assets/default/physical/sounds/temperature.mp3' },
      { id: 'pulseOximetery', src: './assets/default/physical/sounds/pulseOximetery.mp3' },
      { id: 'mouth', src: './assets/default/physical/sounds/mouth.mp3' },
      { id: 'nose', src: './assets/default/physical/sounds/nose.mp3' },
      { id: 'eyes', src: './assets/default/physical/sounds/eyes.mp3' },
      { id: 'ears', src: './assets/default/physical/sounds/ears.mp3' },
      { id: 'laydown', src: './assets/default/physical/sounds/laydown.mp3' },
      { id: 'situp', src: './assets/default/physical/sounds/situp.mp3' },
      { id: 'pulse', src: './assets/default/physical/sounds/pulse.mp3' },
      { id: 'Head', src: './assets/default/physical/sounds/head.mp3' },
      { id: 'Torso', src: './assets/default/physical/sounds/torso.mp3' },
      { id: 'Feet', src: './assets/default/physical/sounds/feet.mp3' },
      { id: 'Hands', src: './assets/default/physical/sounds/hands.mp3' },
      { id: 'HandsPed', src: './assets/default/physical/sounds/handsPed.mp3' },
      { id: 'HeadPed', src: './assets/default/physical/sounds/headPed.mp3' },
      { id: 'TorsoPed', src: './assets/default/physical/sounds/torsoPed.mp3' },
      { id: 'FeetPed', src: './assets/default/physical/sounds/feetPed.mp3' },
      { id: 'LeftArmPit', src: './assets/default/physical/sounds/leftarmpit.mp3' },
      { id: 'RightArmPit', src: './assets/default/physical/sounds/rightarmpit.mp3' },
      { id: 'bloodpressure', src: './assets/default/physical/sounds/bloodpressure.mp3' },
      { id: 'curtain', src: './assets/default/physical/sounds/curtain.mp3' },
      { id: 'managementPlan', src: './assets/default/management-plan/response.mp3' },
      { id: 'procedures', src: './assets/default/procedures/response.mp3' },
      { id: 'provisionalDiagnosis', src: './assets/default/provisional/response.mp3' },
      { id: 'recommendation', src: './assets/default/recommend/response.mp3' },
      { id: 'referrals', src: './assets/default/referrals/response.mp3' },
      { id: 'sendHome', src: './assets/default/send-home/response.mp3' },
      { id: 'surgery', src: './assets/default/surgery/response.mp3' },
      { id: 'surgeryPlan', src: './assets/default/surgery-plan/response.mp3' },
      { id: 'goodDay', src: './assets/default/doctor/audio/goodDay.mp3' },
      { id: 'blank', src: './assets/default/doctor/audio/blank.mp3' },
      { id: 'bloodPressure.marker', src: './assets/default/physical/Props/BloodPressure/blood-pressure.mp3' },
      { id: 'turnPed', src: './assets/default/physical/sounds/turnPed.mp3' },
      { id: 'eyesPed', src: './assets/default/physical/sounds/eyesPed.mp3' },
      { id: 'earsPed', src: './assets/default/physical/sounds/earsPed.mp3' },
      { id: 'nosePed', src: './assets/default/physical/sounds/nosePed.mp3' },
      { id: 'mouthPed', src: './assets/default/physical/sounds/mouthPed.mp3' },
      { id: 'sizePed', src: './assets/default/physical/sounds/sizePed.mp3' },
      { id: 'tempPed', src: './assets/default/physical/sounds/tempPed.mp3' },
      { id: 'oximeteryPed', src: './assets/default/physical/sounds/oximeteryPed.mp3' },
      { id: 'respPed', src: './assets/default/physical/sounds/respPed.mp3' },
      { id: 'bloodPresPed', src: './assets/default/physical/sounds/bloodPresPed.mp3' },
      { id: 'pulsePed', src: './assets/default/physical/sounds/pulsePed.mp3' },
      { id: 'superficialPed', src: './assets/default/physical/sounds/superficialPed.mp3' },
      { id: 'deepPed', src: './assets/default/physical/sounds/deepPed.mp3' },
      { id: 'ausculationPed', src: './assets/default/physical/sounds/ausculationPed.mp3' },
      { id: 'percussionPed', src: './assets/default/physical/sounds/percussionPed.mp3' },
      { id: 'laydownPed', src: './assets/default/physical/sounds/laydownPed.mp3' },
      { id: 'situpPed', src: './assets/default/physical/sounds/situpPed.mp3' },
      { id: 'hello', src: './assets/patients/' + this.sg['case_id'] + '/history/sounds/hello.mp3' },
      { id: 'hurtWhenTouch', src: './assets/default/doctor/audio/hurts.mp3' },
      { id: 'proDiagnosisCom', src: './assets/default/proDiagnosisCom/response.mp3' },
      { id: 'procedureComOne', src: './assets/default/procedureComOne/response.mp3' },
      { id: 'procedureComTwo', src: './assets/default/procedureComTwo/response.mp3' },
      { id: 'investigationEPA', src: './assets/default/investigationEPA/response.mp3' },
      { id: 'communicationCrisis', src: './assets/default/communicationCrisis/response.mp3' },
      { id: 'communicationSbar', src: './assets/default/communicationSbar/response.mp3' },
      { id: 'mNmRounds', src: './assets/default/mNmRounds/response.mp3' },
      { id: 'mNmRoundsPresentation', src: './assets/default/mNmRoundsPresentation/response.mp3' },
      { id: 'comRecommendations', src: './assets/default/comRecommendations/response.mp3' },
      { id: 'epaProcedureQuestion', src: './assets/default/epaProcedureQuestion/response.mp3' },
      { id: 'epaCrisisQuestion', src: './assets/default/epaCrisisQuestion/response.mp3' },
      { id: 'rpo', src: './assets/default/rpo/response.mp3' },
      { id: 'consentForm', src: './assets/default/consentForm/response.mp3' },
      { id: 'consentFormPage', src: './assets/default/consentForm/pageResponse.mp3' },
      { id: 'consentFormSignature', src: './assets/default/consentFormSignature/response.mp3' },
      { id: 'medications', src: './assets/default/medications/response.mp3' },
    ];
    // this.doctorAudio=[];

    const queue = new createjs.LoadQueue(true);
    queue.setMaxConnections(10);
    queue.on('complete', this.loadComplete, this);
    // if(environment.debug){
    //  }
    queue.on('progress', () => {
      this.progress = queue.progress;
      this.loader.progress = this.progress * 100;
    });
    this.audioPath = './assets/patients/' + this.sg['case_id'] + '/history/sounds/';
    this.docAudioPath = './assets/default/doctor/audio/';
    createjs.Sound.alternateExtensions = ['mp3'];
    queue.installPlugin(createjs.Sound);
    // TODO - fix this
    // this.audioPath="";
    // this.docAudioPath=""
    queue.loadManifest(this.doctorAudio, true);
    if (historyRequired) {
      this.patientHistory = patient;
      this.doctorHistory = doctor;
      queue.loadManifest(patient, false);
      queue.loadManifest(doctor, false);
    }
    queue.load();

    // SET ALL VARIABLES TO CASE ID
    this.patientimg = './assets/patients/' + this.sg['case_id'] + '/history/images/patient.png';
    this.patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    this.physicalimg = './assets/patients/' + this.sg['case_id'] + '/history/images/patient.png';
    this.backgroundImg = './assets/patients/' + this.sg['case_id'] + '/room/' + this.sg['instance_id'] + '.png';

    const doctorMouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        numFrames: 13,
        regX: 0,
        regY: 0,
      },
      animations: {
        ancillary: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        epaProcedureQuestion: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        epaCrisisQuestion: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        physicalExam: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        history: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        provisionalDiagnosis: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
          ],
        },
        finalDiagnosis: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
          ],
        },
        hospitalManagement: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        hospitalSetting: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
          ],
        },
        rpo: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
          ],
        },
        patientManagement: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        invasiveManagement: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        generalOrder: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        managementPlan: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        surgeryPlan: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        surgery: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        consultation: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        counselling: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        medications: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        input: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        procedures: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        referrals: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        sendHome: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        sendHomeOrders: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        followUp: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        complete: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        wellDone: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        tryAgain: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        incorrect: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        correct: {
          speed: '1',
          next: 'Idle',
          frames: [
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        Idle: ['0', '0', '0'],
      },
      images: ['./assets/default/doctor/images/mouth.png'],
    });
    this.curtainSetup();
    this.resetPhys();
    this.patientId = this.sg['case_id'];
    this.pediatric =
      this.patientId === '5c0715573c024f000f745ff4' ||
      this.patientId === '5c081def1ad175000f58d22a' ||
      this.patientId === '5c08197e1ad175000f58d219' ||
      this.patientId === '5c081b791ad175000f58d221' ||
      this.patientId === '5c17f1209020b900168e58ba' ||
      this.patientId === '5c13e2d59020b900168e5731' ||
      this.patientId === '5c0816461ad175000f58d20e' ||
      this.patientId === '5c0819d31ad175000f58d21b' ||
      this.patientId === '5c0818c31ad175000f58d217' ||
      this.patientId === '5c0817431ad175000f58d210' ||
      this.patientId === '5c17f16e9020b900168e58bd' ||
      this.patientId === '5c0818411ad175000f58d215' ||
      this.patientId === '5ea36ce9fa90860012efb1c3' ||
      this.patientId === '5c17f09a9020b900168e58b3' ||
      this.patientId === '5bfdb527341d8d000f82cf99';
    if (historyRequired) {
      this.historySetup();
    }

    this.altPhysicalSetup();
    this.officeSetup(doctorMouth);
    this.nurseSetup();
    this.ancillarySetup();
    this.observationSetup();
    // this.physicalSetup(false);
    this.POD1Setup();
    this.POD2Setup();
    this.POD3Setup();
    this.POD4Setup();
    this.POD5Setup();
    this.POD6Setup();
    this.POD7Setup();
    this.POD8Setup();
    this.POD9Setup();
    // this.POD10Setup();
    this.sendHomeSetup();
    this.inquirySetup();
    this.mnmSetup(undefined);
    this.mnmPresentationSetUp(undefined, undefined);
    this.hotSet = true;
  }

  resetPhys() {
    this.patientService.perspContainer = null;
    this.patientService.hitContainer = null;
    this.patientService.contentContainer = null;
    this.patientService.palpulationDefaultSounds = [];
    this.patientService.respirationTween = null;
    this.patientService.container = new createjs.Container();
    this.patientService.currentPersp = 'Front';
    this.patientService.physicalActions = new Set();
    // this.patientService.scoreAuscultation = new Set();
    // this.patientService.scoreSuperficial = new Set();
    // this.patientService.scoreDeep = new Set();
    this.patientService.scorePercussion = new Set();
    this.patientService.scoreSpecialTools = new Set();
    this.patientService.scorePosition = new Set();
    this.patientService.scoreOther = new Set();
    this.patientService.scoreTotal = [];
    this.patientService.patientSprites = [];
    this.patientService.palpationSprites = [];
    // TODO: Finish bug-free version of reset
    // this.PalpationService.reset();
    this.bloodPressureTestService.reset();
  }

  historySetup() {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'initialAssessment' + '.png';
    this.image = new createjs.Bitmap(bgimg);

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: this.mouthAni,
      images: [this.patientmouth],
    });

    let patient;
    switch (this.sg['case_id']) {
      case '5c081b791ad175000f58d221':
        patient = new createjs.SpriteSheet({
          frames: {
            framerate: 12,
            width: 330,
            height: 725,
            numFrames: 12,
            regX: 0,
            regY: 0,
          },
          animations: {
            blink: {
              frames: [
                '0',
                '1',
                '2',
                '3',
                '2',
                '1',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
              ],
              next: 'blink',
              speed: '0.5',
            },
            fidget: {
              frames: ['4', '5', '6', '7', '8', '7', '6', '5', '4'],
              next: 'blink',
              speed: '1',
            },
            sit: [0],
          },
          images: [this.patientimg],
        });
        this.patientSprite = new createjs.Sprite(patient, 'blink');
        this.mouthSprite = new createjs.Sprite(mouth, 'Idle');
        break;

      case '5e6fc729aa23d900117c1da8': // Enrico
        patient = new createjs.SpriteSheet({
          frames: {
            framerate: 12,
            width: 449,
            height: 696,
            numFrames: 220,
            regX: 0,
            regY: 0,
          },
          animations: {
            Idle: {
              speed: '0.5',
              frames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
            Act: [1, 219, '', 0.7],
          },
          images: [this.patientimg],
        });

        this.patientSprite = new createjs.Sprite(patient, 'Act');
        this.mouthSprite = new createjs.Sprite(mouth, 'Idle');
        // this.patientSprite.addEventListener("click",(event)=>{ this.patientSprite.Act.gotoAndPlay("RightArmUp") });

        break;

      default:
        patient = new createjs.SpriteSheet({
          frames: {
            width: 330,
            height: 725,
            numFrames: 4,
            regX: 0,
            regY: 0,
          },
          animations: {
            run: [1, 8, 'sit'],
            sit: [0],
          },
          images: [this.patientimg],
        });

        // Create Individual Sprites
        this.patientSprite = new createjs.Sprite(patient, 'sit');
        this.mouthSprite = new createjs.Sprite(mouth, 'Idle');
        this.mouthSprite.name = 'patientMouth';
        break;
    }

    // Add assets to stage
    this.historySet.addChild(this.image);
    this.historySet.addChild(this.patientSprite);
    this.historySet.addChild(this.mouthSprite);

    if (this.pediatric) {
      this.mouthSprite.y = 110;
      this.mouthSprite.x = 120;
      this.patientSprite.y = 130;
      this.patientSprite.x = 150;
      // FIDGET CODE SARAH FIX
      if (this.sg['case_id'] === '5c081b791ad175000f58d221') {
        this.mouthSprite.y = 110;
        this.mouthSprite.x = 120;
        this.patientSprite.y = 130;
        this.patientSprite.x = 150;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5bfdb527341d8d000f82cf99') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 210;
        this.mouthSprite.x = 283;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c0819d31ad175000f58d21b') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 209;
        this.mouthSprite.x = 210;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c0715573c024f000f745ff4') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 210;
        this.mouthSprite.x = 284;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c0818c31ad175000f58d217') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 215;
        this.mouthSprite.x = 217;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c0818411ad175000f58d215') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 210;
        this.mouthSprite.x = 270;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c0816461ad175000f58d20e') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 110;
        this.mouthSprite.x = 120;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c13e2d59020b900168e5731') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 200;
        this.mouthSprite.x = 281;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c17f1209020b900168e58ba') {
        this.patientSprite.y = 5;
        this.patientSprite.x = 110;
        this.mouthSprite.y = 190;
        this.mouthSprite.x = 311;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        this.patientSprite.y = 80;
        this.patientSprite.x = 161;
        this.mouthSprite.y = 180;
        this.mouthSprite.x = 310;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c081def1ad175000f58d22a') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 200;
        this.mouthSprite.x = 280;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c08197e1ad175000f58d219') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 200;
        this.mouthSprite.x = 280;
        this.mouthSprite.scaleX = this.mouthSprite.scaleY = 0.28;
      } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 200;
        this.mouthSprite.x = 280;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        this.patientSprite.y = 110;
        this.patientSprite.x = 125;
        this.mouthSprite.y = 305;
        this.mouthSprite.x = 210;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.13;
      }
    } else if (this.aiService.supportedAiCase(this.patientId)) {
      this.mouthSprite.y = 282;
      this.mouthSprite.x = 272.5;
      this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.5;
    } else {
      switch (this.sg['case_id']) {
        case '5e6fc729aa23d900117c1da8':
          this.patientSprite.y = 110;
          this.patientSprite.x = 70;
          this.mouthSprite.y = 193;
          this.mouthSprite.x = 283;
          this.mouthSprite.scaleX = 0.28;
          this.mouthSprite.scaleY = 0.28;
          break;
        case '5bfd820f341d8d000f82cf73':
          this.patientSprite.y = 110;
          this.patientSprite.x = 110;
          this.mouthSprite.y = 197;
          this.mouthSprite.x = 270;
          this.mouthSprite.scaleX = 0.28;
          this.mouthSprite.scaleY = 0.28;
          break;
        case '5b6357bbfb3bf200198a69d7':
          this.patientSprite.y = 110;
          this.patientSprite.x = 110;
          this.mouthSprite.y = 221;
          this.mouthSprite.x = 261;
          this.mouthSprite.scaleX = 0.28;
          this.mouthSprite.scaleY = 0.28;
          break;
        case '5b3bfd8cb07ee9000f216376':
          this.patientSprite.y = 110;
          this.patientSprite.x = 115;
          this.mouthSprite.y = 208;
          this.mouthSprite.x = 269;
          this.mouthSprite.scaleX = 0.25;
          this.mouthSprite.scaleY = 0.25;
          break;
        default:
          this.patientSprite.y = 110;
          this.patientSprite.x = 125;
          this.mouthSprite.y = 201;
          this.mouthSprite.x = 279;
          this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.28;
          break;
      }
    }
  }
  patientClick(event) {
    const toolSelected = this.toolSelected;
    // imageSrc will return the tool name if the correct hotspot is hit.
    const imageSrc = this.detailImageService.checkClick(toolSelected, event.stageX, event.stageY, this.zoom);

    this.examDetailImageStyle = imageSrc;
    switch (toolSelected) {
      case '':
        this.physicalExamSet.getChildByName('mouth').visible = false;
        this.physicalExamSet.getChildByName('ophthalmoscope').visible = false;
        this.physicalExamSet.getChildByName('otoscope').visible = false;
        this.physicalExamSet.getChildByName('speculum').visible = false;
        break;
      case 'ophthalmoscope':
        if (imageSrc) {
          this.physicalExamSet.getChildByName('ophthalmoscope').visible = true;
          this.physicalExamSet.getChildByName('mouth').visible = false;
          this.physicalExamSet.getChildByName('otoscope').visible = false;
          this.physicalExamSet.getChildByName('speculum').visible = false;
          this.bg.visible = true;
          this.stage.update();
        }
        break;
      case 'otoscope':
        if (imageSrc) {
          this.physicalExamSet.getChildByName('otoscope').visible = true;
          this.physicalExamSet.getChildByName('mouth').visible = false;
          this.physicalExamSet.getChildByName('speculum').visible = false;
          this.physicalExamSet.getChildByName('ophthalmoscope').visible = false;
          this.bg.visible = true;
          this.stage.update();
        }
        break;
      case 'tonguedepressor':
        if (imageSrc) {
          this.physicalExamSet.getChildByName('mouth').visible = true;
          this.physicalExamSet.getChildByName('speculum').visible = false;
          this.physicalExamSet.getChildByName('ophthalmoscope').visible = false;
          this.physicalExamSet.getChildByName('otoscope').visible = false;
          this.bg.visible = true;
          this.stage.update();
        }
        break;
      case 'speculum':
        if (imageSrc) {
          this.physicalExamSet.getChildByName('mouth').visible = false;
          this.physicalExamSet.getChildByName('speculum').visible = true;
          this.physicalExamSet.getChildByName('ophthalmoscope').visible = false;
          this.physicalExamSet.getChildByName('otoscope').visible = false;
          this.bg.visible = true;
          this.stage.update();
        }
        break;
      case 'zoom':
        if (this.zoom !== 'Normal') {
          this.zoom = 'Normal';
          this.zoomHelp.visible = true;
          this.patientService.show('Front');
          this.currentPersp = 'Front';
          this.physicalExamSet.getChildByName('background').visible = true;
          this.physicalExamSet.getChildByName('patientDownBackground').visible = false;
          this.physicalExamSet.getChildByName('background').filters = [];
          this.physicalExamSet.getChildByName('background').cache(0, 0, 600, 616);
          this.stage.update();
        } else {
          this.zoom = this.patientService.checkZoom(toolSelected, event.stageX, event.stageY, this.zoom);
          this.currentPersp = this.patientService.getPerspective();
          if (this.zoom !== 'Normal') {
            this.soundManager(this.pediatric ? this.zoom + 'Ped' : this.zoom);
            this.zoomHelp.visible = false;
            const blurFilter = new createjs.BlurFilter(50, 50, 3);
            const bg = this.physicalExamSet.getChildByName('background');
            bg.filters = [blurFilter];
            bg.cache(0, 0, 600, 616);
            this.stage.update();
          }
        }
        break;
    }
  }
  physicalSetup(hotset) {
    this.physicalExamSet = new createjs.Container();
    this.patientService.reset();

    if (this.stageName === 'physicalExam') {
      this.fetchAssignmentDataService.getPhysExamStudent().subscribe((physex) => {
        if (hotset) {
          this.stage.addChild(this.curtain);
          this.curtainTween.setPosition(0);

          this.stage.setChildIndex(this.curtain, this.stage.getNumChildren() - 1);
          // SARAH TODO: Turned off curtain noise  var currentAudio = this.soundManager("curtain");

          this.curtainTween.setPaused(false);
        }
        // TODO: REMOVE WHEN DATABASE UPDATED. SARAH FIX.
        physex['vitals'].o2sat = 95;
        const height: number = physex['height'];
        const weight: number = physex['weight'];
        const temperature: number = physex['vitals'].temperature;
        const bloodOver = physex['vitals'].blood_pressure_over;
        const bloodUnder = physex['vitals'].blood_pressure_under;
        const pulseRate = physex['vitals'].pulse_rate;
        const percent = physex['vitals'].pulsoximetery;
        const respiration = physex['vitals'].respiration_in;
        let background;
        if (this.pediatric) {
          const pediatricBackground = './assets/patients/' + this.sg['case_id'] + '/room/pediatric' + '.png';

          background = new createjs.Bitmap(pediatricBackground);
        } else if (this.aiService.supportedAiCase(this.patientId)) {
          const physicalExam = './assets/patients/' + this.sg['case_id'] + '/room/physicalExam' + '.png';
          background = new createjs.Bitmap(physicalExam);
        } else if (
          this.sg['instance_id'] === 'POD1' ||
          this.sg['instance_id'] === 'POD2' ||
          this.sg['instance_id'] === 'POD3' ||
          this.sg['instance_id'] === 'observation1' ||
          this.sg['instance_id'] === 'observation2' ||
          this.sg['instance_id'] === 'observation3'
        ) {
          const backgroundBG = './assets/patients/' + this.sg['case_id'] + '/room/PhExPOD2.png';
          background = new createjs.Bitmap(backgroundBG);
        } else if (
          this.sg['instance_id'] === 'POD4' ||
          this.sg['instance_id'] === 'POD5' ||
          this.sg['instance_id'] === 'POD6' ||
          this.sg['instance_id'] === 'POD7' ||
          this.sg['instance_id'] === 'POD8' ||
          this.sg['instance_id'] === 'POD9'
        ) {
          const backgroundBG = './assets/patients/' + this.sg['case_id'] + '/room/PhExPOD4.png';
          background = new createjs.Bitmap(backgroundBG);
        } else {
          const backgroundBG = './assets/patients/' + this.sg['case_id'] + '/room/initialAssessment.png';
          background = new createjs.Bitmap(backgroundBG);
        }
        this.blurFilter = new createjs.BlurFilter(5, 5, 1);
        background.name = 'background';
        this.physicalExamSet.addChild(background);
        const patientDownBackground = new createjs.Bitmap(this.patientDownBackgroundImg);
        patientDownBackground.name = 'patientDownBackground';
        patientDownBackground.visible = false;
        this.physicalExamSet.addChild(patientDownBackground);

        const mouthURL =
          './assets/patients/' + this.sg['case_id'] + '/physical-exam/images/' + this.sg['instance_id'] + '/mouth.png';
        const mouth = new createjs.Bitmap(mouthURL);
        mouth.name = 'mouth';
        mouth.x = 100;
        mouth.y = 100;
        mouth.scaleX = mouth.scaleY = 0.5;
        mouth.visible = false;

        const ophthalmoscopeURL =
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/images/' +
          this.sg['instance_id'] +
          '/ophthalmoscope.png';
        const zoomHelpURL = './assets/patients/' + this.sg['case_id'] + '/physical-exam/images/zoom.png';
        this.zoomHelp = new createjs.Bitmap(zoomHelpURL);
        this.zoomHelp.x = this.zoomHelp.y = 0;
        this.zoomHelp.scaleX = this.zoomHelp.scaleY = 1;
        this.zoomHelp.visible = false;
        const ophthalmoscope = new createjs.Bitmap(ophthalmoscopeURL);

        ophthalmoscope.name = 'ophthalmoscope';
        ophthalmoscope.x = 100;
        ophthalmoscope.y = 100;
        ophthalmoscope.scaleX = ophthalmoscope.scaleY = 0.5;
        ophthalmoscope.visible = false;

        const otoscopeURL =
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/images/' +
          this.sg['instance_id'] +
          '/otoscope.png';
        const otoscope = new createjs.Bitmap(otoscopeURL);
        otoscope.name = 'otoscope';
        otoscope.x = 150;
        otoscope.y = 150;
        otoscope.scaleX = otoscope.scaleY = 0.5;
        otoscope.visible = false;

        const speculumURL =
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/images/' +
          this.sg['instance_id'] +
          '/speculum.png';
        const speculum = new createjs.Bitmap(speculumURL);
        speculum.name = 'speculum';
        speculum.x = 100;
        speculum.y = 100;
        speculum.scaleX = speculum.scaleY = 0.5;
        speculum.visible = false;
        this.patientService.setRespirationRate(respiration);
        this.patientService.init(physex);
        this.clockService.init();
        this.palpationService.init();
        this.pulseService.init(pulseRate);
        this.thermometerService.init(temperature);
        this.measurementService.init(height, weight, this.pediatric);
        this.pulsoximeteryService.init(percent);
        this.detailImageService.init(this.detailImages);
        this.bloodPressureTestService.init(bloodOver, bloodUnder);
        this.patientServiceCont = this.patientService.getContainer();
        this.physicalExamSet.addChild(this.patientServiceCont);
        this.physicalExamSet.addChild(this.detailImageService.getHitContainer());
        this.physicalExamSet.addChild(this.measurementService.getContainer());
        this.physicalExamSet.addChild(this.pulsoximeteryService.getContainer());
        this.physicalExamSet.addChild(this.thermometerService.getContainer());
        this.physicalExamSet.addChild(this.pulseService.getContainer());
        this.physicalExamSet.addChild(this.clockService.getClockContainer());
        //         this.stage.addChild(this.bloodPressureTestService.getContainer());
        this.physicalExamSet.addChild(this.palpationService.getContainer());
        this.physicalExamSet.addChild(this.bloodPressureTestService.getContainer());

        this.bg = new createjs.Shape();
        this.bg.graphics.beginFill('#000').drawRect(0, 0, 600, 600);
        this.bg.visible = false;
        this.bg.alpha = 0.95;
        this.physicalExamSet.addChild(this.bg);

        this.physicalExamSet.addChild(mouth);
        this.physicalExamSet.addChild(ophthalmoscope);
        this.physicalExamSet.addChild(otoscope);
        this.physicalExamSet.addChild(speculum);
        this.physicalExamSet.addChild(this.zoomHelp);
        //   this.stage.addChild(this.physicalExamSet);
        //   this.physicalExamSet.y = 0;
        //  this.PatientService.getContainer().removeAllEventListeners('click');
        this.physicalExamSet.addEventListener('click', this.patientClick.bind(this));
        this.physicalExamSet.mouseChildren = true;
        this.stage.update();
      });
    }
    if (!hotset) {
      this.stage.addChild(this.physicalExamSet);
    }
  }

  altPhysicalSetup() {
    const physicalimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + this.sg['instance_id'] + '.png';
    const bga = new createjs.Bitmap(bgimg);
    this.patient = new createjs.Bitmap(physicalimg);

    this.patient.y = 261;
    this.patient.x = 298;
    // Add assets to stage
    this.altPhysicalSet.addChild(bga);
    this.altPhysicalSet.addChild(this.patient);

    // this.stage.addChild(this.altPhysicalSet);
  }

  mnmPresentationSetUp(text, header) {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/mNmRoundsPresentationPatient.png';
    const bga = new createjs.Bitmap(bgimg);
    bga.name = 'mnmPresentationSetUp_Background';
    const tText = new createjs.Text(text, '14px Arial', '#000');
    tText.name = 'mnmPresentationSetUp_Inputtext';
    const tHeder = new createjs.Text(header, 'bold 18px Arial', '#000');
    tHeder.name = 'mnmPresentationSetUp_HeaderText';
    tHeder.x = 136;
    tHeder.y = 60;
    tText.x = 136;
    tText.y = 85;
    this.mnmPresentationSet.removeChild(tText);
    this.mnmPresentationSet.removeChild(tHeder);
    this.mnmPresentationSet.addChild(bga);
    this.mnmPresentationSet.addChild(tText);
    this.mnmPresentationSet.addChild(tHeder);

    this.stage.update();
  }

  communicationSetup(name, step) {
    const defaultBackground = new createjs.Bitmap('./assets/img/app/logo600.png');
    defaultBackground.name = 'defaultBackground';
    defaultBackground.visible = true;
    if (this.communicationSet.getChildByName('defaultBackground') == null) {
      this.communicationSet.addChild(defaultBackground);
    }
    if (step === undefined || step === null) {
      step = 'patient';
    }
    this.communicationSet.removeChild(this.communicationSprite);
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + name + '_' + step + '.png';
    const audience = new createjs.SpriteSheet({
      frames: { framerate: 10, width: 600, height: 600, numFrames: 240, regX: 0, regY: 0 },
      animations: {
        Idle: {
          speed: '0.7',
          frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        Act: [0, 239, 0.7],
      },
      images: [bgimg],
    });
    this.communicationSprite = new createjs.Sprite(audience, 'Act');
    this.communicationSprite.name = 'communicationSprite';
    this.communicationSprite.visible = true;
    if (this.communicationSet.getChildByName('communicationSprite') == null) {
      this.communicationSet.addChild(this.communicationSprite);
    }

    this.stage.update();
  }
  mnmSetup(step) {
    if (step === undefined || step === null) {
      step = 'empty';
    }
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/auditorium_' + step + '.png';
    const bga = new createjs.Bitmap(bgimg);
    const audience = new createjs.SpriteSheet({
      frames: { framerate: 12, width: 1000, height: 1000, numFrames: 240, regX: 0, regY: 0 },
      animations: {
        Act: [0],
      },
      images: [bgimg],
    });
    this.mnmSprite = new createjs.Sprite(audience, 'Act');
    this.mnmSet.removeAllChildren();
    this.mnmSet.addChild(bga);
    this.mnmSet.addChild(this.mnmSprite);
    this.stage.update();
  }
  officeSetup(doctorMouth) {
    // setup doctor scene

    this.doctorMouthSprite = new createjs.Sprite(doctorMouth, 'Idle');
    this.office = new createjs.Bitmap('./assets/default/doctor/images/office.png');

    this.supervisorSet.addChild(this.office);
    this.supervisorSet.addChild(this.doctorMouthSprite);

    // Create Asset Positioning & Scale
    this.doctorMouthSprite.scaleY = this.doctorMouthSprite.scaleX = 0.2;
    this.doctorMouthSprite.y = 198;
    this.doctorMouthSprite.x = 298;
  }
  nurseSetup() {
    this.nurseBG = new createjs.Bitmap('./assets/default/labtest/reception.png');

    const nurseMouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        numFrames: 13,
        regX: 0,
        regY: 0,
      },
      animations: {
        investigations: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        invalid: {
          speed: '1',
          next: 'Idle',
          frames: [
            '0',
            '5',
            '7',
            '7',
            '0',
            '8',
            '8',
            '9',
            '9',
            '8',
            '8',
            '4',
            '1',
            '0',
            '0',
            '0',
            '3',
            '5',
            '1',
            '1',
            '5',
            '3',
            '1',
            '1',
            '1',
            '9',
            '9',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
        },
        Idle: ['0', '0', '0'],
      },
      images: ['./assets/default/labtest/mouth.png'],
    });

    this.nurseMouthSprite = new createjs.Sprite(nurseMouth, 'Idle');

    // Add assets to stage
    this.nurseSet.addChild(this.nurseBG);
    this.nurseSet.addChild(this.nurseMouthSprite);

    // Create Asset Positioning & Scale
    this.nurseMouthSprite.scaleY = this.nurseMouthSprite.scaleX = 0.17;
    this.nurseMouthSprite.y = 302;
    this.nurseMouthSprite.x = 387;
  }
  POD1Setup() {
    const POD1patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const POD1bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD1' + '.png';
    const POD1bg = new createjs.Bitmap(POD1bgimg);
    const POD1patient = new createjs.Bitmap(POD1patientimg);
    this.POD1Set.addChild(POD1bg);
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');
    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD1Set.addChild(mouthSprite);
    }
    if (this.pediatric) {
      if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        mouthSprite.y = 305;
        mouthSprite.x = 153;
        POD1patient.y = 130;
        POD1patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.22;
      } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
        mouthSprite.y = 196;
        mouthSprite.x = 281;
        POD1patient.y = 243;
        POD1patient.x = 324;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.y = 196;
        mouthSprite.x = 22281;
        POD1patient.y = 243;
        POD1patient.x = 324;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      } else {
        mouthSprite.y = 110;
        mouthSprite.x = 120;
        POD1patient.y = 130;
        POD1patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      }
    } else if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 217;
      mouthSprite.x = 343;
      POD1patient.y = 261;
      POD1patient.x = 298;
    } else if (this.sg['case_id'] === '5b6347bafb3bf200198a69b3') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 235;
      mouthSprite.x = 308;
      POD1patient.y = 261;
      POD1patient.x = 298;
    } else if (this.sg['case_id'] === '5c081cac1ad175000f58d225') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.2;
      mouthSprite.y = 227;
      mouthSprite.x = 339;
      POD1patient.y = 261;
      POD1patient.x = 298;
    } else if (this.sg['case_id'] === '5b635848fb3bf200198a69e1') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.15;
      mouthSprite.y = 212;
      mouthSprite.x = 352;
    } else if (this.sg['case_id'] === '5b63479ffb3bf200198a69b1') {
      mouthSprite.scaleY = 0.21;
      mouthSprite.scaleX = 0.18;
      mouthSprite.y = 233;
      mouthSprite.x = 316;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;
      POD1patient.y = 261;
      POD1patient.x = 298;
    }
  }
  POD2Setup() {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD2' + '.png';
    // const image = new createjs.Bitmap(bgimg);
    // Create Sprite Sheets
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';

    const bg = new createjs.Bitmap(bgimg);
    // var patient = new createjs.Bitmap(patientimg);
    this.POD2Set.addChild(bg);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');
    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD2Set.addChild(patientSprite);
      this.POD2Set.addChild(mouthSprite);
    }

    if (this.pediatric) {
      if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        mouthSprite.y = 305;
        mouthSprite.x = 153;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.22;
      } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
        mouthSprite.y = 196;
        mouthSprite.x = 281;
        patient.y = 130;
        patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.scaleY = 0.21;
        mouthSprite.scaleX = 0.18;
        mouthSprite.y = 373;
        mouthSprite.x = 4336;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
      } else {
        mouthSprite.y = 110;
        mouthSprite.x = 120;
        patient.y = 130;
        patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      }
    } else if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 214;
      mouthSprite.x = 350;
      // POD2patient.y = 261;
      // POD2patient.x = 298;
    } else if (this.sg['case_id'] === '5b6347bafb3bf200198a69b3') {
      mouthSprite.y = 235;
      mouthSprite.x = 308;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
    } else if (this.sg['case_id'] === '5bfc9f7a341d8d000f82cf71') {
      mouthSprite.y = 212;
      mouthSprite.x = 315;
      mouthSprite.scaleY = 0.21;
      mouthSprite.scaleX = 0.2;
    } else if (this.sg['case_id'] === '5b635848fb3bf200198a69e1') {
      mouthSprite.y = 239;
      mouthSprite.x = 352;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.15;
    } else if (this.sg['case_id'] === '5b63479ffb3bf200198a69b1') {
      mouthSprite.scaleY = 0.21;
      mouthSprite.scaleX = 0.18;
      mouthSprite.y = 233;
      mouthSprite.x = 316;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD3Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD3' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    this.POD3Set.addChild(bg);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');
    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD3Set.addChild(patientSprite);
      this.POD3Set.addChild(mouthSprite);
    }
    if (this.pediatric) {
      if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        mouthSprite.y = 305;
        mouthSprite.x = 153;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.22;
      } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
        mouthSprite.y = 196;
        mouthSprite.x = 281;
        patient.y = 130;
        patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.y = 233;
        mouthSprite.x = 9316;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
      } else {
        mouthSprite.y = 110;
        mouthSprite.x = 120;
        patient.y = 130;
        patient.x = 150;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      }
    } else if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 234;
      mouthSprite.x = 335;
    } else if (this.sg['case_id'] === '5b635848fb3bf200198a69e1') {
      mouthSprite.y = 239;
      mouthSprite.x = 352;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.15;
    } else if (this.sg['case_id'] === '5b63479ffb3bf200198a69b1') {
      mouthSprite.y = 234;
      mouthSprite.x = 317.5;
      mouthSprite.scaleY = 0.18;
      mouthSprite.scaleX = 0.15;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD4Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD4' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    this.POD4Set.addChild(bg);
    // // var patient = new createjs.Bitmap(patientimg);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');
    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD4Set.addChild(patientSprite);
      this.POD4Set.addChild(mouthSprite);
    }
    if (this.pediatric) {
      if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        mouthSprite.y = 300;
        mouthSprite.x = 160;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.22;
      } else if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
        mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
        mouthSprite.y = 234;
        mouthSprite.x = 335;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.scaleY = 0.21;
        mouthSprite.scaleX = 0.18;
        mouthSprite.y = 373;
        mouthSprite.x = 4336;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
      } else {
        mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
        mouthSprite.y = 245;
        mouthSprite.x = 330;
        patient.y = 261;
        patient.x = 298;
      }
    }
  }
  POD5Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD5' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    // var patient = new createjs.Bitmap(patientimg);
    this.POD5Set.addChild(bg);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD5Set.addChild(patientSprite);
      this.POD5Set.addChild(mouthSprite);
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;
    if (this.pediatric) {
      if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
        mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
        mouthSprite.y = 234;
        mouthSprite.x = 335;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.scaleY = 0.21;
        mouthSprite.scaleX = 0.18;
        mouthSprite.y = 373;
        mouthSprite.x = 4336;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
      } else {
        mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
        mouthSprite.y = 245;
        mouthSprite.x = 330;
        patient.y = 261;
        patient.x = 298;
      }
    }
  }
  POD6Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD6' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    // var patient = new createjs.Bitmap(patientimg);

    // Add assets to stage
    this.POD6Set.addChild(bg);
    //    this.POD6Set.addChild(patient);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD6Set.addChild(patientSprite);
      this.POD6Set.addChild(mouthSprite);
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;
    if (this.pediatric) {
      mouthSprite.y = 110;
      mouthSprite.x = 120;
      patient.y = 130;
      patient.x = 150;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
    } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
      mouthSprite.y = 233;
      mouthSprite.x = 9316;
      this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD7Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD7' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    // // var patient = new createjs.Bitmap(patientimg);

    // // Add assets to stage
    this.POD7Set.addChild(bg);
    // this.POD2Set.addChild(patient);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD7Set.addChild(patientSprite);
      this.POD7Set.addChild(mouthSprite);
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;

    if (this.pediatric) {
      mouthSprite.y = 110;
      mouthSprite.x = 120;
      patient.y = 130;
      patient.x = 150;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD8Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD8' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    // // var patient = new createjs.Bitmap(patientimg);

    // // Add assets to stage
    this.POD8Set.addChild(bg);
    // this.POD2Set.addChild(patient);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD8Set.addChild(patientSprite);
      this.POD8Set.addChild(mouthSprite);
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;

    if (this.pediatric) {
      mouthSprite.y = 110;
      mouthSprite.x = 120;
      patient.y = 130;
      patient.x = 150;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD9Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD9' + '.png';
    const bg = new createjs.Bitmap(bgimg);
    // // var patient = new createjs.Bitmap(patientimg);

    // // Add assets to stage
    this.POD9Set.addChild(bg);
    // this.POD2Set.addChild(patient);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      // Enrco
      this.POD9Set.addChild(patientSprite);
      this.POD9Set.addChild(mouthSprite);
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;

    if (this.pediatric) {
      mouthSprite.y = 110;
      mouthSprite.x = 120;
      patient.y = 130;
      patient.x = 150;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  POD10Setup() {
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/general-order/patient.png';
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'POD10' + '.png';
    const bg = new createjs.Bitmap(bgimg);

    // // Add assets to stage
    this.POD10Set.addChild(bg);
    // this.POD2Set.addChild(patient);
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    if (this.sg['case_id'] !== '5e6fc729aa23d900117c1da8') {
      this.POD10Set.addChild(patientSprite);
      this.POD10Set.addChild(mouthSprite);
    }

    if (this.pediatric) {
      mouthSprite.y = 110;
      mouthSprite.x = 120;
      patient.y = 130;
      patient.x = 150;
      mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
    } else {
      mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      mouthSprite.y = 245;
      mouthSprite.x = 330;

      // Create Asset Positioning & Scale
      //  this.patient.scaleY = this.patient.scaleX = 0.75;
      patient.y = 261;
      patient.x = 298;
    }
  }
  ancillarySetup() {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'ancillaryCare' + '.png';
    const image = new createjs.Bitmap(bgimg);
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/ancillary/images/patient.png';
    // Create Sprite Sheets
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    // Create Individual Sprites
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;

    // Add assets to stage
    this.ancillarySet.addChild(image);
    this.ancillarySet.addChild(patientSprite);
    this.ancillarySet.addChild(mouthSprite);

    if (this.pediatric) {
      if (this.sg['case_id'] === '5c0819d31ad175000f58d21b') {
        mouthSprite.y = 228;
        mouthSprite.x = 306;
        patientSprite.y = 130;
        patientSprite.x = 150;
        mouthSprite.scaleY = 0.19;
        mouthSprite.scaleX = 0.23;
      } else if (this.sg['case_id'] === '5c0818411ad175000f58d215') {
        mouthSprite.y = 112;
        mouthSprite.x = 110;
        patientSprite.y = 130;
        patientSprite.x = 150;
        mouthSprite.scaleY = 0.24;
        mouthSprite.scaleX = 0.28;
      } else if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        mouthSprite.y = 230;
        mouthSprite.x = 201.5;
        patientSprite.y = 130;
        patientSprite.x = 150;
        mouthSprite.scaleY = 0.2;
        mouthSprite.scaleX = 0.2;
      } else if (this.sg['case_id'] === '5ea36ce9fa90860012efb1c3') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        patientSprite.y = 0;
        patientSprite.x = 3000;
        mouthSprite.scaleY = 0;
        mouthSprite.scaleX = 0;
      } else {
        mouthSprite.y = 201;
        mouthSprite.x = 279;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
        patientSprite.y = 110;
        patientSprite.x = 125;
      }
    } else {
      switch (this.sg['case_id']) {
        case '5e6fc729aa23d900117c1da8':
          mouthSprite.y = 238;
          mouthSprite.x = 320;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
          patientSprite.y = 110;
          patientSprite.x = 125;
          break;
        case '5b6347bafb3bf200198a69b3':
          mouthSprite.y = 235;
          mouthSprite.x = 308;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
          patientSprite.y = 1010;
          patientSprite.x = 125;
          break;
        case '5b635126fb3bf200198a69cf':
          mouthSprite.y = 195;
          mouthSprite.x = 323;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.15;
          break;
        case '5c17f0d39020b900168e58b5':
          mouthSprite.y = 201;
          mouthSprite.x = 333.5;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.17;
          break;
        case '5bf6ff9d12af9b000f3f4c6f':
          mouthSprite.y = 209;
          mouthSprite.x = 333;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
          patientSprite.y = 1010;
          patientSprite.x = 1250;
          break;
        case '5b633eb3fb3bf200198a6991':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5ea36b3bfa90860012efb1c1':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5c100d5170f7c4001650e032':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5b634739fb3bf200198a69ad':
          mouthSprite.y = 194;
          mouthSprite.x = 324;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.2;
          break;
        case '5b63504afb3bf200198a69c1':
          mouthSprite.y = 232;
          mouthSprite.x = 330;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.23;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5b6357bbfb3bf200198a69d7':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5c13e8bd9020b900168e5733':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5bf702ad4bfc93000f15d402':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5bf5ef6e398a60000f9b14c4':
          mouthSprite.y = 0;
          mouthSprite.x = 0;
          mouthSprite.scaleY = mouthSprite.scaleX = 0;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5b3bf91db07ee9000f216354':
          mouthSprite.y = 235;
          mouthSprite.x = 311;
          mouthSprite.scaleY = 0.17;
          mouthSprite.scaleX = 0.15;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5b633f62fb3bf200198a6999':
          mouthSprite.y = 245;
          mouthSprite.x = 327;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.2;
          mouthSprite.skewY = 2;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        case '5b63479ffb3bf200198a69b1':
          mouthSprite.y = 233;
          mouthSprite.x = 306;
          mouthSprite.scaleY = 0.2;
          mouthSprite.scaleX = 0.18;
          mouthSprite.skewY = 2;
          patientSprite.y = 0;
          patientSprite.x = 0;
          patientSprite.scaleY = 0;
          break;
        default:
          mouthSprite.y = 201;
          mouthSprite.x = 279;
          mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
          patientSprite.y = 110;
          patientSprite.x = 125;
      }
    }
  }
  observationSetup() {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'ancillaryCare' + '.png';
    const image = new createjs.Bitmap(bgimg);
    const patientimg = './assets/patients/' + this.sg['case_id'] + '/observation/images/patient.png';
    // Create Sprite Sheets
    const patient = new createjs.SpriteSheet({
      frames: {
        width: 330,
        height: 725,
        numFrames: 4,
        regX: 0,
        regY: 0,
      },
      animations: {
        run: [1, 8, 'sit'],
        sit: [0],
      },
      images: [patientimg],
    });
    // Create Individual Sprites
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Create Sprite Sheets

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: { Idle: ['0'] },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const mouthSprite = new createjs.Sprite(mouth, 'Idle');
    // Add assets to stage
    this.observationSet.addChild(image);
    this.observationSet.addChild(patientSprite);
    this.observationSet.addChild(mouthSprite);

    if (this.pediatric) {
      if (this.sg['case_id'] === '5bfdb527341d8d000f82cf99') {
        mouthSprite.y = 245;
        mouthSprite.x = 330;
        mouthSprite.scaleY = 0.2;
        mouthSprite.scaleX = 0.2;
      } else if (this.sg['case_id'] === '5c0819d31ad175000f58d21b') {
        mouthSprite.y = 225;
        mouthSprite.x = 306;
        mouthSprite.scaleY = 0.26;
        mouthSprite.scaleX = 0.24;
      } else {
        mouthSprite.y = 245;
        mouthSprite.x = 330; //
        mouthSprite.scaleY = 0.2;
        mouthSprite.scaleX = 0.2;
      }
    } else {
      if (this.sg['case_id'] === '5b63585efb3bf200198a69e3') {
        mouthSprite.y = 232;
        mouthSprite.x = 302;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.18;
      } else if (this.sg['case_id'] === '5b634739fb3bf200198a69ad') {
        mouthSprite.y = 194;
        mouthSprite.x = 324;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.2;
      } else if (this.sg['case_id'] === '5b635126fb3bf200198a69cf') {
        mouthSprite.y = 195;
        mouthSprite.x = 323;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.15;
      } else if (this.sg['case_id'] === '5bfc9f7a341d8d000f82cf71') {
        mouthSprite.y = 212;
        mouthSprite.x = 317;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.2;
      } else if (this.sg['case_id'] === '5b6357bbfb3bf200198a69d7') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b6346b4fb3bf200198a69a7') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5bf5ef6e398a60000f9b14c4') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b3bfb8cb07ee9000f21636e') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b3bfd79b07ee9000f216374') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b3bfba3b07ee9000f216370') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b3bf91db07ee9000f216354') {
        mouthSprite.y = 235;
        mouthSprite.x = 311;
        mouthSprite.scaleY = 0.17;
        mouthSprite.scaleX = 0.15;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b3bfd8cb07ee9000f216376') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else if (this.sg['case_id'] === '5b634f30fb3bf200198a69bf') {
        mouthSprite.y = 0;
        mouthSprite.x = 0;
        mouthSprite.scaleY = mouthSprite.scaleX = 0;
        patientSprite.y = 0;
        patientSprite.x = 0;
        patientSprite.scaleY = 0;
      } else {
        mouthSprite.y = 201;
        mouthSprite.x = 279;
        mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
      }
    }

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;
    patientSprite.y = 110;
    patientSprite.x = 125;
  }
  sendHomeSetup() {
    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + 'sendHome1' + '.png';
    const image = new createjs.Bitmap(bgimg);
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';

    // Creating Patient Sprite, either default(Same as HistoryTaking), or different to History Taking.
    let patientImg, patient;
    switch (this.sg['case_id']) {
      case '5bfc9f7a341d8d000f82cf71':
        patientImg = './assets/patients/' + this.sg['case_id'] + '/history/images/patientSendHome.png';
        patient = new createjs.SpriteSheet({
          frames: { width: 330, height: 725, numFrames: 4, regX: 0, regY: 0 },
          animations: { run: [1, 8, 'sit'], sit: [0] },
          images: [patientImg],
        });
        break;
      case '5b634f30fb3bf200198a69bf':
        patientImg = './assets/patients/' + this.sg['case_id'] + '/history/images/patientSendHome.png';
        patient = new createjs.SpriteSheet({
          frames: { width: 330, height: 725, numFrames: 4, regX: 0, regY: 0 },
          animations: { run: [1, 8, 'sit'], sit: [0] },
          images: [patientImg],
        });
        break;
      default:
        patientImg = './assets/patients/' + this.sg['case_id'] + '/sendHome/images/patientSendHome.png';
        patient = new createjs.SpriteSheet({
          frames: { width: 330, height: 725, numFrames: 4, regX: 0, regY: 0 },
          animations: { run: [1, 8, 'sit'], sit: [0] },
          images: [patientImg],
        });
        break;
    }

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: { width: 110, height: 72, regX: 0, regY: 0, count: 12 },
      images: [patientmouth],
    });
    // Create Individual Sprites
    const patientSprite = new createjs.Sprite(patient, 'sit');
    const mouthSprite = new createjs.Sprite(mouth);

    // Add assets to stage
    this.sendHomeSet.addChild(image);
    this.sendHomeSet.addChild(patientSprite);

    this.sendHomeSet.addChild(mouthSprite);

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;

    if (this.pediatric) {
      if (this.sg['case_id'] === '5bfdb527341d8d000f82cf99') {
        patientSprite.y = 110;
        patientSprite.x = 125;
        mouthSprite.y = 210;
        mouthSprite.x = 284;
      } else if (this.sg['case_id'] === '5c0819d31ad175000f58d21b') {
        patientSprite.y = 120;
        patientSprite.x = 125;
        mouthSprite.y = 220;
        mouthSprite.x = 209;
      } else if (this.sg['case_id'] === '5c0816461ad175000f58d20e') {
        patientSprite.y = 120;
        patientSprite.x = 145;
        mouthSprite.y = 110;
        mouthSprite.x = 120;
      } else if (this.sg['case_id'] === '5c0715573c024f000f745ff4') {
        patientSprite.y = 140;
        patientSprite.x = 150;
        mouthSprite.y = 240;
        mouthSprite.x = 309;
      } else if (this.sg['case_id'] === '5c0818c31ad175000f58d217') {
        patientSprite.y = 140;
        patientSprite.x = 150;
        mouthSprite.y = 242;
        mouthSprite.x = 241;
      } else if (this.sg['case_id'] === '5c081b791ad175000f58d221') {
        patientSprite.y = 140;
        patientSprite.x = 150;
        mouthSprite.y = 110;
        mouthSprite.x = 120;
      } else if (this.sg['case_id'] === '5c0818411ad175000f58d215') {
        patientSprite.y = 140;
        patientSprite.x = 150;
        mouthSprite.y = 240;
        mouthSprite.x = 295;
      } else if (this.sg['case_id'] === '5c13e2d59020b900168e5731') {
        patientSprite.y = 125;
        patientSprite.x = 140;
        mouthSprite.y = 215;
        mouthSprite.x = 295;
      } else if (this.sg['case_id'] === '5c17f1209020b900168e58ba') {
        patientSprite.y = 0;
        patientSprite.x = 110;
        mouthSprite.y = 185;
        mouthSprite.x = 310;
      } else if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
        patientSprite.y = 90;
        patientSprite.x = 150;
        mouthSprite.y = 190;
        mouthSprite.x = 299;
      } else if (this.sg['case_id'] === '5c081def1ad175000f58d22a') {
        patientSprite.y = 130;
        patientSprite.x = 160;
        mouthSprite.y = 221;
        mouthSprite.x = 314;
      } else if (this.sg['case_id'] === '5c08197e1ad175000f58d219') {
        patientSprite.y = 130;
        patientSprite.x = 160;
        mouthSprite.y = 221;
        mouthSprite.x = 314;
      } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
        patientSprite.y = 130;
        patientSprite.x = 160;
        mouthSprite.y = 221;
        mouthSprite.x = 314;
      } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
        mouthSprite.scaleY = 0.21;
        mouthSprite.scaleX = 0.18;
        mouthSprite.y = 373;
        mouthSprite.x = 4336;
        this.mouthSprite.scaleY = this.mouthSprite.scaleX = 0.15;
      }
    } else if (this.sg['case_id'] === '5bfc9f7a341d8d000f82cf71') {
      patientSprite.y = 130;
      patientSprite.x = 160;
      mouthSprite.y = 221;
      mouthSprite.x = 298;
    } else {
      patientSprite.y = 110;
      patientSprite.x = 125;
      mouthSprite.y = 201;
      mouthSprite.x = 279;
    }
    mouthSprite.scaleY = mouthSprite.scaleX = 0.28;
  }
  inquirySetup() {
    this.patientId = this.sg['case_id'];
    // USE ACTION INQUIRY IF YOU WANT THE REGULAR GENERAL ORDERS BACKGROUND

    const bgimg = './assets/patients/' + this.sg['case_id'] + '/room/' + this.sg['instance_id'] + '.png';
    const patientmouth = './assets/patients/' + this.sg['case_id'] + '/history/images/mouth.png';
    const image = new createjs.Bitmap(bgimg);

    const mouth = new createjs.SpriteSheet({
      framerate: 12,
      frames: {
        width: 110,
        height: 72,
        regX: 0,
        regY: 0,
        count: 12,
      },
      animations: this.inquiryAnimations,
      images: [patientmouth],
    });
    // Create Individual Sprites
    this.inquiryMouthSprite = new createjs.Sprite(mouth, 'Idle');

    // Add assets to stage
    this.inquiryMouthSprite.scaleX = 0.18;
    this.inquirySet.addChild(image);

    // Create Asset Positioning & Scale
    // this.patientSprite.scaleY = this.patientSprite.scaleX = 0.21;
    if (this.pediatric) {
      const patientimg = './assets/patients/' + this.sg['case_id'] + '/history/images/patient.png';

      const patient = new createjs.SpriteSheet({
        frames: { width: 330, height: 725, numFrames: 4, regX: 24000, regY: 0 },
        animations: { run: [1, 8, 'sit'], sit: [0] },
        images: [patientimg],
      });

      const patientSprite = new createjs.Sprite(patient, 'sit');
      this.inquirySet.addChild(patientSprite);
      if (this.pediatric) {
        if (this.sg['case_id'] === '5bfdb527341d8d000f82cf99') {
          this.inquiryMouthSprite.y = 245;
          this.inquiryMouthSprite.x = 330;
          patientSprite.y = 130;
          patientSprite.x = 150;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.2;
        } else if (this.sg['case_id'] === '5c0819d31ad175000f58d21b') {
          this.inquiryMouthSprite.y = 225;
          this.inquiryMouthSprite.x = 305;
          patientSprite.y = 130;
          patientSprite.x = 150;
          this.inquiryMouthSprite.scaleY = 0.23;
          this.inquiryMouthSprite.scaleX = 0.24;
        } else if (this.sg['case_id'] === '5c0818411ad175000f58d215') {
          //
          this.inquiryMouthSprite.y = 112;
          this.inquiryMouthSprite.x = 119;
          patientSprite.y = 119;
          patientSprite.x = 112;
          this.inquiryMouthSprite.scaleY = 0.28;
          this.inquiryMouthSprite.scaleX = 0.28;
        } else if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
          if (this.sg['instance_id'] === 'POD4') {
            this.inquiryMouthSprite.y = 300;
            this.inquiryMouthSprite.x = 159;
            this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.2;
          } else {
            this.inquiryMouthSprite.y = 306;
            this.inquiryMouthSprite.x = 155;
            patientSprite.y = 119;
            patientSprite.x = 112;
            this.inquiryMouthSprite.scaleY = 0.2;
            this.inquiryMouthSprite.scaleX = 0.2;
          }
        } else if (this.sg['case_id'] === '5c17f16e9020b900168e58bd') {
          this.inquiryMouthSprite.y = 196;
          this.inquiryMouthSprite.x = 281;
          patientSprite.y = 119;
          patientSprite.x = 112;
          this.inquiryMouthSprite.scaleY = 0.28;
          this.inquiryMouthSprite.scaleX = 0.28;
        } else if (this.sg['case_id'] === '5c17f09a9020b900168e58b3') {
          this.inquiryMouthSprite.y = 246;
          this.inquiryMouthSprite.x = 331;
          patientSprite.y = 119;
          patientSprite.x = 112;
          this.inquiryMouthSprite.scaleY = 0.15;
          this.inquiryMouthSprite.scaleX = 0.15;
        }
      } else {
        this.inquiryMouthSprite.y = 110;
        this.inquiryMouthSprite.x = 120;
        patientSprite.y = 130;
        patientSprite.x = 150;
        this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.28;
      }
    } else {
      if (
        this.sg['instance_id'] === 'office1' ||
        this.sg['instance_id'] === 'office2' ||
        this.sg['instance_id'] === 'office3'
      ) {
        const patientimg = './assets/patients/' + this.sg['case_id'] + '/history/images/patient.png';
        // Create Sprite Sheets
        const patient = new createjs.SpriteSheet({
          frames: {
            width: 330,
            height: 725,
            numFrames: 4,
            regX: 0,
            regY: 0,
          },
          animations: {
            run: [1, 8, 'sit'],
            sit: [0],
          },
          images: [patientimg],
        });
        const patientSprite = new createjs.Sprite(patient, 'sit');
        patientSprite.y = 110;
        patientSprite.x = 125;
        this.inquiryMouthSprite.y = 201;
        this.inquiryMouthSprite.x = 279;
        this.inquirySet.addChild(patientSprite);
        this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.28;
        if (this.sg['case_id'] === '5c081c131ad175000f58d223') {
          patientSprite.y = 110;
          patientSprite.x = 1000;
          this.inquiryMouthSprite.y = 127;
          this.inquiryMouthSprite.x = 296.5;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
        }
      } else {
        if (this.sg['case_id'] === '5c0817431ad175000f58d210') {
          this.inquiryMouthSprite.y = 306;
          this.inquiryMouthSprite.x = 155;
          this.patientSprite.y = 119;
          this.patientSprite.x = 112;
          this.inquiryMouthSprite.scaleY = 0.2;
          this.inquiryMouthSprite.scaleX = 0.2;
        } else if (this.sg['case_id'] === '5b63585efb3bf200198a69e3') {
          this.inquiryMouthSprite.y = 232;
          this.inquiryMouthSprite.x = 303;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
        } else if (this.sg['case_id'] === '5e6fc729aa23d900117c1da8') {
          this.inquiryMouthSprite.y = 238;
          this.inquiryMouthSprite.x = 320;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
        } else if (this.sg['case_id'] === '5b635848fb3bf200198a69e1') {
          this.inquiryMouthSprite.y = 239;
          this.inquiryMouthSprite.x = 352;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.15;
        } else if (this.sg['case_id'] === '5b63479ffb3bf200198a69b1') {
          this.inquiryMouthSprite.y = 234;
          this.inquiryMouthSprite.x = 317.5;
          this.inquiryMouthSprite.scaleY = 0.18;
          this.inquiryMouthSprite.scaleX = 0.15;
        } else if (this.sg['case_id'] === '5bf6ff9d12af9b000f3f4c6f') {
          if (this.sg['instance_id'] === 'POD2') {
            this.inquiryMouthSprite.y = 214;
            this.inquiryMouthSprite.x = 350;
            this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
          } else if (
            this.sg['instance_id'] === 'POD3' ||
            this.sg['instance_id'] === 'POD4' ||
            this.sg['instance_id'] === 'POD5'
          ) {
            this.inquiryMouthSprite.y = 235;
            this.inquiryMouthSprite.x = 335;
            this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
          }
        } else if (this.sg['case_id'] === '5b6347bafb3bf200198a69b3') {
          if (this.sg['instance_id'] === 'POD1' || this.sg['instance_id'] === 'POD2') {
            this.inquiryMouthSprite.y = 235;
            this.inquiryMouthSprite.x = 308;
            this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
          } else if (this.sg['instance_id'] === 'POD3') {
            this.inquiryMouthSprite.y = 243;
            this.inquiryMouthSprite.x = 330;
            this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
          }
        } else if (this.sg['case_id'] === '5ea36b3bfa90860012efb1c1') {
          this.inquiryMouthSprite.y = 0;
          this.inquiryMouthSprite.x = 0;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.0;
        } else {
          // Default inquiry set. (If you need to edit the patient independantly an if else statement)
          this.inquiryMouthSprite.y = 245;
          this.inquiryMouthSprite.x = 329;
          this.inquiryMouthSprite.scaleY = this.inquiryMouthSprite.scaleX = 0.18;
        }
      }
    }

    this.inquirySet.addChild(this.inquiryMouthSprite);
    this.stage.addChild(this.inquirySet);
    this.stage.update();
  }
  curtainSetup() {
    const image = './assets/default/physical/Props/curtain.png';

    this.curtain = new createjs.Bitmap(image);
    this.curtain.y = 0;
    this.curtain.x = -600;
    this.curtain.visible = true;
    this.curtainTween = createjs.Tween.get(this.curtain, {
      loop: false,
      paused: true,
    })
      .to({ x: 0 }, 500)
      .call(this.addPhex, this, this)
      .wait(3000)
      .to({ x: -600 }, 500)
      .call(this.startPhex, this, this);
    this.curtainTween.setPaused(true);
  }
  addPhex(this) {
    this.stage.addChild(this.physicalExamSet);
    this.stage.removeChild(this.currentStage);

    this.stage.update();
    this.stage.setChildIndex(this.physicalExamSet, this.stage.getNumChildren() - 1);
    this.stage.setChildIndex(this.curtain, this.stage.getNumChildren() - 1);
    this.stage.update();
  }
  startPhex(this) {
    if (!this.stopPhex) {
      this.stageName = 'physicalExam';
      this.stage.setChildIndex(this.physicalExamSet, this.stage.getNumChildren() - 1);
      this.stage.removeChild(this.curtain);

      const audio = 'physicalExam';
      this.soundManager(audio);
    }
  }
  action(name, step = undefined, text = undefined, header = undefined) {
    createjs.Sound.stop();
    // Remove Everything
    if (!this.hotSet && name !== 'history') {
      if (this.STAGEFLAG) {
        this.actionName = name;
        setTimeout(this.readySet(null, null, false), 2000);
      }
    } else if (this.hotSet && name !== 'physicalExam') {
      this.stage.removeAllChildren();
      this.newSet(name, step, text, header);
    } else {
      this.newSet(name, step, text, header);
    }
  }
  soundManager(sound) {
    // Ensures that no loading sounds play at the same time / overlap.
    if (this.videoPlayer) {
      this.videoPlayer.pause();
    }
    createjs.Sound.stop();
    createjs.Sound.play(sound);
  }

  newSet(name, step = undefined, text = undefined, header = undefined) {
    this.communicationComponent = null;
    if (this.loader.active) {
      this.loader.stop();
    }
    if (this.stageName === 'physicalExam' || this.stageName === 'initialPhysicalExam') {
      this.stopPhex = true;
    }
    this.stageName = name;
    this.patientService.stageName = name;
    //  createjs.Ticker.removeAllEventListeners("tick");

    this.stage.update();
    this.pointer = '';
    this.specialSrc = undefined;
    this.showSpecial = false;
    if (this.videoPlayer) {
      this.videoPlayer.pause();
    }
    switch (name) {
      case 'history': {
        this.currentStage = this.historySet;
        this.stage.addChild(this.historySet);
        break;
      }
      case 'proDiagnosisCom':
      case 'procedureComOne':
      case 'procedureComTwo':
      case 'procedureVideo':
      case 'investigationEPA':
      case 'communicationCrisis':
      case 'communicationSbar':
      case 'comRecommendations':
        this.currentStage = this.communicationSet;
        this.communicationComponent = name;
        this.communicationSetup(name, step);
        if (step === undefined || step === null) {
          this.soundManager(name);
        }
        this.stage.addChild(this.communicationSet);
        break;

      case 'mNmRounds':
        this.currentStage = this.mnmSet;
        this.soundManager(name);
        this.mnmSetup(step);
        this.stage.addChild(this.mnmSet);
        break;

      case 'mNmRoundsPresentation':
        this.currentStage = this.mnmPresentationSet;
        this.mnmPresentationSetUp(text, header);
        if (step !== undefined && step !== null) {
          this.soundManager(name);
        }
        this.stage.addChild(this.mnmPresentationSet);
        break;

      case 'rpo':
        this.rpoCounter++;
        this.currentStage = this.supervisorSet;
        this.stage.addChild(this.supervisorSet);
        if (this.rpoCounter === 1) {
          createjs.Sound.play('rpo');
        }
        break;
      case 'epaCrisisQuestion':
      case 'epaProcedureQuestion':
      case 'ancillary':
      case 'provisionalDiagnosis':
      case 'finalDiagnosis':
      case 'invasiveManagement':
      case 'hospitalManagement':
      case 'patientManagement':
      case 'hospitalSetting':
      case 'surgeryPlan':
      case 'managementPlan':
      case 'procedures':
        this.currentStage = this.supervisorSet;
        this.stage.addChild(this.supervisorSet);
        this.soundManager(name);
        this.doctorMouthSprite.gotoAndPlay(name);
        break;
      case 'investigations':
        this.currentStage = this.nurseSet;
        this.stage.addChild(this.nurseSet);
        this.soundManager(name);
        this.nurseMouthSprite.gotoAndPlay(name);
        break;
      case 'physicalExam':
        if (this.sg['instance_id'] === 'initialAssessment') {
          this.physicalSetup(true);
        } else if (this.sg['instance_id'] === 'POD1' || this.sg['instance_id'] === 'preOp') {
          this.stage.addChild(this.POD1Set);
          this.physicalSetup(true);
          this.showSpecial = false;
        } else if (this.sg['instance_id'] === 'POD2') {
          this.stage.addChild(this.POD2Set);
          this.physicalSetup(true);
          this.showSpecial = false;
        } else if (this.sg['instance_id'] === 'POD3') {
          this.stage.addChild(this.POD3Set);
          this.physicalSetup(true);
          this.showSpecial = false;
        } else if (this.sg['instance_id'] === 'POD4') {
          this.physicalSetup(true);
          this.showSpecial = false;
        } else if (this.sg['instance_id'] === 'POD5') {
          this.physicalSetup(true);
          this.showSpecial = false;
        } else if (this.sg['instance_id'] === 'POD6') {
          this.stage.addChild(this.POD6Set);
          this.physicalSetup(true);
        } else if (this.sg['instance_id'] === 'POD7') {
          this.stage.addChild(this.POD7Set);
          this.physicalSetup(true);
        } else if (this.sg['instance_id'] === 'POD8') {
          this.stage.addChild(this.POD8Set);
          this.physicalSetup(true);
        } else if (this.sg['instance_id'] === 'POD9') {
          this.stage.addChild(this.POD9Set);
          this.physicalSetup(true);
        } else if (this.sg['instance_id'] === 'POD10') {
          this.stage.addChild(this.POD10Set);
          this.physicalSetup(true);
        } else if (
          this.sg['instance_id'] === 'observation1' ||
          this.sg['instance_id'] === 'observation2' ||
          this.sg['instance_id'] === 'observation3' ||
          this.sg['instance_id'] === 'office1' ||
          this.sg['instance_id'] === 'office2' ||
          this.sg['instance_id'] === 'office3'
        ) {
          this.physicalSetup(true);
        }
        break;
      case 'inquiry':
        if (
          this.sg['instance_id'] === 'office1' ||
          this.sg['instance_id'] === 'office2' ||
          this.sg['instance_id'] === 'office3'
        ) {
          this.currentStage = this.inquirySet;
          this.stage.addChild(this.inquirySet);
        } else {
          this.currentStage = this.POD1Set;
          this.stage.addChild(this.POD1Set);
        }
        break;
      case 'generalOrder':
      case 'input':
      case 'consultation':
      case 'complications':
        if (this.sg['instance_id'] === 'POD1' || this.sg['instance_id'] === 'preOp') {
          this.currentStage = this.POD1Set;
          this.stage.addChild(this.POD1Set);
        } else if (this.sg['instance_id'] === 'POD2') {
          this.currentStage = this.POD2Set;
          this.stage.addChild(this.POD2Set);
        } else if (this.sg['instance_id'] === 'POD3') {
          this.currentStage = this.POD3Set;
          this.stage.addChild(this.POD3Set);
        } else if (this.sg['instance_id'] === 'POD4') {
          this.currentStage = this.POD4Set;
          this.stage.addChild(this.POD4Set);
        } else if (this.sg['instance_id'] === 'POD5') {
          this.currentStage = this.POD5Set;
          this.stage.addChild(this.POD5Set);
        } else if (this.sg['instance_id'] === 'POD6') {
          this.currentStage = this.POD6Set;
          this.stage.addChild(this.POD6Set);
        } else if (this.sg['instance_id'] === 'POD7') {
          this.currentStage = this.POD7Set;
          this.stage.addChild(this.POD7Set);
        } else if (this.sg['instance_id'] === 'POD8') {
          this.currentStage = this.POD8Set;
          this.stage.addChild(this.POD8Set);
        } else if (this.sg['instance_id'] === 'POD9') {
          this.currentStage = this.POD9Set;
          this.stage.addChild(this.POD9Set);
        } else if (this.sg['instance_id'] === 'POD10') {
          this.currentStage = this.POD10Set;
          this.stage.addChild(this.POD10Set);
        } else if (
          this.sg['instance_id'] === 'observation1' ||
          this.sg['instance_id'] === 'observation2' ||
          this.sg['instance_id'] === 'observation3'
        ) {
          this.currentStage = this.observationSet;
          this.stage.addChild(this.observationSet);
        } else if (this.sg['instance_id'] === 'ancillary' || this.sg['instance_id'] === 'ancillaryCare') {
          this.currentStage = this.ancillarySet;
          this.stage.addChild(this.ancillarySet);
        }
        break;
      case 'sendHome':
      case 'referrals':
      case 'followUp':
      case 'followUpInquiry':
      case 'sendHomeOrders':
      case 'counselling':
        // this.stage.removeChild(this.communicationSet);
        this.stage.update();
        this.currentStage = this.sendHomeSet;
        this.stage.addChild(this.sendHomeSet);
        break;
      case 'supervisor':
        this.currentStage = this.supervisorSet;
        this.stage.addChild(this.supervisorSet);
        break;
    }

    // this.stage.updateCache();
  }
  cut() {
    this.stage.removeAllChildren();
  }

  tearDown(x) {
    if (x === 'history') {
      // createjs.Sound.removeSounds( this.patientHistory.manifest, this.patientHistory.path  );
      // createjs.Sound.removeSounds(  this.doctorHistory.manifest,   this.doctorHistory.path   );
    }
  }
}
