<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
  <div class="pop-up mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
            <p style="text-align: center; display: block; margin: 0 auto">Help - Frequently Asked Questions</p>
          </div>
        </div>
        <div
          *ngIf="
            showHelp != 'Patient History' &&
            showHelp != 'Physical Exam' &&
            showHelp != 'Operations' &&
            showHelp != 'Investigations' &&
            showHelp != 'Consultations' &&
            showHelp != 'Provisional Diagnosis' &&
            showHelp != 'Final Diagnosis' &&
            showHelp != 'General Order'
          "
          class="help-section"
        >
          <div *ngIf="showHelp == 'Medications'">
            <h5>Prescribing Medications | Tutorial:</h5>
            <video autoplay muted plays-inline controls="true">
              <source src="assets/video/medications.mp4" type="video/mp4" />
            </video>
          </div>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  My cases are too difficult/ they aren’t letting me play all the way through, why?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Did you set your Skillset Level? There are four levels at which you can play CyberPatient. Make sure
                you’ve set your Skillset Level in the “Patient Cases” panel of the Dashboard. For more information,
                please read the News post on “Skillset Levels”, or see the Skillset section of the Navigation Guide.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I submitted something by accident, can I go back and change it? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No, unfortunately the cases in CyberPatient simulate real life! If you submit something, you cannot go
                back and change it unless you restart the case. Take extra care when hitting submit!
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> How can I exit and save this case? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                The system automatically saves your place! You can hit the home button, or simply close the web browser,
                and your patient will be waiting right where you left them.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> My patient is acting strangely. What’s happening? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Sometimes a quick refresh will help get your patient back to normal.<br />
                Please try refreshing your browser window in order to restore order to your account, if the issue
                persists, please contact us at info@cyberpatient.ca.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can I start a new case and come back to my current case later? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No! If you leave your patient to start assisting a new one, your previous case will be closed. You can
                always start another case with that patient at a later time, however.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showHelp == 'Patient History'" class="help-section">
          <h5>Taking a History | Tutorial:</h5>
          <video autoplay muted plays-inline controls="true">
            <source src="assets/video/history.mp4" type="video/mp4" />
          </video>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> All the text is jumbled and out of place? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Some screens need a bit extra room to have CyberPatient looking its best. Try maximizing your browser
                window or zooming out slightly.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What if I accidentally submitted something? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                If you submit an answer it is saved in our system and cannot be undone, so be careful! You can always
                exit and restart your case to start fresh, if you need to.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What if I just ask all the questions on the list? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                There may be a few sneaky questions available to ask that are not appropriate for each patient. <br />
                So ask carefully! Think about the relevance of the question before you select it ask.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Where can I record the patient info I’ve been collecting? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>There is a patient chart for your convenience available within CyberPatient.</p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can I skip this section? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                You can, but if you do we will mark you 0 for it! Each section will be scored in your feedback at the
                end.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showHelp == 'Physical Exam'" class="help-section">
          <h5>Conducting a Physical Exam | Tutorial:</h5>
          <video autoplay muted plays-inline controls="true">
            <source src="assets/video/phex.mp4" type="video/mp4" />
          </video>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Why can’t I use certain tools? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                You will need to zoom in on your patient to properly examine them. Try zooming in on the head, hands,
                torso, and feet. Some tools only work when zoomed into a specific area.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I can’t get the stethoscope to work? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Are you zoomed into the chest, and have the stethoscope button activated? The heart and lung sounds are
                realistically mapped, so try clicking and holding on different areas in order to produce the result you
                desire. Click and hold to use the tool. If you have tried all of the above and still cannot find breath
                sounds, please reach out at <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a>
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> How can I turn the patient around? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>The navigation tools can help you zoom, rotate and ask your patient to lie down or sit up.</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showHelp == 'Investigations'" class="help-section">
          <h5>Conducting an Investigation | Tutorial:</h5>
          <video autoplay muted plays-inline controls="true">
            <source src="assets/video/labTests.mp4" type="video/mp4" />
          </video>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Why does it say my test is invalid? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Due to the number of tests available in CyberPatient, you will only receive results for tests which are
                relevant to your patient’s chief complaint. If you think your test might be relevant, try selecting the
                most cost-efficient or effective method of imaging for that location. If you have any further questions
                about invalid tests, please contact us at <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a>
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I can’t figure out what category the test I need is under? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Try out the search bar! It can be found under the tab labeled "Search Tests".</p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can I select as many tests as I want? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Yes! But be careful - we’re marking you on Cost too! Each test is going to add to the total cost of care
                for your patient!
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="showHelp == 'Provisional Diagnosis'" class="help-section">
          <h5>Provisional Diagnosis | Tutorial:</h5>
          <video autoplay muted plays-inline controls="true">
            <source src="assets/video/provisionalDiagnosis.mp4" type="video/mp4" />
          </video>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  My cases are too difficult, they aren’t letting me play all the way through, why?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Did you set your Skillset Level? There are four levels at which you can play CyberPatient. Make sure
                you’ve set your Skillset Level in the “Patient Cases” panel of the Dashboard. For more information,
                please read the News post on “Skillset Levels”, or see the Skillset section of the Navigation Guide.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I submitted something by accident, can I go back and change it? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No, unfortunately the cases in CyberPatient simulate real life! If you submit something, you cannot go
                back and change it unless you restart the case. Take extra care when hitting submit!
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> How can I exit and save this case? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                The system automatically saves your place! You can hit the home button, or simply close the web browser,
                and your patient will be waiting right where you left them.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> My patient is acting strangely. What’s happening? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Sometimes a quick refresh will help get your patient back to normal.<br />
                Please try refreshing your browser window in order to restore order to your account, if the issue
                persists, please contact us at info@cyberpatient.ca.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can I start a new case and come back to my current case later? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No! If you leave your patient to start assisting a new one, your previous case will be closed. You can
                always start another case with that patient at a later time, however.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="showHelp == 'Final Diagnosis'" class="help-section">
          <h5>Final Diagnosis | Tutorial:</h5>
          <video autoplay muted plays-inline controls="true">
            <source src="assets/video/finalDiagnosis.mp4" type="video/mp4" />
          </video>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  My cases are too difficult, they aren’t letting me play all the way through, why?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Did you set your Skillset Level? There are four levels at which you can play CyberPatient. Make sure
                you’ve set your Skillset Level in the “Patient Cases” panel of the Dashboard. For more information,
                please read the News post on “Skillset Levels”, or see the Skillset section of the Navigation Guide.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I submitted something by accident, can I go back and change it? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No, unfortunately the cases in CyberPatient simulate real life! If you submit something, you cannot go
                back and change it unless you restart the case. Take extra care when hitting submit!
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> How can I exit and save this case? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                The system automatically saves your place! You can hit the home button, or simply close the web browser,
                and your patient will be waiting right where you left them.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> My patient is acting strangely. What’s happening? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Sometimes a quick refresh will help get your patient back to normal.<br />
                Please try refreshing your browser window in order to restore order to your account, if the issue
                persists, please contact us at info@cyberpatient.ca.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can I start a new case and come back to my current case later? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                No! If you leave your patient to start assisting a new one, your previous case will be closed. You can
                always start another case with that patient at a later time, however.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="showHelp == 'Operations'" class="help-section">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> I can’t find the operation I’m looking for? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                The search is sensitive to spelling, so make sure you have spelled it correctly. Click on the correct
                suggestion in the drop down box in order to select it.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showHelp == 'Consultations'" class="help-section">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What do consultations refer to? </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Consultations are based on your treatment plan for your patient. Consider which professional opinions
                and assistance you may need to complete your treatment plan for you patient, and begin typing.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showHelp == 'General Order'" class="help-section">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  I selected an order and then the system logged an error and turned the bar red, what now?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                If you change your mind before submitting a general order or make a mistake, it will trigger an
                incorrect answer response. You will still be able to click it and try again even after it has turned
                red.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="guide">
    Can't find the answer you're looking for?<br />
    Reach out to us at <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a>, or try downloading our
    <a href="/assets/img/app/CyberPatient-UserGuide.pdf" download class="user-guide">User Guide</a>.
  </div>
</mat-dialog-content>
