import { Location } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CaseRow, student } from 'app/_models/teacher-dashboard.model';
import { SetSelectedCase } from 'app/actions/teacherdashboard.action';
import { SimpleGlobal } from 'ng2-simple-global';
import { caseData } from '../../_models/caseAttempts.model';
import { SetCurrentCase } from '../../actions/checkpoint.action';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/_services';

@Component({
  selector: 'app-course-completed-cases',
  templateUrl: './course-completed-cases.component.html',
  styleUrls: ['./course-completed-cases.component.scss'],
})
export class CourseCompletedCasesComponent implements OnChanges {
  @Input() data: caseData[];
  @Input() nursing = false;
  @Input() access;
  scoreDataSource = new MatTableDataSource<any>();
  mystudent: student;
  showFeedback = false;
  err;
  @ViewChild('MatTable', { static: true }) table: MatTable<any>;
  noData;
  loading: boolean;
  feedbackcolumns = ['casehistory', 'time', 'score', 'comments', 'action'];

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private sg: SimpleGlobal,
    private _location: Location,
    private authService: AuthenticationService
  ) {}

  addFormativeComment(e: CaseRow) {
    this.store.dispatch([new SetSelectedCase(e)]);
    const path = '/teacher-dashboard/editformativeassessment';
    this.router.navigate([path], { skipLocationChange: false, state: { summativestudent: e } });
  }

  caseScore(e) {
    if (e.caseType === 'SIMULATION') {
      const token = this.authService.getToken();

      const v3Url = `${environment.v3Url}/verifyLms/feedback?sessionId=${window.encodeURIComponent(
        e.session_id
      )}&token=${window.encodeURIComponent(token)}`;

      if (v3Url && token) {
        window.open(v3Url, '_blank');
      }
    } else {
      this.store.dispatch([new SetSelectedCase(e)]);
      const session_id = e.session_id;
      this.sg['getFeedback'] = session_id;
      const url = this.nursing ? 'nursing-feedback' : 'feedback';
      this.store.dispatch([new SetCurrentCase(session_id)]).subscribe(() => {
        this.router.navigate(['/gradesOverview', url], {
          queryParams: {
            session_id,
            case_id: e.case_id,
          },
        });
      });
    }
  }

  popUpAttempts() {}

  getBadge(element) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      if (changes['data']) {
        if (this.data.length > 0 && this.data[0].caseType === 'SIMULATION') {
          this.showFeedback = true;
        } else {
          this.showFeedback = false;
        }
        this.scoreDataSource.data = this.data;
      }
    }, 100);
  }
}
