import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { DemoOptions, Patient } from '../_models/index';


export interface MyID {
  id: string;
}

@Injectable()
export class FetchCaseService {
  public currentUser;
  private urlCaseLibrary = '/api/v1/patients/search';
  private urlCaseGET = '/api/v1/patients/demographics?case_id=mongo_id_here';
  private urlDemographicsOptions = '/api/v1/demographics';
  private urlCreatePatient = '/api/v1/patients/demographics';



sharingID: MyID = {id: 'idnotsetyet'};

  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.urlCaseLibrary = this.sg['url'].concat(this.urlCaseLibrary);
    this.urlCaseGET = this.sg['url'].concat(this.urlCaseGET);
    this.urlDemographicsOptions = this.sg['url'].concat(this.urlDemographicsOptions);
    this.urlCreatePatient = this.sg['url'].concat(this.urlCreatePatient);
  }



// Method to get all the cases existing in the case library
  getAllCaseLibrary(): Observable<Patient[]> {
    return this.http.get(this.urlCaseLibrary).pipe(
      map(mapPatients),
      catchError(this.handleError));

  }


// Method to get all the options for the demographics - 'hair, eyes, race etc'
  getDemoOptions(): Observable<DemoOptions> {
    return this.http.get(this.urlDemographicsOptions).pipe(
      map(mapOptions),
      catchError(this.handleError));

  }

// To catch and handle any error scenarios and provide an error message
  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }



// Method to create a record using data provided by the user
  addCase(patient_name: string,
    patient_age: string,
    patient_height: number,
    patient_weight: number,
    patient_gender: string,
    patient_race: string,
    patient_hair: string,
    patient_eyes: string,
    patient_address: string,
    patient_contact: string,
    patient_date_of_birth: string,
    patient_emergency_contact: string): Observable<any> {

    const body = {
      'patient_name': patient_name,
      'patient_age': patient_age,
      'patient_height': patient_height,
      'patient_weight': patient_weight,
      'patient_gender': patient_gender,
      'patient_race': patient_race,
      'patient_hair': patient_hair,
      'patient_eyes': patient_eyes,
      'patient_address': patient_address,
      'patient_contact': patient_contact,
      'patient_date_of_birth': patient_date_of_birth,
      'patient_emergency_contact': patient_emergency_contact,
      'creator_email': this.currentUser.username
    };

    return this.http.post(this.urlCreatePatient, body).pipe(
      map((response: any) => {
        if (response.message && response.message.case_id !== null) {
          return response.message.case_id;
        } else {
          return null;
        }
      }));
  }

}



// function to take all patients and individually map each patient
function mapPatients(response: any): Patient[] {
  return response.message.map(toPatient);
}

// function to map all options for each demographic feature
function mapOptions(response: any): DemoOptions {
  const demo = <DemoOptions>({
    Race: response.message.race,
    Hair: response.message.hair,
    Eyes: response.message.eyes
  });
  return demo;
}



// function to map all the fields for each patient
function toPatient(r: any): Patient {
  return <Patient>({
    creator_username: r.creator_username,
    last_modified: r.last_modified,
    patient_age: r.patient_age,
    patient_eyes: r.patient_eyes,
    patient_gender: r.patient_gender,
    patient_hair: r.patient_hair,
    patient_height: r.patient_height,
    patient_name: r.patient_name,
    patient_race: r.patient_race,
    patient_weight: r.patient_weight,
    patient_number: r.patient_number,
    patient_address: r.patient_address,
    patient_contact: r.patient_contact,
    patient_date_of_birth: r.patient_date_of_birth,
    patient_emergency_contact: r.patient_emergency_contact
  });
}
