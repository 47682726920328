import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { SaveAssignmentDataService } from '../_services/index';
import { ErrorMsgService } from '../error-msg.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  providers: [SaveAssignmentDataService],
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  comment;
  loadingAndSaving = false;

  constructor(private dialogRef: MatDialogRef<CommentsComponent>, private SaveAssignmentDataService: SaveAssignmentDataService, private router: Router, private sg: SimpleGlobal, public errorService: ErrorMsgService) {
  }

  ngOnInit() {

  }

  submitComment() {
    const body = {
      'url': this.router.url,
      'session_id': this.sg['session_id'],
      'instance': this.sg['instance_id'],
      'component': this.sg['pageTitle'],
      'body': this.comment
    };
    this.loadingAndSaving = true;

    this.SaveAssignmentDataService.submitFeedbackComment(body).subscribe(result => {
      this.loadingAndSaving = false;
      if (result === null) {
        this.errorService.openSnack(null, 'Feedback Submittion Failed', 'commenterror');
      } else {
        this.dialogRef.close();
        this.errorService.openSnack(null, 'Thank you for your feedback', 'commentsuccess');
        // SARAH - ADD DIALOG FOR SUCCESSFULLY SUBMITTED


      }
    }, err => {
      this.loadingAndSaving = false;
    });

  }
}
