import {
  Inject,
  Injectable
} from '@angular/core';
import {
  PatientService
} from './patient.service';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class PalpationService {

  currentPalpation = null;
  stage;
  handCursor;
  soundInstances = [];
  palpationTweens = [];
  container = new createjs.Container();
  isTweenOn = false;
  palpation;
  audioSprite = [{
    id: "palpation.hurtWhenTouch",
    src: "./assets/default/doctor/audio/hurts.mp3"
  }]
  palpationhand1 = "./assets/default/physical/Props/Palpations/hand-palp.png";
  palpationhand2 = "./assets/default/physical/Props/Palpations/shape-6.png";
  palpationhand3 = "./assets/default/physical/Props/Palpations/shape-9.png";
  palpationhand4 = "./assets/default/physical/Props/Palpations/shape-13.png";
  palpationhand5 = "./assets/default/physical/Props/Palpations/shape-14.png";
  auscult;
  superficialHand;
  deepHandContainer;
  deepHand;
  deepHandMask;
  percussionHand1;
  percussionHand2;
  percussionHandContainer;
  auscultationContainer;
  auscultation;


  getContainer() {
    return this.container;
  };


  init() {
    this.currentPalpation = null;
    var that = this;
    var auscFlag = false;
    this.soundInstances = [];
    this.palpationTweens = [];
    this.container = new createjs.Container();
    this.isTweenOn = false;
    this.palpation = undefined;
    this.auscult = undefined;
    var handDown = function (e) {
      this.offset = {
        x: this.x - (e.stageX / 600),
        y: this.y - (e.stageY / 560)
      };
    };

    var handUp = function (e) {

      var tween = that.palpationTweens[that.currentPalpation];
      if (tween != null) {
        that.isTweenOn = true;
        tween.setPosition(0);
        tween.paused = false;
      }

      that.patientService.checkPalpation(that.currentPalpation, e.stageX, e.stageY);
    };

    var handDowna = function (e) {
      //     this.offset = { x: this.x - (e.stageX / this.getStage().scaleX), y: this.y - (e.stageY / this.getStage().scaleY) };
      that.patientService.stopPalpationSound();
    };

    var handUpa = function (e) {

      that.patientService.stopPalpationSound();
      var tween = that.palpationTweens[that.currentPalpation];
      if (tween != null) {
        self = this;
        setTimeout(function () {

          that.isTweenOn = true;
          tween.setPosition(0);
          tween.paused = false;
        }, 2000)
      }

      var xx;
      var yy;

      if (e.stageX === undefined) {
        xx = e.layerX - 100;
        yy = e.layerY;
      } else {
        xx = e.stageX;
        yy = e.stageY;
      }

      that.patientService.checkAuscultation(that.currentPalpation, xx, yy);


    };

    var removeCheck = function (e) {
      //  that.document.removeEventListener("mousemove", handUpa);
      handDowna(e);
    }

    var endTween = function (e) {
      that.isTweenOn = false;
    };

    createjs.Sound.registerSounds(this.audioSprite);


    this.superficialHand = new createjs.Bitmap(this.palpationhand1);
    // superficialHand.scaleX = superficialHand.scaleY = 0.8;
    this.superficialHand.name = 'superficialpalpation';
    createjs.Tween.removeTweens(this.superficialHand);
    this.palpationTweens['superficialpalpation'] = createjs.Tween.get(this.superficialHand, {
      paused: true,
      overide: true
    })
      .to({
        rotation: -5
      }, 300, createjs.Ease.circOut)
      .wait(200)
      .to({
        rotation: 0
      }, 150, createjs.Ease.circOut)
      .call(endTween);
    this.superficialHand.visible = false;
    this.superficialHand.on('mousedown', handDown);
    this.superficialHand.on('pressup', handUp);
    this.container.addChild(this.superficialHand);
    this.deepHandContainer = new createjs.Container();
    this.deepHandContainer.name = 'deeppalpation';
    this.deepHandContainer.visible = false;
    this.deepHand = new createjs.Bitmap(this.palpationhand1);
    // deepHand.scaleX = deepHand.scaleY = 0.8;
    this.deepHandMask = new createjs.Shape();
    this.deepHandMask.graphics.beginFill('#000').moveTo(-20, 0).lineTo(200, 0).lineTo(200, 250).lineTo(40, 250).lineTo(-20, 0).endFill();
    // deepHandContainer.addChild(deepHandMask);
    this.deepHand.mask = this.deepHandMask;
    this.palpationTweens['deeppalpation'] = createjs.Tween.get(this.deepHand, {
      paused: true,
      override: true
    })
      .to({
        x: -20,
        y: -0
      }, 200)
      .wait(250)
      .to({
        x: 0,
        y: 0
      }, 100)
      .call(endTween);
    this.deepHandContainer.addChild(this.deepHand);
    this.deepHandContainer.on('mousedown', handDown);
    this.deepHandContainer.on('pressup', handUp);
    this.container.addChild(this.deepHandContainer);

    this.percussionHandContainer = new createjs.Container();
    this.percussionHandContainer.name = 'percussion';
    this.percussionHand1 = new createjs.Bitmap(this.palpationhand4);
    this.percussionHand2 = new createjs.Bitmap(this.palpationhand5);
    this.percussionHand2.regX = 50;
    this.percussionHand2.regY = 61;
    this.percussionHand2.x = 60;
    this.percussionHand2.y = 61;
    this.palpationTweens['percussion'] = createjs.Tween.get(this.percussionHand2, {
      paused: true,
      override: true
    })
      .wait(100)
      .to({
        rotation: 20
      }, 150, createjs.Ease.circOut)
      .wait(0)
      .to({
        rotation: 0
      }, 100, createjs.Ease.circOut)
      .wait(50)
      .to({
        rotation: 20
      }, 150, createjs.Ease.circOut)
      .wait(0)
      .to({
        rotation: 0
      }, 100, createjs.Ease.circOut)
      .wait(50)
      .call(endTween);
    this.percussionHandContainer.addChild(this.percussionHand1);
    this.percussionHandContainer.addChild(this.percussionHand2);
    this.percussionHandContainer.scaleX = this.percussionHandContainer.scaleY = 1.3;
    this.percussionHandContainer.visible = false;
    this.percussionHandContainer.on('mousedown', handDown);
    this.percussionHandContainer.on('pressup', handUp);
    this.container.addChild(this.percussionHandContainer);

    this.auscultationContainer = new createjs.Container();
    this.auscultationContainer.name = 'auscultation';
    this.auscultationContainer.visible = false;
    this.auscultation = new createjs.Bitmap(this.palpationhand3);
    this.auscultation.scaleX = this.auscultation.scaleY = 1.3;
    this.auscultationContainer.addChild(this.auscultation);
    this.auscultation.x = -2;
    this.auscultation.y = 2;
    this.palpationTweens['auscultation'] = createjs.Tween.get(this.auscultation, {
      paused: true
    })
      .to({
        x: 0,
        y: 0,
        scaleX: 1.25,
        scaleY: 1.25
      }, 300, createjs.Ease.circOut)
      .call(endTween);
    this.palpationTweens['auscultationEnd'] = createjs.Tween.get(this.auscultation, {
      paused: true
    })
      .to({
        x: 0,
        y: 0,
        visible: true,
        scaleX: 1.3,
        scaleY: 1.3
      }, 300, createjs.Ease.circOut);
    this.auscult = this.auscultationContainer

    that.auscult.addEventListener("mousedown", function (e) {
      var event = e;
      handUpa(e);
      /*
      auscFlag = true;
      that.document.addEventListener("mousemove", handUpa); */
      that.document.addEventListener("mouseup", removeCheck);
    });




    this.container.addChild(this.auscultationContainer);

    this.container.visible = false;
  };

  startPalpation(type, stage, persp, zoom) {
    if (this.currentPalpation != null && this.currentPalpation != type) {
      // console.log("Hit that strange use case? Palp Service");
      this.container.getChildByName(this.currentPalpation).visible = false;
    }
    this.currentPalpation = type;
    this.container.visible = true;
    var hand = this.container.getChildByName(type);






    hand.x = 100;
    hand.y = 150;

    if (type === 'auscultation') {
      // console.log("ausculation");

      if (persp === "Front") {
        // console.log("inside front persp");
        //  hand.scaleX = 0.75;
        //    hand.scaleY = 0.75;

      }


      if (persp === "Down") {
        // console.log("inside back persp");
        //        hand.scaleX = 0.9;
        //       hand.scaleY = 0.9;

      }

    } else {


      if (persp === "Front") {
        // console.log("inside front persp");
        //      hand.scaleX = 0.6;
        //      hand.scaleY = 0.6;
      }


      if (persp === "Down") {
        // console.log("inside back persp");
        //        hand.scaleX = 1.05;
        //      hand.scaleY = 1.05;
      }


    }

    hand.visible = true;
    var that = this;

    this.stage = stage;
    this.handCursor = setInterval(this.handleClick.bind(this, stage, hand, type), 50);
    //  createjs.Ticker.addEventListener("tick", this.handleClick.bind(this, stage, hand, type));

  };
  handleClick(stage, hand, type) {
    var difX = stage.mouseX - hand.x;
    var difY = stage.mouseY - hand.y;
    if (type === 'percussion') {
      //   hand.x += difX/2 - 75;
      //  hand.y += difY/2 - 20;
      hand.x += difX / 2 - 120;
      hand.y += difY / 2 - 35;
    } else if (type === 'auscultation') {
      //  hand.x += difX/2 - 100;
      // hand.y += difY/2 - 10;

      hand.x += difX / 2 - 130;
      hand.y += difY / 2 - 5;
    } else {
      //hand.x += difX/2 - 5;
      // hand.y += difY/2 - 40;

      hand.x += difX / 2 - 6;
      hand.y += difY / 2 - 32;


    }
    stage.update();
  }
  stopPalpation() {
    var that = this;
    clearInterval(this.handCursor);
    if (this.container.getChildByName(this.currentPalpation) != null) {
      this.container.getChildByName(this.currentPalpation).visible = false;
    }
  }

  reset() {
    createjs.Sound.removeSound(this.audioSprite);
    this.currentPalpation = null;
    this.stage = undefined;
    this.soundInstances = [];
    this.palpationTweens = [];
    this.container = new createjs.Container();
    this.isTweenOn = false;
    this.palpation = undefined;
    this.container.removeAllChildren();
    this.palpation = undefined;
    this.container.removeAllChildren();
    this.auscult = undefined;
  };


  constructor(private patientService: PatientService, @Inject(DOCUMENT) private document) { }

}
