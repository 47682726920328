import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../_services';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.css'],
})
export class OnboardingDialogComponent {
  constructor(private readonly dialogRef: MatDialogRef<boolean>, private readonly userService: UserService) {}

  get tutorialFlow(): 'professor' | 'admin' {
    return this.userService.user.institutionRole === 'professor' ? 'professor' : 'admin';
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
