<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Success!</h1>
        </div>
        <div class="form-subheader flex">
          <div class="sent-icon">
            <img src="assets/img/app/login/SentEmailIcon.png" />
          </div>
        </div>
        <p class="description">
          If there is an account associated with your email then you will recieve an email with instructions on how to
          reset your password shortly.
        </p>
        <p class="description">
          If you don't recieve your email within a few minutes, please contact
          <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a> for support.
        </p>
        <p class="description">
          <!--  <a  routerLink='/recover' routerLinkActive="active" class="forgot-password"
            >Need to make a new Account?</a
          > -->
        </p>
        <p class="copyright">© 2022 All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
