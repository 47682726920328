import { Injectable } from '@angular/core';
import {
  SimpleGlobal
} from 'ng2-simple-global';
@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {

  height = 92.5;
  weight = 1;
  textTween = null;
  heightTextTween = null;
  weightTextTween = null;
  pediatric = false;
  container = new createjs.Container();
  growth;
  pediatricTween;
  constructor(private sg: SimpleGlobal) {
  }
  getContainer() {
    return this.container;
  };

  init(height, weight, pediatric) {
    this.pediatric = pediatric;
    this.heightTextTween = null;
    this.weightTextTween = null;
    this.textTween = null;
    this.container = new createjs.Container();

    this.height = height;
    this.weight = weight;


    var textContainer = new createjs.Container();
    textContainer.visible = false;

    var tBackground = new createjs.Shape();
    tBackground.graphics.beginFill('#0f77b4')
      .drawRoundRect(0, 0, 320, 150, 10)
      .endFill()
      .beginFill('#138fd8')
      .drawRoundRect(10, 10, 300, 130, 10);
    var hText = new createjs.Text(("Height: " + this.height + " cm"), "42px Arial", "#000");
    var wText = new createjs.Text(("Weight: " + this.weight + " kg"), "42px Arial", "#000");
    hText.x = 15;
    hText.y = 20;
    wText.x = 15;
    wText.y = 90;
    if (this.pediatric) {
      var growthImage =
        "./assets/patients/" +
        this.sg["case_id"] +
        "/physical-exam/images/" + this.sg["instance_id"] + "/growth.jpg";

      this.growth = new createjs.Bitmap(growthImage);
      this.growth.x = 100;
      this.growth.y = 650;
      this.container.addChild(this.growth);
      this.growth.visible = false;

      this.pediatricTween = createjs.Tween.get(this.growth, { paused: true })
        .to({ visible: false, x: 300, y: 650, alpha: 1, scaleX: 0.1, scaleY: 0.1 }, 300)
        .to({ visible: true }, 100)
        .to({ x: 100, y: 200, scaleX: 1, scaleY: 1 }, 800, createjs.Ease.circOut)
        .wait(4000)
        .to({ alpha: 0, visible: false }, 1000, createjs.Ease.circOut)
    }
    textContainer.addChild(tBackground);
    textContainer.addChild(hText);
    textContainer.addChild(wText);
    this.container.addChild(textContainer);
    this.textTween = createjs.Tween.get(textContainer, { paused: true })
      .to({ visible: false, x: -50, y: -50, alpha: 1, scaleX: 0.1, scaleY: 0.1 }, 300)
      .to({ visible: true }, 100)
      .to({ x: 10, y: 10, scaleX: 1, scaleY: 1 }, 800, createjs.Ease.circOut)
      .wait(4000)
      .to({ alpha: 0, visible: false }, 1000, createjs.Ease.circOut)
  };
  showGraph() {
    this.growth.visible = true;
  }
  hideGraph() {
    this.growth.visible = false;
  }
  show() {
    this.textTween.setPosition(0);
    this.textTween.setPaused(false);

    if (this.pediatric) {
      this.pediatricTween.setPosition(0);
      this.pediatricTween.setPaused(false);
    }
  };

  Hide() {
    this.textTween.setPaused(true)
      .setPosition(0);

    if (this.pediatric) {
      this.pediatricTween.setPaused(true)
        .setPosition(0);
    }
  };
}
