import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SetCaseId } from '../actions/checkpoint.action';

@Injectable({
  providedIn: 'root'
})
export class SaveTOSService {
  caseid = null;

  private urlAcceptTOS = '/api/v1/provisional/tosaccept';

  constructor(private store: Store, private http: HttpClient, private sg: SimpleGlobal) {
    this.urlAcceptTOS = this.sg['url'].concat(this.urlAcceptTOS);
  }

  acceptTosPP() {
    return this.http.post(this.urlAcceptTOS, {}).pipe(
      map((response: any) => {
        if (response.token != null) {
          this.sg['session_id'] = null;
          this.sg['case_id'] = null;
          this.store.dispatch([new SetCaseId(null)]);
          return response;
        } else { return null; }
      }),
      catchError(this.handleError),
    );
  }


  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
