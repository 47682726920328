import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TutorialSequence, TutorialsService } from '../tutorials.service';

@Directive({
  selector: '[appTutorialStep]'
})
export class TutorialStepDirective implements OnInit, OnDestroy {
  @Input() appTutorialStep: string;
  @Input() appTutorialStepOrder: number;
  @Input() appTutorialStepContext: string;
  @Input() appTutorialStepUrl?: string;

  private tutorialContainer: HTMLElement;
  private tutorialSequence: TutorialSequence;
  private tutorialSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private tutorialsService: TutorialsService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.getTutorialSequence().then((sequence) => {
      this.tutorialSubscription = sequence.change.subscribe(
        (order) => {
          if (order === this.appTutorialStepOrder) {
            this.showTutorial();
          } else {
            this.hideTutorial();
          }
        },
      );
    });
  }

  ngOnDestroy() {
    this.hideTutorial();
    this.tutorialSubscription?.unsubscribe();
  }

  private async getTutorialSequence(): Promise<TutorialSequence> {
    this.tutorialSequence = this.tutorialsService.tutorialOf(this.appTutorialStepContext);
    if (this.tutorialSequence) {
      return this.tutorialSequence;
    } else {
      await this.wait(100);
      return this.getTutorialSequence();
    }
  }

  private createTutorial() {
    this.tutorialContainer = this.renderer.createElement('div');
    this.renderer.appendChild(
      this.tutorialContainer,
      this.createIcon(),
    );
    this.renderer.appendChild(
      this.tutorialContainer,
      this.createText(),
    );
    this.renderer.appendChild(
      this.tutorialContainer,
      this.createButtons(),
    );

    this.renderer.setAttribute(
      this.tutorialContainer,
      'id',
      `tutorial-${this.appTutorialStepContext}-${this.appTutorialStepOrder}`
    );
    this.renderer.addClass(this.tutorialContainer, 'position-absolute');
    this.renderer.addClass(this.tutorialContainer, 'align-items-center');
    this.renderer.addClass(this.tutorialContainer, 'bg-color-secondary');
    this.renderer.addClass(this.tutorialContainer, 'text-color-white');
    this.renderer.addClass(this.tutorialContainer, 'px-5');
    this.renderer.addClass(this.tutorialContainer, 'py-3');
    this.renderer.addClass(this.tutorialContainer, 'rounded');
    this.renderer.addClass(this.tutorialContainer, 'shadow');
    this.renderer.addClass(this.tutorialContainer, 'fade');
    this.renderer.setStyle(this.tutorialContainer, 'max-width', '500px');
    this.renderer.setStyle(this.tutorialContainer, 'z-index', '1');

    this.renderer.appendChild(document.body, this.tutorialContainer);
  }

  private createIcon(): HTMLElement {
    const icon = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'position-absolute');
    this.renderer.addClass(icon, 'fa');
    this.renderer.addClass(icon, 'fas');
    this.renderer.addClass(icon, 'text-color-secondary');
    this.renderer.setStyle(icon, 'font-size', '3rem');
    this.renderer.setStyle(icon, 'top', '50%');
    this.renderer.setStyle(icon, 'transform', 'translateY(-50%)');

    if (this.windowSide === 'left') {
      this.renderer.addClass(icon, 'fa-caret-left');
      this.renderer.setStyle(icon, 'left', '-16px');
    } else {
      this.renderer.addClass(icon, 'fa-caret-right');
      this.renderer.setStyle(icon, 'right', '-16px');
    }

    return icon;
  }

  private createText(): HTMLElement {
    const text = this.renderer.createElement('div');
    this.renderer.appendChild(
      text,
      this.renderer.createText(this.appTutorialStep),
    );
    this.renderer.addClass(text, 'font-weight-bold');

    return text;
  }

  private createButtons(): HTMLElement {
    const container = this.renderer.createElement('div');
    this.renderer.addClass(container, 'd-flex');
    this.renderer.addClass(container, 'w-100');
    this.renderer.addClass(container, 'flex-row-reverse');
    this.renderer.addClass(container, 'mt-2');
    this.renderer.addClass(container, 'justify-content-between');
    this.renderer.addClass(container, 'align-items-center');

    const nextBtn = this.renderer.createElement('button');
    this.renderer.appendChild(
      nextBtn,
      this.renderer.createText('Got it!'),
    );
    this.renderer.addClass(nextBtn, 'text-color-white');
    this.renderer.addClass(nextBtn, 'mat-raised-button');
    this.renderer.addClass(nextBtn, 'font-weight-bold');
    this.renderer.setStyle(nextBtn, 'background-color', '#625273');
    this.renderer.listen(nextBtn, 'click', () => {
      this.tutorialSequence.next();
    });

    this.renderer.appendChild(
      container,
      nextBtn,
    );

    if (this.appTutorialStepUrl) {
      const learnMoreBtn = this.renderer.createElement('a');
      this.renderer.appendChild(
        learnMoreBtn,
        this.renderer.createText('Learn more'),
      );
      this.renderer.setAttribute(learnMoreBtn, 'href', this.appTutorialStepUrl);
      this.renderer.setAttribute(learnMoreBtn, 'target', '_blank');
      this.renderer.addClass(learnMoreBtn, 'text-color-white');
      this.renderer.appendChild(
        container,
        learnMoreBtn,
      );
    }

    return container;
  }

  private setTutorialPosition() {
    const top = this.y + this.height / 2 - this.tutorialContainer.offsetHeight / 2;
    const left = this.windowSide === 'left' ? this.x + this.width + 24 : this.x - this.tutorialContainer.offsetWidth - 24;
    this.renderer.setStyle(this.tutorialContainer, 'top', top + 'px');
    this.renderer.setStyle(this.tutorialContainer, 'left', left + 'px');
  }

  private showTutorial() {
    this.createTutorial();
    this.setTutorialPosition();
    setTimeout(() => {
      this.renderer.addClass(this.tutorialContainer, 'show');
      this.highlightElement();
      this.scrollToElementIfNeeded();
    });
  }

  private hideTutorial() {
    if (!this.tutorialContainer) {
      return;
    }

    this.renderer.removeClass(this.tutorialContainer, 'show');
    setTimeout(() => {
      this.renderer.removeChild(document.body, this.tutorialContainer);
      this.tutorialContainer = null;
      this.dimElement();
    });
  }

  private highlightElement() {
    this.zIndex = this.zIndex + 1;
  }

  private dimElement() {
    this.renderer.removeStyle(this.el.nativeElement, 'z-index');
  }

  private scrollToElementIfNeeded() {
    if (!this.isInViewPort) {
      window.scroll({
        top: this.y - 200,
        left: 0,
        behavior : 'smooth'
      });
    }
  }

  private async wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private set zIndex(zIndex: number) {
    this.el.nativeElement.style.zIndex = zIndex;
  }

  private get zIndex() {
    return this.el.nativeElement.style.zIndex ?? 0;
  }

  private get x() {
    return this.el.nativeElement.getBoundingClientRect().x;
  }

  private get y() {
    return window.pageYOffset + this.el.nativeElement.getBoundingClientRect().y;
  }

  private get height(): number {
    return this.el.nativeElement.offsetHeight;
  }

  private get width(): number {
    return this.el.nativeElement.offsetWidth;
  }

  private get centerX(): number {
    return this.x + this.width / 2;
  }

  private get isInViewPort(): boolean {
    const rect = this.el.nativeElement.getBoundingClientRect()
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  }

  private get windowSide(): 'left' | 'right' {
    const windowCenterX = window.innerWidth / 2;
    if (this.centerX < windowCenterX) {
      return 'left';
    } else {
      return 'right';
    }
  }
}
