import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralDialogData } from '@canhealth/cyberpatient-ui';

export type ShareUrlDialogData = GeneralDialogData & {
  shareUrl: string;
}

@Component({
  selector: 'app-share-url-dialog',
  templateUrl: './share-url-dialog.component.html',
  styleUrls: ['./share-url-dialog.component.css']
})
export class ShareUrlDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: ShareUrlDialogData,
    private readonly snackbar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
  }

  informCopied() {
    this.snackbar.open('Link copied to clipboard', '', {
      duration: 3000,
    });
  }
}
