import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Course } from '../interfaces/course';
import { FacultyMember } from '../interfaces/faculty-member';
import { GradesOverview } from '../interfaces/grades-overview';

@Injectable()
export class StudentsService {
  constructor(private readonly http: HttpClient) {}

  getStudent(id: string): Observable<FacultyMember> {
    return this.http.get<FacultyMember>(environment.apiUrl.concat('/api/v1/students/').concat(id));
  }

  getCourses(id: string): Observable<Course[]> {
    return this.http.get<Course[]>(environment.apiUrl.concat('/api/v1/students/').concat(id).concat('/courses'));
  }

  getGradesOverview(id: string, courseId?: string): Observable<GradesOverview> {
    let url = environment.apiUrl.concat('/api/v1/students/').concat(id).concat('/grades-overview');
    if (courseId) {
      url = url.concat(`?courseId=${courseId}`);
    }
    return this.http.get<GradesOverview>(url);
  }

  getStudentPerformance(id: string, courseId?: string): Observable<any> {
    const url = environment.apiUrl.concat(
      `/api/v1/students/${id}/student-performance${courseId && `?courseId=${courseId}`}`
    );
    return this.http.get<GradesOverview>(url);
  }
}
