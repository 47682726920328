import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { Subscription } from 'rxjs';
import { filter, last, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SummativeFeedback } from '../_models/teacher-dashboard.model';
import { UserService } from '../_services';
import { SetCurrentCourse } from '../actions/teacherdashboard.action';
import { DebugService } from '../debug.service';
import { Course } from '../lms/interfaces/course';
import { FacultyMember } from '../lms/interfaces/faculty-member';
import { InviteStudentDialogComponent } from '../lms/shared-components/invite-student-dialog/invite-student-dialog.component';
import { CaseLibraryOnboardingDialogComponent } from '../lms/shared-components/onboarding-dialog/case-library-onboarding-dialog/case-library-onboarding-dialog.component';
import { OnboardingDialogComponent } from '../lms/shared-components/onboarding-dialog/onboarding-dialog.component';
import { SidebarService } from '../theme/sidebar/sidebar.service';
import { TutorialSequence, TutorialsService } from '../tutorials/tutorials.service';
import { SummativeAssessmentComponent } from './summative-assessment/summative-assessment.component';
import { TeacherDashboardDataService } from './teacher-dashboard-data.service';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss'],
})
export class TeacherDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('classhoverOver', { static: true }) classhoverOver: TemplateRef<any>;
  @ViewChild('studentSelector', { static: true }) studentSelector: MatSelect;
  selectedStatus = 'active';
  loadingAndSaving = false;
  classDataSource: MatTableDataSource<Course>;
  todayDate: Date = new Date();
  classList: Course[] = [];
  myCourse: Course;
  classtablecolumns = ['status', 'name', 'startDate'];
  studentList = [];
  selectedClass: number;
  filteredList: FacultyMember[] = [];
  selectedCourseId: string;
  summative: SummativeFeedback;
  debug = false;
  tutorialContext = 'teach';

  private tutorialSequence: TutorialSequence;
  private tutorialSubscription: Subscription;

  constructor(
    private debugService: DebugService,
    public dialog: MatDialog,
    private sg: SimpleGlobal,
    private store: Store,
    private router: Router,
    private sidebarService: SidebarService,
    private tutorialService: TutorialsService,
    private userService: UserService,
    private teacherData: TeacherDashboardDataService
  ) {
    this.sg['pageTitle'] = null;
    this.sg['instance_name'] = 'Feedback & Results';
  }

  ngOnInit() {
    this.loadingAndSaving = true;
    if (environment.debug) {
      this.debugService.updateShowHideDebug(this.debug);
    }
    this.teacherData.getClassList().subscribe((courses) => {
      this.classList = courses;
      this.classDataSource = new MatTableDataSource(this.classList);
      this.classDataSource.paginator = this.paginator1;
      this.classDataSource.sort = this.sort;

      this.selectedCourseId = courses[0]?._id;
      this.setClass();
      this.updateStatus();
      this.loadingAndSaving = false;
    });
  }

  ngAfterViewInit() {
    this.userService.userChange
      .pipe(
        filter((user) => user !== undefined && user !== null),
        filter((user) => !user.onboarding.dashboard),
        take(1)
      )
      .subscribe(() => {
        this.startTutorial();
      });
  }

  ngOnDestroy() {
    this.tutorialSequence?.stop();
    this.tutorialSubscription?.unsubscribe();
  }
  isCourseOver = (dt: string): boolean => {
    return new Date(dt).getTime() < new Date().getTime();
  };

  updateStatus() {
    this.classDataSource.data = this.classList.filter((course) => {
      const isOver = this.isCourseOver(course.end);
      const isUpcoming = this.isCourseUpcoming(course.start);
      const isArchive = course.archived;
      switch (this.selectedStatus) {
        case 'inactive':
          return isOver && !isArchive;
        case 'upcoming':
          return isUpcoming && !isArchive;
        case 'archived':
          return isArchive;
        default:
          return !isOver && !isUpcoming && !isArchive;
      }
    });
  }

  setClass() {
    this.myCourse = this.classList.find((x) => x._id === this.selectedCourseId);
    this.filteredList = this.myCourse?.students ?? [];
    this.studentList = this.myCourse?.students ?? [];
  }

  checkDate(dt: string) {
    return Date.parse(this.todayDate.toDateString()) > Date.parse(dt);
  }

  isCourseUpcoming = (dt: string): boolean => {
    return new Date(dt).getTime() > new Date().getTime();
  };

  onPageChange($event) {
    this.filteredList = this.studentList
      .filter((x) => x.classid === this.selectedClass)
      .slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  getBadge(e) {
    return e.hasSummative ? 1 : null;
  }

  showSummativeTooltip() {
    return this.checkDate(this.myCourse.end.toString())
      ? 'Summative Assement'
      : 'Course is still active, cannot assess';
  }

  formativeAssessment(e) {
    this.router.navigate(['/lms', 'students', e.memberId], {
      skipLocationChange: false,
      queryParams: {
        course: this.selectedCourseId,
      },
    });
  }

  showCreateClass() {
    this.router.navigate(['/lms', 'course', 'create'], { skipLocationChange: false });
  }

  gotoClassdashboard(e) {
    this.store.dispatch([new SetCurrentCourse(e)]).subscribe(() => {
      this.router.navigate(['/lms/course', e._id]);
    });
  }

  summativeAssessment(e: FacultyMember) {
    this.router.navigate(['/lms', 'students', e.memberId, 'summative'], {
      skipLocationChange: false,
      queryParams: {
        course: this.selectedCourseId,
      },
    });
  }

  addCases(e) {
    this.store.dispatch([new SetCurrentCourse(e)]);
    const path = '/teacher-dashboard/addcases';
    this.router.navigate([path, e._id], { skipLocationChange: false });
  }

  showAssessment(student: FacultyMember) {
    this.teacherData.getCurrentStudentSummativeAssessment(this.myCourse._id, student.memberId).subscribe((s) => {
      this.summative = s[s.length - 1];
      this.dialog.open(SummativeAssessmentComponent, {
        data: { assessment: this.summative, myCourse: this.myCourse, mystudent: student },
        height: '600px',
        width: '450px',
      });
    });
  }

  openImportDialog() {
    this.dialog.open(InviteStudentDialogComponent);
  }

  navigateToCovid() {
    this.router.navigate(['case-library'], {
      fragment: 'Respirology',
    });
  }

  startTutorial(): void {
    this.tutorialSubscription = this.dialog
      .open(OnboardingDialogComponent)
      .afterClosed()
      .pipe(
        tap((value) => !value && this.finishOnboarding()),
        filter(Boolean),
        mergeMap(() => {
          this.tutorialSequence = this.tutorialService.start(this.tutorialContext, 3);
          return this.tutorialSequence.changeUntilLimit;
        }),
        last(),
        mergeMap(() => this.dialog.open(CaseLibraryOnboardingDialogComponent).afterClosed()),
        tap(() => this.finishOnboarding()),
        filter(Boolean)
      )
      .subscribe(() => {
        this.sidebarService.currentRoute = '/case-library';
      });
  }

  private finishOnboarding() {
    this.userService.updateOnboarding('dashboard').subscribe();
  }
}
