import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { FetchAssignmentDataService } from '../_services/index';

@Component({
  selector: 'app-news',
  providers: [FetchAssignmentDataService],
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  newsfeed;
  loadingAndSaving = false;
  constructor(private fetchAssignmentDataService: FetchAssignmentDataService) { }

  ngOnInit() {
    this.getNewsFeedData();

  }
  getNewsFeedData() {
    this.loadingAndSaving = true;
    this.fetchAssignmentDataService.getNewsFeed().subscribe(newsfeed => {
      this.loadingAndSaving = false;
      this.newsfeed = newsfeed;
    }, err => {
      this.loadingAndSaving = false;
    });
  }

  getDate(news) {
    return moment(news.createdAt).format('MMM Do YYYY');
  }
}
