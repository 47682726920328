import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { mergeMap, tap } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-auth0-callback',
  templateUrl: './auth0-callback.component.html',
  styleUrls: ['./auth0-callback.component.css'],
})
export class Auth0CallbackComponent implements OnInit {
  public isAuth0CallbackLoading = true;
  auth0User;
  user;

  constructor(private readonly auth0Service: AuthService, private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.handleAuth0Callback();

  }

  handleAuth0Login() {
    this.router.navigate(['/login'], {
      skipLocationChange: true
    });
  }

  handleAuth0Callback() {
    this.auth0Service
      .handleRedirectCallback()
      .pipe(
        tap((result) => {
          console.log('Callback result', result);
        }),
        mergeMap(() => this.auth0Service.user$)
      )
      .subscribe((user) => {
        this.isAuth0CallbackLoading = false;
        this.auth0User = user;
        localStorage.setItem('currentAuth0User', JSON.stringify(this.auth0User));
        this.handleAuth0Login();
      });
  }
}
