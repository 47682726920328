import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AgreementPromptComponent } from '../agreement-prompt/agreement-prompt.component';
import { UserService } from './user.service';
import { AuthService } from '@auth0/auth0-angular';

const HTTP_DEFAULT_OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public token: string;
  public permissions: string;
  currentUser;
  epaloginurl;
  private url;
  private http: HttpClient;

  constructor(
    private sg: SimpleGlobal,
    private handler: HttpBackend,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private readonly auth0Service: AuthService
  ) {
    this.url = environment.apiUrl.concat('/api/v1/auth/login');
    this.sg['epaurl'] = environment.epaApiUrl;
    this.epaloginurl = this.sg['epaurl'].concat('/auth/login');
    this.http = new HttpClient(handler);
  }

  init() {
    if (this.getToken()) {
      this.userService.getCurrentUser().subscribe(
        () => {
          return;
        },
        () => {
          this.logout();
        }
      );
    }
  }

  loginAuth0(auth0User) {
    const body = { user: auth0User };
    const url = environment.apiUrl.concat('/api/v1/auth/login/auth0');
    return this.http.post(url, body, HTTP_DEFAULT_OPTIONS).pipe(
      map((response: any) => {
        const token = response.token;
        const permissions = response.permissions;
        const occupation = response.occupation;
        const institutionPermissions = response.institution_permissions;
        const skillset = response.skillset;
        if (token) {
          this.token = token;
          this.sg['membership_status'] = response.membership;
          this.sg['username'] = auth0User.email;
          localStorage.setItem(
            'currentUser',
            JSON.stringify({
              username: auth0User.email,
              token: token,
              role: permissions,
              occupation: occupation,
              institutionPermissions,
              skillset: skillset,
            })
          );
          this.userService.getCurrentUser().subscribe();
          this.sg['session_id'] = null;
          this.sg['case_id'] = null;

          this.router.navigate(['/case-library'], {
            skipLocationChange: true,
          });
        } else {
          return null;
        }
      })
    );
  }

  login(username: string, password: string): Observable<string> {
    const body = { email: username, pwd: password };
    this.sg['username'] = username;
    this.sg['epa'] = true;
    return this.http.post(this.url, body, HTTP_DEFAULT_OPTIONS).pipe(
      mergeMap((response: any) => {
        if (response.message === 'User needs to accept new PolicyUpdate') {
          this.sg['tosToken'] = response.token;
          this.dialog.open(AgreementPromptComponent, {
            data: false,
            disableClose: true,
          });
        } else {
          const token = response.token;
          const permissions = response.permissions;
          const occupation = response.occupation;
          const institutionPermissions = response.institution_permissions;
          const skillset = response.skillset;
          if (token) {
            this.token = token;
            this.sg['membership_status'] = response.membership;

            if (this.sg['epa'] === true) {
              this.epalogin(username, password);
            }
            localStorage.setItem(
              'currentUser',
              JSON.stringify({
                username: username,
                token: token,
                role: permissions,
                occupation: occupation,
                institutionPermissions,
                skillset: skillset,
              })
            );
            return this.userService.getCurrentUser().pipe(
              map(() => {
                this.sg['session_id'] = null;
                this.sg['case_id'] = null;

                return permissions;
              })
            );
          } else {
            return of(null);
          }
        }
      })
    );
  }

  epalogin(username: string, password: string): Observable<boolean> {
    const body = { email: username, pwd: password };
    return this.http.post(this.epaloginurl, body, HTTP_DEFAULT_OPTIONS).pipe(
      map((response: any) => {
        if (response.message === 'User needs to accept new PolicyUpdate') {
          this.sg['epaToken'] = response.token;
        } else {
          const token = response.token;
          const permissions = response.user.permissions;
          const occupation = response.user.occupation;
          if (token) {
            this.token = token;
            localStorage.setItem(
              'currentEpaUser',
              JSON.stringify({
                username: username,
                token: token,
                occupation: occupation,
              })
            );
            return permissions;
          } else {
            return null;
          }
        }
      }),
      catchError(this.handleError)
    );
  }

  resendActivationEmail(email: string): Observable<any> {
    const url = environment.apiUrl.concat('/api/v1/auth/resend');
    const body = { email };
    return this.http.post(url, body).pipe(catchError(this.handleError));
  }

  getToken(): string | null {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user && user.token;
  }

  logout(redirectUrl?: string) {
    this.userService.cleanUserState();
    localStorage.removeItem('currentUser');
    if (localStorage.getItem('currentAuth0User')) {
      localStorage.removeItem('currentAuth0User');
      this.auth0Service.logout();
    }
    localStorage.removeItem('currentEpaUser');
    this.router.navigate(['/login'], {
      queryParams: {
        ...(redirectUrl && {
          redirectUrl: encodeURIComponent(redirectUrl),
        }),
      },
    });
  }

  logoutRedirect(redirectUrl: string) {
    this.userService.cleanUserState();
    localStorage.removeItem('currentUser');
    if (localStorage.getItem('currentAuth0User')) {
      localStorage.removeItem('currentAuth0User');
      this.auth0Service.logout();
    }
    localStorage.removeItem('currentEpaUser');
    this.router.navigate([redirectUrl]);
  }

  activateConfirmAccount(token, body) {
    return this.http.post(environment.apiUrl.concat('/api/v1/auth/register/confirm?token=').concat(token), body).pipe(
      map((response: any) => {
        if (response.message === 'Account activated successfully') {
          return response.message;
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }

  resetPassword(body) {
    return this.http.post(environment.apiUrl.concat('/api/v1/reset/begin'), body).pipe(
      map((response: any) => {
        if (response != null) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }

  confirmPasswordReset(body) {
    return this.http.post(environment.apiUrl.concat('/api/v1/reset/submitanswer'), body).pipe(
      map((response: any) => {
        if (response != null) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const body = error;
      const err = body.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg);
  }
}
