import {rpo, vital} from './../_models/rpo.model';
import { CheckPoint } from 'app/_models';

export class InitRpo{
    static readonly type = '[RPO] Init'
    constructor(public payload: rpo) {} 
}

export class UpdateRpo{
    static readonly type = '[RPO] Update'
    constructor(public payload: rpo) {} 
}

export class AddVital{
    static readonly type = '[VITAL] Add'
    constructor(public payload: vital) {} 
}

export class AddRpoCheckpoint{
    static readonly type = '[ADDRPOCHECKPOINT] Update'
    constructor(public payload: CheckPoint) {} 
}

export class RemoveRpoCheckpoint{
    static readonly type = '[REMOVERPOCHECKPOINT] Remove'
    constructor(public payload: CheckPoint) {} 
}