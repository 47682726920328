import { AfterContentInit, Component, ElementRef, Renderer2, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SessionManagerService } from '../_services/index';
import { DirectorService } from '../director.service';
import { NursingDialogComponent } from 'app/lms/shared-components/nursing-dialog/nursing-dialog.component';
import { ErrorMsgService } from '../error-msg.service';
import { NzMarks } from 'ng-zorro-antd/slider';
import { NURSING_LABTESTS } from 'utils/constants';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
export interface LabElement {
  test: string;
  value: string;
  standard: string;
  unit: string;
  correct: boolean;
  selected: boolean;
}

@Component({
  selector: 'app-student-feedback-nursing',
  templateUrl: './student-feedback-nursing.component.html',
  styleUrls: ['./student-feedback-nursing.component.scss'],
})
export class StudentFeedbackNursingComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  private readonly caseId: string = '';
  private readonly sessionId: string = '';
  videoUrl = 'assets/patients/';
  imgUrl = 'assets/default/videoModule/';
  showQuestion = false;
  showAssessment = false;
  showLoop = false;
  endModuleAt;
  mistakeCounter = 0;
  showInfo = false;
  queued = [];
  playing = false;
  loadingAndSaving = false;
  questionList;
  selectedQuestion;
  infoList;
  infoAdded = [];
  infoTimes = [];
  infoAvailable = [];
  vitalsList;
  selectedVitalsIndex;
  vitalsTimes = [];
  selectedVitals;
  chartList;
  scores = [];
  selectedAssessment;
  notifications = {
    info: 0,
    vitals: 0,
    total: 0,
  };
  videoPlaying = false;
  currentTime = 50;
  videoHightlights: NzMarks = {};
  displayedColumns: string[] = ['test', 'standard', 'unit', 'value'];
  labTests = NURSING_LABTESTS;
  session;
  disablePanel = false;
  activeTab = 0;
  assessmentImgUrl;
  patientImage;
  nursesNotes;
  audioUrl;
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild('loopPlayer', { static: true }) loopPlayer: ElementRef;
  moduleLength;
  chips = [
    {
      label: 'All',
      class: '',
    },
    {
      label: 'Correct',
      class: 'correct',
    },
    {
      label: 'Incorrect',
      class: 'incorrect',
    },
    {
      label: 'Partial',
      class: 'partial',
    },
  ];
  marks = [];
  hiddenMarkTimes: number[] = [];
  hiddenTempMark: number;
  selectedChip;
  listeners = [];
  questionTimes: number[] = [];
  audio;
  constructor(
    readonly director: DirectorService,
    private readonly sessionService: SessionManagerService,
    private route: ActivatedRoute,
    private readonly dialog: MatDialog,
    public errorService: ErrorMsgService,
    private renderer: Renderer2,
    private el: ElementRef,
    private _location: Location
  ) {
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');
    this.caseId = this.route.snapshot.queryParamMap.get('case_id');
    this.assessmentImgUrl = 'assets/patients/' + this.caseId + '/';
    this.patientImage = 'assets/patients/' + this.caseId + '/patientImage.png';
    this.nursesNotes = 'assets/patients/' + this.caseId + '/nurseNotes.png';
    this.audioUrl = 'assets/patients/' + this.caseId + '/audios/';
  }

  backClicked() {
    this._location.back();
  }

  private fetchSession() {
    this.loadingAndSaving = true;
    return this.sessionService.getSession(this.sessionId).subscribe(
      (session) => {
        this.loadingAndSaving = false;
        this.session = session;
        const nursingData = session.nursing.videoModule.userData;
        const tempHighlights = {};
        const tempKeys = Object.keys(nursingData.questions);
        const tempHighlightsArr = [];
        tempKeys.forEach((x) => {
          tempHighlights[x] = '';
          if (nursingData.questions[x].score === 100) {
            nursingData.questions[x].class = 'mark-correct';
            tempHighlightsArr.push('mark-correct');
          } else if (nursingData.questions[x].score === 0) {
            nursingData.questions[x].class = 'mark-incorrect';
            tempHighlightsArr.push('mark-incorrect');
          } else {
            nursingData.questions[x].class = 'mark-partial';
            tempHighlightsArr.push('mark-partial');
          }
        });
        this.questionTimes = tempKeys.map((x) => Number(x));
        this.videoHightlights = { ...tempHighlights };
        this.infoList = { ...nursingData.infoList };
        this.infoTimes = Object.keys(this.infoList).reverse();
        this.vitalsList = { ...nursingData.vitalsList };
        this.vitalsTimes = Object.keys(this.vitalsList);
        this.chartList = [...nursingData.chartList];
        this.moduleLength = nursingData.moduleLength;
        this.questionList = { ...this.checkAnswers(nursingData.questions) };
        setTimeout(() => {
          const parent = this.el.nativeElement.querySelector('.ant-slider-step');
          this.marks = parent ? parent.children : [];
          Array.from(this.marks).forEach((child, i) => {
            this.renderer.addClass(child, tempHighlightsArr[i]);
            const removeClickListener = this.renderer.listen(child, 'click', (event) => {
              this.onClickMark(tempKeys[i], event);
            });
            this.listeners.push(removeClickListener);
          });
        });
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  checkAnswers(questions) {
    for (const x in questions) {
      switch (questions[x].type) {
        case 'checkbox': {
          questions[x].checkedOptions = questions[x].options.map((o, i) => {
            const optionObj = {
              label: o,
              selected: false,
              class: '',
            };
            if (questions[x].selected.includes(o)) {
              optionObj.selected = true;
            }
            if (questions[x].correctOptions.includes(i + 1)) {
              optionObj.class = 'correct';
            } else {
              optionObj.class = 'incorrect';
            }
            return optionObj;
          });
          break;
        }
        case 'lab': {
          const correctOptions = questions[x].requiredLabTests.map((x) => x.test);
          const labTests = { ...this.labTests };
          for (const key in labTests) {
            labTests[key].data.forEach((option) => {
              if (correctOptions.includes(option.test)) {
                option.correct = true;
                const index = questions[x].requiredLabTests.findIndex((req) => req.test === option.test);
                option.value = questions[x].requiredLabTests[index].value;
              } else {
                option.correct = false;
                option.value = 'N/A';
              }
              if (questions[x].selected.includes(option.test)) {
                option.selected = true;
              } else {
                option.selected = false;
              }
            });
            labTests[key].dataSource = new MatTableDataSource<LabElement>(labTests[key].data);
          }
          questions[x].checkedOptions = labTests;
          break;
        }
        case 'radio': {
          questions[x].checkedOptions = questions[x].options.map((o, i) => {
            const optionObj = {
              label: o,
              class: '',
              notes: questions[x].notesOption === i + 1 ? true : false,
            };
            if (questions[x].correctOptions.includes(i + 1)) {
              optionObj.class = 'correct';
            }
            if (o === questions[x].selected) {
              if (questions[x].correctOptions.includes(i + 1)) {
                optionObj.class = 'correct';
              } else {
                optionObj.class = 'incorrect';
              }
            }
            return optionObj;
          });
          break;
        }
        case 'tree':
        case 'sub': {
          questions[x].subOptions.forEach((subQuestion) => {
            subQuestion.checkedOptions = subQuestion.options.map((o, i) => {
              const url = this.imgUrl + o + '.png';
              const img = new Image();
              img.src = url;

              const optionObj = {
                img: url,
                label: o,
                class: '',
              };
              if (subQuestion.correctOptions.includes(i + 1)) {
                optionObj.class = 'correct';
              }
              if (i + 1 === subQuestion.selected && !subQuestion.correctOptions.includes(i + 1)) {
                optionObj.class = 'incorrect';
              }
              return optionObj;
            });
          });
          break;
        }
        case 'assessment': {
          questions[x].checkedOptions = questions[x].assessmentOptions.map((o, i) => {
            const optionObj = {
              index: i,
              label: o.organ,
              points: o.points,
              selected: false,
              class: '',
            };
            if (questions[x].selected.includes(i + 1)) {
              optionObj.selected = true;
            }
            if (questions[x].correctOptions.includes(i + 1)) {
              optionObj.class = 'correct';
            } else {
              optionObj.class = 'incorrect';
            }
            return optionObj;
          });
          break;
        }
      }
    }
    return questions;
  }

  selectChip(chip) {
    this.selectedChip = chip;
    let classToShow;
    switch (chip.class) {
      case 'correct':
        classToShow = 'mark-correct';
        break;
      case 'incorrect':
        classToShow = 'mark-incorrect';
        break;
      case 'partial':
        classToShow = 'mark-partial';
        break;
    }
    Array.from(this.marks).forEach((child) => {
      if (classToShow && !child.classList.contains(classToShow)) {
        this.renderer.addClass(child, 'mark-hide');
      } else {
        this.renderer.removeClass(child, 'mark-hide');
      }
    });
    if (classToShow) {
      this.hiddenMarkTimes = Object.keys(this.questionList)
        .filter((time) => this.questionList[time].class !== classToShow)
        .map((x) => Number(x));
    } else {
      this.hiddenMarkTimes = [];
    }
  }

  onClickMark(i, event: Event) {
    event.stopPropagation();
    this.skipVidTo(Number(i));
  }

  getCoordinateStyle(coordinates) {
    return {
      top: `${coordinates.y}px`,
      left: `${coordinates.x}px`,
      height: `${coordinates.height}px`,
      width: `${coordinates.width}px`,
    };
  }

  typeOf(value) {
    return typeof value;
  }

  playAudio(src) {
    this.audio = new Audio();
    this.audio.src = this.audioUrl + src + '.mp3';
    this.audio.load();
    this.audio.play();
  }

  selectAssessment(index) {
    if (this.audio) {
      this.audio.pause();
    }
    this.selectedAssessment = index;
    if (this.selectedQuestion.assessmentOptions[index].audio) {
      this.playAudio(this.selectedQuestion.assessmentOptions[index].audio);
    }
  }

  timeInSeconds(time) {
    const [mins, seconds] = time.split(':');
    return Number(mins) * 60 + Number(seconds);
  }

  togglePlay() {
    if (!this.selectedQuestion) {
      if (this.videoPlaying) {
        this.videoPlayer.nativeElement.pause();
      } else {
        this.videoPlayer.nativeElement.play();
      }
      this.videoPlaying = !this.videoPlaying;
    } else {
      this.hiddenTempMark = this.currentTime;
      this.continueModule();
    }
  }

  previousQuestion() {
    let previousQuestionTime;
    this.questionTimes.forEach((time) => {
      if (time < this.currentTime) {
        previousQuestionTime = time;
      }
    });
    if (previousQuestionTime) {
      this.skipVidTo(previousQuestionTime);
    }
  }

  nextQuestion() {
    const nextQuestiontime = this.questionTimes.find((time) => time > this.currentTime);
    if (nextQuestiontime) {
      this.skipVidTo(nextQuestiontime);
    }
  }

  toggleQuestionDrawer() {
    this.showQuestion = !this.showQuestion;
  }

  getImgUrl(image) {
    return this.imgUrl + image + '.png';
  }

  selectSub(questionIndex, optionIndex) {
    this.selectedQuestion.subOptions[questionIndex].selected = optionIndex + 1;
  }

  updateSelected(value) {
    let arr = this.selectedQuestion.selected;
    if (!arr) {
      arr = [value];
    } else {
      if (arr.includes(value)) {
        arr.splice(
          arr.findIndex((x) => x === value),
          1
        );
      } else {
        arr.push(value);
      }
    }
    this.selectedQuestion.selected = arr;
  }

  onClickInfo() {
    this.showInfo = !this.showInfo;
    if (this.showInfo) {
      this.updateNotif();
    }
  }

  updateNotif() {
    if (this.activeTab === 1) {
      this.notifications.total -= this.notifications.info;
      this.notifications.info = 0;
    } else if (this.activeTab === 2) {
      this.notifications.total -= this.notifications.vitals;
      this.notifications.vitals = 0;
    }
  }

  resetNotifs() {
    this.notifications.total = 0;
    this.notifications.info = 0;
    this.notifications.vitals = 0;
  }

  addNotif(tab) {
    if (!this.showInfo || (this.activeTab !== tab && this.showInfo)) {
      if (tab === 1) {
        this.notifications.total++;
        this.notifications.info++;
      } else if (tab === 2) {
        this.notifications.total++;
        this.notifications.vitals++;
      }
    }
  }

  tabChanged(tabChangeEvent) {
    this.activeTab = tabChangeEvent.index;
    this.updateNotif();
  }

  onChange(value) {
    this.selectedQuestion.selected = value;
  }

  loopVideo() {
    this.loopPlayer.nativeElement.src = this.videoUrl + this.caseId + '/videos/loop.mp4';
    this.loopPlayer.nativeElement.play();
    this.showLoop = true;
  }

  setQuestion(question) {
    this.showQuestion = true;
    this.selectedQuestion = question;
    this.videoPlayer.nativeElement.pause();
    this.videoPlaying = false;
    if (question.type === 'assessment') {
      this.showAssessment = true;
    }
    if (question.loopVideo) {
      this.loopVideo();
    }
  }

  resetQuestionState() {
    this.selectedQuestion = null;
    this.showQuestion = false;
    this.showLoop = false;
  }

  continueModule() {
    this.resetQuestionState();
    if (this.audio) {
      this.audio.pause();
    }
    this.videoPlayer.nativeElement.play();
    this.videoPlaying = true;
  }

  skipVidTo(time) {
    this.resetQuestionState();
    this.hiddenTempMark = null;
    this.currentTime = time;
    this.videoPlayer.nativeElement.currentTime = time;
    this.resetNotifs();
    const infoKeys = this.infoTimes.filter((k) => {
      if (k < time) {
        this.addNotif(1);
      }
      return k < time;
    });
    if (infoKeys.length) {
      this.infoAvailable = infoKeys.map((k) => this.infoList[k]);
      this.infoAdded = infoKeys;
    }
    let latestVitals;
    this.vitalsTimes.forEach((x) => {
      if (x < time) {
        latestVitals = x;
      }
    });
    if (latestVitals) {
      this.selectedVitals = this.vitalsList[latestVitals];
      this.selectedVitalsIndex = latestVitals;
      this.addNotif(2);
    } else {
      this.selectedVitals = null;
      this.selectedVitalsIndex = null;
    }
  }

  openNursesNotes() {
    this.dialog.open(NursingDialogComponent, { data: { nursesNotes: this.nursesNotes } });
  }

  ngAfterViewInit(): void {
    this.fetchSession();
    this.selectedChip = this.chips[0];
  }

  setCurrentTime(event) {
    const currentTime = Math.floor(event.target.currentTime);
    this.currentTime = currentTime;
    if (this.loadingAndSaving) return;

    if (
      this.questionList[currentTime] &&
      !this.hiddenMarkTimes.includes(currentTime) &&
      this.hiddenTempMark !== currentTime
    ) {
      this.setQuestion(this.questionList[currentTime]);
      this.hiddenTempMark = currentTime;
    }

    if (this.infoList[currentTime] && !this.infoAdded.includes(currentTime)) {
      this.infoAvailable.unshift(this.infoList[currentTime]);
      this.infoAdded.push(currentTime);
      this.addNotif(1);
    }

    if (this.vitalsList[currentTime] && this.selectedVitalsIndex !== currentTime) {
      this.selectedVitals = this.vitalsList[currentTime];
      this.selectedVitalsIndex = currentTime;
      this.addNotif(2);
    }
  }

  ngAfterContentInit(): void {
    createjs.Sound.stop();
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.src = this.videoUrl + this.caseId + '/videos/videoModule.mp4';
  }

  ngOnDestroy(): void {
    this.questionList = null;
    this.listeners.forEach((removeClickListener) => removeClickListener());
  }
}
