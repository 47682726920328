<div *ngIf="course">
  <a class="text-color-primary" (click)="backClicked()">
    <b>Back to dashboard</b>
  </a>
  <div class="mt-3 d-flex justify-content-between text-black-50">
    <div>
      <div *ngIf="student" class="h3 font-weight-bold">
        {{ student.firstName }}
        {{ student.lastName }}
      </div>
      <div *ngIf="course">
        Course: <b>{{ course.name }}</b>
        <i
          *ngIf="course.publicAccess"
          class="fa fas fa-globe-americas ml-2 text-color-primary"
          matTooltip="This is a public class organized by {{ $any(course.institution).name }}"
          matTooltipPosition="above"
        >
        </i>
        -&nbsp;
        <span
          [class.text-danger]="course.ended"
          [matTooltipDisabled]="!course.ended"
          role="button"
          matTooltip="This class has ended. Please contact the professor for more information."
          matTooltipPosition="above"
        >
          {{ course.start | date: 'MMM d yyyy' }} to {{ course.end | date: 'MMM d yyyy' }}
        </span>
      </div>
    </div>
    <div *ngIf="student && !course.nursing">
      Skill Level:
      <span class="notranslate">{{ displaySkillLevel() }} </span>
    </div>
    <div class="font-weight-bold text-left" [hidden]="!course?.requiredPassingPercent">
      Required passing percentage: <span class="notranslate">{{ course?.requiredPassingPercent }}%</span>
    </div>
  </div>

  <div *ngIf="course" class="mt-1 font-weight-light text-black-50">
    {{ course.description }}
  </div>
</div>
