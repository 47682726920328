import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralDialogComponent } from '@canhealth/cyberpatient-ui';
import { Select, Store } from '@ngxs/store';
import {
  AddStudentCurrentCourse,
  RemoveCaseCurrentCourse,
  RemoveFromInviteList,
  RemoveStudentCurrentCourse,
  SetInviteList,
} from 'app/actions/teacherdashboard.action';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { course, listStudent, patientcase } from '../../_models/teacher-dashboard.model';
import { TeachDashBoardModel, TeachDashBoardState } from '../../state/teacherdashboard.state';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';

@Component({
  selector: 'app-classdashboard',
  templateUrl: './classdashboard.component.html',
  styleUrls: ['./classdashboard.component.scss'],
  providers: [TeacherDashboardDataService],
})
export class ClassdashboardComponent implements OnInit, AfterViewInit {
  private invitedList = [];
  public studentList: listStudent[] = [];

  public listOfEmails: Array<{ emailId: string }> = [];
  public mailId: string;
  public classtittle: string;
  public startDate: string;
  public endDate: string;
  public skillLevel: number;
  public isClicked: boolean;
  public selectable = true;
  public removable = true;
  visible = true;
  course: course;
  public isEnableCase: boolean;
  public isEnableStudent: boolean;
  caseDataSource: MatTableDataSource<patientcase>;
  studentDataSource: MatTableDataSource<listStudent>;
  emailDomain = ['can-health.org'];
  casetablecolumns = ['action', 'name', 'category'];
  studenttablecolumns = ['action1', 'name1', 'email1', 'status1'];
  l2 = [];
  dupErr = false;
  invalidErr = false;
  emailValid = false;
  bulklist;
  l3 = [];

  @ViewChild('myAddModal', { static: true }) myAddModal: TemplateRef<any>;
  @ViewChild('removecasedialog', { static: true }) removeCaseDialog: TemplateRef<any>;
  @ViewChild('removestudentdialog', { static: true }) removeStudentDialog: TemplateRef<any>;
  @ViewChild('casepaginator2', { static: true }) casepaginator2: MatPaginator;
  @ViewChild('studentpaginator', { static: true }) studentpaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  @ViewChild('TableTwoSort', { static: true }) tableTwoSort: MatSort;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Select(TeachDashBoardState.getTeacherDashBoardState)
  storeTeacherDashboardState: Observable<TeachDashBoardModel>;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store,
    private tdService: TeacherDashboardDataService
  ) {}

  ngOnInit() {
    this.course = new course();
    this.isEnableCase = false;
    this.isEnableStudent = false;
    this.isClicked = false;
    this.storeTeacherDashboardState.subscribe((x) => {
      this.course = x.selectedcourse;
    });

    if (this.course) {
      this.tdService.getInvitedList(this.course.id).subscribe((res) => {
        this.invitedList = res;
      });
    }

    if (this.course == null) {
      const path = '/teacher-dashboard';
      this.router.navigate([path], { skipLocationChange: false });
    } else {
      this.studentDataSource = new MatTableDataSource<listStudent>(this.studentList);
      this.caseDataSource = new MatTableDataSource<patientcase>(this.course.cases);
    }
    this.mergeInviteList();
  }

  displaySkillLevel() {
    if (!this.course) {
      return;
    }

    if (this.course.recommendationLevel === 'HIST') {
      return (this.skillLevel = 1);
    }
    if (this.course.recommendationLevel === 'HTPE') {
      return (this.skillLevel = 2);
    }
    if (this.course.recommendationLevel === 'NOMR') {
      return (this.skillLevel = 3);
    }
    if (this.course.recommendationLevel === 'FULL') {
      return (this.skillLevel = 4);
    }
  }

  truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  mergeInviteList() {
    this.studentList = [];
    this.invitedList = [];
    this.l2 = [];
    const a$ = this.store.dispatch([new SetInviteList()]);
    const b$ = this.storeTeacherDashboardState;
    const tc = b$.pipe(mergeMap(() => a$));

    tc.subscribe((b) => {
      this.emailDomain = b[0].teacherdashboard.instructor.institution_id.domains;
      this.course = b[0].teacherdashboard.selectedcourse;
      this.tdService.getInvitedList(this.course.id).subscribe((res) => {
        this.invitedList = res;
      });

      this.studentList = this.course.students.map((element) => {
        return {
          name: element.user_id.firstName,
          email: element.user_id.email,
          course_id: this.course._id,
          status: 'Active',
        };
      });

      this.l2 = this.invitedList.map((element) => {
        if (element.course_id === this.course._id) {
          return {
            name: '',
            email: element.email,
            status: 'Invited',
            course_id: this.course._id,
          };
        }
      });

      this.studentList = this.studentList.concat(this.l2);
      this.studentDataSource.data = this.studentList;
      this.caseDataSource.data = this.course.cases;
    });
  }

  ngAfterViewInit() {
    this.isClicked = false;
  }
  backClicked() {
    const path = '/teacher-dashboard';
    this.router.navigate([path], { skipLocationChange: false });
  }

  beginAssessment() {
    const path = '/teacher-dashboard/summativeassessment';
    this.router.navigate([path], { skipLocationChange: false });
  }

  checkValid(searchValue: string) {
    const matches = searchValue.match(
      // eslint-disable-next-line max-len
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    );
    if (matches) {
      if (this.emailDomain !== undefined) {
        const valid = this.emailDomain.some((domain) => searchValue.includes(domain));
        if (valid) {
          return this.checkDuplcates(searchValue);
        } else {
          return -2;
        }
      }
    } else {
      return -2;
    }
  }
  eliminateDuplicates(arr) {
    let i;
    const len = arr.length;
    const out = [];
    const obj = {};

    for (i = 0; i < len; i++) {
      obj[arr[i]] = 0;
    }
    for (i in obj) {
      out.push(i);
    }
    return out;
  }
  checkDuplcates(val: string) {
    if (this.course.students.findIndex((x) => x.user_id.email === val) > -1) {
      return -1;
    }
    if (this.invitedList.findIndex((x) => x.email === val) > -1) {
      return -1;
    }
    return 1;
  }

  processListMailid() {
    let emaillist = [];
    emaillist = this.bulklist
      .replace(/(\r\n|\n|\r)/gm, '')
      .trim()
      .split(',');
    this.l3 = [];
    for (let index = 0; index < emaillist.length; index++) {
      const el = emaillist[index];
      if (el.trim().length !== 0) {
        const found = this.l3.filter((x) => x.k === el);
        this.l3.push({ v: this.checkValid(el), k: el, count: found.length + 1 });
      }
    }
  }

  checkInviteList() {
    return this.l3.filter((x) => x.v === 1 && x.count === 1).length;
  }
  remove1(mail): void {
    const index = this.l3.indexOf(mail);
    if (index >= 0) {
      this.l3.splice(index, 1);
    }
  }
  editClass() {
    const path = '/teacher-dashboard/createclass';
    this.router.navigate([path], { skipLocationChange: false, state: { classedit: true } });
  }

  addCases() {
    const path = '/teacher-dashboard/addcases';
    this.router.navigate([path, this.course._id], { skipLocationChange: false });
    setTimeout(() => {
      this.caseDataSource.paginator = this.casepaginator2;
    });
  }

  addListMailid() {
    if (this.emailValid) {
      this.listOfEmails.push({ emailId: this.mailId });
      this.isClicked = true;
    }
    this.emailValid = false;
    this.mailId = ' ';
  }

  remove(mail): void {
    const index = this.listOfEmails.indexOf(mail);
    if (index >= 0) {
      this.listOfEmails.splice(index, 1);
    }
  }

  sendInvites() {
    const arrlist = this.l3.filter((x) => x.v === 1 && x.count === 1).map((y) => y.k);

    this.store.dispatch([new AddStudentCurrentCourse(this.course, arrlist)]).subscribe(() => {
      this.mergeInviteList();
      this.bulklist = '';
      this.l3 = [];
    });
  }

  openRemoveCaseDialog(e) {
    const dialogRefRemoveCase = this.dialog.open(GeneralDialogComponent, {
      data: {
        header: 'Confirm Remove Case',
        body: `
          <div>You have selected <b>${e.demographics.patient_name}, ${e.tags.category}</b> for removal from your class.</div>
          <div>Are you sure?</div>
        `,
        buttons: [
          {
            text: 'Remove Case',
            color: 'warn',
            onPress: () => {
              this.okRemoveCase(e);
            },
          },
          {
            text: 'Cancel',
            color: '',
          },
        ],
      },
    });
    dialogRefRemoveCase.disableClose = true;
  }

  okRemoveCase(e: patientcase) {
    this.store.dispatch([new RemoveCaseCurrentCourse(this.course, e)]).subscribe((x) => {
      this.course.cases = x[0].teacherdashboard.mycourses.find((x) => x._id === this.course._id).cases;
      this.caseDataSource = new MatTableDataSource<patientcase>(this.course.cases);
      this.caseDataSource.paginator = this.casepaginator2;
    });
  }

  openRemoveStudentDialog(email: string) {
    const dialogRefRemoveStudent = this.dialog.open(GeneralDialogComponent, {
      data: {
        header: 'Confirm Remove Student',
        body: `
            <div>You have selected <b>${email}</b> for removal from your class.</div>
            <div>Are you sure?</div>`,
        buttons: [
          {
            text: 'Remove Student',
            color: 'warn',
            onPress: () => {
              this.okRemoveStudent(email);
            },
          },
          {
            text: 'Cancel',
            color: '',
          },
        ],
      },
    });
    dialogRefRemoveStudent.disableClose = true;
  }

  okRemoveStudent(e: string) {
    const liststudent = this.studentList.find((x) => x.email === e);
    if (liststudent.status == 'Active') {
      const studentToRemove = this.course.students.find((x) => x.user_id.email === e);
      this.store.dispatch([new RemoveStudentCurrentCourse(this.course, studentToRemove)]).subscribe((x) => {
        this.course.students = x[0].teacherdashboard.mycourses.find((x) => x._id === this.course._id).students;
        this.remove(e);
        this.mergeInviteList();
      });
    } else {
      this.store.dispatch([new RemoveFromInviteList(this.course._id, e)]).subscribe(() => this.mergeInviteList());
    }
  }
  chipTooltip(l) {
    if (l.v === -1) {
      return 'Already Invited';
    }
    if (l.v === -2) {
      return 'Invalid email/Not in domain';
    }
    if (l.count > 1) {
      return 'Duplicate';
    }
  }
  openDialog() {
    this.listOfEmails = [];
    const dialogRef = this.dialog.open(this.myAddModal, {
      data: { email: this.emailDomain },
      height: '700px',
      width: '600px',
    });
    this.l3 = [];
    dialogRef.disableClose = true;
  }

  enableRemoveButtonStudent() {
    this.isEnableStudent = true;
  }

  enableRemoveButtonCase() {
    this.isEnableCase = true;
  }
}
