import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../_services';

@Component({
  selector: 'app-case-library-onboarding-dialog',
  templateUrl: './case-library-onboarding-dialog.component.html',
  styleUrls: ['./case-library-onboarding-dialog.component.scss'],
})
export class CaseLibraryOnboardingDialogComponent {
  public feedbackGiven = false;

  constructor(private readonly dialogRef: MatDialogRef<boolean>, private readonly userService: UserService) {}

  get tutorialFlow(): 'professor' | 'admin' {
    return this.userService.user.institutionRole === 'professor' ? 'professor' : 'admin';
  }

  feedback(_good: boolean) {
    this.feedbackGiven = true;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
