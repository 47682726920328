import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Assignment } from '../../_models/index';
import { FetchAssignmentDataService, SaveAssignmentDataService, SessionManagerService } from '../../_services/index';
import { TeacherDashboardDataService } from '../../teacher-dashboard/teacher-dashboard-data.service';
import { SetCaseId, SetCurrentCase } from '../../actions/checkpoint.action';
import { TestConfirmComponent } from './test-confirm/test-confirm.component';
import { isCourseActive } from 'utils/helpers';

@Component({
  selector: 'app-stu-feed',
  providers: [SessionManagerService, FetchAssignmentDataService, SaveAssignmentDataService],
  templateUrl: './stu-feed.component.html',
  styleUrls: ['./stu-feed.component.scss'],
  animations: [
    trigger('resumeButton', [
      state(
        'in',
        style({
          transform: 'translateY(0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(-200%)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class StuFeedComponent implements OnInit {
  listType = 'favourites';
  loadingAndSaving = false;
  activeCourses = false;
  activeCases = false;
  p = 1;
  data: any[] = [];
  refresh: any;
  assignmentsData: MatTableDataSource<Assignment>;
  assignments: any[];
  exams: any[];
  favourited = {};
  activeSessionFlag;
  CasesExistFlag = true;
  columnsToDisplay = ['patient_name', 'due_date', 'category', 'case_id'];
  image;
  skillset;
  currentSkillset = 'Level 1';
  searchTerm;
  activeSession;
  resumeButtonToggle = 'out';
  itemsPerPage = 3;
  currentPage;
  active = false;
  Math: Math = Math;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public feed: Array<any>;
  user;
  institutionPermissions;
  deadlineFlag;
  constructor(
    private store: Store,
    public snackBar: MatSnackBar,
    private teacherDashboardService: TeacherDashboardDataService,
    private saveAssignmentDataService: SaveAssignmentDataService,
    private location: Location,
    private sg: SimpleGlobal,
    public dialog: MatDialog,
    private sessionManagerService: SessionManagerService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private router: Router
  ) {
    this.location.replaceState('');
  }

  ngOnInit() {
    // this.dialog.open(AgreementPromptComponent, { data: false, disableClose: true });
    this.user = localStorage.getItem('currentUser');
    this.institutionPermissions = JSON.parse(this.user)['institutionPermissions'];
    this.loadingAndSaving = true;
    this.fetchAssignmentDataService.getRefreshData().subscribe(
      (refresh) => {
        this.loadingAndSaving = false;
        this.refresh = refresh;
        this.settingGlobalVars(this.refresh);
        // if (this.sg['case_id'] != null && this.sg['assignment_id'] != null) {
        if (this.sg['case_id'] != null) {
          this.resumeButtonToggle = 'in';
        }
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
    this.getCases();
    this.getCourses();
  }

  getCases() {
    this.loadingAndSaving = true;
    this.fetchAssignmentDataService.getFaves().subscribe(
      (cases) => {
        this.loadingAndSaving = false;
        this.data = cases;
        for (const x of this.data) {
          const id = x._id;
          this.favourited[id] = true;
        }
        this.active = true;
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  getCourses() {
    this.teacherDashboardService.getActiveCourseListStudent(true).subscribe((response) => {
      const courseList = response.filter((course) => {
        return isCourseActive(course.start, course.end);
      });
      if (courseList?.length) {
        this.activeCourses = true;
        const cases = courseList.find((course) => course.cases.length);
        if (cases) {
          this.activeCases = true;
        }
      }
    });
  }

  getFirstWord(x) {
    x.replace(/ .*/, '');
  }

  resumeSession() {
    const path = '/assignment/'.concat(this.sg['next_checkpoint']);
    this.router.navigate([path], { skipLocationChange: true });
  }

  startCase(case_id) {
    this.store.dispatch(new StateResetAll()).subscribe(() => {
      this.store.dispatch(new SetCaseId(case_id));
    });

    if (this.sg['session_id'] != null) {
      if (case_id === this.sg['case_id']) {
        this.dialog.open(TestConfirmComponent, { data: { flag: true, case_id: case_id } });
      } else {
        this.dialog.open(TestConfirmComponent, { data: { flag: false, case_id: case_id } });
      }
    } else {
      this.loadingAndSaving = true;
      this.sessionManagerService.createSession(case_id).subscribe(
        (response) => {
          this.loadingAndSaving = false;

          if (response.message === 'Session successfully created') {
            this.sg['session_id'] = response.session_id;
            this.sg['case_id'] = response.result.case_id;
            this.sg['patient_name'] = response.patient_name;
            this.sg['skillset'] = response.result.skillset;
            this.sg['next_checkpoint'] = response.next_checkpoint['component'];
            this.sg['instance_id'] = response.next_checkpoint['instance'];
            this.sg['mistake_counter'] = response.result.mistake_counter.mistakes;
            this.store.dispatch([new SetCurrentCase(response.session_id)]);
            const path = '/assignment/'.concat(this.sg['next_checkpoint']);

            this.router.navigate([path], { skipLocationChange: true });
          }
        },
        () => {
          this.loadingAndSaving = false;
        }
      );
    }
  }

  favourite(case_id, index) {
    if (this.favourited[case_id]) {
      this.removeFavourite(case_id, index);
    } else {
      this.addFavourite(case_id, index);
    }
  }

  removeFavourite(case_id, index) {
    const caseCont = this.data[index];
    const messageToCompare = 'Removed case '.concat(case_id);
    this.loadingAndSaving = true;
    this.saveAssignmentDataService.removeFavourite(case_id).subscribe(
      (response) => {
        this.loadingAndSaving = false;
        this.favourited[case_id] = false;
        if (response['message'] === messageToCompare) {
          this.snackBar.open(caseCont.patient_name + ' removed from Favourites!', 'Dismiss', {
            duration: 2000,
          });
          caseCont.favourite = false;
        }
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  absoluteIndex(indexOnPage: number): number {
    return this.itemsPerPage * (this.currentPage - 1) + indexOnPage;
  }

  addFavourite(case_id, index) {
    const caseCont = this.data[index];
    const messageToCompare = 'Successfully added case '.concat(case_id);
    this.loadingAndSaving = true;
    this.saveAssignmentDataService.addFavourite(case_id).subscribe(
      (response) => {
        this.loadingAndSaving = false;
        this.favourited[case_id] = true;
        if (response['message'] === messageToCompare) {
          this.snackBar.open(caseCont.patient_name + ' added to Favourites!', 'Dismiss', {
            duration: 2000,
          });
          caseCont.favourite = true;
        }
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.assignmentsData.filter = filterValue.trim().toLowerCase();
  }

  settingGlobalVars(ref) {
    this.loadingAndSaving = true;
    this.sg['membership_status'] = ref.membership;
    this.fetchAssignmentDataService.getSkillSet().subscribe(
      (skillset) => {
        this.loadingAndSaving = false;
        this.skillset = skillset.skillset;
        this.sg['skillset'] = this.skillset;
        this.sg['membership_status'] = skillset.membership;
        switch (this.sg['skillset']) {
          // this.currentSkillset = this.sg['skillset'];

          case 'HIST':
            this.currentSkillset = 'Level 1';
            break;

          case 'HTPE':
            this.currentSkillset = 'Level 2';
            break;

          case 'NOMR':
            this.currentSkillset = 'Level 3';
            break;

          case 'FULL':
            this.currentSkillset = 'Level 4';
            break;
        }
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
    if (ref.message === 'no active session exists') {
      this.sg['session_id'] = null;
      this.sg['case_id'] = null;
      this.store.dispatch([new SetCaseId(null)]);
      this.activeSessionFlag = false;
      this.CasesExistFlag = true;
    } else {
      this.CasesExistFlag = false;
      this.activeSessionFlag = true;
      this.sg['case_id'] = ref.case_id;
      this.store.dispatch([new SetCaseId(ref.case_id)]);
      this.sg['session_id'] = ref.session_id;
      this.sg['next_checkpoint'] = ref['next_checkpoint']['component'];
      this.sg['instance_id'] = ref['next_checkpoint']['instance'];
      this.sg['mistake_counter'] = ref.mistake_counter.mistakes;
      this.sg['skillset'] = ref.skillset;
      this.store.dispatch([new SetCurrentCase(ref.session_id)]);
    }
  }
}
