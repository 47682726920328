//Takes a numeber and returns the suffix for it Ex. 1 -> st, 2 - nd used for ui to display 1st and 2nd
export const getSuffix = (i) => {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
};

export const sortCaseList = (list) => {
  const listObj = {};
  const sortedList = [];
  let sortedListByCategory = [];
  list.forEach((x) => {
    const category = x.tags.category[0];
    if (listObj[category]) {
      listObj[category].push(x);
    } else {
      listObj[category] = [x];
    }
  });

  for (const category in listObj) {
    const sorted = listObj[category].sort((a, b) =>
      a.demographics.patient_name.localeCompare(b.demographics.patient_name)
    );
    sortedList.push({ list: sorted, category });
  }
  sortedList.sort((a, b) => a.category.localeCompare(b.category));

  sortedList.forEach((x) => {
    sortedListByCategory = [...sortedListByCategory, ...x.list];
  });

  return sortedListByCategory;
};

export const parseToChartData = (patientStats, isStudent, isNursing = false) => {
  let isAverage = true;
  let showBest = false;
  const labels = Array.from({ length: patientStats.data.length < 5 ? 5 : patientStats.data.length }, (_, i) => i + 1);
  if (patientStats.name !== 'Average') {
    isAverage = false;
    if (isStudent) {
      showBest = true;
    }
  }

  const statsColumns = ['label', 'score', 'time'];
  if (!isStudent && patientStats.data[0]?.studentIds.length) {
    statsColumns.splice(2, 0, 'students');
    statsColumns.splice(0, 0, 'action');
  }

  //Finds the highest score
  const scoresArr = patientStats.data.map((d) => Math.round(d.knowledgeScore));
  const bestScoreIndex = scoresArr.reduce((iMax, score, i, arr) => (score > arr[iMax] ? i : iMax), 0);
  return {
    data: {
      graph: [{ data: [0, ...scoresArr], label: 'Score', tension: 0 }],
      table: patientStats.data.map((d, i) => {
        return {
          score: `${Math.round(+Number(d.knowledgeScore))}% ${showBest && bestScoreIndex === i ? '(Best)' : ''}`,
          time: Math.round(+Number(d.time)),
          index: i,
          label: { num: i + 1, super: getSuffix(i + 1) },
          best: showBest && bestScoreIndex === i,
          students: !isStudent && d?.studentIds.length ? d?.studentIds.length : 0,
          studentIds: !isStudent && d?.studentIds.length ? d?.studentIds : [],
          warn: !isAverage && !isNursing && isStudent && !d?.genuine,
        };
      }),
      statsColumns,
    },
    labels: [
      'Attempts',
      ...labels.map((d, i) => `${i + 1}${getSuffix(i + 1)} ${showBest && bestScoreIndex === i ? '(Best)' : ''}`),
    ],
  };
};

export const parseToBarData = (studentAverages, userScores, student) => {
  const caseIds = [...studentAverages.map((s) => s.case_id), ...userScores.map((s) => s.case_id)];
  const caseIdsUnique = [...new Set(caseIds)];
  const sessions = [];
  caseIdsUnique.forEach((caseId) => {
    const studentScore = userScores.find((s) => s.case_id === caseId);
    const classScore = studentAverages.find((s) => s.case_id === caseId);
    sessions.push({
      class: classScore ? Math.round(classScore.knowledgeScore) : 0,
      student: studentScore ? Math.round(studentScore.knowledgeScore) : 0,
      name: classScore ? classScore.name : studentScore.name,
    });
  });
  //Gets the graph to min lenght to keep the Ui consistent
  if (sessions.length < 5) {
    for (let i = 0; i < 5; i++) {
      if (!sessions[i]) {
        sessions.push({
          class: 0,
          student: 0,
          name: ' ',
        });
      }
    }
  }

  return {
    data: [
      { data: sessions.map((session) => session.student), label: student },
      { data: sessions.map((session) => session.class), label: 'Class Average' },
    ],
    labels: sessions.map((session) => session.name),
  };
};
