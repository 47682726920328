import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable } from 'rxjs';

export interface Translation {
  origin: string;
  translated: string;
}

@Injectable()
export class TranslationsService {
  private readonly translationUrl = '/api/v1/translations';
  constructor(private http: HttpClient, private sg: SimpleGlobal) {}

  translate(contents: string[], to: string): Observable<Translation[]> {
    const url = this.sg['url'].concat(this.translationUrl);
    return this.http.post<Translation[]>(url, { contents, to });
  }
}
