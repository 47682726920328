<div class="container container-fluid p-3">
  <div class="display-4 font-weight-bold text-color-secondary">Super Admin Portal</div>
  <div class="text-muted text-body mt-3">Oh! You've just had access to the <b>Super Admin Portal</b>. One simple rule -
    DON'T DO ANYTHING STUPID!
  </div>

  <ngx-loading *ngIf="fetchInstitutionsLoading"></ngx-loading>
  <div class="alert alert-danger mt-3" *ngIf="fetchInstitutionsError">
    Unable to fetch all institutions. Please refresh the page.
  </div>

  <mat-tab-group dynamicHeight class="mt-3 py-3">
    <mat-tab label="Institutions">
      <app-institutions-list [institutions]="institutions"
                             (edit)="edit($event)"
                             (save)="save($event)"
                             (login)="loginAs($event)"></app-institutions-list>
    </mat-tab>
    <mat-tab label="Users">
      <app-users-list (search)="searchUser($event)"
                      (transferInstitution)="transferUser($event)"
                      [user]="user"
                      [institutions]="institutions"></app-users-list>
    </mat-tab>
  </mat-tab-group>
</div>
