<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
    <div class="pop-up mat-typography" [hidden]="skillFlag">
      <div class="row">
        <div class="large-12 equalize outer-card">
          <div class="description">
            <div class="">
              <p style="text-align:center; display: block; margin: 0 auto;">
                Set your Skillset Level
              </p>
            </div>
          </div>
          <div class="skillset-wrap">
            <span class="instructions">Before you can begin using CyberPatient, you must decide which Level you would
              like to play. <br><b>By default, every account starts at Level 1 but if you would like to advance to a higher
                level, please select it below:</b></span>
            <div class="skill-table">
              <div class="skill-headers">
                <div class="skillset">Skillset:</div>
                <div class="sections">Sections Included:</div>
                <div class="failures">Failures:</div>
              </div>
              <mat-card *ngFor="let skill of skillsets" class="level mat-elevation-z15" (click)="currentSkillset = skill.reference" [class.current]="currentSkillset == skill.reference">
                <div class="skillset">{{skill.skillset}}</div>
                <div class="sections">{{skill.sections}}</div>
                <div class="failures">{{skill.failures}}</div>
              </mat-card>
            </div>
            <div class="warning"><b>Warning!</b> Changing your skill level will end all open cases</div>
          </div>
          <button class="comment-submit action mat-button" mat-raised-button color="primary" (click)="setSkillSet()">Set
            Skillset</button>
        </div>
      </div>
    </div>
    <div class="pop-up mat-typography" [hidden]="!skillFlag">
      <div class="row">
        <div class="large-12 equalize outer-card">
          <div class="description">
            <div class="">
              <p style="text-align:center; display: block; margin: 0 auto;">
                Success! Set to Skill {{newSkillSet.skillset}}
              </p>
            </div>
          </div>
          <div class="skillset-wrap">
            <span class="instructions"><b>Your Skill Level has been set to {{newSkillSet.skillset}}!</b><br>
              If you would like to change your Skill Level in the Future, it is located on the Patient Cases card on your Dashboard, as shown in the image below:</span>
          </div>
          <img class="skill-img" src="./assets/img/app/popups/skillset.PNG"><br>
          <button class="comment-submit action mat-button" mat-raised-button color="primary" (click)="close()">Continue</button>
        </div>
      </div>
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </mat-dialog-content>
  