import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'key'
})
export class KeyPipe implements PipeTransform {

  transform(obj, sort = false): any {
    if (!obj) {
      return [];
    }

    const keys = Object.keys(obj);
    return (sort ? keys.sort() : keys).map((key) => ({
      key,
      value: obj[key]
    }));
  }
}
