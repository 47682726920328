<div>
  <div *ngIf="lineChartData.length" style="display: block; height: 350px">
    <app-stats-scroll [step]="6" [axis]="'x'">
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="true"
        [chartType]="lineChartType"
      ></canvas>
    </app-stats-scroll>
  </div>
  <div *ngIf="barChartData.length" style="display: block">
    <app-stats-scroll [step]="6" [axis]="'x'">
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        height="400"
        [colors]="barChartColors"
        [legend]="true"
        [chartType]="barChartType"
      ></canvas>
    </app-stats-scroll>
  </div>
</div>
