<div class="mobile-dialog-container">
  <div class="mobile-message-box">
    <h2 class="dialog-title">DESKTOP DEVICE RECOMMENDED</h2>
    <p class="mobile-message-text">
      This website is not optimized for mobile or tablet devices. Please use a <strong> Desktop or Laptop </strong> for
      the best experience.
    </p>
    <button class="mobile-message-button" (click)="onClose()">Dismiss</button>
  </div>
</div>
