<div class="pg-loading-screen pg-loading" *ngIf="active" style="background-color: #fff">
  <div class="pg-loading-inner">
    <div class="pg-loading-center-outer">
      <div class="pg-loading-center-middle">
        <h1 class="pg-loading-logo-header">
          <img class="pg-loading-logo" src="/assets/brands/CyberPatient-logo-with-tagline-full-color-rgb.svg" />
        </h1>
        <div class="pg-loading-html pg-loaded">
          <div class="loading-message">
            Having issues with CyberPatient? <br />
            Try using Incognito mode on Chrome for a smoother experience.
            <div class="loadbar-container notranslate">
              <div class="rectangle">
                <div class="loadbar">
                  <div class="value" [style.width.%]="loader.progress"></div>
                </div>
              </div>
              Loading Patient ... {{ loader.progress | number: '1.0-0' }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
