import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-sent',
  templateUrl: './confirmation-sent.component.html',
  styleUrls: ['./confirmation-sent.component.scss']
})
export class ConfirmationSentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  resend() {
  }
}
