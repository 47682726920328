import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvUploaderComponent } from './csv-uploader.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [CsvUploaderComponent],
  exports: [CsvUploaderComponent],
})
export class CsvUploaderModule { }
