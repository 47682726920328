<div mat-dialog-content class="mat-typography mat-dialog-content">
    <mat-card class="large-22 columns equalize outer-card-1">
        <section class="list inner-content head-section">
            <div class="text-description">
                <h1>Patient Chart Comparsion</h1>
            </div>
            <div class="return">
                    <span>{{patientName}}
                    <button mat-raised-button class="action mat-button mat-raised-button mat-primary" color="primary"
                        mat-dialog-close (click)="calculateTotalChartScore()">Return to Grades</button>
                </span>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="body-section">
            <span class="body-header">Use the tools to compare your submittied Chart to the ideal medical standard for Patient Chart and complete your Self-Assessment</span>
            <div>
                <mat-horizontal-stepper #stepper>
                    <div>
                        <mat-step class="mat-horizontal-stepper-header">
                            <ng-template matStepLabel class="steplabel">Compare Chart</ng-template>
                            <div cols="2" class="gird-menu">
                                <div colspan="1" rowspan="1" class="submitted-chart">
                                    <mat-list>
                                        <mat-list-item role="listitem">
                                            <div class="column-header">Submitted Chart</div>
                                        </mat-list-item>
                                        <div class="expansion-block">
                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            History
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Summary of Patient History:</h3>
                                                            <span class="span-data">{{chartData.history.summaryOfPatientHistory}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Cheif Complaint:</h3>
                                                            <span class="span-data">{{chartData.history.chief.notes}}</span>
                                                        </div>
                                                        <div>
                                                            <h3> Hypothesis:</h3>
                                                            <span class="span-data">
                                                            {{chartData.history.chief.hypothesis[0]}}
                                                            {{chartData.history.chief.hypothesis[1]}}
                                                            {{chartData.history.chief.hypothesis[2]}}
                                                            {{chartData.history.chief.hypothesis[3]}}
                                                        </span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Physical Exam
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Summary of Physical Examination</h3>
                                                            <span class="span-data">
                                                            {{chartData.physicalExam.summaryOfPhysicalExamination}}
                                                        </span>
                                                        </div>
                                                        <div>
                                                            <h3>Ancillary Care</h3>
                                                            <span class="span-data">{{chartData.physicalExam.ancillaryCare}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Diagnosis & Testing
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Provisional Diagnosis</h3>
                                                            <span class="span-data">
                                                            {{chartData.diagnosis.results.provDiagnosis}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Diagnosis Test Results</h3>
                                                            <span class="span-data">{{chartData.diagnosis.results.diagnosticResults}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Final Diagnosis</h3>
                                                            <span class="span-data">{{chartData.diagnosis.finalDiag.final}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Rationale</h3>
                                                            <span class="span-data">{{chartData.diagnosis.finalDiag.rationale}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Plan of Action</h3>
                                                            <span class="span-data">{{chartData.diagnosis.management.planOfAction}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Rationale</h3>
                                                            <span class="span-data">{{chartData.diagnosis.management.rationale}}</span>
                                                        </div>

                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Treatment
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Procedures and Surgery</h3>
                                                            <span class="span-data">{{chartData.treatment.invasive.proceduresAndSurgery}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Non-Invasive Management</h3>
                                                            <span class="span-data">{{chartData.treatment.nonInvasive.nonInvasiveManagement}}</span>
                                                        </div>
                                                        <div *ngFor="let note of chartData.treatment.dailyVisits.notes; let i = index;">
                                                            <h3>Day {{i+1}} Notes</h3>
                                                            <span class="span-data">{{note}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Communication
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3> Consulations</h3>
                                                            <span class="span-data">{{chartData.communication.reporting.consultations}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Communication</h3>
                                                            <span class="span-data">{{chartData.communication.reporting.communication}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Discharge Summary
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Discharge Summary & Recommendations</h3>
                                                            <span class="span-data">{{chartData.dischargeSummary.recommendations}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Follow up</h3>
                                                            <span class="span-data">{{chartData.dischargeSummary.followUp}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </div>
                                    </mat-list>
                                </div>
                                <div colspan="1" rowspan="1" class="standard-chart">
                                    <mat-list>
                                        <mat-list-item role="listitem">
                                            <div class="column-header">Standard Chart </div>
                                        </mat-list-item>
                                        <div class="expansion-block">
                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            History
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Summary of Patient History:</h3>
                                                            <span class="span-data">{{standardChart.history.summaryOfPatientHistory}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Cheif Complaint:</h3>
                                                            <span class="span-data">{{standardChart.history.chiefComplaint}}</span>
                                                        </div>
                                                        <div>
                                                            <h3> Hypothesis:</h3>
                                                            <span class="span-data">{{standardChart.history.hypothesis}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Physical Exam
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Summary of Physical Examination</h3>
                                                            <span class="span-data">{{standardChart.physicalExam.summaryOfPhysicalExamination}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Ancillary Care</h3>
                                                            <span class="span-data">{{standardChart.physicalExam.ancillaryCare}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Diagnosis & Testing
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Provisional Diagnosis</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.results.provDiagnosis}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Diagnosis Test Results</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.results.diagnosticResults}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Final Diagnosis</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.finalDiag.final}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Rationale</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.finalDiag.rationale}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Plan of Action</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.management.planOfAction}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Rationale</h3>
                                                            <span class="span-data">{{standardChart.diagnosis.management.rationale}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Treatment
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Procedures and Surgery</h3>
                                                            <span class="span-data">{{standardChart.treatment.invasive.proceduresAndSurgery}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Non-Invasive Management</h3>
                                                            <span class="span-data">{{standardChart.treatment.nonInvasive.nonInvasiveManagement}}</span>
                                                        </div>
                                                        <div *ngFor="let standardNote of standardChart.treatment.dailyVisits.notes; let i = index;">
                                                            <h3>Day {{i + 1}} Notes</h3>
                                                            <span class="span-data">{{standardNote}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Communication
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3> Consulations</h3>
                                                            <span class="span-data">{{standardChart.communication.reporting.consultations}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Communication</h3>
                                                            <span class="span-data">{{standardChart.communication.reporting.communication}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Discharge Summary
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <ng-template matExpansionPanelContent>
                                                        <div>
                                                            <h3>Discharge Summary & Recommendations</h3>
                                                            <span class="span-data">{{standardChart.dischargeSummary.recommendations}}</span>
                                                        </div>
                                                        <div>
                                                            <h3>Follow up</h3>
                                                            <span class="span-data">{{standardChart.dischargeSummary.followUp}}</span>
                                                        </div>
                                                    </ng-template>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </div>
                                    </mat-list>
                                </div>
                            </div>
                            <div class="chart-button">
                                <button mat-raised-button matStepperNext class="action mat-button mat-raised-button mat-primary">Next</button>
                            </div>
                        </mat-step>
                    </div>
                    <div>
                        <mat-step>
                            <ng-template matStepLabel>Self-Assessment</ng-template>
                            <mat-tab-group diableRipple="true" [(selectedIndex)]="selectedTab" (selectedTabChange)="check($event)">
                                <mat-tab label="History">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 0">
                                                            <div>
                                                                <h3>Cheif Complaint:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.history.chief.notes}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Summary of Patient History:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.history.summaryOfPatientHistory}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of historyQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span> Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span>{{ calculateHistoryScore()| number: "1.2-2" }} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Physical Exam">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 1">
                                                            <div>
                                                                <h3>Summary of Physical Examination:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.physicalExam.summaryOfPhysicalExamination}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Ancillary Care:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.physicalExam.ancillaryCare}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of physicalExamQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span> Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span>{{ calculatePhysicalExamScore() | number: "1.2-2" }} % </span>
                                                </div>
                                            </div>
                                        </div>

                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Diagnosis & Testing">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 2">
                                                            <div>
                                                                <h3>Provisional Diagnosis:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.results.provDiagnosis}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Diagnosis Test Results:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.results.diagnosticResults}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Final Diagnosis</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.finalDiag.final}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Rationale</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.finalDiag.rationale}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Plan of Action</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.management.planOfAction}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Rationale</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.diagnosis.management.rationale}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of diagnosisQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span> Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span>{{ calculateDiagnosisScore() | number: "1.2-2" }} % </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Treatment">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 3">
                                                            <div>
                                                                <h3>Procedures and Surgery</h3>
                                                                <div class="chart-submit" *ngIf="chartData">
                                                                    <span class="span-data">{{chartData.treatment.invasive.proceduresAndSurgery}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Non-Invasive Management</h3>
                                                                <div class="chart-submit" *ngIf="chartData">
                                                                    <span class="span-data">{{chartData.treatment.nonInvasive.nonInvasiveManagement}}</span>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="chartData">
                                                                <div *ngFor="let note of chartData.treatment.dailyVisits.notes; let i = index;">
                                                                    <h3>Day {{i+1}} Notes</h3>
                                                                    <p class="span-data">{{note}}</p>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of treatmentQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span> Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span> {{ calculateTreatmentScore() | number: "1.2-2" }} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Communication">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 4">
                                                            <div>
                                                                <h3> Consulations:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.communication.reporting.consultations}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Communication:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.communication.reporting.communication}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of communicationQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span> Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span> {{ calculateCommunicationScore() | number: "1.2-2" }} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Discharge Summary">
                                    <ng-template matTabContent>
                                        <div cols="2" class="gird-menu-self">
                                            <div colspan="1" rowspan="1" class="submitted-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Submitted Chart</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block">
                                                        <ng-container *ngIf="selectedTab === 5">
                                                            <div>
                                                                <h3>Discharge Summary & Recommendations:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.dischargeSummary.recommendations}}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3>Follow up:</h3>
                                                            </div>
                                                            <div *ngIf="chartData">
                                                                <div class="chart-submit">
                                                                    <span class="span-data">{{chartData.dischargeSummary.followUp}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </mat-list>
                                            </div>
                                            <div colspan="1" rowspan="1" class="standard-chart-tabgroup">
                                                <mat-list>
                                                    <mat-list-item role="listitem">
                                                        <div class="column-header-self">Complete Your Self-Assessment</div>
                                                    </mat-list-item>
                                                    <div class="expansion-block-self">
                                                        <div class="chebox-values" *ngFor="let item of dischargeQuestions">
                                                            <mat-checkbox [(ngModel)]="item.score"> <span class="mat-checkbox-label span-data">{{item.content}}</span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="scoring-block">
                                            <div class="scoring">
                                                <div>
                                                    <span>Scoring: </span>
                                                </div>
                                                <div class="value">
                                                    <span> {{ calculateDischargeScore() | number: "1.2-2" }} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-step>
                    </div>
                </mat-horizontal-stepper>
            </div>
        </section>
    </mat-card>
</div>
