import { Location } from '@angular/common';
import { AfterContentInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { course, FormativeFeedback, student, SummativeFeedback } from '../../_models/teacher-dashboard.model';
import { SetCurrentCase } from '../../actions/checkpoint.action';
import { AddSummativeToCurrentStudent, GetFormativeCourse } from '../../actions/teacherdashboard.action';
import { TeachDashBoardModel, TeachDashBoardState, } from '../../state/teacherdashboard.state';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';


@Component({
  selector: 'app-edit-summative-performance',
  templateUrl: './edit-summative-performance.component.html',
  styleUrls: ['./edit-summative-performance.component.scss']
})
export class EditSummativePerformanceComponent implements OnInit, AfterContentInit {


  @Select(TeachDashBoardState.getTeacherDashBoardState)
  storeTeacherDashboardState: Observable<TeachDashBoardModel>;
  myCourse = {} as course;
  summativefeedback = {} as SummativeFeedback;
  feedbacks = [] as SummativeFeedback[];
  scoreDataSource: MatTableDataSource<any>;
  noData: Observable<boolean>;
  mystudent = {} as student;
  @ViewChild('studentpaginator', {static: true}) studentpaginator: MatPaginator;
  @ViewChild('TableOneSort', {static: true}) tableOneSort: MatSort;
  @ViewChild('summativeAseessmentPopUp', {static: true}) summativeAseessmentPopUp: TemplateRef<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  feedbackcolumns = ['casehistory', 'attempts', 'time', 'score'];
  list1;
  assessmentquestions = [
    {id: 1, value: false, text: 'The student completed all their CP assignments.', score: 25},
    {
      id: 2,
      value: false,
      text: 'Student responded to feedback and worked to improve clinical skills, clinical reasoning and critical thinking',
      score: 25
    },
    {id: 3, value: false, text: 'Student reached the designated level of the performance.', score: 25},
    {
      id: 4,
      value: false,
      text: 'Student had a good overall attitude toward the online education process and virtual learning environment.',
      score: 25
    }
  ]
  add = true; // TODO change to false;
  showSummatoveHistory = false;
  formativeFeedbacks: FormativeFeedback[] = [];
  loading: boolean;
  caseData = [];
  average: Number;
  letterGrade: string;
  err;
  loadingAndSaving: boolean;
  notCompletedCases = [];
  completedCases = [];
  newSummative = {} as SummativeFeedback;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private sg: SimpleGlobal,
    private teacherService: TeacherDashboardDataService,
    private _location: Location,
    private store: Store
  ) {
  }

  getCheckedCount() {
    if (this.summativefeedback.checkBoxes) {
      return (this.summativefeedback.checkBoxes.filter(x => x.value).length / this.summativefeedback.checkBoxes.length) * 100;
    } else {
      return 0;
    }
  }

  ngOnInit() {
    this.list1 = this.assessmentquestions;
    this.store.dispatch([new GetFormativeCourse()]);
  }

  ngAfterContentInit(): void {
    this.storeTeacherDashboardState.subscribe((x) => {

      this.mystudent = x.selectedstudent;
      this.myCourse = x.selectedcourse;
      if (this.mystudent == null) {
        const path = '/teacher-dashboard';
        this.router.navigate([path], {skipLocationChange: false});
      } else {

        if (x.formativefeedback != null) {
          this.formativeFeedbacks = x.formativefeedback;
        }
      }
    }).add(this.getGradesOverview()).add(() => {
      this.teacherService.getCurrentStudentSummativeAssessment(this.myCourse._id, this.mystudent._id)
        .subscribe(
          s => {
            this.feedbacks = s;
            this.summativefeedback = s[s.length - 1];
            if (this.feedbacks.length == 0) {
              const sf = {} as SummativeFeedback;
              sf.checkBoxes = this.assessmentquestions;
              this.summativefeedback = sf;
              this.add = true;
              this.feedbacks = [];
            }
          })


    })
  }

  caseScore(e) {
    const sessionId = e.session_id
    this.sg['getFeedback'] = sessionId;
    this.store.dispatch([new SetCurrentCase(sessionId)]).subscribe(() => {
      this.router.navigate(['gradesOverview', 'feedback'], {
        queryParams: {
          session_id: sessionId,
        },
        state: e
      });
    })

  }

  loadSummative(s: SummativeFeedback) {
    this.dialog.open(this.summativeAseessmentPopUp, {data: {assessment: s, student: this.mystudent}});
  }

  saveSummativeAssessment() {
    this.loading = true;
    this.summativefeedback.student_id = this.mystudent._id;
    this.summativefeedback.course_id = this.myCourse._id;
    this.store.dispatch([new AddSummativeToCurrentStudent(this.summativefeedback)]).subscribe(x => {
      this.loading = false;
      this.backClicked();
    })
  }

  editAssessment() {
    this.backClicked();
  }

  backClicked() {
    this._location.back();
  }

  getGradesOverview() {
    this.loadingAndSaving = true;
    if (this.mystudent) {
      this.teacherService.getGradesOverviewUser(this.mystudent._id, this.myCourse._id)
        .subscribe(data => {
          this.caseData = data.message['caseData'] ? data.message['caseData'] : [];

          const arr = this.caseData.filter(x => this.myCourse.cases.some(y => y._id == x.case_id));

          // this.completedCases= arr.filter(x=>
          //   this.teacherService.dateCheck(this.myCourse.start,this.myCourse.end, x.last_completed));

          // Removing this filter until best way to figure out timezones..
          this.completedCases = arr;


          this.notCompletedCases = this.myCourse.cases.filter(x => !this.completedCases.find(y => y.case_id == x._id));

          this.scoreDataSource = new MatTableDataSource<any>(this.completedCases);
          this.scoreDataSource.paginator = this.studentpaginator;
          this.noData = this.scoreDataSource.connect().pipe(map(data => data.length === 0));

          this.loadingAndSaving = false;
        }, err => {
          this.err = err;
          this.loadingAndSaving = false;
        });
    }
  }

  addNew() {

  }

  checkme(last, first) {
    if (first && last) {
      return true;
    }
    if (!last) {
      return true;
    }
  }

}
