<mat-card class="case-library">
  <div class="list-height">
    <div class="header">
      <h1>My Classes</h1>

      <mat-form-field class="dropdown-field">
        <mat-select [(ngModel)]="selectedStatus">
          <mat-option value="active">Active</mat-option>
          <mat-option value="inactive">Done</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="stuFeed">
      <div class="item-list-container">
        <div class="t56">
          <div class="resume-container">
            <div class="no-classes-container" *ngIf="!classesData.length">
              <div>
                <img srcset="assets/icons/no_class.svg" />
              </div>
              <div>
                <span>You are not part of any active classes. Please contact your professor.</span>
              </div>
            </div>
            <ng-container>
              <div *ngIf="classesData.length" class="scroll-list">
                <mat-table #table [dataSource]="sortedClass[selectedStatus]" class="class-list">
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div class="status1" *ngIf="element">
                        <ng-container *ngIf="checkDate(element.end) || element.archived; else red">
                          <div *ngIf="!element.archived"><span class="blackoval"></span> DONE</div>
                          <div *ngIf="element.archived"><span class="redoval"></span> ARCHIVED</div>
                        </ng-container>
                        <ng-template #red>
                          <div *ngIf="isCourseUpcoming(element)"><span class="yellowoval"></span> UPCOMING</div>
                          <div *ngIf="!isCourseUpcoming(element)"><span class="greenoval"></span> ACTIVE</div>
                        </ng-template>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="classname">
                    <mat-header-cell *matHeaderCellDef> Class Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="duration">
                    <mat-header-cell *matHeaderCellDef> Duration </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div style="display: flex; flex-flow: column">
                        <div>{{ element.start | date }}</div>
                        <div>{{ element.end | date }}</div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    class="element-row"
                    (click)="goToClassDashboard(row)"
                  ></mat-row>
                </mat-table>
              </div>
            </ng-container>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
