<mat-card class="case-library">
  <!-- <div class="header">
    <h1>Patient Cases</h1>
  </div> -->
  <div class="stuFeed">
    <!-- <div class="patient-container">
      <div *ngIf="!activeCourses || !activeCases" class="resume-container">
        <div class="no-classes-container">
          <div>
            <img srcset="assets/icons/no_case.svg" />
          </div>
          <div>
            <span>{{
              activeCourses
                ? "Your active classes haven't been assigned any cases"
                : "You aren't part of any active classes"
            }}</span>
          </div>
        </div>
      </div>

      <div class="resume-container">
      
        <div *ngIf="false">
          <span class="resume-head">Resume your Case:</span>
          <div class="item-list container">
            <div class="no-resume" *ngIf="resumeButtonToggle == 'out'">
              You have no active cases! <br />
              Go to the case library to start a new case!<br />
            </div>
            <mat-card class="patient-card resume" *ngIf="resumeButtonToggle == 'in'">
              <mat-card-header>
                <mat-card-title>
                  <span class="title">{{ refresh.patient_name }}</span>
                  <div class="btn-grp">
                    <span
                      class="container playBtn p-0 mt-0"
                      (click)="resumeSession()"
                      matTooltip="Resume Case!"
                      [matTooltipPosition]="'above'"
                    >
                      <span class="play">
                        <mat-icon class="play_arrow">play_arrow</mat-icon>
                      </span>
                    </span>
                  </div>
                </mat-card-title>
              </mat-card-header>

              <mat-card-actions [class]="refresh.tags.category[0]">
                <span class="category-container">
                  <span class="icon"></span>
                  <span class="category">{{ refresh.tags.category[0] }}</span>
                </span>
                <span class="time"> <i class="fa far fa-clock mx-2"></i>45m+ </span>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div class="start-button">
          <button
            mat-raised-button
            color="primary"
            class="mat-raised-button mat-button case-library-btn"
            routerLink="/case-library"
          >
            Start a New Case
          </button>
        </div>
      </div>
    </div> -->
    <div *ngIf="institutionPermissions !== 'student'">
      <mat-divider inset="true" class="stu-feed"></mat-divider>
      <div class="new-case">
        <div class="list-container">
          <div class="new-case-header">
            <span>Start a new case:</span>
            <mat-card class="list-type">
              <mat-form-field>
                <mat-select [(value)]="listType" name="food">
                  <mat-option selected value="favourites"> My Favourites </mat-option>
                  <mat-option disabled value="recent"> Recent Cases <span class="soon">COMING SOON</span> </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card>
          </div>
          <div class="no-favourites" *ngIf="!data.length">
            You currently have no favourites.<br />
            When you favourite cases in the Case Library they will display here. <br />
            You can also start patients on the Case Library page.<br />
            <button
              mat-raised-button
              color="primary"
              class="mat-raised-button mat-button case-library-btn"
              routerLink="/case-library"
            >
              <span>+</span> Add New Patients
            </button>
          </div>
          <ul class="item-list container" *ngIf="data.length">
            <li
              class="patient mix {{ case.tags.category[0] }}"
              [attr.data-title]="case.name"
              *ngFor="let case of data | paginate: { itemsPerPage: itemsPerPage, currentPage: p }; let i = index"
            >
              <mat-card class="patient-card favourited">
                <mat-card-header>
                  <mat-card-title>
                    <span class="title" (click)="startCase(case._id)">{{ case.patient_name }}</span>
                    <div class="btn-grp">
                      <label
                        class="container favourites"
                        (click)="$event.stopPropagation()"
                        matTooltip="Add to QuickList"
                        [matTooltipPosition]="'above'"
                      >
                        <input
                          type="checkbox"
                          value="true"
                          (change)="favourite(case._id, i)"
                          (click)="$event.stopPropagation()"
                          [checked]="favourited[case._id]"
                        />
                        <div class="toggle">
                          <div class="star" [class.activated]="favourited[case._id]"></div>
                        </div>
                      </label>
                      <span
                        class="container playBtn"
                        (click)="startCase(case._id)"
                        matTooltip="Start Case!"
                        [matTooltipPosition]="'above'"
                      >
                        <span class="play">
                          <mat-icon class="play_arrow">play_arrow</mat-icon>
                        </span>
                      </span>
                    </div>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-actions [class]="case.tags.category[0]">
                  <span class="category-container">
                    <span class="icon"></span>
                    <span class="category">{{ case.tags.category[0] }}</span>
                  </span>
                  <span class="time"> <i class="fa far fa-clock mx-2"></i>30m+ </span>
                </mat-card-actions>
              </mat-card>
            </li>
          </ul>
        </div>
        <div *ngIf="active && data.length" class="pagination-container">
          <!-- {{p*}}-{{itemsPerPage * (p)}} of {{data.length}}  -->
          Page {{ p }} of {{ Math.ceil(data.length / itemsPerPage) }}
          <pagination-controls (pageChange)="p = $event" previousLabel="" responsive="true" nextLabel="" maxSize="0">
          </pagination-controls>
        </div>
      </div>
    </div>

    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </div>
</mat-card>
