import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CourseSummativeFeedback, Course, CourseInstitutionId } from '../interfaces/course';
import { FacultyMember } from '../interfaces/faculty-member';
import { GradesOverview } from '../interfaces/grades-overview';

@Injectable()
export class CoursesService {
  constructor(private readonly http: HttpClient) {}

  createCourse(course: Course): Observable<Course> {
    const url = environment.apiUrl.concat('/api/v1/institutions/courses');
    return this.http.post<Course>(url, course);
  }

  getCourseSummativeFeedback(id: string): Observable<CourseSummativeFeedback> {
    const url = environment.apiUrl.concat(`/api/v1/courses/${id}/summative-feedback`);
    return this.http.get<CourseSummativeFeedback>(url);
  }

  getCoursePerformance(courseId?: string): Observable<any> {
    const url = environment.apiUrl.concat(`/api/v1/students/student-performance${courseId && `?courseId=${courseId}`}`);
    return this.http.get<GradesOverview>(url);
  }

  getTrialPerformance(courseId?: string): Observable<any> {
    const url = environment.apiUrl.concat(`/api/v1/students/trial-performance${courseId && `?courseId=${courseId}`}`);
    return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
  }

  fetchCourses(): Observable<Course[]> {
    const url = environment.apiUrl.concat('/api/v1/institutions/courses');
    return this.http.get<Course[]>(url);
  }

  getCourseById(id: string): Observable<Course> {
    return this.http.get<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id));
  }

  getCourseOnlyById(id: string): Observable<CourseInstitutionId> {
    return this.http.get<CourseInstitutionId>(environment.apiUrl.concat('/api/v1/courses/').concat(id));
  }

  addStudents(id: string, studentIds: string[] = [], invitationTokens: string[] = []): Observable<Course> {
    return this.http.post<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id).concat('/add-students'), {
      studentIds,
      invitationTokens,
    });
  }

  enroll(id: string): Observable<Course> {
    return this.http.post<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id).concat('/enroll'), {});
  }

  enrollToCourse(courseId: string): Observable<CourseInstitutionId> {
    const url = environment.apiUrl.concat('/api/v1/user/course/enroll');
    return this.http.post<CourseInstitutionId>(url, {
      courseId,
    });
  }

  removeStudents(id: string, studentIds: string[] = [], invitationTokens: string[] = []): Observable<any> {
    const studentIdsQuery = studentIds.map(encodeURIComponent).join(',');
    const invitationTokensQuery = invitationTokens.map(encodeURIComponent).join(',');
    return this.http.delete<any>(
      environment.apiUrl
        .concat('/api/v1/courses/')
        .concat(id)
        .concat('/students')
        .concat('?studentIds=')
        .concat(studentIdsQuery)
        .concat('&invitationTokens=')
        .concat(invitationTokensQuery)
    );
  }

  fetchAllStudents(id: string, options?: { populate?: string[] }): Observable<FacultyMember[]> {
    let url = environment.apiUrl.concat('/api/v1/courses/').concat(id).concat('/students');

    if (options && options.populate && options.populate.length > 0) {
      url = `${url}?populate=${options.populate.join(',')}`;
    }

    return this.http.get<FacultyMember[]>(url);
  }

  setCases(id: string, caseIds: string[]): Observable<Course> {
    return this.http.post<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id).concat('/cases'), {
      caseIds,
    });
  }

  archive(id: string): Observable<Course> {
    return this.http.patch<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id), {
      archived: true,
    });
  }
  unArchive(id: string): Observable<Course> {
    return this.http.patch<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id), {
      archived: false,
    });
  }

  togglePublic(id: string, publicAccess: boolean): Observable<Course> {
    return this.http.patch<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id), {
      publicAccess: publicAccess,
    });
  }

  updateCourse(id: string, body: Partial<Course>): Observable<Course> {
    return this.http.patch<Course>(environment.apiUrl.concat('/api/v1/courses/').concat(id), {
      ...body,
    });
  }
}
