export const hasCourseEnded = (date) => {
  const expiryDate = new Date(date);
  const now = new Date();
  now.setUTCHours(0, 0, 0, 0);
  expiryDate.setUTCHours(0, 0, 0, 0);
  return now.getTime() > expiryDate.getTime();
};

export const isCourseActive = (start, end) => {
  const now = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);
  now.setUTCHours(0, 0, 0, 0);
  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(0, 0, 0, 0);
  return now.getTime() >= startDate.getTime() && now.getTime() <= endDate.getTime();
};
