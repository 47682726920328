<div class="">
  <div class="bg-color-primary text-center text-color-white font-weight-bold h3 py-3">Ideal time for attempts</div>

  <div class="stats-table">
    <div class="text-center py-3">Attempts highlighted took the student less time than ideal for the skill level. Table below shows the ideal time required for a case attempt in every skill level.</div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="level">
        <th mat-header-cell class="font-weight-bold text-color-secondary" *matHeaderCellDef>Skill Level</th>
        <td mat-cell *matCellDef="let element">
            {{element.level}}
        </td>
      </ng-container>

      <ng-container matColumnDef="includes">
        <th mat-header-cell class="font-weight-bold text-color-secondary" *matHeaderCellDef>Includes</th>
        <td mat-cell *matCellDef="let element">{{ element.includes }}</td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell class="font-weight-bold text-color-secondary" *matHeaderCellDef>
          Ideal Time
        </th>
        <td mat-cell *matCellDef="let element">>= {{ element.time }} mins
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
