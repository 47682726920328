import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThermometerService {

    temperature = 92.5;
    thermometerTween = null;
    thermometerTextTween = null;
    container = new createjs.Container();

    constructor() { }

    getContainer() {
        return this.container;
    };

    init(temp) {
        this.thermometerTween = null;
        this.thermometerTextTween = null;
        this.container = new createjs.Container();

        this.temperature = temp;
        var thermometer = new createjs.Bitmap("./assets/default/physical/Props/Thermometer/thermometer.png");
        thermometer.scaleX = thermometer.scaleY = 0.75;
        thermometer.x = 1024;
        thermometer.y = 180;
        this.container.addChild(thermometer);
        this.thermometerTween = createjs.Tween.get(thermometer, { paused: true })
            .to({ x: 340 }, 500, createjs.Ease.circOut)
            .wait(500)
            .to({ x: 1024 }, 500, createjs.Ease.circIn);

        var thermometerTextContainer = new createjs.Container();
        thermometerTextContainer.visible = false;

        var tBackground = new createjs.Shape();
        tBackground.graphics.beginFill('#0f77b4')
            .drawRoundRect(0, 0, 220, 110, 10)
            .endFill()
            .beginFill('#138fd8')
            .drawRoundRect(10, 10, 200, 90, 10);
        var tText = new createjs.Text((this.temperature + String.fromCharCode(176) + "C"), "60px Arial", "#000");
        tText.x = 15;
        tText.y = 20;
        thermometerTextContainer.addChild(tBackground);
        thermometerTextContainer.addChild(tText);
        this.container.addChild(thermometerTextContainer);

        this.thermometerTextTween = createjs.Tween.get(thermometerTextContainer, { paused: true })
            .to({ visible: false, x: 100, y: 105, alpha: 1, scaleX: 0.1, scaleY: 0.1 }, 300)
            .wait(800)
            .to({ visible: true }, 100)
            .to({ x: 180, y: 385, scaleX: 1, scaleY: 1 }, 800, createjs.Ease.circOut)
            .wait(2000)
            .to({ alpha: 0, visible: false }, 1000, createjs.Ease.circOut);
    };

    show() {
        //        var deferred = $q.defer();
        this.thermometerTween.setPosition(0);
        this.thermometerTween.setPaused(false);
        this.thermometerTextTween.call(function () {
            //            deferred.resolve('show temperature animation done, deselect tool');
        });
        this.thermometerTextTween.setPosition(0);
        this.thermometerTextTween.setPaused(false);

        //      return deferred.promise;
    };

    Hide() {
        this.thermometerTween.setPaused(true)
            .setPosition(0);
        this.thermometerTextTween.setPaused(true)
            .setPosition(0);
    };
}
