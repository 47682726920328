import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

const defaultData = [
  {
    level: 1,
    includes: 'History Taking',
    time: 2,
  },
  {
    level: 2,
    includes: 'History, Physical Exam',
    time: 5,
  },
  {
    level: 3,
    includes: 'History, Physical, Diagnosis & Treatment with failures',
    time: 10,
  },
  {
    level: 4,
    includes: 'History, Physical, Diagnosis & Treatment with failures',
    time: 10,
  },
];
const defaultColumns = ['level', 'includes', 'time'];
@Component({
  selector: 'app-help-dialog',
  styleUrls: ['./help-dialog.component.scss'],
  templateUrl: './help-dialog.component.html',
})
export class HelpDialog implements OnInit {
  displayedColumns;
  dataSource;
  constructor(public dialogRef: MatDialogRef<HelpDialog>) {}

  ngOnInit() {
    this.dataSource = defaultData;
    this.displayedColumns = defaultColumns;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
