declare var Chart: any;

import { Component, OnInit, ViewChild, ElementRef, NgModule } from '@angular/core';
import { FetchAssignmentDataService } from '../../_services/index';
import { SimpleGlobal } from 'ng2-simple-global';
import { Router } from '@angular/router';

@Component({
  selector: 'last-assignment',
  providers: [FetchAssignmentDataService],
  templateUrl: './stu-piechart.component.html',
  styleUrls: ['./stu-piechart.component.scss']
})
export class StuPiechartComponent implements OnInit {
  public studentScoreData: number[];
  
  loadingAndSaving = false;
  data = {
    student: {
      knowledgeScore: 0,
      timeScore: 0,
      costScore: 0
    },

    state: {
      selectedState: 'knowledgeScore',
    }
  } 

  constructor(private sg: SimpleGlobal, private router: Router, private FetchAssignmentDataService: FetchAssignmentDataService) {
    this.loadingAndSaving = true;
    this.FetchAssignmentDataService.getLastCaseFeedback().subscribe(result => {
     
      this.loadingAndSaving = false;
      this.data.student.knowledgeScore = Math.ceil(result["knowledgeScore"]);
      this.data.student.timeScore = Math.ceil(result["time"]);
      this.data.student.costScore = Math.ceil(result["costDeviation"]);
      this.setup();
      

    }, err=>{
      this.loadingAndSaving = false;
    },
    ()=>{ console.log('HTTP request completed.')}
    );
  
  }

  ngOnInit() {
    
  }

  setup(){
    Chart.plugins.register({
      afterDatasetsDraw: function (chart) {
        if (!chart.options.centerText) return;
        var ctx = chart.ctx,
          width = chart.options.width,
          height = chart.options.height;
        var fontSize = (height / 80).toFixed(0);
        ctx.font = `600 ${fontSize}em Open Sans`;
        // ctx.font = fontSize + "em Open Sans";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#706b87";
        var numberText = chart.data.datasets[0].data[1],
          textX = Math.round((width - ctx.measureText(numberText).width) / 2),
          textY = (height / 2) - 5;
        ctx.fillText(numberText, textX, textY);
        ctx.restore();

        var ctx = chart.ctx,
          width = chart.options.width,
          height = chart.options.height;
        var fontSize = (height / 200).toFixed(2);
        ctx.font = `300 ${fontSize}em Open Sans`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#706b87";
        var bodyText = chart.options.centerTextPhrase,
          textX = Math.round((width - ctx.measureText(bodyText).width) / 2),
          textY = ((height / 2) * 1.28);
        ctx.fillText(bodyText, textX, textY);
        ctx.restore();
      }
    });

    Chart.plugins.register({
      afterDatasetsUpdate: function (chart) {
        var ctx = chart.ctx,
          width = chart.options.width,
          height = chart.options.height;
        var fontSize = (height / 60).toFixed(0);
        ctx.font = fontSize + "em Open Sans";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#706b87";
        var numberText = chart.data.datasets[0].data[1],
          textX = Math.round((width - ctx.measureText(numberText).width) / 2),
          textY = (height / 2) - 5;
        ctx.fillText(numberText, textX, textY);
      }
    });
    this.studentScoreData = [100 - this.data.student[this.data.state.selectedState], this.data.student[this.data.state.selectedState]];

  }

  public studentScoreColors: Array<any> = [
    {
      backgroundColor: ["#ebebeb", '#8bc34a'],
      pointBackgroundColor: ['#8bc34a', "#ebebeb"],
      pointBorderColor: ['#8bc34a', "#ebebeb"],
    },
  ];
  public studentScoreOptions: any = {
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    responsive: true,
    centerText: true,
    centerNumber: this.data.student[this.data.state.selectedState],
    centerTextPhrase: 'Your Score',
    cutoutPercentage: 75,
    height: 200,
    width: 400,
  };

  //public classScoreData: number[] = [100 - this.data.class.knowledgeScore, this.data.class.knowledgeScore];
  public classScoreColors: Array<any> = [
    {
      backgroundColor: ["#ebebeb", "#8781a4"]
    }
  ];
  public classScoreOptions: any = {
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    cutoutPercentage: 70,
    centerText: true,
    responsive: true,
    //centerNumber: this.data.class[this.data.state.selectedState],
    centerTextPhrase: "Class Score",
    height: 150,
    width: 300,
  };

  public doughnutChartType: string = 'doughnut';

  public focusScores(score): void {
    this.data.state.selectedState = score;
    this.studentScoreData = [100 - this.data.student[this.data.state.selectedState], this.data.student[this.data.state.selectedState]]
   // this.classScoreData = [100 - this.data.class[this.data.state.selectedState], this.data.class[this.data.state.selectedState]];
  }
}
