import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SummativeFeedback } from 'app/_models/teacher-dashboard.model';
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Signature } from '../../_models/signature';
import { coursegrade as coursescore, } from '../../_models/teacher-dashboard.model';
import { Course } from '../../lms/interfaces/course';
import { FacultyMember } from '../../lms/interfaces/faculty-member';
import { InstitutionService } from '../../lms/services/institution.service';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';

@Component({
  selector: 'app-summative-assessment',
  templateUrl: './summative-assessment.component.html',
  styleUrls: ['./summative-assessment.component.scss'],
  providers: [InstitutionService],
})
export class SummativeAssessmentComponent implements OnInit {
  loading: boolean;
  score: coursescore = new coursescore();
  isStudent: boolean;

  private signature: Signature;
  private logoImgSrc: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { assessment: SummativeFeedback, mystudent: FacultyMember, myCourse: Course },
    private teacherService: TeacherDashboardDataService,
    private institutionService: InstitutionService,
  ) {
  }

  ngOnInit(): void {
  }

  openPanel() {
    const canvas = <HTMLCanvasElement>document.getElementById('myCanvas');

    const ctx = canvas.getContext('2d');
    const certificateImg = new Image();
    certificateImg.src = './assets/img/app/course_cert_cp.png';
    const signatureImg = new Image();
    const logoImg = new Image();

    const data = this.data;
    const score = this.score;
    certificateImg.onload = () => {
      const ratio = certificateImg.width / certificateImg.height;
      const height = 1000;
      const width = ratio * height;

      ctx.drawImage(certificateImg, 20, 1, width, height);
      ctx.moveTo(500, 410);
      ctx.textAlign = 'center';
      ctx.font = 'normal small-caps 800 40px/150% Arial';

      const baseY = 460;
      ctx.fillText(data.mystudent.firstName
        + ' ' + data.mystudent.lastName, 500, baseY);
      ctx.font = '20px Arial';

      ctx.fillText('Has completed course ' + data.myCourse.name + ' with score of '
        + score.grade.toFixed(2) + '%', 500, baseY + 40);

      ctx.fillText('On ' + moment(data.assessment.created).format('DD/MM/YYYY'), 500, baseY + 70)

      signatureImg.src = this.signature?.signatureImg ? `data:image/jpeg;base64,${this.signature.signatureImg}` : '';
      logoImg.src = this.logoImgSrc ? `data:image/jpeg;base64,${this.logoImgSrc}` : '';
    }

    signatureImg.onload = () => {
      const ratio = signatureImg.height / signatureImg.width;
      const width = 200;
      ctx.drawImage(signatureImg, 150, 700, width, ratio * width);
      ctx.font = '16px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(this.signature?.signatureText ?? '', 250, 820, 400);
    }

    logoImg.onload = () => {
      const ratio = logoImg.height / logoImg.width;
      const width = 200;
      const height = ratio * width;
      ctx.drawImage(logoImg, 650, 700, width, height);
    }
  }

  exportas() {
    html2canvas(document.getElementById('myCanvas'), {
      allowTaint: true,
      useCORS: true,
      scale: 0.7
    }).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      const doc = new jsPDF('l', 'pt', 'letter');

      doc.addImage(img, 'PNG', 20, 0, 750, 600);
      doc.save(this.data.mystudent.firstName
        + '_' + this.data.mystudent.lastName + '_' + this.data.myCourse._id + '.pdf');
    });
  }

  getValue() {
    let i = 0;
    this.data.assessment.checkBoxes.forEach((x) => {
      if (x.value === true) {
        i++;
      }
    });

    return i / this.data.assessment.checkBoxes.length * 100;
  }

  getGrade() {
    this.loading = true;
    this.isStudent = JSON.parse(localStorage.getItem('currentUser')).occupation === 'Student';

    const observables: Observable<any>[] = [this.fetchSignature(), this.fetchInstitutionLogo()];

    if (this.isStudent) {
      observables.push(this.teacherService.getCourseScoreStudent(this.data.myCourse));
    } else {
      observables.push(this.teacherService.getCourseScore(this.data.myCourse._id, this.data.mystudent.memberId));
    }

    forkJoin(observables)
      .subscribe(([, , data]) => {
        this.score = data;
        if (this.score.grade == null) {
          this.score.grade = 0;
        }
        this.openPanel();
        this.loading = false;
      }, err => {
        this.score.grade = -1;
        this.score.err = err;
        this.loading = false;
      })
  }

  private fetchSignature(): Observable<Signature | undefined> {
    if (!this.data.myCourse.professor?.memberId) {
      return of(undefined);
    }
    return this.teacherService
      .getSignature(this.data.myCourse.professor.memberId)
      .pipe(tap((signature) => {
        this.signature = signature;
      }));
  }

  private fetchInstitutionLogo() {
    return this.institutionService
      .retrieveLogo()
      .pipe(tap((response) => {
        this.logoImgSrc = response.logoImg;
      }));
  }
}
