import { Injectable, OnInit } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { environment } from '../../../environments/environment';
import { DebugService } from '../../debug.service';
import { ClockService } from './clock-service.service';
import { CreatejsShapeService } from './createjs-shape.service';
import { SpritesheetService } from './spritesheet.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService implements OnInit {
  palpationSpritesDown = [];
  patientId;
  soundList;
  stageName;
  pediatric = false;
  debug: any = true;
  // HOT SPOT indicator for debug purpose.
  alphaDebug = environment.alphaDebug;
  background;
  scoreSpecialTools;
  sounds = [];
  soundURL;
  soundSprites;
  soundSprites2;
  perspContainer;
  hitContainer;
  contentContainer;
  palpulationDefaultSounds = [];
  respirationTween = null;
  container = new createjs.Container();
  currentPersp = 'Front';
  physicalActions = new Set();
  // scoreAuscultation = new Set();
  // scoreSuperficial = new Set();
  // scoreDeep = new Set();
  scorePercussion = new Set();
  scorePosition = new Set();
  scoreOther = new Set();
  scoreTotal = new Array();
  patientSprites = [];
  palpationSprites = [];
  patientImage;
  palpationImage;
  backgroundImg;
  patientDownBackgroundImg;
  patientDownHotSpot;
  patientDownBackground;
  choices;
  persps = [
    'Front',
    'RightSide',
    'LeftSide',
    'Back',
    'Down',
    'LeftArmPit',
    'RightArmPit',
    'TorsoFront',
    'TorsoBack',
    'TorsoRight',
    'TorsoLeft',
    'HeadFront',
    'HeadLeft',
    'HeadRight',
    'HeadBack',
    'Feet',
    'Hands',
  ];
  respiration = {
    rate: 300,
    toroSpriteName: 'patientTorso',
    headSpriteName: 'patientHead',
  };
  patientDownImageSlice = [
    { name: 'Down.Perc.Bowels', x: 0, y: 422, w: 300, h: 216 },
    { name: 'Down.Ausc.Lungs', x: 0, y: 201, w: 270, h: 220 },
    { name: 'Down.Ausc.Bowels', x: 0, y: 0, w: 282, h: 200 },
    { name: 'Down.Perc.Lungs', x: 301, y: 422, w: 248, h: 215 },
    { name: 'Down.Perc.LungsDead', x: 283, y: 0, w: 277, h: 250 },
    { name: 'Down.Perc.Liver', x: 283, y: 251, w: 82, h: 108 },
    { name: 'Down.Perc.Heart', x: 366, y: 251, w: 134, h: 103 },
  ];
  pediatricPatientDownImageSlice = [
    {
      name: 'Down.Perc.Bowels',
      x: 189,
      y: 0,
      w: 338,
      h: 252,
    },
    {
      name: 'Down.Ausc.Lungs',
      x: 0,
      y: 691,
      w: 265,
      h: 252,
    },
    {
      name: 'Down.Ausc.Bowels',
      x: 189,
      y: 0,
      w: 338,
      h: 252,
    },
    {
      name: 'Down.Perc.Lungs',
      x: 0,
      y: 691,
      w: 265,
      h: 252,
    },
    {
      name: 'Down.Perc.LungsDead',
      x: 0,
      y: 360,
      w: 321,
      h: 279,
    },
    { name: 'Down.Perc.Liver', x: 283, y: 251, w: 82, h: 108 },
    {
      name: 'Down.Perc.Heart',
      x: 322,
      y: 360,
      w: 104,
      h: 155,
    },
  ];

  respirationHeadTween;
  patientImageSlice;
  palpation = [
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Rect',
            x: 160,
            y: 345,
            w: 120,
            h: 50,
          },
        ],
      },
      sounds: ['patient.yesPainIs'],
      loopSound: false,
    },
    {
      type: 'deeppalpation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Rect',
            x: 160,
            y: 345,
            w: 120,
            h: 50,
          },
        ],
      },
      sounds: ['patient.reallyHurt'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: true,
      persp: null,
      hitArea: null,
      sounds: ['patient.percussionDull'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Palp.Thyroid',
          x: 565,
          y: 700,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.thyroid'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Palp.Subclavicular',
          x: 225,
          y: 880,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.subclavicular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Palp.Superclavicular',
          x: 225,
          y: 800,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.superclavicular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Palp.Cervical',
          x: 325,
          y: 630,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.cervical'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Palp.Submandipular',
          x: 410,
          y: 505,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.submandibular'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'HeadFront',
      hitArea: {
        image: {
          name: 'Front.Head.Ausc.Lungs',
          x: 370,
          y: 1055,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadLeft',
      hitArea: {
        image: {
          name: 'Left.Head.Palp.Cervical',
          x: 420,
          y: 575,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.cervical'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadLeft',
      hitArea: {
        image: {
          name: 'Left.Head.Palp.SuperClavicular',
          x: 380,
          y: 825,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.superclavicular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadLeft',
      hitArea: {
        image: {
          name: 'Left.Head.Palp.Thyroid',
          x: 665,
          y: 710,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.thyroid'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadLeft',
      hitArea: {
        image: {
          name: 'Left.Head.Palp.Submandipular',
          x: 610,
          y: 500,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.submandibular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadRight',
      hitArea: {
        image: {
          name: 'Right.Head.Palp.Cervical',
          x: 780,
          y: 575,
          scaleX: -1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.cervical'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadRight',
      hitArea: {
        image: {
          name: 'Right.Head.Palp.SuperClavicular',
          x: 820,
          y: 825,
          scaleX: -1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.superclavicular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadRight',
      hitArea: {
        image: {
          name: 'Right.Head.Palp.Thyroid',
          x: 540,
          y: 710,
          scaleX: -1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.thyroid'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadRight',
      hitArea: {
        image: {
          name: 'Right.Head.Palp.Submandipular',
          x: 610,
          y: 500,
          scaleX: -1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.submandibular'],
      loopSound: false,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadBack',
      hitArea: {
        image: {
          name: 'Back.Head.Palp.Cervical',
          x: 460,
          y: 720,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.cervical'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Perc.Bowels',
          x: 260,
          y: 620,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.bowels'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Perc.LungsDeadSpace',
          x: 280,
          y: 130,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungsDead'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Perc.Lungs',
          x: 330,
          y: 125,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungs'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Perc.Heart',
          x: 500,
          y: 240,
          scaleX: 1.1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.heart'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Ausc.Bowels',
          x: 260,
          y: 620,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.bowels'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: {
          name: 'Front.Torso.Ausc.Lungs',
          x: 330,
          y: 125,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 260,
            y: 140,
            r: 35,
          },
        ],
      },
      sounds: ['patient.ausc.aortic'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 290,
            y: 250,
            r: 35,
          },
        ],
      },
      sounds: ['patient.ausc.epigastric'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 360,
            y: 250,
            r: 35,
          },
        ],
      },
      sounds: ['patient.ausc.apex'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 316,
            y: 200,
            r: 35,
          },
        ],
      },
      sounds: ['patient.ausc.rightventrical'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoFront',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 340,
            y: 140,
            r: 35,
          },
        ],
      },
      sounds: ['patient.ausc.pulmonary'],
      loopSound: true,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'TorsoBack',
      hitArea: {
        image: {
          name: 'Back.Torso.Palp.Lungs',
          x: 345,
          y: 140,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.palp.lungs'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoBack',
      hitArea: {
        image: {
          name: 'Back.Torso.Perc.LungsDeadSpace',
          x: 290,
          y: 100,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungsDead'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoBack',
      hitArea: {
        image: {
          name: 'Back.Torso.Ausc.Lungs',
          x: 345,
          y: 140,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoBack',
      hitArea: {
        image: {
          name: 'Back.Torso.Ausc.Bronc',
          x: 534,
          y: 80,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.bronchial'],
      loopSound: true,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'TorsoBack',
      hitArea: {
        image: {
          name: 'Back.Torso.Perc.Lungs',
          x: 345,
          y: 140,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungs'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoRight',
      hitArea: {
        image: {
          name: 'Right.Torso.Ausc.Lungs',
          x: 360,
          y: 145,
          scaleX: 0.8,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoRight',
      hitArea: {
        image: {
          name: 'Right.Torso.Ausc.Bowels',
          x: 544,
          y: 775,
          scaleX: -1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.bowels'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoLeft',
      hitArea: {
        image: {
          name: 'Left.Torso.Ausc.Lungs',
          x: 510,
          y: 170,
          scaleX: 0.8,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'TorsoLeft',
      hitArea: {
        image: {
          name: 'Left.Torso.Ausc.Bowels',
          x: 654,
          y: 600,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.bowels'],
      loopSound: true,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Perc.Lungs',
          x: 424,
          y: 50,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungs'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Perc.LungsDead',
          x: 414,
          y: 40,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.lungsDead'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Perc.Liver',
          x: 500,
          y: 260,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.liver'],
      loopSound: false,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Perc.Heart',
          x: 584,
          y: 140,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.heart'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Ausc.Lungs',
          x: 394,
          y: 50,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.lungs'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Ausc.Bowels',
          x: 540,
          y: 400,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.ausc.bowels'],
      loopSound: true,
    },
    {
      type: 'percussion',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: {
          name: 'Down.Perc.Bowels',
          x: 540,
          y: 400,
          scaleX: 1,
        },
        shapes: null,
      },
      sounds: ['patient.perc.bowels'],
      loopSound: false,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 300,
            y: 100,
            r: 25,
          },
        ],
      },
      sounds: ['patient.ausc.aortic'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 360,
            y: 80,
            r: 25,
          },
        ],
      },
      sounds: ['patient.ausc.pulmonary'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 350,
            y: 150,
            r: 25,
          },
        ],
      },
      sounds: ['patient.ausc.epigastric'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 410,
            y: 150,
            r: 25,
          },
        ],
      },
      sounds: ['patient.ausc.apex'],
      loopSound: true,
    },
    {
      type: 'auscultation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Circle',
            x: 366,
            y: 120,
            r: 25,
          },
        ],
      },
      sounds: ['patient.ausc.rightventrical'],
      loopSound: true,
    },
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Rect',
            x: 240,
            y: 200,
            w: 120,
            h: 50,
          },
        ],
      },
      sounds: ['patient.yesPainIs'],
      loopSound: false,
    },
    {
      type: 'deeppalpation',
      isDefault: false,
      persp: 'Down',
      hitArea: {
        image: null,
        shapes: [
          {
            shape: 'Rect',
            x: 240,
            y: 200,
            w: 120,
            h: 50,
          },
        ],
      },
      sounds: ['patient.reallyHurt'],
      loopSound: false,
    },
  ];
  palpationImageSlice = [
    {
      name: 'Front.Torso.Ausc.Bowels',
      x: 189,
      y: 0,
      w: 338,
      h: 252,
    },
    {
      name: 'Front.Torso.Perc.Bowels',
      x: 189,
      y: 0,
      w: 338,
      h: 252,
    },
    {
      name: 'Back.Head.Palp.Cervical',
      x: 532,
      y: 837,
      w: 149,
      h: 60,
    },
    {
      name: 'Right.Head.Palp.Thyroid',
      x: 471,
      y: 253,
      w: 37,
      h: 59,
    },
    {
      name: 'Right.Head.Palp.SuperClavicular',
      x: 0,
      y: 0,
      w: 172,
      h: 56,
    },
    {
      name: 'Right.Head.Palp.Cervical',
      x: 532,
      y: 691,
      w: 148,
      h: 145,
    },
    {
      name: 'Right.Head.Palp.Submandipular',
      x: 528,
      y: 479,
      w: 126,
      h: 93,
    },
    {
      name: 'Left.Head.Palp.Thyroid',
      x: 471,
      y: 253,
      w: 37,
      h: 59,
    },
    {
      name: 'Left.Head.Palp.SuperClavicular',
      x: 0,
      y: 0,
      w: 172,
      h: 56,
    },
    {
      name: 'Left.Head.Palp.Cervical',
      x: 532,
      y: 691,
      w: 148,
      h: 145,
    },
    {
      name: 'Left.Head.Palp.Submandipular',
      x: 528,
      y: 479,
      w: 126,
      h: 93,
    },
    {
      name: 'Front.Head.Ausc.Lungs',
      x: 423,
      y: 640,
      w: 251,
      h: 40,
    },
    {
      name: 'Front.Head.Palp.Thyroid',
      x: 377,
      y: 640,
      w: 45,
      h: 50,
    },
    {
      name: 'Front.Head.Palp.Subclavicular',
      x: 0,
      y: 944,
      w: 385,
      h: 35,
    },
    {
      name: 'Front.Head.Palp.Superclavicular',
      x: 0,
      y: 640,
      w: 376,
      h: 50,
    },
    {
      name: 'Front.Head.Palp.Cervical',
      x: 189,
      y: 253,
      w: 281,
      h: 96,
    },
    {
      name: 'Front.Head.Palp.Submandipular',
      x: 322,
      y: 516,
      w: 193,
      h: 104,
    },
    {
      name: 'Right.Torso.Ausc.Bowels',
      x: 528,
      y: 303,
      w: 152,
      h: 175,
    },
    {
      name: 'Right.Torso.Ausc.Lungs',
      x: 528,
      y: 0,
      w: 153,
      h: 302,
    },
    {
      name: 'Left.Torso.Ausc.Bowels',
      x: 528,
      y: 303,
      w: 152,
      h: 175,
    },
    {
      name: 'Left.Torso.Ausc.Lungs',
      x: 0,
      y: 57,
      w: 188,
      h: 302,
    },
    {
      name: 'Back.Torso.Palp.Lungs',
      x: 266,
      y: 691,
      w: 265,
      h: 225,
    },
    {
      name: 'Back.Torso.Ausc.Lungs',
      x: 266,
      y: 691,
      w: 265,
      h: 225,
    },
    {
      name: 'Back.Torso.Perc.LungsRight',
      x: 396,
      y: 691,
      w: 135,
      h: 225,
    },
    {
      name: 'Back.Torso.Ausc.LungsRight',
      x: 396,
      y: 691,
      w: 135,
      h: 225,
    },
    {
      name: 'Back.Torso.Perc.LungsLeft',
      x: 266,
      y: 691,
      w: 130,
      h: 225,
    },
    {
      name: 'Back.Torso.Ausc.LungsLeft',
      x: 266,
      y: 691,
      w: 130,
      h: 225,
    },
    {
      name: 'Back.Torso.Perc.Lungs',
      x: 266,
      y: 691,
      w: 265,
      h: 225,
    },
    {
      name: 'Back.Torso.Ausc.Bronc',
      x: 427,
      y: 360,
      w: 81,
      h: 124,
    },
    {
      name: 'Front.Torso.Perc.LungsDeadSpace',
      x: 0,
      y: 360,
      w: 321,
      h: 279,
    },
    {
      name: 'Back.Torso.Perc.LungsDeadSpace',
      x: 0,
      y: 360,
      w: 321,
      h: 279,
    },
    {
      name: 'Front.Torso.Perc.Heart',
      x: 322,
      y: 360,
      w: 104,
      h: 155,
    },
    {
      name: 'Front.Torso.Ausc.LungsRight',
      x: 130,
      y: 691,
      w: 135,
      h: 252,
    },
    {
      name: 'Front.Torso.Ausc.Lungs',
      x: 0,
      y: 691,
      w: 265,
      h: 252,
    },
    {
      name: 'Front.Torso.Ausc.LungsLeft',
      x: 0,
      y: 691,
      w: 130,
      h: 252,
    },
    {
      name: 'Pediatric.Ausc.Lungs',
      x: 266,
      y: 691,
      w: 265,
      h: 225,
    },
    {
      name: 'Front.Torso.Perc.LungsLeft',
      x: 0,
      y: 691,
      w: 130,
      h: 252,
    },
    {
      name: 'Front.Torso.Perc.Lungs',
      x: 0,
      y: 691,
      w: 130,
      h: 252,
    },
    {
      name: 'Front.Torso.Perc.LungsRight',
      x: 130,
      y: 691,
      w: 135,
      h: 252,
    },
  ];
  hotspots = {
    adult: {
      raiseRightArmHotSpot: [
        {
          shape: 'Rect',
          x: 190,
          y: 270,
          w: 55,
          h: 100,
        },
      ],
      raiseLeftArmHotSpot: [
        {
          shape: 'Rect',
          x: 345,
          y: 270,
          w: 55,
          h: 100,
        },
      ],
      headHotSpot: [
        {
          shape: 'Circle',
          x: 290,
          y: 200,
          r: 65,
        },
      ],
      torsoHotSpot: [
        {
          shape: 'Rect',
          x: 245,
          y: 265,
          w: 100,
          h: 190,
        },
      ],
      handsHotSpot: [
        {
          shape: 'Rect',
          x: 120,
          y: 425,
          w: 100,
          h: 150,
        },
        {
          shape: 'Rect',
          x: 370,
          y: 425,
          w: 100,
          h: 150,
        },
      ],
      legsHotSpot: [
        {
          shape: 'Rect',
          x: 220,
          y: 455,
          w: 150,
          h: 200,
        },
      ],
    },
    pediatric: {
      headHotSpot: [
        {
          shape: 'Circle',
          x: 290,
          y: 240,
          r: 55,
        },
      ],
      torsoHotSpot: [
        {
          shape: 'Rect',
          x: 240,
          y: 290,
          w: 100,
          h: 100,
        },
      ],
      handsHotSpot: [
        {
          shape: 'Rect',
          x: 140,
          y: 275,
          w: 100,
          h: 50,
        },
        {
          shape: 'Rect',
          x: 340,
          y: 275,
          w: 100,
          h: 50,
        },
      ],
      legsHotSpot: [
        {
          shape: 'Rect',
          x: 200,
          y: 390,
          w: 180,
          h: 100,
        },
      ],
    },
  };

  views = [
    {
      persp: 'Front',
      sprites: [
        {
          name: 'patientTorso',
          x: 200,
          y: 240,
          scaleX: 1,
          scaleY: 1,
          regX: 60,
          regY: 0,
        },
        {
          name: 'patientHead',
          x: 250,
          y: 145,
          scaleX: 1,
          scaleY: 1,
          regX: 10,
          regY: 0,
        },
        {
          name: 'patientBody',
          x: 220,
          y: 446,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'RightSide',
      sprites: [
        {
          name: 'patientRightSide',
          x: 80,
          y: 145,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'LeftSide',
      sprites: [
        {
          name: 'patientLeftSide',
          x: 280,
          y: 145,
          scaleX: -1,
          scaleY: 1,
          regX: 200,
          regY: 0,
        },
      ],
    },
    {
      persp: 'Back',
      sprites: [
        {
          name: 'patientBack',
          x: 190,
          y: 120,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'Down',
      sprites: [
        {
          name: 'patientDown',
          x: 50,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'RightArmPit',
      sprites: [
        {
          name: 'patientRightArmPit',
          x: 443,
          y: 136,
          scaleX: -1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'LeftArmPit',
      sprites: [
        {
          name: 'patientLeftArmPit',
          x: 147,
          y: 136,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'TorsoFront',
      sprites: [
        {
          name: 'patientTorsoFront',
          x: 0,
          y: 2,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'TorsoRight',
      sprites: [
        {
          name: 'patientTorsoRight',
          x: 100,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'TorsoLeft',
      sprites: [
        {
          name: 'patientTorsoLeft',
          x: 500,
          y: 0,
          scaleX: -1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'TorsoBack',
      sprites: [
        {
          name: 'patientTorsoBack',
          x: 18,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'HeadFront',
      sprites: [
        {
          name: 'patientHeadFront',
          x: 11,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'HeadLeft',
      sprites: [
        {
          name: 'patientHeadRight',
          x: 500,
          y: 0,
          scaleX: -1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'HeadRight',
      sprites: [
        {
          name: 'patientHeadLeft',
          x: 100,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'HeadBack',
      sprites: [
        {
          name: 'patientHeadBack',
          x: 10,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'Hands',
      sprites: [
        {
          name: 'patientHands',
          x: 0,
          y: 60,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
    {
      persp: 'Feet',
      sprites: [
        {
          name: 'patientFeet',
          x: 160,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          regX: 0,
          regY: 0,
        },
      ],
    },
  ];
  constructor(
    private spriteService: SpritesheetService,
    private createjsShapeService: CreatejsShapeService,
    private clockService: ClockService,
    private debugService: DebugService,
    private sg: SimpleGlobal
  ) {
    this.perspContainer = null;

    this.hitContainer = null;
    this.contentContainer = null;
    this.palpulationDefaultSounds = [];
    this.respirationTween = null;
    this.container = new createjs.Container();
    this.currentPersp = 'Front';
    this.physicalActions = new Set();
    // this.scoreAuscultation = new Set();
    // this.scoreSuperficial = new Set();
    // this.scoreDeep = new Set();
    this.scorePercussion = new Set();
    this.scoreSpecialTools = new Set();
    this.scorePosition = new Set();
    this.scoreOther = new Set();
    this.scoreTotal = new Array();
    this.patientSprites = [];
    this.palpationSprites = [];
    if (createjs.Sound !== undefined && createjs.Sounds !== null) {
      createjs.Sound.removeAllSounds();
    }
  }
  ngOnInit() {

    this.debugService.getalphaDebug().subscribe(f => this.alphaDebug = f);

  }

  // Get Functions
  getPersps() {
    return this.persps;
  }

  getContainer() {
    return this.container;
  }

  getPerspective() {
    return this.currentPersp;
  }
  selectSpecial(name) {
    this.scoreSpecialTools.add(name);
  }
  getScore() {
    if (this.scorePercussion.size > 0) {
      this.scoreTotal['percussion'] = Array.from(this.scorePercussion);
      //// console.log('scorePercussion', scorePercussion);
    }
    //
    // if (this.scoreSuperficial.size > 0) {
    //   this.scoreTotal['superficialpalpation'] = Array.from(
    //     this.scoreSuperficial
    //   );
    //   // console.log('scoreSuperficial', scoreSuperficial);
    // }
    //
    // if (this.scoreDeep.size > 0) {
    //   this.scoreTotal['deeppalpation'] = Array.from(this.scoreDeep);
    //   // console.log('scoreDeep', scoreDeep);
    // }
    // if (this.scoreAuscultation.size > 0) {
    //   this.scoreTotal['auscultation'] = Array.from(this.scoreAuscultation);
    //   //// console.log('scoreAuscultation', scoreAuscultation);
    // }
    if (this.scoreSpecialTools.size > 0) {
      this.scoreTotal['specialtools'] = Array.from(this.scoreSpecialTools);
    }

    const scoreOther = Array.from(this.scoreOther);
    // console.log('scoreOther', scoreOther);

    /*

         for(let a of scoreOther){
           let flag = 0;
            for(let b of Object.keys(this.scoreTotal)){
              // console.log("a : ", a);
              if(a===b){
                flag = 1;

              }
            }
            if (flag===1){
              let index: number = scoreOther.indexOf(a);
              if (index !== -1) {
                scoreOther.splice(index, 1);
        }
            }


         }

    */

    this.scoreTotal['basetools'] = scoreOther;
    return this.scoreTotal;
  }

  init(data) {
    const that = this;


    this.patientImageSlice = this.spriteService.getSheet(this.sg['case_id']);

    this.soundSprites = [
      {
        name: 'patient.no',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/no.mp3',
      },
      {
        name: 'patient.no2',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/no.mp3',
      },
      {
        name: 'patient.no3',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/no.mp3',
      },
      {
        name: 'patient.reallyHurt',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/reallyHurt.mp3',
      },
      {
        name: 'patient.yesPainIs',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/painIs.mp3',
      },
      {
        name: 'patient.ausc.bronchial',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.bronchial.mp3',
      },
      {
        name: 'patient.ausc.rightventrical',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.rightventrical.mp3',
      },
      {
        name: 'patient.ausc.lungs',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.lungs.mp3',
      },
      {
        name: 'patient.ausc.lungsleft',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.lungsleft.mp3',
      },
      {
        name: 'patient.ausc.lungsright',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.lungsright.mp3',
      },
      {
        name: 'patient.ausc.bowels',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.bowels.mp3',
      },
      {
        name: 'patient.ausc.pulmonary',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.pulmonary.mp3',
      },
      {
        name: 'patient.ausc.apex',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.apex.mp3',
      },
      {
        name: 'patient.ausc.aortic',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.aortic.mp3',
      },
      {
        name: 'patient.ausc.epigastric',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.ausc.epigastric.mp3',
      },
      {
        name: 'patient.percussionDull',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/percussionDull.mp3',
      },
      {
        name: 'patient.percussionTimpani',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/percussionTimpani.mp3',
      },
      {
        name: 'patient.perc.bowels',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.bowels.mp3',
      },
      {
        name: 'patient.perc.lungs',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.lungs.mp3',
      },
      {
        name: 'patient.perc.lungsright',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.lungsright.mp3',
      },
      {
        name: 'patient.perc.lungsleft',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.lungsleft.mp3',
      },
      {
        name: 'patient.perc.heart',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.heart.mp3',
      },
      {
        name: 'patient.perc.lungsDead',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.perc.lungsDead.mp3',
      },
      {
        name: 'patient.palp.lungs',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.lungs.mp3',
      },
      {
        name: 'patient.palp.subclavicular',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.subclavicular.mp3',
      },
      {
        name: 'patient.palp.superclavicular',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.superclavicular.mp3',
      },
      {
        name: 'patient.palp.submandibular',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.submandibular.mp3',
      },
      {
        name: 'patient.palp.cervical',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.cervical.mp3',
      },
      {
        name: 'patient.palp.thyroid',
        src:
          './assets/patients/' +
          this.sg['case_id'] +
          '/physical-exam/audio/' +
          this.sg['instance_id'] +
          '/patient.palp.thyroid.mp3',
      },
    ];
    this.soundSprites2 = [
      {
        id: 'patient.no',
        src: 'no.mp3',
      },
      {
        id: 'patient.no2',
        src: 'no.mp3',
      },
      {
        id: 'patient.no3',
        src: 'no.mp3',
      },
      {
        id: 'patient.reallyHurt',
        src: 'reallyHurt.mp3',
      },
      {
        id: 'patient.yesPainIs',
        src: 'painIs.mp3',
      },
      {
        id: 'patient.ausc.bronchial',
        src: 'patient.ausc.bronchial.mp3',
      },
      {
        id: 'patient.ausc.rightventrical',
        src: 'patient.ausc.rightventrical.mp3',
      },
      {
        id: 'patient.ausc.lungs',
        src: 'patient.ausc.lungs.mp3',
      },
      {
        id: 'patient.ausc.lungsright',
        src: 'patient.ausc.lungsright.mp3',
      },
      {
        id: 'patient.ausc.lungsleft',
        src: 'patient.ausc.lungsleft.mp3',
      },
      {
        id: 'patient.ausc.bowels',
        src: 'patient.ausc.bowels.mp3',
      },
      {
        id: 'patient.ausc.pulmonary',
        src: 'patient.ausc.pulmonary.mp3',
      },
      {
        id: 'patient.ausc.apex',
        src: 'patient.ausc.apex.mp3',
      },
      {
        id: 'patient.ausc.aortic',
        src: 'patient.ausc.aortic.mp3',
      },
      {
        id: 'patient.ausc.epigastric',
        src: 'patient.ausc.epigastric.mp3',
      },
      {
        id: 'patient.percussionDull',
        src: 'percussionDull.mp3',
      },
      {
        id: 'patient.percussionTimpani',
        src: 'percussionTimpani.mp3',
      },
      {
        id: 'patient.perc.bowels',
        src: 'patient.perc.bowels.mp3',
      },
      {
        id: 'patient.perc.lungs',
        src: 'patient.perc.lungs.mp3',
      },
      {
        id: 'patient.perc.lungsleft',
        src: 'patient.perc.lungsleft.mp3',
      },
      {
        id: 'patient.perc.lungsright',
        src: 'patient.perc.lungsright.mp3',
      },
      {
        id: 'patient.perc.heart',
        src: 'patient.perc.heart.mp3',
      },
      {
        id: 'patient.perc.liver',
        src: 'patient.perc.liver.mp3',
      },
      {
        id: 'patient.perc.lungsDead',
        src: 'patient.perc.lungsDead.mp3',
      },
      {
        id: 'patient.palp.lungs',
        src: 'patient.palp.lungs.mp3',
      },
      {
        id: 'patient.palp.subclavicular',
        src: 'patient.palp.subclavicular.mp3',
      },
      {
        id: 'patient.palp.submandibular',
        src: 'patient.palp.submandibular.mp3',
      },
      {
        id: 'patient.palp.superclavicular',
        src: 'patient.palp.superclavicular.mp3',
      },
      {
        id: 'patient.palp.cervical',
        src: 'patient.palp.cervical.mp3',
      },
      {
        id: 'patient.palp.thyroid',
        src: 'patient.palp.thyroid.mp3',
      },
    ];
    this.soundURL =
      './assets/patients/' +
      this.sg['case_id'] +
      '/physical-exam/audio/' +
      this.sg['instance_id'] +
      '/';
    this.patientImage =
      './assets/patients/' +
      this.sg['case_id'] +
      '/physical-exam/images/' +
      this.sg['instance_id'] +
      '/spritesheet.png';
    this.palpationImage =
      './assets/patients/' +
      this.sg['case_id'] +
      '/physical-exam/images/' +
      this.sg['instance_id'] +
      '/palpation.png';
    this.backgroundImg = './assets/default/history/empty.png';
    this.patientDownBackgroundImg =
      './assets/default/physical/Props/' +
      this.sg['instance_id'] +
      '/physicalExamDownBg.png';
    this.patientDownHotSpot =
      './assets/patients/' +
      this.sg['case_id'] +
      '/physical-exam/images/' +
      this.sg['instance_id'] +
      '/down.png';
    // create children persp-content and persp-hit container
    for (const persp of this.persps) {
      this.perspContainer = new createjs.Container();
      this.perspContainer.name = persp;
      this.contentContainer = new createjs.Container();
      this.contentContainer.name = 'contentContainer';
      this.hitContainer = new createjs.Container();
      this.hitContainer.name = 'hitContainer';
      this.perspContainer.addChild(this.contentContainer);
      this.perspContainer.addChild(this.hitContainer);
      this.container.addChild(this.perspContainer);
    }

    createjs.Sound.registerSounds(this.soundSprites2, this.soundURL);
    // create sound instance
    /*
    if (this.sounds != []) {
      this.soundSprites.forEach(function (soundSprite) {
        that.sounds[soundSprite.name] = createjs.Sound.createInstance(soundSprite.name);
      });
    } */

    // console.log("SOUNDS", this.sounds);

    // create sprites
    this.patientSprites = this.slice(this.patientImage, this.patientImageSlice);

    // TODO: SARAH THIS IS WHERE YOU MODIFY FOR PEDIATRICS.
    // Just do a check for the pedatrics and swap the sprites slicing variables.
    this.palpationSprites = this.slice(
      this.palpationImage,
      this.palpationImageSlice
    );

    this.palpationSpritesDown = this.slice(
      this.patientDownHotSpot,
      this.patientDownImageSlice
    );

    createjs.Sound.registerSounds(this.soundSprites);
    // console.log("this.views", this.spriteService.getViews(this.sg["case_id"]));

    this.patientId = this.sg['case_id'];
    if (
      this.patientId === '5c0715573c024f000f745ff4' ||
      this.patientId === '5c17f1209020b900168e58ba' ||
      this.patientId === '5c13e2d59020b900168e5731' ||
      this.patientId === '5c0818411ad175000f58d215' ||
      this.patientId === '5bfdb527341d8d000f82cf99' ||
      this.patientId === '5c08197e1ad175000f58d219' ||
      this.patientId === '5c17f16e9020b900168e58bd' ||
      this.patientId === '5c081def1ad175000f58d22a'
    ) {
      this.pediatric = true;
    } else {
      this.pediatric = false;
    }
    this.setPatient(this.spriteService.getViews(this.sg['case_id']));

    if (
      this.sg['case_id'] === '5c081d181ad175000f58d228' &&
      this.sg['instance_id'] !== 'initialAssessment'
    ) {
      this.setPalpation(this.spriteService.getPalp('default'),undefined);
    } else {
      this.setPalpation(this.spriteService.getPalp(this.sg['case_id']),undefined);
    }
    this.setRespiration(this.respiration);
    this.show('Front');
  }

  // Set Patient Sprites
  setPatient(manifest) {
    this.debugService.getalphaDebug().subscribe(f => {
      this.alphaDebug = f / 100;
    });
    for (const patientView of manifest) {
      const perspContentContainer = this.container
        .getChildByName(patientView.persp)
        .getChildByName('contentContainer');
      //  console.log(this.patientSprites);
      for (const patientSprite of patientView.sprites) {
        this.patientSprites[patientSprite.name].x = patientSprite.x;
        this.patientSprites[patientSprite.name].y = patientSprite.y;
        this.patientSprites[patientSprite.name].scaleX = patientSprite.scaleX;
        this.patientSprites[patientSprite.name].scaleY = patientSprite.scaleY;
        this.patientSprites[patientSprite.name].regX = patientSprite.regX;
        this.patientSprites[patientSprite.name].regY = patientSprite.regY;
        perspContentContainer.addChild(this.patientSprites[patientSprite.name]);
      }
    }

    // setup raiseArm hot spots
    const frontHitContainer = this.container
      .getChildByName('Front')
      .getChildByName('hitContainer');
    let hotspots;
    if (this.pediatric) {
      hotspots = this.hotspots.pediatric;
    } else {
      hotspots = this.hotspots.adult;
    }
    const torsoHitShape = this.createjsShapeService.createShape(
      hotspots.torsoHotSpot
    );
    torsoHitShape.name = 'torsoHitArea';
    torsoHitShape.hitArea = this.createjsShapeService.createShape(
      hotspots.torsoHotSpot
    );
    torsoHitShape.alpha = this.alphaDebug;
    torsoHitShape.hitArea.alpha = 1;
    frontHitContainer.addChild(torsoHitShape);

    const headHitShape = this.createjsShapeService.createShape(
      hotspots.headHotSpot
    );
    headHitShape.name = 'headHitArea';
    headHitShape.hitArea = this.createjsShapeService.createShape(
      hotspots.headHotSpot
    );
    headHitShape.alpha = this.alphaDebug;
    headHitShape.hitArea.alpha = 1;
    frontHitContainer.addChild(headHitShape);

    const legsHitShape = this.createjsShapeService.createShape(
      hotspots.legsHotSpot
    );
    legsHitShape.name = 'legsHitArea';
    legsHitShape.hitArea = this.createjsShapeService.createShape(
      hotspots.legsHotSpot
    );
    legsHitShape.alpha = this.alphaDebug;
    legsHitShape.hitArea.alpha = 1;
    frontHitContainer.addChild(legsHitShape);

    const handsHitShape = this.createjsShapeService.createShape(
      hotspots.handsHotSpot
    );
    handsHitShape.name = 'handsHitArea';
    handsHitShape.hitArea = this.createjsShapeService.createShape(
      hotspots.handsHotSpot
    );
    handsHitShape.alpha = this.alphaDebug;
    handsHitShape.hitArea.alpha = 1;
    frontHitContainer.addChild(handsHitShape);
    if (!this.pediatric) {
      const raiseArmRightHitShape = this.createjsShapeService.createShape(
        hotspots.raiseRightArmHotSpot
      );
      raiseArmRightHitShape.name = 'raiseRightArmHitArea';
      raiseArmRightHitShape.hitArea = this.createjsShapeService.createShape(
        hotspots.raiseRightArmHotSpot
      );
      raiseArmRightHitShape.alpha = this.alphaDebug;
      raiseArmRightHitShape.hitArea.alpha = 1;
      frontHitContainer.addChild(raiseArmRightHitShape);

      const raiseArmleftHitShape = this.createjsShapeService.createShape(
        hotspots.raiseLeftArmHotSpot
      );
      raiseArmleftHitShape.name = 'raiseLeftArmHitArea';
      raiseArmleftHitShape.hitArea = this.createjsShapeService.createShape(
        hotspots.raiseLeftArmHotSpot
      );
      raiseArmleftHitShape.alpha = this.alphaDebug;
      raiseArmleftHitShape.hitArea.alpha = 1;
      frontHitContainer.addChild(raiseArmleftHitShape);
    }
  }

  // to save CPU, we're only updating when we need to, instead of on a tick:1

  reset() {
    this.container = new createjs.Container();
    this.perspContainer = null;

    this.hitContainer = null;
    this.contentContainer = null;
    this.palpulationDefaultSounds = [];
    this.respirationTween = null;
    this.container = new createjs.Container();
    this.currentPersp = 'Front';
    this.physicalActions = new Set();
    // this.scoreAuscultation = new Set();
    // this.scoreSuperficial = new Set();
    // this.scoreDeep = new Set();
    this.scorePercussion = new Set();
    this.scoreSpecialTools = new Set();
    this.scorePosition = new Set();
    this.scoreOther = new Set();
    this.scoreTotal = new Array();
    this.patientSprites = [];
    this.palpationSprites = [];
  }

  setRespiration(manifest) {
    // console.log("resp mani", manifest);
    const delay = (60 / manifest.rate) * 1000 - 2200;
    const torso = this.container
      .getChildByName('Front')
      .getChildByName('contentContainer')
      .getChildByName(manifest.toroSpriteName);
    const torsoY = torso.y;
    if (this.pediatric) {
      // BABIES
      this.respirationTween = createjs.Tween.get(torso, {
        loop: true,
        paused: true,
      })
        .to(
          {
            scaleY: 1.25,
            y: torsoY - 10,
          },
          1200,
          createjs.Ease.circOut
        )
        .wait(delay)
        .to(
          {
            scaleY: 1.2,
            y: torsoY,
          },
          1000,
          createjs.Ease.circOut
        );

      const head = this.container
        .getChildByName('Front')
        .getChildByName('contentContainer')
        .getChildByName(manifest.headSpriteName);
      const headY = head.y;
      this.respirationHeadTween = createjs.Tween.get(head, {
        loop: true,
        paused: true,
      })
        .to(
          {
            y: headY - 10,
          },
          1200,
          createjs.Ease.circOut
        )
        .wait(delay)
        .to(
          {
            y: headY,
          },
          1000,
          createjs.Ease.circOut
        );
    } else {
      // ADULTS
      this.respirationTween = createjs.Tween.get(torso, {
        loop: true,
        paused: true,
      })
        .to(
          {
            scaleY: 1.05,
            y: torsoY - 10,
          },
          1200,
          createjs.Ease.circOut
        )
        .wait(delay)
        .to(
          {
            scaleY: 1,
            y: torsoY,
          },
          1000,
          createjs.Ease.circOut
        );

      const head = this.container
        .getChildByName('Front')
        .getChildByName('contentContainer')
        .getChildByName(manifest.headSpriteName);
      const headY = head.y;
      this.respirationHeadTween = createjs.Tween.get(head, {
        loop: true,
        paused: true,
      })
        .to(
          {
            y: headY - 10,
          },
          1200,
          createjs.Ease.circOut
        )
        .wait(delay)
        .to(
          {
            y: headY,
          },
          1000,
          createjs.Ease.circOut
        );
    }
  }

  startRespirate() {
    this.respirationTween.setPaused(false);
    this.respirationHeadTween.setPaused(false);

    this.choices = Array.from(this.physicalActions);
    // console.log('chosen list', this.choices)
  }

  setRespirationRate(manifest) {
    // console.log("mani", manifest);
    this.respiration.rate = manifest;
  }
  stopRespirate() {
    this.respirationHeadTween.setPaused(true);
    this.respirationTween.setPaused(true);
  }

  // random sound file will be picked and played when one of the shapes got pressed
  setPalpation(manifests,alpha) {
    this.debugService.getalphaDebug().subscribe(f => this.alphaDebug = f / 100);

    for (const manifest of manifests) {
      if (manifest.isDefault) {
        this.palpulationDefaultSounds[manifest.type] = {
          sounds: manifest.sounds,
          loop: manifest.loopSound,
        };
      } else {
        const perspContainer = this.container.getChildByName(manifest.persp);
        const hitContainer = perspContainer.getChildByName('hitContainer');
        let typeContainer = hitContainer.getChildByName(manifest.type);
        if (typeContainer == null) {
          typeContainer = new createjs.Container();
          typeContainer.name = manifest.type;
          hitContainer.addChild(typeContainer);
        }

        const hitArea = manifest.hitArea;

        if (hitArea.image != null) {
          let hitImage = this.palpationSprites[hitArea.image.name];
          if (hitImage === undefined || hitImage === null) {
            hitImage = this.palpationSpritesDown[hitArea.image.name];
          }
          hitImage.x = hitArea.image.x * 0.5;
          hitImage.y = hitArea.image.y * 0.5;
          hitImage.scaleX = hitArea.image.scaleX;
          // Ensure Scale Y is always positive.
          hitImage.scaleY =
            -hitImage.scaleX > 0 ? -hitImage.scaleX : hitImage.scaleX;
          if (hitArea.image.name === 'Down.Ausc.Bowels') {
            hitImage.rotation = 5;
          }
          if (hitArea.image.name === 'Down.Perc.Liver') {
            hitImage.scaleX = hitImage.scaleY = 1.6;
            hitImage.rotation = 15;
          }  
          hitImage.alpha = this.alphaDebug;    
          hitImage.sounds = manifest.sounds;
          hitImage.loopSound = manifest.loopSound;
          const txt = new createjs.Text(
            hitImage.name,
            'bold 30px monospace',
            '#fff'
          );
          txt.x = 50;
          txt.y = 50;
          txt.textAlign = 'center';
          txt.textBaseline = 'middle';

          hitImage.on('mouseout', function (event) {
            const sX = Math.floor(event.stageX);
            const sY = Math.floor(event.stageY);
            hitImage.dX = sX - hitImage.x;
            hitImage.dY = sY - hitImage.y;
          });
          typeContainer.addChild(hitImage);
        } else {
          const shape = this.createjsShapeService.createShape(hitArea.shapes);
          shape.name = manifest.type;
          shape.sounds = manifest.sounds;
          shape.alpha = this.alphaDebug;

          // Show Hotspots shortly for TorsoFront
          if(alpha != undefined){
            shape.alpha = alpha
            setTimeout(()=>{
              shape.alpha = this.alphaDebug;
            },7000)
          }
          shape.loopSound = manifest.loopSound;

          const txt = new createjs.Text(
            shape.name,
            'bold 30px monospace',
            '#fff'
          );
          txt.x = 50;
          txt.y = 50;
          txt.textAlign = 'center';
          txt.textBaseline = 'middle';

          shape.on('mousedown', function (event) {
            const sX = Math.floor(event.stageX);
            const sY = Math.floor(event.stageY);
            shape.dX = sX - shape.x;
            shape.dY = sY - shape.y;
          });
          // added
          typeContainer.on('added', (e) => {
           // console.log(e);
          })

          typeContainer.addChild(shape);
        }
      }
    }
  }

  score(x) {
    this.scoreOther.add(x);
  }
  checkPalpation(type, x, y) {
    this.stopPalpationSound();
    const hitContainer = this.container
      .getChildByName(this.currentPersp)
      .getChildByName('hitContainer');
    const typeContainer = hitContainer.getChildByName(type);
    const location: string = x + ',' + y;
    console.log('pal .loc', location);
    //    this.physicalActions.add([type, location]);

    let palpationSounds = null;
    let loop: any = false;
    // console.log("typeContainer", typeContainer);
    if (typeContainer != null) {
      // console.log("Checking Palpation pt 2");
      if (type === 'percussion') {
        const hitAreas = typeContainer.children;
        for (let i = hitAreas.length - 1; i >= 0; i--) {
          const hitArea = hitAreas[i];

          loop = hitArea.loopSound ? -1 : 0;
          const pt = hitArea.globalToLocal(x, y);

          if (hitArea.hitTest(pt.x, pt.y)) {
            palpationSounds = hitArea.sounds;
            // console.log('palpsounds', palpationSounds[0]);
            // console.log('palpsounds type', type);

            // SCORING PART 2
            // if (type === 'superficialpalpation') {
            //   this.scoreSuperficial.add(palpationSounds[0]);
            // } else if (type === 'deeppalpation') {
            //   this.scoreDeep.add(palpationSounds[0]);
            // } else if (type === 'auscultation') {
            //   this.scoreAuscultation.add(palpationSounds[0]);
            //   // console.log('Ausct sounds', this.scoreAuscultation);
            // } else
            if (type === 'percussion') {
              this.scorePercussion.add(palpationSounds[0]);
            }
          }
          if (
            palpationSounds == null &&
            this.palpulationDefaultSounds[type] != null
          ) {
            palpationSounds = this.palpulationDefaultSounds[type].sounds;
            if (palpationSounds != null) {
              loop = this.palpulationDefaultSounds[type].loop;
            }
          }
          if (palpationSounds != null) {
            const index = Math.floor(Math.random() * palpationSounds.length);

            const playSound = this.sounds[palpationSounds[index]];
            //  var finalSound = playSound.src.src;

            const audio = createjs.Sound.play(palpationSounds[index], {
              delay: 200,
              loop: loop,
            });
          }
        }
      } else {
        const hitAreas = typeContainer.children;
        for (let i = hitAreas.length - 1; i >= 0; i--) {
          const hitArea = hitAreas[i];

          loop = hitArea.loopSound ? -1 : 0;
          const pt = hitArea.globalToLocal(x, y);

          if (hitArea.hitTest(pt.x, pt.y)) {
            palpationSounds = hitArea.sounds;
            // SCORING PART 2
            // if (type === 'superficialpalpation') {
            //   this.scoreSuperficial.add(palpationSounds[0]);
            // } else if (type === 'deeppalpation') {
            //   this.scoreDeep.add(palpationSounds[0]);
            // } else if (type === 'auscultation') {
            //   this.scoreAuscultation.add(palpationSounds[0]);
            //   // console.log('Ausct sounds', this.scoreAuscultation);
            // } else
            if (type === 'percussion') {
              this.scorePercussion.add(palpationSounds[0]);
            }
          }
        }
        if (
          palpationSounds == null &&
          this.palpulationDefaultSounds[type] != null
        ) {
          palpationSounds = this.palpulationDefaultSounds[type].sounds;
          if (palpationSounds != null) {
            loop = this.palpulationDefaultSounds[type].loop;
          }
        }
        if (palpationSounds != null) {
          // console.log("TESTING PALP", palpationSounds)
          const index = Math.floor(Math.random() * palpationSounds.length);

          const playSound = this.sounds[palpationSounds[index]];
          // var finalSound = playSound.src.src;

          const audio = createjs.Sound.play(palpationSounds[index], {
            delay: 200,
            loop: loop,
          });
        }
      }
    } else {
      if (
        palpationSounds == null &&
        this.palpulationDefaultSounds[type] != null
      ) {
        palpationSounds = this.palpulationDefaultSounds[type].sounds;
        if (palpationSounds != null) {
          loop = this.palpulationDefaultSounds[type].loop;
        }
      }
      if (palpationSounds != null) {
        // console.log("TESTING PALP", palpationSounds)
        const index = Math.floor(Math.random() * palpationSounds.length);

        const playSound = this.sounds[palpationSounds[index]];
        //  var finalSound = playSound.src.src;

        const audio = createjs.Sound.play(palpationSounds[index], {
          delay: 200,
          loop: loop,
        });
      }
    }
  }
  checkAuscultation(type, x, y) {
    const hitContainer = this.container
      .getChildByName(this.currentPersp)
      .getChildByName('hitContainer');
    const typeContainer = hitContainer.getChildByName(type);
    const location: string = x + ',' + y;
    this.soundList = [];
    //    this.physicalActions.add([type, location]);

    let palpationSounds = null;
    let loop: any = false;
    if (typeContainer != null) {
      const hitAreas = typeContainer.children;
      const num = 0;
      for (let i = hitAreas.length - 1; i >= 0; i--) {
        const hitArea = hitAreas[i];
        loop = hitArea.loopSound ? -1 : 0;
        const pt = hitArea.globalToLocal(x, y);

        if (hitArea.hitTest(pt.x, pt.y)) {
          palpationSounds = hitArea.sounds;
          //  console.log("PALPSOUNDS", palpationSounds);

          // SCORING PART 2
          if (type === 'auscultation') {
            // this.scoreAuscultation.add(palpationSounds[0]);
          }
        }

        if (
          palpationSounds == null &&
          this.palpulationDefaultSounds[type] != null
        ) {
          palpationSounds = this.palpulationDefaultSounds[type].sounds;
          if (palpationSounds != null) {
            loop = this.palpulationDefaultSounds[type].loop;
          }
        }
        if (palpationSounds != null) {
          //  var index = Math.floor(Math.random() * (palpationSounds.length));
          // console.log("HERE", palpationSounds);
          const index = 0;

          const playSound = palpationSounds[index];
          this.soundList.push(playSound);
          // var finalSound = playSound.src.src;
        }
      }
      const soundLength = this.soundList.length;
      for (const sound of this.soundList) {
        /*if(soundLength > 0 && sound != "patient.ausc.lungs"){


              var audio = createjs.Sound.play(sound, {
                delay: 200,
                loop: true
              });
            } else if(soundLength = 0){ */
        const audio = createjs.Sound.play(sound, {
          loop: true,
        });
        //  }
      }
    }
  }

  stopPalpationSound() {
    if (
      this.stageName === 'physicalExam' ||
      this.stageName === 'initialPhysicalExam'
    ) {
      createjs.Sound.stop();
      this.soundList = []; /*
    for (var key in this.sounds) {
      if (this.sounds.hasOwnProperty(key)) {
        this.sounds[key].stop();
      }
    } */
    }
  }

  checkZoom(tool, x, y, zoom) {
    if (this.currentPersp !== 'Front') {
      this.currentPersp = 'Front';
      return 'Normal';
    }

    const hitContainer = this.container
      .getChildByName('Front')
      .getChildByName('hitContainer');

    let hitArea, pt;

    if (!this.pediatric) {
      hitArea = hitContainer.getChildByName('raiseRightArmHitArea');
      pt = hitArea.globalToLocal(x, y);
      if (hitArea.hitTest(pt.x, pt.y)) {
        // console.log('RightArmPit');
        this.show('RightArmPit');
        this.scorePosition.add(['raisedRight']);
        return 'RightArmPit';
      }

      hitArea = hitContainer.getChildByName('raiseLeftArmHitArea');
      pt = hitArea.globalToLocal(x, y);
      if (hitArea.hitTest(pt.x, pt.y)) {
        // console.log('LeftArmPit');
        this.show('LeftArmPit');
        this.scorePosition.add(['raisedLeft']);
        return 'LeftArmPit';
      }
    }

    hitArea = hitContainer.getChildByName('headHitArea');
    pt = hitArea.globalToLocal(x, y);
    if (hitArea.hitTest(pt.x, pt.y)) {
      // console.log('HeadFront');
      this.show('HeadFront');
      this.scorePosition.add(['zoomedHead']);
      return 'Head';
    }

    hitArea = hitContainer.getChildByName('torsoHitArea');
    pt = hitArea.globalToLocal(x, y);
    if (hitArea.hitTest(pt.x, pt.y)) {
      // console.log('TorsoFront');
      this.show('TorsoFront');
      this.scorePosition.add(['zoomedTorso']);
      return 'Torso';
    }

    hitArea = hitContainer.getChildByName('legsHitArea');
    pt = hitArea.globalToLocal(x, y);
    if (hitArea.hitTest(pt.x, pt.y)) {
      // console.log('Feet');
      this.show('Feet');
      this.scorePosition.add(['zoomedFeet']);
      return 'Feet';
    }

    hitArea = hitContainer.getChildByName('handsHitArea');
    pt = hitArea.globalToLocal(x, y);
    if (hitArea.hitTest(pt.x, pt.y)) {
      // console.log('Hands');
      this.show('Hands');
      this.scorePosition.add(['zoomedHands']);
      return 'Hands';
    }

    return 'Normal';
  }

  show(persp) {
    this.currentPersp = persp;
    this.container.children.forEach(function (perspContainer) {
      perspContainer.visible = perspContainer.name === persp;
    });
  }

  // Image Slicer
  slice(bigImage, manifests) {
    const result = [];
    for (const manifest of manifests) {
      const image = new createjs.Bitmap(bigImage);
      image.name = manifest.name;
      image.sourceRect = new createjs.Rectangle(
        manifest.x,
        manifest.y,
        manifest.w,
        manifest.h
      );
      result[manifest.name] = image;
    }
    return result;
  }
}
