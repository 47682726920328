import { Injectable } from '@angular/core';
import { Medicine } from './_models/index';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MedicinesService {

  constructor() { }

  getMedicine():Observable<Medicine[]>{
    return   Observable.of(null);
  }
  // "C1": "t1",
  // "C2": "t11",
  // "C3": "t112",
  // "C4": "Imidazole and triazole derivatives",
  // "Name": "Acetaminophen/Tylenol",
  // "Dose": "500 mg Tab",
  // "Description": "Imidazole and triazole derivatives",
  // "Calc": "15",
  // "Cost": 0.0921,
  // "Unit": "mg/kg/dose",
  // "Label": 2
 


}
