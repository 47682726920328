import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Course } from '../../lms/interfaces/course';
import { sortCaseList } from 'utils/statistics';
import { PatientCase } from '../../lms/interfaces/patient-case';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';
import { UserService } from '../../_services/user.service';
import { CourseNotActiveDialogComponent } from '../../course-not-active-dialog/course-not-active-dialog.component';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/_services';

@Component({
  selector: 'app-assignment-cases',
  templateUrl: './assignment-cases.component.html',
  styleUrls: ['./assignment-cases.component.scss'],
})
export class AssignmentCasesComponent implements OnChanges {
  @Input() course: Course;
  @Input() showCaseLibrary: boolean;
  @Output() toCaseLibrary = new EventEmitter();
  isFormative: boolean;
  loadingAndSaving: boolean;
  coursecaseDataSource = new MatTableDataSource<any>();
  courseCases = ['no', 'icon', 'name', 'category'];
  user;
  courseActive = false;

  constructor(
    private teacherService: TeacherDashboardDataService,
    private router: Router,
    private _location: Location,
    public dialog: MatDialog,
    private userService: UserService,
    private authService: AuthenticationService
  ) {
    this.userService.getCurrentUserLatest().subscribe((response) => {
      this.user = response.user;
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}th, ${year}`;
  }

  openDialog() {
    this.dialog.open(CourseNotActiveDialogComponent, {
      data: '',
    });
  }

  caseLibrary() {
    this.toCaseLibrary.emit();
  }

  getGradesOverview() {
    this.loadingAndSaving = true;

    if (this.course != null) {
      if (!this.course.ended && new Date(this.course.start) < new Date()) {
        this.courseActive = true;
      }
      this.coursecaseDataSource.data = sortCaseList(this.course.cases);
    }
  }

  isHideHeader() {
    this.isFormative = this._location.isCurrentPathEqualTo('/teacher-dashboard/formativeassessment');
  }

  ngOnChanges(): void {
    this.getGradesOverview();
    this.isHideHeader();
  }

  goToCase(caseObj: PatientCase) {
    if (caseObj.caseType === 'SIMULATION') {
      const token = this.authService.getToken();
      const v3Url =
        environment.v3Url +
        '/verifyLms/simulation?token=' +
        window.encodeURIComponent(token) +
        '&caseId=' +
        window.encodeURIComponent(caseObj._id) +
        '&courseId=' +
        window.encodeURIComponent(this.course._id);
      if (v3Url && token) {
        window.open(v3Url, '_blank');
      }
    } else {
      this.router.navigate(['case-library', caseObj._id], {
        queryParams: {
          course: this.course._id,
        },
      });
    }
  }
}
