<div class="charts-wrapper" *ngIf="data.student.timeScore > 0">
    <div class="student-chart" style="display: block; width: 400px; height: 200px;">
        <canvas #studentChart baseChart [data]="studentScoreData" [chartType]="doughnutChartType" [colors]="studentScoreColors" [options]="studentScoreOptions"></canvas>
    </div>
    <!-- <div class="class-chart" style="display: block; width: 300px; height: 150px;">
    <canvas #classChart baseChart [data]="classScoreData" [chartType]="doughnutChartType" [colors]="classScoreColors"
      [options]="classScoreOptions"></canvas>
  </div>  -->
</div>

<div class="progress-area">
    <div class="flex-item">
        <div class="progress-item" [ngClass]="{active: data.state.selectedState === 'timeScore'}" (click)="focusScores('timeScore')">
            <h4 class="stat-text">Time Taken (mins)</h4>
            <p class="stat-number">{{data.student.timeScore}}</p>
        </div>
    </div>
    <div class="flex-item">
        <div class="progress-item" [ngClass]="{active: data.state.selectedState === 'knowledgeScore'}" (click)="focusScores('knowledgeScore')">
            <h4 class="stat-text">Knowledge Score</h4>
            <p class="stat-number">{{data.student.knowledgeScore}}%</p>
        </div>
    </div>
    <div class="flex-item">
        <div class="progress-item" [ngClass]="{active: data.state.selectedState === 'costScore'}" (click)="focusScores('costScore')">
            <h4 class="stat-text">Cost Deviation</h4>
            <p class="stat-number">{{data.student.costScore}}%</p>
        </div>
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</div>