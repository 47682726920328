<div class="outage-card">
  <header>
    <h1>We are experiencing a 'Code Blue'</h1>
    <p>
      Our rapid response team is in place and currently doing all they can to resuscitate CyberPatient.
      <br />
      We are sorry for any disturbance or inconvenience this unforeseen emergency situation may have caused.
      <br />
      We will keep you informed of CyberPatient's vitals as they stabilize.
      <br />
      <br />
      Thank you for your patience and understanding.
    </p>
    <p>
      Check current server status at:
      <a href="https://stats.uptimerobot.com/ZRE9vf7qkA" target="_blank">cyberpatient.status</a>
      <br />
      Reach out to us <a href="mailto:info@cyberpatient.ca" target="_blank">info@cyberpatient.ca</a>
    </p>
  </header>
</div>
