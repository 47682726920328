<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
  <div class="pop-up mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
            <p style="text-align:center; display: block; margin: 0 auto;">
              {{content.title}}
            </p>
          </div>
        </div>
        <section class="response medication inputs blood">
          <p style="margin-top: 20px;">
            <span class="quick">{{content.description}}</span></p>
          <div class="agreement-box" id="policyBox" #policyBox>
            <div class="policy" appAppMotationObserver [innerHtml]="content.policy" (innerHtmlRendered)="scrollToTop()">
            </div>
          </div>
        <!--  <mat-checkbox [(ngModel)]="confirm" [disabled]="readCheck()" class="agree-box">{{content.checkbox}}
          </mat-checkbox> -->

        </section>
        <section class="actions">
          <button mat-raised-button color="primary"
            class="action mat-button mat-raised-button mat-primary cancel"
            [mat-dialog-close]>Close</button>
        </section>
        <!--
        <section class="actions">
          <button mat-raised-button color="primary"
            class="action mat-button mat-raised-button mat-primary cancel"
            [mat-dialog-close]="false">{{content.btn1text}}</button>
          <button class="action mat-button mat-raised-button mat-primary success" 
            [disabled]="buttonCheck()" [mat-dialog-close]="true">{{content.btn2text}}</button>
        </section>
      -->
      </div>
    </div>
    <!--  <ngx-loading [show]="loadingAndSaving"></ngx-loading> -->
  </div>
</mat-dialog-content>
