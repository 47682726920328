<aside class="sidebar" [hidden]="error">
  <ul>
    <li *ngFor="let item of menuItems">
      <a class="mt-3 mx-auto"
         (click)="onNavItemClick(item)"
         [matTooltip]="item.name"
         matTooltipPosition="after"
         matTooltipClass="sidebar"
         routerLinkActive="active">
        <i class="fa {{item.icon}} sidebar-icon" [class.sidebar-icon--active]="currentRoute === item.router"></i>
        <div class="text mt-1 mx-auto">{{ item.name }}</div>
      </a>
    </li>
  </ul>
  <button mat-fab matTooltip="Click to hide/show debug window"
          matTooltipPosition="after"
          matTooltipClass="sidebar"
          class="debugclass1" *ngIf="debug" (click)="showDebug()">
    <mat-icon fontSet="fa" fontIcon="fa-bug">
    </mat-icon>
  </button>
</aside>
