import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreatejsShapeService {
  getCircleManifest(x, y, r) {
    return {
      shape: 'Circle',
      x: x,
      y: y,
      r: r,
    };
  }

  getRectManifest(x, y, w, h) {
    return {
      shape: 'Rect',
      x: x,
      y: y,
      w: w,
      h: h,
    };
  }

  createShape(manifests) {
    const shape = new createjs.Shape();
    const randomColor = '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });
    const g = shape.graphics.beginFill(randomColor);
    manifests.forEach(function (manifest) {
      switch (manifest.shape) {
        case 'Rect':
          g.drawRect(manifest.x, manifest.y, manifest.w, manifest.h);
          break;
        case 'Circle':
          g.drawCircle(manifest.x, manifest.y, manifest.r);
          break;
      }
    });
    shape.alpha = 0;
    g.endFill();

    return shape;
  }
}
