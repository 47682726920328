<div class="p-5">
  <div class="text-color-primary mx-auto text-center">
    <div class="h4 font-weight-bolder">Welcome to</div>
    <div class="h3 font-weight-bolder">CyberPatient™ Learning Management System</div>
  </div>

  <div class="text-center">
    <img src="/assets/img/app/InstructorDashboardPanelcopy.jpg" width="500"/>
  </div>

  <button mat-button
          class="d-block mx-auto text-color-primary font-weight-bold"
          style="font-size: 1.1rem !important;"
          (click)="confirm()">
    Start with help
  </button>
  <button mat-button
          class="d-block mx-auto"
          (click)="cancel()">
    I don't need help
  </button>
</div>
