<div class="h21">
  <mat-card class="assignments-card">
    <mat-card-header class="assignments-card-header">
      <div
        class="feedheader"
        matTooltip="This class has ended. Please contact the professor for more information."
        matTooltipPosition="above"
        [matTooltipDisabled]="!course || !course.ended"
      >
        Cases Assigned
        <button
          *ngIf="showCaseLibrary"
          mat-raised-button
          color="success"
          class="ml-3"
          [disabled]="true"
          (click)="caseLibrary()"
        >
          Case Library
        </button>
      </div>
    </mat-card-header>

    <mat-card-content class="assignments-card-content">
      <div class="notcompletetable">
        <table mat-table matSort [dataSource]="coursecaseDataSource">
          <ng-container matColumnDef="no">
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
          </ng-container>
          <ng-container matColumnDef="icon">
            <td mat-cell *matCellDef="let element">
              <div [class]="element.tags.category">
                <span class="icon"></span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">{{ element.demographics.patient_name }}</td>
          </ng-container>
          <ng-container matColumnDef="category">
            <td mat-cell *matCellDef="let element">{{ element.tags.category }}</td>
          </ng-container>
          <tr
            mat-row
            [style]="courseActive ? 'cursor: pointer' : ''"
            *matRowDef="let row; columns: courseCases"
            (click)="openDialog()"
          ></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
