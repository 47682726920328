<div class="invite-faculty-dialog overflow-hidden">
  <div class="bg-color-primary text-center text-color-white font-weight-bold h3 py-3">Invite Faculty</div>
  <div class="d-flex flex-row-reverse pt-3 px-5">
    <button class="text-color-secondary ml-5">
      <i class="fa fa-table"></i>
      <a
        download="Import_Faculty_Template.csv"
        href="https://cyberpatient-assets.s3-us-west-2.amazonaws.com/template_faculties.csv"
        class="ml-1 text-color-secondary font-weight-bold"
      >
        Template
      </a>
    </button>
    <app-csv-uploader (onUpload)="handleUploadCsv($event)">
      <i class="fa fa-upload text-color-secondary"></i>
      <span class="ml-1 font-weight-bold text-color-secondary">Bulk import (.csv)</span>
    </app-csv-uploader>
  </div>
  <div *ngIf="institutionLimitReached" class="d-flex justify-content-center mt-5">
    <mat-error>Institution member limit has been reached please contact admin</mat-error>
  </div>
  <div class="container container-fluid pb-5 mt-3">
    <div *ngIf="exceedLimitError" class="alert alert-danger mx-3" role="alert">
      You have exceeded the seats limit. Please contact our sales team to increase the limit.
    </div>
    <div class="invite-faculty-dialog-form">
      <div class="row" *ngFor="let faculty of facultyForms.controls; let i = index" [id]="'facultyForm_' + i">
        <ng-container [formGroup]="faculty">
          <div class="col-3">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" required formControlName="email" />
              <mat-error *ngIf="faculty.get('email').getError('required') || faculty.get('email').getError('email')"
                >Enter a valid email</mat-error
              >
              <mat-error *ngIf="faculty.get('email').getError('notIn')">Email already exists</mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" required formControlName="firstName" />
              <mat-error *ngIf="faculty.get('firstName').invalid">First name is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" required formControlName="lastName" />
              <mat-error *ngIf="faculty.get('lastName').invalid">Last name is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>Role</mat-label>
              <mat-select required formControlName="role" class="text-capitalize">
                <mat-option class="text-capitalize" *ngFor="let role of roles" [value]="role">
                  {{ role }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="faculty.get('role').invalid">Role is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-1 d-flex align-items-center">
            <button *ngIf="i > 0" (click)="removeForm(i)">
              <i class="fa fa-times-circle text-muted pt-3"></i>
            </button>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col">
          <button class="text-color-secondary" (click)="addFacultyForm()">
            <i class="fa fa-plus-circle"></i>
            <span class="font-weight-bold mx-1">Add More</span>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <button
        mat-raised-button
        color="dialog-success"
        class="px-5 py-1 mx-2"
        type="submit"
        [disabled]="loading"
        (click)="onSubmit()"
      >
        Submit
      </button>
      <button mat-dialog-close mat-raised-button color="dialog-cancel" class="px-5 py-1 mx-2">Cancel</button>
    </div>
  </div>
</div>
