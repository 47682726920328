<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Reset Password</h1>
        </div>
        <div class="form-subheader">
          <p>Please choose your new password.</p>
        </div>
        <div class="example-container settings">
          <form
            [formGroup]="frmSignup"
            #captchaProtectedForm="ngForm"
            (submit)="submitRequest()"
            class="example-container"
          >
            <mat-form-field appearance="outline" floatLabel="auto" class="passwordbox">
              <mat-label>New Password</mat-label>
              <input
                matInput
                id="new"
                formControlName="new"
                type="password"
                class="form-control"
                [ngClass]="frmSignup.controls['new'].invalid ? 'is-invalid' : ''"
                autocomplete="new-password"
              />
              <mat-error
                class="col"
                [ngClass]="
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('minlength')
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <i class="material-icons">{{
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('minlength')
                    ? 'cancel'
                    : 'check_circle'
                }}</i>
                Must be at least 8 characters!
              </mat-error>
              <mat-error
                class="col"
                [ngClass]="
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasNumber')
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <i class="material-icons">{{
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasNumber')
                    ? 'cancel'
                    : 'check_circle'
                }}</i>
                Must contain at least 1 number!
              </mat-error>
              <mat-error
                class="col"
                [ngClass]="
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasCapitalCase')
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <i class="material-icons">{{
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasCapitalCase')
                    ? 'cancel'
                    : 'check_circle'
                }}</i>
                Must contain at least 1 in Capital Case!
              </mat-error>
              <mat-error
                class="col"
                [ngClass]="
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasSmallCase')
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <i class="material-icons">{{
                  frmSignup.controls['new'].hasError('required') || frmSignup.controls['new'].hasError('hasSmallCase')
                    ? 'cancel'
                    : 'check_circle'
                }}</i>
                Must contain at least 1 Letter in Small Case!
              </mat-error>
              <mat-error
                class="col"
                [ngClass]="
                  frmSignup.controls['new'].hasError('required') ||
                  frmSignup.controls['new'].hasError('hasSpecialCharacters')
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <i class="material-icons">{{
                  frmSignup.controls['new'].hasError('required') ||
                  frmSignup.controls['new'].hasError('hasSpecialCharacters')
                    ? 'cancel'
                    : 'check_circle'
                }}</i>
                Must contain at least 1 Special Character!
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="auto">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                id="verify"
                formControlName="verify"
                type="password"
                class="form-control"
                [ngClass]="frmSignup.controls['verify'].invalid ? 'is-invalid' : ''"
                autocomplete="new-password"
              />
              <mat-error class="text-danger" *ngIf="frmSignup.controls['verify'].hasError('required')">
                Password is Required!
              </mat-error>
              <mat-error class="text-danger" *ngIf="frmSignup.controls['verify'].hasError('NoPassswordMatch')">
                Password do not match
              </mat-error>
            </mat-form-field>
            <div style="width: 100%; margin-top: 20px">
              <re-captcha id="captcha" formControlName="captcha" name="captcha" required></re-captcha>
              <div class="btngrp submit">
                <div class="form-group">
                  <button [disabled]="frmSignup.invalid" type="submit" mat-raised-button color="primary">
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- <a routerLink='/recover' routerLinkActive="active" class="forgot-password">Need to make a new account?</a>-->
        <p class="copyright">© 2022 All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </div>
</section>
