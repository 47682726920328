<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Email Confirmation</h1>
        </div>
        <!-- <div class="form-subheader">
              <p>Don't have a CyberPatient Account?</p>
              <p><a href="/recover">Create a new account</a></p>
            </div> -->
        <div class="confirm-email">
          <img class="envelope" src="assets/img/app/envelope.png" />
          <p>Please check your inbox! We’ve sent you a confirmation email that contains a link to confirm your email
            address. Please make sure to check your spam folder!</p>

          <p>If you don’t recieve your email within a 15 minutes,
            <br />
            <a routerLink='/resend' routerLinkActive="active">Click to resend</a>
            or contact
            <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a>.
          </p>


        </div>

      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
<p class="copyright">
  © Interactive Health International 2022 | Privacy Policy | Terms of Service
</p>
