import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { course } from '../../_models/teacher-dashboard.model';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';

@Component({
  selector: 'app-add-cases',
  templateUrl: './add-cases.component.html',
  styleUrls: ['./add-cases.component.scss'],
})
export class AddCasesComponent {
  @Input()
  course = {} as course;

  expand = {
    Psychiatry: false,
    Cardiac: false,
    Gastrointestinal: false,
    Genitourinary: false,
    Respirology: false,
    OBGYN: false,
    Pediatrics: false,
    'Central Nervous System': false,
    Musculoskeletal: false,
    ENT: false,
    Neoplasms: false,
    Surgery: false,
    Epa: false,
  };
  data: any;
  refresh: any;

  loadingAndSaving = false;

  subscription = false;
  @ViewChild('deprecatedlearningobjectivepopup', { static: true })
  deprecatedLearningObjectivePopup: TemplateRef<any>;
  @ViewChild('learningobjectivepopup', { static: true })
  learningObjectivePopup: TemplateRef<any>;
  isDeprecatedLibrary = false;
  showShortDesciption = true;
  myindex = 0;

  loloading: boolean;

  constructor(
    private router: Router,
    private teacherDashboardDataService: TeacherDashboardDataService,
    private sg: SimpleGlobal,
    public dialog: MatDialog
  ) {
    this.getCases();
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption;
  }

  addToClass(case_id, category, index, $event) {
    const caseCont = this.data[category][index];

    // TODO - Add user id from api login.
    const idx = this.course.cases.findIndex((x) => x._id === caseCont._id);
    if (idx >= 0) {
      $event.target.innerText = 'ADD';
    } else {
      $event.target.innerText = 'ADDED';
    }
  }

  setText(e) {
    if (this.course != null) {
      return this.course.cases.findIndex((x) => x._id === e._id) === -1 ? 'ADD' : 'ADDED';
    } else {
      return '';
    }
  }

  toggleSection(x) {
    this.expand[x] = !this.expand[x];
  }

  gotoClassdashboard() {
    this.router.navigate(['/teacher-dashboard/classdashboard']);
  }

  caseActive(caseType) {
    let subscribed = false;
    if (this.sg['membership_status']) {
      subscribed = true;
    } else if (caseType === 'free') {
      subscribed = true;
    } else {
      subscribed = false;
    }
    return subscribed;
  }

  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (!a[property] || !b[property]) {
        return -1;
      }
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  getDeprecatedFinalDiagnostic(patient: any) {
    if (patient.finalDiagnosis) {
      return Object.keys(patient.finalDiagnosis.diagnosis).find((k) => patient.finalDiagnosis.diagnosis[k] === true);
    } else {
      return 'No Diagnosis data!';
    }
  }

  getFinalDiagnostic(patient: any) {
    if (patient.diagnosis) {
      return patient.diagnosis;
    } else {
      return 'No Diagnosis data!';
    }
  }

  getCases() {
    this.loadingAndSaving = true;
    // this.fetchAssignmentDataService.getCaseLibrary()
    this.teacherDashboardDataService.getCaseLibrary().subscribe(
      (cases) => {
        this.sg['membership_status'] = cases.membership;
        this.data = cases.message;
        for (const category in this.data) {
          this.isDeprecatedLibrary = true;
          this.data[category].sort(this.dynamicSort('patient_name'));
          this.data[category].sort(this.dynamicSort('membership_access'));
        }
        this.loadingAndSaving = false;
      },
      (err) => {
        console.error('Failed to load cases', err);
        this.loadingAndSaving = false;
      }
    );
  }

  darkCheck(category) {
    if (category === 'Pediatrics' || category === 'Gastrointestinal' || category === 'OBGYN') {
      return true;
    } else {
      return false;
    }
  }

  showDeprecatedLearningObjectives(case_id: string, i: number) {
    this.loloading = true;
    this.myindex = i;

    this.teacherDashboardDataService.getLearningObjectives(case_id).subscribe((l) => {
      if (l.caseRec) {
        const dialoglearningobjectives = this.dialog.open(this.deprecatedLearningObjectivePopup, {
          data: l.caseRec.learning_objectives,
          height: '450px',
          width: '700px',
          maxWidth: '800px',
        });
        dialoglearningobjectives.disableClose = false;
        this.loloading = false;
      }
    });
  }

  showLearningObjectives(patient: any) {
    const sentences = patient.learningObjectives.split(/(?<=[.!?;:])\s+/);
    const dialogRef = this.dialog.open(this.learningObjectivePopup, {
      data: sentences,
      height: '500px',
      width: '700px',
      maxWidth: '850px',
    });
    dialogRef.disableClose = false;
  }
}
