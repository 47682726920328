import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      const userRole = JSON.parse(localStorage.getItem('currentUser'));
      const permission = route.data['permission'];
      let role = userRole['role'];
      if (role === null || role === undefined) {
        role = 'learner';
      }
      if (!permission) {
        throw new Error('Permissions is not setup!');
      }
      if (!permission.only.length) {
        throw new Error('Roles are not setup!');
      }

      const canActivate = permission.only.includes(role);
      if (!canActivate) {
        this.router.navigate([permission.redirectTo]);
      }
      return canActivate;
    } else {
      this.authService.logout(state.url);
      return false;
    }
  }
}
