import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../_models';
import { Institution } from '../../lms/interfaces/institution';
import { Editable } from '../../shared/interfaces/editable';
import { SuperAdminService } from '../services/super-admin.service';
import { TransferInstitutionEvent } from '../users-list/users-list.component';

@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.scss'],
})
export class SuperAdminDashboardComponent implements OnInit {
  public institutions: Editable<Institution>[] = [];
  public fetchInstitutionsLoading = false;
  public fetchInstitutionsError = false;
  public user: User;

  constructor(
    private readonly superAdminService: SuperAdminService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.fetchInstitutions();
  }

  public loginAs(institution: Institution) {
    this.superAdminService
      .suLogin(institution.ownerId)
      .subscribe(async () => {
        await this.router.navigate(['/']);
        window.location.reload();
      })
  }

  public edit(institution: Editable<Institution>) {
    institution.editing = true;
  }

  public save(institution: Editable<Institution>) {
    if (!institution.editing) {
      return;
    } else {
      this.superAdminService.updateInstitution(
        institution._id,
        {
          membersLimit: institution.membersLimit,
          paid: institution.paid,
          allowPublicCourses: institution.allowPublicCourses,
        }
      ).subscribe(
        () => {
          this.toastr.success(
            `Successfully updated ${institution.name} information`,
            'SUCCESS',
            {
              timeOut: 3000,
            }
          );
        },
        () => {
          this.toastr.error(
            `Unable to update ${institution.name} information`,
            'ERROR',
            {
              timeOut: 3000,
            }
          );
        }
      );
    }

    institution.editing = false;
  }

  public searchUser(email: string) {
    this.superAdminService.searchUser(email).subscribe((user) => {
      this.user = user;
    });
  }

  public transferUser(event: TransferInstitutionEvent) {
    this.superAdminService.updateUser(event.user._id, {
      institutionId: event.institution._id,
      role: event.role,
    }).subscribe((user) => {
      this.user = user;
    });
  }

  private fetchInstitutions() {
    this.fetchInstitutionsLoading = true;
    this.fetchInstitutionsError = false;
    this.superAdminService
      .listInstitutions()
      .subscribe((institutions) => {
        this.fetchInstitutionsLoading = true;
        this.institutions = institutions
          .map((institution) => ({
            ...institution,
            editing: false,
          }))
          .sort((li, ri) => {
            return new Date(ri.createdAt).getTime() - new Date(li.createdAt).getTime();
          });
      }, () => {
        this.fetchInstitutionsError = true;
      });
  }   
}
