<div class="mat-typography">
  <!--- Student  -->

  <div class="row head-wrap">
    <div class="large-12 columns">
      <div class="large-6 columns" *ngIf="user && user.institutionRole !== 'student'">
        <mat-card class="dashcard equalize mat-elevation-z1">
          <div class="stat-header centered-header" style="text-align: center">
            <span class="piechart-header">Last Case</span>
            <last-assignment class="last-assignment"></last-assignment>
          </div>
        </mat-card>
      </div>

      <div class="large-6 columns" *ngIf="user && user.institutionRole === 'student'">
        <mat-card class="dashcard equalize mat-elevation-z1">
          <app-classes-list></app-classes-list>
          <news-feed class="mat-elevation-z1 news-feed large-12"></news-feed>
        </mat-card>
      </div>

      <div class="large-6 columns" *ngIf="user && user.institutionRole === 'student'">
        <app-stu-feed></app-stu-feed>
      </div>
      <div class="large-6 columns" *ngIf="user && user.institutionRole !== 'student'">
        <app-stu-feed></app-stu-feed>
        <news-feed class="mat-elevation-z1 news-feed large-12"></news-feed>
        <mat-card class="dashcard equalize mat-elevation-z1"></mat-card>
      </div>
    </div>
  </div>
</div>