<div class="course2">
  <mat-card class="course-grade-card">
    <mat-card-header class="course-grade-card-header" [hidden]="IsFormmative">
      <div class="performnaneheader">
        <div class="feedheader">Course Performance Summary</div>
        <div>
          <ng-container *ngIf="loading; else elseTemplate">
            <mat-spinner [diameter]="40"></mat-spinner>
          </ng-container>
          <ng-template #elseTemplate>
            <button
              mat-icon-button
              matTooltip="Refresh Score"
              matTooltipPosition="after"
              matTooltipClass="sidebar"
              aria-label="refresh score"
              (click)="getGrade()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content class="course-grade-card-content">
      <div class="performancescore">
        <div class="p1-header avg" *ngIf="score">
          <label class="p1-header-label">Average</label>
          <ng-container *ngIf="score.grade == -1; else elseTemplate">
            <span style="color: red">Calculation error!</span>
          </ng-container>
          <ng-template #elseTemplate>
            <span class="result-value notranslate">
              {{ score.grade ? (score.grade | number: '1.0-0') + '%' : 'N/A' }}
            </span>
          </ng-template>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="p1-header">
          <label class="p1-header-label-gc">Grade</label><span class="result-value">{{ grade }}</span>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="p1-header">
          <label class="p1-header-label-gc">Attempted Cases</label
          ><span class="result-value" *ngIf="course != null">{{ course.cases.length }}</span>
        </div>
      </div>
      <div *ngIf="chartData" class="stat-container">
        <div class="chart-container">
          <div class="desc-container">
            <!-- <div class="stat-header">Performance</div> -->
            <div class="text-body text-muted text-left">
              Scores per attempt per case. Select <strong><i>Average</i></strong> to view the average score for all case
              attempts.
            </div>
            <mat-form-field appearance="fill">
              <mat-label>Select Case</mat-label>
              <mat-select (selectionChange)="setPatientData($event.value)" [(value)]="selectedPatient">
                <mat-option *ngFor="let stats of patientStatistics" [value]="stats.id">
                  {{ stats.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-stats
            [lineChartData]="chartData.graph"
            [lineChartLabels]="chartLabels"
            class="dash-stat term-scores"
          ></app-stats>
          <div class="stats-table">
            <table mat-table [dataSource]="chartData.table" class="mat-elevation-z0">
              <ng-container matColumnDef="label">
                <th mat-header-cell class="font-weight-bold text-color-secondary" *matHeaderCellDef>Attempt</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.label.num }}<sup>{{ element.label.super }}</sup>
                </td>
              </ng-container>

              <ng-container matColumnDef="score">
                <th mat-header-cell class="font-weight-bold text-color-secondary" *matHeaderCellDef>Score</th>
                <td mat-cell *matCellDef="let element">{{ element.score }}</td>
              </ng-container>

              <ng-container matColumnDef="time">
                <th
                  mat-header-cell
                  matTooltipPosition="above"
                  matTooltip="Average time spent by the students who made this attempt"
                  class="font-weight-bold text-color-secondary"
                  *matHeaderCellDef
                >
                  Duration
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.time }} mins
                  <button (click)="openHelpDialog()" *ngIf="element.warn" mat-icon-button>
                    <mat-icon>help</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chartData.statsColumns"></tr>
              <tr
                mat-row
                [ngClass]="{ 'session-warn': row.warn }"
                *matRowDef="let row; columns: chartData.statsColumns"
              ></tr>
            </table>
          </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div *ngIf="barData" class="chart-container">
          <div class="desc-container row mt-3">
            <div class="center text-body text-muted text-left col-12">
              Your best score per case compared to the class average.
            </div>
          </div>
          <app-stats [barChartData]="barData" [barChartLabels]="barLabels"></app-stats>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
