import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable } from 'rxjs';
import generalOrder from '../_models/generalorder.model';
import { vital } from '../_models/rpo.model';
import { FetchAssignmentDataService } from '../_services/fetchAssignmentData.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralOrderDataService {
  private Vitals$ = [] as vital[];

  constructor(
    private sg: SimpleGlobal,
    private fetchAssignmentDataService: FetchAssignmentDataService
  ) {}

  setData(): Observable<vital[]> {
    this.Vitals$ = [];

    for (let index = 1; index < 9; index++) {
      this.sg['instance_id'] = 'POD' + index;
      this.fetchAssignmentDataService
        .getGeneralOrderStudent()
        .subscribe((gOrder: generalOrder) => {
          let myvital = {} as vital;
          myvital.Day = 'Day ' + index;
          if (gOrder) {
            myvital.Blood_Pressure =
              gOrder.monitoring[gOrder.monitoring.chosen].SBP.data[0] +
              '/' +
              gOrder.monitoring[gOrder.monitoring.chosen].DBP.data[0];
            myvital.temperature =
              gOrder.monitoring[gOrder.monitoring.chosen].Temperature.data[0];
            myvital.Pulse_Rate =
              gOrder.monitoring[gOrder.monitoring.chosen].Pulse.data[0];
            myvital.Repiratory_Rate =
              gOrder.monitoring[gOrder.monitoring.chosen].RR.data[0];
            myvital.spo2 =
              gOrder.monitoring[gOrder.monitoring.chosen].O2Sat.data[0];
            myvital.gcs =
              gOrder.monitoring[gOrder.monitoring.chosen].GCS.data[0];
            myvital.activity = gOrder.activity.category;
            myvital.diet = gOrder.diet.category;
            myvital.Repiratory_Monitor = gOrder.respiratoryMonitoring.category;
            this.Vitals$.push(myvital);
          }
          gOrder = null;
          myvital = null;
        });
    }
    this.Vitals$.sort((a, b) => (a.Day > b.Day ? 1 : -1));
    return Observable.of(this.Vitals$);
  }
}
