import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards';
import { SuperAdminGuard } from './_guards/super-admin.guard';
import { ActivateEmailComponent } from './activate-email/activate-email.component';
import { Auth0CallbackComponent } from './auth/auth0-callback/auth0-callback.component';
import { BillingComponent } from './billing/billing.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmationSentComponent } from './confirmation-sent/confirmation-sent.component';
import { DashContentComponent } from './dashboard';
import { ErrorPageComponent } from './error-page/error-page.component';
import { GradesComponent } from './grades';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { NewsComponent } from './news/news.component';
import { NotAuthorizedComponent } from './not-authorized';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegisterAccountAddressComponent } from './register-account-address/register-account-address.component';
import { RegisterAccountPersonalComponent } from './register-account-personal/register-account-personal.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { RegisterCampaignAccountComponent } from './register-campaign-account/register-campaign-account.component';
import { ResendActivationComponent } from './resend-activation/resend-activation.component';
import { ResetEmailComponent } from './reset-email/reset-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StudentCaseOverviewComponent } from './student-case-overview/student-case-overview.component';
import { StudentFeedbackNursingComponent } from './student-feedback-nursing/student-feedback-nursing.component';
import { StudentGradesComponent } from './student-grades/student-grades.component';
import { SuperAdminDashboardComponent } from './super-admin/super-admin-dashboard/super-admin-dashboard.component';
import { AddCasesComponent } from './teacher-dashboard/add-cases/add-cases.component';
import { ClassdashboardComponent } from './teacher-dashboard/classldashboard/classdashboard.component';
import { CreateClassComponent } from './teacher-dashboard/create-class/create-class.component';
import { EditFormativeAssessmentComponent } from './teacher-dashboard/edit-formative-assessment/edit-formative-assessment.component';
import { EditSummativePerformanceComponent } from './teacher-dashboard/edit-summative-performance/edit-summative-performance.component';
import { FormativeAssessmentComponent } from './teacher-dashboard/formative-assessment/formative-assessment.component';
import { StudentFormativeAssessmentComponent } from './teacher-dashboard/student-formative-assessment/student-formative-assessment.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { OutagePageComponent } from './outage-page/outage-page.component';

const routes: Routes = [
  { path: 'outage-page', component: OutagePageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'resend', component: ResendActivationComponent },
  { path: 'recover', component: PasswordRecoveryComponent },
  { path: 'news', component: NewsComponent },
  { path: 'resetPassword/:token', component: ResetPasswordComponent },
  { path: 'activate/:token', component: ActivateEmailComponent },
  { path: 'reset-email', component: ResetEmailComponent },
  { path: 'confirmEmail', component: ConfirmEmailComponent },
  { path: 'confirmSent', component: ConfirmationSentComponent },
  { path: 'confirmPasswordReset', component: ResetPasswordComponent },
  { path: 'register', component: RegisterAccountComponent },
  { path: 'register-address', component: RegisterAccountAddressComponent },
  { path: 'register-personal', component: RegisterAccountPersonalComponent },
  { path: 'register-campaign-account', component: RegisterCampaignAccountComponent },
  {
    path: 'assignment',
    loadChildren: () => import('./simulations/simulations.module').then((m) => m.SimulationsModule),
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['sysadmin', 'creator', 'learner'],
        redirectTo: 'notauthorized',
      },
    },
  },
  { path: 'error', component: ErrorPageComponent },
  {
    path: 'notauthorized',
    component: NotAuthorizedComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['sysadmin', 'creator', 'learner'],
        redirectTo: 'notauthorized',
      },
    },
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['sysadmin', 'creator', 'learner'],
        redirectTo: 'login',
      },
    },
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['creator', 'sysadmin', 'learner'],
        redirectTo: 'dashboard',
      },
    },
  },
  {
    path: 'dashboard',
    component: DashContentComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['creator', 'sysadmin', 'learner'],
        redirectTo: 'notauthorized',
      },
    },
  },
  {
    path: 'lms',
    loadChildren: () => import('app/lms/lms.module').then((m) => m.LmsModule),
  },
  {
    path: 'sudo',
    component: SuperAdminDashboardComponent,
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'case-library',
    loadChildren: () => import('app/case-library/case-library.module').then((m) => m.CaseLibraryModule),
    canActivate: [AuthGuard],
    data: {
      epa: false,
      permission: {
        only: ['creator', 'sysadmin', 'learner'],
        redirectTo: 'dashboard',
      },
    },
  },
  {
    path: 'teacher-dashboard',
    children: [
      { path: '', component: TeacherDashboardComponent, pathMatch: 'full' },
      { path: 'createclass', component: CreateClassComponent },
      { path: 'classdashboard', component: ClassdashboardComponent },
      { path: 'editsummativeperformance', component: EditSummativePerformanceComponent },
      { path: 'formativeassessment', component: FormativeAssessmentComponent },
      { path: 'editformativeassessment', component: EditFormativeAssessmentComponent },
      { path: 'addcases/:id', component: AddCasesComponent },
    ],
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['creator', 'learner', 'sysadmin'],
        redirectTo: 'notauthorized',
      },
    },
  },
  {
    path: 'grades',
    component: GradesComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['creator', 'sysadmin'],
        redirectTo: 'notauthorized',
      },
    },
  },
  {
    path: 'student-class/:id',
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['learner', 'sysadmin'],
        redirectTo: 'notauthorized',
      },
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: StudentFormativeAssessmentComponent,
      },
    ],
  },
  {
    path: 'gradesOverview',
    canActivate: [AuthGuard],
    data: {
      permission: {
        only: ['learner'],
        redirectTo: 'notauthorized',
      },
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: StudentGradesComponent,
      },
      {
        path: 'caseOverview',
        component: StudentCaseOverviewComponent,
      },
      {
        path: 'feedback',
        loadChildren: () => import('./student-feedback/student-feedback.module').then((m) => m.StudentFeedbackModule),
      },
      {
        path: 'nursing-feedback',
        component: StudentFeedbackNursingComponent,
      },
    ],
  },
  {
    path: 'education-resource',
    loadChildren: () => import('./education-resource/education-resource.module').then((m) => m.EducationResourceModule),
  },
  {
    path: 'simulations',
    loadChildren: () => import('./simulations/simulations.module').then((m) => m.SimulationsModule),
  },
  {
    path: 'callback',
    component: Auth0CallbackComponent,
  },
  { path: '**', redirectTo: '' },
  { path: 'undefined', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
