<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Create an Account</h1>
        </div>
        <mat-horizontal-stepper
          labelPosition="bottom"
          #stepper
          linear
          [@.disabled]="true"
          (selectionChange)="onTabSelectionChange($event, stepper)"
        >
          <mat-step [stepControl]="registrationForm">
            <form [formGroup]="registrationForm" class="login-form">
              <ng-template matStepLabel>Name & Email</ng-template>
              <div class="px-3 form-element">
                <label> Email Address </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input
                    matInput
                    id="email"
                    placeholder="Email"
                    type="email"
                    class="full-width"
                    class="lf--input"
                    formControlName="email"
                    required
                  />
                  <mat-error class="text-danger">
                    {{
                      registrationForm.get('email').hasError('notIn')
                        ? 'Email already exists'
                        : 'Please enter a valid email'
                    }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-row">
                <div class="form-element">
                  <div class="full-width small-12 columns">
                    <label> First Name </label>
                    <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                      <input
                        matInput
                        id="firstName"
                        type="text"
                        placeholder="First Name"
                        class="full-width"
                        formControlName="firstName"
                        required
                        class="lf--input"
                      />
                      <mat-error class="text-danger"> First Name is Required! </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-row">
                <div class="form-element">
                  <div class="full-width small-12 columns">
                    <label> Last Name </label>
                    <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                      <input
                        matInput
                        id="lastName"
                        placeholder="Last Name"
                        type="text"
                        class="full-width"
                        class="lf--input"
                        formControlName="lastName"
                        required
                      />
                      <mat-error class="text-danger"> Last Name is Required! </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div>
                <button class="nextBtn" mat-button (click)="goNext(stepper)">Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="registrationFormExtended">
            <form [formGroup]="registrationFormExtended" class="login-form">
              <ng-template matStepLabel>Address & Occupation</ng-template>

              <div class="full-width small-12 columns">
                <p>Occupation</p>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <mat-select
                    formControlName="occupation"
                    placeholder="Select an Occupation"
                    required
                    name="occupation"
                  >
                    <mat-option *ngFor="let occupation of occupationsList" [value]="occupation">
                      {{ occupation }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="text-danger"> Occupation is Required! </mat-error>
                </mat-form-field>
              </div>
              <div class="medium-6 columns">
                <p>Country</p>
                <mat-form-field appearance="outline" floatLabel="never">
                  <mat-select
                    formControlName="country"
                    placeholder="Select a Country"
                    (selectionChange)="updateInstitutions()"
                    required
                  >
                    <mat-option *ngFor="let option of countryList" [value]="option.countryShortCode">
                      {{ option.countryName }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="text-danger"> Country is Required! </mat-error>
                </mat-form-field>
              </div>
              <div class="full-width small-12 columns">
                <p>Institution</p>
                <mat-form-field
                  appearance="outline"
                  [attr.disabled]="!countryChosen"
                  floatLabel="never"
                  class="full-width"
                >
                  <input
                    type="text"
                    placeholder="Enter institution name"
                    aria-label="Institution"
                    matInput
                    formControlName="institution"
                    [matAutocomplete]="institutionsAutocomplete"
                  />
                  <mat-autocomplete autoActiveFirstOption #institutionsAutocomplete="matAutocomplete">
                    <mat-option *ngFor="let option of filteredInstitutions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="!countryChosen">Please Choose a Country First!</mat-error>
                  <mat-error class="text-danger"> Please enter your institution name </mat-error>
                </mat-form-field>
              </div>
              <div class="full-width small-12 columns">
                <mat-checkbox formControlName="agree" class="policy-box" #policy required>
                  I have read and agree to the terms and conditions of the End-User License and Privacy Policy.
                </mat-checkbox>
                <div class="d-flex justify-content-center my-3">
                  <a
                    class="small mx-3"
                    href="https://www.cyberpatient.ca/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Privacy Policy</a
                  >
                  <a
                    class="small mx-3"
                    href="https://www.cyberpatient.ca/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Terms of Use</a
                  >
                </div>
              </div>
              <div class="full-width small-12 columns create-container">
                <button
                  type="submit"
                  mat-button
                  [disabled]="!registrationFormExtended.valid || loading"
                  (click)="submitForm()"
                >
                  Create Account
                </button>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
