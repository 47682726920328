import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('new').value; // get password from our password form control
    const confirmPassword: string = control.get('verify').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('verify').setErrors({ NoPassswordMatch: true });
    }
  }

  static registerPasswordMatchValidator(control: AbstractControl) {
    const password: string = control.get('pwd').value; // get password from our password form control
    const confirmPassword: string = control.get('verify').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('verify').setErrors({ NoPassswordMatch: true });
    }
  }

  static notIn(arr: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!arr || arr.length === 0) {
        return null;
      }

      const isInArray = arr.includes(control.value);
      return isInArray ? { notIn: true } : null;
    }
  }

  static notContains(value: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!value || !control.value) {
        return null;
      }

      const doesContain = (control.value as string).includes(value);
      return doesContain ? { notContains: true } : null;
    }
  }
}
