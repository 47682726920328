import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { course, userid } from 'app/_models/teacher-dashboard.model';
import { Observable } from 'rxjs';
import { TeachDashBoardModel, TeachDashBoardState } from '../../state/teacherdashboard.state';
import { TeacherDashboardDataService } from '../../teacher-dashboard/teacher-dashboard-data.service';
import { hasCourseEnded } from 'utils/helpers';

@Component({
  selector: 'app-classes-list',
  templateUrl: './classeslist.component.html',
  styleUrls: ['./classeslist.component.scss'],
})
export class ClasseslistComponent implements OnInit {
  public classesData = [];
  displayedColumns = ['status', 'classname', 'duration'];
  todayDate: Date = new Date();
  dataSource: MatTableDataSource<course>;
  classlist: course[] = [];
  selectedStatus = 'active';
  @Select(TeachDashBoardState.getTeacherDashBoardState)
  storeTeacherDashboardState: Observable<TeachDashBoardModel>;
  sortedClass: { active: course[]; inactive: course[] };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  userid: userid;

  constructor(private teacherDashboardService: TeacherDashboardDataService, private router: Router) {}

  ngOnInit() {
    this.teacherDashboardService.getCourseListStudent().subscribe((response) => {
      this.classesData = response.filter((x) => x != null);
      this.dataSource = new MatTableDataSource(this.classesData);
      this.sortClass();
    });
    this.storeTeacherDashboardState.subscribe((s) => (this.userid = s.userid));
  }

  sortClass() {
    const active: course[] = [];
    const inactive: course[] = [];

    this.classesData.forEach((course) => {
      const isOver = hasCourseEnded(course.end);
      if (isOver && !course.archived) {
        inactive.push(course);
      } else {
        active.push(course);
      }
    });

    this.sortedClass = {
      active: active,
      inactive: inactive,
    };
  }

  getcourses() {
    this.teacherDashboardService.getCourseListStudent().subscribe((response) => {
      this.classesData = response;
    });
  }

  checkDate(dt: string) {
    return Date.parse(this.todayDate.toDateString()) > Date.parse(dt);
  }

  isCourseUpcoming = (course: course): boolean => {
    return new Date(course.start).getTime() > new Date().getTime();
  };

  goToClassDashboard(course: course) {
    this.router.navigate(['student-class', course._id]);
  }
}

export interface Classes {
  status: string;
  className: string;
  duration: string;
}
