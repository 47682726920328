<div class="mat-typography">
    <div class="row">
        <div class="container">
            <div class="mat-elevation-z0 score-card">
                <mat-card-header>
                    <mat-card-title>

                        <h1 class="header-create">Create New Class</h1>

                    </mat-card-title>
                    <mat-card-subtitle>
                        <p>Fill in the class details that you would like your students to see.<br/>Add your student list and assign cases to your new class</p>
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div class="t56">

                        <form class="cl-form" [formGroup]="currentForm" (ngSubmit)="onSubmit()">

                            <div class="form-group c1">
                                <mat-label for="title"><label class="name-header">Class Name</label></mat-label>
                                <mat-form-field appearance="outline">
                                    <input formControlName="title" required matInput>
                                </mat-form-field>

                            </div>

                            <div class="form-group c1">
                                <mat-label><label class="name-header">Description</label></mat-label>
                                <mat-form-field appearance="outline">
                                    <textarea formControlName="description" matInput></textarea>
                                </mat-form-field>
                            </div>
                            <div class="sec2">

                                <div class="form-group date">
                                    <mat-label><label class="name-header">Class Start:</label></mat-label>
                                    <mat-form-field appearance="fill" class="fc22 fc221">
                                        <mat-label class="name-header date">Date Picker</mat-label>
                                        <input formControlName="startDate" matInput [matDatepicker]="pickerstart" required readonly [disabled]="checkStart()" [min]="edit?myCourse.start:todayDate">
                                        <mat-datepicker-toggle matSuffix [for]="pickerstart">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerstart></mat-datepicker>

                                    </mat-form-field>
                                </div>

                                <div class="form-group date">
                                    <mat-label><label class="name-header">Class End:</label>
                                    </mat-label>
                                    <mat-form-field appearance="fill" class="fc22 fc221">
                                        <mat-label class="name-header date">Date Picker</mat-label>
                                        <input formControlName="endDate" matInput [matDatepicker]="pickerend" required readonly [min]="endDate">
                                        <mat-datepicker-toggle matSuffix [for]="pickerend">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerend></mat-datepicker>
                                    </mat-form-field>
                                </div>


                            </div>
                            <div class="form-group fc222">
                                <mat-label><label class="name-header">Skill Level</label></mat-label>
                                <mat-form-field required appearance="outline">

                                    <mat-select formControlName="skillLevel" [(value)]="selected">
                                        <mat-option value="HIST">1</mat-option>
                                        <mat-option value="HTPE">2</mat-option>
                                        <mat-option value="NOMR">3</mat-option>
                                        <mat-option value="FULL">4</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group c1">
                              <mat-label><label class="name-header">Required passing percentage</label></mat-label>
                              <mat-form-field appearance="outline">
                                  <textarea formControlName="requiredPassingPercent" matInput></textarea>
                              </mat-form-field>
                          </div>
                            <div class="sec3">
                                <div *ngIf="currentForm.status=='INVALID' && currentForm.errors!=null">
                                    <span>* {{currentForm.errors.dates}}</span>
                                </div>
                            </div>


                        </form>

                    </div>
                </mat-card-content>
                <mat-card-actions class="create-class-actions">
                    <button mat-raised-button [disabled]="validate()" color="primary" type="submit" (click)="onSubmit()" class="create-class-button complete">{{submitButtonValue}}</button>
                    <button mat-raised-button color="primary" (click)="onCancel()" class="create-class-button">Cancel</button>
                </mat-card-actions>
            </div>
        </div>
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</div>
