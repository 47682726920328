<mat-dialog-content>
    <div class="addStudents-modal">
        <h3 class="add-student-heading">Summative Assessment on : {{data.assessment.created | date}} </h3>
        <div class="invite-list2">
            <div *ngIf="data.assessment!=undefined">
                <div *ngFor="let question of data.assessment.checkBoxes">
                    <div>
                        <ng-container *ngIf="question.value; else uncheck">
                            <span class="material-icons c1" color="primary">
                                done
                            </span>
                        </ng-container>
                        <ng-template #uncheck>
                            <span class="material-icons c2" color="warn">
                                close
                            </span>
                        </ng-template>
                        <span class="qt2">{{question.text}}</span>
                    </div>


                </div>
                <div class="result2">
                    <h4> Final Assessment Result: </h4>
                    <ng-container *ngIf="data.assessment.grading; else elseresult">
                        <span class="material-icons c1" color="primary">
                            done
                        </span>Pass

                    </ng-container>
                    <ng-template #elseresult>
                        <span class="material-icons c2" color="warn">
                            close
                        </span> Fail
                    </ng-template>
                    <span>
                        {{data.assessment.score}} %
                    </span>
                </div>
                <div>
                    <h4>Comments: </h4>
                    {{data.assessment.text}}
                </div>
            </div>
            <div>
                <mat-accordion *ngIf="data.assessment.grading">
                    <mat-expansion-panel hideToggle (opened)="getGrade()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Certificate Available
                            </mat-panel-title>
                            <mat-panel-description>
                                Click to see
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="loading; else elseTemplate">
                            <mat-spinner [diameter]="80"></mat-spinner>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div class="pn1">
                                <button mat-button color="primary" [disabled]="!data.assessment.grading" mat-button cdkFocusInitial
                                    (click)="exportas()">Download a certificate</button>
                                
                            </div>
                        </ng-template>
                        <canvas id="myCanvas" width="1000" height="1000"></canvas>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</mat-dialog-content>
