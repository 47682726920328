import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AddCourse, EditCourse } from 'app/actions/teacherdashboard.action';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { course, instructor } from '../../_models/teacher-dashboard.model'
import { TeachDashBoardModel, TeachDashBoardState } from '../../state/teacherdashboard.state';


@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss']
})
export class CreateClassComponent implements OnInit {
  currentForm;
  edit = false;
  submitted = false;
  Insturctor: instructor;
  myCourse: course;


  @Select(TeachDashBoardState.getTeacherDashBoardState) storeTeacherDashboardState: Observable<TeachDashBoardModel>;
  submitButtonValue: string;
  selected = '1';
  loadingAndSaving;
  todayDate: Date = new Date();
  endDate: Date = new Date();
  constructor(private formBuilder: FormBuilder, private router: Router, private store: Store, private _location: Location, ) {
  }

  ngOnInit() {
    this.loadingAndSaving = true;
    this.storeTeacherDashboardState.subscribe((x) => {
      this.Insturctor = x.instructor;
      if (x.instructor == null) {
        const path = '/teacher-dashboard';
        this.router.navigate([path], { skipLocationChange: false });
      }
      if (history.state.classedit) {
        this.edit = true;
        this.submitButtonValue = 'Save Class'

        this.myCourse = x.selectedcourse;
        this.currentForm = this.formBuilder.group({
          name: '',
          description: this.myCourse.description,
          startDate: [this.myCourse.start],
          endDate: [this.myCourse.end],

          skillLevel: [this.myCourse.recommendationLevel, Validators.required],
          requiredPassingPercent: [this.myCourse.requiredPassingPercent, Validators.required],
          title: [this.myCourse.title, Validators.required],
        }, { validator: this.dateLessThan('startDate', 'endDate') });

      } else {
        this.edit = false;
        this.submitButtonValue = 'Complete'
        this.currentForm = this.formBuilder.group({
          name: '',
          description: '',
          status: 'active',
          startDate: [''],
          endDate: [''],
          skillLevel: ['', Validators.required],
          requiredPassingPercent: [Number],
          title: ['', Validators.required]
        }, { validator: this.dateLessThan('startDate', 'endDate') });
      }
    });
    this.loadingAndSaving = false;
    this.setMinDate();
  }
checkStart(): boolean {
  if (this.edit) {
    if (this.currentForm.valid) {
      const dt1 = new Date(this.currentForm.controls.startDate.value);
      const dt2 = new Date();
      return dt1 < dt2 ? true : false;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

  setMinDate() {

    if (this.currentForm.valid) {
      const dt1 = new Date(this.currentForm.controls.startDate.value);
      const dt2 = new Date();
      if (dt1 > dt2) {
        this.currentForm.get('startDate').disable();
      }

    }

  }
  dateLessThan(from: string, to: string) {

    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (new Date(f.value) > new Date(t.value)) {
        return {
          dates: 'Date from should be less than Date to'
        };
      }
      return {};
    }
  }

  onCancel() {
    // this.router.navigate(['/teacher-dashboard']);
    this._location.back();
  }

  validate() {
    if (this.currentForm.valid) {
      if (!this.currentForm.pristine) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }

  }
  onSubmit() {

    const currentCourse: course = {
      _id: '1',
      status: 'active',
      cases: [],
      students: [],
      institution_id: this.Insturctor.institution_id._id,
      instructor_id: this.Insturctor._id,
      name: 'a',
      start: moment(this.currentForm.controls.startDate.value, 'YYYY-MM-DD').toDate(),
      end: moment(this.currentForm.controls.endDate.value, 'YYYY-MM-DD').toDate(),
      title: this.currentForm.controls.title.value,
      description: this.currentForm.controls.description.value,
      recommendationLevel: this.currentForm.controls.skillLevel.value,
      requiredPassingPercent: this.currentForm.controls.requiredPassingPercent.value,
      createdBy: '',
      created: new Date(),
      __v: 1,
      id: ''
    }


    if (this.edit) {

      currentCourse._id = this.myCourse._id;
      currentCourse.cases = this.myCourse.cases;
      currentCourse.students = this.myCourse.students;
      currentCourse.status = this.myCourse.status;
      currentCourse.start = moment(this.currentForm.controls.startDate.value, 'YYYY-MM-DD').toDate(),
        currentCourse.end = moment(this.currentForm.controls.endDate.value, 'YYYY-MM-DD').toDate(),
        currentCourse.title = this.currentForm.controls.title.value,
        currentCourse.description = this.currentForm.controls.description.value,
        currentCourse.recommendationLevel = this.currentForm.controls.skillLevel.value,
        currentCourse.requiredPassingPercent = this.currentForm.controls.requiredPassingPercent.value,


        this.store.dispatch([new EditCourse(currentCourse)]).subscribe(() => {
          const path = '/teacher-dashboard/classdashboard';
          this.router.navigate([path], { skipLocationChange: false });
        })
    } else {
      delete currentCourse._id;
      delete currentCourse.cases;
      delete currentCourse.students;

      this.store.dispatch([new AddCourse(currentCourse)]).subscribe(() => {
        const path = '/teacher-dashboard/classdashboard';
        this.router.navigate([path], { skipLocationChange: false });
      })
    }


  }


}
