import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';

export class TutorialSequence {
  subject: BehaviorSubject<number>;
  length: number;

  constructor(subject: BehaviorSubject<number>, length: number) {
    this.subject = subject;
    this.length = length;
  }

  get change(): Observable<number> {
    return this.subject.asObservable();
  }

  get changeUntilLimit(): Observable<number> {
    return this.subject.pipe(take(this.length));
  }

  next(value?: number) {
    this.subject.next(value ?? this.subject.getValue() + 1);
  }

  stop() {
    this.next(-1);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TutorialsService {
  private tutorialsMap: Map<string, TutorialSequence> = new Map();
  private backgroundEl: HTMLElement;

  constructor(@Inject(DOCUMENT) private document: HTMLDocument) {
  }

  start(context: string, length: number): TutorialSequence {
    let tutorialSequence = this.tutorialOf(context);

    if (!tutorialSequence) {
      tutorialSequence = new TutorialSequence(
        new BehaviorSubject<number>(0),
        length + 1,
      );
      this.tutorialsMap.set(context, tutorialSequence);
    } else {
      tutorialSequence.length = length + 1;
      tutorialSequence.next(0);
    }

    tutorialSequence.changeUntilLimit.pipe(
      takeWhile((val) => val !== -1)
    ).subscribe(
      (order) => {
        if (order === 0) {
          this.createDimBackground();
          setTimeout(() => {
            this.showDimBackground();
          });
        }
      },
      () => {},
      () => {
        this.hideDimBackground();
      }
    );

    return tutorialSequence;
  }

  tutorialOf(context: string): TutorialSequence | undefined {
    return this.tutorialsMap.get(context);
  }

  private createDimBackground() {
    if (this.backgroundEl) {
      return;
    }

    const background = this.document.createElement('div');
    background.setAttribute('class', 'bg-fluid bg-color-dim fade');
    background.setAttribute('style', 'pointer-events: none; z-index: 0');
    document.body.appendChild(background);

    this.backgroundEl = background;
  }

  private showDimBackground() {
    this.backgroundEl.classList.add('show');
  }

  private hideDimBackground() {
    this.backgroundEl?.classList.remove('show');
    setTimeout(() => {
      this.backgroundEl?.remove();
      this.backgroundEl = undefined;
    });
  }
}
