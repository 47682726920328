import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services';
import { AuthenticationService } from '../../_services/authentication.service';
import { DebugService } from '../../debug.service';
import { SidebarItem, SidebarService } from './sidebar.service';
import { SimpleGlobal } from 'ng2-simple-global';
import { HealthcheckService } from 'app/_services/healthcheck.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  role;
  error;
  component;
  instance;
  placeholder;
  debug = false;
  elmnt;
  pos1;
  pos4;
  teacher: boolean;
  epaMenuFlag: boolean;
  localShowHide: boolean;
  public menuItems: Array<IrouterType>;
  public currentRoute = '/';

  constructor(
    private sidebarService: SidebarService,
    private debugService: DebugService,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private sg: SimpleGlobal,
    private healthcheckService: HealthcheckService
  ) {
    this.epaMenuFlag = environment.showEpa;
    this._loadMenuOptions();

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit() {
    this.debugService.getshowDebugWindow().subscribe((f) => (this.debug = f));
    this.debugService.getLocalShowHideDebug().subscribe((f) => (this.localShowHide = f));
    this._loadMenuOptions();
    this.sidebarService.routeChange.subscribe((route) => {
      this.currentRoute = route;
    });
  }

  showDebug() {
    this.localShowHide
      ? this.debugService.updateLocalShowHideDebug(false)
      : this.debugService.updateLocalShowHideDebug(true);
  }

  _loadMenuOptions() {
    this.userService.userChange.subscribe((user) => {
      if (!user) {
        this.error = true;
      } else {
        this.role = user.permissions;
        if (this.role === null || this.role === undefined) {
          this.role = 'learner';
        }
        this.menuItems = this.sidebarService.getCommon();
        if (this.role === 'learner') {
          this.error = false;
        } else if (this.role === 'creator' || this.role === 'admin' || this.role === 'sysadmin') {
          this.menuItems = [...this.menuItems, ...this.sidebarService.getCreator()];
          this.error = false;
        }
        if (this.epaMenuFlag === false || environment.production) {
          this.menuItems = this.menuItems.filter((menuItem) => menuItem.router !== '/case-library/epa');
        }
        if (user.institutionRole === 'student') {
          this.menuItems = this.menuItems.filter(
            (menuItem) =>
              menuItem.router !== '/case-library/epa' &&
              menuItem.router !== '/account' &&
              menuItem.router !== '/case-library' &&
              menuItem.router !== '/favourites'
          );
        }

        if (user.institutionRole !== 'professor') {
          this.menuItems = this.menuItems.filter((menuItem) => menuItem.name !== 'Teacher Dashboard');
        }
      }
    });
  }

  onNavItemClick(item: SidebarItem) {
    if (!this.healthcheckService.isServerDown()) {
      this.sidebarService.currentRoute = item.router;
    }
  }
}
