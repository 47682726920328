<div>
  <mat-form-field appearance="outline" class="w-50">
    <input matInput placeholder="Search email address"
           [(ngModel)]="searchText"
           (keydown.enter)="search.emit(searchText)">
  </mat-form-field>
  <section *ngIf="user">
    <div class="row">
      <h3 class="col-6">{{user.firstName}} {{user.lastName}}</h3>
      <div class="col-6">Email: <b>{{user.email}}</b></div>
    </div>
    <div class="row my-2">
      <div class="col-6">Institution: <b>{{user.institution}}</b></div>
      <div class="col-6">Occupation: <b>{{user.occupation}}</b></div>
    </div>
    <div class="row my-2">
      <div class="col-6">Account Type: <b>{{user.institutionRole ? 'LMS - ' + user.institutionRole : 'Individual' }}</b>
      </div>
      <div class="col-6">Membership: <b>{{user.membership}}</b></div>
    </div>
    <div class="row my-2">
      <div class="col-6">User ID: <b>{{user._id}}</b></div>
      <div class="col-6">Institution Member ID: <b>{{user.memberId || 'N/A' }}</b></div>
    </div>
    <div class="row mt-3">
      <button mat-button class="font-weight-bold text-color-primary" (click)="handleToggleEditing()">
        {{ editing ? 'Cancel' : 'Transfer Institution' }}
      </button>
    </div>
    <div *ngIf="editing">
      <mat-form-field appearance="outline" class="col-5">
        <mat-select [(value)]="selectedInstitution">
          <mat-option *ngFor="let institution of institutions" [value]="institution">
            {{institution.name}} ({{institution._id}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-5">
        <mat-select [(value)]="selectedRole">
          <mat-option value="admin">
            Admin
          </mat-option>
          <mat-option value="professor">
            Professor
          </mat-option>
          <mat-option value="student">
            Student
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button class="font-weight-bold text-color-primary col-2"
              (click)="handleTransferInstitution()">
        Confirm
      </button>
    </div>
  </section>
</div>
