import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { SuperAdminService } from './services/super-admin.service';
import { SuperAdminDashboardComponent } from './super-admin-dashboard/super-admin-dashboard.component';
import { InstitutionsListComponent } from './institutions-list/institutions-list.component';
import { UsersListComponent } from './users-list/users-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



@NgModule({
  declarations: [SuperAdminDashboardComponent, InstitutionsListComponent, UsersListComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatTableModule,
    MatSlideToggleModule
  ],
  providers: [SuperAdminService],
})
export class SuperAdminModule { }
