<div class="container-fluid position-relative">
  <a class="text-color-primary" (click)="backClicked()">
    <b>Back</b>
  </a>
  <div class="patient-history module-container mat-typography row">
    <mat-card class="large-12 columns cyberWrap equalize outer-card">
      <div class="large-12 columns overall-header">
        <div class="large-6 columns">
          <h2>Performance:</h2>
        </div>
      </div>
      <div *ngIf="session" class="row header-content scores">
        <div class="large-3 columns">
          <div class="result flex">
            <h4 class="stat-label">Knowledge:</h4>
            <h4 class="stat-number">{{ session.feedback.finalScores.knowledgeScore | number: '1.2-2' }}%</h4>
          </div>
        </div>
        <div class="large-3 columns">
          <div class="result flex">
            <h4 class="stat-label">Time:</h4>
            <h4 class="stat-number">{{ session.feedback.finalScores.time | number: '1.2-2' }} mins</h4>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="large-12 columns cyberWrap equalize outer-card">
      <div class="description-container">
        <div class="large-9 columns header-container">
          <h3>
            Play the scenario and use the seekbar to select checkpoints for checking your correct or incorrect answers
            to questions
          </h3>
        </div>
        <div class="large-3 columns header-container">
          <button
            [disabled]="disablePanel"
            mat-raised-button
            matBadgeColor="red"
            [matBadge]="notifications.total"
            [matBadgeHidden]="!notifications.total"
            class="info-button"
            (click)="onClickInfo()"
          >
            <mat-icon>book</mat-icon> Info
          </button>
        </div>
      </div>
      <mat-drawer-container class="drawer-container w-100" hasBackdrop="false">
        <mat-drawer [opened]="showQuestion" disableClose #drawerQuestions class="question-sidenav" mode="over">
          <section *ngIf="selectedQuestion" class="question-content">
            <div class="question-header-container">
              <span class="col-10">{{ selectedQuestion.question }}</span>
              <div class="toggle-button col-2">
                <button class="mat-button" (click)="toggleQuestionDrawer()">
                  <mat-icon class="mat-primary">arrow_back_ios</mat-icon>Hide
                </button>
              </div>
            </div>
            <div class="option-group" *ngIf="selectedQuestion.type === 'assessment'">
              <mat-accordion class="option-assessment">
                <mat-expansion-panel
                  *ngFor="let option of selectedQuestion.checkedOptions; index as qIndex"
                  [expanded]="selectedAssessment === qIndex"
                  (opened)="selectAssessment(qIndex)"
                  hideToggle
                >
                  <mat-expansion-panel-header class="assessment-panel-header">
                    <div class="assessment-label col">
                      <span class="assessment-text">{{ option.label }}</span>
                    </div>
                    <div class="assessment-icon col-3 {{ option.selected ? option.class : '' }}">
                      <mat-icon *ngIf="option.class == 'correct'" mat-primary> check_circle</mat-icon>
                      <mat-icon *ngIf="option.class == 'incorrect'" mat-primary>cancel</mat-icon>
                    </div>
                  </mat-expansion-panel-header>
                  <ul>
                    <li *ngFor="let point of option.points">{{ point }}</li>
                  </ul>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="option-group" *ngIf="selectedQuestion.type === 'lab'">
              <mat-accordion class="option-assessment">
                <mat-expansion-panel *ngFor="let lab of labTests | keyvalue">
                  <mat-expansion-panel-header>
                    <span>{{ lab.key }}</span>
                  </mat-expansion-panel-header>
                  <table mat-table [dataSource]="lab.value.dataSource" class="mat-elevation-z0">
                    <ng-container matColumnDef="test">
                      <th mat-header-cell *matHeaderCellDef>Labtest</th>
                      <td mat-cell class="labtest-text" *matCellDef="let element">{{ element.test }}</td>
                    </ng-container>

                    <ng-container matColumnDef="standard">
                      <th mat-header-cell *matHeaderCellDef>Standard</th>
                      <td mat-cell *matCellDef="let element" class="labtest-text" [innerHTML]="element.standard"></td>
                    </ng-container>

                    <ng-container matColumnDef="unit">
                      <th mat-header-cell *matHeaderCellDef>Unit</th>
                      <td mat-cell class="labtest-text" *matCellDef="let element" [innerHTML]="element.unit"></td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                      <th mat-header-cell *matHeaderCellDef>Result</th>
                      <td mat-cell class="labtest-text" *matCellDef="let element">
                        <div class="icon-text">
                          <span>{{ element.value }}</span>
                          <mat-icon> {{ element.correct ? 'check_circle' : 'cancel' }}</mat-icon>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                      [ngClass]="row.selected ? (row.correct ? 'tr-correct' : 'tr-incorrect') : 'tr'"
                    ></tr>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="option-group options" *ngIf="selectedQuestion.type === 'sub'">
              <div *ngFor="let subOptions of selectedQuestion.subOptions; index as qIndex">
                <div class="sub-question-header">
                  <span>{{ subOptions.question }}</span>
                </div>
                <div class="sub-question-container">
                  <div
                    [matTooltip]="option.label"
                    [matTooltipPosition]="'above'"
                    class="instrument-img {{ option.class }}"
                    *ngFor="let option of subOptions.checkedOptions"
                  >
                    <img [src]="option.img" />
                  </div>
                </div>
              </div>
            </div>
            <mat-radio-group *ngIf="selectedQuestion.type === 'tree'" class="option-group options">
              <mat-radio-button
                class="radio-button"
                *ngFor="let item of selectedQuestion.treeOptions"
                [value]="item"
                (change)="onChange(item)"
              >
                <span class="wrap-mat-radio-label">
                  {{ item.option }}
                </span>
              </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="selectedQuestion.type === 'radio'" class="option-group">
              <div *ngFor="let item of selectedQuestion.checkedOptions" class="option-radio {{ item.class }}">
                <span class="wrap-mat-radio-label">
                  {{ item.label }}
                </span>
                <button *ngIf="item.notes" mat-outline-button (click)="openNursesNotes()" mat-primary>
                  Show Notes
                </button>
              </div>
            </div>
            <div *ngIf="selectedQuestion.type === 'checkbox'" class="option-group">
              <div class="option-checkbox" *ngFor="let item of selectedQuestion.checkedOptions">
                <div class="checkbox-label col-9">
                  <span>{{ item.label }}</span>
                </div>
                <div class="checkbox-icon col-3 {{ item.selected ? item.class : '' }}">
                  <mat-icon *ngIf="item.class == 'correct'" mat-primary>
                    {{ item.class == 'correct' ? 'check_circle' : 'cancel' }}</mat-icon
                  >
                  <mat-icon *ngIf="item.class == 'incorrect'" mat-primary>cancel</mat-icon>
                </div>
              </div>
            </div>
          </section>
        </mat-drawer>
        <mat-drawer
          [opened]="showInfo"
          #drawerInfo
          (closedStart)="showInfo = false"
          class="info-sidenav"
          mode="over"
          position="end"
        >
          <section class="tab-content">
            <div class="tabs-container">
              <mat-tab-group
                (selectedTabChange)="tabChanged($event)"
                mat-stretch-tabs="true"
                mat-align-tabs="start"
                disablePagination="true"
                class="tab-group"
              >
                <mat-tab>
                  <ng-template mat-tab-label class="tab-header">
                    <i class="fa fa-clipboard sidebar-icon"></i>
                    <div class="text mt-1 mx-auto">PATIENT INFO</div>
                  </ng-template>
                  <div *ngIf="chartList?.length" class="chart-container">
                    <img [src]="patientImage" />
                    <div *ngFor="let chart of chartList">
                      <h3>{{ chart.title }}</h3>
                      <p class="text-description" class="info-text" [innerHTML]="chart.body"></p>
                    </div>
                  </div>
                  <div *ngIf="!chartList?.length" class="vitals-container">
                    <p class="text-description" class="info-text">No Patient Info Available.</p>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label class="tab-header">
                    <i class="fa fa-book sidebar-icon"></i>
                    <div class="text mt-1 mx-auto">UPDATES</div>
                  </ng-template>
                  <div *ngIf="infoAvailable?.length" class="info-container">
                    <div class="info-text-container" *ngFor="let info of infoAvailable"><p [innerHTML]="info"></p></div>
                  </div>
                  <div *ngIf="!infoAvailable?.length" class="vitals-container">
                    <p class="text-description">No Updates Currently.</p>
                  </div>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label class="tab-header">
                    <i class="fa fa-heartbeat sidebar-icon"></i>
                    <div class="text mt-1 mx-auto">VITALS</div>
                  </ng-template>
                  <div *ngIf="selectedVitals" class="vitals-container">
                    <div *ngIf="selectedVitals.vitals.length">
                      <h2>Vitals:</h2>
                      <p *ngFor="let vitals of selectedVitals.vitals" class="text-description">
                        <strong>{{ vitals.label }}:</strong> {{ vitals.value }}
                      </p>
                    </div>
                    <div *ngIf="selectedVitals.settings.length">
                      <h2>Settings:</h2>
                      <p *ngFor="let vitals of selectedVitals.settings" class="text-description">
                        <strong>{{ vitals.label }}:</strong> {{ vitals.value }}
                      </p>
                    </div>
                  </div>
                  <div *ngIf="!selectedVitals" class="vitals-container">
                    <p class="text-description">No Vitals Currently.</p>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="close-container">
              <button class="action mat-button mat-raised-button mat-primary" (click)="showInfo = false">Close</button>
            </div>
          </section>
        </mat-drawer>
        <mat-drawer-content>
          <div class="show-question-container toggle-button" *ngIf="selectedQuestion && !showQuestion">
            <button class="mat-button" (click)="toggleQuestionDrawer()">
              <mat-icon class="mat-primary">arrow_forward_ios</mat-icon>Show Question
            </button>
          </div>
          <video (timeupdate)="setCurrentTime($event)" class="w-100" type="video/mp4" preload="auto" #videoPlayer>
            Browser not supported
          </video>
          <div [hidden]="!showLoop" class="w-100 loop-container">
            <video class="w-100" type="video/mp4" preload="auto" loop #loopPlayer>Browser not supported</video>
          </div>
          <div class="w-100 assessment-background" *ngIf="selectedQuestion && selectedQuestion.type === 'assessment'">
            <div class="img-container">
              <img
                [src]="
                  assessmentImgUrl +
                  (selectedQuestion.assessmentImage ? selectedQuestion.assessmentImage : 'assessment') +
                  '.png'
                "
              />
              <div class="coordinates-container">
                <div
                  class="area"
                  *ngFor="let option of selectedQuestion.assessmentOptions; index as i"
                  [ngStyle]="getCoordinateStyle(option.coordinates.img)"
                >
                  <div
                    [title]="option.organ"
                    class="area-clickable"
                    (click)="selectAssessment(i)"
                    [ngStyle]="getCoordinateStyle(option.coordinates.hotspot)"
                  ></div>
                  <img [src]="option.src" />
                </div>
              </div>
            </div>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
      <div *ngIf="videoHightlights" class="video-controls">
        <div class="control-buttons">
          <button mat-icon-button (click)="previousQuestion()">
            <mat-icon class="control-icon">skip_previous</mat-icon>
          </button>
          <button mat-icon-button (click)="togglePlay()">
            <mat-icon class="control-icon">{{ videoPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
          </button>
          <button mat-icon-button (click)="nextQuestion()">
            <mat-icon class="control-icon">skip_next</mat-icon>
          </button>
        </div>
        <div class="slider-container">
          <nz-slider
            nzTooltipVisible="never"
            [nzMarks]="videoHightlights"
            (ngModelChange)="skipVidTo($event)"
            [nzMax]="moduleLength"
            [ngModel]="currentTime"
          ></nz-slider>
        </div>
      </div>
      <mat-chip-list>
        <mat-chip [ngClass]="['question-type-chip', chip.class]" *ngFor="let chip of chips" (click)="selectChip(chip)">
          <mat-icon class="check-icon" *ngIf="selectedChip === chip">check</mat-icon>
          {{ chip.label }}
        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </div>
</div>
