<div class="pending-invitation-dialog overflow-hidden">
  <div class="bg-color-primary text-center text-color-white font-weight-bold h3 py-3">Pending Institution Invites</div>
  <div class="container container-fluid pb-5 mt-3">
    <span class="font-weight-bold mx-1">
      <div class="invitation-list">
        <div class="invitation-list-item" *ngFor="let invitation of data">
          <span class="font-weight-bold text-color-secondary">{{ invitation.institution.name }}</span>
          <div *ngIf="checkLoading(invitation._id)" class="invitation-buttons">
            <mat-spinner diameter="40"></mat-spinner>
          </div>
          <div *ngIf="checkDeleted(invitation._id)" class="invitation-buttons">
            <span class="mat-error">Deleted</span>
          </div>
          <div *ngIf="invitation.consumed" class="invitation-buttons">
            <span class="text-color-primary">Accepted</span>
          </div>
          <div
            *ngIf="!checkLoading(invitation._id) && !checkDeleted(invitation._id) && !invitation.consumed"
            class="invitation-buttons"
          >
            <button mat-raised-button color="dialog-success" aria-label="Accept" (click)="onAccept(invitation._id)">
              Accept
            </button>
            <button mat-button color="error" aria-label="Decline" (click)="onDecline(invitation._id)">Decline</button>
          </div>
        </div>
      </div>
    </span>
  </div>
</div>
