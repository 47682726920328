import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isBefore'
})
export class IsBeforePipe implements PipeTransform {
  transform(value: string, compareTime: string): boolean {
    const compareUnix = compareTime === 'now' ? Date.now() : new Date(compareTime).getTime();
    return new Date(value).getTime() < compareUnix;
  }
}
