import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export interface SidebarItem {
  name: string;
  icon: string;
  router: string;
}

@Injectable()
export class SidebarService {
  private commonItems: SidebarItem[] = [
    {
      name: 'Dashboard',
      icon: 'fa-home',
      router: '/',
    },
    {
      name: 'Competency Assessment Library',
      icon: 'fa-hospital-user',
      router: '/case-library/nursing',
    },
    {
      name: 'Educational Resources',
      icon: 'fa fa-book',
      router: '/education-resource',
    },
  ];
  private routeState = new Subject<string>();
  private _creator: SidebarItem[] = [
    {
      name: 'Learners',
      icon: 'fa-graduation-cap',
      router: '/learners',
    },
    {
      name: 'Grades',
      icon: 'fa-star',
      router: '/grades',
    },
    {
      name: 'EPA Score',
      icon: 'fa-marker',
      router: '/EPAScore',
    },
    {
      name: 'Support',
      icon: 'fa-life-ring',
      router: '/support',
    },
  ];
  private _learner: SidebarItem[] = [];

  constructor(private readonly router: Router) {
    this.listenToRouteChange();
  }

  set currentRoute(route: string) {
    this.routeState.next(route);
  }

  get routeChange() {
    return this.routeState.asObservable();
  }

  private listenToRouteChange() {
    this.routeChange.subscribe((route) => {
      this.router.navigate([route]);
    });
  }

  getCreator(): IrouterType[] {
    return this._creator;
  }

  getLearner(): IrouterType[] {
    return this._learner;
  }

  getCommon(): IrouterType[] {
    return this.commonItems;
  }
}
