<div class="case-library">
  <div class="row head2">
    <div *ngIf="course != null">
      <h3 class="sub-header">Assign Cases to {{ course.title }}</h3>
      <span class="instruct"> Select from the cases below to assign them to your new class.</span>
    </div>
    <span class="sp2"><button class="done-button" color="primary" mat-raised-button
        (click)="gotoClassdashboard()">Done</button></span>
  </div>

  <mat-card class="having-fun isnt-hard library-card">
    <section *ngFor="let x of data | key: true; let j = index">
      <div class="section-head">
        <h2>{{ x.key }}</h2>
        <span class="see-more" (click)="toggleSection(x.key)">See More
          <div class="arrow" [ngClass]="expand[x.key] ? 'expand' : 'collapse'">↓</div>
        </span>
        <mat-divider inset="true"></mat-divider>
      </div>
      <div class="patient-container" [ngClass]="expand[x.key] ? 'expanded' : 'collapsed'">
        <ul class="item-list">
          <li *ngFor="let case of x.value; let i = index" class="patient">
            <mat-card class="patient-card">
              <mat-card-header>
                <mat-card-title>
                  <span class="title">{{ case.patient_name }}</span>

                  <span>
                    <button mat-stroked-button [ngClass]="setText(case) == 'ADD' ? 'adc' : 'addedc'" color="primary"
                      (click)="addToClass(case._id, x.key, i, $event)">
                      {{ setText(case) }}
                    </button>
                  </span>
                </mat-card-title>
                <mat-card-subtitle>
                  <div class="show-less" matTooltipPosition="above" [matTooltip]="getFinalDiagnostic(case)">
                    {{ isDeprecatedLibrary ? getDeprecatedFinalDiagnostic(case) : getFinalDiagnostic(case) |
                    truncatetext: 40 }}
                  </div>

                  <span
                    (click)="isDeprecatedLibrary ? showDeprecatedLearningObjectives(case, i)  : showLearningObjectives(case)"
                    class="learningo">
                    Learning Objectives
                  </span>
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-actions [class]="x.key">
                <span class="category-container">
                  <span class="icon"></span>
                  <span class="category">{{ x.key }}</span>
                </span>
                <span class="time">
                  <i class="fa-solid fa-square-h tags" *ngIf="case.tags.patientSettings === 'inpatient'"
                    [matTooltip]="'Inpatient'" [matTooltipPosition]="'above'"></i>
                  <i class="fa-solid fa-user-doctor tags" *ngIf="case.tags.patientSettings === 'outpatient'"
                    [matTooltip]="'Outpatient'" [matTooltipPosition]="'above'"></i>
                  <i class="fa-solid fa-user-check" *ngIf="case.tags.peerReviewed" [matTooltip]="'Peer Reviewed Case'"
                    [matTooltipPosition]="'above'"></i>
                </span>
              </mat-card-actions>
            </mat-card>
          </li>
        </ul>
      </div>
    </section>
  </mat-card>
  <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</div>
<ng-template #deprecatedlearningobjectivepopup let-learningobjectivesdata>
  <ng-container *ngIf="!learningobjectivesdata; else elseTemplate"> N/A </ng-container>
  <ng-template #elseTemplate>
    <div class="lo-modal12">
      <p class="lo-heading">{{ learningobjectivesdata.header }}</p>
      <div class="invite-list2" *ngFor="let o of learningobjectivesdata.contents">
        <h3>{{ o.topic }}</h3>
        <div *ngFor="let sub of o.subTopic">
          <p class="lop">{{ sub }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
<ng-template #learningobjectivepopup let-sentences>
  <div class="lo-modal12">
    <div>
      <p class="lo-heading">Learning Objectives</p>
      <ng-container *ngFor="let sentence of sentences">
        <p>{{ sentence }}</p>
      </ng-container>
    </div>
  </div>
</ng-template>