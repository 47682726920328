import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgreementPromptComponent } from '../../agreement-prompt/agreement-prompt.component';
/* eslint-disable */
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openPolicy() {
    this.dialog.open(AgreementPromptComponent);
  }
  currYear: number = new Date().getFullYear();
}
