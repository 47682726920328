import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Signature } from '../_models/signature';
import {
  course,
  coursegrade,
  feedback,
  FormativeFeedback,
  student,
  SummativeFeedback,
} from '../_models/teacher-dashboard.model';
import { Course } from '../lms/interfaces/course';

@Injectable({ providedIn: 'root' })
export class TeacherDashboardDataService {
  private url = environment.apiUrl + '/api/v1/';
  private v2SimulationsUrl = environment.apiUrl + '/api/v2/simulation/';

  constructor(private http: HttpClient) {}

  getCourseListStudent(slim = false): Observable<Course[]> {
    let url = this.url.concat('students/current/courses');
    if (slim) {
      url += '&slim=true';
    }
    return this.http.get<Course[]>(url).pipe(catchError(this.handleError));
  }

  getActiveCourseListStudent(slim = false): Observable<Course[]> {
    let url = this.url.concat('students/current/courses');
    if (slim) {
      url += '?slim=true';
    }
    return this.http.get<Course[]>(url).pipe(catchError(this.handleError));
  }

  getFormativeStudent(course_id: string): Observable<FormativeFeedback[]> {
    return this.http
      .get<FormativeFeedback[]>(this.url.concat('students/current/courses/' + course_id + '/formative'))
      .pipe(catchError(this.handleError));
  }
  getStudentCourseSummative(course_id: string): Observable<SummativeFeedback[]> {
    return this.http
      .get<SummativeFeedback[]>(this.url.concat('students/current/courses/' + course_id + '/summative'))
      .pipe(catchError(this.handleError));
  }
  getStudentCourseGrades(course_id: string): Observable<any> {
    return this.http
      .get(this.url.concat('students/current/courses/' + course_id + '/grades'))
      .pipe(catchError(this.handleError));
  }

  creatStudentSession(course_id: string, case_id: string): Observable<any> {
    return this.http
      .post(this.url.concat('students/current/courses/' + course_id + '/cases/' + case_id + '/sessions'), null)
      .pipe(catchError(this.handleError));
  }

  abortAndCreateTeacherDashboardSession(session_id: string, course_id: string, case_id: string) {
    return this.http
      .post(
        this.url.concat(
          'students/current/courses/' + course_id + '/cases/' + case_id + '/sessions/' + session_id + '/restart'
        ),
        null
      )
      .pipe(catchError(this.handleError));
  }

  dateCheck(from, to, check) {
    return Date.parse(check) <= Date.parse(to) && Date.parse(check) >= Date.parse(from);
  }

  gradeConvert(numericScore: number): string {
    if (numericScore === null || numericScore === undefined) {
      return 'N/A';
    }
    const score = Math.ceil(numericScore);
    if (score >= 90) {
      return 'A+';
    } else if (score >= 85 && score <= 89) {
      return 'A';
    } else if (score >= 80 && score <= 84) {
      return 'A-';
    } else if (score >= 76 && score <= 79) {
      return 'B+';
    } else if (score >= 72 && score <= 75) {
      return 'B';
    } else if (score >= 68 && score <= 71) {
      return 'B-';
    } else if (score >= 64 && score <= 67) {
      return 'C+';
    } else if (score >= 60 && score <= 63) {
      return 'C';
    } else if (score >= 55 && score <= 59) {
      return 'C-';
    } else if (score >= 50 && score <= 54) {
      return 'D';
    } else if (score > 0 && score <= 49) {
      return 'F';
    } else {
      return 'N/A';
    }
  }
  getCaseLibrary(caseType = 'LEGACY', nursing = false): Observable<any> {
    if (caseType === 'SIMULATION') {
      return this.http.get(this.v2SimulationsUrl.concat('cases')).pipe(catchError(this.handleError));
    }
    return this.http
      .get(this.url.concat(`caseLibrary?caseType=${caseType}&nursing=${nursing}`))
      .pipe(catchError(this.handleError));
  }

  getLearningObjectives(case_id: string): Observable<any> {
    return this.http.get(this.url.concat('case/' + case_id)).pipe(catchError(this.handleError));
  }
  getClassList(): Observable<Course[]> {
    return this.http.get<Course[]>(this.url.concat('instructors/current/courses')).pipe(catchError(this.handleError));
  }
  getCurrentInstructor(): Observable<any> {
    return this.http.get(this.url.concat('instructors/current')).pipe(catchError(this.handleError));
  }

  getCourseScore(course_id: string, student_id: string): Observable<coursegrade> {
    return this.http
      .get<coursegrade>(
        this.url.concat('instructors/current/courses/' + course_id + '/students/' + student_id + '/grades')
      )
      .pipe(catchError(this.handleError));
  }
  getInvitedList(course_id: string): Observable<any> {
    return this.http
      .get(this.url.concat('instructors/current/courses/' + course_id + '/pending'))
      .pipe(catchError(this.handleError));
  }

  getCourseScoreStudent(c: Course): Observable<coursegrade> {
    return this.http
      .get<coursegrade>(this.url.concat('students/current/courses/' + c._id + '/grades'))
      .pipe(catchError(this.handleError));
  }

  getAssignmentFeedback(sessionId, email): Observable<feedback> {
    return this.http
      .get<feedback>(
        this.url.concat('feedback?session_id=' + sessionId + '&student_email=' + encodeURIComponent(email))
      )
      .pipe(catchError(this.handleError));
  }

  getCurrentStudentSummativeAssessment(course_id: string, student_id: string): Observable<SummativeFeedback[]> {
    return this.http
      .get<SummativeFeedback>(
        this.url.concat('instructors/current/courses/' + course_id + '/students/' + student_id + '/summative')
      )
      .pipe(catchError(this.handleError));
  }

  addCourse(c: course): Observable<course> {
    return this.http.post<course>(this.url.concat('instructors/current/courses'), c);
  }

  editCourse(c: course): Observable<course> {
    const modifiedCourse = {
      name: c.name,
      start: c.start,
      end: c.end,
      title: c.title,
      description: c.description,
      recommendationLevel: c.recommendationLevel,
      requiredPassingPercent: c.requiredPassingPercent,
    };

    return this.http
      .patch<course>(this.url.concat('instructors/current/courses/' + c._id), modifiedCourse)
      .pipe(catchError(this.handleError));
  }

  addStudentToClass(course: course, studentsArray: string[]) {
    const obj = {
      students: studentsArray
        .map((s) => s.trim())
        .filter(function (item, pos, self) {
          return self.indexOf(item) === pos && item.length !== 0;
        }),
    };
    const body = obj;
    return this.http
      .post(this.url.concat('instructors/current/courses/' + course._id + '/students'), body)
      .pipe(catchError(this.handleError));
  }
  removeStudentFromClass(course: course, student_id: string) {
    return this.http
      .delete(this.url.concat('instructors/current/courses/' + course._id + '/students/' + student_id))
      .pipe(catchError(this.handleError));
  }

  removeFromInvitedList(course_id: string, email: string) {
    const encodedEmail = encodeURIComponent(email);
    return this.http
      .delete(this.url.concat('instructors/current/courses/' + course_id + '/pending?email=' + encodedEmail))
      .pipe(catchError(this.handleError));
  }

  addCaseToClass(course: course, patient_case_id: string) {
    return this.http
      .put(this.url.concat('instructors/current/courses/' + course._id + '/cases/' + patient_case_id), '')
      .pipe(catchError(this.handleError));
  }

  removeCaseFromClass(course: course, patient_case_id: string) {
    return this.http
      .delete(this.url.concat('instructors/current/courses/' + course._id + '/cases/' + patient_case_id))
      .pipe(catchError(this.handleError));
  }

  addSummativeAssessment(assessment: SummativeFeedback) {
    delete assessment['_id'];
    return this.http.post<SummativeFeedback>(
      this.url.concat(
        'instructors/current/courses/' + assessment.course_id + '/students/' + assessment.student_id + '/summative'
      ),
      assessment
    );
  }

  getGradesOverviewUser(student_id: string, course_id: string): Observable<any> {
    const url = this.url.concat('courses/' + course_id + '/students/' + student_id + '/gradesoverview');
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  getGradesIndividualCase(case_id: string): Observable<any> {
    const url = this.url.concat('gradesindividual?case_id=' + encodeURIComponent(case_id));
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  addFormativeAssessment(
    course_id: string,
    student_id: string,
    assessment: string,
    case_id: string
  ): Observable<FormativeFeedback> {
    const body = { text: assessment };
    return this.http
      .post<FormativeFeedback>(
        this.url.concat(
          'instructors/current/courses/' + course_id + '/students/' + student_id + '/cases/' + case_id + '/formative'
        ),
        body
      )
      .pipe(catchError(this.handleError));
  }

  getFormativeInsturctorByCase(
    course_id: string,
    student_id: string,
    case_id: string
  ): Observable<FormativeFeedback[]> {
    return this.http
      .get<FormativeFeedback[]>(
        this.url.concat(
          'instructors/current/courses/' + course_id + '/students/' + student_id + '/cases/' + case_id + '/formative'
        )
      )
      .pipe(catchError(this.handleError));
  }

  getFormativeInstructorFullCourse(c: course, s: student): Observable<FormativeFeedback[]> {
    if (c != null && s != null) {
      return this.http
        .get<FormativeFeedback[]>(
          this.url.concat('instructors/current/courses/' + c._id + '/students/' + s._id + '/formative')
        )
        .pipe(catchError(this.handleError));
    } else {
      return Observable.empty();
    }
  }

  setSignature(base64Img: string, text: string): Observable<Signature> {
    return this.http.post<Signature>(`${environment.apiUrl}/api/v1/instructors/signature`, {
      img: base64Img,
      text,
    });
  }

  getSignature(memberId: string): Observable<Signature> {
    return this.http.get<Signature>(`${environment.apiUrl}/api/v1/instructors/signature?memberId=${memberId}`);
  }

  private handleError() {
    return of(null);
  }
}
