import { Component, Input } from '@angular/core';
import { Graph, BarData } from 'app/lms/interfaces/statistics';
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent {
  @Input() lineChartData: Graph[] = [];
  @Input() lineChartLabels: Array<string>;
  public labels: string[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      onClick: function(event, legendItem) {}
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontFamily: 'Open Sans',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSize: 10,
            fontFamily: 'Open Sans',
          },
        },
      ],
    },
  };
  public lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: 'rgba(139,195,74,0.3)',
      borderColor: '#8bc34a',
      pointBackgroundColor: '#8bc34a',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#8bc34a',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  public barChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSize: 10,
            fontFamily: 'Open Sans',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily: 'Open Sans',
          },
        },
      ],
    },
  };
  @Input() barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = true;

  @Input() barChartData: BarData[] = [];

  public barChartColors: Array<any> = [
    {
      // grey
      backgroundColor: '#8781A4',
      borderColor: '#4ab1c3',
    },
    {
      // green
      backgroundColor: '#8bc34a',
      borderColor: '#8bc34a',
    },
  ];
}
