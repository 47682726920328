<div>
    <div class="dash-container">
        <table>
            <tr>
                <td>
                    <h2 class="class-name">
                        <div *ngIf="course!=null">
                            {{course.title}}
                        </div>
                        <div>
                            <button mat-raised-button class="back-button" (click)="backClicked()">Back </button>
                        </div>
                    </h2>
                    <div *ngIf="course!=null" class="class-description">
                        {{course.description}}
                    </div>
                </td>
                <td>
                    <div class="skill-container" *ngIf="course">
                        <div>
                            <h2>Skill Level: {{displaySkillLevel()}}</h2>
                            <div class="sc1">
                                <mat-icon>calendar_today</mat-icon>
                                <label style="margin-left: 11px;">{{course.start | date:'mediumDate' }} -
                                    {{course.end | date:'mediumDate'}}</label>
                            </div>

                        </div>
                        <div>
                            <button (click)="editClass()" [matTooltip]="course.status!='active'?'Inactive':'Edit'"
                                [matTooltipPosition]="'above'" [disabled]="course.status!='active'" mat-icon-button>
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <div>
                        <div class="list-heading">
                            <h3 class="heading" *ngIf="course!=null">Student List
                                <mat-slide-toggle [disabled]="course.status!='active'" [(ngModel)]="isEnableStudent">
                                    Enable edit
                                </mat-slide-toggle>

                                <button mat-icon-button [matMenuTriggerFor]="menuStudent" class="menu-button"
                                    matTooltipPosition="above" matTooltip=" Click here to remove the student">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuStudent="matMenu">
                                    <button [disabled]="course.status!='active'" mat-menu-item
                                        (click)="enableRemoveButtonStudent()">
                                        <span>Remove</span>
                                    </button>
                                </mat-menu>
                            </h3>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div class="list-conatiner">
                            <div class="student-list">
                                <ng-container *ngIf="!studentDataSource || studentDataSource.data.length === 0; else list2">
                                    <div class="descr-cases">
                                        There no students here! Click "Add Students" below to add students to your new
                                        class
                                    </div>
                                </ng-container>
                                <ng-template #list2>
                                    <div class="scroll-list">
                                        <table class="t1" mat-table matSort [dataSource]="studentDataSource"
                                            #TableOneSort="matSort">
                                            <ng-container matColumnDef="action1">
                                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <button mat-icon-button [disabled]="!isEnableStudent"
                                                        (click)="openRemoveStudentDialog(element.email)"
                                                        matTooltipPosition="left" matTooltipClass="ttp"
                                                        matTooltip="Remove this student">
                                                        <mat-icon>clear</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="name1">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                                <td mat-cell *matCellDef="let element">
                                                <app-string-truncate [inputString]="element.name" shortenLength="13"    >
                                                    </app-string-truncate>
                                                   </td>
                                            </ng-container>
                                            <ng-container matColumnDef="email1">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="status1">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="studenttablecolumns; sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: studenttablecolumns;"></tr>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="flex-container">
                                <div class="add-button-container" *ngIf="course!=null">
                                    <button mat-raised-button class="student-add-button"
                                        [disabled]="course.status!='active'" (click)="openDialog()">Add
                                        Students</button>
                                </div>

                            </div>
                        </div>

                        <ng-template #myAddModal let-data>
                            <mat-dialog-content class="accent-color">

                                <h3 class="add-student-heading">Add Students to {{course.title}} </h3>
                                <h4>Enter or copy and paste email(s) ,sperated by comma , and then hit Import to validate .Once import is finish Press 'Send Invite' to complete sending invite(s) </h4>
                                <div class="add-students-block">
                                    <div class="domain">
                                        Valid domain : {{data.email}}
                                    </div>
                                    <textarea class="t2" (change)="l3=[]" matInput [(ngModel)]="bulklist"></textarea>
                                    <div class="t78">
                                        <button class="add-invite" (click)="processListMailid()">Import </button>
                                    </div>

                                    <div class="l32">

                                        <mat-chip-list #chipList class="l32">
                                            <mat-chip class="chips" *ngFor="let l of l3" [matTooltip]="chipTooltip(l)"
                                            [matTooltipPosition]="'above'"
                                                [ngClass]="(l.v==1 && l.count==1)?'chipOk':'chipWarn'"
                                                [removable]="removable" (removed)="remove1(l)">{{l.k}}
                                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>
                            </mat-dialog-content>
                            <mat-dialog-actions align="end">
                                <div class="t89">
                                    <mat-chip-list class="mat-chip-list-stacked">
                                        <mat-chip class="chipOk"> Valid invites:{{checkInviteList()}}</mat-chip>
                                        <mat-chip class="chipWarn">Invalid:{{l3.length-checkInviteList()}}</mat-chip>
                                    </mat-chip-list>
                                </div>

                                <button mat-raised-button class="action mat-button mat-raised-button mat-primary back"
                                    mat-dialog-close>
                                    Back
                                </button>
                                <button mat-raised-button (click)="sendInvites()" [disabled]="checkInviteList()==0"
                                    class="action mat-button mat-raised-button mat-primary add" color="primary"
                                    [matTooltip]="checkInviteList()==0?'':'Click for send invites'"
                                    [matTooltipPosition]="'above'" mat-dialog-close>
                                    Send Invites
                                </button>

                            </mat-dialog-actions>
                        </ng-template>
                    </div>
                    <div *ngIf="!studentDataSource || studentDataSource.data.length === 0" fxLayout="row" fxLayoutGap="8px" class="info-text">
                        <span><i class="fa fa-exclamation-circle"></i></span>
                        <span>To complete the setup of your course,
                        let's invite your students and start assigning them new cases!
                        </span>
                    </div>
                </td>
                <td valign="top">
                    <div>
                        <div class="list-heading">
                            <h3 class="heading">Assignments
                                <button mat-icon-button [matMenuTriggerFor]="menuCase" class="menu-button"
                                    matTooltipPosition="above" matTooltip=" Click here to remove the case">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuCase="matMenu">
                                    <button mat-menu-item *ngIf="course" [disabled]="course.status !== 'active'"
                                        (click)="enableRemoveButtonCase()">
                                        <span>Remove</span>
                                    </button>
                                </mat-menu>
                            </h3>
                        </div>
                        <div class="list-conatiner-cases">
                            <div class="case-list" *ngIf="course && course.cases">
                                <ng-container *ngIf="course.cases.length === 0; else list1">
                                    <div class="descr-cases">
                                        You don't have anything Assigned! Click "Add Cases" below to add Patient Cases
                                        to your course work
                                    </div>
                                </ng-container>
                                <ng-template #list1>
                                    <div class="scroll-list">
                                        <table class="t1" mat-table matSort [dataSource]="caseDataSource"
                                            #TableTwoSort="matSort">
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="t5">
                                                        <button mat-icon-button [disabled]="!isEnableCase"
                                                            (click)="openRemoveCaseDialog(element)"
                                                            matTooltipPosition="above" matTooltipClass="ttp"
                                                            matTooltip="Remove this case">
                                                            <mat-icon>clear</mat-icon>
                                                        </button>
                                                        <div [class]="element.tags.category">
                                                            <span class="icon"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.demographics.patient_name}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="category">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                                                <td mat-cell *matCellDef="let element"> {{element.tags.category}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="casetablecolumns; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row; columns: casetablecolumns;"></tr>
                                        </table>
                                    </div>


                                </ng-template>
                            </div>
                            <div class="flex-container">
                                <div class="add-button-container" *ngIf="course!=null">
                                    <button mat-raised-button class="case-add-button"
                                        [disabled]="course.status!='active'" (click)="addCases()">Add
                                        Cases</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="studentDataSource && studentDataSource.data.length && (!caseDataSource || caseDataSource.data.length === 0)"
                         fxLayout="row"
                         fxLayoutGap="8px"
                         class="info-text">
                        <span><i class="fa fa-exclamation-circle"></i></span>
                        <span>To complete the setup of your course,
                        don't forget to start assigning new cases!
                        </span>
                    </div>
                </td>
            </tr>

        </table>
    </div>
</div>
