import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimpleGlobal } from 'ng2-simple-global';
@Component({
  selector: 'app-voice-command-error-dialog',
  templateUrl: './voice-command-error-dialog.component.html',
  styleUrls: ['./voice-command-error-dialog.component.scss'],
})
export class VoiceCommandErrorDialogComponent implements OnInit {
  title = 'Warning Message';
  mainText = 'Please try it again or search the keywords.';
  subText = 'Please try it again.';
  constructor(
    public dialogRef: MatDialogRef<any>,
    public sg: SimpleGlobal,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    if (this.data) {
      this.title = 'Warning Message';
      this.mainText = this.data.mainText;
      this.subText = this.data.subText;
    } else {
      this.title = 'Warning Message';
      this.mainText = 'Please try it again or search the keywords.';
      this.subText = 'Please try it again.';
    }
  }
  close() {
    this.dialogRef.close(false);
  }
}
