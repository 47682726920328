import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';
import 'rxjs/add/operator/map';

import { catchError, map } from 'rxjs/operators';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class PaymentManagementService {
  id: string;
  private urlStartTransaction = '/api/v1/payments/intent';
  private urlGetPlans = '/api/v1/payments/plans';
  private urlSelectPlan = '/api/v1/payments/subscription';
  private urlUpdateCCard = '/api/v1/payments/card';
  private urlCustDetails = '/api/v1/payments/card';
  private urlCancelSubscription = '/api/v1/payments/subscription/cancel';
  private urlActivePlan = '/api/v1/payments/plans?status=active'
  private urlUpdateBilling = '/api/v1/payments/card';
  private urlSubsRestore = '/api/v1/payments/subscription/restore';

  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.urlStartTransaction = this.sg['url'].concat(this.urlStartTransaction);
    this.urlGetPlans = this.sg['url'].concat(this.urlGetPlans);
    this.urlSelectPlan = this.sg['url'].concat(this.urlSelectPlan);
    this.urlUpdateCCard = this.sg['url'].concat(this.urlUpdateCCard);
    this.urlCustDetails = this.sg['url'].concat(this.urlCustDetails);
    this.urlCancelSubscription = this.sg['url'].concat(this.urlCancelSubscription);
    this.urlUpdateBilling = this.sg['url'].concat(this.urlUpdateBilling);
    this.urlActivePlan = this.sg['url'].concat(this.urlActivePlan);
  }

  getPlans(): Observable<any> {
    return this.http.get(this.urlGetPlans).pipe(catchError(this.handleError));
  }

  getActivePlan(): Observable<any> {
    return this.http.get(this.urlActivePlan).pipe(catchError(this.handleError));
  }

  getCust(): Observable<any> {
    return this.http.get(this.urlCustDetails).pipe(catchError(this.handleError));
  }

  getSubs(): Observable<any> {
    return this.http.get(this.urlSelectPlan).pipe(catchError(this.handleError));
  }


  restoreSubs(): Observable<any> {
    return this.http.post(this.urlSubsRestore, {}).pipe(
      catchError(this.handleError)
    );
  }


  selectPlan(plan): Observable<any> {
    return this.http.put(this.urlSelectPlan, plan).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError));
  }

  updateCCard(newCard): Observable<any> {
    return this.http.put(this.urlUpdateCCard, newCard).pipe(
      catchError(this.handleError)
    );
  }

  updateBilling(body): Observable<any> {
    return this.http.patch(this.urlUpdateBilling, body).pipe(
      catchError(this.handleError)
    );
  }

  cancelSubs(): Observable<any> {
    return this.http.post(this.urlCancelSubscription, {}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
