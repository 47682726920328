<div class="row grades">
  <div class="medium-5 columns">
    <mat-card class="mainStat spotlight" *ngIf="caseData && caseData.length > 0">
      <h2>
        Performance Snapshot</h2>
      <div class="medium-4 columns">
        <h4>Cases</h4>
        <span>{{caseData.length}}</span>

      </div>
      <div class="medium-4 columns">
        <h4>Average</h4>
        <span>{{average  | number:'1.0-0'}}%</span>

      </div>
      <div class="medium-4 columns notranslate">
        <h4>Grade</h4>
        <span>{{letterGrade}}</span>

      </div>
    </mat-card>

    <mat-card class="spotlight">
      <div class="statContainer">
        <div class="text">
          <h2>
            Last Assignment Performance
          </h2>
          <h4>Your score versus the ideal user score.</h4>
        </div>
        <div style="display: block" *ngIf="barChartLabels.length > 0">
          <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [colors]="barChartColors" [legend]="true" [chartType]="barChartType"></canvas>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="medium-7 columns">
    <mat-card class="spotlight cases">
      <h2>
        Case History
      </h2>
      <mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>>
          <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="last_completed">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Completed</mat-header-cell>>
          <mat-cell *matCellDef="let row"> {{row.last_completed | date}} </mat-cell>>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="bestScore">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Best Score</mat-header-cell>>
          <mat-cell *matCellDef="let row">
            <div *ngIf="row.bestScore != null">{{row.bestScore | number: '1.0-0'}}%</div>
            <div *ngIf="row.bestScore == null">N/A</div>
          </mat-cell>>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getGradesDetails(row)">
        </mat-row>
      </mat-table>

      <mat-paginator [pageSize]="4"></mat-paginator>
    </mat-card>
  </div>
  <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</div>
