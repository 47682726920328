import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDialogComponent } from '@canhealth/cyberpatient-ui';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EmailCheckResponse, RegisterAccService } from '../_services/register-acc.service';
import { AgreementPromptComponent } from '../agreement-prompt/agreement-prompt.component';
import { CustomValidators } from '../custom-validators';

@Component({
  selector: 'app-register-campaign-account',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegisterAccService],
  templateUrl: './register-campaign-account.component.html',
  styleUrls: ['./register-campaign-account.component.scss'],
})
export class RegisterCampaignAccountComponent implements OnInit {
  loading = false;
  error = '';
  registrationForm: FormGroup;
  registrationFormExtended: FormGroup;
  institutions: any = [];
  institutionsList: any = [];
  countryChosen = false;
  occupationsList: any = ['Student', 'Educator', 'Email Campaign', 'Other'];
  filteredInstitutions: Observable<string[]>;
  countryList: any = [];
  inviteToken?: string;

  private existingEmails: string[] = [];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private registerAccService: RegisterAccService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    import('../../assets/data/universities.json').then((institutions) => {
      this.institutionsList = institutions.default;
    });
    import('../../assets/data/countryregions.json').then((countries) => {
      this.countryList = countries.default;
    });

    const params = this.route.snapshot.queryParams;
    this.inviteToken = params['invite_token'] || null;
    this.initForm();
    this.setDefaultValueFromParams();
    this.filteredInstitutions = this.registrationFormExtended.get('institution').valueChanges.pipe(
      startWith(''),
      map((value) => this._filterInstitutions(value))
    );
  }

  initForm() {
    this.registrationForm = this.formBuilder.group({
      accountType: ['individual', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, CustomValidators.notIn(this.existingEmails).bind(this)]],
      agree: [false, Validators.requiredTrue],
    });
    this.registrationFormExtended = this.formBuilder.group({
      occupation: ['Email Campaign', Validators.required],
      institution: ['Email Campaign', Validators.required],
      country: ['Canada', Validators.required],
    });
  }

  submitForm() {
    this.loading = true;

    if (this.registrationForm.valid && this.registrationFormExtended.valid) {
      const body = {
        accountType: this.registrationForm.value.accountType,
        firstName: this.registrationForm.value.firstName,
        lastName: this.registrationForm.value.lastName,
        email: this.inviteToken ? null : this.registrationForm.value.email,
        occupation: this.inviteToken ? null : this.registrationFormExtended.value.occupation,
        country: this.inviteToken ? null : this.registrationFormExtended.value.country,
        institution: this.inviteToken ? null : this.registrationFormExtended.value.institution,
        permissions: 'learner',
        inviteToken: this.inviteToken,
        edugroup: 'Online_Registered_User',
      };

      this.registerAccService.register(body).subscribe(async (result) => {
        this.loading = false;

        if (result === true) {
          await this.router.navigate(['/confirmSent']);
        }
      });
    }
  }

  openPolicy() {
    this.dialog.open(AgreementPromptComponent);
  }

  goNext(stepper: MatStepper) {
    if (this.registrationForm.get('email').value) {
      this._checkEmail().subscribe((valid) => {
        if (valid) {
          stepper.next();
        }
      });
    } else {
      if (this.registrationForm.valid) {
        stepper.next();
      }
    }
  }

  private setDefaultValueFromParams() {
    if (!this.inviteToken) {
      return;
    }

    const params = this.route.snapshot.queryParams;
    const inviteEmail = params['email'] && decodeURIComponent(params['email']);
    const inviteCountry = params['country'] && decodeURIComponent(params['country']);
    const inviteInstitution = params['institution'] && decodeURIComponent(params['institution']);

    this.registrationFormExtended.patchValue({
      occupation: 'Student',
    });
    this.registrationFormExtended.get('occupation').disable();

    if (inviteEmail) {
      this.registrationForm.patchValue({
        email: inviteEmail,
      });
      this.registrationForm.get('email').disable();
    }
    if (inviteCountry) {
      this.registrationFormExtended.patchValue({
        country: inviteCountry,
      });
      this.registrationFormExtended.get('country').disable();
    }
    if (inviteInstitution) {
      this.registrationFormExtended.patchValue({
        institution: inviteInstitution,
      });
      this.registrationFormExtended.get('institution').disable();
    }
  }

  private _filterInstitutions(value: string): string[] {
    return this.institutions
      .filter((institution) => institution.name.toLowerCase().includes((value || '').toLowerCase()))
      .map((institution) => institution.name);
  }

  private _checkEmail(): Observable<any> {
    return this.registerAccService.checkEmail(this.registrationForm.value['email']).pipe(
      map((response: EmailCheckResponse) => {
        if (response.emailExists) {
          this.existingEmails = [...this.existingEmails, response.email];
          this.registrationForm
            .get('email')
            .setValidators([Validators.required, Validators.email, CustomValidators.notIn(this.existingEmails)]);
        } else if (
          this.registrationForm.get('accountType').value === 'institution' &&
          response.institutionExists &&
          response.institutionDetails
        ) {
          this.dialog.open(GeneralDialogComponent, {
            data: {
              containerClasses: ['dialog-md'],
              header: 'An institution with your email domain has already registered',
              body: `<p>It looks like this email domain has already been registered under ${response.institutionDetails.name}.<br/>
               If you are from the same institution, please check with the admin at <b>${response.institutionDetails.contact}</b></p>`,
              buttons: [
                {
                  text: 'OK',
                  color: 'success',
                },
              ],
            },
          });
          return false;
        }
        this.registrationForm.get('email').updateValueAndValidity();
        return this.registrationForm.valid;
      })
    );
  }
}
