import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-register-account-address",
  templateUrl: "./register-account-address.component.html",
  styleUrls: ["./register-account-address.component.scss"]
})
export class RegisterAccountAddressComponent implements OnInit {
  model: any = {};
  loading = false;
  error = "";

  registrationaddressform;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}
}
