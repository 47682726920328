import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';

@Injectable()
export class InstanceNameService {
  constructor(private sg: SimpleGlobal) {}

  getInstance(): string {
    const instance = this.sg['instance_id'] as string;

    let text: string;

    switch (instance) {
      case 'initialAssessment':
        text = 'Initial Assessment';
        break;
      case 'decisions':
        text = 'Decisions';
        break;
      case 'ancillaryCare':
      case 'ancillary':
        text = 'Ancillary Care';
        break;
      case 'preOp':
        text = 'Pre-Operation';
        break;
      case 'POD1':
        text = 'Hospital Day 1';
        break;
      case 'POD2':
        text = 'Hospital Day 2';
        break;
      case 'POD3':
        text = 'Hospital Day 3';
        break;
      case 'POD4':
        text = 'Hospital Day 4';
        break;
      case 'POD5':
        text = 'Hospital Day 5';
        break;
      case 'POD6':
        text = 'Hospital Day 6';
        break;
      case 'POD7':
        text = 'Hospital Day 7';
        break;
      case 'POD8':
        text = 'Hospital Day 8';
        break;
      case 'POD9':
        text = 'Hospital Day 9';
        break;
      case 'POD10':
        text = 'Hospital Day 10';
        break;
      case 'sendHome1':
      case 'sendHome2':
      case 'sendHome3':
        text = 'Send Home';
        break;
      case 'observation1':
        text = 'Observation #1';
        break;
      case 'observation2':
        text = 'Observation #2';
        break;
      case 'observation3':
        text = 'Observation #3';
        break;
      case 'office1':
        text = 'Office Visit #1';
        break;
      case 'office2':
        text = 'Office Visit #2';
        break;
      case 'office3':
        text = 'Office Visit #3';
        break;
      default:
        text = 'CyberPatient';
    }

    return text;
  }
}
