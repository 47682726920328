import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { User } from '../_models';
import { AuthenticationService, UserService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly usersService: UserService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.getToken()) {
      this.router.navigate(['/login'], { replaceUrl: true });
      return of(false);
    }

    return this.usersService
      .userChange
      .pipe(
        filter(Boolean),
        first(),
        map((user: User) => user.admin),
        tap((canActivate) => {
          if (!canActivate) {
            this.router.navigate(['notauthorized'], { replaceUrl: true });
          }
        }),
      );
  }
}
