import { Injectable } from '@angular/core';
import {SimpleGlobal} from 'ng2-simple-global';

@Injectable({
    providedIn: 'root',
  })
export class ClockService {
  constructor() { }

  clockTween = null;
  clockContainer = new createjs.Container();
  clockTick;
  interval = null;
  digitalClockText = null;
  startText = null;
  isClockRunning = false;
  hh;
  mm;
  ss;
  startBtn;

       getClockContainer() {
           return this.clockContainer;
       };

       init() {
           var that = this;
           var clockDial = new createjs.Bitmap("./assets/default/physical/Props/Clock/clock.png");
           this.clockContainer.addChild(clockDial);
           var clockHand = new createjs.Bitmap("./assets/default/physical/Props/Clock/clockHand.png");
           clockHand.regX = 3;
           clockHand.regY = 77;
           clockHand.x = 105;
           clockHand.y = 104;
           clockHand.rotation = 0;
           that.clockTick = 0;
           this.clockContainer.addChild(clockHand);
           this.clockContainer.visible = false;

           var digitalClock = new createjs.Container();
           digitalClock.y = 215;
           var digitalClockBackground = new createjs.Shape();
           digitalClockBackground.graphics.beginFill('#0f77b4')
               .drawRoundRect(0, 0, 220, 110, 10)
               .endFill()
               .beginFill('#138fd8')
               .drawRoundRect(10, 10, 200, 90, 10);
           this.digitalClockText = new createjs.Text("00:00:00", "40px Arial", "#000");
           this.digitalClockText.x = 35;
           this.digitalClockText.y = 10;
           digitalClock.addChild(digitalClockBackground);
           digitalClock.addChild(this.digitalClockText);

           this.startBtn = new createjs.Shape();
           this.startBtn.graphics.beginFill('#333')
               .drawRoundRect(0, 0, 90, 40, 10)
               .endFill();
           this.startBtn.x = 15;
           this.startBtn.y = 55;
           digitalClock.addChild(this.startBtn);

           this.startText = new createjs.Text("START", "25px Arial", '#fff');
           this.startText.x = 18;
           this.startText.y = 60;
           digitalClock.addChild(this.startText);

           var resetBtn = new createjs.Shape();
           resetBtn.graphics.beginFill('#333')
               .drawRoundRect(0, 0, 90, 40, 10)
               .endFill();
           resetBtn.x = 110;
           resetBtn.y = 55;
           digitalClock.addChild(resetBtn);

           var resetText = new createjs.Text("RESET", "25px Arial", '#fff');
           resetText.x = 112;
           resetText.y = 60;
           digitalClock.addChild(resetText);

           this.clockContainer.addChild(digitalClock);
           this.startBtn.on('click', function (e) {

               if (that.isClockRunning) {
                  that.Stop();
                   return;
               }
               that.Start();
           });

           resetBtn.on('click', function (e) {
               that.Reset();
           });

           this.clockTween = createjs.Tween.get(clockHand, { loop: true, paused: true })
               .to({ rotation: 359 }, 60000);
       };

       Hide() {
           this.Stop();
           this.Reset();
           this.clockContainer.visible = false;
           clearInterval(this.interval);
       };

       Show(x, y) {
           this.clockContainer.x = x;
           this.clockContainer.y = y;
           this.clockContainer.visible = true;
       };

       Reset() {
           this.clockTick = 0;
           this.clockTween.setPosition(0);
           this.digitalClockText.text = '00:00:00';
       };

       Stop() {
           this.isClockRunning = false;
           this.startText.text = 'START';
           this.startText.x = 18;
           clearInterval(this.interval);
           this.clockTween.setPaused(true);
       };

    Start() {
        var that = this;
           this.isClockRunning = true;
           this.startText.text = 'STOP';
           this.startText.x = 25;
           this.clockTween.setPaused(false);
           this.interval = setInterval(function () {
               that.clockTick++;
               this.hh = Math.floor(that.clockTick / 3600);
               this.hh = this.hh < 9 ? '0' + this.hh : this.hh;
               this.mm = Math.floor((that.clockTick % 3600) / 60);
               this.mm = this.mm < 9 ? '0' + this.mm : this.mm;
               this.ss = (that.clockTick % 3600) % 60;
               this.ss = this.ss < 9 ? '0' + this.ss : this.ss;
               that.digitalClockText.text = this.hh + ':' + this.mm + ':' + this.ss;
           }, 1000);
       };
   }
