<p class="copyright">
  © Interactive Health International {{ currYear }} |
  <a class="openPolicy" href="https://www.cyberpatient.ca/privacy-policy" target="_blank" rel="noreferrer noopener">
    Privacy Policy
  </a>
  &
  <a class="openPolicy" href="https://www.cyberpatient.ca/terms-of-use" target="_blank" rel="noreferrer noopener">
    Terms of Use
  </a>
</p>
