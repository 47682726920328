import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SetCurrentCase, UpdateCheckpointMessage } from 'app/actions/checkpoint.action';
import { SetEpaSessionId } from 'app/actions/epa.action';
import { CheckPointState } from 'app/state/checkpoints.state';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable } from 'rxjs';
import { AttemptData, CaseAttempts, CheckPointMessage, finalScores, iCaseAttempts, Score } from '../_models/index';
import { FetchAssignmentDataService } from '../_services';
import { EpaConfigDataService } from '../epa-config-data.service';

@Component({
  selector: 'app-student-case-overview',
  providers: [FetchAssignmentDataService],
  templateUrl: './student-case-overview.component.html',
  styleUrls: ['./student-case-overview.component.scss']
})
export class StudentCaseOverviewComponent implements OnInit {
  caseData;

  loadingAndSaving = false;
  displayedColumns: string[] = ['id', 'completed', 'finalScores.knowledgeScore', 'finalScores.cost', 'finalScores.time', 'Action'];
  assData: any = {
    caseData: {
      name: null,
      average: null
    },
    attempts: [],
  };
  feedbackData: any;
  dataSource: MatTableDataSource<CaseAttempts>;
  public barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  isLoading = true;
  barChartLabels: string[] = ['Loading'];
  barChartType = 'bar';
  barChartLegend = true;
  newChartData;
  barChartData: any[] = [];
  sort;
  paginator;
  barChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(139, 195, 74, 0.8)',
      borderColor: '#8bc34a',
    },
    { // grey
      backgroundColor: 'rgba(74, 177, 195, 0.8)',
      borderColor: '#4ab1c3',
    }
  ];
  mydata: AttemptData
  mycase: CheckPointMessage;
  score: Score = new Score();
  @Select(CheckPointState.getStoreCheckPoints) storeCheckPoints: Observable<CheckPointMessage>;
  case_id: string;
  performaceData: iCaseAttempts = {} as iCaseAttempts;

  constructor(private epacfgdata: EpaConfigDataService, private store: Store, private FetchAssignmentDataService: FetchAssignmentDataService, private sg: SimpleGlobal, private router: Router) {

  }

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  ngOnInit() {
    this.getGradesDetails();
  }

  back() {
    const path = '/gradesOverview'
    // let path = '/assignment/Input';
    this.router.navigate([path], {
      skipLocationChange: false
    });

  }

  getGradesDetails() {
    //  this.FetchAssignmentDataService.getGradesDetailed(assId).subscribe(assData => {
    if (this.sg['grades_ass'] === undefined) {
    } else {
      let myarr;

      this.loadingAndSaving = true;
      this.FetchAssignmentDataService.getGradesDetailed(this.sg['grades_ass']).subscribe(x => {
        //     this.FetchAssignmentDataService.getGradesDetailed("5ad52b6db58d7474937e591c").subscribe(assData => {
        this.loadingAndSaving = false;
        this.assData = x;
        this.score = x;
        this.mydata = x;
        myarr = this.mydata.attempts.filter(x => x.completed);
        myarr.forEach((x, i) => {
          x.id = i + 1;
        });
        this.mydata.attempts = myarr;


        if (this.assData.caseData.average == null) {
          this.assData.caseData.average = 80;
        }
        const classAvg = [80];
        this.newChartData = [
          {data: [this.assData.caseData.average], label: 'You'},
          {data: classAvg, label: 'Ideal'}
        ];
        this.barChartLabels = [];
        this.barChartLabels.push(this.assData.caseData.name);
        this.barChartData = this.newChartData;

        this.assData.attempts = myarr;
        this.dataSource = new MatTableDataSource(myarr);
        this.assData.attempts = this.getCaseAttempts(x.attempts);
        this.dataSource = new MatTableDataSource(this.assData.attempts);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.storeCheckPoints.subscribe(x => this.mycase = x);
        this.sg['case_id'] = this.sg['grades_ass']

        this.FetchAssignmentDataService.getCheckPoints().subscribe(
          c => {
            this.store.dispatch([new UpdateCheckpointMessage(c)]);
          }
        );

        this.loadingAndSaving = false;
      }, err => {
        this.loadingAndSaving = false;

      });

    }
  }

  setDataSource() {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openFeedback(sessionId) {

    this.sg['getFeedback'] = sessionId;
    this.store.dispatch([new SetCurrentCase(sessionId)])

    if (this.mycase.message.epa) {
      this.store.dispatch([new SetEpaSessionId(sessionId)])
      const attempt: iCaseAttempts = this.mydata.attempts.find(x => x.session_id === sessionId);
      this.epacfgdata.getEpaPeformanceData(attempt.finalScores);
      this.router.navigate(['/assignment/epafeedback'], {skipLocationChange: true});
    } else {
      this.router.navigate(['/gradesOverview/feedback'], {
        queryParams: {
          session_id: sessionId,
        }
      });
    }
  }

  private getCaseAttempts(attemptsData: any = []) {
    return attemptsData.map((attemptData, index) => {
      const attempt = new CaseAttempts();
      attempt.id = index + 1;
      attempt.session_id = attemptData.session_id;
      attempt.completed = attemptData.completed;

      const fs = new finalScores();

      if (attemptData.finalScores) {
        fs.cost = attemptData.finalScores.cost;
        fs.knowledgeScore = attemptData.finalScores.knowledgeScore;
        fs.time = attemptData.finalScores.time;
        fs.costDeviation = attemptData.finalScores.costDeviation;
        fs.idealCost = attemptData.finalScores.idealCost;
      }

      attempt.finalScores = fs;

      return attempt;
    });
  }
}
