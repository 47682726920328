import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { EPARecodingSection, SavedRecords, CheckPoint, epascore, EpaAnswerPerSession } from './../_models/index'
import { GetSavedRecords, AddRecordings, SaveRecordingToBackEnd, SetEpaSessionId, UpdateEpaAnswer } from '../actions/epa.action'
import { Injectable } from '@angular/core';
import { patch, insertItem, updateItem, iif } from '@ngxs/store/operators';
import { EpaConfigDataService } from "app/epa-config-data.service";

import { FetchAssignmentDataService } from "../_services";

export class EpaStateModel {
    recordArray: EPARecodingSection[];
    savedRecords: SavedRecords[];
    epacurrentsessionid: string;
    epascores: epascore[];

    currentEpaAnswer: EpaAnswerPerSession;
}

@State<EpaStateModel>({
    name: 'epa',
    defaults: {
        recordArray: [],
        savedRecords: [],
        epacurrentsessionid: null,
        epascores: [],
        currentEpaAnswer: null
    }
})

@Injectable()
export class EpaState {

    constructor(private fetchAssignmentDataService: FetchAssignmentDataService, private epacfg: EpaConfigDataService, private store: Store) { }

    @Selector()
    static getEpaScores(state: EpaStateModel) {
        return state.epascores;
    }


    @Selector()
    static getCurrentEpaAnswers(state: EpaStateModel) {
        return state.currentEpaAnswer;
    }

    @Selector()
    static getCurrentEpaSessionId(state: EpaStateModel) {
        return state.epacurrentsessionid;
    }

    @Selector()
    static getCurrentEpas(state: EpaStateModel) {
        return state.recordArray;
    }

    @Selector()
    static getSavedEpas(state: EpaStateModel) {
        return state.savedRecords;
    }

    @Action(SetEpaSessionId)
    setEpaSessionId({ getState, setState }: StateContext<EpaStateModel>, { payload }: SetEpaSessionId) {
        const state = getState();
        setState({ ...state, epacurrentsessionid: payload });
    }
    @Action(AddRecordings)
    addRecordings({ getState, patchState }: StateContext<EpaStateModel>, { payload }: AddRecordings) {
        const state = getState();
        patchState({
            recordArray: [...state.recordArray, payload]
        });
    }

    @Action(GetSavedRecords)
    getSaved({ getState, setState }: StateContext<EpaStateModel>) {
        const state = getState();
        const data = this.epacfg.getRecordData(state.epacurrentsessionid).then(r =>
            setState({ ...state, savedRecords: r })
        )
    }

    @Action(UpdateEpaAnswer)
    updateEpaAnswer({ getState, setState }: StateContext<EpaStateModel>, { payload }: UpdateEpaAnswer) {
        const state = getState();
        setState({
            ...state, currentEpaAnswer: payload
        });
        this.fetchAssignmentDataService.postEpaAnswers(payload.case_id, payload.session_id, payload).subscribe(p => {})

    }

    insertOrUpdateAnswer(session_id: string, myanswer: EpaAnswerPerSession) {
        console.log('myan', myanswer);
        return iif<EpaAnswerPerSession[]>(
            epaanswers => epaanswers.some(answer => answer.session_id == session_id),
            updateItem(answer => answer.session_id == session_id, patch(myanswer)),
            insertItem(myanswer)

        );
    }


    @Action(SaveRecordingToBackEnd)
    save({ getState, patchState }: StateContext<EpaStateModel>) {
        const state = getState();
        // TDODO add db save logic here
    }





}