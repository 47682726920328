<div class="row grades">
  <div class="medium-4 columns">
    <mat-card style="margin-bottom: 30px;">
      <button color=primary class="detailsBtn action" style="width: 94%;" mat-raised-button (click)="back()">
         Back to Grades</button>
    </mat-card>
    <mat-card class="spotlight" *ngIf="assData.caseData.name">
      <div class="statContainer">
        <div class="text">
          <h2>
            Case Performance
          </h2>
          <h4>Your {{assData.caseData.name}} score <br /> versus the ideal score.</h4>
        </div>
        <div style="display: block; min-height: 325px;">
          <canvas *ngIf="barChartData?.length" baseChart [datasets]="barChartData" [labels]="barChartLabels"
            [options]="barChartOptions" [colors]="barChartColors" [legend]="true" [chartType]="barChartType"></canvas>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="medium-8 columns">
    <mat-card class="spotlight">
      <h2>
        Attempt History
      </h2>
      <h4>{{assData.caseData.name}}</h4>
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Progress Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Attempt # </th>
          <td mat-cell *matCellDef="let row; let i = index" matSortDirection="desc">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let row"> {{row==undefined?'':row.completed | date}} </td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="finalScores.knowledgeScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Knowledge </th>
          <td mat-cell *matCellDef="let row">

            <ng-container *ngIf="row.finalScores; else nullKnowledge">
              <span
                *ngIf="row.finalScores.knowledgeScore != null">{{row==undefined?'':row.finalScores.knowledgeScore | number:'1.2-2'}}%</span>
              <span *ngIf="row.finalScores.knowledgeScore == null">N/A</span>
            </ng-container>
            <ng-template #nullKnowledge>
              <span>N/A</span>
            </ng-template>

          </td>
        </ng-container>

        <ng-container matColumnDef="finalScores.cost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.finalScores; else nullCost">
              <span>${{row==undefined?'':row.finalScores.cost | number:'1.2-2'}}</span>
            </ng-container>
            <ng-template #nullCost>
              <span>N/A</span>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="finalScores.time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.finalScores; else nulltime">
              <span *ngIf="row.finalScores.time != 0">{{row==undefined?'':row.finalScores.time | number:'1.2-2'}}</span>
              <span *ngIf="row.finalScores.time === 0">N/A</span>
            </ng-container>
            <ng-template #nulltime>
              <span>N/A</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
          <td mat-cell *matCellDef="let row; let i = index"><button color=primary class="detailsBtn action"
              [disabled]="row.finalScores.time === 0" mat-raised-button
              (click)="openFeedback(row.session_id)">Feedback</button></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>

      <mat-paginator [pageSize]="4"></mat-paginator>
    </mat-card>
  </div>
  <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</div>
