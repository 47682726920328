import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-register-account-personal",
  templateUrl: "./register-account-personal.component.html",
  styleUrls: ["./register-account-personal.component.scss"]
})
export class RegisterAccountPersonalComponent implements OnInit {
  model: any = {};
  loading = false;
  error = "";

  registrationpersonalform;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}
}
