import { EPARecodingSection, CheckPoint, epascore, EpaAnswerPerSession } from './../_models/index'
 

export class GetSavedRecords{
    static readonly type = '[GETSAVEDRECORDS] Get'
}

export class AddRecordings{
    static readonly type = '[ADDRECORDING] Add'
    constructor(public payload: EPARecodingSection) {} 
}

export class SetEpaSessionId{
    static readonly type = '[SETEPASESSIONID] Add'
    constructor(public payload: string) {} 
}

export class SaveRecordingToBackEnd{
    static readonly type = '[SAVERECORDINGS] Save'
}
 
export class UpdateEpaAnswer{
    static readonly type = '[UPDATEEPAANSWER] Update'
    constructor(public payload: EpaAnswerPerSession) {} 
}

