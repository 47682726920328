import { Injectable } from '@angular/core';
import { PopUpContainerComponent } from './pop-up-container/pop-up-container.component';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {

  constructor(public dialog: MatDialog) { }


  SetSkillLevel() {
    let dialogRef = this.dialog.open(PopUpContainerComponent, { disableClose: true });
  }
}
