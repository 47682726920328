export const DEFAULT_ASSESSMENT_QUESTIONS = [
  {
    id: 1,
    value: false,
    score: 25,
    text: 'Student completed all their CyberPatient assignments.',
  },
  {
    id: 2,
    value: false,
    score: 25,
    text: 'Student responded to feedback and worked to improve clinical skills, clinical reasoning and critical thinking.',
  },
  {
    id: 3,
    value: false,
    score: 25,
    text: 'Student reached the required level of performance.',
  },
  {
    id: 4,
    value: false,
    score: 25,
    text: 'Student had a good overall attitude toward the online education process and virtual learning environment.',
  },
];

export const NURSING_LABTESTS = {
  CBC: {
    data: [
      {
        test: 'RBC',
        standard: '4.0 - 5.2 (F)<br>4.4 - 5.7 (M)',
        unit: '10<sup>12</sup>L',
      },
      {
        test: 'Hemoglobin',
        standard: '123 - 157 (F)<br>130 - 170 (M)',
        unit: 'g/L',
      },
      {
        test: 'Hematocrit',
        standard: '0.37 - 0.46 (F)<br>0.38 - 0.50 (M)',
        unit: 'g/L',
      },
      {
        test: 'MCV',
        standard: '80 - 100',
        unit: 'fL',
      },
      {
        test: 'MCH',
        standard: '27 - 34',
        unit: 'pg',
      },
      {
        test: 'MCHC',
        standard: '33 - 36',
        unit: 'g/dL',
      },
      {
        test: 'RDW',
        standard: '11.5 - 14.5',
        unit: '%',
      },
      {
        test: 'WBC',
        standard: '4 - 10',
        unit: '10<sup>9</sup>/L',
      },
      {
        test: 'Platelets',
        standard: '130 - 400',
        unit: '10<sup>9</sup>/L',
      },
    ],
  },
  Electrolyte: {
    data: [
      {
        test: 'Sodium',
        standard: '135 - 145',
        unit: 'mmol/L',
      },
      {
        test: 'Potassium',
        standard: '3.5 - 5.1',
        unit: 'mmol/L',
      },
      {
        test: 'Chloride',
        standard: '98 - 106',
        unit: 'mmol/L',
      },
    ],
  },
  'Glucose Panel': {
    data: [
      {
        test: 'Fasting Glucose',
        standard: '3.3 - 5.8',
        unit: 'mmol/L',
      },
      {
        test: 'Random Glucose',
        standard: '3.8 - 11.1',
        unit: 'mmol/L',
      },
      {
        test: 'Hemoglobin A1C',
        standard: '4 - 6',
        unit: '%',
      },
    ],
  },
  Enzyme: {
    data: [
      {
        test: 'Troponin T',
        standard: '< 0.01',
        unit: 'µg/L',
      },
      {
        test: 'Troponin I',
        standard: '< 0.01',
        unit: 'µg/L',
      },
      {
        test: 'Amylase',
        standard: '< 160',
        unit: 'U/L',
      },
    ],
  },
  'Blood Biochemical Profile': {
    data: [
      {
        test: 'Lactate',
        standard: '1 - 1.81',
        unit: 'mmol/L',
      },
      {
        test: 'Uric Acid',
        standard: '180 - 420',
        unit: 'µmol/L',
      },
    ],
  },
  Coagulation: {
    data: [
      {
        test: 'Fibrinogen',
        standard: '5.1 - 11.8',
        unit: 'µmol/L',
      },
      {
        test: 'PTT',
        standard: '28 - 38',
        unit: 'seconds',
      },
      {
        test: 'PT',
        standard: '10 - 13',
        unit: 'seconds',
      },
      {
        test: 'INR',
        standard: '0.9 - 1.2',
        unit: 'µmol/L',
      },
    ],
  },
  'Thyroid Test': {
    data: [
      {
        test: 'TSH',
        standard: '0.4 - 5.0',
        unit: 'mU/L',
      },
      {
        test: 'T4',
        standard: '8.5 - 15.2',
        unit: 'pmol/L',
      },
      {
        test: 'T3',
        standard: '3.5 - 6.5',
        unit: 'pmol/L',
      },
    ],
  },
};
