import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invitation } from 'app/lms/interfaces/Invitation';
import { InstitutionService } from './../../services/institution.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/_services';

@Component({
  selector: 'app-pending-invite-dialog',
  templateUrl: './pending-invite-dialog.component.html',
  styleUrls: ['./pending-invite-dialog.component.scss'],
})
export class PendingInviteDialogComponent {
  public loading: string[] = [];
  public deleted: string[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Invitation[] = [],
    private readonly matDialogRef: MatDialogRef<PendingInviteDialogComponent>,
    private institutionService: InstitutionService,
    private userService: UserService,
    private readonly toastr: ToastrService
  ) {}

  onAccept(invitationId: string) {
    this.loading.push(invitationId);
    this.institutionService.addInstitution(invitationId).subscribe(
      () => {
        this.data.find((i) => i._id === invitationId).consumed = true;
        this.toastr.success('Invite accepted', 'SUCCESS', {
          timeOut: 3000,
        });
        this.userService.getCurrentUser().subscribe();
      },
      () => {
        this.toastr.error('Unable to accept invite. Please try again', 'ERROR', {
          timeOut: 5000,
        });
      },
      () => {
        this.loading.splice(this.loading.indexOf(invitationId), 1);
        this.checkPendingInvites();
      }
    );
  }

  checkLoading(token: string) {
    return this.loading.includes(token);
  }

  checkDeleted(token: string) {
    return this.deleted.includes(token);
  }

  onDecline(invitationId: string) {
    this.loading.push(invitationId);
    this.institutionService.deleteInvitations([invitationId]).subscribe(
      () => {
        this.deleted.push(invitationId);
        this.toastr.success('Invite declined', 'SUCCESS', {
          timeOut: 3000,
        });
      },
      () => {
        this.toastr.error('Unable to decline invite. Please try again', 'ERROR', {
          timeOut: 5000,
        });
      },
      () => {
        this.loading.splice(this.loading.indexOf(invitationId), 1);
        this.checkPendingInvites();
      }
    );
  }

  checkPendingInvites() {
    let pending = false;
    this.data.forEach((invitation) => {
      if (invitation.consumed === false && !this.deleted.includes(invitation._id)) {
        pending = true;
      }
    });
    if (!pending) this.onClose();
  }

  onClose() {
    this.matDialogRef.close({ submitted: true });
  }
}
