import {Location} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SimpleGlobal} from 'ng2-simple-global';

@Component({
  selector: 'app-skilllevel-confirm-dialog',
  templateUrl: './skilllevel-confirm-dialog.component.html',
  styleUrls: ['./skilllevel-confirm-dialog.component.scss']
})
export class SkilllevelConfirmDialogComponent implements OnInit {
  h1text = '';
  h3text = '';
  h5text = '';
  btnNo = '';
  btnYes = '';
  constructor(
    public dialogRef: MatDialogRef<any>,
    public sg: SimpleGlobal,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit() {
    this.h1text = 'Warning Message'
    this.h3text = `Your current skill level doesn't match the assigned skill level from the course.`;
    this.h5text = 'Do you wish to continue?';
    this.btnNo = 'Set New Skill Level';
    this.btnYes = 'Proceed';
  }
  resume() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }
}
