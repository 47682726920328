export class CaseAttempts {
  completed: string;
  finalScores: finalScores;
  id: number;
  session_id: string;
  summary: [iSummary];
}

export class finalScores {
  knowledgeScore: number = 0;
  time: number = 0;
  cost: number = 0;
  costDeviation: number = 0;
  idealCost: number = 0;
}

export class Score {
  casedata: caseData;
  attempts: [CaseAttempts];
}
export class caseData {
  assigned: string;
  attempts: number;
  average: number;
  case_id: string;
  last_completed: string;
  name: string;
  caseType?: string;
}

export class iSummary {
  cost: string;
  costDeviation: string;
  idealCost: string;
  knowledgeScore: number;
  name: string;
  time: number;
}
//--------------------------------------------------------
export interface CaseData {
  average: number;
  assigned: string;
  attempts: number;
  last_completed: string;
  name: string;
  case_id: string;
}

export interface FinalScores {
  knowledgeScore: number;
  time: number;
  cost: number;
  costDeviation: number;
  idealCost: number;
}

export interface Summary {
  name: string;
  knowledgeScore: number;
  time: number;
  cost: any;
  costDeviation: any;
  idealCost: any;
}

export interface iCaseAttempts {
  session_id: string;
  completed?: string;
  finalScores: FinalScores;
  summary: Summary[];
  id: number;
}

export interface AttemptData {
  caseData: CaseData;
  attempts: iCaseAttempts[];
}
