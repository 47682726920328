 
import { course,patientcase, instructor,student, SummativeFeedback, FormativeFeedback, CaseRow, userid } from './../_models/teacher-dashboard.model'

export class GetCurrentCourse{
    static readonly type = '[CURRETCCOURSE] GetCurrentCourse'
}

export class GetCurrentInstructor{
    static readonly type = '[CURRETCINSTURCOTR] GetCurrentInstructor'
}
export class GetCourses{
    static readonly type = '[GETCOURSES] GetCourses'
}

export class AddCourse  {
    static readonly type = '[ADDCOURSE] AddCourse'
    constructor(public payload: course) {} 
}
export class EditCourse  {
    static readonly type = '[EDITCOURSE] EditCourse'
    constructor(public payload: course) {} 
}

export class RemoveCourse  {
    static readonly type = '[REMOVECOURSE] RemoveCourse'
    constructor(public payload: course) {} 
}

export class SetCurrentCourse  {
    static readonly type = '[SETCURRENTCOURSE] SetCurrentCourse'
    constructor(public payload: course) {} 
}

export class SetStudent  {
    static readonly type = '[SETSTUDENT] SetStudent'
    constructor(public payload: userid) {} 
}
export class SetInviteList  {
    static readonly type = '[SETINVITELIST] SetInviteList'
     
}
export class SetSelectedStudent  {
    static readonly type = '[SETSELECTEDSTUDENT] SetSelectedStudent'
    constructor(public payload: student) {} 
}
export class SetSelectedCase  {
    static readonly type = '[SETSELECTEDCASE] SetSelectedCase'
    constructor(public payload: CaseRow) {}
}


export class AddCaseCurrentCourse  {
    static readonly type = '[ADDCASECURRENTCOURSE] AddCase'
    constructor(public readonly currentCourse:course,public payload: patientcase) {} 
}
export class RemoveCaseCurrentCourse  {
    static readonly type = '[REMOVECASECURRENTCOURSE] RemoveCase'
    constructor(public readonly currentCourse:course, public readonly payload: patientcase) {} 
}

export class AddStudentCurrentCourse  {
    static readonly type = '[ADDSTUDENTCURRENTCOURSE] AddStudent'
    constructor(public readonly currentCourse:course,public payload: string[]) {} 
}
export class RemoveStudentCurrentCourse  {
    static readonly type = '[REMOVESTUDENTCURRENTCOURSE] RemoveStudent'
    constructor(public readonly currentCourse:course, public readonly payload: student) {} 
}

export class RemoveFromInviteList  {
    static readonly type = '[REMOVEFROMINVITELIST] RemoveFromInviteList'
    constructor(public readonly courseid:string, public readonly email: string) {} 
}

export class GetSummativeCurrentStudent  {
    static readonly type = '[GETSUMMATIVE] GetSummative'
}

export class AddSummativeToCurrentStudent  {
    static readonly type = '[ADDSUMMATIVE] AddSummative'
    constructor(  public readonly payload: SummativeFeedback) {}
}

export class UpdateSummativeToCurrentStudent  {
    static readonly type = '[UPDATESUMMATIVE] UpdateSummative'
    constructor(  public readonly payload: SummativeFeedback) {}
}

export class GetFormativeCurrentStudent  {
    static readonly type = '[GETFORMATIVE] GetFormative'
}

export class AddFormativeToCurrentStudent  {
    static readonly type = '[ADDFORMATIVE] AddFormative'
    constructor(  public readonly payload: string) {} 
}

export class GetFormativeCourse  {
    static readonly type = '[GETFORMATIVECOURSE] GetFormativeCourse'
}
