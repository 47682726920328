<div class="list-conatiner" fxFlexAlign="stretch" fxLayout="row">
    <mat-card class="completed-case-card" fxLayout="row">
        <mat-card-content class="completed-case-card-content">
            <div class="student-list2">
                <table mat-table matSort #MatTable [dataSource]="scoreDataSource" class="cases-table">

                    <ng-container matColumnDef="casehistory">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header"> Case History
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-sub-headers"> Completed </th>
                        <td mat-cell *matCellDef="let element"> {{element.last_completed | date:'shortDate'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-sub-headers">Best Score </th>
                        <td mat-cell *matCellDef="let element"> {{element.bestScore| number:'1.0-0'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="comments">
                      <th mat-header-cell *matHeaderCellDef [hidden]="access || !showFeedback" class="table-sub-headers"> Feedback Comments </th>
                      <td mat-cell *matCellDef="let element" [hidden]="access || !showFeedback">
                          <mat-icon color="{{element.comments > 0 ? 'primary' : 'grey'}}" [matBadge]="element.comments" matBadgeSize="small"
                              matBadgeColor="warn">feedback</mat-icon>
           
                      </td>
                  </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef [hidden]="!access" class="table-sub-headers"> Feedback </th>
                        <td mat-cell *matCellDef="let element" [hidden]="!access">
                            <button mat-icon-button color="accent" (click)="addFormativeComment(element)">
                              <mat-icon [matBadge]="getBadge(element)" matBadgeSize="medium"
                                  matBadgeColor="warn">feedback</mat-icon>
                          </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="feedbackcolumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: feedbackcolumns;" matTooltip="Click for details" [matTooltipPosition]="'above'" style="cursor:pointer;" (click)="caseScore(row)"></tr>
                </table>
                <div class="descr-cases" *ngIf="data.length==0">
                    No Data available
                </div>
                <div *ngIf="err">
                    {{err}}
                </div>
                <div class="spin1" *ngIf="loading && data.length!=0">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
