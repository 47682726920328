import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../services/contacts.service';

interface ContactDialogData {
  email: string;
  institution: string;
  subject: string;
}

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  public contactForm: FormGroup;

  constructor(
    private contactsService: ContactsService,
    private readonly fb: FormBuilder,
    private readonly matDialogRef: MatDialogRef<ContactDialogComponent>,
    private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data: ContactDialogData,
  ) { }

  ngOnInit(): void {
    this.contactForm = this.buildForm();
  }

  buildForm() {
    return this.fb.group({
      email: [this.data.email || '', [Validators.email, Validators.required]],
      institution: [this.data.institution || '', Validators.required],
      subject: [this.data.subject || '', [Validators.required]],
      content: ['', [Validators.required]],
      captcha: [null, [Validators.required]],
    })
  }

  onSubmit() {
    if (!this.contactForm.valid) {
      return;
    }
    this.contactsService
      .createTicket(this.contactForm.get('subject').value, this.contactForm.get('content').value, this.contactForm.get('institution').value)
      .subscribe(() => {
        this.toastr.success('Our sales team will contact you shortly', 'Thank you!', {
          timeOut: 3000,
        });
        this.matDialogRef.close();
      });
  }
}
