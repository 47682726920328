import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { GeneralDialogComponent } from '@canhealth/cyberpatient-ui';
import { AuthenticationService } from '../_services/index';
import { UserService } from '../_services/user.service';
import { PendingInviteDialogComponent } from 'app/lms/shared-components/pending-invite-dialog/pending-invite-dialog.component';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  loadingAndSaving = false;
  error = '';
  loginform;

  profileJson: string = null;

  private redirectUrl: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public auth: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const auth0User = JSON.parse(localStorage.getItem('currentAuth0User'));
    if (auth0User) {
      this.authenticationService.loginAuth0(auth0User).subscribe(
        async (result) => {
          console.log('result:', result);
        },
        (err: HttpErrorResponse) => {
          console.log('err:', err);
        }
      );
    }
    this.loginform = this.formBuilder.group({
      username: this.formBuilder.control('', Validators.compose([Validators.required])),
      password: this.formBuilder.control(''),
    });
    this.setupRedirect();
  }

  auth0Login() {
    this.auth.loginWithRedirect();
  }

  setupRedirect() {
    const redirectUrlQuery = this.route.snapshot.queryParamMap.get('redirectUrl');
    this.redirectUrl = redirectUrlQuery ? decodeURIComponent(redirectUrlQuery) : null;
    this.router.navigate([], {
      queryParams: {
        redirectUrl: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  login() {
    this.loading = true;
    this.loadingAndSaving = true;
    this.authenticationService.login(this.loginform.value.username, this.loginform.value.password).subscribe(
      async (result) => {
        this.loadingAndSaving = false;

        if (result === null) {
          // login failed
          this.error = 'Username or password is incorrect';
        } else {
          if (this.redirectUrl) {
            await this.router.navigateByUrl(this.redirectUrl);
          } else {
            await this.router.navigate(['']);
          }
          this.userService.retrievePendingInvitations().subscribe((data) => {
            if (data.length) {
              this.dialog.open(PendingInviteDialogComponent, {
                data,
                width: '450px',
              });
            }
          });
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 422) {
          this.dialog.open(GeneralDialogComponent, {
            data: {
              header: 'Login failed',
              body: `<h5>Invalid username or password</h5>`,
              buttons: [
                {
                  text: 'Dismiss',
                  color: 'primary',
                },
              ],
            },
          });
        }
        this.loadingAndSaving = false;
      }
    );
  }
  currYear: number = new Date().getFullYear();
}
