import { DragDropModule } from '@angular/cdk/drag-drop';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GeneralDialogModule } from '@canhealth/cyberpatient-ui';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SimpleGlobal } from 'ng2-simple-global';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { AuthGuard } from './_guards/index';
import { httpInterceptorProviders } from './_http-interceptors';
import {
  AuthenticationService,
  FetchAssignmentDataService,
  FetchCaseService,
  FetchHistoryService,
  GetMenuListService,
  InstanceNameService,
  PagerService,
  SaveAssignmentDataService,
  SessionManagerService,
  UserService,
} from './_services/index';
import { PaymentManagementService } from './_services/payment-management.service';
import { RegisterAccService } from './_services/register-acc.service';
import { SaveTOSService } from './_services/save-tos.service';
import { TranslationsService } from './_services/translations.service';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ChangePasswordComponent } from './account-settings/change-password/change-password.component';
import { GeneralSettingsComponent } from './account-settings/general-settings/general-settings.component';
import { LogoFormComponent } from './account-settings/logo-form/logo-form.component';
import { ModifySubscriptionComponent } from './account-settings/modify-subscription/modify-subscription.component';
import { SignatureFormComponent } from './account-settings/signature-form/signature-form.component';
import { AbortConfirmComponent } from './account-skillset/abort-confirm/abort-confirm.component';
import { AccountSkillsetComponent } from './account-skillset/account-skillset.component';
import { SuccessConfirmComponent } from './account-skillset/success-confirm/success-confirm.component';
import { ActivateEmailComponent } from './activate-email/activate-email.component';
import { AgreementPromptComponent } from './agreement-prompt/agreement-prompt.component';
import { AppMotationObserverDirective } from './agreement-prompt/app-motation-observer.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AudioRecordingService } from './audio-recording.service';
import { BillingPopupComponent } from './billing/billing-popup/billing-popup.component';
import { BillingComponent } from './billing/billing.component';
import { chartScoreShareService } from './chartScoreShareService';
import { CommentsComponent } from './comments/comments.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmationSentComponent } from './confirmation-sent/confirmation-sent.component';
import { cpImageSlicerService } from './cp-image-slicer-service.service';
import { BarchartComponent } from './dashboard/barchart/barchart.component';
import { ClasseslistComponent } from './dashboard/classeslist/classeslist.component';
import { DashStatComponent } from './dashboard/dash-stat/dash-stat.component';
import { FeedComponent } from './dashboard/feed/feed.component';
import { FeedService } from './dashboard/feed/feed.service';
import { DashContentComponent } from './dashboard/index';
import { NewsFeedComponent } from './dashboard/news-feed/news-feed.component';
import { StuFeedService } from './dashboard/stu-feed/feed.service';
import { StuFeedComponent } from './dashboard/stu-feed/stu-feed.component';
import { TestConfirmComponent } from './dashboard/stu-feed/test-confirm/test-confirm.component';
import { HistoryTakingErrorDialogComponent } from './student-case/history-taking-error-dialog/history-taking-error-dialog.component';
import { SkilllevelConfirmDialogComponent } from './teacher-dashboard/assignment-cases/skilllevel-confirm-dialog/skilllevel-confirm-dialog.component';
import { StuPiechartComponent } from './dashboard/stu-piechart/stu-piechart.component';
import { StudentCompareChartComponent } from './dashboard/student-compare-chart/student-compare-chart.component';
import { EpachartcompareComponent } from './epachartcompare/epachartcompare.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ErrorMsgService } from './error-msg.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ConfirmRemovalComponent } from './favourites/confirm-removal/confirm-removal.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { favouritesFilter } from './favourites/filter.pipe';
import { FilterPipe } from './filter.pipe';
import { GradesComponent } from './grades/grades.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/index';
import { LabScoreService } from './lab-tests/tests/lab-score.service';
import { SharedComponentsModule } from './lms/shared-components/shared-components.module';
import { LoginComponent } from './login/login.component';
import { MatchHeightDirective } from './match-height.directive';
import { NewsComponent } from './news/news.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { OrderByPipe } from './orderBy';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PopUpContainerComponent } from './pop-ups/pop-up-container/pop-up-container.component';
import { ReadOnlyDirective } from './read-only.directive';
import { RegisterAccountAddressComponent } from './register-account-address/register-account-address.component';
import { RegisterAccountPersonalComponent } from './register-account-personal/register-account-personal.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { RemoveSpacesPipe } from './remove-spaces.pipe';
import { ResendActivationComponent } from './resend-activation/resend-activation.component';
import { ResetEmailComponent } from './reset-email/reset-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetComponent } from './reset/reset.component';
import { RecordingCardComponent } from './review-asseement/recording-card/recording-card.component';
import { RouteEventsService } from './route-events.service';
import { ScorePeriodPipe } from './score-period.pipe';
import { SharedModule } from './shared/shared.module';
import { CheckPointState } from './state/checkpoints.state';
import { EpaState } from './state/epa.state';
import { RpoState } from './state/rpo.state';
import { TeachDashBoardState } from './state/teacherdashboard.state';
import { StringTruncateComponent } from './string-truncate/string-truncate.component';
import { StudentCaseOverviewComponent } from './student-case-overview/student-case-overview.component';
import { StudentConfirmComponent } from './student-confirm/student-confirm.component';
import { StudentCounsellingComponent } from './student-counselling/student-counselling.component';
import { StudentFeedbackEpaComponent } from './student-feedback-epa/student-feedback-epa.component';
import { StudentFeedbackNursingComponent } from './student-feedback-nursing/student-feedback-nursing.component';
import { ConfirmDialogComponent } from './student-general-order/confirm-dialog/confirm-dialog.component';
import { GeneralOrderService } from './student-general-order/general-order-service.service';
import { StudentGradesComponent } from './student-grades/student-grades.component';
import { StudentIntroComponent } from './student-intro/student-intro.component';
import { DetailImageService } from './student-physical/services/detail-image.service';
import { ZoomService } from './student-physical/services/zoom.service';
import { StudentScoreComponent } from './student-score/student-score.component';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { AddCasesComponent } from './teacher-dashboard/add-cases/add-cases.component';
import { AssignmentCasesComponent } from './teacher-dashboard/assignment-cases/assignment-cases.component';
import { ClassdashboardComponent } from './teacher-dashboard/classldashboard/classdashboard.component';
import { CourseCompletedCasesComponent } from './teacher-dashboard/course-completed-cases/course-completed-cases.component';
import { CourseGradeComponent } from './teacher-dashboard/course-grade/course-grade.component';
import { CourseHeaderComponent } from './teacher-dashboard/course-header/course-header.component';
import { CreateClassComponent } from './teacher-dashboard/create-class/create-class.component';
import { EditFormativeAssessmentComponent } from './teacher-dashboard/edit-formative-assessment/edit-formative-assessment.component';
import { EditSummativePerformanceComponent } from './teacher-dashboard/edit-summative-performance/edit-summative-performance.component';
import { FormativeAssessmentComponent } from './teacher-dashboard/formative-assessment/formative-assessment.component';
import { StudentFormativeAssessmentComponent } from './teacher-dashboard/student-formative-assessment/student-formative-assessment.component';
import { SummativeAssessmentComponent } from './teacher-dashboard/summative-assessment/summative-assessment.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { FooterComponent } from './theme/footer/footer.component';
import { LoadService } from './theme/loading/load-service.service';
import { LoadingComponent } from './theme/loading/loading.component';
import { MsgCenterComponent } from './theme/msg-center/msg-center.component';
import { PageTopComponent } from './theme/page-top/page-top.component';
import { SidebarComponent } from './theme/sidebar/sidebar.component';
import { SidebarService } from './theme/sidebar/sidebar.service';
import { TutorialsModule } from './tutorials/tutorials.module';
import { AuthModule } from '@auth0/auth0-angular';
import { Auth0CallbackComponent } from './auth/auth0-callback/auth0-callback.component';
import { RegisterCampaignAccountComponent } from './register-campaign-account/register-campaign-account.component';
import { MobileDialogComponent } from './mobile-dialog/mobile-dialog.component';
import { BrowserSuggestionSnackbarComponent } from './browser-suggestion-snackbar/browser-suggestion-snackbar.component';
import { OutagePageComponent } from './outage-page/outage-page.component';
import { VoiceCommandErrorDialogComponent } from './student-case/voice-command-error-dialog/voice-command-error-dialog.component';
import { CourseNotActiveDialogComponent } from './course-not-active-dialog/course-not-active-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxPaginationModule,
    ChartsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTableModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatGridListModule,
    MatSortModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DragDropModule,
    [
      NgxsModule.forRoot([RpoState, CheckPointState, EpaState, TeachDashBoardState]),
      NgxsResetPluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot(),
    ],
    MatBadgeModule,
    GeneralDialogModule,
    SuperAdminModule,
    AppRoutingModule,
    PrettyJsonModule,
    SharedComponentsModule,
    TutorialsModule,
    NzSliderModule,
    AuthModule.forRoot({
      domain: 'dev-i5vxfbrc.us.auth0.com',
      clientId: 'oC7PYDORAdp3sdgifS30DEee97obdgvA',
      skipRedirectCallback: true,
      redirect_uri: 'https://feat-auth0-new.cyberpatient.ca/callback',
    }),
  ],
  declarations: [
    AppComponent,
    LoadingComponent,
    LoginComponent,
    HomeComponent,
    DashContentComponent,
    FooterComponent,
    PageTopComponent,
    SidebarComponent,
    MsgCenterComponent,
    AccountSettingsComponent,
    ChangePasswordComponent,
    ModifySubscriptionComponent,
    GradesComponent,
    StudentCaseOverviewComponent,
    BarchartComponent,
    FeedComponent,
    DashStatComponent,
    RemoveSpacesPipe,
    OrderByPipe,
    NotAuthorizedComponent,
    StudentGradesComponent,
    StuFeedComponent,
    StudentScoreComponent,
    TestConfirmComponent,
    HistoryTakingErrorDialogComponent,
    SkilllevelConfirmDialogComponent,
    HelpComponent,
    AbortConfirmComponent,
    SuccessConfirmComponent,
    // InvasivePlanCLabTestsComponentomponent,
    ConfirmDialogComponent,
    MatchHeightDirective,
    StudentCounsellingComponent,
    StudentIntroComponent,
    StudentConfirmComponent,
    ErrorMessageComponent,
    ErrorPageComponent,
    StudentCompareChartComponent,
    StuPiechartComponent,
    PasswordRecoveryComponent,
    ResetEmailComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
    RegisterAccountComponent,
    RegisterAccountAddressComponent,
    RegisterAccountPersonalComponent,
    AccountSkillsetComponent,
    FilterPipe,
    favouritesFilter,
    ResetComponent,
    ActivateEmailComponent,
    FavouritesComponent,
    ConfirmRemovalComponent,
    ScorePeriodPipe,
    NewsComponent,
    NewsFeedComponent,
    HelpComponent,
    CommentsComponent,
    ConfirmationSentComponent,
    BillingComponent,
    AppMotationObserverDirective,
    // ConfirmPaymentComponent,
    BillingPopupComponent,
    AgreementPromptComponent,
    PopUpContainerComponent,
    RecordingCardComponent,
    EpachartcompareComponent,
    TeacherDashboardComponent,
    CreateClassComponent,
    AddCasesComponent,
    ClassdashboardComponent,
    SummativeAssessmentComponent,
    EditSummativePerformanceComponent,
    FormativeAssessmentComponent,
    EditFormativeAssessmentComponent,
    ReadOnlyDirective,
    ClasseslistComponent,
    CourseGradeComponent,
    CourseCompletedCasesComponent,
    StudentFormativeAssessmentComponent,
    CourseHeaderComponent,
    AssignmentCasesComponent,
    StringTruncateComponent,
    ResendActivationComponent,
    GeneralSettingsComponent,
    SignatureFormComponent,
    LogoFormComponent,
    StudentFeedbackEpaComponent,
    StudentFeedbackNursingComponent,
    Auth0CallbackComponent,
    RegisterCampaignAccountComponent,
    MobileDialogComponent,
    BrowserSuggestionSnackbarComponent,
    OutagePageComponent,
    VoiceCommandErrorDialogComponent,
    CourseNotActiveDialogComponent,
  ],
  entryComponents: [
    HelpComponent,
    MobileDialogComponent,
    BrowserSuggestionSnackbarComponent,
    TestConfirmComponent,
    HistoryTakingErrorDialogComponent,
    SkilllevelConfirmDialogComponent,
    AbortConfirmComponent,
    SuccessConfirmComponent,
    ErrorMessageComponent,
    BillingPopupComponent,
    ConfirmDialogComponent,
    CommentsComponent,
    StudentConfirmComponent,
    AgreementPromptComponent,
    PopUpContainerComponent,
    SummativeAssessmentComponent,
    EpachartcompareComponent,
    VoiceCommandErrorDialogComponent,
    CourseNotActiveDialogComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LekhXcUAAAAAJ3tqEvIZCTqQ3VGtj9DlMC8nwQ6',
      } as RecaptchaSettings,
    },
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthGuard,
    AuthenticationService,
    SaveTOSService,
    FeedService,
    SidebarService,
    StuFeedService,
    FetchCaseService,
    FetchHistoryService,
    InstanceNameService,
    PagerService,
    SimpleGlobal,
    cpImageSlicerService,
    ZoomService,
    DetailImageService,
    FetchAssignmentDataService,
    SaveAssignmentDataService,
    LabScoreService,
    GeneralOrderService,
    GetMenuListService,
    UserService,
    LoadService,
    SessionManagerService,
    ErrorMsgService,
    httpInterceptorProviders,
    RegisterAccService,
    PaymentManagementService,
    AudioRecordingService,
    chartScoreShareService,
    RouteEventsService,
    TranslationsService,
    ThemeService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
