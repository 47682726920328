import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import countryregions from '../../assets/data/countryregions.json';
import { PaymentManagementService } from '../_services/payment-management.service';

declare var Stripe: stripe.StripeStatic;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  providers: [PaymentManagementService],
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  // userEmail = "sarah@can-health.org";
  client_secret;
  dataLoaded = false;
  loadingAndSaving = false;
  billing_details: any;
  stripeForm: FormGroup;
  public chosenPlan;
  disableSubmit = true;
  plans: any[];
  billingForm: FormGroup;
  countryList: any = countryregions;
  regionsList: any = [];
  countryChosen = false;
  stripeResult;
  // stripe : stripe.Stripe;
  stripe;
  details: any;
  clientSecret;
  card;
  cardErrors;
  formState;
  dateString;
  date;
  tokenResult;
  // amount = 2000;
  receiptLink;
  // @Input() description: string;
  @Input() billingDetails: any;
  // @Input() amount: number;
  @ViewChild('cardElement', {static: false}) cardElement: ElementRef;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  private cardToken: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private paymentManagementService: PaymentManagementService, private sg: SimpleGlobal) {
    this.getPlans();
    if (this.sg['plan_type'] == undefined) {
      this.sg['plan_type'] = 0;
    }
    this.billingForm = this.formBuilder.group({
      plan: [this.sg['plan_type'], Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      region: [{value: '', disabled: (!this.countryChosen)}, Validators.required],
      country: ['', Validators.required],
      postal: ['', Validators.required]
    });
    this.billingForm.get('region').disable();
  }

  getPlans() {
    this.loadingAndSaving = true;

    this.paymentManagementService.getPlans().subscribe(result => {
      this.loadingAndSaving = false;
      this.plans = result;
      if (this.plans[0].earlyBirdEligible === true) {
        this.plans[0].price = 110;
      }
      this.chosenPlan = this.plans[this.billingForm.value.plan];
      this.dataLoaded = true;
    }, err => {
      this.loadingAndSaving = false;
    });


  }

  openCaseLibrary() {
    this.router.navigate(['/case-library']);
  }

  submitPayment() {
    // this.setBillingValues();
    this.chosenPlan = this.plans[this.billingForm.value.plan];
    this.loadingAndSaving = true;

    this.stripe.createToken(
      this.card, this.billing_details
    ).then((result) => {
      // this.cardToken = result.token.id;
      if (result.error) {
      } else {
        this.cardToken = result.token.id;
        const planReq = {
          'plan_id': this.chosenPlan.plan_id,
          'card_token': this.cardToken
        };

        this.sendConfirmation(planReq);

      }
    });


  }


  reset() {
    this.formState = '';
    this.billingForm.reset();
  }

  ngOnInit() {


  }

  openReceipt() {
    window.open(this.receiptLink, '_blank');
  }

  sendConfirmation(plan) {
    this.loadingAndSaving = true;

    this.paymentManagementService.selectPlan(plan).subscribe(result => {
      this.loadingAndSaving = false;
      if (result.subscriptionStatus === 'succeeded') {
        this.sg['membership_status'] = result.membership;
        this.formState = 'success';
        this.receiptLink = result.receiptURL;
      }
    }, err => {
      this.loadingAndSaving = false;
    });
    ;
  }

  updateRegions() {
    const result = this.countryList.filter(obj => {
      return obj.countryShortCode === this.billingForm.value.country;
    })
    this.regionsList = result[0].regions;
    this.billingForm.get('region').reset();
    this.billingForm.get('region').enable();
    this.countryChosen = true;
  }

  setBillingValues() {
    if (this.billingForm.valid) {
      this.stepper.next();
      this.chosenPlan = this.plans[this.billingForm.value.plan];

      const name = this.billingForm.value.firstName.concat(' ', this.billingForm.value.lastName);
      const email = this.sg['username'];


      this.billing_details = ({
        'address_city': this.billingForm.value.city,
        'address_country': this.billingForm.value.country,
        'address_line1': this.billingForm.value.address1,
        'address_line2': this.billingForm.value.address2,
        'address_zip': this.billingForm.value.postal,
        'address_state': this.billingForm.value.region,
        'name': name,
        'email': this.sg['username']

      });


      const currentDate = new Date();

      const dateHolder = currentDate.getDate();
      let month = currentDate.getMonth(); // Be careful! January is 0 not 1
      let year = currentDate.getFullYear();
      this.date = (month + 1) + '/' + dateHolder + '/' + year;
      if (this.billingForm.value.plan == 'Annual Plan') {
        year = year + 1;
      } else {
        month = month + 2;
      }

      this.dateString = dateHolder + '/' + month + '/' + year;
      if (!this.stripe) {
        this.stripe = Stripe(this.sg['stripeKey']);
        const style = {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        };

        const elements = this.stripe.elements();
        this.card = elements.create('card', {style});
        this.card.mount(this.cardElement.nativeElement);
        this.card.addEventListener('change', ({error}) => {
          this.cardErrors = error && error.message;
          if (error === undefined) {

            this.disableSubmit = false;
          } else {
            this.disableSubmit = true;
          }


        });
      }
    }
  }
}
