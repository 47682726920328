import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Institution } from '../../lms/interfaces/institution';
import { User } from '../../_models/user.model'
import { Editable } from '../../shared/interfaces/editable';
import { SuperAdminService } from '../services/super-admin.service';




type EditableInstitution = Editable<Institution>;

export interface TestElement {
  name: string;
  expiraryDate: string;
  license: string;
  accountStatus: string;
  remind: boolean;
}

@Component({
  selector: 'app-institutions-list',
  templateUrl: './institutions-list.component.html',
  styleUrls: ['./institutions-list.component.scss']
})

export class InstitutionsListComponent implements OnInit {
  @Input() institutions: EditableInstitution[];
  @Input() users: User[] = [];
  @Output() edit = new EventEmitter<EditableInstitution>();
  @Output() save = new EventEmitter<EditableInstitution>();
  @Output() login = new EventEmitter<EditableInstitution>();

  columns = [
    {
      columnDef: 'name',
      header: 'Name',
      cell: (element: User) => `${element.firstName} ${element.lastName}`,
    },
    {
      columnDef: 'payment_valid_until',
      header: 'Expiry Date',
      cell: (element: User) => `${element.payment_valid_until}`.slice(0,10),
    },
    {
      columnDef: 'accountStatus',
      header: 'Account Status',
      cell: (element: User) => `${element.accountStatus}`[0].toUpperCase() + `${element.accountStatus}`.substring(1),
    },
    {
      columnDef: 'membership',
      header: 'Membership',
      cell: (element: User) => `${element.membership}`[0].toUpperCase() + `${element.membership}`.substring(1),
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: User) => `${element.email}`,
    }

  ];
  displayedColumns = this.columns.map(e=>e.columnDef);
  dataSource = [];


  constructor(private readonly superAdminService: SuperAdminService) { }

  ngOnInit(): void {
    this.fetchAllUsers()
  }

  toggleInstitution(e){
    const accordion = document.getElementById(e)
    if(accordion.classList.contains('collapse')){
      accordion.classList.remove('collapse')
    }else{
      accordion.classList.add('collapse')
    }
  }

  private fetchAllUsers(){
    this.superAdminService.listAllUsers().subscribe((users) => {
      this.users = [...users]
      this.dataSource = this.users;
    })
  }

  sortDataSource(institutation){
    const sortArr:User[] = this.dataSource?.filter(e => {
        return e.institution === institutation
      })    
      return sortArr
    }    
}
