import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HealthcheckService } from 'app/_services/healthcheck.service';

@Injectable()
export class HealthCheckInterceptor implements HttpInterceptor {
  private ticketSubmitted = false;

  constructor(private healthcheckService: HealthcheckService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else if (!this.healthcheckService.isServerDown()) {
          this.healthcheckService.serverHealthFlag().subscribe(
            () => {
              this.ticketSubmitted = false;
              return;
            },
            () => {
              if (!this.ticketSubmitted) {
                this.ticketSubmitted = true;
              }
            }
          );
        }
        return throwError(errorMsg);
      })
    );
  }
}
