<div class="notes-container">
  <div *ngIf="data.nursesNotes">
    <img [src]="data.nursesNotes"/>
    <div class="continue-container">
      <button class="action mat-button mat-raised-button mat-primary" (click)="onClose()">Continue</button>
    </div>
  </div>
  <div class="table-container" *ngIf="labDataSource">
    <table mat-table [dataSource]="labDataSource" class="mat-elevation-z0">
    
      <ng-container matColumnDef="test">
        <th mat-header-cell *matHeaderCellDef> Test </th>
        <td mat-cell *matCellDef="let element"> {{element.test}} </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element">{{element.value ? element.value : "N/A"}}</td>
      </ng-container>

      <ng-container matColumnDef="standard">
        <th mat-header-cell *matHeaderCellDef> Standard </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.standard"></td>
      </ng-container>
    
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef> Unit </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.unit"></td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row  *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
  <div *ngIf="labDataSource" class="table-button">
    <button class="action mat-button mat-raised-button mat-primary" (click)="onClose()">Continue</button>
  </div>
</div>
