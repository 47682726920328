import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { CaseRow, FormativeFeedback } from '../../_models/teacher-dashboard.model';
import { SessionManagerService } from '../../_services';
import { Course } from '../../lms/interfaces/course';
import { FacultyMember } from '../../lms/interfaces/faculty-member';
import { StudentsService } from '../../lms/services/students.service';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';

@Component({
  selector: 'app-edit-formative-assessment',
  templateUrl: './edit-formative-assessment.component.html',
  styleUrls: ['./edit-formative-assessment.component.scss'],
})
export class EditFormativeAssessmentComponent implements OnInit {
  data = [];
  myCourse = {} as Course;
  listOfFeedback: FormativeFeedback[] = [];
  mystudent = {} as FacultyMember;
  comment = '';
  caserow = {} as CaseRow;

  cost = 0;
  time = 0;

  loading: boolean;

  err;
  loadingAndSaving: boolean;

  private readonly studentId: string;
  private readonly sessionId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private studentService: StudentsService,
    private teacherService: TeacherDashboardDataService,
    private sessionService: SessionManagerService,
    private _location: Location,
    private sg: SimpleGlobal
  ) {
    this.mystudent = this.router.getCurrentNavigation().extras.state?.student;
    this.myCourse = this.router.getCurrentNavigation().extras.state?.course;
    this.caserow = this.router.getCurrentNavigation().extras.state?.caseData;
    this.studentId = this.route.snapshot.paramMap.get('id');
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');
  }

  ngOnInit() {
    this.loading = false;
    if (!this.sessionId && !this.studentId) {
      this.router.navigate(['']);
    }
    this.fetchData();
  }

  getScore() {
    return this.teacherService.getAssignmentFeedback(this.caserow.session_id, this.mystudent.email).pipe(
      tap((f) => {
        if (f) {
          this.cost = f.message.feedback.finalScores.cost;
          this.time = f.message.feedback.finalScores.time;
        }
      })
    );
  }

  getFormative() {
    return this.teacherService
      .getFormativeInsturctorByCase(this.myCourse._id, this.mystudent.memberId, this.caserow.case_id)
      .pipe(
        tap((formativeFeedbacks) => {
          this.listOfFeedback = formativeFeedbacks.filter((f) => f.case_id._id === this.caserow.case_id);
        })
      );
  }

  addComment() {
    this.loading = true;
    this.teacherService
      .addFormativeAssessment(this.myCourse._id, this.mystudent.memberId, this.comment, this.caserow.case_id)
      .subscribe((feedback) => {
        this.listOfFeedback.push(feedback);
        this.comment = '';
        this.loading = false;
      });
  }

  backClicked() {
    this._location.back();
  }

  getGradesOverview() {
    this.loadingAndSaving = true;
    this.sg['getFeedback'] = this.caserow.session_id;
    const url = this.myCourse.nursing ? 'nursing-feedback' : 'feedback';
    this.router.navigate(['gradesOverview', url], {
      queryParams: {
        session_id: this.caserow.session_id ?? this.sessionId,
        case_id: this.caserow.case_id,
      },
      state: {
        student: this.mystudent,
        course: this.myCourse,
        caseData: this.caserow,
      },
    });
  }

  private fetchData() {
    this.loading = true;

    let dataObs;

    if (this.mystudent && this.myCourse && this.caserow) {
      dataObs = forkJoin([this.getScore(), this.getFormative()]);
    } else {
      dataObs = forkJoin([this.fetchStudent(), this.fetchSession()]).pipe(
        mergeMap(() => forkJoin([this.getScore(), this.getFormative()]))
      );
    }
    dataObs.subscribe(() => (this.loading = false));
  }

  private fetchStudent() {
    return this.studentService.getStudent(this.studentId).pipe(
      tap((student) => {
        this.mystudent = student;
      })
    );
  }

  private fetchSession() {
    return this.sessionService.getSession(this.sessionId).pipe(
      tap((session: any) => {
        this.myCourse = session.course;
        this.caserow = {
          attempts: 0,
          bestScore: session.feedback?.finalScores?.knowledgeScore ?? 0,
          case_id: session.patientCase._id,
          last_completed: undefined,
          name: session.patientCase?.demographic?.patient_name ?? 'N/A',
          session_id: this.sessionId,
        };
      })
    );
  }
}
