import { Injectable } from '@angular/core';

@Injectable()
export class GeneralOrderService {
  fluidSet = [];

  oralSet;

  bloodSet = [];

  respiratorySet;

  outputSet = [];

  manualMonitorSet = [];

  continuousMonitorSet = [];

  constructor() { }

//SEND FUNCTIONS
  fluid(fluid, rate, duration) {
    this.fluidSet.push({'name': fluid, 'rate': rate, 'duration': duration});
    // console.log(this.fluidSet, 'We are within General Order Service');
  }


  respiratory(data) {
    this.respiratorySet = data;
    // console.log(this.respiratorySet, 'We are within General Order Service');
  }

  oral(data) {
    this.oralSet = data;
    // console.log(this.oralSet, 'We are within General Order Service');
  }

  blood(data) {
    this.bloodSet.push(data);
    // console.log(this.bloodSet, 'We are within General Order Service');
  }

  output(data) {
    this.outputSet = data;
    // console.log(this.outputSet, 'We are within General Order Service');
  }

  manualMonitor(data) {
    this.manualMonitorSet = data;
    // console.log(this.manualMonitorSet, 'We are within General Order Service');
  }

  continuousMonitor(data) {
    this.continuousMonitorSet = data;
    // console.log(this.continuousMonitorSet, 'We are within General Order Service');
  }

// RETURN FUNCTIONS
  getFluid() {
    return this.fluidSet;
  }

  getOral() {
    return this.oralSet;
  }

  getBlood() {
    return this.bloodSet;
  }

  getRespiratory() {
    return this.respiratorySet;
  }

  getOutput() {
    return this.outputSet;
  }

  getManualMonitor(){
    return this.manualMonitorSet;
  }

  getContinuousMonitor() {
    return this.continuousMonitorSet;
  }

}
