import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PaymentManagementService } from '../../_services/payment-management.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SimpleGlobal} from 'ng2-simple-global';

@Component({
  selector: 'app-billing-popup',
  templateUrl: './billing-popup.component.html',
  styleUrls: ['./billing-popup.component.scss']
})
export class BillingPopupComponent implements OnInit {
  order;
  data;
  type;
  submitted = false;
  subscriptions = [
    {
      value: 'Free',
      info: 'Limited Access to 12 cases. Upgrade anytime.',
      price: 0,
      description: ['Free Forever!']
    },
    {
      value: 'Annual',
      info: 'Unlimited cases for the year. Tax Included.',
      price: 9,
      description: ['Introductory price! ','Billed Annually, one payment of, ', 'CA$180', ' CA$110.'],
      discount: '180/year',
      discountText: 'Earlybird Pricing!',
      planID: 0
    },
    {
      value: 'Monthly',
      info: 'Unlimited cases, Cancel anytime. Tax Included.',
      price: 21,
      description: ['Equal to $252/year. ', 'Billed Monthly'],
      planID: 1
    }
  ]
  subscriptionNoEarly =     {
    value: 'Annual',
    info: 'Unlimited cases for the year, Save 25% with Annual Billing. Tax Included.',
    price: 15,
    description: ['Billed Annually, one payment of CA$180.'],
  };
  constructor(public dialogRef: MatDialogRef<any>, private sg: SimpleGlobal, @Inject(MAT_DIALOG_DATA) public x: any,  private PaymentManagementService: PaymentManagementService, private router: Router) { 
    
    this.PaymentManagementService.getPlans().subscribe(result => {

      var annualPlan = result[0];
      // Replace the annual object if early bird is false
       if(annualPlan.earlyBirdEligible === false){
       this.subscriptions[1] = this.subscriptionNoEarly;
       }
    }, err => {
    });
 
  }

  ngOnInit() {



  }
  goToBilling(type) {
    if(type !== 'Free') {
      this.sg['plan_type'] = type;
      this.router.navigate(['/billing']);
    }
  }

}
