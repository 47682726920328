<ng-container *ngIf="dataLoaded">

  <mat-horizontal-stepper *ngIf="!formState" linear="false" #stepper>
    <mat-step [stepControl]="billingForm">

      <form [formGroup]="billingForm" class="billing-form">
        <div class="billing-form-container medium-10 columns">

          <div class="row">
            <h2>Your Plan</h2>
            <section class="plan-type">
              <mat-radio-group class="plan-type-radio" formControlName="plan" required aria-label="Select an option">
                <mat-radio-button *ngFor="let x of plans; let i = index" [value]="i" class="mat-elevation-z2">
                  <span>CA${{x.price}}<span>/{{x.length}}</span></span><br><span class="sub-radio">{{x.display}}
                  </span>
                </mat-radio-button>

              </mat-radio-group>
            </section>
          </div>
          <div class="row billing-details form-element">
            <h2>Billing Details</h2>
            <div class="medium-6 columns">
              <label> First Name </label>
              <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                <input matInput id="firstName" type="text" placeholder="First Name" class="full-width"
                  formControlName="firstName" required class="lf--input" />
                <mat-error class="text-danger">
                  First Name is Required!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="medium-6 columns">
              <label> Last Name </label>
              <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                <input matInput id="lastName" placeholder="Last Name" type="text" class="full-width" class="lf--input"
                  formControlName="lastName" required />
                <mat-error class="text-danger">
                  Last Name is Required!
                </mat-error>
              </mat-form-field>
            </div>

            <div class="form-element">
              <div class="small-12 columns">
                <label> Address Line 1</label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input matInput id="address1" placeholder="Address Line 1" type="text" class="full-width"
                    class="lf--input" formControlName="address1" required />
                  <mat-error class="text-danger">
                    Address is Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


            <div class="form-element">
              <div class="full-width small-12 columns">
                <label> Address Line 2 </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input matInput id="address2" placeholder="Address Line 2" type="text" class="full-width"
                    class="lf--input" formControlName="address2" />
                </mat-form-field>
              </div>
            </div>

            <div class="medium-6 columns">
              <p>Country</p>
              <mat-form-field appearance="outline" floatLabel="never">
                <mat-select formControlName="country" placeholder="Select a Country" (selectionChange)="updateRegions()"
                  required name="country">
                  <mat-option *ngFor="let option of countryList" [value]="option.countryShortCode">
                    {{option.countryName}}
                  </mat-option>
                </mat-select>
                <mat-error class="text-danger">
                  Country is Required!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="medium-6 columns">
              <p>Region</p>
              <mat-form-field appearance="outline" floatLabel="never" [attr.disabled]="!countryChosen">
                <mat-select formControlName="region" placeholder="Select a Region" required name="region"
                  [attr.disabled]="!countryChosen">
                  <mat-option *ngFor="let option of regionsList" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="countryChosen" class="text-danger">
                  Region is Required!
                </mat-error>
                <mat-error *ngIf="!countryChosen">Please Choose a Country First!</mat-error>
              </mat-form-field>
            </div>
            <div class="medium-6 columns">
              <div class="form-element">
                <label> City </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input matInput id="city" placeholder="City" type="text" class="full-width" class="lf--input"
                    formControlName="city" required />
                  <mat-error class="text-danger">
                    City is Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="medium-6 columns">
              <div class="form-element">
                <label> Postal Code </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input matInput id="postal" placeholder="Postal Code" type="text" class="full-width" class="lf--input"
                    formControlName="postal" required />
                  <mat-error class="text-danger">
                    Please enter a valid postal code!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <button mat-button type="submit" (click)="setBillingValues()">Next</button>

          </div>
        </div>
      </form>

    </mat-step>
    <mat-step>

      <div class="billing-form">
        <div class="billing-form-container medium-6 columns">

          <div class="row">
            <h2>Your Plan<button matStepperPrevious class="edit">Edit</button></h2>
            <section class="plan-type">
              <section class="plan-summary">
                <span class="plan-type">{{chosenPlan.info}}</span>
                <p>{{chosenPlan.value}}</p>
              </section>
            </section>
          </div>
          <hr />


          <div class="row">
            <h2>Billing Details<button matStepperPrevious class="edit">Edit</button></h2>
            <section class="plan-type">
              <section class="plan-summary">
                <span class="plan-type">{{billingForm.value.firstName}} {{billingForm.value.lastName}}</span>
                <p>{{billingForm.value.address1}}, {{billingForm.value.address2}}</p>
                <p>{{billingForm.value.city}}, {{billingForm.value.region}}</p>
                <p>{{billingForm.value.country}}, {{billingForm.value.postal}}</p>
              </section>
            </section>
          </div>
          <hr />

          <h2 class="stripe-form">Payment Details</h2>


          <form class="stripe-form">

           

            <div #cardElement>


            </div>

            <p>{{cardErrors}}</p>

          </form>
        </div>
      
      <div class="medium-6 columns">

        <section class="billing-total">
          <span class="total">Total:</span>
          <span class="price">CA${{chosenPlan.price}}</span><span class="repeat-text">{{chosenPlan.info}}</span>
          <button mat-raised-button color="primary" type="submit" class="confirm-btn" [disabled]="disableSubmit" (click)="submitPayment()">Complete
            Payment</button>
        </section>
        <section class="billing-legal">
          A recurring yearly/monthly charge of CA${{chosenPlan.price}} will automatically apply and start on
          {{date | date:'MMM d'}}. You may cancel at
          any time in your payment settings. By clicking ‘Complete Payment’, you agree to our Terms of Service and
          authorize
          this recurring charge.
        </section>
      </div>
    </div>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="billing-form" *ngIf="formState == 'error'">
    <div class="medium-8 columns">
      <h2>There was an issue completing your purchase.</h2>
      <p>We're sorry, we were unable to complete the transaction with the information provided. If you continue to have
        issues, please contact our support team at <a href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a></p>
      <button mat-button (click)="reset()">Back</button>
    </div>
  </div>
  <div class="billing-form success" *ngIf="formState == 'success'">
    <div class="medium-6 columns">
      <div class="row">
        <h2>Success, your purchase is complete!</h2>
        <p>A receipt has been emailed to {{billingForm.value.email}}. This subscription will be charged <ng-container
            *ngIf="billingForm.value.plan == 'Annual Plan'">annually</ng-container>
          <ng-container *ngIf="billingForm.value.plan != 'Annual Plan'">monthly</ng-container>, unless you
          choose to cancel your plan in your payment settings. Your next payment will come out on {{dateString}}.
        </p>
        <button mat-button color="primary" (click)="openCaseLibrary()">Explore Case Library</button>
      </div>
      <br>
      <div class="row">
        <h2>{{chosenPlan.value}}</h2>
        <section class="plan-type">
        </section>
      </div>
      <br />
      <div class="row">
        <span class="total">Total:</span>
        <section class="billing-total success">
          <span class="price">CA${{chosenPlan.price}}</span><span class="repeat-text">{{chosenPlan.info}}</span>
        </section>
        <section class="billing-legal">
          A recurring yearly/monthly charge of CA${{chosenPlan.price}} will automatically apply and start on
          {{date | date:'MMM d'}}. You may cancel at
          any time in your payment settings. By clicking ‘Complete Payment’, you agree to our Terms of Service and
          authorize
          this recurring charge.
          <button mat-button color="accent" (click)="openReceipt()">View Receipt</button>
        </section>
      </div>
    </div>

  </div>
  <ngx-loading [show]="loadingAndSaving"></ngx-loading>

</ng-container>
<script src="https://js.stripe.com/v3/"></script>
