<mat-dialog-content class="accent-color dialogue-content">
  <div class="pop-up mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
              <p style="text-align:center; display: block; margin: 0 auto;">
                  Please confirm your decision.
                </p>
          </div>
        </div>
        <div class="actions">
          <button mat-raised-button color="primary" [mat-dialog-close]="true" tabindex="2" class="action mat-button mat-raised-button mat-primary">Confirm</button>
          <button mat-raised-button color="warn" [mat-dialog-close]="false" tabindex="1" class="action cancel mat-button mat-raised-button mat-primary">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>