import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable()
export class LoadService {
  public status: Subject<boolean> = new Subject();
  public progress = 0;
  private _active = false;

  public loadAudioStatus: Subject<boolean> = new Subject();

  getloadAudioStatus(): Observable<boolean> {
    return this.loadAudioStatus.asObservable();
  }
  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public start(): void {
    this.active = true;
  }

  public stop(): void {
    this.active = false;
  }
}
