import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckService {
  private http: HttpClient;
  private isApiDown = false;
  private url: string = environment.apiUrl.concat('/');

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  serverHealthFlag() {
    return this.http.get(this.url).pipe(
      tap(
        () => {
          this.isApiDown = false;
        },
        () => {
          this.isApiDown = true;
        }
      )
    );
  }

  isServerDown() {
    return this.isApiDown;
  }
}
