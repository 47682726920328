import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { RegisterAccService } from '../../../_services/register-acc.service';
import { CustomValidators } from '../../../custom-validators';
import { InstitutionService } from '../../services/institution.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-invite-faculty-dialog',
  templateUrl: './invite-faculty-dialog.component.html',
  styleUrls: ['./invite-faculty-dialog.component.scss'],
})
export class InviteFacultyDialogComponent {
  public loading = false;
  public roles = ['admin', 'professor'];
  public institutionLimitReached = false;
  public institutionId: string;
  public facultyForms = new FormArray([this.createFacultyFormGroup()]);
  public exceedLimitError = false;

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly registerService: RegisterAccService,
    private readonly snack: MatSnackBar,
    private userService: UserService,
    private readonly matDialogRef: MatDialogRef<InviteFacultyDialogComponent>
  ) {}

  ngOnInit() {
    this.institutionId = this.userService.user.institutionId;
  }

  addFacultyForm() {
    this.facultyForms.push(this.createFacultyFormGroup());
  }

  removeForm(index: number) {
    this.facultyForms.removeAt(index);
  }

  onSubmit() {
    this.forceValidate();
    if (!this.facultyForms.valid) {
      this.scrollToInvalidControl();
      return;
    }

    this.loading = true;
    this.checkEmails()
      .pipe(
        tap((formValid) => !formValid && (this.loading = false)),
        filter((formValid) => formValid),
        mergeMap(() => this.submitInvitation())
      )
      .subscribe(
        () => {
          this.loading = false;

          this.snack.open(`Invitations have been sent to ${this.facultyForms.value.length} users`, 'Done', {
            duration: 3000,
          });
          this.matDialogRef.close({ submitted: true });
        },
        (error: HttpErrorResponse) => {
          this.loading = false;

          if (error.status === 409) {
            this.showExceedLimitError();
          } else {
            this.snack.open('Error sending invitations. Please try again.', 'Dismiss', {
              duration: 3000,
              panelClass: 'text-danger',
            });
          }
        }
      );
  }

  private createFacultyFormGroup(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
    });
  }

  private forceValidate() {
    this.facultyForms.controls.forEach((control) => {
      const controls = (control as FormGroup).controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        controls[key].updateValueAndValidity();
      });
    });
  }

  private scrollToInvalidControl() {
    const index = this.facultyForms.controls.findIndex((control) => control.invalid);
    const el = document.getElementById(`facultyForm_${index}`);
    if (el) {
      el.scrollIntoView();
    }
  }

  private checkEmails(): Observable<boolean> {
    const emails = this.facultyForms.value.map((val) => val.email);
    return this.registerService.checkEmails(emails, this.institutionId).pipe(
      tap(({ existingInstructors, institutionLimitReached }) => {
        this.institutionLimitReached = institutionLimitReached;
        this.facultyForms.controls.forEach((control) => {
          control
            .get('email')
            .setValidators([Validators.required, Validators.email, CustomValidators.notIn(existingInstructors)]);
        });
        this.forceValidate();
        this.scrollToInvalidControl();
      }),
      map(() => {
        return this.facultyForms.valid && !this.institutionLimitReached;
      })
    );
  }

  private submitInvitation(): Observable<any> {
    return this.institutionService.inviteInstitutionMembers(this.facultyForms.value);
  }

  handleUploadCsv(rows: any[]) {
    const valid = rows.every((row) => !!row.email && !!row.firstName && !!row.lastName && !!row.role);

    if (!valid) {
      this.snack.open('Your CSV is not in the correct format', 'Dismiss', {
        panelClass: 'text-danger',
      });

      return;
    }

    rows.forEach((row, index) => {
      if (index > this.facultyForms.length - 1) {
        this.addFacultyForm();
      }
      const control = this.facultyForms.at(index);
      control.setValue({
        email: row.email,
        firstName: row.firstName,
        lastName: row.lastName,
        role: row.role.toLowerCase(),
      });
    });
  }

  private showExceedLimitError() {
    this.exceedLimitError = true;
    setTimeout(() => {
      this.exceedLimitError = false;
    }, 5000);
  }
}
