import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetCaseId } from 'app/actions/checkpoint.action';
import { TriageVideoComponent } from 'app/triage-video/triage-video.component';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import {
  Assignment,
  ChiefComplaints,
  EpaAnswerPerSession,
  LabTest,
  Patient,
  PatientAnimation,
  PatientStudent,
  PhysicalExam,
  Question,
  RefreshData,
  StandardRecording,
  User,
} from '../_models/index';
import { UserService } from './user.service';

export interface MyAssignmentID {
  assignment_id: string;
  case_id: string;
  hot_set: boolean;
  session_id: string;
  next_checkpoint: string;
  refURL: string;
  mistake_counter: number;
  instance_id: string;
  url: string;
  pageTitle: string;
  pageBlurb: string;
  instance_name: string;
  hardFeedback: boolean;
  sessionCompleted: string;
  exam_mode: boolean;
  grades_ass: string;
  getFeedback: string;
  patient_name: string;
  skillset: string;
  tosToken: string;
  username: string;
  permissions: string;
  membership_status: string;
  plan_type: number;
  stripeKey: string;
  epa: boolean;
}

@Injectable()
export class FetchAssignmentDataService {
  sharingPID: MyAssignmentID = {
    case_id: 'patientidnotsetyet',
    assignment_id: 'assignmentidnotsetyet',
    session_id: 'sessionidnotsetyet',
    next_checkpoint: 'checkpointnotsetyet',
    refURL: 'refURLnotsetyet',
    mistake_counter: 0,
    instance_id: 'instance_idnotsetyet',
    url: 'urlnotsetyet',
    pageTitle: 'page title not set yet',
    pageBlurb: 'page title not set yet',
    instance_name: 'page title not set yet',
    hardFeedback: false,
    sessionCompleted: 'not set yet',
    hot_set: false,
    exam_mode: false,
    grades_ass: 'not set yet',
    getFeedback: 'not set yet',
    patient_name: '',
    skillset: 'not set yet',
    tosToken: 'not set yet',
    username: 'not set yet',
    permissions: 'not set yet',
    membership_status: 'free',
    plan_type: 0,
    stripeKey: 'not set yet',
    epa: true,
  };

  public currentUser;
  public username;
  private urlStudentAssignmentList = '/api/v1/assignments/';
  private urlStudentCase = '/api/v1/patients/search';
  private urlAssignmentFeedback = '/api/v1/feedback';
  private urlEPAFeedback = '/api/v1/epapayload';
  private urlGradesOverview = '/api/v1/gradesoverview';
  private urlGradesDetails = '/api/v1/gradesindividual';
  private urlDecryptTest = '/api/v1/testDecrypt';
  private urlEncryptTest = '/api/v1/testEncrypt';
  private urlSkillSet = '/api/v1/auth/skillset?student_email=';
  private resetPassword2 = '/api/v1/reset/sq?token=';
  private urlActivateEmail = '/api/v1/auth/register/confirm?token=';
  private urlCaseLibraryList = '/api/v1/caseLibrary';
  private v2SimulationsUrl = '/api/v2/simulation/';
  private urlNewsFeed = '/api/v1/newsfeed';
  private urlFavourites = '/api/v1/faves';
  private urlLatestFeedback = '/api/v1/latestfeedback';
  private urlPatientChart = '/api/v1/sessionpatientchart';
  private urlOnboarding = '/api/v1/onboarding';
  private urlStandardChart = '/api/v1/case/';
  keys: String[];

  constructor(
    private http: HttpClient,
    private sg: SimpleGlobal,
    private router: Router,
    private store: Store,
    private userService: UserService
  ) {
    this.sg['url'] = environment.apiUrl;
    this.sg['stripeKey'] = environment.stripeKey;
    this.urlStudentAssignmentList = this.sg['url'].concat(this.urlStudentAssignmentList);
    this.urlStudentCase = this.sg['url'].concat(this.urlStudentCase);
    this.urlAssignmentFeedback = this.sg['url'].concat(this.urlAssignmentFeedback);
    this.urlEPAFeedback = this.sg['url'].concat(this.urlEPAFeedback);
    this.urlGradesOverview = this.sg['url'].concat(this.urlGradesOverview);
    this.urlGradesDetails = this.sg['url'].concat(this.urlGradesDetails);
    this.urlDecryptTest = this.sg['url'].concat(this.urlDecryptTest);
    this.urlEncryptTest = this.sg['url'].concat(this.urlEncryptTest);
    this.urlSkillSet = this.sg['url'].concat(this.urlSkillSet);
    this.resetPassword2 = this.sg['url'].concat(this.resetPassword2);
    this.urlActivateEmail = this.sg['url'].concat(this.urlActivateEmail);
    this.urlCaseLibraryList = this.sg['url'].concat(this.urlCaseLibraryList);
    this.urlNewsFeed = this.sg['url'].concat(this.urlNewsFeed);
    this.urlFavourites = this.sg['url'].concat(this.urlFavourites);
    this.urlLatestFeedback = this.sg['url'].concat(this.urlLatestFeedback);
    this.urlPatientChart = this.sg['url'].concat(this.urlPatientChart);
    this.urlOnboarding = this.sg['url'].concat(this.urlOnboarding);
    this.urlStandardChart = this.sg['url'].concat(this.urlStandardChart);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.v2SimulationsUrl = this.sg['url'].concat(this.v2SimulationsUrl);
  }

  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  getCaseLibrary(caseType = 'LEGACY'): Observable<any> {
    let url;
    if (caseType === 'SIMULATION') {
      url = this.v2SimulationsUrl.concat('cases');
    } else {
      url = this.urlCaseLibraryList;
    }
    const cases$ = this.http.get(url).pipe(map(mapGeneric2), catchError(this.handleError));
    return cases$;
  }

  getNursingCaseLibrary(): Observable<any> {
    const url = this.urlCaseLibraryList.concat('?nursing=true');
    const cases$ = this.http.get(url).pipe(map(mapGeneric2), catchError(this.handleError));
    return cases$;
  }

  getVideoModuleStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=videoModule&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const data = this.http.get(url).pipe(map(mapAssignments), catchError(this.handleError));
    return data;
  }

  // List of assignments based on the logged in student account
  getAssignmentListStudent(): Observable<Assignment[]> {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = this.urlStudentAssignmentList.concat('?student_email=' + encodeURIComponent(user.email));
        const ass$ = this.http.get(url).pipe(map(mapAssignments), catchError(this.handleError));
        return ass$;
      })
    );
  }

  // List of assignments based on the logged in teacher account
  getAssignmentListTeacher(): Observable<Assignment[]> {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = this.urlStudentAssignmentList.concat('?teacher_email' + encodeURIComponent(user.email));
        const ass$ = this.http.get(url).pipe(map(mapAssignments), catchError(this.handleError));
        return ass$;
      })
    );
  }

  getNewsFeed() {
    const news$ = this.http.get(this.urlNewsFeed).pipe(map(mapGeneric2), catchError(this.handleError));
    return news$;
  }

  getLastCaseFeedback() {
    const lastcase$ = this.http.get(this.urlLatestFeedback).pipe(map(mapGeneric), catchError(this.handleError));
    return lastcase$;
  }

  getFaves() {
    const favo$ = this.http.get(this.urlFavourites).pipe(map(mapGeneric), catchError(this.handleError));
    return favo$;
  }

  getAfterFileCheckpoint(comp): Observable<any> {
    const url = this.sg['url'].concat(
      '/api/v1/afterFileCheckpoint?session_id=' +
        this.sg['session_id'] +
        '&instance=' +
        this.sg['instance_id'] +
        '&component=' +
        comp
    );
    const navNext$ = this.http.get(url).pipe(map(toNextPage), catchError(this.handleError));
    return navNext$;
  }

  // Called in case of a refresh or logout/login to get info on an ongoing session
  getRefreshData(): Observable<RefreshData> {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = environment.apiUrl.concat('/api/v1/refreshsession?student_email=' + encodeURIComponent(user.email));
        const data$ = this.http.get(url).pipe(map(mapRefreshData), catchError(this.handleError));
        return data$;
      })
    );
  }

  getSkillSet() {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = this.urlSkillSet.concat(encodeURIComponent(user.email));
        const skillSet$ = this.http.get(url).pipe(map(toRes), catchError(this.handleError));
        return skillSet$;
      })
    );
  }

  getEpaAnswer(caseid: string, sessionid: string) {
    const url = this.sg['url'].concat('/api/v1/case/' + caseid + '/epa-answers?sessionId=' + sessionid);
    const answers$ = this.http.get(url).pipe(map(toRes), catchError(this.handleError));
    return answers$;
  }

  postEpaAnswers(caseid: string, sessionid: string, epaanswer: EpaAnswerPerSession) {
    const body = epaanswer;
    const url = this.sg['url'].concat('/api/v1/case/' + caseid + '/epa-answers?sessionId=' + sessionid);
    return this.http.post(url, body).pipe(map(toRes), catchError(this.handleError));
  }

  getCheckPoints(caseId?: string): Observable<any> {
    this.store.dispatch([new SetCaseId(this.sg['case_id'] || caseId)]);
    const url = environment.apiUrl.concat(
      '/api/v1/patients/search?case_id=' + (this.sg['case_id'] || caseId) + '&component=checkpoints'
    );
    return this.http.get(url).pipe(map(mapRefreshData), catchError(this.handleError));
  }

  getSecretQResetPassword(token) {
    const url = this.resetPassword2.concat(token);
    const res$ = this.http.get(url).pipe(map(toRes), catchError(this.handleError));
    return res$;
  }
  getEmailActivationToken(token) {
    const url = this.urlActivateEmail.concat(token);
    const res$ = this.http.get(url).pipe(map(toRes), catchError(this.handleError));
    return res$;
  }

  getOnboarding() {
    const url = this.urlOnboarding;
    const onboarding$ = this.http.get(url).pipe(map(mapGeneric), catchError(this.handleError));
    return onboarding$;
  }

  // Gets the queried patient/case's info - demographics, animations, history
  getStudentCaseDemo(): Observable<Patient> {
    const url = this.urlStudentCase.concat('?component=demographics&case_id=' + this.sg['case_id']);
    const patientdem$ = this.http.get(url).pipe(map(toPatientDemo), catchError(this.handleError));
    return patientdem$;
  }

  // Method to get a specific case assigned to the student
  getStudentCaseHistory(): Observable<PatientStudent> {
    // let url = this.urlStudentCase.concat("historyTaking?case_id=" + this.sg['case_id']);
    const url = this.urlStudentCase.concat(
      '?component=historyTaking&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const patientstu$ = this.http.get(url).pipe(map(toPatient), catchError(this.handleError));
    return patientstu$;
  }

  getStudentPatientChartHistory(sessionid?: string, closeSession?: boolean): Observable<any> {
    let url;
    url = this.urlPatientChart.concat('?session_id=' + sessionid);
    url += '&flag=true';
    const patientchart$ = this.http.get(url).pipe(map(toNextPage), catchError(this.handleError));
    return patientchart$;
  }

  getStandardChart(caseid: string): Observable<any> {
    const url = this.urlStandardChart.concat(caseid + '/standard-chart');
    const chart$ = this.http.get(url).pipe(map(toRes), catchError(this.handleError));
    return chart$;
  }

  getStudentInquiry(): Observable<PatientStudent> {
    const url = this.urlStudentCase.concat(
      '?component=inquiry&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const patientstu$ = this.http.get(url).pipe(map(toInquiry), catchError(this.handleError));
    return patientstu$;
  }

  getPhysExamStudent(): Observable<PhysicalExam> {
    const url = this.urlStudentCase.concat(
      '?component=physicalExam&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const phy$ = this.http.get(url).pipe(map(toPhysical), catchError(this.handleError));
    return phy$;
  }

  getPhysExamStudentInitial(): Observable<PhysicalExam> {
    const url = this.urlStudentCase.concat(
      '?component=physicalExam&instance=' + 'initialAssessment' + '&case_id=' + this.sg['case_id']
    );

    const phy$ = this.http.get(url).pipe(map(toPhysical), catchError(this.handleError));
    return phy$;
  }

  getlabTestStudent(): Observable<LabTest> {
    const url = this.urlStudentCase.concat(
      '?component=labTests&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const lab$ = this.http.get(url).pipe(map(toLab), catchError(this.handleError));
    return lab$;
  }

  getfinalDiagnosisStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=finalDiagnosis&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const diag$ = this.http.get(url).pipe(map(toDiagnosis), catchError(this.handleError));

    return diag$;
  }

  getprovisionalDiagnosisStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=provisionalDiagnosis&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const diag$ = this.http.get(url).pipe(map(toDiagnosis), catchError(this.handleError));

    return diag$;
  }
  getPerformSurgerySteps(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=surgery&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const ssteps$ = this.http.get(url).pipe(map(toSurgSteps), catchError(this.handleError));

    return ssteps$;
  }

  getSurgeryPlanStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=surgeryPlan&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const surgplan$ = this.http.get(url).pipe(map(toSurgPlan), catchError(this.handleError));
    return surgplan$;
  }

  getGeneralOrderStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=generalOrder&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const genorder$ = this.http.get(url).pipe(map(toGenOrder), catchError(this.handleError));
    return genorder$;
  }
  getSendHomeOrderStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=sendHomeOrders&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const sendhomeorder$ = this.http.get(url).pipe(map(toGenOrder), catchError(this.handleError));
    return sendhomeorder$;
  }
  getManagementPlanStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=managementPlan&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const mangt$ = this.http.get(url).pipe(map(toMangt), catchError(this.handleError));

    return mangt$;
  }
  getHospitalOfficeManagementPlanStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=hospitalOfficeManagement&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const mangt$ = this.http.get(url).pipe(map(toMangt), catchError(this.handleError));

    return mangt$;
  }
  getDelayDataStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=observationDelay&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const delay$ = this.http.get(url).pipe(map(toProc), catchError(this.handleError));

    return delay$;
  }
  getPatientManagementStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=patientManagement&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const mangt$ = this.http.get(url).pipe(map(toMangt), catchError(this.handleError));

    return mangt$;
  }
  getInvasiveManagementStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=invasiveManagement&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const mangt$ = this.http.get(url).pipe(map(toMangt), catchError(this.handleError));

    return mangt$;
  }

  getHospitalSettingStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=hospitalSetting&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const hosp$ = this.http.get(url).pipe(map(toHosp), catchError(this.handleError));

    return hosp$;
  }

  getComplicationsStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=complications&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    return this.http.get(url).pipe(map(toComp), catchError(this.handleError));
  }

  getAncillaryCareStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=ancillaryCare&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const hosp$ = this.http.get(url).pipe(map(toAnc), catchError(this.handleError));

    return hosp$;
  }

  getSelectedSurgeryListStudent(): Observable<any> {
    const url = this.sg['url'].concat(
      '/api/v1/selectvideo?session_id=' +
        this.sg['session_id'] +
        '&instance=' +
        this.sg['instance_id'] +
        '&component=surgery'
    );
    const list$ = this.http.get(url).pipe(map(toSurgeryList), catchError(this.handleError));

    return list$;
  }

  getConsultationStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=consultations&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const hosp$ = this.http.get(url).pipe(map(toCons), catchError(this.handleError));

    return hosp$;
  }
  getInputStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=input&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const inp$ = this.http.get(url).pipe(map(toInput), catchError(this.handleError));

    return inp$;
  }

  getProceduresStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=procedures&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const hosp$ = this.http.get(url).pipe(map(toProc), catchError(this.handleError));

    return hosp$;
  }

  getSendHomeRecommendationStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=recommendations&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const rec$ = this.http.get(url).pipe(map(toSendHomeRec), catchError(this.handleError));

    return rec$;
  }

  getSendHomeReferralsStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=referrals&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const rec$ = this.http.get(url).pipe(map(toSendHomeRef), catchError(this.handleError));

    return rec$;
  }

  getCounsellingDataStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=counselling&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    const rec$ = this.http.get(url).pipe(map(toSendHomeCounsel), catchError(this.handleError));

    return rec$;
  }

  getMedicationsDataStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=medications&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const rec$ = this.http.get(url).pipe(map(toMeds), catchError(this.handleError));
    return rec$;
  }
  getMedicationsDataStudentSendHome(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=sendHomeMedications&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );
    const rec$ = this.http.get(url).pipe(map(tosendHomeMeds), catchError(this.handleError));
    return rec$;
  }

  getSendHomeFollowupStudent(): Observable<any> {
    const url = this.urlStudentCase.concat(
      '?component=followUp&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']
    );

    return this.http.get(url).pipe(map(toFollowUp), catchError(this.handleError));
  }
  getStandardRecording(caseid: string): Observable<StandardRecording[]> {
    const geturl = this.sg['url'].concat('/api/v1/case/' + caseid + '/standard-recordings');
    return this.http.get<StandardRecording[]>(geturl);
  }

  getSelfAssessmentQuestionforCom(caseid: string): Observable<any> {
    const geturl = this.sg['url'].concat('/api/v1/case/' + caseid + '/self-assessments');
    return this.http.get(geturl);
  }

  getSelfAssessmentQuestionforChart(caseid: string): Observable<any> {
    const geturl = this.sg['url'].concat('/api/v1/case/' + caseid + '/self-assessments');
    return this.http.get(geturl);
  }

  getAssignmentFeedback(sessionId): Observable<any> {
    const url = this.urlAssignmentFeedback.concat('?session_id=' + sessionId);
    return this.http.get(url).pipe(map(toFeedback), catchError(this.handleError));
  }

  getAssignmentFeedbackDetails(componentName): Observable<any> {
    const url = this.urlAssignmentFeedback.concat('?session_id=' + this.sg['getFeedback']);

    return this.http.get(url).pipe(map(toFeedback), catchError(this.handleError));
  }

  getEPAFeedback(sessionId): Observable<any> {
    const url = this.urlEPAFeedback.concat('?session_id=5be08fa14b8274000f65c933');

    return this.http.get(url).pipe(map(toFeedback), catchError(this.handleError));
  }

  getGradesOverview(): Observable<any> {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = this.urlGradesOverview.concat('?student_email=' + encodeURIComponent(user.email));

        return this.http.get(url).pipe(map(toFeedback), catchError(this.handleError));
      })
    );
  }

  getGradesDetailed(assId): Observable<any> {
    return this.userService.userChange.pipe(
      filter(Boolean),
      take(1),
      mergeMap((user: User) => {
        const url = this.urlGradesDetails.concat(
          '?student_email=' + encodeURIComponent(user.email) + '&case_id=' + assId
        );

        return this.http.get(url).pipe(map(toFeedback), catchError(this.handleError));
      })
    );
  }
}

function toDecryptthis(response: any): any {
  return response.message;
}

function mapAssignments(response: any): Assignment[] {
  return response.message;
}
function mapGeneric(response: any): any {
  return response.message;
}
function mapGeneric2(response: any): any {
  return response;
}

function toNextPage(response: any): any {
  return response.message;
}

function toAssignments(r: any): Assignment {
  const assignment = <Assignment>{
    case_id: r.case_id._id,
    student_email: r.student_id.email,
    creator_email: r.creator_id._email,
    patient_name: r.case_id.demographics.patient_name,
    student_firstname: r.student_id.firstName,
    student_lastname: r.student_id.lastName,
    creator_firstname: r.creator_id.firstName,
    creator_lastname: r.creator_id.lastName,

    due_date: r.due_date,
    assignment_id: r._id,
    time_allowed: r.time_allowed,
  };
  return assignment;
}

// function to take all patients and individually map each patient
function mapPatients(response: any): PatientStudent {
  return response.message.map(toAnimations);
}

// function to map all the fields for each patient
function toPatient(response: any): PatientStudent {
  const patient = <PatientStudent>{
    history: mapCComps(response),
    bodyAnimations: toAnimations(response.message.bodyAnimations),
    mouthAnimations: toAnimations(response.message.mouthAnimations),
  };
  return patient;
}

// function to map values for bodyAnimations and mouthAnimations
function toAnimations(r: any): PatientAnimation {
  let pa = null;
  if (r) {
    pa = <PatientAnimation>{
      framerate: r.framerate,
      width: r.width,
      height: r.height,
      count: r.count,
      regX: r.regX,
      regY: r.regY,
      animations: r.animations,
    };
  }
  return pa;
}

// function to map all Chief Complaints
function mapCComps(response: any): ChiefComplaints {
  const keys = Object.keys(response.message.history);

  let count = 0;
  const ccomparray = <ChiefComplaints>[];
  for (const entry of keys) {
    ccomparray[count] = mapCComp(entry, response.message.history[entry]);

    count++;
  }
  return ccomparray;
}

// function to map respective questions to each Chief Complaint
function mapCComp(entry: string, r: any): ChiefComplaints {
  const cc = <ChiefComplaints>{
    ccomplaint: entry,
    quests: r.map(toQuestion),
  };

  return cc;
}
function toInquiry(response: any): PatientStudent {
  const patient = <PatientStudent>{
    inquiry: response.message.inquiry.map(toQuestion),
    bodyAnimations: toAnimations(response.message.bodyAnimations),
    mouthAnimations: toAnimations(response.message.mouthAnimations),
  };

  return patient;
}
// Map each question and answer
function toQuestion(r: any): Question {
  const qa = <Question>{
    Q: r.Q,
    A: r.A,
    score: r.score,
    id: r.identifier,
  };

  return qa;
}

function toVideoUrl(response: any) {
  return response.message;
}

function toAnc(response: any) {
  return response.message.ancillaryCare;
}

function toFollowUp(response: any) {
  return response.message.followUp;
}
function toPatientDemo(response: any): Patient {
  const patient = <Patient>{
    last_modified: response.message.last_modified,
    patient_age: response.message.patient_age,
    patient_eyes: response.message.patient_eyes,
    patient_gender: response.message.patient_gender,
    patient_hair: response.message.patient_hair,
    patient_height: response.message.patient_height,
    patient_name: response.message.patient_name,
    patient_race: response.message.patient_race,
    patient_weight: response.message.patient_weight,
    patient_number: response.message.patient_number,
  };

  return patient;
}
function toDiagnosis(response: any): any {
  return response.message.diagnosis;
}

function toTriage(response: any): any {
  return response.message.triage;
}

function toHosp(response: any): any {
  return response.message.hospitalSetting;
}
function toSurgSteps(response: any): any {
  return response.message.surgerySteps;
}
function toSurgPlan(response: any): any {
  return response.message.operations;
}

function toMangt(response: any): any {
  return response.message.management;
}

function toRes(response: any): any {
  return response;
}
/*
   function toMangtPlan(response: any): any{
    return response.message.managementPlan;

   }
   */
function toMeds(response: any): any {
  return response.message;
}
function tosendHomeMeds(response: any): any {
  return response.message;
}

function toComp(response: any): any {
  return response.message.complications;
}

function toCons(response: any): any {
  return response.message.consultations;
}

function toProc(response: any): any {
  return response.message;
}

function toSendHomeRec(response: any): any {
  return response.message.sendHomeRecommendations;
}

function toSendHomeRef(response: any): any {
  return response.message.referrals;
}

function toSendHomeCounsel(response: any): any {
  return response.message.sendHomeCounselling;
}

function toInput(response: any): any {
  return response.message;
}
function toGenOrder(response: any): any {
  return response.message;
}
function toPhysical(response: any): PhysicalExam {
  const physical = <PhysicalExam>{
    vitals: response.message.vitals,
    custom_images: response.message.custom_images,
    default_images: response.message.default_images,
    default_sounds: response.message.default_sounds,
    patient_sounds: response.message.patient_sounds,
    views: response.message.views,
    patientImageSlice: response.message.patientImageSlice,
    palpation: response.message.palpation,
    score: response.message.score,
    height: response.message.patient_height,
    weight: response.message.patient_weight,
  };

  return physical;
}

function toSurgeryList(response: any): any {
  return response.message;
}
function toFeedback(response: any): any {
  return response.message;
}

function toLab(response: any): LabTest {
  const lab = <LabTest>{
    case_id: response.case_id,
    stage: response.stage,
    score: response.score,
    Blood_CBC: response.Blood_CBC,
    Blood_ElectrolytePanel: response.Blood_ElectrolytePanel,
    Blood_KidneyFunctionTests: response.Blood_KidneyFunctionTests,
    Blood_LiverFunctionTests: response.Blood_LiverFunctionTests,
    Blood_CoagulationTests: response.Blood_CoagulationTests,
    Blood_LipidProfile: response.Blood_LipidProfile,
    Blood_ABG: response.Blood_ABG,
    Blood_BloodTyping: response.Blood_BloodTyping,
    Blood_AcutePhaseReactant: response.Blood_AcutePhaseReactant,
    Blood_IronProfile: response.Blood_IronProfile,
    Blood_Enzymes: response.Blood_Enzymes,
    Blood_RheumatologyProfile: response.Blood_RheumatologyProfile,
    Blood_ThyroidFunctionTests: response.Blood_ThyroidFunctionTests,
    Blood_Hormones: response.Blood_Hormones,
    Blood_GlucosePanel: response.Blood_GlucosePanel,
    Blood_Serology: response.Blood_Serology,
    Blood_Toxicology: response.Blood_Toxicology,
    Blood_Other: response.Blood_Other,
    BloodBiomarker: response.BloodBiomarker,
    Urine_UrineAnalysis: response.Urine_UrineAnalysis,
    Urine_Other: response.Urine_Other,
    Stool: response.Stool,
    Skin_TuberculinTest: response.Skin_TuberculinTest,
    BloodCulture: response.BloodCulture,
    BloodSmearCulture: response.BloodSmearCulture,
    UrineCultureSensitivity: response.UrineCultureSensitivity,
    CerebrospinalFluid: response.CerebrospinalFluid,
    CerebrospinalFluidSmear: response.CerebrospinalFluidSmear,
    CerebrospinalFluidCulture: response.CerebrospinalFluidCulture,
    BronchoalveolarLavageSwab: response.BronchoalveolarLavageSwab,
    NasopharyngealSwab: response.NasopharyngealSwab,
    Procalcitonon: response.Procalcitonon,
    CTScan_Head: response.CTScan_Head,
    CTScan_Orbit: response.CTScan_Orbit,
    CTScan_Neck: response.CTScan_Neck,
    CTScan_CervicalSpine: response.CTScan_CervicalSpine,
    CTScan_ThoracicSpine: response.CTScan_ThoracicSpine,
    CTScan_LumbosacralSpine: response.CTScan_LumbosacralSpine,
    CTScan_Chest: response.CTScan_Chest,
    CTScan_Abdomen: response.CTScan_Abdomen,
    CTScan_Abdominopelvic: response.CTScan_Abdominopelvic,
    CTScan_Pelvis: response.CTScan_Pelvis,
    CTScan_UpperExtremity: response.CTScan_UpperExtremity,
    CTScan_LowerExtremity: response.CTScan_LowerExtremity,
    CTAngiogram_Brain: response.CTAngiogram_Brain,
    CTAngiogram_Neck: response.CTAngiogram_Neck,
    CTAngiogram_Heart: response.CTAngiogram_Heart,
    CTAngiogram_Lungs: response.CTAngiogram_Lungs,
    CTAngiogram_Abdomen: response.CTAngiogram_Abdomen,
    CTAngiogram_Pelvis: response.CTAngiogram_Pelvis,
    CTAngiogram_UpperExtremity: response.CTAngiogram_UpperExtremity,
    CTAngiogram_Lowerextremity: response.CTAngiogram_Lowerextremity,
    MRI_Brain: response.MRI_Brain,
    MRI_Orbit: response.MRI_Orbit,
    MRI_Neck: response.MRI_Neck,
    MRI_CervicalSpine: response.MRI_CervicalSpine,
    MRI_ThoracicSpine: response.MRI_ThoracicSpine,
    MRI_LumbosacralSpine: response.MRI_LumbosacralSpine,
    MRI_Chest: response.MRI_Chest,
    MRI_Breast: response.MRI_Breast,
    MRI_Abdomen: response.MRI_Abdomen,
    MRI_Pelvis: response.MRI_Pelvis,
    MRI_UpperExtremity: response.MRI_UpperExtremity,
    MRI_LowerExtremity: response.MRI_LowerExtremity,
    MRI_MRCP: response.MRI_MRCP,
    MRA_Brain: response.MRA_Brain,
    MRA_Neck: response.MRA_Neck,
    MRA_Heart: response.MRA_Heart,
    MRA_Lungs: response.MRA_Lungs,
    MRA_Abdomen: response.MRA_Abdomen,
    MRA_Pelvis: response.MRA_Pelvis,
    MRA_Kidneys: response.MRA_Kidneys,
    MRA_UpperExtremity: response.MRA_UpperExtremity,
    MRA_LowerExtremity: response.MRA_LowerExtremity,
    Xray_Skull: response.Xray_Skull,
    Xray_Sinus: response.Xray_Sinus,
    Xray_Dental: response.Xray_Dental,
    Xray_Neck: response.Xray_Neck,
    Xray_Chest: response.Xray_Chest,
    Xray_Abdominal: response.Xray_Abdominal,
    Xray_ThoracicSpine: response.Xray_ThoracicSpine,
    Xray_LumbosacralSpine: response.Xray_LumbosacralSpine,
    Xray_Pelvis: response.Xray_Pelvis,
    Xray_JointsShoulder: response.Xray_JointsShoulder,
    Xray_JointsElbow: response.Xray_JointsElbow,
    Xray_JointsWrist: response.Xray_JointsWrist,
    Xray_JointsHip: response.Xray_JointsHip,
    Xray_JointsKnee: response.Xray_JointsKnee,
    Xray_JointsAnkle: response.Xray_JointsAnkle,
    Xray_ExtremitiesArm: response.Xray_ExtremitiesArm,
    Xray_ExtremitiesForearm: response.Xray_ExtremitiesForearm,
    Xray_ExtremitiesHand: response.Xray_ExtremitiesHand,
    Xray_ExtremitiesThigh: response.Xray_ExtremitiesThigh,
    Xray_ExtremitiesCalf: response.Xray_ExtremitiesCalf,
    Xray_ExtremitiesFoot: response.Xray_ExtremitiesFoot,
    Xray_BonesClavicle: response.Xray_BonesClavicle,
    Xray_BonesHumerus: response.Xray_BonesHumerus,
    Xray_BonesRadius: response.Xray_BonesRadius,
    Xray_BonesUlna: response.Xray_BonesUlna,
    Xray_BonesFemur: response.Xray_BonesFemur,
    Xray_BonesTibia: response.Xray_BonesTibia,
    Xray_BonesFibula: response.Xray_BonesFibula,
    Ultrasound_NeckVessels: response.Ultrasound_NeckVessels,
    Ultrasound_UpperVessels: response.Ultrasound_UpperVessels,
    Ultrasound_LowerVessels: response.Ultrasound_LowerVessels,
    Ultrasound_Eye: response.Ultrasound_Eye,
    Ultrasound_Thyroid: response.Ultrasound_Thyroid,
    Ultrasound_Breast: response.Ultrasound_Breast,
    Ultrasound_Abdomen: response.Ultrasound_Abdomen,
    Ultrasound_Pelvis: response.Ultrasound_Pelvis,
    Ultrasound_Kidneys: response.Ultrasound_Kidneys,
    Ultrasound_Pregnancy: response.Ultrasound_Pregnancy,
    Ultrasound_Transvaginal: response.Ultrasound_Transvaginal,
    Biopsy_UltrasoundGuidedBreast: response.Biopsy_UltrasoundGuidedBreast,
    Breast_Pathalogy: response.Breast_Pathalogy,
    Echocardiography_Transthoracic: response.Echocardiography_Transthoracic,
    Echocardiography_Transesophageal: response.Echocardiography_Transesophageal,
    NuclearImaging_BoneScan: response.NuclearImaging_BoneScan,
    NuclearImaging_ThyroidScan: response.NuclearImaging_ThyroidScan,
    NuclearImaging_HeartScan: response.NuclearImaging_HeartScan,
    NuclearImaging_CardiacStress: response.NuclearImaging_CardiacStress,
    Fluoroscopy_BariumSwallow: response.Fluoroscopy_BariumSwallow,
    Fluoroscopy_BariumEnema: response.Fluoroscopy_BariumEnema,
    Fluoroscopy_Hysterosalpingography: response.Fluoroscopy_Hysterosalpingography,
    Radiology_PETScan: response.Radiology_PETScan,
    Radiology_Mammography: response.Radiology_Mammography,
    Visualization_NasalEndoscopy: response.Visualization_NasalEndoscopy,
    Visualization_Bronchoscopy: response.Visualization_Bronchoscopy,
    Visualization_UpperGastrointestinalEndoscopy: response.Visualization_UpperGastrointestinalEndoscopy,
    Visualization_Sigmoidoscopy: response.Visualization_Sigmoidoscopy,
    Visualization_Colonoscopy: response.Visualization_Colonoscopy,
    Visualization_Cystoscopy: response.Visualization_Cystoscopy,
    Visualization_Colposcopy: response.Visualization_Colposcopy,
    Visualization_Hysteroscopy: response.Visualization_Hysteroscopy,
    Visualization_ERCP: response.Visualization_ERCP,
    Angiography_Brain: response.Angiography_Brain,
    Angiography_Neck: response.Angiography_Neck,
    Angiography_Heart: response.Angiography_Heart,
    Angiography_Lungs: response.Angiography_Lungs,
    Angiography_Abdomen: response.Angiography_Abdomen,
    Angiography_Pelvis: response.Angiography_Pelvis,
    Angiography_Kidneys: response.Angiography_Kidneys,
    Angiography_UpperExtremity: response.Angiography_UpperExtremity,
    Angiography_LowerExtremity: response.Angiography_LowerExtremity,
    Biopsy_FineNeedleAspiration: response.Biopsy_FineNeedleAspiration,
    Biopsy_NeedleBiopsy: response.Biopsy_NeedleBiopsy,
    Biopsy_UltrasoundBiopsy: response.Biopsy_UltrasoundBiopsy,
    Biopsy_CTBiopsy: response.Biopsy_CTBiopsy,
    Biopsy_Surgical: response.Biopsy_Surgical,
    Other_ECG: response.Other_ECG,
    Other_HolterMonitoring: response.Other_HolterMonitoring,
    Other_ExerciseStressTest: response.Other_ExerciseStressTest,
    Other_PulmonaryFunctionTest: response.Other_PulmonaryFunctionTest,
  };

  return lab;
}

function mapRefreshData(response: any): RefreshData {
  return response;
}
