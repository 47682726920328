import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SimpleGlobal
} from 'ng2-simple-global';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  showHelp: string;
  historyVideo = 'assets/video/history.mp4';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, private router: Router, private sg: SimpleGlobal) { }

  ngOnInit() {
    this.showHelp = this.sg["pageTitle"]
  }


  userGuide() {
    window.open('./assets/img/app/CyberPatient-UserGuide.pdf', '_blank');
  }

}
