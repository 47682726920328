<mat-card class="news">
  <div class="header">
    <h1>User Guides</h1>
    <div class="subtitle">Quick access to help tools and user guides.</div>
  </div>

  <div class="news-item-container">
    <div class="news-item" *ngFor="let news of newsfeed">
      <div class="news-item-head">
        <span class="tag {{ news.tag }}">{{ news.tag }}</span>
        <h3 class="h-3">{{ news.title }}</h3>
      </div>
      <div class="news-item-date p-4-subtitle">{{ getDate(news) }}</div>
      <div class="news-item-body p-2-body" [innerHTML]="news.body"></div>
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </div>
</mat-card>
