import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { ChiefComplaints, Question } from '../_models';


export interface MyData {
  url: string;
}

@Injectable()
export class FetchHistoryService {
  sharingData: MyData = { url: 'whatevs' };
  public id;
  public urlChooseSystem = '/api/v1/systems';
  public urlHistory = '/api/v1/patients/historytaking';
  questions;
  public keys;


  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.id = this.sg['gvid'];
    this.urlChooseSystem = this.sg['url'].concat(this.urlChooseSystem);
    this.urlHistory = this.sg['url'].concat(this.urlHistory);

  }



  // Method to load all systems and linked chief complaints
  getAllSystems() {
    return this.http.get(this.urlChooseSystem).pipe(
      map((response: any) => response.message),
      catchError(this.handleError));

  }


  // Method to map chief complaints to systems
  getQuestions(url): Observable<ChiefComplaints[]> {


    this.questions = this.http.get(url).pipe(
      map(mapCComps),
      catchError(this.handleError)
    );

    return this.questions;

  }

  savePatientHistory(a, b) {
    const history = {};
    for (let _i = 0; _i < a.length; _i++) {
      history[a[_i]] = b[_i];
    }
    const body = { 'case_id': this.id, history };
    return this.http.post(this.urlHistory, body).pipe(
      map((response: any) => {
        return response.message._id != null;
      }));

  }


  // To catch and handle any error scenarios and provide an error message
  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }


}



// Decode the response for different ChiefComplaints and their questions and answers
function mapCComps(response: any): ChiefComplaints {
  this.keys = Object.keys(response.message);
  let count = 0;
  const ccomparray = <ChiefComplaints>[];
  for (const entry of this.keys) {
    ccomparray[count] = mapCComp(entry, response);

    count++;

  }
  return ccomparray;

}


// Map the response for each ChiefComplaints and their questions and answers
function mapCComp(entry: string, response: any): ChiefComplaints {

  return <ChiefComplaints>({
    ccomplaint: entry,
    quests: response.message[entry].map(toQuestion)
  });


}

// Map each question and answer
function toQuestion(r: any): Question {
  return <Question>({
    Q: r.Q,
    A: r.A,
    Score: r.Score
  });
}

