<cp-general-dialog>
  <div section="title"
       class="bg-color-primary py-3 h2 font-weight-bold text-color-white">
    Contact our sales
  </div>
  <div section="body">
    <form [formGroup]="contactForm" class="container">
      <div class="row">
        <mat-form-field appearance="standard" class="col-6">
          <mat-label>Your email</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-6">
          <mat-label>Your Institution</mat-label>
          <input matInput formControlName="institution">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label>Email Subject</mat-label>
          <input matInput formControlName="subject">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label>Tell us how our sales team can help you today</mat-label>
          <textarea
            matInput
            formControlName="content"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5">
          </textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col">
          <re-captcha class="mt-3" formControlName="captcha" required></re-captcha>
        </div>
      </div>
    </form>
  </div>
  <div section="actions">
    <button mat-raised-button color="primary" class="text-color-white px-5" (click)="onSubmit()">Submit</button>
    <button mat-raised-button matDialogClose class="px-5">Cancel</button>
  </div>
</cp-general-dialog>
