import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable } from 'rxjs';
import { mergeMap, first, filter, take } from 'rxjs/operators';
import { CheckPoint, EpaAnswerPerSession, User } from '../_models';
import { FetchAssignmentDataService, UserService } from '../_services/index';
import { chartScoreShareService } from '../chartScoreShareService';
import { CheckPointState, CheckPointStateModel } from '../state/checkpoints.state';
import { EpaState } from '../state/epa.state';

@Component({
  selector: 'app-epa-chart-compare',
  templateUrl: './epachartcompare.component.html',
  styleUrls: ['./epachartcompare.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class EpachartcompareComponent implements OnInit {
  @Output() scoreEvent = new EventEmitter<Number>();

  public chartData;
  public standardChart;
  public chartQuestions;
  public component;
  public tabData: CheckPoint[];
  public historyQuestions = [];
  public physicalExamQuestions = [];
  public diagnosisQuestions = [];
  public treatmentQuestions = [];
  public communicationQuestions = [];
  public dischargeQuestions = [];
  public historyScore;
  public physicalScore;
  public daignosisScore;
  public treatmentScore;
  public communScore;
  public dischargeScore;
  public totalChartScore;
  case_id: string;
  session_id: string;
  public selfQuestions;
  public type;
  updateScore;
  public selectedTab;
  public standardflag = false;
  myChartAnswer = {} as EpaAnswerPerSession;
  patientName: string;
  studentName: string;
  @Select(CheckPointState.getCurrentCheckPointState) storeCheckPoints: Observable<CheckPointStateModel>;
  @Select(EpaState.getCurrentEpaAnswers) storeCurrentAnswer: Observable<EpaAnswerPerSession>;

  constructor(
    private _fetchchart: FetchAssignmentDataService,
    private _sg: SimpleGlobal,
    private chartservice: chartScoreShareService,
    public dialogRef: MatDialogRef<EpachartcompareComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.patientName = this._sg['case_name'];
    if (!currentUser['firstName'] || !currentUser['lastName']) {
      this.userService.userChange
        .pipe(
          filter(Boolean),
          take(1),
        )
        .subscribe((user: User) => {
        this.studentName = user.firstName + ' ' + user.lastName;
      });
    } else {
      this.studentName = currentUser['firstName'] + ' ' + currentUser['lastName'];
    }
    let answer: EpaAnswerPerSession;

    this.storeCurrentAnswer.pipe(first()).subscribe(ans => {
      this.case_id = ans.case_id;
      this.session_id = ans.session_id
      this.getChart();
      this.getStandarChart();
      answer = ans;

      if (answer.chartScore == null) {
        this.storeCheckPoints.subscribe(a => {
          this.case_id = a.caseid;
          this.getAssessmentQuestions();
        });
        const getcaseid = this.storeCheckPoints;
        const getst = Observable.of(this.getStandarChart());
        const getass = Observable.of(this.getAssessmentQuestions());


        getcaseid
          .pipe(
            mergeMap(() => getst),
            mergeMap(() => getass)
          )
          .subscribe(() => {
            this.myChartAnswer.chartScore = [
              {section: 'historyTaking', questions: this.historyQuestions},
              {section: 'physicalExam', questions: this.physicalExamQuestions},
              {section: 'provisionalDiagnosis', questions: this.diagnosisQuestions},
              {section: 'managementPlan', questions: this.treatmentQuestions},
              {section: 'comRecommendations', questions: this.communicationQuestions},
              {section: 'followUp', questions: this.dischargeQuestions},
            ]
          });
      } else {
        this.myChartAnswer = answer
        for (const data of this.myChartAnswer.chartScore) {
          if (data.section === 'historyTaking') {
            this.historyQuestions = data.questions;
          } else if (data.section === 'physicalExam') {
            this.physicalExamQuestions = data.questions;
          } else if (data.section === 'provisionalDiagnosis') {
            this.diagnosisQuestions = data.questions;
          } else if (data.section === 'managementPlan') {
            this.treatmentQuestions = data.questions;
          } else if (data.section === 'comRecommendations') {
            this.communicationQuestions = data.questions;
          } else if (data.section === 'followUp') {
            this.dischargeQuestions = data.questions;
          }
        }
      }

    });
  }


  getChart() {

    this._fetchchart.getStudentPatientChartHistory(this.session_id, true).subscribe(
      result => {
        this.chartData = result;

        // TODO: Clean up after the data schema has been fully migrated
        this.migrateDailyNotes(result);
      },
    )
  }

  closeDialog() {
    this.dialogRef.close(this.myChartAnswer);
  }


  getStandarChart() {
    this._fetchchart.getStandardChart(this.case_id).subscribe((result) => {
      this.standardChart = result;
    });
  }


  getAssessmentQuestions() {
    // this.case_id = this._sg["case_id"];
    const results = this._fetchchart.getSelfAssessmentQuestionforChart(this.case_id);
    results.subscribe((val) => {
      this.selfQuestions = val;
      this.selfQuestions.forEach((res) => {
        res.score = false
      });
      for (const data of this.selfQuestions) {
        this.type = data.type;
        this.component = data.component;
        // push({q:data.content,score:data.score});
        if (this.component === 'historyTaking') {
          this.historyQuestions.push(data);
        } else if (this.component === 'physicalExam') {
          this.physicalExamQuestions.push(data);
        } else if (this.component === 'provisionalDiagnosis') {
          this.diagnosisQuestions.push(data);
        } else if (this.component === 'managementPlan') {
          this.treatmentQuestions.push(data);
        } else if (this.component === 'comRecommendations') {
          this.communicationQuestions.push(data);
        } else if (this.component === 'followUp') {
          this.dischargeQuestions.push(data);
        }
      }
      this.selectedTab = 0;
    });
  }

  check(e) {
    this.selectedTab = e.index;
  }

  calculateHistoryScore() {
    const x = this.historyQuestions.filter(x => x.score).length;
    const y = this.historyQuestions.length;
    this.historyScore = (x / y).toFixed(2);
    return Number(this.historyScore) * 100;
  }

  calculatePhysicalExamScore() {
    const x = this.physicalExamQuestions.filter(x => x.score).length;
    const y = this.physicalExamQuestions.length;
    this.physicalScore = (x / y).toFixed(2);
    return Number(this.physicalScore) * 100;
  }

  calculateDiagnosisScore() {
    const x = this.diagnosisQuestions.filter(x => x.score).length;
    const y = this.diagnosisQuestions.length;
    this.daignosisScore = (x / y).toFixed(2);
    return Number(this.daignosisScore) * 100;
  }

  calculateTreatmentScore() {
    const x = this.treatmentQuestions.filter(x => x.score).length;
    const y = this.treatmentQuestions.length;
    this.treatmentScore = (x / y).toFixed(2);
    return Number(this.treatmentScore) * 100;
  }

  calculateCommunicationScore() {
    const x = this.communicationQuestions.filter(x => x.score).length;
    const y = this.communicationQuestions.length;
    this.communScore = (x / y).toFixed(2);
    return Number(this.communScore) * 100;
  }

  calculateDischargeScore() {
    const x = this.dischargeQuestions.filter(x => x.score).length;
    const y = this.dischargeQuestions.length;
    this.dischargeScore = (x / y).toFixed(2);
    return Number(this.dischargeScore) * 100;
  }

  calculateTotalChartScore() {
    const avg = Number(this.historyScore) + Number(this.physicalScore) + Number(this.daignosisScore) + Number(this.treatmentScore) + Number(this.communScore) + Number(this.dischargeScore);
    const totalavg = (Number(avg) / 6) * 100;
    this.totalChartScore = totalavg;
    this.chartservice.currentScore.subscribe(res => this.updateScore = res);

    this.chartservice.updateChartAnswer(this.myChartAnswer);

    this.closeDialog();
  }


  migrateDailyNotes(result: any) {
    if (this.chartData.treatment &&
      this.chartData.treatment.dailyVisits &&
      result.treatment &&
      result.treatment.dailyVisits
    ) {
      this.chartData.treatment.dailyVisits.notes = [
        result.treatment.dailyVisits.day1Notes,
        result.treatment.dailyVisits.day2Notes,
        result.treatment.dailyVisits.day3Notes,
        ...(result.treatment.dailyVisits.notes || []),
      ].filter((note) => note !== undefined && note !== null);
    }
  }
}
