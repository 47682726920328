import {
  Component,
  OnInit
} from '@angular/core';
import {
  AuthenticationService,
  SaveAssignmentDataService
} from '../_services/index';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  FetchAssignmentDataService
} from '../_services/index';
import {
  ErrorMsgService
} from '../error-msg.service';
import { CustomValidators } from '../custom-validators';

export interface FormModel {
  newPwd?: string;
  confirm?: string;
  captcha?: string;
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public frmSignup: FormGroup;
  resetToken: string;

  loadingAndSaving = false;
  public formModel: FormModel = {};
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private saveAssignmentDataService: SaveAssignmentDataService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private router: Router, public errorService: ErrorMsgService) {
      this.frmSignup = this.createSignupForm();
      this.resetToken = this.route.snapshot.paramMap.get('token');
    }

  ngOnInit() {



  }
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        new: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        verify: [null, Validators.compose([Validators.required])],
        captcha: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }
  submitRequest() {

    if (this.frmSignup.value.captcha) {
      delete this.formModel.captcha;
      const body = {
        token: this.resetToken,
        newPwd: this.frmSignup.value.new
      };
      this.loadingAndSaving = true;

      this.authService.confirmPasswordReset(body).subscribe(result => {
        this.loadingAndSaving = false;
        if (result === null) {
          this.errorService.openSnack('error', 'Reset Password', 'reseterror');
        } else {
          this.errorService.openSnack('success', 'Reset Password', 'resetsuccess');
          this.router.navigate(['/login'], {
            skipLocationChange: true
          });
        }
      }, err => {
        this.loadingAndSaving = false;
      });
    }

  }
}
