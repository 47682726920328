import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { AuthenticationService } from '../../_services/authentication.service';
import { UserService } from 'app/_services';
import { User } from 'app/_models';

@Component({
  selector: 'app-msg-center',
  templateUrl: './msg-center.component.html',
  styleUrls: ['./msg-center.component.scss'],
})
export class MsgCenterComponent implements OnInit {
  public user: User;

  constructor(
    private router: Router,
    private store: Store,
    private authService: AuthenticationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    this.userService.userChange.subscribe((user: User) => {
      this.user = user;
    });
  }

  setInstitute(institutionId: string) {
    this.userService.updateSelectedInstitutionId(institutionId).subscribe(() => {
      this.loadUser();
      this.reloadComponent();
    });
  }

  showInstitutions() {
    return this.user.institutions?.length >= 2;
  }

  reloadComponent() {
    this.router.navigateByUrl('/');
  }

  logout() {
    this.store.dispatch(new StateResetAll());
    this.authService.logout();
  }
}
