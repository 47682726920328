import { Injectable } from '@angular/core';
import { ClockService } from './clock-service.service';

@Injectable({
  providedIn: 'root',
})
export class RespirationService {
  Hide() {
            this.clockService.Hide();
         };

  Show() {
             this.clockService.Show(10, 60);
         };

  constructor(private clockService: ClockService) { }

}
