<mat-card class="settings">
  <div class="header">
    <h1>Set Your Password.</h1>
  </div>
  <div class="account-settings activate-email">
    <div class="row">
      <h3>Please confirm your account password below:</h3>
      <mat-divider [inset]="true"></mat-divider>
    </div>
    <form #captchaProtectedForm="ngForm" (submit)="activateAccount()" [formGroup]="frmSignup" class="activate-form">
      <div class="row">
        <mat-form-field appearance="outline" floatLabel="auto" class="passwordbox">
          <mat-label>Set your Password:</mat-label>
          <input
            matInput
            id="pwd"
            formControlName="pwd"
            type="password"
            class="form-control"
            [ngClass]="frmSignup.controls['pwd'].invalid ? 'mat-error' : ''"
          />
          <mat-error
            class="col"
            [ngClass]="
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('minlength')
                ? 'text-danger'
                : 'text-success'
            "
          >
            <i class="material-icons">{{
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('minlength')
                ? 'cancel'
                : 'check_circle'
            }}</i>
            Must be at least 8 characters!
          </mat-error>
          <mat-error
            class="col"
            [ngClass]="
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasNumber')
                ? 'text-danger'
                : 'text-success'
            "
          >
            <i class="material-icons">{{
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasNumber')
                ? 'cancel'
                : 'check_circle'
            }}</i>
            Must contain at least 1 number!
          </mat-error>
          <mat-error
            class="col"
            [ngClass]="
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasCapitalCase')
                ? 'text-danger'
                : 'text-success'
            "
          >
            <i class="material-icons">{{
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasCapitalCase')
                ? 'cancel'
                : 'check_circle'
            }}</i>
            Must contain at least 1 in Capital Case!
          </mat-error>
          <mat-error
            class="col"
            [ngClass]="
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasSmallCase')
                ? 'text-danger'
                : 'text-success'
            "
          >
            <i class="material-icons">{{
              frmSignup.controls['pwd'].hasError('required') || frmSignup.controls['pwd'].hasError('hasSmallCase')
                ? 'cancel'
                : 'check_circle'
            }}</i>
            Must contain at least 1 Letter in Small Case!
          </mat-error>
          <mat-error
            class="col"
            [ngClass]="
              frmSignup.controls['pwd'].hasError('required') ||
              frmSignup.controls['pwd'].hasError('hasSpecialCharacters')
                ? 'text-danger'
                : 'text-success'
            "
          >
            <i class="material-icons">{{
              frmSignup.controls['pwd'].hasError('required') ||
              frmSignup.controls['pwd'].hasError('hasSpecialCharacters')
                ? 'cancel'
                : 'check_circle'
            }}</i>
            Must contain at least 1 Special Character!
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>Confirm Password:</mat-label>
          <input
            matInput
            id="verify"
            formControlName="verify"
            type="password"
            class="form-control"
            [ngClass]="frmSignup.controls['verify'].invalid ? 'is-invalid' : ''"
          />
          <mat-error class="text-danger" *ngIf="frmSignup.controls['verify'].hasError('required')">
            Password is Required!
          </mat-error>
          <mat-error class="text-danger" *ngIf="frmSignup.controls['verify'].hasError('NoPassswordMatch')">
            Password do not match
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="row"> Removed temporarily (Awaiting HUBSPOT integration)
        <mat-checkbox formControlName="marketingEmailPermission" class="policy-box">
          I would like to subscribe to email updates from CyberPatient
        </mat-checkbox>
      </div> -->
      <div class="row">
        <re-captcha formControlName="captcha" name="captcha" id="captcha" required></re-captcha>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button
          mat-raised-button
          [disabled]="frmSignup.invalid"
          color="primary"
          type="submit"
          matTooltip="Make sure you have selected all of your security questions from the drop down menus, and filled out all the required answers and passwords."
          [matTooltipPosition]="'above'"
          [matTooltipDisabled]="!frmSignup.invalid"
        >
          Activate
        </button>
      </div>
    </form>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </div>
</mat-card>
