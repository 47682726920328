import { Component, OnInit } from '@angular/core';
import { FetchAssignmentDataService } from '../../_services/index';


@Component({
  selector: 'news-feed',
  providers: [FetchAssignmentDataService],

  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {
  /*newsfeed = [
    {
      "title": "FIXED! ~ A bug that causes a giant earthworm to crawl through users screens when they hit the wrong answer has been resolved.",
      "date": "July 1, 2019",
      "body": "This portion is a summary about the earthworm. It should generally convey when the issue was discovered, and what happened when students encountered it.It would probably include notes like this one that establish that we finished working on the bug and solved it in a timely manner. Something like, we found the worm nest underneath Ron’s computer and were able to hire exterminators to come in and destroy them. They shouldn’t be able to travel through cyberspace to attack the users anymore, but we’re keeping an eye out for complaints, still.",
      "tag": "BUG"
    },
    {
      "title": "We got some new cases in! We’d like to thank the academy, and our parents.",
      "date": "June 20, 2019",
      "body": "This portion is a summary about the earthworm. It should generally convey when the issue was discovered, and what happened when students encountered it.It would probably include notes like this one that establish that we finished working on the bug and solved it in a timely manner. Something like, we found the worm nest underneath Ron’s computer and were able to hire exterminators to come in and destroy them. They shouldn’t be able to travel through cyberspace to attack the users anymore, but we’re keeping an eye out for complaints, still.",
      "tag": "UPDATE"
    },
    {
      "title": "We got some new cases in! We’d like to thank the academy, and our parents.",
      "date": "June 20, 2019",
      "body": "This portion is a summary about the earthworm. It should generally convey when the issue was discovered, and what happened when students encountered it.It would probably include notes like this one that establish that we finished working on the bug and solved it in a timely manner. Something like, we found the worm nest underneath Ron’s computer and were able to hire exterminators to come in and destroy them. They shouldn’t be able to travel through cyberspace to attack the users anymore, but we’re keeping an eye out for complaints, still.",
      "tag": "UPDATE"
    }
  ] */
  newsfeed;
  loadingAndSaving = false;
  user;
  institutionPermissions;

  constructor(private FetchAssignmentDataService: FetchAssignmentDataService) { }

  ngOnInit() {
    this.getNewsFeedData();
    this.user = localStorage.getItem('currentUser');
    this.institutionPermissions = (JSON.parse(this.user)).institutionPermissions;

  }

  getNewsFeedData(){
    this.loadingAndSaving = true;

    this.FetchAssignmentDataService.getNewsFeed().subscribe(newsfeed => {
      this.loadingAndSaving = false;
      this.newsfeed = newsfeed;


    }, err=>{
      this.loadingAndSaving = false;
    });
  }


  

}
