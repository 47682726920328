import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invitation } from 'app/lms/interfaces/Invitation';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { User, UserOnboarding } from '../_models';

interface UserResponse {
  user: User;
  suUser?: User;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private userState = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient) {}

  get user(): User {
    return this.userState.getValue();
  }

  get userChange(): Observable<User> {
    return this.userState.asObservable().pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  updateSelectedInstitutionId(institutionId: string) {
    const url = environment.apiUrl.concat('/api/v1/user/institution/select');
    return this.http
      .patch<User>(url, {
        institutionId,
      })
      .pipe(
        tap((user) => {
          this.userState.next({
            ...this.user,
            ...user,
          });
        })
      );
  }

  getCurrentUser(): Observable<UserResponse> {
    const url = environment.apiUrl.concat('/api/v1/user/current');
    return this.http.get<UserResponse>(url).pipe(
      tap((response) => {
        this.userState.next({
          ...this.user,
          ...response.user,
          suUser: response.suUser?.email,
        });
      })
    );
  }

  cleanUserState() {
    this.userState.next(null);
  }

  getCurrentUserLatest(): Observable<UserResponse> {
    const url = environment.apiUrl.concat('/api/v1/user/current');
    return this.http.get<UserResponse>(url);
  }

  retrievePendingInvitations(): Observable<Invitation[]> {
    return this.http.get<Invitation[]>(environment.apiUrl.concat('/api/v1/institutions/invitation/pending'));
  }

  updateSkillset(body: { newSkillset: string }) {
    const url = environment.apiUrl
      .concat('/api/v1/auth/upgradeSkillset?student_email=')
      .concat(encodeURIComponent(this.user.email));

    return this.http.put(url, body).pipe(
      map((response: any) => {
        if (response.message != null) {
          this.userState.next({
            ...this.user,
            skillset: body.newSkillset,
          });
          return response;
        } else {
          return null;
        }
      })
    );
  }

  updateOnboarding(type: keyof UserOnboarding): Observable<UserOnboarding> {
    const url = environment.apiUrl.concat('/api/v1/onboarding?onboardingCategory=').concat(type);

    return this.http.put<UserOnboarding>(url, {}).pipe(
      tap((onboarding) => {
        this.userState.next({
          ...this.user,
          onboarding,
        });
      })
    );
  }

  updateInstitution(institutionId: string, role: 'student' | 'professor'): Observable<User> {
    const url = environment.apiUrl.concat('/api/v1/user');
    return this.http
      .patch<User>(url, {
        institutionId,
        role,
      })
      .pipe(
        map((user) => ({
          ...this.user,
          institutionRole: user.institutionRole,
          institutionId: user.institutionId,
          institution: user.institution,
          memberId: user.memberId,
        })),
        tap((user) => {
          this.userState.next(user);
        })
      );
  }
}
