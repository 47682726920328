<cp-general-dialog>
  <div section="body" class="text-center">
    <mat-form-field class="w-100" appearance="outline">
      <input matInput type="text" readonly [value]="data.shareUrl">
    </mat-form-field>
  </div>
  <div section="actions">
    <button mat-raised-button
            mat-dialog-close
            color="success"
            [cdkCopyToClipboard]="data.shareUrl"
            (click)="informCopied()">
      Copy link
    </button>
  </div>
</cp-general-dialog>
