<mat-dialog-content class="accent-color dialogue-content">
  <div class="box-container mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
            <p style="text-align: center; display: block; margin: 0 auto">Transition to New System</p>
          </div>
        </div>
        <section class="response">
          <p style="text-align: center; margin-top: 20px">
            Please use <a href="https://learn.cyberpatient.ca" target="_self">learn.cyberpatient.ca</a> with your
            current login to access the cases.
          </p>
        </section>
        <section class="actions">
          <button mat-button (click)="dialogRef.close()">Close</button>
        </section>
      </div>
    </div>
  </div>
</mat-dialog-content>
