import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of, Subscription } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { InstitutionRole, User } from '../../_models';
import { FormativeFeedback, SummativeFeedback } from '../../_models/teacher-dashboard.model';
import { UserService } from '../../_services';
import { CourseInstitutionId } from '../../lms/interfaces/course';
import { PatientCase } from '../../lms/interfaces/patient-case';
import { CoursesService } from '../../lms/services/courses.service';
import { SummativeAssessmentComponent } from '../summative-assessment/summative-assessment.component';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';
import { hasCourseEnded } from 'utils/helpers';

@Component({
  selector: 'app-student-formative-assessment',
  templateUrl: './student-formative-assessment.component.html',
  styleUrls: ['./student-formative-assessment.component.scss'],
  providers: [CoursesService],
})
export class StudentFormativeAssessmentComponent implements OnInit, OnDestroy {
  course: CourseInstitutionId;
  formative: FormativeFeedback[] = [];
  summative: SummativeFeedback;
  student: User;
  loadingAndSaving: boolean;
  completedCases = [];

  private courseId: string;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private sg: SimpleGlobal,
    private teacherService: TeacherDashboardDataService,
    private store: Store,
    private courseService: CoursesService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.subscription = this.fetchData().subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backClicked() {
    this.router.navigate(['/']);
  }

  viewSummative() {
    this.dialog.open(SummativeAssessmentComponent, {
      data: {
        assessment: this.summative,
        myCourse: this.course,
        mystudent: this.student,
      },
      height: '450px',
      width: '600px',
    });
  }

  getGradesOverview() {
    return this.teacherService.getGradesOverviewUser(this.student.memberId, this.course._id).pipe(
      tap(
        (data) => {
          const caseData = data.message['caseData'] ? data.message['caseData'] : [];
          this.completedCases = caseData.filter((x) => this.course.cases.some((y: PatientCase) => y._id === x.case_id));
        },
        () => {
          this.toastr.error('Failed to retrieve your grade. Please refresh the page', 'Error');
        }
      )
    );
  }

  toCaseLibrary() {
    this.router.navigate(['/case-library'], {
      relativeTo: this.route,
      queryParams: {
        course: this.courseId,
      },
    });
  }

  canAccess() {
    return this.course?.publicAccess || this.isInClass();
  }

  isInClass() {
    return this.course?.students.includes(this.student?.memberId);
  }

  isStudent() {
    return this.student?.institutionRole === InstitutionRole.STUDENT;
  }

  enrollToCourse() {
    this.loadingAndSaving = true;
    this.courseService.enrollToCourse(this.courseId).subscribe(
      (course) => {
        this.course = {
          ...this.course,
          students: course.students,
        };
        this.loadingAndSaving = false;
        this.toastr.success(`You have successfully enrolled in ${this.course.name}`);
        this.userService.updateSelectedInstitutionId(this.course.institution);
      },
      () => {
        this.toastr.error('This course has already ended or is no longer open for enrollment', 'Failed to enroll');
      }
    );
  }

  private fetchCourse() {
    return this.courseService.getCourseOnlyById(this.courseId).pipe(
      tap((course) => {
        if (course.courseType === 'SIMULATION') {
          course.cases.forEach((caseObj) => {
            caseObj.caseType = 'SIMULATION';
            caseObj.demographics = { patient_name: caseObj.patientName + ' (V3)' };
            caseObj.tags = {
              category: [caseObj.category],
              difficulty: 'easy',
              length: 'short',
              patientSettings: caseObj.inPatient ? 'inpatient' : 'outpatient',
              peerReviewed: caseObj.peerReviewed,
            };
          });
        }
        this.course = course;

        this.course.ended = hasCourseEnded(course.end);
      })
    );
  }

  private fetchFormativeFeedback() {
    return this.teacherService.getFormativeStudent(this.course._id).pipe(
      tap((feedbacks) => {
        this.formative = feedbacks.filter((feedback) =>
          this.teacherService.dateCheck(this.course.start, this.course.end, feedback.created)
        );
      })
    );
  }

  private fetchSummativeFeedback() {
    return this.teacherService.getStudentCourseSummative(this.course._id).pipe(
      tap((feedbacks) => {
        this.summative = this.retrieveLatestFeedback(feedbacks);
      })
    );
  }

  private fetchGrades() {
    return forkJoin([this.fetchFormativeFeedback(), this.fetchSummativeFeedback(), this.getGradesOverview()]);
  }

  private fetchData() {
    this.loadingAndSaving = true;
    return this.userService.userChange.pipe(
      filter((user) => !!user),
      take(1),
      tap((user) => (this.student = user)),
      mergeMap(() => this.fetchCourse()),
      mergeMap(() => (this.isInClass() ? this.fetchGrades() : of(null))),
      tap(() => {
        this.loadingAndSaving = false;
      })
    );
  }

  private retrieveLatestFeedback(feedbacks: SummativeFeedback[]) {
    return feedbacks?.reduce((latest, curr) => {
      const latestCreated = new Date(latest.created).getTime();
      const curCreated = new Date(curr.created).getTime();

      return latestCreated < curCreated ? curr : latest;
    }, feedbacks[0]);
  }
}
