import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, SaveAssignmentDataService } from '../_services/index';
import { ErrorMsgService } from '../error-msg.service';
import { CustomValidators } from './../custom-validators';

export interface FormModel {
  secretQ1?: string;
  secretA1?: string;
  secretQ2?: string;
  secretA2?: string;
  secretQ3?: string;
  secretA3?: string;
  pwd?: string;
  verify?: string;
  captcha?: string;
}
@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss'],
  providers: [AuthenticationService]
})
export class ActivateEmailComponent implements OnInit {
  showForm = false;
  showError = false;
  securityChange: FormGroup;
  loadingAndSaving = false;
  public formModel: FormModel = {};
  token: string;
  email: string;
  tokenChecked = false;
  resetToken: string;
  required = new FormControl('', Validators.required);
  public frmSignup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private saveAssignmentDataService: SaveAssignmentDataService,
    public errorService: ErrorMsgService
  ) { }

  ngOnInit() {
    this.frmSignup = this.createSignupForm();
    this.token = this.route.snapshot.paramMap.get('token');
  }
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        pwd: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        verify: [null, Validators.compose([Validators.required])],
        captcha: [null, Validators.compose([Validators.required])],
        marketingEmailPermission: [true]
      },
      {
        // check whether our password and verify password match
        validator: CustomValidators.registerPasswordMatchValidator
      }
    );
  }

  activateAccount() {
    if (this.frmSignup.value.captcha) {
      const submission = {};
      for (const x of Object.keys(this.frmSignup.value)) {
        if (x !== 'captcha' && x !== 'verify') {
          submission[x] = this.frmSignup.value[x];
        }
      }
      this.loadingAndSaving = true;
      this.authenticationService.activateConfirmAccount(this.token, submission).subscribe(result => {
        this.loadingAndSaving = false;
        if (result === 'Account activated successfully') {
          this.errorService.openSnack(0, 'Account Activation Successful! Please login to proceed.', 'accountactivated');
        } else if (result === null) {
        }
      }, err => {
        this.loadingAndSaving = false;
      });
    }
  }

}
