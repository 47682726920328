import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private readonly http: HttpClient) { }

  createTicket(subject: string, content: string, institution: string): Observable<any> {
    return this.http.post(environment.apiUrl.concat('/api/v1/contacts/tickets'), {
      subject,
      content,
      institution,
    })
  }
}
