import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import 'rxjs/Rx';
import { Element, LabTest } from '../_models';


@Injectable()
export class LabtestService {

  id: string;
  lbtst;
  private urlLabTest = '/api/v1/patients/';
  private urlAllLabs = '/api/v1/labtests';
  private urlStudent = '/api/v1/patients/search';
  private urlAllMeds = '/api/v1/medications';

  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.urlLabTest = this.sg['url'].concat(this.urlLabTest);
    this.urlAllLabs = this.sg['url'].concat(this.urlAllLabs);
    this.urlStudent = this.sg['url'].concat(this.urlStudent);
    this.urlAllMeds = this.sg['url'].concat(this.urlAllMeds);

  }


  getAllLabs() {
    return this.http.get(this.urlAllLabs).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
  }

  getAllMeds(): Observable<Element[]> {


    const med$ = this.http.get(this.urlAllMeds).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
    return med$;

  }


  getMedsStudent(): Observable<any> {
    const url = this.urlStudent.concat('?component=DrugList&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']);

    const urlMedTest$ = this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
    return urlMedTest$;

  }

  getLabTestStudent(): Observable<LabTest> {

    const url = this.urlStudent.concat('?component=labTests&instance=' + this.sg['instance_id'] + '&case_id=' + this.sg['case_id']);

    const labtest$ = this.http.get(url).pipe(
      map(saveIt),
      catchError(this.handleError), );

    return labtest$;

  }


  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}

function saveIt(response: any) {
  return response.message;

}
