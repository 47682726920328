<div #wrapper class="chart-scroll-wrapper">
  <div class="content" (wheel)="onScrollEvent($event)">
    <ng-content></ng-content>
  </div>
  <div *ngIf="axis === 'y'" class="slider-wrapper-vertical">
    <input #scroll class="range round" type="range" min="1" max="1" step=".1" value="1" />
  </div>
  <div *ngIf="axis === 'x'" class="slider-wrapper-horizontal">
    <input #scroll class="range round" type="range" min="1" step=".1" value="1" />
  </div>
</div>
