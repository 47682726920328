import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { userid } from 'app/_models/teacher-dashboard.model';
import { SetStudent } from 'app/actions/teacherdashboard.action';
import { SimpleGlobal } from 'ng2-simple-global';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { Router } from '@angular/router';
import { Course } from 'app/lms/interfaces/course';
import { Institution } from 'app/lms/interfaces/institution';
import { ToastrService } from 'ngx-toastr';
import {
  FetchAssignmentDataService,
  UserService,
  SaveAssignmentDataService,
  SessionManagerService,
} from '../_services/index';
import { DebugService } from '../debug.service';
import { PopUpService } from '../pop-ups/pop-up.service';
import { CoursesService } from 'app/lms/services/courses.service';
import { LoadService } from '../theme/loading/load-service.service';
import { SetCaseId } from 'app/actions/checkpoint.action';
import { Invitation } from 'app/lms/interfaces/Invitation';

@Component({
  selector: 'app-dash-content',
  templateUrl: './dash-content.component.html',
  styleUrls: ['./dash-content.component.scss'],
  providers: [CoursesService],
})
export class DashContentComponent implements OnInit {
  user: User;
  currentSkillset;
  onboarding;
  loadingAndSaving: boolean;
  course: Course;
  private courseId: string;
  invitations: Invitation[] = [];

  constructor(
    private store: Store,
    private debugService: DebugService,
    private popUpService: PopUpService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    public loader: LoadService,
    private sg: SimpleGlobal,
    private courseService: CoursesService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private saveAssignmentDataService: SaveAssignmentDataService,
    private sessionManagerService: SessionManagerService
  ) {
    this.sg['pageTitle'] = 'Dashboard';
    this.sg['pageBlurb'] = 'See your CyberPatient Activity';
    if (environment.debug) {
      this.debugService.updateShowHideDebug(environment.debug);
    }
  }

  ngOnInit() {
    // get users from secure api end point
    this.userService.getCurrentUser();
    this.user = this.userService.user;

    const student: userid = {
      _id: this.user?._id,
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      email: this.user?.email,
    };
    this.store.dispatch([new SetStudent(student)]);

    switch (this.sg['skillset']) {
      case 'HIST':
        this.currentSkillset = 'Level 1';
        break;

      case 'HTPE':
        this.currentSkillset = 'Level 2';
        break;

      case 'FULL':
        this.currentSkillset = 'Level 3';
        break;
    }
  }

  // Currently not used Popup guide for Student Accounts (SkillSet).
  onboardingCheck() {
    if (!this.onboarding) {
      this.fetchAssignmentDataService.getOnboarding().subscribe((result) => {
        // onboarding update
        if (result.skillset !== null) {
          this.onboarding = result;
          if (this.user.occupation === 'Email Campaign' && this.user && !this.onboarding.skillset) {
            this.onboardingCamgaignSetting();
          } else if (
            (!this.onboarding.skillset && this.user) ||
            (this.onboarding.skillset === undefined && this.user)
          ) {
            this.popUpService.SetSkillLevel();
          }
        }
      });
    }
  }

  onboardingCamgaignSetting() {
    this.setSkillSet();
    setTimeout(() => {
      this.enrollToInstitution();
    }, 500);
  }

  enrollToInstitution() {
    // enroll to institution
    const institution = this.course?.institution as Institution;
    if (institution) {
      this.loadingAndSaving = true;
      this.userService.updateInstitution(institution._id, 'student').subscribe((user) => {
        this.user = user;
        this.loadingAndSaving = false;
        this.enrollToCourse();
      });
    }
  }

  enrollToCourse() {
    this.courseService.enroll(this.courseId).subscribe(
      (course) => {
        this.course = {
          ...this.course,
          students: course.students,
        };
        this.loadingAndSaving = false;
        this.toastr.success(`You have successfully enrolled in ${this.course.name}`);
      },
      () => {
        this.toastr.error('This course has already ended or is no longer open for enrollment', 'Failed to enroll');
      }
    );
  }

  setSkillSet() {
    this.loadingAndSaving = true;
    const chosenSkill = 'HTPE';
    this.fetchAssignmentDataService.getRefreshData().subscribe(
      (result) => {
        this.loadingAndSaving = false;
        const currentSession = result;
        if (currentSession['message'] === 'no active session exists') {
          const body = {
            newSkillset: chosenSkill,
          };
          this.loadingAndSaving = true;

          this.userService.updateSkillset(body).subscribe(() => {
            this.loadingAndSaving = false;
            this.saveAssignmentDataService.updateOnboarding('skillset').subscribe(() => {
              this.sg['skillset'] = chosenSkill;
              this.router.navigate(['/'], {
                skipLocationChange: true,
              });
            });
          });
        } else {
          this.sessionManagerService.abortSession(this.sg['session_id']).subscribe((result) => {
            if (result.message.status === 'aborted' || result.message === 'session is already aborted') {
              this.sg['session_id'] = null;
              this.sg['case_id'] = null;
              this.store.dispatch([new SetCaseId(null)]);
              const body = {
                newSkillset: chosenSkill,
              };
              this.userService.updateSkillset(body).subscribe(() => {
                this.saveAssignmentDataService.updateOnboarding('skillset').subscribe(() => {
                  this.sg['skillset'] = chosenSkill;
                  this.router.navigate(['/'], {
                    skipLocationChange: true,
                  });
                });
              });
            }
          });
        }
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  ngOnDestory() {
    this.loader.start();
  }
}
