import { LoadService } from './load-service.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  text;
  startTime;
  timeInterval;
  timer;
  refresh;
  textArray = [
    'Virtual Patients, Real Experience'
  ];
  public active: boolean;

  public constructor(public loader: LoadService) {
    loader.status.subscribe((status: boolean) => {
      this.active = status;
    })
  }
  ngOnInit() {

  }

  timerStart() {
    this.timer = null;
    this.startTime = null;
    clearInterval(this.timeInterval);
    this.startTime = performance.now();
    this.timeInterval = setInterval( () => {
      this.timer = performance.now() - this.startTime;
    }, 1);
  }

  rando() {
    const num = Math.floor(Math.random() * this.textArray.length);
    this.text = this.textArray[num];
  }
}
