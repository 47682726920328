<div>
  <div>
    <app-course-header [student]="mystudent" [course]="myCourse"></app-course-header>
  </div>
  <div class="container-fluid mt-3">
    <div *ngIf="caserow">
      <h2 class="text-black-50">Case Performance: {{caserow.name}} </h2>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <mat-card>
          <div class="d-flex justify-content-between">
            <h1 class="text-black-50">Performance Scores</h1>
            <button mat-raised-button color="success" class="rounded-pill w-25" (click)="getGradesOverview()">
              See Details
            </button>
          </div>
          <div class="w-75 mx-auto d-flex justify-content-between align-items-center mt-5">
            <div *ngIf="caserow" class="h1 font-weight-bold text-color-secondary">
              {{caserow.bestScore| number:'1.2-2'}}%
            </div>
            <div class="text-color-secondary">
              <div>Cost: <b>{{cost | currency}}</b></div>
              <div>Time: <b>{{time | number:'1.2-2'}} mins</b></div>
            </div>
          </div>
        </mat-card>
        <mat-card class="mt-3">
          <h1 class="text-black-50">Formative Feedback</h1>
          <mat-form-field appearance="fill" class="w-100 d-block">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="5"
                      placeholder="Provide feedback to your students..."
                      [(ngModel)]="comment">
            </textarea>
          </mat-form-field>
          <div class="d-flex justify-content-end">
            <button mat-raised-button
                    color="success"
                    class="rounded-pill float-right"
                    [disabled]="loading || comment.trim().length==0"
                    (click)="addComment()">
              Save Feedback
            </button>
            <mat-spinner *ngIf="loading"></mat-spinner>
          </div>
        </mat-card>
      </div>
      <div class="col-6">
        <mat-card>
          <h1 class="text-black-50">Submitted Formative Assessments</h1>
          <ng-container *ngIf="loading; else elseTemplate">
            <mat-spinner [diameter]="140"></mat-spinner>
          </ng-container>
          <ng-template #elseTemplate>
            <div *ngIf="listOfFeedback.length !== 0; else elseblock">
              <div *ngFor="let comment of listOfFeedback" class="my-4">
                <div class="text-black-50 small">{{comment.created | date : 'MMM d, y, h:mm a' }}</div>
                <div class="bg-color-lightgray p-3 rounded">{{comment.text}}</div>
              </div>
            </div>
            <ng-template #elseblock>
              <span>
                No assessments available. Your assessments will appear here once submitted.
              </span>
            </ng-template>

          </ng-template>
        </mat-card>
      </div>
    </div>
  </div>
</div>
