import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CheckPointMessage, CheckPoint } from './../_models/checkpoint.model';
import {
  SetCaseId,
  SetCurrentCheckpoint,
  UpdateCheckpointMessage,
  AddEpaCheckPoints,
  SetCurrentCase,
} from '../actions/checkpoint.action';
import { Injectable } from '@angular/core';
import { FetchAssignmentDataService } from '../_services/index';

export class CheckPointStateModel {
  checkpointmessage: CheckPointMessage;
  caseid: string;
  epa: boolean;
  currentCheckpoint: CheckPoint;
  epacheckpoints: CheckPoint[];
  currentsessionid: string;
}

@State<CheckPointStateModel>({
  name: 'checkpoints',
  defaults: {
    checkpointmessage: null,
    caseid: null,
    epa: false,
    currentCheckpoint: null,
    epacheckpoints: null,
    currentsessionid: null,
  },
})
@Injectable()
export class CheckPointState {
  constructor(private fetchData: FetchAssignmentDataService) {}

  @Selector()
  static getStoreEpaStatus(state: CheckPointStateModel) {
    return state.epa;
  }

  @Selector()
  static getCurrentCheckPointState(state: CheckPointStateModel) {
    return state;
  }

  @Selector()
  static getStoreEpaCheckPoints(state: CheckPointStateModel) {
    return state.epacheckpoints;
  }

  @Selector()
  static getStoreCheckPoints(state: CheckPointStateModel) {
    return state.checkpointmessage;
  }

  @Action(SetCaseId)
  setcaseid({ getState, setState }: StateContext<CheckPointStateModel>, { payload }: SetCaseId) {
    const state = getState();
    setState({ ...state, caseid: payload });
  }

  @Action(UpdateCheckpointMessage)
  update({ getState, patchState }: StateContext<CheckPointStateModel>, { payload }: UpdateCheckpointMessage) {
    const state = getState();
    patchState({ ...state, checkpointmessage: payload, caseid: payload.message.case_id, epa: payload.message.epa });
  }

  @Action(SetCurrentCheckpoint)
  setCurrentCheckPoint(
    { getState, patchState }: StateContext<CheckPointStateModel>,
    { payload }: SetCurrentCheckpoint
  ) {
    const state = getState();
    patchState({ ...state, currentCheckpoint: payload });
  }

  @Action(SetCurrentCase)
  setCurrentSessionId({ getState, patchState }: StateContext<CheckPointStateModel>, { payload }: SetCurrentCase) {
    const state = getState();
    patchState({ ...state, currentsessionid: payload });
  }

  @Action(AddEpaCheckPoints)
  addEpaCheckPoints({ getState, patchState }: StateContext<CheckPointStateModel>, { payload }: AddEpaCheckPoints) {
    const state = getState();
    patchState({ ...state, epacheckpoints: payload });
  }
}
