import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { RegisterAccService } from '../../../_services/register-acc.service';
import { CustomValidators } from '../../../custom-validators';
import { InstitutionService } from '../../services/institution.service';
import { UserService } from './../../../_services/user.service';

@Component({
  selector: 'app-invite-student-dialog',
  templateUrl: './invite-student-dialog.component.html',
  styleUrls: ['./invite-student-dialog.component.scss'],
  providers: [InstitutionService],
})
export class InviteStudentDialogComponent implements OnInit {
  public loading = false;
  public studentForms = new FormArray([this.createStudentFormGroup()]);
  public institutionId: string;
  public inviteLimit = 250;
  public institutionLimitReached = false;
  constructor(
    private readonly institutionService: InstitutionService,
    private readonly registerService: RegisterAccService,
    private readonly snack: MatSnackBar,
    private readonly matDialogRef: MatDialogRef<InviteStudentDialogComponent>,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.institutionId = this.userService.user.institutionId;
  }

  addForm() {
    this.studentForms.push(this.createStudentFormGroup());
  }

  removeForm(index: number) {
    this.studentForms.removeAt(index);
  }

  onSubmit() {
    this.forceValidate();
    if (!this.studentForms.valid) {
      this.scrollToInvalidControl();
      return;
    }

    this.loading = true;
    this.checkEmails()
      .pipe(
        tap((formValid) => !formValid && (this.loading = false)),
        filter((formValid) => formValid),
        mergeMap(() => this.submitInvitation())
      )
      .subscribe(
        () => {
          this.loading = false;

          this.snack.open(`Invitations have been sent to ${this.studentForms.value.length} users`, 'Done', {
            duration: 3000,
          });
          this.matDialogRef.close({ submitted: true });
        },
        (error) => {
          this.loading = false;
          console.error('Error sending invitation', error);
          this.snack.open('Error sending invitations. Please try again.', 'Dismiss', {
            duration: 3000,
            panelClass: 'text-danger',
          });
        }
      );
  }

  limitInvites() {
    for (let i = this.studentForms.length - 1; i >= this.inviteLimit; i--) {
      this.studentForms.removeAt(i);
    }
  }

  handleUploadCsv(rows: any[]) {
    const valid = rows.every((row) => !!row.email && !!row.firstName && !!row.lastName && !!row.studentId);

    if (!valid) {
      this.snack.open('Your CSV is not in the correct format', 'Dismiss', {
        panelClass: 'text-danger',
      });

      return;
    }
    rows.forEach((row, index) => {
      if (index > this.studentForms.length - 1) {
        this.addForm();
      }
      const control = this.studentForms.at(index);
      control.setValue({
        email: row.email,
        firstName: row.firstName,
        lastName: row.lastName,
        studentId: row.studentId,
      });
    });
  }

  private createStudentFormGroup(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      studentId: new FormControl('', Validators.required),
    });
  }

  private forceValidate() {
    this.studentForms.controls.forEach((control) => {
      const controls = (control as FormGroup).controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        controls[key].updateValueAndValidity();
      });
    });
  }

  private scrollToInvalidControl() {
    const index = this.studentForms.controls.findIndex((control) => control.invalid);
    const el = document.getElementById(`studentForm_${index}`);
    if (el) {
      el.scrollIntoView();
    }
  }

  private checkEmails(): Observable<boolean> {
    const emails = this.studentForms.value.map((val) => val.email);
    return this.registerService.checkEmails(emails, this.institutionId).pipe(
      tap(({ existingInstitutionStudents, existingInstructors, institutionLimitReached }) => {
        this.institutionLimitReached = institutionLimitReached;
        this.studentForms.controls.forEach((control) => {
          control
            .get('email')
            .setValidators([
              Validators.required,
              Validators.email,
              CustomValidators.notIn([...existingInstitutionStudents, ...existingInstructors]),
            ]);
        });
        this.forceValidate();
        this.scrollToInvalidControl();
      }),
      map(() => {
        return this.studentForms.valid && !this.institutionLimitReached;
      })
    );
  }

  private submitInvitation(): Observable<any> {
    const students = this.studentForms.value.map((student) => ({
      ...student,
      facultyManagedId: student.studentId,
      role: 'student',
    }));

    return this.institutionService.inviteInstitutionMembers(students);
  }
}
