<div class="formative-assesment">
    <div class="format-asses-heading">
        <!-- <div>
            <button mat-raised-button class="back-button" (click)="backClicked()">Back to Dashboard</button>
        </div> -->
        <app-course-header [student]="mystudent" [course]="myCourse"></app-course-header>
    </div>
    <div class="header-sub">
        Case Performance Overview
    </div>
    <div class="dash-container">
        <table>
            <tr>
                <td>
                    <div class="left-column">
                        <app-course-completed-cases [data]="completedCases" [access]="true">

                        </app-course-completed-cases>
                    </div>
                </td>

                <td>
                    <div class="right-column">
  
                        <div class="course-grade">
                            <app-course-grade [course]='myCourse' [student]='mystudent' [isStudent]='false'>
                            </app-course-grade>
                        </div>

                        <div class="performnaneheader">
                            <app-assignment-cases [course]="myCourse"
                                                  [showCaseLibrary]="false"></app-assignment-cases>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>

</div>
