import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleGlobal } from 'ng2-simple-global';
import { Router } from '@angular/router';
import { HealthcheckService } from 'app/_services/healthcheck.service';

@Component({
  selector: 'app-outage-page',
  templateUrl: './outage-page.component.html',
  styleUrls: ['./outage-page.component.scss'],
})
export class OutagePageComponent implements OnInit, OnDestroy {
  private timeInterval: NodeJS.Timer;
  constructor(
    private httpClient: HttpClient,
    private sg: SimpleGlobal,
    private router: Router,
    private healthcheckService: HealthcheckService
  ) {}
  ngOnInit(): void {
    this.timeInterval = setInterval(() => {
      this.retryConnection();
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this.timeInterval);
  }
  private retryConnection() {
    if (this.healthcheckService.isServerDown()) {
      this.healthcheckService.serverHealthFlag().subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    }
  }
}
