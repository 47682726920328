import { Injectable } from '@angular/core';

@Injectable()
export class cpImageSlicerService {


  constructor() {
  }
}
var slice = function (bigImage, manifests) {
    var result = [];
    manifests.forEach(function (manifest) {
        var image = new createjs.Bitmap(bigImage);
        image.name = manifest.name;
        image.sourceRect = new createjs.Rectangle(manifest.x, manifest.y, manifest.w, manifest.h);
        result[manifest.name] = image;
    });
    return result;
}
