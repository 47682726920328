<div class="user-profile clearfix">
  <div class="col" *ngIf="user && showInstitutions()">
    <!-- <mat-form-field appearance="fill" class="w-100 notranslate">
      <mat-label>Institution</mat-label>
      <mat-select >
        <mat-option  value="custom">SDasd </mat-option>
      </mat-select>
    </mat-form-field> -->
    <button class="currentInst" mat-button [matMenuTriggerFor]="instiutions">
      Current Institution:
      {{ user.institution }}
      <a class="profile-toggle-link dropdown-toggle" data-dropdown="drop1" aria-controls="drop1" aria-expanded="false">
      </a>
    </button>
    <mat-menu #instiutions="matMenu">
      <button
        class="currentInst"
        mat-menu-item
        *ngFor="let member of user.institutions"
        (click)="setInstitute(member.institution._id)"
      >
        {{ member.institution.name }}
      </button>
    </mat-menu>
  </div>
  <div class="col">
    <button mat-icon-button class="userAccount" [matMenuTriggerFor]="menu">
      <a class="profile-toggle-link dropdown-toggle" data-dropdown="drop1" aria-controls="drop1" aria-expanded="false">
        <img src="./assets/img/app/user-icon.svg" />
      </a>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item routerLink="/" routerLinkActive="active">
        <span>Dashboard</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>
