import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveAssignmentDataService } from "../_services/index";

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
   private SaveAssignmentDataService: SaveAssignmentDataService,
   private router: Router
  ) {}

  ngOnInit() {
    const token: string = this.route.snapshot.queryParamMap.get('token');
    this.SaveAssignmentDataService.confirmEmail(token).subscribe(result => {
      if (result === null) {



      } else {  
        let path = '/reset-email/';
      
      }
    });
  }

  

}
