import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import {
  FetchAssignmentDataService,
  SaveAssignmentDataService,
  SessionManagerService,
  UserService
} from '../../_services/index';
import { SetCaseId } from '../../actions/checkpoint.action';

@Component({
  selector: 'app-pop-up-container',
  templateUrl: './pop-up-container.component.html',
  styleUrls: ['./pop-up-container.component.scss']
})
export class PopUpContainerComponent implements OnInit {
  currentSkillset = 'HIST';
  skillFlag;
  loadingAndSaving = false;
  newSkillSet = {
    'skillset': 'Level 1',
    'sections': 'History Taking',
    'failures': 'No Failures',
    'reference': 'HIST'
  };
  skillsets = [{
    'skillset': 'Level 1',
    'sections': 'History Taking',
    'failures': 'No Failures',
    'reference': 'HIST'
  },
    {
      'skillset': 'Level 2',
      'sections': 'History Taking & Physical Exam',
      'failures': 'No Failures',
      'reference': 'HTPE'
    },
    {
      'skillset': 'Level 3',
      'sections': 'All Sections (Full Case)',
      'failures': 'Three Strikes fails the section, case continues.',
      'reference': 'NOMR'
    },
    {
      'skillset': 'Level 4',
      'sections': 'All Sections (Full Case)',
      'failures': 'Three Strikes fails the case. Case must be restarted.',
      'reference': 'FULL'
    }
  ]

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<PopUpContainerComponent>,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private router: Router,
    private sg: SimpleGlobal,
    private saveAssignmentDataService: SaveAssignmentDataService,
    private sessionManagerService: SessionManagerService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
  }

  getSkillset(skills) {
    return skills.reference === this.currentSkillset;
  }

  setSkillSet() {
    this.loadingAndSaving = true;
    this.newSkillSet = this.skillsets.find(this.getSkillset, this);
    const chosenSkill = this.skillsets.find(this.getSkillset, this).reference;
    this.fetchAssignmentDataService.getRefreshData().subscribe(result => {
      this.loadingAndSaving = false;
      const currentSession = result;
      if (currentSession['message'] === 'no active session exists') {

        const body = {
          'newSkillset': chosenSkill
        };
        this.loadingAndSaving = true;

        this.userService.updateSkillset(body).subscribe(result => {
          this.loadingAndSaving = false;
          if (result === null) {
          } else {
            this.saveAssignmentDataService.updateOnboarding('skillset').subscribe(result => {
              this.sg['skillset'] = chosenSkill;
              this.skillFlag = true;
              this.router.navigate(['/'], {
                skipLocationChange: true
              });
            })
          }
        });
      } else {

        this.sessionManagerService.abortSession(this.sg['session_id']).subscribe((result => {
            if (result.message.status === 'aborted' || result.message === 'session is already aborted') {

              this.sg['session_id'] = null;
              this.sg['case_id'] = null;
              this.store.dispatch([new SetCaseId(null)]);

              const body = {
                'newSkillset': chosenSkill
              };
              this.userService.updateSkillset(body).subscribe(result => {
                if (result === null) {
                } else {
                  this.saveAssignmentDataService.updateOnboarding('skillset').subscribe(result => {
                    this.sg['skillset'] = chosenSkill;
                    this.skillFlag = true;
                    this.router.navigate(['/'], {
                      skipLocationChange: true
                    });
                  })
                }
              });
            } else {
            }
          }
        ));
      }

    }, err => {
      this.loadingAndSaving = false;
    });
  }

  close() {
    this.dialogRef.close();
  }

}
