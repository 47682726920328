import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-string-truncate',
  templateUrl: './string-truncate.component.html',
  styleUrls: ['./string-truncate.component.scss']
})
export class StringTruncateComponent implements OnInit,OnChanges {

  @Input() inputString:string;
  @Input() shortenLength:number;
  toolTip:string;
  shortText:string;
  constructor() { }

  ngOnInit() {
    this.toolTip=this.inputString;
    this.shortText= this.truncate(this.inputString,this.shortenLength);
  }
  ngOnChanges() {
     
  }
  truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '..' : str;
  };
}
