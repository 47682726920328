import { Injectable } from '@angular/core';
import { ClockService } from './clock-service.service';
import {SimpleGlobal} from 'ng2-simple-global';

@Injectable({
    providedIn: 'root',
  })
export class PulseService {
  pulseBeginTween = null;
  pulseLoopTween = null;
  container = new createjs.Container();

       getContainer() {
           return this.container;
       };

       init(pulse) {
        this.pulseBeginTween = null;
        this.pulseLoopTween = null;
        this.container = new createjs.Container();
           // PULSE CALC TO BE MADE
           // Delay is 60 seconds divided by the pulse, multipled to be in milisecond format. (x1000)
           // The time of the animation for each pulse is then subtracted from the delay. The delay denotes the time between pulse animations.
           var delay = (((60/pulse)*1000)-300);
           this.container.visible = false;
           var bg = new createjs.Shape();
           bg.graphics.beginFill('#000')
               .rect(0, 0, 1024, 1024);
           bg.alpha = .95;
           this.container.addChild(bg);
           var handURL = "./assets/patients/" + this.sg['case_id'] + "/physical-exam/images/"+ this.sg["instance_id"] +"/pulsePatientHand.png";
           var patientHand = new createjs.Bitmap(handURL);
           patientHand.scaleX = patientHand.scaleY = 0.75;
           patientHand.x = 250;
           patientHand.y = 200;
           this.container.addChild(patientHand);

           var pulseDoctorHand = new createjs.Bitmap("./assets/default/physical/Props/Pulse/pulseDoctorHand.png");
           pulseDoctorHand.scaleX = pulseDoctorHand.scaleY = 0.75;
           pulseDoctorHand.x = 300;
           pulseDoctorHand.y = -300;
           this.container.addChild(pulseDoctorHand);

           this.pulseBeginTween = createjs.Tween.get(pulseDoctorHand, { paused: true })
               .to({ x: 300, y: -2 }, 1000, createjs.Ease.circOut);
           pulseDoctorHand.x = 550;
           pulseDoctorHand.y = -2;
           this.pulseLoopTween = createjs.Tween.get(pulseDoctorHand, { loop: true, paused: true })
               .wait((delay))
               .to({ y: 0 }, 150)
               .to({ y: -2 }, 150);
       };

       Hide() {
           this.clockService.Hide();
           this.container.visible = false;
           this.pulseBeginTween.setPaused(true);
       };

       Show() {
           this.clockService.Show(20, 200);
           this.container.visible = true;
           this.pulseBeginTween.setPaused(false).play(this.pulseLoopTween);
       };
       Start() {
         this.clockService.Start();
       }

  constructor(private clockService: ClockService, private sg: SimpleGlobal) { }

}
