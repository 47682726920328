import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BillingPopupComponent } from 'app/billing/billing-popup/billing-popup.component';
import { SimpleGlobal } from 'ng2-simple-global';
import { environment } from '../../../environments/environment';
import { User } from '../../_models';
import { UserService } from '../../_services';

@Component({
  selector: 'app-page-top',
  templateUrl: './page-top.component.html',
  styleUrls: ['./page-top.component.scss'],
})
export class PageTopComponent implements OnInit, AfterViewInit {
  user: User;
  casename;
  patientName;
  title;
  blurb;
  instance;
  subscribed = false;
  loggedIn = false;
  public hideButton: boolean;

  menuState = 'in';
  contentState = 'in';

  public isMenuShouldCollapsed = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    public sg: SimpleGlobal,
    public dialog: MatDialog,
    private userService: UserService
  ) {
    router.events.subscribe(() => {
      this.title = this.sg['pageTitle'];
      this.blurb = this.sg['pageBlurb'];
      this.patientName = this.sg['patient_name'];

      if (this.sg['instance_name'] == undefined) {
        this.instance = 'CyberPatient';
      } else {
        this.instance = this.sg['instance_name'] + ' | ';
      }
      this.checkUser();
    });
  }

  ngOnInit() {
    this.checkUser();
    this.title = this.sg['pageTitle'];
    this.title = this.sg['pageBlurb'];
    this.isEnableButton();
  }

  ngAfterViewInit(): void {
    this.initGoogleTranslate();
  }

  openBilling() {
    this.dialog.open(BillingPopupComponent);
  }

  checkUser() {
    this.userService.userChange.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.contentState = this.contentState === 'out' ? 'in' : 'out';
    const val1 = this.menuState;
    const val2 = this.contentState;
    this.notify.emit({ val1, val2 });
  }

  isEnableButton() {
    if (environment.production === true) {
      this.hideButton = true;
    } else {
      this.hideButton = false;
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    const isMenuShouldCollapsed = this._shouldMenuCollapse();

    if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
      this.menuState = 'out';
      this.contentState = 'out';
      const val1 = this.menuState;
      const val2 = this.contentState;
      this.notify.emit({ val1, val2 });
    }
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= 960;
  }

  private initGoogleTranslate() {
    const translateScript = document.createElement('script');
    translateScript.type = 'text/javascript';
    translateScript.textContent = `
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'en'},'google_translate_element');
      }
    `;

    const googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

    document.body.appendChild(translateScript);
    document.body.appendChild(googleScript);
  }
}
