import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxLoadingComponent } from './ngx-loading.component';

@NgModule({
  declarations: [NgxLoadingComponent],
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
  ],
  exports: [NgxLoadingComponent],
})
export class NgxLoadingModule { }
