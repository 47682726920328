import { Injectable } from '@angular/core';
import { CreatejsShapeService } from './createjs-shape.service';

@Injectable()
export class ZoomService {

  constructor(private createjsShapeService: CreatejsShapeService) { }

  tools = [];
  bg;
  hitContainer = new createjs.Container();

      init(manifests) {
        var that = this;
          var loaderManifest = [];

          for (let manifest of manifests) {
              //setup hit object
              var shape = this.createjsShapeService.createShape(manifest.hitArea.shapes);
              shape.x = manifest.hitArea.x;
              shape.y = manifest.hitArea.y;
              that.hitContainer.addChild(shape);

              that.tools[manifest.type] = {
                  hitObject: shape
              };

              // console.log("tools start",that.tools);
          };
      };

      getHitContainer() {
          return this.hitContainer;
      };

      checkClick(selectedTool, x, y) {

      //    if (selectedTool === 'zoom') {

            for(let tool of this.tools) {
              // console.log("toolinside", tool);
              var pt = tool.hitObject.globalToLocal(x, y);
              // console.log(pt);
              if (tool.hitObject.hitTest(pt.x, pt.y)) {
                // console.log('TOOLTOOL', tool);
                  return tool;
              }
        //    }
          }
          return '';
      };

}
