import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { EpaAnswerPerSession } from './_models';

@Injectable()
export class chartScoreShareService {
    private chartAnswers = new BehaviorSubject<EpaAnswerPerSession>(null);
    private totalChartScore = new BehaviorSubject(0.00);
    currentScore = this.totalChartScore.asObservable();

    constructor() {
    }


    
    updateChartAnswer(answer:EpaAnswerPerSession){
        this.chartAnswers.next(answer);
        this.chartAnswers.complete();
    }

    getAnswer():Observable<EpaAnswerPerSession> {
        return  this.chartAnswers.asObservable()
    }
}