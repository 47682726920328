import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { throwError as observableThrowError } from 'rxjs';
import 'rxjs/add/operator/map';

import { catchError, map } from 'rxjs/operators';
import 'rxjs/Rx';

@Injectable()
export class SaveAssignmentDataService {
  id: string;
  public headers3;
  public currentUser;
  keys: String[];
  private urlSaveData = '/api/v1/sessioncheckpoint/';
  private urlCreateAssignment = '/api/v1/assignments/';
  private urlSaveMistakeCounter = '/api/v1/sessionmistakes';
  private urlSavePatientChart = '/api/v1/sessionpatientchart';
  private urlterminateSession = '/api/v1/terminatesession';
  private urlSubmitEPAScores = '/api/v1/epaFinal';
  private urlChangeEmail = '/api/v1/auth/changeEmail';
  private urlChangePassword = '/api/v1/auth/changepwd';
  private urlChangeSecurity = '/api/v1/auth/changesecretq';
  private urlConfirmEmail = '/api/v1/auth/confirmEmail?token=';
  private urlUpdateSkillSet = '/api/v1/auth/upgradeSkillset?student_email=';
  private urlOnboarding = '/api/v1/onboarding?onboardingCategory='
  private urlAddFavourite = '/api/v1/addFave?case_id=';
  private urlRemoveFavourite = '/api/v1/removeFave?case_id=';
  private urlFeedbackComment = '/api/v1/userfeedback';

  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.urlSaveData = this.sg['url'].concat(this.urlSaveData);
    this.urlCreateAssignment = this.sg['url'].concat(this.urlCreateAssignment);
    this.urlSaveMistakeCounter = this.sg['url'].concat(
      this.urlSaveMistakeCounter
    );
    this.urlSavePatientChart = this.sg['url'].concat(this.urlSavePatientChart);
    this.urlterminateSession = this.sg['url'].concat(this.urlterminateSession);
    this.urlSubmitEPAScores = this.sg['url'].concat(this.urlSubmitEPAScores);
    this.urlChangeEmail = this.sg['url'].concat(this.urlChangeEmail);
    this.urlChangeSecurity = this.sg['url'].concat(this.urlChangeSecurity);
    this.urlUpdateSkillSet = this.sg['url'].concat(this.urlUpdateSkillSet);
    this.urlOnboarding = this.sg['url'].concat(this.urlOnboarding);
    this.urlChangePassword = this.sg['url'].concat(this.urlChangePassword);
    // this.urlConfirmEmail = this.sg['url'].concat(this.urlConfirmEmail);
    // var temp = "https://staging.cyberpatient.ca";
    this.urlConfirmEmail = this.sg['url'].concat(this.urlConfirmEmail);
    this.urlAddFavourite = this.sg['url'].concat(this.urlAddFavourite);
    this.urlRemoveFavourite = this.sg['url'].concat(this.urlRemoveFavourite);
    this.urlFeedbackComment = this.sg['url'].concat(this.urlFeedbackComment);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  sendQuery(body, component, instance) {
    const url = this.urlSaveData.concat(
      '?session_id=' +
      this.sg['session_id'] +
      '&component=' +
      component +
      '&instance=' +
      instance
    );
    return this.http
      .put(url, body).pipe(
        map((response: any) => {
          if (response.message._id != null) {
            //   return true; {}

            return response;
          } else {
            return null;
          }
        }),
        catchError(this.handleError),
      );
  }

  addFavourite(case_id) {

    const url = this.urlAddFavourite.concat(case_id);
    return this.http.put(url, {}).pipe(
      map((response: any) => {
        if (response.message != null) {
          //   return true; {}

          return response;
        } else {
          return null;
        }
      }),
      catchError(this.handleError), );


  }

  removeFavourite(case_id) {


    const url = this.urlRemoveFavourite.concat(case_id);
    return this.http.put(url, {}).pipe(
      map((response: any) => {
        if (response.message != null) {
          //   return true; {}

          return response;
        } else {
          return null;
        }
      }),
      catchError(this.handleError), );


  }

  submitFeedbackComment(comment) {

    // let url = this.urlRemoveFavourite.concat(case_id);
    return this.http.post(this.urlFeedbackComment, comment).pipe(
      map((response: any) => {
        if (response.message === 'saved successfully') {
          //   return true; {}

          return response.result;
        } else {
          return null;
        }
      }),
      catchError(this.handleError), );

  }

  saveAudioFile(file, epa, audience) {
    const url = this.sg['url'].concat(
      '/api/v1/uploadaudio?session_id=' +
      this.sg['session_id'] +
      '&epa=' +
      epa +
      '&audience=' +
      audience
    );

    const fd = new FormData();
    fd.append('file', file);


    return this.http
      .post(url, fd).pipe(
        map((response: any) => {

          if (response.message === 'success') {
            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));
  }

  updateOnboarding(keyName) {
    const url = this.urlOnboarding.concat(keyName);
    const body = {};
    body[keyName] = true;
    return this.http.put(url, body).pipe(map((response: any) => {
      return null;
    }), catchError(this.handleError));
  }

  changeEmail(body) {
    return this.http
      .post(this.urlChangeEmail, body).pipe(
        map((response: any) => {
          if (response.message === 'email sent') {
            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));
  }

  changePassword(body) {

    return this.http
      .post(this.urlChangePassword, body).pipe(
        map((response: any) => {
          if (response.message === 'password changed successfully') {
            const username = response.email;
            const token = response.token;
            const permissions = response.permissions;
            localStorage.setItem('currentUser', JSON.stringify({
              'username': username,
              'token': token,
              'role': permissions
            }));
            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));

  }

  confirmEmail(token) {
    return this.http
      .get(this.urlConfirmEmail.concat(token)).pipe(
        map((response: any) => {
          if (response.message === 'email sent') {
            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));
  }

  changeSecurity(body) {

    return this.http
      .post(this.urlChangeSecurity, body).pipe(
        map((response: any) => {
          if (response.message === 'security questions updated') {

            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));
  }

  sendMistakeCounter(body) {
    const url = this.urlSaveMistakeCounter.concat(
      '?session_id=' + this.sg['session_id']
    );
    return this.http.put(url, body).pipe(map((response: any) => {
      if (response.message._id != null) {
        //   return true; {}
        return response;
      } else {
        return null;
      }
    }), catchError(this.handleError));
  }

  createAssignment(body) {
    return this.http
      .post(this.urlCreateAssignment, body).pipe(
        map((response: any) => {
          if (response.message._id != null) {
            return true;
          } else {
            return false;
          }
        }), catchError(this.handleError));
  }

  savePatientChart(chart) {
    const url = this.urlSavePatientChart.concat(
      '?session_id=',
      this.sg['session_id']
    );

    return this.http.put(url, chart).pipe(map((response: any) => {
      if (response.message._id != null) {
        return true;
      } else {
        return false;
      }
    }), catchError(this.handleError));
  }

  terminateSession(body, component, instance) {
    this.sg['hardFeedback'] = false;

    const url = this.urlterminateSession.concat(
      '?session_id=' +
      this.sg['session_id'] +
      '&component=' +
      component +
      '&instance=' +
      instance
    );
    return this.http.post(url, body).pipe(map((response: any) => {
      if (response.message._id != null) {
        //   return true; {}

        return response;
      } else {
        return null;
      }
    }), catchError(this.handleError));
  }

  saveEPAScores(payload) {
    const url = this.urlSubmitEPAScores.concat(
      '?session_id=5b3e5d7aec9c4a000f1290dd'
    );
    return this.http
      .post(url, payload).pipe(
        map((response: any) => {
          if (response.message.status === 'completed') {
            //   return true; {}

            return response;
          } else {
            return null;
          }
        }), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
