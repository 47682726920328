import { Injectable } from '@angular/core';
import { CreatejsShapeService } from './createjs-shape.service';

@Injectable({
  providedIn: 'root',
})
export class DetailImageService {
  tools = [];
  bg;
  hitContainer = new createjs.Container();

  constructor(private createjsShapeService: CreatejsShapeService) {
  }

  init(manifests) {
    const that = this;
    const loaderManifest = [];

    for (const manifest of manifests) {
      // setup hit object
      const shape = this.createjsShapeService.createShape(manifest.hitArea.shapes);
      shape.x = manifest.hitArea.x;
      shape.y = manifest.hitArea.y;

      that.hitContainer.addChild(shape);

      that.tools[manifest.type] = {
        hitObject: shape,
        src: manifest.src
      };

    }
  };

  getHitContainer() {
    return this.hitContainer;
  };

  checkClick(selectedTool, x, y, zoom) {
    const tool = this.tools[selectedTool];
    if (tool != null) {
      if (zoom === 'Head') {
        const pt = tool.hitObject.globalToLocal(x, y);
        if (tool.hitObject.hitTest(pt.x, pt.y)) {
          return tool.src;
        }
      } else {
        return '';
      }
    }
    return '';
  };

}
