import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { course, student, SummativeFeedback } from '../../_models/teacher-dashboard.model';
import { TeachDashBoardModel, TeachDashBoardState, } from '../../state/teacherdashboard.state';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-formative-assessment',
  templateUrl: './formative-assessment.component.html',
  styleUrls: ['./formative-assessment.component.scss']
})
export class FormativeAssessmentComponent implements OnInit {

  Stringyfy
  @Select(TeachDashBoardState.getTeacherDashBoardState)
  storeTeacherDashboardState: Observable<TeachDashBoardModel>;
  myCourse: course;
  loading;
  coursecaseDataSource = new MatTableDataSource<any>();
  mystudent: student;
  @ViewChild('casepaginator', {static: true}) casepaginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  feedbackcolumns = ['casehistory', 'attempts', 'time', 'score', 'action'];
  courseCases = ['no', 'icon', 'name', 'category'];
  latest: SummativeFeedback;
  grades: any;
  average: Number;
  err;
  loadingAndSaving: boolean;
  completedCases = [];
  filterWithin

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private teacherService: TeacherDashboardDataService,
    private _location: Location,
  ) {
  }

  ngOnInit() {
    this.storeTeacherDashboardState.pipe(take(1)).subscribe((x) => {
      this.mystudent = x.selectedstudent;
      this.myCourse = x.selectedcourse;

      if (this.mystudent == null) {
        const path = '/teacher-dashboard';
        this.router.navigate([path], {skipLocationChange: false});
      } else {

        this.getGradesOverview();
      }
    })
  }

  backClicked() {
    this._location.back();
  }

  getGradesOverview() {

    this.loadingAndSaving = true;
    if (this.mystudent) {
      this.teacherService.getGradesOverviewUser(this.mystudent._id, this.myCourse._id)
        .subscribe(data => {
        this.coursecaseDataSource.data = this.myCourse.cases;

        this.grades = data.message['caseData'] ? data.message['caseData'] : [];
        if (this.grades) {
          const arr = this.grades.filter(x => this.myCourse.cases.some(y => y._id === x.case_id));
          // This filter needs to be applied
          //  this.completedCases= arr.filter(x=>
          //   this.teacherService.dateCheck(this.myCourse.start,this.myCourse.end, x.last_completed));
          // Removing this filter until best way to figure out timezones..
          this.completedCases = arr;
        }
        this.loadingAndSaving = false;
      }, err => {
        this.err = err;
        this.loadingAndSaving = false;
      });
    }
  }

}
