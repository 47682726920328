<div class="py-5">
  <div class="text-color-primary mx-auto text-center">
    <div class="h4 font-weight-bolder">Ready for your journey with</div>
    <div class="h3 font-weight-bolder">CyberPatient™?</div>
  </div>

  <div class="text-center mt-4">
    <img src="/assets/img/app/MedPost_md.png" width="500px"/>
  </div>

  <div *ngIf="!feedbackGiven"
       class="d-flex text-muted justify-content-center align-items-center">
    <small>Did you find these instructions helpful?</small>
    <button (click)="feedback(true)"
            class="text-color-white bg-color-primary rounded-circle p-2 ml-3 mr-2">
      <i class="fa fas fa-thumbs-up"></i>
    </button>
    <button (click)="feedback(false)"
            class="bg-color-danger text-color-white rounded-circle p-2">
      <i class="fa fas fa-thumbs-down"></i>
    </button>
  </div>

  <div *ngIf="feedbackGiven" class="text-muted text-center small">
    Thank you for your feedback!
  </div>

  <button mat-button
          class="d-block mx-auto text-color-primary font-weight-bold"
          style="font-size: 1.1rem !important;"
          (click)="confirm()">
    Go to simulations library
  </button>
  <button mat-button
          class="d-block mx-auto"
          (click)="cancel()">
    I'll do it later
  </button>
</div>
