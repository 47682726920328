import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { GeneralDialogModule } from '@canhealth/cyberpatient-ui';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CsvUploaderModule } from '../../csv-uploader/csv-uploader.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactsService } from '../services/contacts.service';
import { InstitutionService } from '../services/institution.service';
import { CaseListComponent } from './case-list/case-list.component';
import { StatsComponent } from './stats/stats.component';
import { ChartScrollableComponent } from './stats/stats-scroll/stats-scroll.component';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { InviteFacultyDialogComponent } from './invite-faculty-dialog/invite-faculty-dialog.component';
import { TutorialDialogComponent } from 'app/case-library/simulation-thumbnail/tutorial-dialog/tutorial-dialog.component';
import { InviteStudentDialogComponent } from './invite-student-dialog/invite-student-dialog.component';
import { HelpDialog } from './help-dialog/help-dialog.component';
import { NursingDialogComponent } from './nursing-dialog/nursing-dialog.component';
import { OnboardingDialogComponent } from './onboarding-dialog/onboarding-dialog.component';
import { CourseKeywordPipe, CourseStatusPipe } from './pipes/course-filter.pipe';
import { MemberKeywordPipe } from './pipes/member-filter.pipe';
import { SkillLevelPipe } from './pipes/skill-level.pipe';
import { StudentListComponent } from './student-list/student-list.component';
import { ShareUrlDialogComponent } from './share-url-dialog/share-url-dialog.component';
import { CaseLibraryOnboardingDialogComponent } from './onboarding-dialog/case-library-onboarding-dialog/case-library-onboarding-dialog.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SessionHighestScorePipe } from './pipes/session-highest-score.pipe';
import { PendingInviteDialogComponent } from './pending-invite-dialog/pending-invite-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    CsvUploaderModule,
    GeneralDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ClipboardModule,
    MatBadgeModule,
    ChartsModule,
  ],
  declarations: [
    InviteFacultyDialogComponent,
    TutorialDialogComponent,
    InviteStudentDialogComponent,
    HelpDialog,
    NursingDialogComponent,
    ContactDialogComponent,
    CaseListComponent,
    StatsComponent,
    ChartScrollableComponent,
    StudentListComponent,
    MemberKeywordPipe,
    CourseStatusPipe,
    CourseKeywordPipe,
    SkillLevelPipe,
    ShareUrlDialogComponent,
    OnboardingDialogComponent,
    CaseLibraryOnboardingDialogComponent,
    SessionHighestScorePipe,
    PendingInviteDialogComponent,
  ],
  entryComponents: [
    InviteFacultyDialogComponent,
    TutorialDialogComponent,
    InviteStudentDialogComponent,
    HelpDialog,
    NursingDialogComponent,
    ContactDialogComponent,
    ShareUrlDialogComponent,
    OnboardingDialogComponent,
    CaseLibraryOnboardingDialogComponent,
    PendingInviteDialogComponent,
  ],
  exports: [
    InviteFacultyDialogComponent,
    TutorialDialogComponent,
    InviteStudentDialogComponent,
    HelpDialog,
    NursingDialogComponent,
    ContactDialogComponent,
    CaseListComponent,
    StatsComponent,
    StudentListComponent,
    MemberKeywordPipe,
    CourseStatusPipe,
    CourseKeywordPipe,
    SkillLevelPipe,
  ],
  providers: [InstitutionService, ContactsService, ThemeService],
})
export class SharedComponentsModule {}
