<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header"><h1>Create an Account</h1></div>
        <!-- <div class="form-subheader">
          <p>Don't have a CyberPatient Account?</p>
          <p><a href="/recover">Create a new account</a></p>
        </div> -->
        <form [formGroup]="registrationpersonalform" class="login-form">
          <div class="flex-row">
            <div class="form-element">
              <label> First Name </label>
              <input
                id="fname"
                type="text"
                class="lf--input"
                formControlName="firstName"
                [(ngModel)]="model.fname"
                username="ngModel"
              />
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element">
              <label> Last Name </label>
              <input
                id="lastName"
                type="text"
                class="lf--input"
                formControlName="lastName"
                [(ngModel)]="model.lname"
                password="ngModel"
              />
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element">
              <label> Institution Name</label>
              <input
                id="institutionName"
                type="text"
                class="lf--input"
                formControlName="institutionName"
                [(ngModel)]="model.iname"
                password="ngModel"
              />
            </div>
          </div>
          <div class="flex submit">
            <button type="submit" value="LOGIN" class="action mat-button mat-raised-button mat-primary">
              Create Account
            </button>
          </div>
        </form>
        <p class="copyright">© 2022 All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
