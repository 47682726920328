import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'student-confirm',
  templateUrl: './student-confirm.component.html',
  styleUrls: ['./student-confirm.component.scss']
})
export class StudentConfirmComponent implements OnInit {

  constructor(
   public dialogRef: MatDialogRef<StudentConfirmComponent>,
   @Inject(MAT_DIALOG_DATA) public data: any) { }

 onNoClick(): void {
   this.dialogRef.close();
 }

  ngOnInit() {
  }

}
