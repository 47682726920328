// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-qa.cyberpatient.ca',
  // apiUrl: "https://api-pr-83.cyberpatient.ca",
  epaApiUrl: 'https://epa-api-qa.cyberpatient.ca',
  stripeKey: 'pk_test_lcrIyTDCS2ecayR6RiAXHsLE00cxKMo2vr',
  mixpanelToken: 'b138b004a63fa533eca718567d6ad4d4',
  helpSurveyId: '',
  emailCampaignId: '6298efdcbb7bded393d6e1b8',
  debug: true,
  showEpa: true,
  alphaDebug: 0,
  v3Url: 'https://v2-dev.cyberpatient.ca',
};
