import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TeacherDashboardDataService } from '../teacher-dashboard/teacher-dashboard-data.service'
import {
  CaseRow,
  course,
  FormativeFeedback,
  instructor,
  invitedstudent,
  student,
  SummativeFeedback,
  userid
} from './../_models/teacher-dashboard.model'
import {
  AddCaseCurrentCourse,
  AddCourse,
  AddFormativeToCurrentStudent,
  AddStudentCurrentCourse,
  AddSummativeToCurrentStudent,
  EditCourse,
  GetCourses,
  GetCurrentInstructor,
  GetFormativeCourse,
  GetSummativeCurrentStudent,
  RemoveCaseCurrentCourse,
  RemoveCourse,
  RemoveFromInviteList,
  RemoveStudentCurrentCourse,
  SetCurrentCourse,
  SetInviteList,
  SetSelectedCase,
  SetSelectedStudent,
  SetStudent
} from './../actions/teacherdashboard.action'

export class TeachDashBoardModel {
  instructor: instructor;
  selectedcaseid: CaseRow;
  selectedcourse: course;
  selectedstudent: student;
  instructorselectedsummative: SummativeFeedback[];
  inviteList: invitedstudent[];
  formativefeedback: FormativeFeedback[];
  mycourses: course[];
  userid: userid;
}

@State<TeachDashBoardModel>({
  name: 'teacherdashboard',
  defaults: {
    inviteList: null,
    userid: null,
    formativefeedback: null,
    selectedcaseid: null,
    selectedstudent: null,
    instructorselectedsummative: null,
    selectedcourse: null,
    mycourses: null,
    instructor: null
  }
})
@Injectable()
export class TeachDashBoardState {

  constructor(private teacherDashboardDataService: TeacherDashboardDataService) {

  }

  @Selector()
  static getTeacherDashBoardState(state: TeachDashBoardModel) {
    return state;
  }

  @Action(GetCurrentInstructor)
  getInstructor(ctx: StateContext<TeachDashBoardModel>) {
    return this.teacherDashboardDataService.getCurrentInstructor().pipe(tap(i => {
      ctx.setState((state) => ({...state, instructor: i}));
    }))
  }

  @Action(GetCourses)
  getCourses(ctx: StateContext<TeachDashBoardModel>) {
    return this.teacherDashboardDataService.getClassList().pipe(tap(c => {
      const courses = c as unknown as course[];
      ctx.setState((state) => ({
        ...state,
        mycourses: courses,
      }));
    }))
  }

  @Action(SetStudent)
  SetStudent({getState, setState}: StateContext<TeachDashBoardModel>, {payload}: SetStudent) {
    setState((state) => ({
      ...state,
      userid: payload,
    }));
  }

  @Action(SetCurrentCourse)
  setCurrentcourse({getState, setState}: StateContext<TeachDashBoardModel>, {payload}: SetCurrentCourse) {
    setState((state) => ({
      ...state,
      selectedcourse: payload,
    }));
  }

  @Action(SetSelectedStudent)
  setSelectedStudent({getState, patchState}: StateContext<TeachDashBoardModel>, {payload}: SetSelectedStudent) {
    const state = getState();
    patchState({selectedstudent: payload})
  }

  @Action(SetSelectedCase)
  setSelectedCase({patchState}: StateContext<TeachDashBoardModel>, {payload}: SetSelectedCase) {
    patchState({selectedcaseid: payload})
  }

  @Action(SetInviteList)
  setInviteList(ctx: StateContext<TeachDashBoardModel>) {
    const state = ctx.getState();
    return this.teacherDashboardDataService.getInvitedList(state.selectedcourse._id).pipe(tap(c => {
        ctx.setState({...state, inviteList: c});
      })
    )
  }

  @Action(AddCourse)
  addCourse(ctx: StateContext<TeachDashBoardModel>, {payload}: AddCourse) {
    return this.teacherDashboardDataService.addCourse(payload).pipe(tap(c => {
        const cx: course = c;
        const state = ctx.getState();
        ctx.setState(
          patch({
            mycourses: append([cx]),
          }),
        );

        ctx.setState({...state, selectedcourse: cx});
      })
    )
  }

  @Action(EditCourse)
  editCourse(ctx: StateContext<TeachDashBoardModel>, {payload}: EditCourse) {
    return this.teacherDashboardDataService.editCourse(payload).pipe(
      tap(c => {
        const cx: course = c;
        ctx.setState(
          patch({
            mycourses: updateItem<course>(Course => Course._id == cx._id, payload)
          })
        );
        const state = ctx.getState();
        ctx.setState({...state, selectedcourse: payload});
      })
    )
  }

  @Action(RemoveCourse)
  removeCourse({getState, setState}: StateContext<TeachDashBoardModel>, {payload}: RemoveCourse) {
    const state = getState();
    const filteredArray = state.mycourses.filter(item => item._id !== payload._id);
    setState({
      ...state,
      mycourses: filteredArray,
    });
  }

  @Action(AddCaseCurrentCourse)
  AddCase(ctx: StateContext<TeachDashBoardModel>, {currentCourse, payload}: AddCaseCurrentCourse) {
    return this.teacherDashboardDataService.addCaseToClass(currentCourse, payload._id).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(c => {
        ctx.setState(patch<TeachDashBoardModel>({
          mycourses: updateItem<course>(c => c._id === c._id, c)
        }))
      }))
  }

  @Action(RemoveCaseCurrentCourse)
  RemoveCase(ctx: StateContext<TeachDashBoardModel>, {currentCourse, payload}: RemoveCaseCurrentCourse) {
    return this.teacherDashboardDataService.removeCaseFromClass(currentCourse, payload._id).pipe(tap(c => {
      ctx.setState(patch<TeachDashBoardModel>({
        mycourses: updateItem<course>(c => c._id === c._id, c)
      }))
    }))
  }

  @Action(AddStudentCurrentCourse)
  AddStudent(ctx: StateContext<TeachDashBoardModel>, {currentCourse, payload}: AddStudentCurrentCourse) {
    return this.teacherDashboardDataService.addStudentToClass(currentCourse, payload).pipe(tap(c => {
      ctx.setState(patch<TeachDashBoardModel>({
        mycourses: updateItem<course>(c => c._id === c._id, c)

      }))
      const state = ctx.getState();
      ctx.setState({...state, selectedcourse: c});
    }));
  }

  @Action(RemoveFromInviteList)
  RemoveFromInviteListt({setState}: StateContext<TeachDashBoardModel>, {courseid, email}: RemoveFromInviteList) {
    return this.teacherDashboardDataService.removeFromInvitedList(courseid, email).pipe(tap(c => {
      setState(
        patch({
          inviteList: removeItem<invitedstudent>(n => n.email == email)
        })
      )
    }))
  }

  @Action(RemoveStudentCurrentCourse)
  RemoveStudent({setState}: StateContext<TeachDashBoardModel>, {currentCourse, payload}: RemoveStudentCurrentCourse) {

    return this.teacherDashboardDataService.removeStudentFromClass(currentCourse, payload._id).pipe(tap(c => {
      setState(patch<TeachDashBoardModel>({
        mycourses: updateItem<course>(c => c._id === currentCourse._id, patch<course>({
          students: removeItem<student>(p => p._id === payload._id)
        }))
      }));
      setState(patch<TeachDashBoardModel>({
        selectedcourse: patch<course>({
          students: removeItem<student>(p => p._id === payload._id)
        })
      }));

    }))
  }

  @Action(GetSummativeCurrentStudent)
  GetSummative(ctx: StateContext<TeachDashBoardModel>) {
    const state = ctx.getState();
    return this.teacherDashboardDataService.getCurrentStudentSummativeAssessment(state.selectedcourse._id,
      state.selectedstudent._id).pipe(
      tap((i) => {
        ctx.setState({...state, instructorselectedsummative: i});
      }))
  }

  @Action(AddSummativeToCurrentStudent)
  AddSummative(ctx: StateContext<TeachDashBoardModel>, {payload}: AddSummativeToCurrentStudent) {
    const state = ctx.getState();

    return this.teacherDashboardDataService.addSummativeAssessment(payload)
      .pipe(tap(i => {
        const cx: SummativeFeedback = i;
        ctx.setState(
          patch({
            instructorselectedsummative: append([cx])
          })
        );
      }))
  }

  // @Action(GetFormativeCurrentStudent)
  // GetFormative(ctx: StateContext<TeachDashBoardModel>) {
  //     const state = ctx.getState();
  //     return this.teacherDashboardDataService.getFormativeInsturctorByCase(state.selectedcourse, state.selectedstudent, state.selectedcaseid)
  //         .pipe(tap(i => {
  //             ctx.setState({ ...state, selectedformatives: i });
  //         }))
  // }


  @Action(GetFormativeCourse)
  GetFormativeCourse(ctx: StateContext<TeachDashBoardModel>) {
    const state = ctx.getState();
    return this.teacherDashboardDataService.getFormativeInstructorFullCourse(state.selectedcourse, state.selectedstudent)
      .pipe(tap(i => {
        ctx.setState({...state, formativefeedback: i});
      }))
  }

  @Action(AddFormativeToCurrentStudent)
  AddFormative(ctx: StateContext<TeachDashBoardModel>, {payload}: AddFormativeToCurrentStudent) {
    const state = ctx.getState();
    return this.teacherDashboardDataService.addFormativeAssessment(state.selectedcourse._id, state.selectedstudent._id, payload, state.selectedcaseid.case_id)
      .pipe(tap(i => {
        const cx: FormativeFeedback = i;
        ctx.setState(
          patch({
            formativefeedback: append([cx])
          })
        );
      }))
  }
}
