import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FetchAssignmentDataService, SessionManagerService, UserService } from '../../_services';
import { SetCaseId, SetCurrentCase, UpdateCheckpointMessage } from '../../actions/checkpoint.action';
import { TestConfirmComponent } from '../../dashboard/stu-feed/test-confirm/test-confirm.component';
import { Course } from '../../lms/interfaces/course';
import { TeacherDashboardDataService } from '../../teacher-dashboard/teacher-dashboard-data.service';

@Injectable({
  providedIn: 'root',
})
export class CaseLibraryService {
  constructor(
    private store: Store,
    private sg: SimpleGlobal,
    private dialog: MatDialog,
    private userService: UserService,
    private sessionManagerService: SessionManagerService,
    private teacherDashboardService: TeacherDashboardDataService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private router: Router
  ) {}

  startCase(caseId: string, patientName: string, course: Course = null): Observable<any> {
    const isStudent = this.userService.user.institutionRole === 'student';
    this.store.dispatch(new StateResetAll());
    if (this.sg['session_id'] !== null) {
      if (caseId === this.sg['case_id']) {
        this.dialog.open(TestConfirmComponent, {
          data: { flag: true, case_id: caseId, course_id: course?._id, patient_name: patientName },
        });
      } else {
        this.dialog.open(TestConfirmComponent, {
          data: { flag: false, case_id: caseId, course_id: course?._id, patient_name: patientName },
        });
      }
      return of(true);
    } else {
      let session;
      if (isStudent && course) {
        session = this.teacherDashboardService.creatStudentSession(course._id, caseId);
      } else {
        session = this.sessionManagerService.createSession(caseId);
      }

      return session.pipe(
        tap(async (response: any) => {
          if (response.message === 'Session successfully created') {
            this.sg['session_id'] = response.session_id;
            this.sg['course_id'] = response.course_id;
            this.sg['case_id'] = response.result.case_id;
            this.sg['patient_name'] = response.patient_name;
            this.sg['skillset'] = response.result.skillset;
            this.sg['next_checkpoint'] = response.next_checkpoint['component'];
            this.sg['instance_id'] = response.next_checkpoint['instance'];
            this.sg['mistake_counter'] = response.result.mistake_counter.mistakes;

            this.store.dispatch([new SetCaseId(this.sg['case_id'])]);
            this.store.dispatch([new SetCurrentCase(response.session_id)]);
            this.fetchAssignmentDataService.getCheckPoints(this.sg['case_id']).subscribe((checkpoint) => {
              if (checkpoint) {
                this.store.dispatch([new UpdateCheckpointMessage(checkpoint)]);
              }
            });
            const path = '/assignment/'.concat(this.sg['next_checkpoint']);
            await this.router.navigate([path], { skipLocationChange: true });
          } else if (response.message === 'active session exists') {
            this.sg['session_id'] = response.session_id;
            this.startCase(caseId, patientName, course);
          } else {
            this.sg['session_id'] = null;
            this.sg['course_id'] = null;
            this.sg['case_id'] = null;
          }
        })
      );
    }
  }
}
