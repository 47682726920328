import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { BehaviorSubject, Observable, } from 'rxjs';
import { environment } from '../environments/environment';
import { CommunicationSet } from './_models/CommunicationSet.model';
import { EPAMnM, EPAMnmHeader } from './_models/epaMnM.model';
import { EPARecodingSection, epascore, finalScores, Recording, SavedRecords } from './_models/index';
import { SetEpaSessionId } from './actions/epa.action';
import { CheckPointState, CheckPointStateModel } from './state/checkpoints.state';

@Injectable({
  providedIn: 'root',
})
export class EpaConfigDataService {
  id: string;
  @Select(CheckPointState.getCurrentCheckPointState) storeCurrentCheckpointState: Observable<CheckPointStateModel>;
  sessid;
  private createUrl = environment.epaApiUrl;
  private epaSessionUrl = '/epa-sessions';
  private obj = new finalScores();
  private message = new BehaviorSubject<finalScores>(this.obj);
  public sessioncaseattempts = this.message.asObservable();
  private epalist: EPARecodingSection[] = [
    {
      EPA: 'EPA1',
      meetingwith: 'patient',
      epaIsRequired: true,
      Description: 'Patient',
      checklist: [
        'Communicate the condition of the patient to the necessary parties by recording youself below',
      ],
      recordings: {
        file: '',
        blob: '',
        created: '',
        caseid: '',
        time: '',
      },
      expanded: false,
      component: '',
      instance: '',
      score: 0,
      dbsaved: false,
      answeRecording: null,
      caseid: this.sg['case_id']
    },
    {
      EPA: 'EPA2',
      epaIsRequired: true,
      meetingwith: 'family',
      Description: 'Family',
      checklist: [
        'Obtain hstory and perform a physical exam adapted to the patients situation.Record yourself speaking to the appropriate parties below',
      ],
      recordings: {
        file: '',
        blob: '',
        created: '',
        caseid: '',
        time: '',
      },
      expanded: false,
      component: '',
      score: 0,
      instance: '',
      answeRecording: null,
      dbsaved: false,
      caseid: this.sg['case_id']
    },
    {
      EPA: 'EPA3',
      epaIsRequired: true,
      meetingwith: 'careTaker',
      Description: 'Caretaker',
      checklist: [
        'Communicate the outcomes of your investigations, management plan, the patient situation, an explain your final diagnosis with the appropriate parties by recording yourself below',
      ],
      recordings: {
        file: '',
        blob: '',
        created: '',
        caseid: '',
        time: '',
      },
      expanded: false,
      component: '',
      score: 0,
      instance: '',
      answeRecording: null,
      dbsaved: false,
      caseid: this.sg['case_id']
    },
    {
      EPA: 'EPA4',
      epaIsRequired: true,
      meetingwith: 'social',
      Description: 'Social Worker',
      checklist: [
        'Obtain hstory and perform a physical exam adapted to the patients situation.Record yourself speaking to the appropriate parties below',
      ],
      recordings: {
        file: '',
        blob: '',
        created: '',
        caseid: '',
        time: '',
      },
      expanded: false,
      component: '',
      instance: '',
      score: 0,
      answeRecording: null,
      dbsaved: false,
      caseid: this.sg['case_id']
    },
    {
      EPA: 'EPA5',
      epaIsRequired: true,
      meetingwith: 'medical',
      Description: 'Medical Team',
      checklist: [
        'Communicate your findings so far and next steps in your patient care plan by recording youself below',
      ],
      recordings: {
        file: '',
        blob: '',
        created: '',
        caseid: '',
        time: '',
      },
      expanded: false,
      component: '',
      instance: '',
      score: 0,
      answeRecording: null,
      dbsaved: false,
      caseid: this.sg['case_id']
    },
  ];

  private mnmData: EPAMnM[] = [
    {
      topic: 'Topic 1',
      header: 'Case presentation:',
      inputValues: [
        {
          label: 'Brief summary :',
          placeholder:
            ' e.g.  patient information: Age/Presentation/Vital signs/+ve,-ve physical and history findings \n\r e.g. Patient privacy: Should not be any patient identifiers',
          value: null,
        },
        {
          label: 'Lab tests, Treatments:',
          placeholder:
            ' e.g. relevant +ve/-ve Lab tests/Procedures e.g. Medications/Interventions',
          value: null,
        },
        {
          label: 'complications :',
          placeholder:
            ' e.g. relevant +ve/-ve Lab tests/Procedures e.g. Medications/Interventions',
          value: null,
        },
      ],
    },
    {
      topic: 'Topic 2',
      header: 'Patient-related issues:',
      inputValues: [
        {
          label: 'Patient communication barrier:',
          placeholder:
            'e.g.  language, behavior, intoxication,  obtunded,  critically  ill,  etc',
          value: null,
        },
        {
          label: 'Personal competency error :',
          placeholder:
            'e.g. procedure, interpretation of ECGs, laboratory/diagnostic imaging tests',
          value: null,
        },
        {
          label: 'Personal complacency to routine:',
          placeholder:
            'e.g.  failure  of  routine behaviors(regular bedside care, attention to vital signs and appropriate monitoring)',
          value: null,
        },
        {
          label: 'Personal impairment:',
          placeholder: ' e.g.  fatigue, illness, emotional distress',
          value: null,
        },
      ],
    },
    {
      topic: 'Topic 3',
      header: 'Healthcare & System-related issues:',
      inputValues: [
        {
          label: 'Teamwork issues:',
          placeholder:
            'e.g. Communication breakdown at any level (Team member/shift/teams/specialty boundaries)',
          value: null,
        },
        {
          label: 'Assignment of unqualified personnel:',
          placeholder:
            'e.g. Supervision breakdown at any level (Resident/ student)',
          value: null,
        },
        {
          label: 'Hospital and System issues: ',
          placeholder:
            ' e.g. staffing, stocking, equipment. services, e.g.consultants, beds, budgetary constraints,  e.g. policies & guidelines issues,',
          value: null,
        },
        {
          label: 'External contributors:',
          placeholder: 'e.g. paramedic services/ regulations/priorities',
          value: null,
        },
      ],
    },
    {
      topic: 'Topic 4',
      header: 'Action Items to mitigate adverse outcome in the future:',
      inputValues: [
        {
          label: 'Strategies to avoid cognitive bias:',
          placeholder: ' e.g. patient and/or personal cognitive bias',
          value: null,
        },
        {
          label: 'Education to improve:',
          placeholder:
            ' e.g. practice guidelines, policies, procedures ,e.g. individual/team training (use of simulation)',
          value: null,
        },
        {
          label: 'Systemic Changes to improve:',
          placeholder: ' e.g. Changes to department/division/hospital/external',
          value: null,
        },
        {
          label: 'Actions for implementation:',
          placeholder:
            'e.g. appointing a team/individual to follow-up implementation',
          value: null,
        },
      ],
    },
  ];
  private epamnmheader: EPAMnmHeader = {
    header: 'Mobidity and Mortality Prep',
    description:
      'Make slide for your presentation in the text boxes below,and prepare to present it to the auditorium. You will have 5 minutes to prepare an oral presenation',
    data: this.mnmData,
  };

  constructor(private http: HttpClient, private sg: SimpleGlobal, private store: Store) {
  }

  async createSessionId(): Promise<string> {
    this.sg['epaurl'] = environment.epaApiUrl;
    this.createUrl = this.sg['epaurl'].concat(this.epaSessionUrl);
    let mysession = '';
    this.storeCurrentCheckpointState.subscribe(x => mysession = x.currentsessionid);
    const body = {caseId: this.sg['case_id'], sessionId: mysession};
    const response = await this.http
      .post(this.createUrl, body)
      .toPromise();
    this.store.dispatch([new SetEpaSessionId(response['_id'])])
    return response['_id'];
  }

  async saveMnmNotes(form): Promise<any> {
    this.sessid = await this.createSessionId();
    const body = form;
    const saveurl =
      this.sg['epaurl'] + '/epa-sessions/' + this.sessid + '/mnm-notes/';
    return await this.http
      .post(saveurl, body)
      .toPromise();
  }

  async getRecordData(mysession: string): Promise<SavedRecords[]> {
    const geturl = this.sg['epaurl'].concat(this.epaSessionUrl + '?sessionId=' + mysession);
    return await this.http.get<SavedRecords[]>(geturl).toPromise();
  }

  async getEpaPeformanceData(val: finalScores) {
    this.message.next(val);
  }


  async getEpaScores(): Promise<epascore[]> {
    return [];
  }

  async getRecorderUrl(data: Recording) {
    const geturl = this.sg['epaurl'].concat(this.epaSessionUrl + '/' + data.epaSessionId + '/recordings/' + data._id);
    return await this.http.get<Recording>(geturl).toPromise();
  }

  async saveRecordData(): Promise<string> {
    this.sessid = await this.createSessionId();
    return '';
  }

  getComponentData(): Observable<CommunicationSet[]> {
    const data: CommunicationSet[] = [
      {
        component: 'proDiagnosisCom',
        description:
          'Communicate the status of the patient and your immediate care plans to the necessary parties below.',
        subtext: '',
      },
      {
        component: 'procedureComOne',
        description:
          'Communicate and justify your differential diagnosis based on your clinical assessment, and describe the next steps in your immediate care plan with the appropriate parties. Record your messages below.',
        subtext: '',
      },
      {
        component: 'procedureComTwo',
        description:
          'Explain the details of your selected procedure and its possible risks with the appropriate parties by recording yourself below.',
        subtext: '',
      },
      {
        component: 'investigationEPA',
        description:
          'Please communicate the details of your investigation plan to the appropriate parties by recording yourself below.',
        subtext: '',
      },
      {
        component: 'communicationCrisis',
        description:
          'Communicate the details of your patient\'s crisis situation and the next steps in your care plan to appropriate parties. Record your messages below.',
        subtext: '',
      },
      {
        component: 'communicationSbar',
        description:
          'Please conduct handover communication using the structured communication tools provided for you in the patient chart. Record your messages to the necessary parties below.',
        subtext: '',
      },
      {
        component: 'mNmRoundsPresentation',
        description:
          'Press record when you are ready to begin your Mortality and Morbidity round presentation. You have five minutes. Flip through your slides as you go.',
        subtext:
          'Make Slide ofr your presentation in the text boxes below, and prepare to present it to the auditorium.You will have 5 Minutes to prepare an oral presentation',
      },
      {
        component: 'comRecommendations',
        description:
          'Communicate your patient discharge plan recommendations by using evidence-based information for sending the patient home. Talk to the appropriate parties by recording yourself below.',
        subtext: '',
      },
    ];

    return Observable.of(data);
  }

  getEPAs(): Observable<EPARecodingSection[]> {
    return Observable.of(this.epalist);
  }

  getEpaMnm(): Observable<EPAMnmHeader> {
    return Observable.of(this.epamnmheader);
  }

}
