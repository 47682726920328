<mat-card class="news">
  <div *ngIf="institutionPermissions === 'student' || institutionPermissions === 'instructor'">
    <mat-tab-group class="newsFeed">
      <mat-tab label="CyberPatient">
        <ngb-carousel class="no-indicators">
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <div class="img-feed">
                <img src="./assets/img/app/InstructorDashboardPanelcopy1.jpg" alt="Random first slide" />
              </div>
            </div>
            <span
              >The distance between theory and practice can be miles apart. Our interactive learning platform will
              bridge that gap.
            </span>
          </ng-template>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <div class="img-feed">
                <img src="./assets/img/app/InstructorDashboardPanelcopy2.jpg" alt="Random first slide" />
              </div>
            </div>
            <span
              >Our real-life clinical simulations without real-life consequences prepares you for when it does get
              real.</span
            >
          </ng-template>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <div class="img-feed">
                <img src="./assets/img/app/InstructorDashboardPanelcopy3.jpg" alt="Random first slide" />
              </div>
            </div>
            <span
              >The ultimate tool for faculty in preparing students for real-life by integrating all aspects of theory
              and practical knowledge.</span
            >
          </ng-template>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <div class="img-feed">
                <img src="./assets/img/app/InstructorDashboardPanelcopy4.jpg" alt="Random first slide" />
              </div>
            </div>
            <span
              >Our digital hospital can be accessed anytime, anywhere by all healthcare professionals. Practice to be
              prepared, practice to be precise.</span
            >
          </ng-template>
        </ngb-carousel>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span [matBadge]="newsfeed?.length" matBadgeOverlap="false">User Guides</span>
        </ng-template>

        <div class="news-item-container">
          <div class="news-item" *ngFor="let news of newsfeed | slice: 0:3; let i = index">
            <div class="news-item-head">
              <a routerLink="/news" routerLinkActive="active"
                ><span class="tag {{ news.tag }}">{{ news.tag }}</span>
                <h3 class="h-3">{{ news.title }}</h3>
              </a>
            </div>
            <div class="news-item-date p-4-subtitle">{{ news.date }}</div>
          </div>
        </div>
        <div class="header">
          <a routerLink="/news" routerLinkActive="active"> <div class="subtitle">See More</div></a>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</mat-card>
