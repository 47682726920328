<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header"><h1>Create an Account</h1></div>
        <!-- <div class="form-subheader">
          <p>Don't have a CyberPatient Account?</p>
          <p><a href="/recover">Create a new account</a></p>
        </div> -->
        <form [formGroup]="registrationaddressform" class="login-form">
          <div class="flex-row">
            <div class="form-element">
              <label> Address Line 1 </label>
              <input
                id="address1"
                type="text"
                class="lf--input"
                formControlName="address1"
                [(ngModel)]="model.address1"
              />
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element">
              <label> Address Line 2 </label>
              <input
                id="address2"
                type="text"
                class="lf--input"
                formControlName="address2"
                [(ngModel)]="model.address2"
              />
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element space-right">
              <label>City</label>
              <input id="city" type="text" class="lf--input" formControlName="city" [(ngModel)]="model.city" />
            </div>
            <div class="form-element space-left">
              <label>Province/State/Region</label>
              <input id="region" type="text" class="lf--input" formControlName="region" [(ngModel)]="model.region" />
            </div>
          </div>

          <div class="flex-row">
            <div class="form-element space-right">
              <label>Postal Code</label>
              <input id="postal" type="text" class="lf--input" formControlName="postal" [(ngModel)]="model.postal" />
            </div>
            <div class="form-element space-left">
              <label>Country</label>
              <mat-form-field class="follow countries" floatLabel="never">
                <mat-select placeholder="Select:" class="select" [(ngModel)]="model.country" name="choice">
                  <mat-option [value]="'Canada'" class="follow-up-option"> Canada </mat-option>
                  <mat-option [value]="'United States'" class="follow-up-option"> United States </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="flex submit">
            <button type="submit" value="LOGIN" class="action mat-button mat-raised-button mat-primary">
              Create Account
            </button>
          </div>
        </form>
        <p class="copyright">© 2022 All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
