import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-loading',
  templateUrl: './ngx-loading.component.html',
  styleUrls: ['./ngx-loading.component.scss']
})
export class NgxLoadingComponent implements OnInit {
  @Input() show: boolean;

  constructor() { }

  ngOnInit() {
  }

}
