import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AddSpacesToCapsPipe } from '../add-spaces-to-caps.pipe';
import { CamelcaseToTextPipe } from '../camelcase-to-text.pipe';
import { AnnouncementBannerComponent } from '../dashboard/announcement-banner/announcement-banner.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { KeyPipe } from '../key.pipe';
import { NgxLoadingModule } from '../ngx-loading/ngx-loading.module';
import { TruncateTextPipe } from '../truncate-text.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { Html2TextPipe } from './pipes/html2-text.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { UnderscoreSplitPipe } from './pipes/underscore-split.pipe';
import { IsAfterPipe } from './pipes/is-after.pipe';
import { IsBeforePipe } from './pipes/is-before.pipe';
import { IncludesPipe } from './pipes/includes.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatPaginatorModule,
    NgxLoadingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  ],
  declarations: [
    KeyPipe,
    TruncateTextPipe,
    AnnouncementBannerComponent,
    SafePipe,
    Html2TextPipe,
    HighlightPipe,
    AddSpacesToCapsPipe,
    CamelcaseToTextPipe,
    CapitalizePipe,
    UnderscoreSplitPipe,
    IsAfterPipe,
    IsBeforePipe,
    IncludesPipe,
  ],
  exports: [
    NgbModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxLoadingModule,
    FlexLayoutModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    ToastrModule,
    KeyPipe,
    TruncateTextPipe,
    AnnouncementBannerComponent,
    SafePipe,
    Html2TextPipe,
    HighlightPipe,
    AddSpacesToCapsPipe,
    CamelcaseToTextPipe,
    CapitalizePipe,
    UnderscoreSplitPipe,
    IsAfterPipe,
    IsBeforePipe,
    IncludesPipe,
  ],
})
export class SharedModule {}
