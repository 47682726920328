<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Resend Activation Email</h1>
        </div>
        <div class="form-subheader">
          <p>Please enter the email address associated with your account.</p>
        </div>
        <div class="alert alert-danger font-weight-normal" *ngIf="error">
          Email is not found or has already been activated.
        </div>
        <form #captchaProtectedForm="ngForm" class="example-form" (ngSubmit)="submitRequest()">
          <div class="flex-row">
            <div class="form-element login-form">
              <label> Email </label>
              <mat-form-field>
                <input matInput [(ngModel)]="formModel.email" name="email" required [email]="true" type="email" />
              </mat-form-field>
            </div>
          </div>
          <re-captcha [(ngModel)]="formModel.captcha" name="captcha" required #captchaControl="ngModel"></re-captcha>
          <div class="flex submit">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="action mat-button mat-raised-button mat-primary"
            >
              Resend Activation Email
            </button>
          </div>
        </form>
        <p class="copyright">© 2022 All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
