import { Injectable } from '@angular/core';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable()
export class ErrorMsgService {
  errorRef;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog) {
  }


  openSnack(error, name, type) {
    if (this.dialog.openDialogs.length == 0) {
      this.dialog.open(ErrorMessageComponent, {
        data:
        {
          error: error, name: name, type: type
        }
      });
    }
  }

  databaseError(error, name, type, route) {
    if (this.dialog.openDialogs.length == 0) {
      this.dialog.open(ErrorMessageComponent, {
        data:
        {
          error: error, name: name, type: type, routeTo: route
        }
      });
    }
  }
}
