import {Injectable} from '@angular/core';

@Injectable()
export class FeedService {

  private _data = [
    {
      type: 'video-message',
      author: 'Kostya',
      surname: 'Danovsky',
      header: 'Completed Patient',
      text: '- Banana Jama (Hypochondriac)',
      time: 'Today 9:30 pm',
      ago: '3 hrs ago',
      expanded: true,
    }, {
      type: 'video-message',
      author: 'Andrey',
      surname: 'Hrabouski',
      header: 'Completed Patient',
      text: '- Harry Potter (Avada Kedavra)',
      time: 'Today 9:30 pm',
      ago: '3 hrs ago',
      expanded: true,
    }, {
      type: 'video-message',
      author: 'Vlad',
      surname: 'Lugovsky',
      header: 'Completed Patient',
      text: '- Leeloo Dallas (Multipass)',
      time: 'Today 9:30 pm',
      ago: '3 hrs ago',
      expanded: true,
    }, {
      type: 'video-message',
      author: 'Nasta',
      surname: 'Linnie',
      header: 'Completed Patient',
      text: '- Angela Sangherra (Breast Cancer)',
      time: 'Today 9:30 pm',
      ago: '3 hrs ago',
      expanded: true,
    }, {
      type: 'video-message',
      author: 'Nick',
      surname: 'Cat',
      header: 'Completed Patient',
      text: '- Angela Sangherra (Breast Cancer)',
      time: 'Today 9:30 pm',
      ago: '3 hrs ago',
      expanded: true,
    }
  ];

  getData() {
    return this._data;
  }
}
