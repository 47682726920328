import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { SessionManagerService, FetchAssignmentDataService } from '../../../_services/index';
import { ErrorMsgService } from '../../../error-msg.service';
import { Store } from '@ngxs/store';
import { SetCaseId, SetCurrentCase } from '../../../actions/checkpoint.action';
import { TeacherDashboardDataService } from '../../../teacher-dashboard/teacher-dashboard-data.service';
import { CaseLibraryService } from 'app/case-library/services/case-library.service';
@Component({
  selector: 'app-test-confirm',
  providers: [SessionManagerService, TeacherDashboardDataService, FetchAssignmentDataService],
  templateUrl: './test-confirm.component.html',
  styleUrls: ['./test-confirm.component.scss'],
})
export class TestConfirmComponent implements OnInit {
  h1text = 'Start a new case?';
  h3text = 'You can only have one active case at a time.';
  h4text =
    'You currently have another case in progress. If you start a new case now, the previous case will be terminated. Would you like to continue?';
  btn1text = 'Start New Case';
  btn2text = 'Cancel';
  case_id: string;
  course_id: string;
  patient_name: string;
  loadingAndSaving = false;
  refresh: any;
  activeSessionFlag;
  CasesExistFlag = true;

  constructor(
    public errorService: ErrorMsgService,
    private router: Router,
    public dialogRef: MatDialogRef<any>,
    public sg: SimpleGlobal,
    private sessionManagerService: SessionManagerService,
    private store: Store,
    private teacherDashboardService: TeacherDashboardDataService,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private caseLibraryService: CaseLibraryService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    if (this.data.flag === true) {
      this.h1text = 'Resume Case?';
      this.h3text = "Oops! It looks like you've already started this case.";
      this.h4text = 'Would you like to restart or resume from your previous session?';
      this.btn1text = 'Restart Case';
      this.btn2text = 'Resume Case';
    } else {
      this.h1text = 'Start a new case?';
      this.h3text = 'You can only have one active case at a time.';
      this.h4text =
        'You currently have another case in progress. If you start a new case now, the previous case will be terminated. Would you like to continue?';
      this.btn1text = 'Start New Case';
      this.btn2text = 'Cancel';
    }

    this.fetchAssignmentDataService.getRefreshData().subscribe(
      (refresh) => {
        this.loadingAndSaving = false;
        this.refresh = refresh;
        this.settingGlobalVars(this.refresh);
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
  }

  settingGlobalVars(ref) {
    this.loadingAndSaving = true;
    this.sg['membership_status'] = ref.membership;
    this.fetchAssignmentDataService.getSkillSet().subscribe(
      (skillset) => {
        this.loadingAndSaving = false;
        this.sg['membership_status'] = skillset.membership;
      },
      () => {
        this.loadingAndSaving = false;
      }
    );
    if (ref.message === 'no active session exists') {
      this.sg['session_id'] = null;
      this.sg['case_id'] = null;
      this.store.dispatch([new SetCaseId(null)]);
      this.activeSessionFlag = false;
      this.CasesExistFlag = true;
    } else {
      this.CasesExistFlag = false;
      this.activeSessionFlag = true;
      this.sg['case_id'] = ref.case_id;
      this.store.dispatch([new SetCaseId(ref.case_id)]);
      this.sg['session_id'] = ref.session_id;
      this.sg['next_checkpoint'] = ref['next_checkpoint']['component'];
      this.sg['instance_id'] = ref['next_checkpoint']['instance'];
      this.sg['mistake_counter'] = ref.mistake_counter.mistakes;
      this.sg['skillset'] = ref.skillset;
      this.store.dispatch([new SetCurrentCase(ref.session_id)]);
    }
  }

  fresh() {
    this.loadingAndSaving = true;
    if (this.data.course_id && this.sg['session_id']) {
      this.teacherDashboardService
        .abortAndCreateTeacherDashboardSession(this.sg['session_id'], this.data.course_id, this.data.case_id)
        .subscribe(
          async (response) => {
            if (response.message === 'Session successfully created') {
              const responseMessage = response.result;
              this.sg['session_id'] = response.session_id;
              this.store.dispatch([new SetCurrentCase(response.session_id)]);
              this.sg['course_id'] = responseMessage.course_id;
              this.sg['case_id'] = responseMessage.case_id;
              this.sg['patient_name'] = responseMessage.patient_name;
              this.sg['skillset'] = responseMessage.skillset;
              this.sg['next_checkpoint'] = response.next_checkpoint['component'];
              this.sg['instance_id'] = response.next_checkpoint['instance'];
              this.sg['mistake_counter'] = responseMessage.mistake_counter.mistakes;
              const path = '/assignment/'.concat(this.sg['next_checkpoint']);
              await this.router.navigate([path], { skipLocationChange: true });
              this.loadingAndSaving = false;
              this.dialogRef.close();
            } else {
              this.sg['session_id'] = null;
              this.sg['course_id'] = null;
              this.sg['case_id'] = null;
            }
          },
          () => {
            this.loadingAndSaving = false;
          }
        );
    } else {
      if (this.sg['session_id']) {
        this.sessionManagerService.abortAndCreateSession(this.sg['session_id'], this.data.case_id).subscribe(
          async (response) => {
            const responseMessage = response.createMessage;
            if (responseMessage.message === 'Session successfully created') {
              this.sg['session_id'] = responseMessage.session_id;
              this.store.dispatch([new SetCurrentCase(responseMessage.session_id)]);
              this.sg['case_id'] = responseMessage.result.case_id;
              this.sg['patient_name'] = responseMessage.patient_name;
              this.sg['skillset'] = responseMessage.result.skillset;
              this.sg['next_checkpoint'] = responseMessage.next_checkpoint['component'];
              this.sg['instance_id'] = responseMessage.next_checkpoint['instance'];
              this.sg['mistake_counter'] = responseMessage.result.mistake_counter.mistakes;
              const path = '/assignment/'.concat(this.sg['next_checkpoint']);
              await this.router.navigate([path], { skipLocationChange: true });
              this.loadingAndSaving = false;
              this.dialogRef.close();
            } else {
              this.sg['session_id'] = null;
              this.sg['case_id'] = null;
            }
          },
          () => {
            this.loadingAndSaving = false;
          }
        );
      } else {
        this.caseLibraryService
          .startCase(this.data.case_id, this.data.patient_name, this.data.course_id)
          .subscribe(() => {
            this.dialogRef.close();
            this.loadingAndSaving = false;
          });
      }
    }
  }

  resume() {
    if (this.btn2text !== 'Cancel') {
      const path = '/assignment/'.concat(this.sg['next_checkpoint']);
      this.router.navigate([path], { skipLocationChange: true });
    }
    this.dialogRef.close();
  }
}
