import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PulsoximeteryService {

  percentValue = 100;
  pulsoximeteryTextTween = null;
  container = new createjs.Container();

  constructor() { }

  getContainer() {
    return this.container;
  };

  init(percent) {
    this.pulsoximeteryTextTween = null;
    this.container = new createjs.Container();

    this.percentValue = percent;

    const pulsoximeteryTextContainer = new createjs.Container();
    pulsoximeteryTextContainer.visible = false;

    const tBackground = new createjs.Shape();
    tBackground.graphics.beginFill('#0f77b4')
      .drawRoundRect(0, 0, 200, 90, 10)
      .endFill()
      .beginFill('#138fd8')
      .drawRoundRect(10, 10, 180, 70, 0);
    const pText = new createjs.Text((this.percentValue + "%"), "60px Arial", "#000");
    pText.x = 15;
    pText.y = 20;
    pulsoximeteryTextContainer.addChild(tBackground);
    pulsoximeteryTextContainer.addChild(pText);
    this.container.addChild(pulsoximeteryTextContainer);

    this.pulsoximeteryTextTween = createjs.Tween.get(pulsoximeteryTextContainer, { paused: true })
    .to({ visible: false,  x: 150, y: -50, alpha: 1, scaleX: 0.1, scaleY: 0.1 }, 300)
    .to({ visible: true }, 100)
    .to({ x: 325, y: 30, scaleX: 1, scaleY: 1 }, 800, createjs.Ease.circOut)
    .wait(2000)
    .to({ alpha: 0, visible: false }, 1000, createjs.Ease.circOut);
  };

  show() {
    this.pulsoximeteryTextTween.setPosition(0);
    this.pulsoximeteryTextTween.setPaused(false);
  };
  Hide() {
    this.pulsoximeteryTextTween.setPaused(true)
        .setPosition(0);
    this.pulsoximeteryTextTween.setPaused(true)
        .setPosition(0);
  };
}
