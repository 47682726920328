import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { User } from '../../_models';
import { coursegrade as coursescore } from '../../_models/teacher-dashboard.model';
import { Course } from '../../lms/interfaces/course';
import { PatientStatistic, ChartData, BarData } from 'app/lms/interfaces/statistics';
import { LoadService } from '../../theme/loading/load-service.service';
import { TeacherDashboardDataService } from '../teacher-dashboard-data.service';
import { StudentsService } from '../../lms/services/students.service';
import { tap } from 'rxjs/operators';
import { parseToChartData, parseToBarData } from 'utils/statistics';
import { ToastrService } from 'ngx-toastr';
import { HelpDialog } from 'app/lms/shared-components/help-dialog/help-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-course-grade',
  templateUrl: './course-grade.component.html',
  styleUrls: ['./course-grade.component.scss'],
  providers: [StudentsService],
})
export class CourseGradeComponent implements OnInit, OnChanges {
  @Input() student: User;
  @Input() course: Course;
  @Input() isStudent: boolean;
  @Input() displayStats: boolean;
  IsFormmative: boolean;
  loading: boolean;
  chartData: ChartData;
  selectedPatient: '';
  chartLabels: string[] = ['', '', ''];
  patientStatistics: PatientStatistic[] = [];
  barData: BarData[];
  barLabels: string[] = ['', '', ''];
  score: coursescore = new coursescore();
  grade = 'N/A';
  err;

  constructor(
    private teacherService: TeacherDashboardDataService,
    private studentsService: StudentsService,
    public loader: LoadService,
    private readonly toastr: ToastrService,
    private _location: Location,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getStudentPerformance().subscribe();
  }

  ngOnChanges() {
    this.getGrade();
  }

  openHelpDialog() {
    this.dialog.open(HelpDialog);
  }

  setPatientData(patientStatsId) {
    this.selectedPatient = patientStatsId;
    const patientStats = this.patientStatistics.find((x) => x.id === patientStatsId);
    const parsedChartData = parseToChartData(patientStats, true, this.course.nursing);
    this.chartData = parsedChartData.data;
    this.chartLabels = parsedChartData.labels;
  }

  getGrade() {
    this.loading = true;
    return this.teacherService.getGradesOverviewUser(this.student.memberId, this.course._id).subscribe(
      (data) => {
        this.score = {
          grade: data.message.average,
          err: null,
          noOfCases: data.message.caseData.length,
        };
        this.grade = this.teacherService.gradeConvert(this.score.grade);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.score.grade = -1;
        this.score.err = err;
        this.err = err;
      }
    );
  }

  getStudentPerformance() {
    return this.studentsService.getStudentPerformance(this.student.memberId, this.course._id).pipe(
      tap(
        (data) => {
          if (data.patientStatistics.length) {
            this.patientStatistics = data.patientStatistics;
            this.setPatientData(data.patientStatistics[0].id);
          }
          if (data.studentAverages.length || data.userScores?.length) {
            const parsedBarData = parseToBarData(data.studentAverages, data.userScores, 'You');
            this.barData = parsedBarData.data;
            this.barLabels = parsedBarData.labels;
          }
        },
        () => {
          this.toastr.error('Unable to load student statistics. Please reload the page', 'Error', {
            timeOut: 5000,
          });
        }
      )
    );
  }
}
