import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserService } from './user.service';

interface AIResponse {
  answer: string;
  voice: string;
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private url: string = environment.apiUrl;
  currentAudio: HTMLAudioElement | null = null;
  constructor(private http: HttpClient, private userService: UserService) {}

  saveToDB(mlDataBody: any): Observable<void> {
    const url = `${this.url}/api/v1/mlData`;
    return this.http.post<void>(url, mlDataBody);
  }

  aiCommunicate(question: string, patientId: string): Observable<AIResponse> {
    const url = `${this.url}/api/v1/ai`;
    if (!question) {
      console.log('No text passed here');
      return;
    }
    const body = { text: question, patient_id: patientId };
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post<AIResponse>(url, body, { headers });
  }

  getSpeechMarks(data: { text: string; voice: string }) {
    const { text, voice } = data;
    const url = `${this.url}/api/v1/text-to-speechmarks`;
    const body = { text, voice };
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post(url, body, { headers });
  }

  synthesizeSpeech(data: { text: string; voice: string }): Promise<Blob> {
    const { text, voice } = data;
    const response = this.http
      .post(
        `${this.url}/api/v1/text-to-speech`,
        { text, voice, upload_file: false },
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .toPromise();
    return response
      .then((res) => {
        if (res.status === 200) {
          return res.body;
        } else {
          throw new Error(`Failed to create audio. Status code: ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        throw new Error('Error synthesizing speech');
      });
  }

  playAudio(audio: HTMLAudioElement) {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio = null;
    }

    audio.onended = () => {
      this.currentAudio = null;
    };
    audio.play();
    this.currentAudio = audio;
  }

  supportedAiCase(patientId: string) {
    const supportedPatientIds = [
      // '5b3bfa47b07ee9000f216368',
      // '5b3bfdc4b07ee9000f21637a',
      // '5b3bfb73b07ee9000f21636c',
      // '5a79ff172890d4519eade938',
      // '5be5cdf98dc041000fc4cf51',
      // '5b3bf88fb07ee9000f216352',
      // '5c13e8bd9020b900168e5733',
      // '5b635772fb3bf200198a69d3',
      // '5b3bf937b07ee9000f216356',
      // '5b3bf91db07ee9000f216354',
      // '5b3bf984b07ee9000f21635a',
      // '5b6346b4fb3bf200198a69a7',
      // '5b3bfb8cb07ee9000f21636e',
      // '5b3bf9efb07ee9000f216360',
      // '5b633e47fb3bf200198a698f',
    ];
    return supportedPatientIds.includes(patientId);
  }

  getCommunicationCases(): Observable<any> {
    return this.http.get(`${this.url}/api/v2/communication/cases`);
  }

  getAvailableSessionsCount(): Observable<any> {
    return this.http.get(`${this.url}/api/v2/communication/communication-sessions/available-sessions`);
  }
}
