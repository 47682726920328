import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstitutionRole, User } from '../../_models';
import { Institution } from '../../lms/interfaces/institution';

export interface TransferInstitutionEvent {
  user: User;
  institution: Institution;
  role: InstitutionRole;
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() user: User;
  @Input() institutions: Institution[];
  @Output() search = new EventEmitter<string>();
  @Output() transferInstitution = new EventEmitter<TransferInstitutionEvent>();

  searchText: string;
  editing = false;
  selectedInstitution: Institution;
  selectedRole: InstitutionRole;

  constructor() { }

  ngOnInit(): void {
  }

  handleTransferInstitution() {
    this.transferInstitution.emit({
      user: this.user,
      institution: this.selectedInstitution,
      role: this.selectedRole,
    });
    this.editing = false;
  }

  handleToggleEditing() {
    this.editing = !this.editing;
    if (!this.editing) {
      this.selectedInstitution = undefined;
    }
  }
}
