import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface EmailsCheckResponse {
  existingEmails: string[];
  existingInstitutionStudents: string[];
  existingInstructors: string[];
  institutionLimitReached: boolean;
}

export interface EmailCheckResponse {
  email: string;
  emailExists: boolean;
  domain: string;
  institutionExists: boolean;
  institutionDetails: {
    name: string;
    country: string;
    contact: string;
  };
}

const DEFAULT_HTTP_OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

@Injectable({
  providedIn: 'root',
})
export class RegisterAccService {
  private http: HttpClient;
  private urlRegister = '/api/v1/auth/register';

  constructor(private handler: HttpBackend, private sg: SimpleGlobal) {
    this.http = new HttpClient(handler);
    this.urlRegister = this.sg['url'].concat(this.urlRegister);
  }

  register(body) {
    return this.http.post(this.urlRegister, body, DEFAULT_HTTP_OPTIONS).pipe(
      map((response: any) => {
        return response.message === 'Check email to continue';
      }),
      catchError(this.handleError)
    );
  }

  checkEmail(email: string): Observable<EmailCheckResponse> {
    const encodedEmail = encodeURIComponent(email);
    return this.http.get<EmailCheckResponse>(
      environment.apiUrl.concat('/api/v1/auth/check-email?email=', encodedEmail)
    );
  }

  checkEmails(emails: string[], institutionId: string): Observable<EmailsCheckResponse> {
    const encodedEmails = emails.map(encodeURIComponent);
    return this.http.get<EmailsCheckResponse>(
      environment.apiUrl.concat('/api/v1/auth/check-emails?emails=', encodedEmails.join(',')),
      { params: { institutionId } }
    );
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
