<loading></loading>
<!-- DEBUG -->
<div [hidden]="!debug && !debugClosed" [ngClass]="{ hidelocal: hidelocal }" id="debugconsole">
  <div class="debug" cdkDrag style="cursor: move">
    <div class="example-handle" cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
        ></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title> DEBUG CENTRAL </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field>
          <mat-select placeholder="Instance" [(value)]="selectedInstance">
            <mat-option value="initialAssessment">Initial Assessment</mat-option>
            <mat-option value="ancillary">Ancillary Care</mat-option>
            <mat-option value="decisions">Decisions</mat-option>
            <mat-option value="preOp">Pre-Op</mat-option>
            <mat-option value="POD1">Hospital Day 1</mat-option>
            <mat-option value="POD2">Hospital Day 2</mat-option>
            <mat-option value="POD3">Hospital Day 3</mat-option>
            <mat-option value="POD4">Hospital Day 4</mat-option>
            <mat-option value="POD5">Hospital Day 5</mat-option>
            <mat-option value="POD6">Hospital Day 6</mat-option>
            <mat-option value="POD7">Hospital Day 7</mat-option>
            <mat-option value="POD8">Hospital Day 8</mat-option>
            <mat-option value="POD9">Hospital Day 9</mat-option>
            <mat-option value="POD10">Hospital Day 10</mat-option>
            <mat-option value="office1">Office Visit 1</mat-option>
            <mat-option value="office2">Office Visit 2</mat-option>
            <mat-option value="office3">Office Visit 3</mat-option>
            <mat-option value="sendHome1">Send Home 1</mat-option>
            <mat-option value="sendHome2">Send Home 2</mat-option>
            <mat-option value="sendHome3">Send Home 3</mat-option>
            <mat-option value="observation1">Observation 1</mat-option>
            <mat-option value="observation2">Observation 2</mat-option>
            <mat-option value="observation3">Observation 3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Component" [(value)]="selectedComponent">
            <mat-option value="ancillaryCare">Ancillary Care</mat-option>

            <mat-option value="proDiagnosisCom">proDiagnosisCom</mat-option>
            <mat-option value="procedureComOne">procedureComOne</mat-option>
            <mat-option value="epaProcedureQuestion">epaProcedureQuestion</mat-option>
            <mat-option value="procedureComTwo">procedureComTwo</mat-option>
            <mat-option value="procedureVideo">procedureVideo</mat-option>
            <mat-option value="investigationEPA">investigationEPA</mat-option>
            <mat-option value="epaCrisisQuestion">Crisis Question</mat-option>
            <mat-option value="communicationCrisis">communicationCrisis</mat-option>
            <mat-option value="communicationSbar">communicationSbar</mat-option>
            <mat-option value="mNmRounds">mNmRounds</mat-option>
            <mat-option value="mNmRoundsPresentation">mNmRoundsPresentation</mat-option>
            <mat-option value="comRecommendations">comRecommendations</mat-option>

            <mat-option value="complications">Complications</mat-option>
            <mat-option value="consultations">Consultations</mat-option>
            <mat-option value="counselling">Counselling</mat-option>
            <mat-option value="finalDiagnosis">Final Diagnosis</mat-option>
            <mat-option value="followUp">Follow Up</mat-option>
            <mat-option value="generalOrder">General Order</mat-option>
            <mat-option value="historyTaking">History Taking</mat-option>
            <mat-option value="hospitalOfficeManagement">Hospital/Office Management</mat-option>
            <mat-option value="hospitalSetting">Hospital Setting</mat-option>
            <mat-option value="input">Input</mat-option>
            <mat-option value="inquiry">Inquiry</mat-option>
            <mat-option value="invasiveManagement">Invasive Management</mat-option>
            <mat-option value="labTests">Investigations</mat-option>
            <mat-option value="managementPlan">Management Plan</mat-option>
            <mat-option value="medications">Medications</mat-option>
            <mat-option value="patientManagement">Patient Management</mat-option>
            <mat-option value="physicalExam">Physical Exam</mat-option>
            <mat-option value="procedures">Procedures</mat-option>
            <mat-option value="provisionalDiagnosis">Provisional Diagnosis</mat-option>
            <mat-option value="recommendations">Recommendations</mat-option>
            <mat-option value="referrals">Referrals</mat-option>
            <mat-option value="sendHomeMedications">Send Home Medications</mat-option>
            <mat-option value="sendHomeOrders">Send Home Orders</mat-option>
            <mat-option value="surgery">Surgery</mat-option>
            <mat-option value="surgeryPlan">Surgery Plan</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button mat-raised-button color="accent" skipLocationChange (click)="debugInstance()">
            Go
            <mat-icon>play_arrow</mat-icon>
          </button>
        </div>

        <div>
          <mat-checkbox [(ngModel)]="loadAudio" (change)="setLoadAudio()">Load Audio</mat-checkbox>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="false" (afterExpand)="loadCheckPoints()">
        <mat-expansion-panel-header>
          <mat-panel-title> Filtered Checkpoints </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="checkPointInstances == null; else elseTemplate">
          <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #elseTemplate style="cursor: auto">
          <mat-form-field>
            <mat-select
              placeholder="Instance"
              [(value)]="selectedInstance"
              (selectionChange)="instanceChange($event)"
              required
            >
              <mat-option *ngFor="let i of checkPointInstances" [value]="i">{{ i }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Component" [(value)]="selectedComponent" (selectionChange)="comChanged($event)">
              <mat-option *ngFor="let c of checkPointComponents" [value]="c">{{ c }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <div>
          <button
            [disabled]="selectedComponent === null"
            mat-raised-button
            color="accent"
            skipLocationChange
            (click)="debugInstance()"
            required
          >
            Go
            <mat-icon>play_arrow</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel style="cursor: auto" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Answers</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container class="answerparent">
          <prettyjson class="jsonBox" [obj]="debugData"></prettyjson>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel style="cursor: auto" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Alpha Debug</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container class="ald">
          <mat-slider
            (change)="updateSliderValue($event)"
            thumbLabel
            [(ngModel)]="value"
            [displayWith]="formatLabel"
            min="1"
            max="100"
            step="1"
          ></mat-slider>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<app-page-top></app-page-top>
<sidebar class="slidebar d-none d-md-block"> </sidebar>

<div class="main">
  <div class="content">
    <div class="row mx-auto">
      <div class="columns">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <footer></footer>
</div>
