<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
  <div class="patient-history mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description" [ngClass]="type === 'error' ? 'error' : ''">
          <div class="">
            <p style="text-align:center; display: block; margin: 0 auto;">
              <ng-container *ngIf="type === 'error' && errorsLeft > 0">
                Incorrect
              </ng-container>
              <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset === 'NOMR'">
                You made too many mistakes.
              </ng-container>
              <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset === 'FULL'">
                <div class="fail">Case Failed</div>
              </ng-container>
              <ng-container *ngIf="type === 'warn'">
                <span style="text-align:center;"> {{ error }} </span>
              </ng-container>
              <ng-container *ngIf="type === 'invalid-test'">
                <span style="text-align:center;">{{error}}
                </span>
              </ng-container>
              <ng-container *ngIf="type === 'gradesError'">
                <span style="text-align:center;"> No Grades available </span>
              </ng-container>
              <ng-container *ngIf="type === 'passwordsuccess'">
                <span style="text-align:center;"> Your password has been updated! </span>
              </ng-container>
              <ng-container *ngIf="type === 'emailsuccess'">
                <span style="text-align:center;"> Your email has been updated! </span>
              </ng-container>
              <ng-container *ngIf="type === 'commenterror'">
                <span style="text-align:center;"> There was an issue with submitting your feedback! </span>
              </ng-container>
              <ng-container *ngIf="type === 'commentsuccess'">
                <span style="text-align:center;"> Thank you for your feedback! </span>
              </ng-container>
              <ng-container *ngIf="type === 'securitysuccess'">
                <span style="text-align:center;"> Your Security Questions have been set! </span>
              </ng-container>
              <ng-container *ngIf="type === 'accountactivated'">
                <span style="text-align:center;"> Your account is all set up!</span>
              </ng-container>
              <ng-container *ngIf="type === 'cardupdatesuccessful'">
                <span style="text-align:center;">Your card information has been updated!</span>
              </ng-container>
              <ng-container *ngIf="type === 'resetsuccess'">
                <span style="text-align:center;"> Your password has been reset. </span>
              </ng-container>
              <ng-container *ngIf="type === 'captchaerror'">
                <span style="text-align:center;"> Please complete the CAPTCHA.</span>
              </ng-container>
              <ng-container *ngIf="type === 'passworderror'">
                <span style="text-align:center;"> There was an issue updating your password. </span>
              </ng-container>
              <ng-container *ngIf="type === 'emailerror'">
                <span style="text-align:center;"> There was an issue updating your email.</span>
              </ng-container>
              <ng-container *ngIf="type === 'passwordmatch'">
                <span style="text-align:center;"> Your passwords don't match! </span>
              </ng-container>
              <ng-container *ngIf="type === 'dberror'">
                <span style="text-align:center;"> {{ error }} Error! </span>
              </ng-container>
              <ng-container *ngIf="type === 'noRouteError'">
                <span style="text-align:center;"> {{ error }} Error! </span>
              </ng-container>
              <ng-container *ngIf="type === 'tokenerror'">
                <span style="text-align:center;"> Invalid Token Error! </span>
              </ng-container>
              <ng-container *ngIf="type === 'tokenexpired'">
                <span style="text-align:center;"> Your Token is Expired! </span>
              </ng-container>
            </p>
          </div>
        </div>
        <!---
          TODO: Finish icons & design This was removed to make the message smaller temporarily.
        <section class="list inner-content">
          <div class="grey-circle"></div>
        </section>
      -->
        <section class="response medication inputs blood">
          <p style="text-align:center; margin-top: 20px;">
            <ng-container *ngIf="type === 'error' && errorsLeft > 0 && skillset === 'FULL'">
              <span class="errorWarning">
                You have {{errorsLeft}} attempt<span *ngIf="errorsLeft > 1">s</span> left for {{errorName}}.<br />
              </span>
              <span style="text-align:center; display: block;">
                You are only permitted 3 attempts when completing each section. If you fail, you will need to restart
                this case in order to continue.
              </span>
            </ng-container>

            <ng-container *ngIf="type === 'passworderror'">
              <span class="errorWarning">
                Please try again. If you continue to have issues, please contact our support team at <a
                  href="mailto:support@cyberpatient.ca">support@cyberpatient.ca</a>.<br />
              </span>
            </ng-container>

            <ng-container *ngIf="type === 'error' && errorsLeft > 0 && skillset === 'NOMR'">
              <span class="errorWarning">
                You have made {{error}} error<span *ngIf="error > 1">s</span> in {{errorName}}.<br />
              </span>
            </ng-container>

            <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset === 'NOMR'">
              <span class="errorWarning">
                You have made too many errors in {{errorName}}. <br>You will be moved to the next step.<br />
              </span>
            </ng-container>

            <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset === 'FULL'">
              <span class="errorWarning">
                You have 0 attempts left for {{errorName}}.<br />
              </span>
              <span style="text-align:center; display: block;">
                You have failed this case. <br> Please review your answers or return to the dashboard and restart.
              </span>
            </ng-container>
            <ng-container *ngIf="type === 'dberror'">
              {{errorName}}
            </ng-container>
            <ng-container *ngIf="type === 'membershipexpired'">
              {{errorName}}
            </ng-container>
            <ng-container *ngIf="type === 'noRouteError'">
              {{errorName}}
            </ng-container>
            <ng-container *ngIf="type === 'tokensuccess' || type === 'resetsuccess' || type === 'accountactivated'">
              You may now login to your account.
            </ng-container>
            <ng-container *ngIf="type === 'emailsuccess' || type === 'passwordsuccess'">
              Your information has been updated.
            </ng-container>
            <ng-container *ngIf="type === 'cardupdatesuccessful'">
              Your card was updated successfully.
            </ng-container>
            <ng-container *ngIf="type === 'tokenexpired'">
              <span class="errorWarning">
                Your token has expired! Please check your email for your new token. </span>
            </ng-container>
            <ng-container
              *ngIf="type === 'tokenerror' || type === 'reseterror' || type === 'emailerror' || type === 'securityerror'">
              <span class="errorWarning">
                We've hit some sort of problem. If you continue to see this message, please contact support at <a
                  href="mailto:support@cyberpatient.ca">support@cyberpatient.ca</a>.<br />
              </span> </ng-container>
            <ng-container *ngIf="type === 'commenterror'"> Please try again. If the issue persists, please email our
              support team at <a href="mailto:support@cyberpatient.ca">support@cyberpatient.ca</a>.</ng-container>
            <ng-container *ngIf="type === 'commentsuccess'"> We greatly appreciate you taking the time to provide us
              with feedback.</ng-container>
            <ng-container *ngIf="type === 'warn'"> Warning. </ng-container>
            <ng-container *ngIf="type === 'invalid-test'">
              Invalid Test.</ng-container>
            <ng-container *ngIf="type === 'success'"> Success!</ng-container>
            <ng-container *ngIf="type === 'critical'">
              Critical Error!</ng-container>
            <ng-container *ngIf="type === 'info'">
              Helpful Information</ng-container>
          </p>
        </section>
        <section class="actions">
          <ng-container *ngIf="type === 'error' && errorsLeft > 0">
            <button class="action mat-button mat-raised-button mat-primary" [mat-dialog-close]="true">
              Try Again
            </button>
          </ng-container>
          <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset == 'NOMR'">
            <button class="action mat-button mat-raised-button mat-primary" [mat-dialog-close]="true">
              Next Step
            </button>
          </ng-container>
          <ng-container *ngIf="type === 'gradesError'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="returnToDashboard()"
              [mat-dialog-close]="true">
              Dashboard
            </button>
          </ng-container>
          <ng-container *ngIf="type === 'error' && errorsLeft == 0 && skillset == 'FULL'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="returnToDashboard()"
              [mat-dialog-close]="true">
              Dashboard
            </button>
            <button class="action mat-button mat-raised-button mat-primary" [mat-dialog-close]="true">
              Feedback
            </button>
          </ng-container>
          <ng-container
            *ngIf="type !== 'error' && type !== 'passwordsuccess' && type !== 'tokensuccess' && type !== 'emailsuccess' && type !== 'securitysuccess' && type !== 'dberror' && type !== 'accountactivated' && type !== 'tokenexpired'">
            <button class="action mat-button mat-raised-button mat-primary" [mat-dialog-close]>
              Back
            </button>
          </ng-container>
          <ng-container *ngIf="type === 'cardupdatesuccessful'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="returnToDashboard()"
              [mat-dialog-close]="true">
              Dashboard
            </button>
          </ng-container>
          <ng-container *ngIf="type === 'membershipexpired'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="goToBilling()"
              [mat-dialog-close]="true">
              Go to Billing
            </button>
          </ng-container>
          <ng-container *ngIf="type == 'passwordsuccess' || type == 'emailsuccess' || type == 'securitysuccess' ">
            <button class="action mat-button mat-raised-button mat-primary" (click)="returnToSettings()"
              [mat-dialog-close]="true">
              Back to Settings
            </button>
          </ng-container>
          <ng-container *ngIf="type == 'tokensuccess' || type == 'resetsuccess' || type === 'accountactivated'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="returnToLogin()"
              [mat-dialog-close]="true">
              Login
            </button>
          </ng-container>
          <ng-container *ngIf="type == 'dberror'">
            <button class="action mat-button mat-raised-button mat-primary" (click)="dbErrorRoute()"
              [mat-dialog-close]="true">
              Okay
            </button>
          </ng-container>
        </section>
      </div>
    </div>
  </div>
</mat-dialog-content>
