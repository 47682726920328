import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { Session } from '../_models/session.model';

@Injectable()
export class SessionManagerService implements OnInit {
  id: string;
  public currentUser;
  public username;
  private showStudent: boolean;
  private urlSession = '/api/v1/sessiontoken/';
  private urlAbortSession = '/api/v1/abortsession?session_id=';
  private urlAbortAndCreateSession =
    '/api/v1/abortandcreatesession?session_id=';
  private urlCompleteSession = '/api/v1/completesession';

  constructor(private sg: SimpleGlobal,
              private http: HttpClient,
  ) {
    this.urlSession = this.sg['url'].concat(this.urlSession);
    this.urlAbortSession = this.sg['url'].concat(this.urlAbortSession);
    this.urlAbortAndCreateSession = this.sg['url'].concat(
      this.urlAbortAndCreateSession
    );
    this.urlCompleteSession = this.sg['url'].concat(this.urlCompleteSession);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit(): void {
    this.showStudent = this.currentUser.institutionPermissions === 'student';
  }

  createSession(case_id) {
    this.sg['hardFeedback'] = false;

    const body = {
      student_email: this.currentUser.username,
      case_id: case_id
    };

    return this.http.post(this.urlSession, body).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  abortAndCreateSession(session_id, case_id) {
    this.sg['hardFeedback'] = false;
    const url = this.urlAbortAndCreateSession.concat(session_id);
    const body = {
      student_email: this.currentUser.username,
      case_id: case_id,
    };
    return this.http.post(url, body).pipe(
      map((response: any) => {
        if (response.message === 'Success') {
          this.sg['session_id'] = null;
          return response;
        } else {
        }
      }),
      catchError(this.handleError)
    );
  }

  abortSession(session_id) {
    this.sg['hardFeedback'] = false;
    const url = this.urlAbortSession.concat(session_id);
    const body = {};

    return this.http.post(url, body).pipe(
      map((response: any) => {
        if (response.message === 'session is already aborted') {
          this.sg['session_id'] = null;
        }
        return response;
      }),
      catchError(this.handleError)
    );
  }

  completeSession(sessionid, course_id = null) {
    this.sg['hardFeedback'] = false;
    const body = {};
    let url = this.urlCompleteSession.concat('?session_id=', sessionid);
    if (course_id != null) {
      url = url.concat('&course_id=', course_id)
    }

    return this.http.post(url, body).pipe(
      map((response: any) => {
        if (response.message.status === 'completed' && response.message.last_checkpoint === 'completed') {
          this.sg['sessionCompleted'] = this.sg['session_id'];
        }
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getSession(sessionId: string): Observable<Session> {
    return this.http.get<Session>(environment.apiUrl.concat('/api/v1/sessions/').concat(sessionId));
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg);
  }
}
