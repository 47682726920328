<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Create an Account</h1>
        </div>
        <form [formGroup]="registrationForm" class="login-form">
          <div class="px-3 mb-3 form-element"></div>

          <div class="px-3 form-element">
            <label> Email Address </label>
            <mat-form-field appearance="outline" class="full-width" floatLabel="never">
              <input
                matInput
                id="email"
                placeholder="Email"
                type="email"
                class="full-width"
                class="lf--input"
                formControlName="email"
                required
              />
              <mat-error class="text-danger">
                {{
                  registrationForm.get('email').hasError('notIn')
                    ? 'Email already exists'
                    : 'Please enter a valid email'
                }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex-row">
            <div class="form-element">
              <div class="full-width small-12 columns">
                <label> First Name </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input
                    matInput
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    class="full-width"
                    formControlName="firstName"
                    required
                    class="lf--input"
                  />
                  <mat-error class="text-danger"> First Name is Required! </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element">
              <div class="full-width small-12 columns">
                <label> Last Name </label>
                <mat-form-field appearance="outline" class="full-width" floatLabel="never">
                  <input
                    matInput
                    id="lastName"
                    placeholder="Last Name"
                    type="text"
                    class="full-width"
                    class="lf--input"
                    formControlName="lastName"
                    required
                  />
                  <mat-error class="text-danger"> Last Name is Required! </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="full-width small-12 columns">
            <mat-checkbox formControlName="agree" class="policy-box" #policy required>
              I have read and agree to the terms and conditions of the End-User License and Privacy Policy.
            </mat-checkbox>
            <div class="d-flex justify-content-center my-3">
              <a
                class="small mx-3"
                href="https://www.cyberpatient.ca/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                >Privacy Policy</a
              >
              <a
                class="small mx-3"
                href="https://www.cyberpatient.ca/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                >Terms of Use</a
              >
            </div>
          </div>

          <div class="full-width small-12 columns create-container">
            <button type="submit" mat-button [disabled]="!registrationForm.valid || loading" (click)="submitForm()">
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
  </div>
</section>
