<div class="feedback-mat-typography-row">
  <div class="mt-3 small">
    <span class="text-muted">Not sure where to start? </span>
    <a (click)="startTutorial()" class="text-color-primary">
      <u>View our onboarding tutorials again.</u>
    </a>
  </div>

  <div class="grid-container">
    <div class="grid-item1 i1">
      <div class="grid-container2 c1">
        <mat-card
          class="mat-elevation-z1 score-card"
          appTutorialStep="Create your own class or view classes that are assigned to you by the faculty administrator"
          [appTutorialStepOrder]="0"
          [appTutorialStepContext]="tutorialContext"
        >
          <mat-card-header class="c29">
            <mat-card-title>
              <div class="tdt1">
                <div class="h1">
                  <h3 class="header-myclass">Class Management</h3>
                </div>
                <div class="tdt2">
                  <mat-form-field>
                    <mat-select [(ngModel)]="selectedStatus" (selectionChange)="updateStatus()">
                      <mat-option value="active">Active</mat-option>
                      <mat-option value="upcoming">Upcoming</mat-option>
                      <mat-option value="inactive">Done</mat-option>
                      <mat-option value="archived">Archived</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="t56">
              <ng-container *ngIf="classDataSource == undefined || classDataSource.data.length == null; else datatable">
              </ng-container>
              <ng-template #datatable>
                <table mat-table [dataSource]="classDataSource" class="t7">
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status.</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="status1">
                        <ng-container *ngIf="checkDate(element.end) || element.archived; else red">
                          <div *ngIf="!element.archived"><span class="blackoval"></span> DONE</div>
                          <div *ngIf="element.archived"><span class="redoval"></span> ARCHIVED</div>
                        </ng-container>
                        <ng-template #red>
                          <div *ngIf="isCourseUpcoming(element.start)"><span class="yellowoval"></span> UPCOMING</div>
                          <div *ngIf="!isCourseUpcoming(element.start)"><span class="greenoval"></span> ACTIVE</div>
                        </ng-template>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Class Name.</th>
                    <td mat-cell *matCellDef="let element" class="title">{{ element.name }}</td>
                  </ng-container>

                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>Semester Dates</th>
                    <td mat-cell *matCellDef="let element">{{ element.end | date: 'longDate' }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="classtablecolumns; sticky: true"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: classtablecolumns"
                    [matTooltip]="row.description"
                    [matTooltipPosition]="'above'"
                    style="cursor: pointer"
                    (click)="gotoClassdashboard(row)"
                  ></tr>
                </table>
              </ng-template>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="flex-container">
              <div class="btn2">
                <button mat-raised-button color="success" (click)="showCreateClass()" [disabled]="true">
                  + Create Class
                </button>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="c2">
        <news-feed class="mat-elevation-z1 news-feed large-12"></news-feed>
      </div>
    </div>
    <div class="grid-item1 i2">
      <mat-card
        class="mat-elevation-z1 score-card2 mat-tab-group"
        appTutorialStep="Manage students, view their performance and give them feedback"
        appTutorialStepUrl="https://youtu.be/44IaluNwR5M"
        [appTutorialStepOrder]="1"
        [appTutorialStepContext]="tutorialContext"
      >
        <div class="w-100 px-4 py-3">
          <mat-card-title class="d-flex justify-content-between m-0">
            <span class="h5 font-weight-bold mt-1 text-color-secondary">Student List</span>
            <button
              mat-raised-button
              color="success"
              (click)="openImportDialog()"
              appTutorialStep="Know a student that is not in the list? Invite them to CyberPatient and add them to your class"
              [appTutorialStepOrder]="2"
              [appTutorialStepContext]="tutorialContext"
              [disabled]="true"
            >
              Invite Students
            </button>
          </mat-card-title>
        </div>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="t3">
            <div class="show-class">
              <mat-label class="lable-feild">Select Class:</mat-label>
              <mat-form-field appearance="outline" *ngIf="classList">
                <mat-select
                  #studentSelector
                  (selectionChange)="setClass()"
                  [(ngModel)]="selectedCourseId"
                  placeholder="Select Class"
                >
                  <mat-option *ngFor="let class of classList; index as i; first as isFirst" [value]="class._id">
                    {{ class.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="t311">
              <table class="t1">
                <tr>
                  <th>
                    <div class="c3">Student Name</div>
                  </th>
                  <th>
                    <div class="c31">Feedback</div>
                  </th>
                  <th>
                    <div class="c31">Assessment</div>
                  </th>
                </tr>
                <ng-container *ngIf="filteredList.length == 0; else studentlist"> </ng-container>
                <ng-template #studentlist>
                  <tr *ngFor="let item of filteredList; index as i">
                    <td>
                      <div class="c4 c42">
                        <span>{{ item.firstName }}&nbsp;{{ item.lastName }}</span>
                      </div>
                    </td>

                    <td>
                      <div class="c5">
                        <span>
                          <button
                            mat-icon-button
                            color="accent"
                            (click)="formativeAssessment(item)"
                            matTooltipPosition="above"
                            matTooltip="Formative Feedback"
                          >
                            <mat-icon matBadgeSize="medium" matBadgeColor="warn">feedback</mat-icon>
                          </button>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="c6">
                        <span *ngIf="getBadge(item) == 1">
                          <button
                            class="b314"
                            mat-icon-button
                            color="accent"
                            (click)="showAssessment(item)"
                            matTooltipPosition="above"
                            matTooltip="check certificate"
                          >
                            <mat-icon matBadgeSize="medium" matBadgeColor="warn"> assignment_turned_in </mat-icon>
                          </button>
                        </span>
                        <button
                          class="b314"
                          mat-icon-button
                          color="accent"
                          (click)="summativeAssessment(item)"
                          [disabled]="!checkDate(myCourse.end.toString())"
                        >
                          <mat-icon
                            [matBadge]="getBadge(item)"
                            matBadgeSize="medium"
                            matTooltipPosition="above"
                            [matTooltip]="showSummativeTooltip()"
                            matBadgeColor="warn"
                            class="hide-text"
                          >
                            assignment
                          </mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template #elseTemplate>
                  <span>...Loading...</span>
                </ng-template>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ngx-loading [show]="loadingAndSaving"></ngx-loading>
