<div class="py-3" style="max-height: 600px; overflow-x: hidden; overflow-y: auto">
  <div class="row">
    <div class="col-3 h5 font-weight-bold text-black-50">
      Institution Name
    </div>
    <div class="col-3 h5 font-weight-bold text-black-50">
      Contact
    </div>
    <div class="col-2 h5 font-weight-bold text-black-50">
      Max. Users
    </div>
    <div class="col-2 h5 font-weight-bold text-black-50">
      Membership
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-2">
    <div *ngFor="let institution of institutions; let i=index">
      <div class="row d-flex align-items-center">
        
        <div class="col-3" [matTooltip]="institution._id" matTooltipPosition="above">
          {{ institution.name }} 
          <i class="fas" [ngClass]="true?'fa-angle-down':'fa-angle-up'" (click)="toggleInstitution(i)"></i>
        </div>
        <div class="col-3">
          <a class="text-body"
             [matTooltip]="institution.owner.email"
             matTooltipPosition="above"
             [href]="'mailto:' + institution.owner.email">
            <u>{{ institution.owner.firstName }} {{ institution.owner.lastName }}</u>
          </a>
        </div>
        <div class="col-2">
          <mat-form-field appearance="standard" class="w-100">
            <input [disabled]="!institution.editing"
                   matInput
                   type="number"
                   [(ngModel)]="institution.membersLimit">
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="standard" class="w-100">
            <mat-select [disabled]="!institution.editing" [(value)]="institution.paid">
              <mat-option [value]="true">Paid</mat-option>
              <mat-option [value]="false">Free</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
          <button mat-button color="primary" class="font-weight-bold" (click)="login.emit(institution)">LOG IN</button>
        </div>
        <div class="col-1">
          <button *ngIf="!institution.editing"
                  style="font-size: 1.25rem"
                  class="text-muted"
                  mat-button
                  (click)="edit.emit(institution)">
            <i class="fa fas fa-edit"></i>
          </button>
          <button *ngIf="institution.editing"
                  style="font-size: 1.25rem"
                  mat-button color="primary"
                  (click)="save.emit(institution)">
            <i class="fa fas fa-check-circle"></i>
          </button>
        </div>
      </div>

      <div *ngIf="institution.editing" class="row mb-3">
        <mat-checkbox class="col-4" [(ngModel)]="institution.allowPublicCourses">Allow public class</mat-checkbox>
      </div>

      <!-- collapse content here  -->
      <div id="{{i}}" class="collapse">
        <table mat-table [dataSource]="sortDataSource(institution.name)" class="mat-elevation-z8" style="width: 100%;">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef" > 
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.header}} </th>
            <td mat-cell *matCellDef="let row" > {{column.cell(row)}}</td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>     
      </div>

      <mat-divider *ngIf="i < institutions.length - 1"></mat-divider>
    </div>
  </div>
</div>
