<div>
  <div class="m-3">
    <app-course-header [student]="student" [course]="course" (back)="backClicked()"></app-course-header>
  </div>

  <div class="alert alert-danger mx-2" *ngIf="!loadingAndSaving && !canAccess()">
    Sorry, you don't have enough permission to access this class. Please contact the professor or info@cyberpatient.ca
  </div>

  <ng-container *ngIf="canAccess()">
    <div class="text-center">
      <button mat-raised-button (click)="enrollToCourse()" *ngIf="!loadingAndSaving && !isInClass()"
        [disabled]="(course && (course.end | isBefore: 'now')) || loadingAndSaving" color="success"
        class="font-weight-bold ml-2 px-5 py-1">
        Click to enroll
      </button>
    </div>
    <!-- <div
      class="d-flex align-items-center mx-2 my-3 alert alert-warning"
      *ngIf="student && course && student.skillset !== course.recommendationLevel"
    >
      Your skill level setting is different than the recommended skill level.
      <a class="mx-1" routerLink="/account/skillset"
        ><u><b>Click here to change</b></u></a
      >.
      <mat-icon
        style="cursor: pointer"
        [ngbTooltip]="skillLevelTipContent"
        placement="bottom"
        triggers="click"
        autoClose="outside"
        class="mx-1"
      >
        info_outlined
      </mat-icon>
      <ng-template #skillLevelTipContent>
        <div class="text-left">
          <p>
            Each skill level allows you to run our simulations in a different difficulty. Your professor always set a
            recommended skill level for the whole class to follow.
          </p>
          <p>
            In case your skill level setting is different than the recommendation, we suggest you to update it in the
            account settings.
          </p>
        </div>
      </ng-template>
    </div> -->
    <div class="d-flex align-items-center mx-2 my-3 text-danger">
      <ng-container *ngIf="course.archived"><span>This class is archived by your instructor.</span></ng-container>
    </div>
    <div class="dash-container">
      <table class="t24">
        <tr>
          <td colspan="2">
            <app-course-grade *ngIf="!loadingAndSaving && isInClass()" [course]="course" [student]="student"
              [isStudent]="true" [displayStats]="true">
            </app-course-grade>
          </td>
        </tr>
        <tr>
          <td>
            <div class="performancesnapshot">
              <app-course-completed-cases *ngIf="completedCases" [data]="completedCases" [nursing]="course.nursing"
                [access]="false">
              </app-course-completed-cases>
            </div>
          </td>
          <td>
            <div class="performancesnapshot">
              <app-assignment-cases [course]="course" [showCaseLibrary]="true"
                (toCaseLibrary)="toCaseLibrary()"></app-assignment-cases>
            </div>
          </td>
        </tr>
        <tr *ngIf="course.courseType === 'LEGACY'">
          <td>
            <h3 class="header">Summative Assessment</h3>
            <div class="fb4">
              <ng-container *ngIf="summative; else hassummative">
                <div>
                  Your instructor has submitted the Summative assessment for this class. Click view for details.
                </div>
                <div class="h45">
                  <div>
                    {{ summative.created | date }}
                  </div>
                  <a (click)="viewSummative()" class="text-color-primary mx-3"><u>View</u></a>
                </div>
              </ng-container>
              <ng-template #hassummative>
                <div>
                  When your instructor completes their final assessment of this course, it will show up here. Come back
                  at the end of the course dates to view your final marks
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
  <table>
    <tr *ngIf="course.courseType === 'LEGACY'">
      <td>
        <mat-card>
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <span class="header" [matBadge]="formative.length" matBadgeOverlap="false">Formative Feedback</span>
              </ng-template>
              <ng-container *ngIf="formative != null">
                <div class="formativeFeedBack">
                  <div *ngFor="let feedback of formative; let j = index">
                    <div class="text-black-50 small">{{ feedback.created | date: 'MMM d, y, h:mm a' }}:</div>
                    <div class="bg-color-lightgray p-3 rounded">({{ j + 1 }}) {{ feedback.text }}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container class="noContent" *ngIf="!formative.length">
                <div class="bg-color-lightgray p-3 rounded">You have no feedback yet.</div>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </mat-card>
      </td>
    </tr>
  </table>
</div>