import { Injectable } from '@angular/core';

@Injectable()
export class LabScoreService {
  scoreSet = new Set();
  scoreTotal = new Array();

  constructor() { }

  ordered(name) {
    this.scoreSet.add(name);
    this.scoreTotal = Array.from(this.scoreSet);
    // console.log(this.scoreTotal);

  }

  getScore() {
    return this.scoreTotal;
  }

}
