import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleGlobal } from 'ng2-simple-global';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import 'rxjs/Rx';

@Injectable()
export class GetMenuListService {
  public username;
  keys: String[];
  private urlGetMenu = '/api/v1/';
  private medUrl = this.sg['url'].concat(this.urlGetMenu);

  constructor(private http: HttpClient, private sg: SimpleGlobal) {
    this.urlGetMenu = this.sg['url'].concat(this.urlGetMenu);
  }

  getSurgeryPlan(): Observable<any> {
    const url = this.urlGetMenu.concat('surgeryplan/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
  }

  getConsultationMenu(): Observable<any> {
    const url = this.urlGetMenu.concat('consultations/');
    return this.http.get(url).pipe(
      map((response: any) => response.message.Consultations),
      catchError(this.handleError),
    );
  }

  getProcedures(): Observable<any> {
    const url = this.urlGetMenu.concat('procedures/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
  }

  getMedications(page): Observable<any> {
    const url = this.medUrl.concat('medicines?page=' + page);
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  getMedicationCategory(): Observable<any> {
    // let url = this.urlGetMenu.concat('medicines/categories');
    const url = this.medUrl.concat('medicines/categories');
    return this.http.get(url).pipe(
      catchError(this.handleError),
    );
  }

  getMedicationsByCategory1(c): Observable<any> {
    const url = this.medUrl.concat('medicines?c1=' + encodeURIComponent(c));
    return this.http.get(decodeURIComponent(url)).pipe(
      catchError(this.handleError),
    );
  }

  getMedicationsByCategory2(c): Observable<any> {
    const url = this.medUrl.concat('medicines?c2=' + encodeURIComponent(c));
    return this.http.get(url).pipe(
      catchError(this.handleError),
    );
  }

  getMedicationsByCategory3(c): Observable<any> {
    const url = this.medUrl.concat('medicines?c3=' + encodeURIComponent(c));
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  getMedicationsBySearch(searchTerm, page: number): Observable<any> {
    // let url = this.urlGetMenu.concat('medications/');
    const url = this.medUrl.concat('medicines?page=' + page + '&keyword=' + searchTerm);
    return this.http.get(url).pipe(
      catchError(this.handleError),
    );
  }

  getLabtests(): Observable<any> {
    const url = this.urlGetMenu.concat('labtests/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
  }

  getPhysExam(): Observable<any> {
    const url = this.urlGetMenu.concat('physexam/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError),
    );
  }

  getReferrals(): Observable<any> {
    const url = this.urlGetMenu.concat('referrals/');
    return this.http.get(url).pipe(
      map((response: any) => response.message.referrals),
      catchError(this.handleError)
    );
  }

  getIVFluids(): Observable<any> {
    const url = this.urlGetMenu.concat('input/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError)
    );
  }

  getCounselling(): Observable<any> {
    const url = this.urlGetMenu.concat('counselling/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError)
    );
  }

  getRecommendations(): Observable<any> {
    const url = this.urlGetMenu.concat('recommendations/');
    return this.http.get(url).pipe(
      map((response: any) => response.message),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse | any) {
    // On a live system, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error;
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
