import {Location} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SimpleGlobal} from 'ng2-simple-global';

@Component({
  selector: 'app-history-taking-error-dialog',
  templateUrl: './history-taking-error-dialog.component.html',
  styleUrls: ['./history-taking-error-dialog.component.scss']
})
export class HistoryTakingErrorDialogComponent implements OnInit {
  h1text = 'Warning Message'
  h3text = 'You have asked more questions than requested.';
  h4text = 'You will get 0% mark on this section.';
  h5text = 'Do you wish to continue?';
  btnNo = 'No';
  btnYes = 'Yes';
  constructor(
    public dialogRef: MatDialogRef<any>,
    public sg: SimpleGlobal,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit() {
    this.h1text = 'Oops, Incorrect History Taking!';
    if(this.data.chosen.length === 0) {
      this.h3text = 'It seems you have asked NO questions. You will receive 0% for History Taking.';
      this.h5text = "Press 'Ask Questions' to ask questions. Make sure to ask the most relevant questions.";
      this.btnNo = 'Ask Questions';
    } else {
      this.h3text = 'It seems you have asked ALL questions. You will receive 0% for History Taking.';
      this.h5text = "Press 'Reset History Taking' to reset your questions. Make sure to ask the most relevant questions.";
      this.btnNo = 'Reset History Taking';
    }
    this.h4text = "Press 'Proceed to Physical Exam', If you wish to proceed to Physical Examination.";
    this.btnYes = 'Proceed to Physical Exam';
  }
  resume() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }
}
