import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleGlobal } from 'ng2-simple-global';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionManagerService } from '../../_services/index';
import { SaveAssignmentDataService } from '../../_services/index';
import { Location } from '@angular/common';
import { ErrorMsgService } from '../../error-msg.service';



@Component({
  selector: 'app-success-confirm',
  providers: [SessionManagerService, SaveAssignmentDataService],
  templateUrl: './success-confirm.component.html',
  styleUrls: ['./success-confirm.component.scss']
})
export class SuccessConfirmComponent implements OnInit {

  constructor(public errorService: ErrorMsgService, private router: Router, public dialogRef: MatDialogRef<any>, public sg: SimpleGlobal, private SaveAssignmentDataService: SaveAssignmentDataService, private SessionManagerService: SessionManagerService, @Inject(MAT_DIALOG_DATA) public data: any, private location: Location) { }
  h1text = 'You have upgraded to the level. Any case you start now will have more features.'
  btn2text = 'Continue';
  new_skillset;
  skillset_description;


  ngOnInit() {
    switch (this.data.new_skillset) {
      case "HIST":
        this.new_skillset = "Level 1"
        this.skillset_description = "History Taking"
        break;
      case "HTPE":
        this.new_skillset = "Level 2"
        this.skillset_description = "History Taking, Physical Exam"
        break;
      case "NOMR":
        this.new_skillset = "Level 3"
        this.skillset_description = "History Taking, Physical Exam, Diagnosis & Treatment without failure limits"
        break;
      case "FULL":
        this.new_skillset = "Level 4"
        this.skillset_description = "History Taking, Physical Exam, Diagnosis & Treatment with failure limits"
        break;
    }
  }


}
