<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
  <div class="pop-up mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
            <p style="text-align:center; display: block; margin: 0 auto;">
              Send Feedback or Comments about Cyberpatient
            </p>
          </div>
        </div>
        <mat-form-field appearance="fill" class="commentbox">
          <textarea matInput placeholder="Write your comments here..." [(ngModel)]="comment"></textarea>
        </mat-form-field>
        <button class="action mat-button cancel mat-raised-button mat-primary" [mat-dialog-close]>
          Back
        </button>
        <button class="comment-submit action mat-button" mat-raised-button color="primary"
          (click)="submitComment()">Submit</button>
      </div>
    </div>
  </div>
  <div class="guide">Need some support?<br> Reach out to us at <a
      href="mailto:info@cyberpatient.ca">info@cyberpatient.ca</a>, or try downloading our <a
      href="/assets/img/app/CyberPatient-UserGuide.pdf" download class="user-guide">User
      Guide</a>.</div>
      <ngx-loading [show]="loadingAndSaving"></ngx-loading>
</mat-dialog-content>
