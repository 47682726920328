import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetSelectedCase, } from 'app/actions/teacherdashboard.action';
import { SimpleGlobal } from 'ng2-simple-global';
import { environment } from '../../environments/environment';
import { GradesList } from '../_models/index';
import { FetchAssignmentDataService, InstanceNameService, UserService } from '../_services/index';
import { DebugService } from '../debug.service';
import { ErrorMsgService } from '../error-msg.service';

@Component({
  selector: 'app-student-grades',
  providers: [FetchAssignmentDataService],
  templateUrl: './student-grades.component.html',
  styleUrls: ['./student-grades.component.scss']
})

export class StudentGradesComponent implements OnInit {
  data: any;
  caseData: any[];
  examData: any[];
  average: number;
  assData: any;
  caseTable = false;
  userScores = [];
  letterGrade: string;
  newChartData = [];
  averageScores = [];
  loadingAndSaving = false;
  dataSource: MatTableDataSource<GradesList>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  caseNames = [];
  public barChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  barChartLabels: string[] = [];
  barChartType = 'bar';
  barChartLegend = true;

  barChartData: any[] = [
    {data: [1, 2, 3], label: 'Student'},
    {data: [1, 2, 3], label: 'Ideal'}
  ];

  barChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(139, 195, 74, 0.8)',
      borderColor: '#8bc34a',
    },
    { // grey
      backgroundColor: 'rgba(74, 177, 195, 0.8)',
      borderColor: '#4ab1c3',
    }
  ];

  debug = false;
  displayedColumns: string[] = ['name', 'attempts', 'last_completed', 'bestScore'];


  constructor(private debugService: DebugService,
              private store: Store,
              private nameService: InstanceNameService,
              public errorService: ErrorMsgService,
              private fetchAssignmentDataService: FetchAssignmentDataService,
              private sg: SimpleGlobal,
              private router: Router,
              private userService: UserService,
  ) {

    if (environment.debug) {
      this.debugService.updateShowHideDebug(this.debug);
    }

  }

  ngOnInit() {
    this.store.dispatch([new SetSelectedCase(null)]);
    this.getGradesOverview();
  }

  gradeConvert(score) {
    if (score >= 90) {
      return 'A+'
    } else if (score >= 85 && score <= 89) {
      return 'A'
    } else if (score >= 80 && score <= 84) {
      return 'A-'
    } else if (score >= 76 && score <= 79) {
      return 'B+'
    } else if (score >= 72 && score <= 75) {
      return 'B'
    } else if (score >= 68 && score <= 71) {
      return 'B-'
    } else if (score >= 64 && score <= 67) {
      return 'C+'
    } else if (score >= 60 && score <= 63) {
      return 'C'
    } else if (score >= 55 && score <= 59) {
      return 'C-'
    } else if (score >= 50 && score <= 54) {
      return 'D'
    } else if (score >= 0 && score <= 49) {
      return 'F'
    } else {
      return 'N/A'
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getGradesOverview() {
    this.loadingAndSaving = true;
    this.fetchAssignmentDataService.getGradesOverview().subscribe(data => {

      this.loadingAndSaving = false;


      if (data === 'query could not return valid results') {

        this.errorService.openSnack(0, 'No Grades available', 'gradesError')
      } else {
        this.data = data;
        this.caseData = this.data['caseData'];
        this.examData = this.data['examData'];
        this.average = this.data['average'];
        if (this.average == null) {
          this.average = 80;
        }
        this.letterGrade = this.gradeConvert(this.average);
        this.dataSource = new MatTableDataSource(this.caseData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.caseTable = true;
        this.newChartData = [
          {data: [this.average], label: 'You'},
          {data: [80], label: 'Ideal'}
        ];

        this.barChartData = this.newChartData;
        this.barChartLabels = [''];
      }
    }, err => {
      this.loadingAndSaving = false;
    });
  }

  getGradesDetails(row) {
    this.sg['grades_ass'] = row.case_id;
    this.sg['case_name'] = row.name;
    this.router.navigate(['/gradesOverview/caseOverview'], {skipLocationChange: false});
  }
}
