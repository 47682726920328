import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpritesheetService {
  pediatric = false;
  sprites = {
    default: [
      {
        name: 'patientBody',
        x: 900,
        y: 1088,
        w: 159,
        h: 165,
      },
      {
        name: 'patientHead',
        x: 1753,
        y: 2058,
        w: 83,
        h: 122,
      },
      {
        name: 'patientLeftSide',
        x: 1034,
        y: 561,
        w: 267,
        h: 456,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1088,
        w: 298,
        h: 543,
      },
      {
        name: 'patientRightSide',
        x: 1034,
        y: 561,
        w: 267,
        h: 456,
      },
      {
        name: 'patientTorso',
        x: 900,
        y: 1254,
        w: 303,
        h: 313,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 332,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 332,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1753,
        y: 1649,
        w: 210,
        h: 408,
      },
      {
        name: 'patientDown',
        x: 1152,
        y: 1649,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1402,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 526,
      },
      {
        name: 'patientHeadFront',
        x: 570,
        y: 1649,
        w: 581,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 333,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 333,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1088,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1088,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1649,
        w: 569,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1402,
        y: 0,
        w: 600,
        h: 560,
      },
    ],
    harryKing: [
      {
        name: 'patientBack',
        x: 1202,
        y: 0,
        w: 273,
        h: 457,
      },
      {
        name: 'patientBody',
        x: 1467,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1476,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 478,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 346,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1476,
        y: 1122,
        w: 600,
        h: 544,
      },
      {
        name: 'patientHeadLeft',
        x: 947,
        y: 1683,
        w: 519,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 947,
        y: 1683,
        w: 519,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1683,
        w: 345,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1683,
        w: 345,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 561,
        w: 576,
        h: 553,
      },
      {
        name: 'patientTorso',
        x: 1036,
        y: 1122,
        w: 411,
        h: 448,
      },
      {
        name: 'patientTorsoBack',
        x: 1476,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1122,
        w: 477,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1122,
        w: 477,
        h: 560,
      },
    ],
    devinAtkins: [
      {
        name: 'patientBack',
        x: 1564,
        y: 1122,
        w: 298,
        h: 555,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 561,
        w: 244,
        h: 178,
      },
      {
        name: 'patientDown',
        x: 1564,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 0,
        w: 518,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 848,
        y: 1122,
        w: 552,
        h: 508,
      },
      {
        name: 'patientHead',
        x: 1401,
        y: 1122,
        w: 87,
        h: 127,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1122,
        w: 537,
        h: 559,
      },
      {
        name: 'patientHeadLeft',
        x: 519,
        y: 0,
        w: 443,
        h: 554,
      },
      {
        name: 'patientHeadRight',
        x: 519,
        y: 0,
        w: 443,
        h: 554,
      },
      {
        name: 'patientLeftArmPit',
        x: 963,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 963,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1863,
        y: 1122,
        w: 298,
        h: 551,
      },
      {
        name: 'patientRightSide',
        x: 1863,
        y: 1122,
        w: 298,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 740,
        w: 349,
        h: 328,
      },
      {
        name: 'patientTorsoBack',
        x: 963,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1564,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 538,
        y: 1122,
        w: 309,
        h: 558,
      },
      {
        name: 'patientTorsoRight',
        x: 538,
        y: 1122,
        w: 309,
        h: 558,
      },
    ],
    linaAlAndalusi: [
      {
        name: 'patientBack',
        x: 601,
        y: 566,
        w: 599,
        h: 152,
      },
      {
        name: 'patientBody',
        x: 192,
        y: 563,
        w: 191,
        h: 108,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 790,
        w: 600,
        h: 565,
      },
      {
        name: 'patientFeet',
        x: 1202,
        y: 499,
        w: 600,
        h: 565,
      },
      {
        name: 'patientHands',
        x: 1202,
        y: 1356,
        w: 580,
        h: 515,
      },
      {
        name: 'patientHead',
        x: 192,
        y: 672,
        w: 191,
        h: 110,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 0,
        w: 600,
        h: 498,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 0,
        w: 600,
        h: 565,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 790,
        w: 600,
        h: 341,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 790,
        w: 600,
        h: 341,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1356,
        w: 600,
        h: 565,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1356,
        w: 600,
        h: 565,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1132,
        w: 600,
        h: 113,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1132,
        w: 600,
        h: 113,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 563,
        w: 191,
        h: 226,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 600,
        h: 562,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1356,
        w: 600,
        h: 565,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 1065,
        w: 600,
        h: 282,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 1065,
        w: 600,
        h: 282,
      },
    ],
    amrSaleh: [
      {
        name: 'patientBody',
        x: 1146,
        y: 428,
        w: 227,
        h: 193,
      },
      {
        name: 'patientBack',
        x: 296,
        y: 546,
        w: 242,
        h: 412,
      },
      {
        name: 'patientHeadBack',
        x: 1500,
        y: 1122,
        w: 525,
        h: 491,
      },
      {
        name: 'patientDown',
        x: 537,
        y: 1107,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 545,
        y: 561,
        w: 520,
        h: 479,
      },
      {
        name: 'patientHeadLeft',
        x: 1146,
        y: 1121,
        w: 289,
        h: 485,
      },
      {
        name: 'patientHeadRight',
        x: 1146,
        y: 1121,
        w: 289,
        h: 485,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1107,
        w: 536,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1107,
        w: 536,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1500,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1500,
        y: 561,
        w: 547,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 546,
        w: 295,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 546,
        w: 295,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1146,
        y: 622,
        w: 250,
        h: 498,
      },
      {
        name: 'patientRightSide',
        x: 1146,
        y: 622,
        w: 250,
        h: 498,
      },
      {
        name: 'patientTorso',
        x: 1146,
        y: 0,
        w: 353,
        h: 427,
      },
      {
        name: 'patientHead',
        x: 296,
        y: 959,
        w: 90,
        h: 116,
      },
      {
        name: 'patientTorsoFront',
        x: 545,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 544,
        h: 545,
      },
    ],
    andrewEvans: [
      {
        name: 'patientBack',
        x: 1200,
        y: 1169,
        w: 274,
        h: 409,
      },
      {
        name: 'patientBody',
        x: 273,
        y: 0,
        w: 313,
        h: 199,
      },
      {
        name: 'patientDown',
        x: 1483,
        y: 987,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 486,
        w: 553,
        h: 555,
      },
      {
        name: 'patientHands',
        x: 587,
        y: 378,
        w: 543,
        h: 549,
      },
      {
        name: 'patientHead',
        x: 1200,
        y: 1042,
        w: 92,
        h: 126,
      },
      {
        name: 'patientHeadBack',
        x: 1483,
        y: 561,
        w: 583,
        h: 425,
      },
      {
        name: 'patientHeadFront',
        x: 587,
        y: 0,
        w: 546,
        h: 377,
      },
      {
        name: 'patientHeadLeft',
        x: 1134,
        y: 0,
        w: 348,
        h: 445,
      },
      {
        name: 'patientHeadRight',
        x: 1134,
        y: 0,
        w: 348,
        h: 445,
      },
      {
        name: 'patientLeftArmPit',
        x: 1483,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1483,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 0,
        w: 272,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 0,
        w: 272,
        h: 485,
      },
      {
        name: 'patientTorso',
        x: 273,
        y: 200,
        w: 261,
        h: 225,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1042,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1042,
        w: 598,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1134,
        y: 446,
        w: 335,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1134,
        y: 446,
        w: 335,
        h: 560,
      },
    ],
    peterWhite: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1063,
        w: 260,
        h: 424,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1683,
        w: 600,
        h: 548,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1803,
        y: 0,
        w: 378,
        h: 558,
      },
      {
        name: 'patientHeadRight',
        x: 1803,
        y: 0,
        w: 378,
        h: 558,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1803,
        y: 559,
        w: 282,
        h: 503,
      },
      {
        name: 'patientRightSide',
        x: 1803,
        y: 559,
        w: 282,
        h: 503,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
    ],
    enricoPAccoro: [
      {
        name: 'patientBack',
        x: 1900,
        y: 1121,
        w: 266,
        h: 414,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 599,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1001,
        y: 509,
        w: 585,
        h: 469,
      },
      {
        name: 'patientHead',
        x: 825,
        y: 528,
        w: 110,
        h: 117,
      },
      {
        name: 'patientHeadBack',
        x: 1001,
        y: 0,
        w: 599,
        h: 508,
      },
      {
        name: 'patientHeadFront',
        x: 965,
        y: 1122,
        w: 575,
        h: 485,
      },
      {
        name: 'patientHeadRight',
        x: 600,
        y: 0,
        w: 400,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 600,
        y: 0,
        w: 400,
        h: 527,
      },
      {
        name: 'patientBody',
        x: 610,
        y: 528,
        w: 224,
        h: 210,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 561,
        w: 562,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 561,
        w: 562,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1601,
        y: 1121,
        w: 298,
        h: 491,
      },
      {
        name: 'patientLeftSide',
        x: 1601,
        y: 1121,
        w: 298,
        h: 491,
      },
      {
        name: 'patientFeet',
        x: 1601,
        y: 561,
        w: 554,
        h: 559,
      },
      {
        name: 'patientTorso',
        x: 580,
        y: 739,
        w: 420,
        h: 328,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1122,
        w: 363,
        h: 559,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1122,
        w: 363,
        h: 559,
      },
    ],
    lemondPolk: [
      {
        name: 'patientBack',
        x: 0,
        y: 478,
        w: 286,
        h: 408,
      },
      {
        name: 'patientBody',
        x: 287,
        y: 595,
        w: 310,
        h: 193,
      },
      {
        name: 'patientDown',
        x: 1075,
        y: 1448,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 643,
        y: 0,
        w: 540,
        h: 528,
      },
      {
        name: 'patientHead',
        x: 287,
        y: 478,
        w: 95,
        h: 116,
      },
      {
        name: 'patientHeadBack',
        x: 1184,
        y: 0,
        w: 600,
        h: 423,
      },
      {
        name: 'patientHeadFront',
        x: 1184,
        y: 424,
        w: 593,
        h: 436,
      },
      {
        name: 'patientHeadLeft',
        x: 267,
        y: 0,
        w: 375,
        h: 431,
      },
      {
        name: 'patientHeadRight',
        x: 267,
        y: 0,
        w: 375,
        h: 431,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1448,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1448,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 0,
        w: 266,
        h: 477,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 0,
        w: 266,
        h: 477,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 887,
        w: 567,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 643,
        y: 529,
        w: 349,
        h: 309,
      },
      {
        name: 'patientTorsoBack',
        x: 1184,
        y: 861,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 887,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1448,
        w: 473,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1448,
        w: 473,
        h: 560,
      },
    ],
    songZhi: [
      {
        name: 'patientHead',
        x: 402,
        y: 420,
        w: 86,
        h: 120,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1113,
        w: 543,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1113,
        w: 543,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 552,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1538,
        y: 0,
        w: 600,
        h: 552,
      },
      {
        name: 'patientHeadFront',
        x: 1538,
        y: 553,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 401,
        h: 551,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 401,
        h: 551,
      },
      {
        name: 'patientTorsoBack',
        x: 1538,
        y: 1104,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1113,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 402,
        y: 0,
        w: 257,
        h: 419,
      },
      {
        name: 'patientTorsoLeft',
        x: 660,
        y: 511,
        w: 273,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 660,
        y: 511,
        w: 273,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 660,
        y: 0,
        w: 283,
        h: 510,
      },
      {
        name: 'patientRightSide',
        x: 660,
        y: 0,
        w: 283,
        h: 510,
      },
      {
        name: 'patientHands',
        x: 944,
        y: 554,
        w: 531,
        h: 473,
      },
      {
        name: 'patientTorso',
        x: 1145,
        y: 1308,
        w: 341,
        h: 334,
      },
      {
        name: 'patientBody',
        x: 1145,
        y: 1113,
        w: 226,
        h: 194,
      },
      {
        name: 'patientFeet',
        x: 944,
        y: 0,
        w: 593,
        h: 553,
      },
    ],
    josephRodriguez: [
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    michaelRose: [
      {
        name: 'patientFeet',
        x: 824,
        y: 561,
        w: 467,
        h: 514,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 794,
        w: 192,
        h: 223,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 590,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 794,
        w: 192,
        h: 223,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 590,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1343,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1343,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 591,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 394,
        y: 0,
        w: 69,
        h: 85,
      },
      {
        name: 'patientTorso',
        x: 1192,
        y: 1122,
        w: 149,
        h: 99,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1018,
        w: 172,
        h: 96,
      },
      {
        name: 'patientHands',
        x: 464,
        y: 0,
        w: 359,
        h: 547,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 561,
        w: 199,
        h: 232,
      },
      {
        name: 'patientHeadBack',
        x: 1343,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 824,
        y: 0,
        w: 518,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 393,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 824,
        y: 0,
        w: 518,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 393,
        h: 560,
      },
    ],
    jessicaAnderson: [
      {
        name: 'patientBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1122,
        w: 403,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1122,
        w: 403,
        h: 560,
      },
    ],
    jamesRodriguez: [
      {
        name: 'patientFeet',
        x: 772,
        y: 561,
        w: 497,
        h: 480,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1129,
        y: 1122,
        w: 188,
        h: 223,
      },
      {
        name: 'patientHeadBack',
        x: 1360,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1122,
        w: 527,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1129,
        y: 1346,
        w: 188,
        h: 223,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 412,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 772,
        y: 0,
        w: 587,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1122,
        w: 527,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1129,
        y: 1346,
        w: 188,
        h: 223,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 412,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 772,
        y: 0,
        w: 587,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1360,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1360,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 413,
        y: 0,
        w: 358,
        h: 547,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 561,
        w: 72,
        h: 149,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 795,
        w: 147,
        h: 97,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 711,
        w: 156,
        h: 83,
      },
    ],
    adamPeterson: [
      {
        name: 'patientBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1803,
        y: 561,
        w: 546,
        h: 551,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1113,
        w: 473,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1113,
        w: 473,
        h: 560,
      },
    ],
    etienneShanker: [
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 540,
        h: 548,
      },
      {
        name: 'patientBack',
        x: 1239,
        y: 561,
        w: 256,
        h: 415,
      },
      {
        name: 'patientBody',
        x: 541,
        y: 561,
        w: 246,
        h: 155,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1110,
        w: 590,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 788,
        y: 561,
        w: 89,
        h: 124,
      },
      {
        name: 'patientHeadBack',
        x: 1528,
        y: 0,
        w: 600,
        h: 547,
      },
      {
        name: 'patientHeadFront',
        x: 591,
        y: 1110,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 547,
        y: 0,
        w: 415,
        h: 541,
      },
      {
        name: 'patientHeadLeft',
        x: 547,
        y: 0,
        w: 415,
        h: 541,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 546,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 546,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 963,
        y: 561,
        w: 275,
        h: 496,
      },
      {
        name: 'patientLeftSide',
        x: 963,
        y: 561,
        w: 275,
        h: 496,
      },
      {
        name: 'patientFeet',
        x: 963,
        y: 0,
        w: 564,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 541,
        y: 717,
        w: 384,
        h: 385,
      },
      {
        name: 'patientTorsoBack',
        x: 1528,
        y: 1109,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1528,
        y: 548,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1192,
        y: 1110,
        w: 317,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1192,
        y: 1110,
        w: 317,
        h: 560,
      },
    ],
    andrewParker: [
      {
        name: 'patientHands',
        x: 998,
        y: 0,
        w: 540,
        h: 545,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1093,
        w: 587,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1093,
        w: 587,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 535,
        w: 600,
        h: 557,
      },
      {
        name: 'patientDown',
        x: 1539,
        y: 1047,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 998,
        y: 546,
        w: 539,
        h: 485,
      },
      {
        name: 'patientTorsoBack',
        x: 588,
        y: 1093,
        w: 587,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1539,
        y: 561,
        w: 600,
        h: 485,
      },
      {
        name: 'patientTorsoFront',
        x: 1539,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1176,
        y: 1093,
        w: 357,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 607,
        y: 0,
        w: 390,
        h: 520,
      },
      {
        name: 'patientTorsoRight',
        x: 1176,
        y: 1093,
        w: 357,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 607,
        y: 0,
        w: 390,
        h: 520,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 116,
        w: 255,
        h: 418,
      },
      {
        name: 'patientLeftSide',
        x: 607,
        y: 521,
        w: 265,
        h: 487,
      },
      {
        name: 'patientRightSide',
        x: 607,
        y: 521,
        w: 265,
        h: 487,
      },
      {
        name: 'patientBody',
        x: 256,
        y: 341,
        w: 240,
        h: 187,
      },
      {
        name: 'patientTorso',
        x: 256,
        y: 0,
        w: 350,
        h: 340,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 80,
        h: 115,
      },
    ],
    jayGarret: [
      {
        name: 'patientTorsoFront',
        x: 880,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 880,
        y: 561,
        w: 575,
        h: 508,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 535,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 535,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1111,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1481,
        y: 1092,
        w: 593,
        h: 557,
      },
      {
        name: 'patientDown',
        x: 1481,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1830,
        y: 561,
        w: 237,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 1830,
        y: 561,
        w: 237,
        h: 485,
      },
      {
        name: 'patientBack',
        x: 1160,
        y: 1237,
        w: 229,
        h: 408,
      },
      {
        name: 'patientTorsoLeft',
        x: 536,
        y: 0,
        w: 343,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 536,
        y: 0,
        w: 343,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 544,
        y: 561,
        w: 208,
        h: 187,
      },
      {
        name: 'patientHeadLeft',
        x: 1481,
        y: 561,
        w: 348,
        h: 530,
      },
      {
        name: 'patientHeadRight',
        x: 1481,
        y: 561,
        w: 348,
        h: 530,
      },
      {
        name: 'patientTorso',
        x: 544,
        y: 749,
        w: 312,
        h: 319,
      },
      {
        name: 'patientHead',
        x: 1160,
        y: 1111,
        w: 82,
        h: 125,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1111,
        w: 558,
        h: 559,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 543,
        h: 549,
      },
    ],
    seanCooper: [
      {
        name: 'patientBack',
        x: 1159,
        y: 1122,
        w: 256,
        h: 429,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1422,
        y: 550,
        w: 590,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1683,
        w: 600,
        h: 529,
      },
      {
        name: 'patientHeadFront',
        x: 1422,
        y: 1111,
        w: 600,
        h: 514,
      },
      {
        name: 'patientHeadLeft',
        x: 1011,
        y: 0,
        w: 410,
        h: 508,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 460,
        w: 334,
        h: 497,
      },
      {
        name: 'patientHeadRight',
        x: 1011,
        y: 0,
        w: 410,
        h: 508,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 460,
        w: 334,
        h: 497,
      },
      {
        name: 'patientFeet',
        x: 1422,
        y: 0,
        w: 600,
        h: 549,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 409,
        h: 459,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1011,
        y: 509,
        w: 410,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1011,
        y: 509,
        w: 410,
        h: 560,
      },
    ],
    markWarlegan: [
      {
        name: 'patientBack',
        x: 1199,
        y: 1076,
        w: 265,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 1487,
        y: 1076,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1076,
        w: 597,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1202,
        y: 1637,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1637,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1487,
        y: 0,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 514,
      },
      {
        name: 'patientHeadLeft',
        x: 997,
        y: 0,
        w: 489,
        h: 512,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1637,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 513,
        w: 356,
        h: 513,
      },
      {
        name: 'patientHeadRight',
        x: 997,
        y: 0,
        w: 489,
        h: 512,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1637,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 513,
        w: 356,
        h: 513,
      },
      {
        name: 'patientFeet',
        x: 1487,
        y: 516,
        w: 565,
        h: 559,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 395,
        h: 512,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1076,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 997,
        y: 513,
        w: 414,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 997,
        y: 513,
        w: 414,
        h: 560,
      },
    ],
    jennaMartin: [
      {
        name: 'patientHands',
        x: 1051,
        y: 560,
        w: 382,
        h: 464,
      },
      {
        name: 'patientLeftArmPit',
        x: 1472,
        y: 0,
        w: 490,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1472,
        y: 0,
        w: 490,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1472,
        y: 561,
        w: 484,
        h: 535,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 576,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 525,
        h: 531,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1093,
        w: 486,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1051,
        y: 0,
        w: 420,
        h: 559,
      },
      {
        name: 'patientTorsoLeft',
        x: 1088,
        y: 1093,
        w: 363,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1472,
        y: 1097,
        w: 371,
        h: 548,
      },
      {
        name: 'patientTorsoRight',
        x: 1088,
        y: 1093,
        w: 363,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1472,
        y: 1097,
        w: 371,
        h: 548,
      },
      {
        name: 'patientHead',
        x: 577,
        y: 407,
        w: 117,
        h: 155,
      },
      {
        name: 'patientTorso',
        x: 784,
        y: 0,
        w: 266,
        h: 334,
      },
      {
        name: 'patientBody',
        x: 784,
        y: 844,
        w: 207,
        h: 188,
      },
      {
        name: 'patientBack',
        x: 577,
        y: 0,
        w: 206,
        h: 406,
      },
      {
        name: 'patientLeftSide',
        x: 784,
        y: 335,
        w: 255,
        h: 508,
      },
      {
        name: 'patientRightSide',
        x: 784,
        y: 335,
        w: 255,
        h: 508,
      },
      {
        name: 'patientDown',
        x: 487,
        y: 1093,
        w: 600,
        h: 560,
      },
    ],
    daveMark: [
      {
        name: 'patientBody',
        x: 633,
        y: 860,
        w: 261,
        h: 180,
      },
      {
        name: 'patientBack',
        x: 312,
        y: 0,
        w: 320,
        h: 468,
      },
      {
        name: 'patientDown',
        x: 1185,
        y: 1067,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 633,
        y: 0,
        w: 551,
        h: 506,
      },
      {
        name: 'patientHead',
        x: 895,
        y: 860,
        w: 99,
        h: 142,
      },
      {
        name: 'patientHeadBack',
        x: 1185,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1185,
        y: 561,
        w: 600,
        h: 505,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1631,
        w: 435,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1631,
        w: 435,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1070,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1070,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 0,
        w: 311,
        h: 508,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 0,
        w: 311,
        h: 508,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1070,
        w: 518,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 633,
        y: 507,
        w: 370,
        h: 352,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 509,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1631,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1631,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1631,
        w: 600,
        h: 560,
      },
    ],
    jerryCarter: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 249,
        h: 405,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 851,
        y: 0,
        w: 591,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 851,
        y: 561,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 439,
        y: 1075,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadFront',
        x: 1443,
        y: 551,
        w: 600,
        h: 514,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 458,
        h: 513,
      },
      {
        name: 'patientLeftArmPit',
        x: 1443,
        y: 1066,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 459,
        y: 561,
        w: 318,
        h: 479,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 458,
        h: 513,
      },
      {
        name: 'patientRightArmPit',
        x: 1443,
        y: 1066,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 459,
        y: 561,
        w: 318,
        h: 479,
      },
      {
        name: 'patientFeet',
        x: 1443,
        y: 0,
        w: 600,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 1040,
        y: 1075,
        w: 390,
        h: 447,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1075,
        w: 438,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1075,
        w: 438,
        h: 560,
      },
    ],
    cattyMars: [
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1617,
        y: 1122,
        w: 540,
        h: 548,
      },
      {
        name: 'patientTorsoLeft',
        x: 582,
        y: 1122,
        w: 380,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 963,
        y: 1122,
        w: 305,
        h: 492,
      },
      {
        name: 'patientTorsoRight',
        x: 582,
        y: 1122,
        w: 380,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 963,
        y: 1122,
        w: 305,
        h: 492,
      },
      {
        name: 'patientBody',
        x: 820,
        y: 558,
        w: 172,
        h: 204,
      },
      {
        name: 'patientTorso',
        x: 1269,
        y: 1122,
        w: 325,
        h: 426,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 969,
        w: 120,
        h: 136,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 558,
        w: 218,
        h: 410,
      },
      {
        name: 'patientHeadFront',
        x: 1617,
        y: 0,
        w: 591,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 0,
        w: 414,
        h: 557,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 0,
        w: 414,
        h: 557,
      },
      {
        name: 'patientTorsoFront',
        x: 1016,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 0,
        w: 587,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 581,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1016,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1617,
        y: 561,
        w: 570,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1617,
        y: 561,
        w: 570,
        h: 560,
      },
    ],
    kristenMeyer: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1641,
        w: 226,
        h: 442,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 519,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1080,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 991,
        y: 0,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 518,
      },
      {
        name: 'patientHeadFront',
        x: 1549,
        y: 0,
        w: 600,
        h: 522,
      },
      {
        name: 'patientHeadLeft',
        x: 1549,
        y: 1084,
        w: 593,
        h: 518,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 389,
        h: 498,
      },
      {
        name: 'patientHeadRight',
        x: 1549,
        y: 1084,
        w: 593,
        h: 518,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 389,
        h: 498,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1080,
        w: 585,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 499,
        w: 375,
        h: 498,
      },
      {
        name: 'patientTorsoBack',
        x: 1549,
        y: 523,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 991,
        y: 500,
        w: 479,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 991,
        y: 500,
        w: 479,
        h: 560,
      },
    ],
    cindyDervis: [
      {
        name: 'patientBack',
        x: 1159,
        y: 1122,
        w: 224,
        h: 420,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1647,
        w: 502,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1104,
        y: 1647,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 524,
      },
      {
        name: 'patientHeadFront',
        x: 1425,
        y: 1094,
        w: 600,
        h: 547,
      },
      {
        name: 'patientHeadLeft',
        x: 959,
        y: 0,
        w: 465,
        h: 530,
      },
      {
        name: 'patientLeftArmPit',
        x: 1425,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 596,
        y: 0,
        w: 362,
        h: 494,
      },
      {
        name: 'patientHeadRight',
        x: 959,
        y: 0,
        w: 465,
        h: 530,
      },
      {
        name: 'patientRightArmPit',
        x: 1425,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 596,
        y: 0,
        w: 362,
        h: 494,
      },
      {
        name: 'patientFeet',
        x: 1425,
        y: 561,
        w: 477,
        h: 532,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 561,
        w: 353,
        h: 467,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 503,
        y: 1647,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 959,
        y: 531,
        w: 438,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 959,
        y: 531,
        w: 438,
        h: 560,
      },
    ],
    georgeHarrison: [
      {
        name: 'patientHead',
        x: 1140,
        y: 1122,
        w: 73,
        h: 129,
      },
      {
        name: 'patientBack',
        x: 643,
        y: 694,
        w: 245,
        h: 427,
      },
      {
        name: 'patientBody',
        x: 643,
        y: 0,
        w: 259,
        h: 193,
      },
      {
        name: 'patientDown',
        x: 903,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1504,
        y: 1083,
        w: 543,
        h: 549,
      },
      {
        name: 'patientHeadBack',
        x: 1504,
        y: 0,
        w: 563,
        h: 522,
      },
      {
        name: 'patientHeadFront',
        x: 903,
        y: 561,
        w: 600,
        h: 532,
      },
      {
        name: 'patientHeadLeft',
        x: 320,
        y: 0,
        w: 322,
        h: 521,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1122,
        w: 538,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 643,
        y: 194,
        w: 251,
        h: 499,
      },
      {
        name: 'patientHeadRight',
        x: 320,
        y: 0,
        w: 322,
        h: 521,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1122,
        w: 538,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 643,
        y: 194,
        w: 251,
        h: 499,
      },
      {
        name: 'patientFeet',
        x: 1504,
        y: 523,
        w: 550,
        h: 559,
      },
      {
        name: 'patientTorso',
        x: 1140,
        y: 1252,
        w: 308,
        h: 359,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 319,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 319,
        h: 560,
      },
    ],
    angelDickinson: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1087,
        w: 217,
        h: 426,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 526,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1087,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1436,
        y: 561,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1648,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 525,
      },
      {
        name: 'patientHeadFront',
        x: 1436,
        y: 1117,
        w: 600,
        h: 526,
      },
      {
        name: 'patientHeadLeft',
        x: 973,
        y: 0,
        w: 462,
        h: 518,
      },
      {
        name: 'patientLeftArmPit',
        x: 1436,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 518,
        w: 364,
        h: 539,
      },
      {
        name: 'patientHeadRight',
        x: 973,
        y: 0,
        w: 462,
        h: 518,
      },
      {
        name: 'patientRightArmPit',
        x: 1436,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 518,
        w: 364,
        h: 539,
      },
      {
        name: 'patientFeet',
        x: 1202,
        y: 1648,
        w: 504,
        h: 555,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 371,
        h: 517,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1087,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1648,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 973,
        y: 519,
        w: 449,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 973,
        y: 519,
        w: 449,
        h: 560,
      },
    ],
    saraRay: [
      {
        name: 'patientBack',
        x: 1147,
        y: 1094,
        w: 234,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 1397,
        y: 1086,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1094,
        w: 588,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 589,
        y: 1094,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1655,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1397,
        y: 0,
        w: 600,
        h: 524,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 1655,
        w: 600,
        h: 547,
      },
      {
        name: 'patientHeadLeft',
        x: 478,
        y: 561,
        w: 465,
        h: 530,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 958,
        y: 561,
        w: 376,
        h: 516,
      },
      {
        name: 'patientHeadRight',
        x: 478,
        y: 561,
        w: 465,
        h: 530,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 958,
        y: 561,
        w: 376,
        h: 516,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 477,
        h: 532,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 356,
        h: 454,
      },
      {
        name: 'patientTorsoBack',
        x: 1397,
        y: 525,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1655,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 958,
        y: 0,
        w: 438,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 958,
        y: 0,
        w: 438,
        h: 560,
      },
    ],
    charlotteHeys: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1102,
        w: 208,
        h: 398,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1102,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1663,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1466,
        y: 1119,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1466,
        y: 558,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 543,
      },
      {
        name: 'patientHeadFront',
        x: 1466,
        y: 0,
        w: 600,
        h: 557,
      },
      {
        name: 'patientHeadLeft',
        x: 955,
        y: 0,
        w: 510,
        h: 537,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 1663,
        w: 575,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 353,
        h: 484,
      },
      {
        name: 'patientHeadRight',
        x: 955,
        y: 0,
        w: 510,
        h: 537,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 1663,
        w: 575,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 353,
        h: 484,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 544,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 485,
        w: 312,
        h: 369,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1663,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1102,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 955,
        y: 538,
        w: 482,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 955,
        y: 538,
        w: 482,
        h: 560,
      },
    ],
    evaSpencer: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1667,
        w: 210,
        h: 430,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1667,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1667,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1504,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1504,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1112,
        w: 600,
        h: 554,
      },
      {
        name: 'patientHeadLeft',
        x: 964,
        y: 561,
        w: 500,
        h: 534,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1667,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 362,
        h: 504,
      },
      {
        name: 'patientHeadRight',
        x: 964,
        y: 561,
        w: 500,
        h: 534,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1667,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 362,
        h: 504,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1112,
        w: 585,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 561,
        w: 353,
        h: 475,
      },
      {
        name: 'patientTorsoBack',
        x: 1504,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 964,
        y: 0,
        w: 539,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 964,
        y: 0,
        w: 539,
        h: 560,
      },
    ],
    royMendez: [
      {
        name: 'patientBack',
        x: 1178,
        y: 1075,
        w: 235,
        h: 432,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1435,
        y: 561,
        w: 598,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1435,
        y: 1122,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1636,
        w: 600,
        h: 522,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 513,
      },
      {
        name: 'patientHeadLeft',
        x: 1003,
        y: 0,
        w: 431,
        h: 521,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 561,
        w: 321,
        h: 502,
      },
      {
        name: 'patientHeadRight',
        x: 1003,
        y: 0,
        w: 431,
        h: 521,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 561,
        w: 321,
        h: 502,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1075,
        w: 576,
        h: 555,
      },
      {
        name: 'patientTorso',
        x: 1003,
        y: 522,
        w: 359,
        h: 471,
      },
      {
        name: 'patientTorsoBack',
        x: 1435,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1075,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 0,
        w: 401,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 0,
        w: 401,
        h: 560,
      },
    ],
    emaliaDahill: [
      {
        name: 'patientBack',
        x: 547,
        y: 561,
        w: 238,
        h: 434,
      },
      {
        name: 'patientBody',
        x: 590,
        y: 0,
        w: 218,
        h: 407,
      },
      {
        name: 'patientDown',
        x: 525,
        y: 1677,
        w: 592,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 546,
        h: 554,
      },
      {
        name: 'patientHead',
        x: 1259,
        y: 1054,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1259,
        y: 561,
        w: 600,
        h: 492,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1116,
        w: 600,
        h: 495,
      },
      {
        name: 'patientHeadLeft',
        x: 809,
        y: 0,
        w: 449,
        h: 493,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 589,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 809,
        y: 494,
        w: 277,
        h: 502,
      },
      {
        name: 'patientHeadRight',
        x: 809,
        y: 0,
        w: 449,
        h: 493,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 589,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 809,
        y: 494,
        w: 277,
        h: 502,
      },
      {
        name: 'patientFeet',
        x: 1118,
        y: 1677,
        w: 358,
        h: 538,
      },
      {
        name: 'patientTorso',
        x: 1477,
        y: 1677,
        w: 248,
        h: 435,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1116,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1259,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1677,
        w: 524,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1677,
        w: 524,
        h: 560,
      },
    ],
    danielaAlcon: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1122,
        w: 261,
        h: 429,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1675,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1474,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 924,
        y: 0,
        w: 549,
        h: 559,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 552,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadLeft',
        x: 924,
        y: 560,
        w: 535,
        h: 534,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1675,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 322,
        h: 508,
      },
      {
        name: 'patientHeadRight',
        x: 924,
        y: 560,
        w: 535,
        h: 534,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1675,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 322,
        h: 508,
      },
      {
        name: 'patientFeet',
        x: 1474,
        y: 1122,
        w: 593,
        h: 476,
      },
      {
        name: 'patientTorso',
        x: 470,
        y: 561,
        w: 363,
        h: 426,
      },
      {
        name: 'patientTorsoBack',
        x: 1474,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1675,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 469,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 469,
        h: 560,
      },
    ],
    anyaSimmons: [
      {
        name: 'patientHands',
        x: 601,
        y: 561,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 984,
        y: 1518,
        w: 90,
        h: 130,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1472,
        w: 380,
        h: 182,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1042,
        w: 380,
        h: 429,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 0,
        w: 600,
        h: 538,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1100,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 394,
        h: 551,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 552,
        w: 262,
        h: 489,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1100,
        w: 382,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 394,
        h: 551,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 552,
        w: 262,
        h: 489,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1100,
        w: 382,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 984,
        y: 1100,
        w: 214,
        h: 417,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 539,
        w: 588,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1597,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1597,
        y: 1080,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1597,
        y: 561,
        w: 600,
        h: 518,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
    ],
    dianeJames: [
      {
        name: 'patientHeadBack',
        x: 1658,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1037,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1658,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1076,
        y: 558,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1658,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 557,
        y: 0,
        w: 518,
        h: 541,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1197,
        y: 1122,
        w: 312,
        h: 498,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1197,
        y: 1122,
        w: 312,
        h: 498,
      },
      {
        name: 'patientFeet',
        x: 1076,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 1658,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 435,
        h: 560,
      },
    ],
    davidMason: [
      {
        name: 'patientHead',
        x: 1803,
        y: 1652,
        w: 79,
        h: 117,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 540,
      },
      {
        name: 'patientBack',
        x: 976,
        y: 453,
        w: 324,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1652,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 419,
        y: 0,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1102,
        w: 600,
        h: 549,
      },
      {
        name: 'patientHeadLeft',
        x: 1357,
        y: 1122,
        w: 537,
        h: 528,
      },
      {
        name: 'patientLeftArmPit',
        x: 1357,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 561,
        w: 372,
        h: 502,
      },
      {
        name: 'patientHeadRight',
        x: 1357,
        y: 1122,
        w: 537,
        h: 528,
      },
      {
        name: 'patientRightArmPit',
        x: 1357,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 561,
        w: 372,
        h: 502,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1102,
        w: 590,
        h: 549,
      },
      {
        name: 'patientTorso',
        x: 976,
        y: 0,
        w: 380,
        h: 452,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1652,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1652,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 418,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 418,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1357,
        y: 561,
        w: 600,
        h: 560,
      },
    ],
    johnWilson: [
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1632,
        w: 418,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1632,
        w: 418,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 510,
        w: 263,
        h: 400,
      },
      {
        name: 'patientLeftSide',
        x: 557,
        y: 0,
        w: 347,
        h: 478,
      },
      {
        name: 'patientRightSide',
        x: 557,
        y: 0,
        w: 347,
        h: 478,
      },
      {
        name: 'patientHead',
        x: 419,
        y: 1632,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1621,
        y: 1632,
        w: 370,
        h: 443,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1071,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 510,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 905,
        y: 0,
        w: 590,
        h: 549,
      },
      {
        name: 'patientHeadBack',
        x: 1496,
        y: 1077,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 905,
        y: 550,
        w: 544,
        h: 514,
      },
      {
        name: 'patientHeadRight',
        x: 905,
        y: 550,
        w: 544,
        h: 514,
      },
      {
        name: 'patientHeadFront',
        x: 1496,
        y: 0,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 556,
        h: 509,
      },
      {
        name: 'patientTorsoBack',
        x: 1496,
        y: 516,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1020,
        y: 1632,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1020,
        y: 1632,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1071,
        w: 600,
        h: 560,
      },
    ],
    aliciaBolts: [
      {
        name: 'patientBack',
        x: 1189,
        y: 1122,
        w: 218,
        h: 410,
      },
      {
        name: 'patientBody',
        x: 1408,
        y: 1122,
        w: 172,
        h: 204,
      },
      {
        name: 'patientDown',
        x: 1614,
        y: 561,
        w: 581,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1013,
        y: 561,
        w: 540,
        h: 548,
      },
      {
        name: 'patientHead',
        x: 1189,
        y: 1533,
        w: 119,
        h: 137,
      },
      {
        name: 'patientHeadBack',
        x: 1013,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 575,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 576,
        y: 561,
        w: 414,
        h: 557,
      },
      {
        name: 'patientLeftArmPit',
        x: 1614,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 707,
        y: 0,
        w: 305,
        h: 492,
      },
      {
        name: 'patientHeadRight',
        x: 576,
        y: 561,
        w: 414,
        h: 557,
      },
      {
        name: 'patientRightArmPit',
        x: 1614,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 707,
        y: 0,
        w: 305,
        h: 492,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 587,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 381,
        y: 0,
        w: 325,
        h: 426,
      },
      {
        name: 'patientTorsoBack',
        x: 1614,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 588,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 380,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 380,
        h: 560,
      },
    ],
    lesiaOldberry: [
      {
        name: 'patientBack',
        x: 894,
        y: 1117,
        w: 198,
        h: 400,
      },
      {
        name: 'patientBody',
        x: 883,
        y: 561,
        w: 168,
        h: 193,
      },
      {
        name: 'patientDown',
        x: 1103,
        y: 1084,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 883,
        y: 755,
        w: 159,
        h: 140,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1117,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1645,
        w: 494,
        h: 503,
      },
      {
        name: 'patientLeftArmPit',
        x: 549,
        y: 0,
        w: 553,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1117,
        w: 292,
        h: 480,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1645,
        w: 494,
        h: 503,
      },
      {
        name: 'patientRightArmPit',
        x: 549,
        y: 0,
        w: 553,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1117,
        w: 292,
        h: 480,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 1645,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1103,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1645,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 556,
        w: 524,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 556,
        w: 524,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHeadFront',
        x: 1103,
        y: 561,
        w: 600,
        h: 522,
      },
      {
        name: 'patientFeet',
        x: 549,
        y: 561,
        w: 333,
        h: 525,
      },
    ],
    kenHo: [
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1102,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1102,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1663,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1520,
        y: 550,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1158,
        y: 1102,
        w: 324,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1663,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1102,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 1202,
        y: 1663,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1520,
        y: 0,
        w: 600,
        h: 549,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 540,
      },
      {
        name: 'patientHeadLeft',
        x: 982,
        y: 0,
        w: 537,
        h: 528,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 453,
        w: 372,
        h: 502,
      },
      {
        name: 'patientHeadRight',
        x: 982,
        y: 0,
        w: 537,
        h: 528,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 453,
        w: 372,
        h: 502,
      },
      {
        name: 'patientFeet',
        x: 1520,
        y: 1111,
        w: 590,
        h: 549,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 380,
        h: 452,
      },
      {
        name: 'patientTorsoLeft',
        x: 982,
        y: 529,
        w: 524,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 982,
        y: 529,
        w: 524,
        h: 560,
      },
    ],
    johnOak: [
      {
        name: 'patientHands',
        x: 0,
        y: 584,
        w: 543,
        h: 549,
      },
      {
        name: 'patientRightArmPit',
        x: 1578,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1578,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1134,
        w: 522,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 978,
        y: 0,
        w: 599,
        h: 487,
      },
      {
        name: 'patientTorsoBack',
        x: 1578,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1578,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 978,
        y: 488,
        w: 556,
        h: 482,
      },
      {
        name: 'patientTorsoLeft',
        x: 523,
        y: 1134,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 523,
        y: 1134,
        w: 435,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 606,
        y: 0,
        w: 371,
        h: 528,
      },
      {
        name: 'patientHeadRight',
        x: 606,
        y: 0,
        w: 371,
        h: 528,
      },
      {
        name: 'patientBack',
        x: 341,
        y: 0,
        w: 264,
        h: 408,
      },
      {
        name: 'patientLeftSide',
        x: 606,
        y: 529,
        w: 297,
        h: 498,
      },
      {
        name: 'patientRightSide',
        x: 606,
        y: 529,
        w: 297,
        h: 498,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 0,
        w: 241,
        h: 193,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 194,
        w: 340,
        h: 389,
      },
      {
        name: 'patientHead',
        x: 341,
        y: 409,
        w: 85,
        h: 126,
      },
      {
        name: 'patientDown',
        x: 959,
        y: 1134,
        w: 600,
        h: 560,
      },
    ],
    tomBarber: [
      {
        name: 'patientBack',
        x: 1202,
        y: 480,
        w: 286,
        h: 400,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1075,
        w: 592,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1109,
        y: 1636,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 514,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1516,
        y: 0,
        w: 600,
        h: 521,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 0,
        w: 600,
        h: 513,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 561,
        w: 600,
        h: 513,
      },
      {
        name: 'patientLeftArmPit',
        x: 508,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 0,
        w: 313,
        h: 479,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 561,
        w: 600,
        h: 513,
      },
      {
        name: 'patientRightArmPit',
        x: 508,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 0,
        w: 313,
        h: 479,
      },
      {
        name: 'patientFeet',
        x: 1516,
        y: 1083,
        w: 593,
        h: 521,
      },
      {
        name: 'patientTorso',
        x: 1667,
        y: 1636,
        w: 408,
        h: 425,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1075,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1516,
        y: 522,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1636,
        w: 507,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1636,
        w: 507,
        h: 560,
      },
    ],
    rogerSheperd: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 269,
        h: 439,
      },
      {
        name: 'patientBody',
        x: 1457,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1202,
        y: 1647,
        w: 586,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 871,
        y: 551,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1647,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1457,
        y: 1090,
        w: 600,
        h: 534,
      },
      {
        name: 'patientHeadFront',
        x: 1457,
        y: 561,
        w: 600,
        h: 528,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 474,
        h: 524,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 475,
        y: 561,
        w: 358,
        h: 513,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 474,
        h: 524,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 475,
        y: 561,
        w: 358,
        h: 513,
      },
      {
        name: 'patientFeet',
        x: 871,
        y: 0,
        w: 585,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 1031,
        y: 1086,
        w: 408,
        h: 486,
      },
      {
        name: 'patientTorsoBack',
        x: 430,
        y: 1086,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1647,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1086,
        w: 429,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1086,
        w: 429,
        h: 560,
      },
    ],
    emilianoParis: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1116,
        w: 236,
        h: 382,
      },
      {
        name: 'patientBody',
        x: 1448,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 555,
        w: 594,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 546,
        h: 554,
      },
      {
        name: 'patientHead',
        x: 1448,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1116,
        w: 600,
        h: 529,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1116,
        w: 600,
        h: 514,
      },
      {
        name: 'patientHeadLeft',
        x: 942,
        y: 0,
        w: 505,
        h: 521,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 595,
        y: 555,
        w: 314,
        h: 464,
      },
      {
        name: 'patientHeadRight',
        x: 942,
        y: 0,
        w: 505,
        h: 521,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 595,
        y: 555,
        w: 314,
        h: 464,
      },
      {
        name: 'patientFeet',
        x: 1448,
        y: 1122,
        w: 598,
        h: 508,
      },
      {
        name: 'patientTorso',
        x: 547,
        y: 0,
        w: 394,
        h: 446,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 942,
        y: 522,
        w: 401,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 942,
        y: 522,
        w: 401,
        h: 560,
      },
    ],
    joshuaJameson: [
      {
        name: 'patientBack',
        x: 601,
        y: 561,
        w: 256,
        h: 433,
      },
      {
        name: 'patientBody',
        x: 929,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1674,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 1122,
        w: 546,
        h: 551,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 547,
        y: 1122,
        w: 600,
        h: 516,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 1674,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 1530,
        y: 1114,
        w: 473,
        h: 517,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1148,
        y: 1122,
        w: 328,
        h: 510,
      },
      {
        name: 'patientHeadRight',
        x: 1530,
        y: 1114,
        w: 473,
        h: 517,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1148,
        y: 1122,
        w: 328,
        h: 510,
      },
      {
        name: 'patientFeet',
        x: 1530,
        y: 561,
        w: 473,
        h: 552,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 327,
        h: 492,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1674,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 929,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1530,
        y: 0,
        w: 473,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1530,
        y: 0,
        w: 473,
        h: 560,
      },
    ],
    nadiaSolanski: [
      {
        name: 'patientLeftSide',
        x: 513,
        y: 561,
        w: 195,
        h: 223,
      },
      {
        name: 'patientRightSide',
        x: 513,
        y: 561,
        w: 195,
        h: 223,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 745,
        y: 0,
        w: 600,
        h: 559,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1122,
        w: 501,
        h: 514,
      },
      {
        name: 'patientLeftArmPit',
        x: 745,
        y: 560,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 745,
        y: 560,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 513,
        y: 785,
        w: 64,
        h: 107,
      },
      {
        name: 'patientTorso',
        x: 1103,
        y: 1363,
        w: 163,
        h: 100,
      },
      {
        name: 'patientBody',
        x: 1103,
        y: 1464,
        w: 186,
        h: 95,
      },
      {
        name: 'patientHands',
        x: 385,
        y: 0,
        w: 359,
        h: 547,
      },
      {
        name: 'patientTorsoFront',
        x: 1346,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1346,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1346,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 384,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 512,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 384,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 512,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1103,
        y: 1122,
        w: 220,
        h: 240,
      },
    ],
    muhanadKattan: [
      {
        name: 'patientHead',
        x: 1403,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1108,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 516,
        y: 0,
        w: 329,
        h: 516,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1108,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 516,
        y: 0,
        w: 329,
        h: 516,
      },
      {
        name: 'patientFeet',
        x: 846,
        y: 510,
        w: 541,
        h: 554,
      },
      {
        name: 'patientTorso',
        x: 1570,
        y: 1669,
        w: 379,
        h: 518,
      },
      {
        name: 'patientTorsoFront',
        x: 1403,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 594,
        y: 1669,
        w: 374,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 594,
        y: 1669,
        w: 374,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 587,
        y: 547,
        w: 233,
        h: 435,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1108,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 846,
        y: 0,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHeadBack',
        x: 1405,
        y: 1122,
        w: 600,
        h: 546,
      },
      {
        name: 'patientHeadFront',
        x: 969,
        y: 1669,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 515,
        h: 546,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 515,
        h: 546,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 547,
        w: 586,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1669,
        w: 593,
        h: 560,
      },
    ],
    tomSimpson: [
      {
        name: 'patientHands',
        x: 1464,
        y: 1085,
        w: 572,
        h: 465,
      },
      {
        name: 'patientBack',
        x: 1202,
        y: 0,
        w: 261,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 498,
        y: 1644,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 511,
        y: 1122,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1464,
        y: 561,
        w: 600,
        h: 523,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1122,
        w: 510,
        h: 521,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1112,
        y: 1122,
        w: 309,
        h: 511,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1122,
        w: 510,
        h: 521,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1112,
        y: 1122,
        w: 309,
        h: 511,
      },
      {
        name: 'patientFeet',
        x: 1099,
        y: 1644,
        w: 580,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 1680,
        y: 1644,
        w: 370,
        h: 533,
      },
      {
        name: 'patientTorsoBack',
        x: 1464,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1644,
        w: 497,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1644,
        w: 497,
        h: 560,
      },
    ],
    arthurKelly: [
      {
        name: 'patientBack',
        x: 1156,
        y: 558,
        w: 258,
        h: 424,
      },
      {
        name: 'patientBody',
        x: 384,
        y: 227,
        w: 213,
        h: 187,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1552,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 611,
        y: 0,
        w: 544,
        h: 531,
      },
      {
        name: 'patientHead',
        x: 533,
        y: 510,
        w: 75,
        h: 128,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 510,
        w: 532,
        h: 480,
      },
      {
        name: 'patientHeadFront',
        x: 611,
        y: 532,
        w: 525,
        h: 422,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 383,
        h: 509,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 383,
        h: 509,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 991,
        w: 554,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 991,
        w: 554,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1552,
        w: 288,
        h: 513,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1552,
        w: 288,
        h: 513,
      },
      {
        name: 'patientFeet',
        x: 1156,
        y: 0,
        w: 364,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 384,
        y: 0,
        w: 226,
        h: 226,
      },
      {
        name: 'patientTorsoBack',
        x: 555,
        y: 991,
        w: 589,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1552,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1145,
        y: 991,
        w: 366,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1145,
        y: 991,
        w: 366,
        h: 560,
      },
    ],
    billClark: [
      {
        name: 'patientBack',
        x: 899,
        y: 1683,
        w: 283,
        h: 429,
      },
      {
        name: 'patientBody',
        x: 1541,
        y: 1683,
        w: 254,
        h: 189,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1541,
        y: 1873,
        w: 85,
        h: 111,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1683,
        w: 600,
        h: 539,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1122,
        w: 509,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1122,
        w: 509,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1683,
        w: 297,
        h: 508,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1683,
        w: 297,
        h: 508,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1183,
        y: 1683,
        w: 357,
        h: 337,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
    ],
    nazaninPahlevan: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1653,
        w: 229,
        h: 445,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1092,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1464,
        y: 1119,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 531,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 530,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 1653,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHeadLeft',
        x: 972,
        y: 0,
        w: 491,
        h: 525,
      },
      {
        name: 'patientLeftArmPit',
        x: 1464,
        y: 558,
        w: 575,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 437,
        w: 369,
        h: 525,
      },
      {
        name: 'patientHeadRight',
        x: 972,
        y: 0,
        w: 491,
        h: 525,
      },
      {
        name: 'patientRightArmPit',
        x: 1464,
        y: 558,
        w: 575,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 437,
        w: 369,
        h: 525,
      },
      {
        name: 'patientFeet',
        x: 1464,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 370,
        h: 436,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1092,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 972,
        y: 526,
        w: 410,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 972,
        y: 526,
        w: 410,
        h: 560,
      },
    ],
    melisentMccraw: [
      {
        name: 'patientBack',
        x: 601,
        y: 447,
        w: 211,
        h: 406,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 0,
        w: 236,
        h: 446,
      },
      {
        name: 'patientDown',
        x: 596,
        y: 1052,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 552,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1474,
        y: 1119,
        w: 301,
        h: 405,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1613,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 838,
        y: 0,
        w: 362,
        h: 544,
      },
      {
        name: 'patientLeftArmPit',
        x: 1197,
        y: 1613,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1201,
        y: 1119,
        w: 272,
        h: 445,
      },
      {
        name: 'patientHeadRight',
        x: 838,
        y: 0,
        w: 362,
        h: 544,
      },
      {
        name: 'patientRightArmPit',
        x: 1197,
        y: 1613,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1201,
        y: 1119,
        w: 272,
        h: 445,
      },
      {
        name: 'patientFeet',
        x: 1201,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 838,
        y: 545,
        w: 301,
        h: 305,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1052,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1613,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1201,
        y: 558,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1201,
        y: 558,
        w: 435,
        h: 560,
      },
    ],
    brandiGraham: [
      {
        name: 'patientBack',
        x: 1574,
        y: 1122,
        w: 317,
        h: 510,
      },
      {
        name: 'patientBody',
        x: 533,
        y: 561,
        w: 172,
        h: 238,
      },
      {
        name: 'patientDown',
        x: 973,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 952,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 835,
        y: 800,
        w: 137,
        h: 155,
      },
      {
        name: 'patientHeadBack',
        x: 1574,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 532,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 350,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 350,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 973,
        y: 561,
        w: 540,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 973,
        y: 561,
        w: 540,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1892,
        y: 1122,
        w: 251,
        h: 502,
      },
      {
        name: 'patientRightSide',
        x: 1892,
        y: 1122,
        w: 251,
        h: 502,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 0,
        w: 473,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 533,
        y: 805,
        w: 301,
        h: 310,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1574,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 474,
        y: 0,
        w: 498,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 474,
        y: 0,
        w: 498,
        h: 560,
      },
    ],
    lillianGreen: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1112,
        w: 210,
        h: 430,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1673,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1465,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1465,
        y: 561,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1202,
        y: 1673,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1112,
        w: 600,
        h: 554,
      },
      {
        name: 'patientHeadLeft',
        x: 964,
        y: 0,
        w: 500,
        h: 534,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 362,
        h: 504,
      },
      {
        name: 'patientHeadRight',
        x: 964,
        y: 0,
        w: 500,
        h: 534,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 362,
        h: 504,
      },
      {
        name: 'patientFeet',
        x: 1465,
        y: 1061,
        w: 585,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 561,
        w: 353,
        h: 475,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1673,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1112,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 964,
        y: 535,
        w: 407,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 964,
        y: 535,
        w: 407,
        h: 560,
      },
    ],
    annaShermin: [
      {
        name: 'patientLeftSide',
        x: 397,
        y: 561,
        w: 307,
        h: 499,
      },
      {
        name: 'patientRightSide',
        x: 397,
        y: 561,
        w: 307,
        h: 499,
      },
      {
        name: 'patientBody',
        x: 1163,
        y: 1467,
        w: 207,
        h: 197,
      },
      {
        name: 'patientTorso',
        x: 1163,
        y: 1116,
        w: 343,
        h: 350,
      },
      {
        name: 'patientHead',
        x: 1371,
        y: 1467,
        w: 111,
        h: 164,
      },
      {
        name: 'patientBack',
        x: 705,
        y: 561,
        w: 214,
        h: 416,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1116,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 396,
        h: 554,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 396,
        h: 554,
      },
      {
        name: 'patientHeadFront',
        x: 996,
        y: 561,
        w: 542,
        h: 526,
      },
      {
        name: 'patientHeadBack',
        x: 1597,
        y: 561,
        w: 562,
        h: 557,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1116,
        w: 561,
        h: 559,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 559,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 560,
        y: 0,
        w: 435,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 559,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 560,
        y: 0,
        w: 435,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1597,
        y: 1119,
        w: 557,
        h: 499,
      },
      {
        name: 'patientDown',
        x: 996,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1597,
        y: 0,
        w: 600,
        h: 560,
      },
    ],
    isabellaJohnson: [
      {
        name: 'patientHands',
        x: 0,
        y: 1178,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1615,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 617,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 617,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1615,
        y: 1122,
        w: 599,
        h: 555,
      },
      {
        name: 'patientDown',
        x: 1615,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 532,
        y: 0,
        w: 499,
        h: 558,
      },
      {
        name: 'patientLeftArmPit',
        x: 1032,
        y: 0,
        w: 582,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1032,
        y: 0,
        w: 582,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 308,
        y: 0,
        w: 223,
        h: 415,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 131,
        w: 307,
        h: 485,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1178,
        w: 393,
        h: 559,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 131,
        w: 307,
        h: 485,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1178,
        w: 393,
        h: 559,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1178,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 117,
        h: 130,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 809,
        w: 313,
        h: 319,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 617,
        w: 180,
        h: 191,
      },
      {
        name: 'patientHeadFront',
        x: 1032,
        y: 561,
        w: 573,
        h: 544,
      },
    ],
    meganSliva: [
      {
        name: 'patientBack',
        x: 587,
        y: 533,
        w: 241,
        h: 465,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 533,
        w: 586,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 511,
        y: 1094,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 852,
        y: 0,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1453,
        y: 1122,
        w: 600,
        h: 518,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 494,
        h: 532,
      },
      {
        name: 'patientLeftArmPit',
        x: 1453,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1060,
        y: 1094,
        w: 381,
        h: 506,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 494,
        h: 532,
      },
      {
        name: 'patientRightArmPit',
        x: 1453,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1060,
        y: 1094,
        w: 381,
        h: 506,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1094,
        w: 510,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 495,
        y: 0,
        w: 356,
        h: 514,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1453,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 852,
        y: 521,
        w: 403,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 852,
        y: 521,
        w: 403,
        h: 560,
      },
    ],
    jeremyRamzi: [
      {
        name: 'patientBack',
        x: 596,
        y: 561,
        w: 285,
        h: 461,
      },
      {
        name: 'patientBody',
        x: 1513,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 561,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 955,
        y: 0,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1513,
        y: 561,
        w: 600,
        h: 542,
      },
      {
        name: 'patientHeadFront',
        x: 1513,
        y: 1110,
        w: 600,
        h: 553,
      },
      {
        name: 'patientHeadLeft',
        x: 579,
        y: 1122,
        w: 500,
        h: 523,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 353,
        h: 477,
      },
      {
        name: 'patientHeadRight',
        x: 579,
        y: 1122,
        w: 500,
        h: 523,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 353,
        h: 477,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 578,
        h: 558,
      },
      {
        name: 'patientTorso',
        x: 1080,
        y: 1122,
        w: 383,
        h: 493,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 955,
        y: 500,
        w: 440,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 955,
        y: 500,
        w: 440,
        h: 560,
      },
    ],
    javadBanivand: [
      {
        name: 'patientBack',
        x: 1159,
        y: 1119,
        w: 281,
        h: 410,
      },
      {
        name: 'patientBody',
        x: 1455,
        y: 1064,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1635,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1119,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1455,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1455,
        y: 561,
        w: 600,
        h: 502,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1119,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 1000,
        y: 0,
        w: 454,
        h: 514,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 558,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 558,
        w: 351,
        h: 523,
      },
      {
        name: 'patientHeadRight',
        x: 1000,
        y: 0,
        w: 454,
        h: 514,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 558,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 558,
        w: 351,
        h: 523,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 0,
        w: 585,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 586,
        y: 0,
        w: 413,
        h: 513,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1635,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1635,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1000,
        y: 515,
        w: 452,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1000,
        y: 515,
        w: 452,
        h: 560,
      },
    ],
    kevinWhinery: [
      {
        name: 'patientBack',
        x: 367,
        y: 217,
        w: 184,
        h: 227,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1031,
        w: 159,
        h: 80,
      },
      {
        name: 'patientDown',
        x: 1042,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 363,
        h: 480,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 481,
        w: 358,
        h: 549,
      },
      {
        name: 'patientHead',
        x: 965,
        y: 0,
        w: 76,
        h: 95,
      },
      {
        name: 'patientHeadBack',
        x: 1094,
        y: 1122,
        w: 538,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 366,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 367,
        y: 0,
        w: 187,
        h: 216,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 366,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 367,
        y: 0,
        w: 187,
        h: 216,
      },
      {
        name: 'patientTorso',
        x: 367,
        y: 445,
        w: 146,
        h: 98,
      },
      {
        name: 'patientTorsoBack',
        x: 493,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1042,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1122,
        w: 492,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1122,
        w: 492,
        h: 560,
      },
    ],
    donnaWilliams: [
      {
        name: 'patientTorsoBack',
        x: 1158,
        y: 1058,
        w: 567,
        h: 534,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1740,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1740,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1740,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1740,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1683,
        w: 589,
        h: 558,
      },
      {
        name: 'patientDown',
        x: 1740,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 561,
        w: 216,
        h: 416,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1122,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1122,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1158,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1158,
        y: 558,
        w: 557,
        h: 499,
      },
    ],
    elizabethKnightly: [
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1122,
        w: 395,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1122,
        w: 395,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
    ],
    vanHarrison: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1653,
        w: 287,
        h: 447,
      },
      {
        name: 'patientBody',
        x: 1513,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 950,
        y: 1094,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1202,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1513,
        y: 561,
        w: 600,
        h: 524,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 532,
      },
      {
        name: 'patientHeadLeft',
        x: 991,
        y: 0,
        w: 521,
        h: 512,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 579,
        y: 1094,
        w: 370,
        h: 513,
      },
      {
        name: 'patientHeadRight',
        x: 991,
        y: 0,
        w: 521,
        h: 512,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1653,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 579,
        y: 1094,
        w: 370,
        h: 513,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1094,
        w: 578,
        h: 558,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 389,
        h: 459,
      },
      {
        name: 'patientTorsoBack',
        x: 1513,
        y: 1086,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 991,
        y: 513,
        w: 440,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 991,
        y: 513,
        w: 440,
        h: 560,
      },
    ],
    larrySimpsons: [
      {
        name: 'patientBack',
        x: 1253,
        y: 1118,
        w: 287,
        h: 439,
      },
      {
        name: 'patientDown',
        x: 1580,
        y: 1072,
        w: 600,
        h: 559,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 576,
        h: 556,
      },
      {
        name: 'patientHands',
        x: 698,
        y: 1118,
        w: 554,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 844,
        y: 561,
        w: 88,
        h: 120,
      },
      {
        name: 'patientHeadBack',
        x: 980,
        y: 0,
        w: 599,
        h: 515,
      },
      {
        name: 'patientHeadFront',
        x: 1580,
        y: 560,
        w: 600,
        h: 511,
      },
      {
        name: 'patientHeadLeft',
        x: 321,
        y: 1118,
        w: 376,
        h: 512,
      },
      {
        name: 'patientHeadRight',
        x: 321,
        y: 1118,
        w: 376,
        h: 512,
      },
      {
        name: 'patientBody',
        x: 577,
        y: 561,
        w: 266,
        h: 145,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1118,
        w: 320,
        h: 513,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1118,
        w: 320,
        h: 513,
      },
      {
        name: 'patientTorso',
        x: 577,
        y: 707,
        w: 390,
        h: 357,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 0,
        w: 378,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 0,
        w: 378,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 980,
        y: 516,
        w: 592,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 980,
        y: 516,
        w: 592,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1580,
        y: 0,
        w: 600,
        h: 559,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
    ],
    martinHarris: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 302,
        h: 466,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 467,
        w: 242,
        h: 194,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 662,
        w: 262,
        h: 314,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1045,
        y: 1683,
        w: 600,
        h: 549,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1683,
        w: 443,
        h: 556,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 904,
        y: 340,
        w: 322,
        h: 490,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1683,
        w: 443,
        h: 556,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 904,
        y: 340,
        w: 322,
        h: 490,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 904,
        y: 0,
        w: 338,
        h: 339,
      },
      {
        name: 'patientTorsoBack',
        x: 1243,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1243,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1243,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1243,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    lillianMcKay: [
      {
        name: 'patientHeadBack',
        x: 1658,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1037,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1658,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1076,
        y: 558,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1658,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 557,
        y: 0,
        w: 518,
        h: 541,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1197,
        y: 1122,
        w: 312,
        h: 498,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1197,
        y: 1122,
        w: 312,
        h: 498,
      },
      {
        name: 'patientFeet',
        x: 1076,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 1658,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 435,
        h: 560,
      },
    ],
    shahrzadDickinson: [
      {
        name: 'patientFeet',
        x: 422,
        y: 1115,
        w: 536,
        h: 557,
      },
      {
        name: 'patientHands',
        x: 954,
        y: 561,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 823,
        y: 893,
        w: 105,
        h: 164,
      },
      {
        name: 'patientHeadBack',
        x: 959,
        y: 1115,
        w: 530,
        h: 539,
      },
      {
        name: 'patientHeadFront',
        x: 452,
        y: 0,
        w: 501,
        h: 539,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 451,
        h: 553,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 451,
        h: 553,
      },
      {
        name: 'patientLeftSide',
        x: 1548,
        y: 1122,
        w: 328,
        h: 507,
      },
      {
        name: 'patientRightSide',
        x: 1548,
        y: 1122,
        w: 328,
        h: 507,
      },
      {
        name: 'patientLeftArmPit',
        x: 954,
        y: 0,
        w: 593,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 954,
        y: 0,
        w: 593,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 554,
        w: 331,
        h: 338,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 554,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1548,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1115,
        w: 421,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1115,
        w: 421,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1877,
        y: 1122,
        w: 232,
        h: 442,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 893,
        w: 221,
        h: 207,
      },
      {
        name: 'patientDown',
        x: 1548,
        y: 0,
        w: 600,
        h: 560,
      },
    ],
    magganSpencer: [
      {
        name: 'patientBack',
        x: 878,
        y: 561,
        w: 220,
        h: 419,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1451,
        w: 207,
        h: 188,
      },
      {
        name: 'patientDown',
        x: 1202,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1043,
        y: 1085,
        w: 117,
        h: 128,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 533,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 0,
        w: 594,
        h: 532,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1085,
        w: 441,
        h: 551,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 561,
        w: 276,
        h: 492,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1085,
        w: 441,
        h: 551,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 561,
        w: 276,
        h: 492,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1085,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 561,
        w: 334,
        h: 328,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 890,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1646,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1646,
        w: 600,
        h: 560,
      },
    ],
    susanHarrison: [
      {
        name: 'patientHead',
        x: 568,
        y: 561,
        w: 209,
        h: 507,
      },
      {
        name: 'patientTorso',
        x: 396,
        y: 0,
        w: 343,
        h: 379,
      },
      {
        name: 'patientBody',
        x: 396,
        y: 380,
        w: 252,
        h: 172,
      },
      {
        name: 'patientLeftSide',
        x: 740,
        y: 0,
        w: 298,
        h: 495,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 395,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 740,
        y: 0,
        w: 298,
        h: 495,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 395,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1640,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1640,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1122,
        w: 419,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1122,
        w: 419,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 420,
        y: 1122,
        w: 595,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 778,
        y: 561,
        w: 223,
        h: 424,
      },
      {
        name: 'patientHeadBack',
        x: 1640,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1039,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1039,
        y: 561,
        w: 573,
        h: 557,
      },
      {
        name: 'patientHands',
        x: 1016,
        y: 1122,
        w: 546,
        h: 526,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 561,
        w: 567,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 561,
        w: 567,
        h: 560,
      },
    ],
    jamesMetler: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 246,
        h: 424,
      },
      {
        name: 'patientBody',
        x: 396,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1471,
        y: 1683,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 848,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1449,
        y: 0,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadLeft',
        x: 585,
        y: 1683,
        w: 509,
        h: 546,
      },
      {
        name: 'patientLeftArmPit',
        x: 1449,
        y: 1113,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1095,
        y: 1683,
        w: 375,
        h: 521,
      },
      {
        name: 'patientHeadRight',
        x: 585,
        y: 1683,
        w: 509,
        h: 546,
      },
      {
        name: 'patientRightArmPit',
        x: 1449,
        y: 1113,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1095,
        y: 1683,
        w: 375,
        h: 521,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1683,
        w: 584,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 997,
        y: 1122,
        w: 414,
        h: 471,
      },
      {
        name: 'patientTorsoBack',
        x: 848,
        y: 561,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1449,
        y: 552,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1122,
        w: 395,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1122,
        w: 395,
        h: 560,
      },
    ],
    johnBloom: [
      {
        name: 'patientBody',
        x: 0,
        y: 500,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1622,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 557,
        h: 499,
      },
      {
        name: 'patientLeftArmPit',
        x: 1422,
        y: 529,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1422,
        y: 529,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1197,
        y: 1622,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 596,
        y: 1622,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 500,
        w: 271,
        h: 428,
      },
      {
        name: 'patientHead',
        x: 886,
        y: 0,
        w: 535,
        h: 501,
      },
      {
        name: 'patientHeadBack',
        x: 1422,
        y: 1090,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadFront',
        x: 368,
        y: 1061,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 1422,
        y: 0,
        w: 600,
        h: 528,
      },
      {
        name: 'patientLeftSide',
        x: 969,
        y: 1061,
        w: 369,
        h: 515,
      },
      {
        name: 'patientHeadRight',
        x: 1422,
        y: 0,
        w: 600,
        h: 528,
      },
      {
        name: 'patientRightSide',
        x: 969,
        y: 1061,
        w: 369,
        h: 515,
      },
      {
        name: 'patientFeet',
        x: 886,
        y: 502,
        w: 518,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 558,
        y: 0,
        w: 327,
        h: 487,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1061,
        w: 367,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1061,
        w: 367,
        h: 560,
      },
    ],
    adrianColeman: [
      {
        name: 'patientHands',
        x: 587,
        y: 1122,
        w: 540,
        h: 544,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 586,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 586,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1569,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 977,
        w: 90,
        h: 126,
      },
      {
        name: 'patientTorso',
        x: 1159,
        y: 710,
        w: 372,
        h: 390,
      },
      {
        name: 'patientBody',
        x: 1159,
        y: 519,
        w: 246,
        h: 190,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 557,
        h: 486,
      },
      {
        name: 'patientHeadFront',
        x: 1569,
        y: 1122,
        w: 598,
        h: 491,
      },
      {
        name: 'patientTorsoFront',
        x: 1569,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1159,
        y: 0,
        w: 409,
        h: 518,
      },
      {
        name: 'patientTorsoLeft',
        x: 1159,
        y: 1101,
        w: 352,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1159,
        y: 0,
        w: 409,
        h: 518,
      },
      {
        name: 'patientTorsoRight',
        x: 1159,
        y: 1101,
        w: 352,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 876,
        y: 487,
        w: 263,
        h: 422,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 487,
        w: 274,
        h: 489,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 487,
        w: 274,
        h: 489,
      },
    ],
    steveMarshall: [
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    simonRobins: [
      {
        name: 'patientBack',
        x: 1198,
        y: 1118,
        w: 287,
        h: 407,
      },
      {
        name: 'patientBody',
        x: 1496,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1118,
        w: 596,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 0,
        w: 548,
        h: 556,
      },
      {
        name: 'patientHead',
        x: 1496,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1131,
        y: 1679,
        w: 600,
        h: 513,
      },
      {
        name: 'patientHeadFront',
        x: 1496,
        y: 1122,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1679,
        w: 529,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 597,
        y: 1118,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 549,
        y: 0,
        w: 345,
        h: 483,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1679,
        w: 529,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 597,
        y: 1118,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 549,
        y: 0,
        w: 345,
        h: 483,
      },
      {
        name: 'patientFeet',
        x: 895,
        y: 561,
        w: 596,
        h: 549,
      },
      {
        name: 'patientTorso',
        x: 508,
        y: 557,
        w: 381,
        h: 454,
      },
      {
        name: 'patientTorsoBack',
        x: 530,
        y: 1679,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 895,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 557,
        w: 507,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 557,
        w: 507,
        h: 560,
      },
    ],
    josephTsai: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 258,
        h: 465,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 860,
        y: 551,
        w: 548,
        h: 556,
      },
      {
        name: 'patientHead',
        x: 1444,
        y: 1076,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1683,
        w: 600,
        h: 534,
      },
      {
        name: 'patientHeadFront',
        x: 1444,
        y: 561,
        w: 600,
        h: 514,
      },
      {
        name: 'patientHeadLeft',
        x: 962,
        y: 1122,
        w: 398,
        h: 514,
      },
      {
        name: 'patientLeftArmPit',
        x: 1444,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1122,
        w: 360,
        h: 529,
      },
      {
        name: 'patientHeadRight',
        x: 962,
        y: 1122,
        w: 398,
        h: 514,
      },
      {
        name: 'patientRightArmPit',
        x: 1444,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1122,
        w: 360,
        h: 529,
      },
      {
        name: 'patientFeet',
        x: 860,
        y: 0,
        w: 583,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 367,
        y: 561,
        w: 404,
        h: 508,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 366,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 366,
        h: 560,
      },
    ],
    albertoDouglas: [
      {
        name: 'patientBack',
        x: 601,
        y: 432,
        w: 239,
        h: 387,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 820,
        w: 214,
        h: 142,
      },
      {
        name: 'patientFeet',
        x: 859,
        y: 1122,
        w: 370,
        h: 550,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 311,
        w: 86,
        h: 120,
      },
      {
        name: 'patientLeftSide',
        x: 1230,
        y: 1122,
        w: 255,
        h: 446,
      },
      {
        name: 'patientRightSide',
        x: 1230,
        y: 1122,
        w: 255,
        h: 446,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 318,
        h: 310,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1122,
        w: 402,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1122,
        w: 402,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 920,
        y: 0,
        w: 592,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 920,
        y: 561,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1513,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 403,
        y: 1122,
        w: 455,
        h: 559,
      },
      {
        name: 'patientHeadRight',
        x: 403,
        y: 1122,
        w: 455,
        h: 559,
      },
      {
        name: 'patientLeftArmPit',
        x: 1513,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1513,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1513,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    anthonyBrandon: [
      {
        name: 'patientBack',
        x: 263,
        y: 0,
        w: 264,
        h: 404,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1128,
        w: 256,
        h: 151,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 454,
        w: 77,
        h: 112,
      },
      {
        name: 'patientHeadLeft',
        x: 528,
        y: 0,
        w: 499,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 0,
        w: 262,
        h: 453,
      },
      {
        name: 'patientHeadRight',
        x: 528,
        y: 0,
        w: 499,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 0,
        w: 262,
        h: 453,
      },
      {
        name: 'patientDown',
        x: 1028,
        y: 0,
        w: 582,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1611,
        y: 561,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHands',
        x: 1028,
        y: 561,
        w: 572,
        h: 456,
      },
      {
        name: 'patientHeadBack',
        x: 1611,
        y: 1112,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1611,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 567,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 567,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1280,
        w: 362,
        h: 321,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 567,
        w: 323,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 567,
        w: 323,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1128,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1128,
        w: 600,
        h: 560,
      },
    ],
    brandonGreen: [
      {
        name: 'patientBack',
        x: 764,
        y: 0,
        w: 238,
        h: 405,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 0,
        w: 262,
        h: 179,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1230,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1003,
        y: 551,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 764,
        y: 406,
        w: 90,
        h: 155,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 672,
        w: 600,
        h: 557,
      },
      {
        name: 'patientHeadFront',
        x: 1584,
        y: 561,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadLeft',
        x: 296,
        y: 0,
        w: 467,
        h: 550,
      },
      {
        name: 'patientLeftArmPit',
        x: 1584,
        y: 1107,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 180,
        w: 295,
        h: 491,
      },
      {
        name: 'patientHeadRight',
        x: 296,
        y: 0,
        w: 467,
        h: 550,
      },
      {
        name: 'patientRightArmPit',
        x: 1584,
        y: 1107,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 180,
        w: 295,
        h: 491,
      },
      {
        name: 'patientFeet',
        x: 1003,
        y: 0,
        w: 580,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 672,
        w: 344,
        h: 476,
      },
      {
        name: 'patientTorsoBack',
        x: 1584,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1230,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 1230,
        w: 371,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 1230,
        w: 371,
        h: 560,
      },
    ],
    leonSheldon: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 568,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 568,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1198,
        y: 0,
        w: 548,
        h: 559,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 0,
        w: 596,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1747,
        y: 506,
        w: 240,
        h: 420,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 561,
        w: 587,
        h: 494,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1747,
        y: 1297,
        w: 222,
        h: 214,
      },
      {
        name: 'patientTorso',
        x: 1747,
        y: 927,
        w: 324,
        h: 369,
      },
      {
        name: 'patientHead',
        x: 1747,
        y: 1512,
        w: 81,
        h: 123,
      },
      {
        name: 'patientLeftSide',
        x: 882,
        y: 1122,
        w: 250,
        h: 491,
      },
      {
        name: 'patientTorsoLeft',
        x: 569,
        y: 1122,
        w: 312,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1747,
        y: 0,
        w: 352,
        h: 505,
      },
      {
        name: 'patientRightSide',
        x: 882,
        y: 1122,
        w: 250,
        h: 491,
      },
      {
        name: 'patientTorsoRight',
        x: 569,
        y: 1122,
        w: 312,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1747,
        y: 0,
        w: 352,
        h: 505,
      },
      {
        name: 'patientHeadBack',
        x: 1198,
        y: 1110,
        w: 548,
        h: 530,
      },
      {
        name: 'patientHands',
        x: 1198,
        y: 560,
        w: 543,
        h: 549,
      },
    ],
    johnRay: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 271,
        h: 428,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1090,
        w: 590,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1176,
        y: 1651,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 873,
        y: 561,
        w: 535,
        h: 501,
      },
      {
        name: 'patientHeadBack',
        x: 575,
        y: 1651,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadFront',
        x: 1474,
        y: 1122,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 600,
        h: 528,
      },
      {
        name: 'patientLeftArmPit',
        x: 1474,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 959,
        y: 1090,
        w: 369,
        h: 515,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 600,
        h: 528,
      },
      {
        name: 'patientRightArmPit',
        x: 1474,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 959,
        y: 1090,
        w: 369,
        h: 515,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1651,
        w: 574,
        h: 552,
      },
      {
        name: 'patientTorso',
        x: 1734,
        y: 1651,
        w: 327,
        h: 487,
      },
      {
        name: 'patientTorsoBack',
        x: 1474,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 873,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 591,
        y: 1090,
        w: 367,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 591,
        y: 1090,
        w: 367,
        h: 560,
      },
    ],
    sarahYang: [
      {
        name: 'patientBack',
        x: 1125,
        y: 1079,
        w: 239,
        h: 477,
      },
      {
        name: 'patientBody',
        x: 1400,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1400,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 1400,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 799,
        y: 519,
        w: 600,
        h: 525,
      },
      {
        name: 'patientHeadFront',
        x: 799,
        y: 0,
        w: 600,
        h: 518,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 411,
        h: 517,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 412,
        y: 533,
        w: 376,
        h: 530,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 411,
        h: 517,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 412,
        y: 533,
        w: 376,
        h: 530,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1079,
        w: 523,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 412,
        y: 0,
        w: 386,
        h: 532,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1079,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 518,
        w: 392,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 518,
        w: 392,
        h: 560,
      },
    ],
    adhaHunt: [
      {
        name: 'patientBack',
        x: 1140,
        y: 1122,
        w: 214,
        h: 416,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1680,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1680,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 582,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1441,
        y: 1122,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadFront',
        x: 1441,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 951,
        y: 0,
        w: 489,
        h: 542,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 561,
        w: 575,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 349,
        h: 482,
      },
      {
        name: 'patientHeadRight',
        x: 951,
        y: 0,
        w: 489,
        h: 542,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 561,
        w: 575,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 349,
        h: 482,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 483,
        w: 319,
        h: 370,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1680,
        w: 572,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1441,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 951,
        y: 543,
        w: 410,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 951,
        y: 543,
        w: 410,
        h: 560,
      },
    ],
    edwinJohnson: [
      {
        name: 'patientHands',
        x: 549,
        y: 1122,
        w: 543,
        h: 549,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 561,
        w: 545,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 561,
        w: 545,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 548,
        h: 559,
      },
      {
        name: 'patientHead',
        x: 1727,
        y: 1426,
        w: 86,
        h: 126,
      },
      {
        name: 'patientTorso',
        x: 1719,
        y: 1015,
        w: 330,
        h: 374,
      },
      {
        name: 'patientBody',
        x: 1500,
        y: 1426,
        w: 226,
        h: 193,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 0,
        w: 585,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1500,
        y: 0,
        w: 559,
        h: 514,
      },
      {
        name: 'patientHeadBack',
        x: 1500,
        y: 515,
        w: 549,
        h: 499,
      },
      {
        name: 'patientTorsoLeft',
        x: 1187,
        y: 0,
        w: 312,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1093,
        y: 1122,
        w: 350,
        h: 537,
      },
      {
        name: 'patientTorsoRight',
        x: 1187,
        y: 0,
        w: 312,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1093,
        y: 1122,
        w: 350,
        h: 537,
      },
      {
        name: 'patientBack',
        x: 1500,
        y: 1015,
        w: 218,
        h: 410,
      },
      {
        name: 'patientLeftSide',
        x: 1187,
        y: 561,
        w: 241,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 1187,
        y: 561,
        w: 241,
        h: 485,
      },
    ],
    rebeccaRamirez: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 997,
        y: 561,
        w: 569,
        h: 488,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 542,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 997,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1598,
        y: 1054,
        w: 547,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1598,
        y: 0,
        w: 559,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 395,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 395,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 396,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 543,
        y: 561,
        w: 191,
        h: 415,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 1122,
        w: 395,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1598,
        y: 561,
        w: 222,
        h: 492,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 1122,
        w: 395,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1598,
        y: 561,
        w: 222,
        h: 492,
      },
      {
        name: 'patientHead',
        x: 735,
        y: 561,
        w: 165,
        h: 151,
      },
      {
        name: 'patientTorso',
        x: 1821,
        y: 700,
        w: 314,
        h: 300,
      },
      {
        name: 'patientBody',
        x: 735,
        y: 713,
        w: 235,
        h: 175,
      },
    ],
    shadiMaha: [
      {
        name: 'patientBody',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1409,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientLeftArmPit',
        x: 1409,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1409,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1041,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1041,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1172,
        y: 1120,
        w: 216,
        h: 426,
      },
      {
        name: 'patientDown',
        x: 1409,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1120,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 558,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1681,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 0,
        w: 439,
        h: 531,
      },
      {
        name: 'patientLeftSide',
        x: 1041,
        y: 561,
        w: 362,
        h: 531,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 0,
        w: 439,
        h: 531,
      },
      {
        name: 'patientRightSide',
        x: 1041,
        y: 561,
        w: 362,
        h: 531,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1120,
        w: 570,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 561,
        w: 387,
        h: 478,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1681,
        w: 595,
        h: 560,
      },
    ],
    sueChenju: [
      {
        name: 'patientBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1803,
        y: 558,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1803,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1058,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1058,
        w: 435,
        h: 560,
      },
    ],
    stevenLampard: [
      {
        name: 'patientBack',
        x: 596,
        y: 547,
        w: 246,
        h: 424,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1669,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1202,
        y: 1669,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 886,
        y: 561,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 886,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1487,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1487,
        y: 1113,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 509,
        h: 546,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1108,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 510,
        y: 0,
        w: 375,
        h: 521,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 509,
        h: 546,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1108,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 510,
        y: 0,
        w: 375,
        h: 521,
      },
      {
        name: 'patientFeet',
        x: 1487,
        y: 561,
        w: 584,
        h: 551,
      },
      {
        name: 'patientTorso',
        x: 997,
        y: 1108,
        w: 414,
        h: 471,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 547,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1669,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1108,
        w: 395,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1108,
        w: 395,
        h: 560,
      },
    ],
    alexandraBrandon: [
      {
        name: 'patientBack',
        x: 1733,
        y: 1660,
        w: 241,
        h: 465,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1660,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1660,
        w: 582,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1184,
        y: 1660,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1099,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1099,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1382,
        y: 0,
        w: 600,
        h: 522,
      },
      {
        name: 'patientHeadLeft',
        x: 475,
        y: 561,
        w: 495,
        h: 531,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 376,
        h: 525,
      },
      {
        name: 'patientHeadRight',
        x: 475,
        y: 561,
        w: 495,
        h: 531,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 376,
        h: 525,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 474,
        h: 537,
      },
      {
        name: 'patientTorso',
        x: 978,
        y: 561,
        w: 356,
        h: 514,
      },
      {
        name: 'patientTorsoBack',
        x: 1382,
        y: 1084,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1382,
        y: 523,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 978,
        y: 0,
        w: 403,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 978,
        y: 0,
        w: 403,
        h: 560,
      },
    ],
    aliAhmad: [
      {
        name: 'patientBack',
        x: 579,
        y: 530,
        w: 278,
        h: 429,
      },
      {
        name: 'patientBody',
        x: 1467,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 441,
        y: 1089,
        w: 590,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1467,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1650,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 866,
        y: 545,
        w: 600,
        h: 522,
      },
      {
        name: 'patientHeadFront',
        x: 866,
        y: 0,
        w: 600,
        h: 544,
      },
      {
        name: 'patientHeadLeft',
        x: 416,
        y: 0,
        w: 449,
        h: 468,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1650,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1032,
        y: 1089,
        w: 370,
        h: 518,
      },
      {
        name: 'patientHeadRight',
        x: 416,
        y: 0,
        w: 449,
        h: 468,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1650,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1032,
        y: 1089,
        w: 370,
        h: 518,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 530,
        w: 578,
        h: 558,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 0,
        w: 415,
        h: 529,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1650,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1467,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1089,
        w: 440,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1089,
        w: 440,
        h: 560,
      },
    ],
    selbyMartin: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1077,
        w: 272,
        h: 443,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 516,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1077,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 926,
        y: 0,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 1475,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1475,
        y: 1112,
        w: 600,
        h: 513,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 0,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 926,
        y: 556,
        w: 541,
        h: 513,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1638,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 324,
        h: 521,
      },
      {
        name: 'patientHeadRight',
        x: 926,
        y: 556,
        w: 541,
        h: 513,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1638,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 324,
        h: 521,
      },
      {
        name: 'patientFeet',
        x: 1475,
        y: 561,
        w: 580,
        h: 550,
      },
      {
        name: 'patientTorso',
        x: 1700,
        y: 1638,
        w: 374,
        h: 499,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1638,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 601,
        y: 1077,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 1638,
        w: 497,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 1638,
        w: 497,
        h: 560,
      },
    ],
    ernestStabile: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 233,
        h: 435,
      },
      {
        name: 'patientBody',
        x: 1436,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 593,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1436,
        y: 1107,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 835,
        y: 0,
        w: 600,
        h: 546,
      },
      {
        name: 'patientHeadFront',
        x: 1436,
        y: 561,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadLeft',
        x: 594,
        y: 1122,
        w: 515,
        h: 546,
      },
      {
        name: 'patientLeftArmPit',
        x: 1137,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1110,
        y: 1122,
        w: 322,
        h: 520,
      },
      {
        name: 'patientHeadRight',
        x: 594,
        y: 1122,
        w: 515,
        h: 546,
      },
      {
        name: 'patientRightArmPit',
        x: 1137,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1110,
        y: 1122,
        w: 322,
        h: 520,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1683,
        w: 549,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 375,
        y: 561,
        w: 364,
        h: 483,
      },
      {
        name: 'patientTorsoBack',
        x: 550,
        y: 1683,
        w: 586,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 835,
        y: 547,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 374,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 374,
        h: 560,
      },
    ],
    claytonTodd: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1605,
        w: 271,
        h: 428,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1488,
        y: 529,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1488,
        y: 1090,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 370,
        y: 561,
        w: 535,
        h: 501,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1077,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1077,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 1488,
        y: 0,
        w: 600,
        h: 528,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 561,
        w: 369,
        h: 515,
      },
      {
        name: 'patientHeadRight',
        x: 1488,
        y: 0,
        w: 600,
        h: 528,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 561,
        w: 369,
        h: 515,
      },
      {
        name: 'patientFeet',
        x: 969,
        y: 0,
        w: 518,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 969,
        y: 557,
        w: 327,
        h: 487,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 0,
        w: 367,
        h: 560,
      },
    ],
    maryJames: [
      {
        name: 'patientBack',
        x: 1140,
        y: 1122,
        w: 214,
        h: 416,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1680,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1680,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 582,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1441,
        y: 1122,
        w: 600,
        h: 545,
      },
      {
        name: 'patientHeadFront',
        x: 1441,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 951,
        y: 0,
        w: 489,
        h: 542,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 561,
        w: 575,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 349,
        h: 482,
      },
      {
        name: 'patientHeadRight',
        x: 951,
        y: 0,
        w: 489,
        h: 542,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 561,
        w: 575,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 349,
        h: 482,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 483,
        w: 319,
        h: 370,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1680,
        w: 572,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1441,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 951,
        y: 543,
        w: 410,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 951,
        y: 543,
        w: 410,
        h: 560,
      },
    ],
    jackRobinson: [
      {
        name: 'patientBack',
        x: 1185,
        y: 1122,
        w: 256,
        h: 447,
      },
      {
        name: 'patientBody',
        x: 594,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1640,
        w: 593,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 960,
        y: 0,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 1195,
        y: 1640,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1517,
        y: 1122,
        w: 600,
        h: 512,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 517,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 583,
        h: 513,
      },
      {
        name: 'patientLeftArmPit',
        x: 1517,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 587,
        y: 561,
        w: 338,
        h: 514,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 583,
        h: 513,
      },
      {
        name: 'patientRightArmPit',
        x: 1517,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 587,
        y: 561,
        w: 338,
        h: 514,
      },
      {
        name: 'patientFeet',
        x: 375,
        y: 0,
        w: 584,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 960,
        y: 510,
        w: 392,
        h: 519,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 586,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1517,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 374,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 374,
        h: 560,
      },
    ],
    ericThompson: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1605,
        w: 271,
        h: 428,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1483,
        y: 1090,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 370,
        y: 561,
        w: 535,
        h: 501,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1077,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1077,
        w: 600,
        h: 527,
      },
      {
        name: 'patientHeadLeft',
        x: 1483,
        y: 561,
        w: 600,
        h: 528,
      },
      {
        name: 'patientLeftArmPit',
        x: 1483,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 561,
        w: 369,
        h: 515,
      },
      {
        name: 'patientHeadRight',
        x: 1483,
        y: 561,
        w: 600,
        h: 528,
      },
      {
        name: 'patientRightArmPit',
        x: 1483,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 561,
        w: 369,
        h: 515,
      },
      {
        name: 'patientFeet',
        x: 596,
        y: 0,
        w: 518,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 1115,
        y: 561,
        w: 327,
        h: 487,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1605,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1115,
        y: 0,
        w: 367,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1115,
        y: 0,
        w: 367,
        h: 560,
      },
    ],
    davidJones: [
      {
        name: 'patientBack',
        x: 857,
        y: 1683,
        w: 248,
        h: 431,
      },
      {
        name: 'patientBody',
        x: 1447,
        y: 1683,
        w: 224,
        h: 170,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1447,
        y: 1854,
        w: 86,
        h: 124,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1683,
        w: 600,
        h: 550,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1122,
        w: 444,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1683,
        w: 255,
        h: 500,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1122,
        w: 444,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1683,
        w: 255,
        h: 500,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1106,
        y: 1683,
        w: 340,
        h: 352,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
    ],
    anthonyBaker: [
      {
        name: 'patientHead',
        x: 870,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 539,
        h: 520,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1643,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 540,
        y: 561,
        w: 317,
        h: 514,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 539,
        h: 520,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1643,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 540,
        y: 561,
        w: 317,
        h: 514,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1643,
        w: 583,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 490,
        y: 0,
        w: 379,
        h: 502,
      },
      {
        name: 'patientTorsoBack',
        x: 581,
        y: 1082,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1471,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 489,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 489,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1182,
        y: 1082,
        w: 258,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 1471,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1082,
        w: 580,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1185,
        y: 1643,
        w: 600,
        h: 526,
      },
      {
        name: 'patientHeadFront',
        x: 870,
        y: 561,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHands',
        x: 1471,
        y: 1122,
        w: 556,
        h: 509,
      },
    ],
    alexJohnson: [
      {
        name: 'patientBack',
        x: 894,
        y: 531,
        w: 245,
        h: 413,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1423,
        w: 216,
        h: 171,
      },
      {
        name: 'patientDown',
        x: 1159,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 0,
        w: 557,
        h: 530,
      },
      {
        name: 'patientHead',
        x: 818,
        y: 1423,
        w: 83,
        h: 123,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 600,
        h: 543,
      },
      {
        name: 'patientHeadFront',
        x: 1159,
        y: 1122,
        w: 600,
        h: 539,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1666,
        w: 471,
        h: 554,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1666,
        w: 471,
        h: 554,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 544,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 544,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 531,
        w: 292,
        h: 507,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 531,
        w: 292,
        h: 507,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1666,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 1105,
        w: 336,
        h: 317,
      },
      {
        name: 'patientTorsoBack',
        x: 1159,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1105,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1666,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1666,
        w: 600,
        h: 560,
      },
    ],
    adamXu: [
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1664,
        w: 432,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1664,
        w: 432,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1103,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1664,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1591,
        y: 1664,
        w: 409,
        h: 442,
      },
      {
        name: 'patientFeet',
        x: 901,
        y: 558,
        w: 584,
        h: 477,
      },
      {
        name: 'patientLeftSide',
        x: 592,
        y: 0,
        w: 308,
        h: 500,
      },
      {
        name: 'patientLeftArmPit',
        x: 1502,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 591,
        h: 541,
      },
      {
        name: 'patientRightSide',
        x: 592,
        y: 0,
        w: 308,
        h: 500,
      },
      {
        name: 'patientRightArmPit',
        x: 1502,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 591,
        h: 541,
      },
      {
        name: 'patientHeadFront',
        x: 1502,
        y: 1122,
        w: 600,
        h: 541,
      },
      {
        name: 'patientHeadBack',
        x: 901,
        y: 0,
        w: 600,
        h: 557,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 542,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1034,
        y: 1664,
        w: 556,
        h: 509,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1103,
        w: 594,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1502,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1196,
        y: 1103,
        w: 298,
        h: 430,
      },
    ],
    adamBeach: [
      {
        name: 'patientHands',
        x: 992,
        y: 1122,
        w: 562,
        h: 550,
      },
      {
        name: 'patientBack',
        x: 1846,
        y: 561,
        w: 251,
        h: 412,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 860,
        y: 324,
        w: 88,
        h: 117,
      },
      {
        name: 'patientHeadBack',
        x: 972,
        y: 0,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadFront',
        x: 972,
        y: 552,
        w: 600,
        h: 544,
      },
      {
        name: 'patientHeadLeft',
        x: 565,
        y: 1122,
        w: 426,
        h: 558,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 564,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1573,
        y: 561,
        w: 272,
        h: 496,
      },
      {
        name: 'patientHeadRight',
        x: 565,
        y: 1122,
        w: 426,
        h: 558,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 564,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1573,
        y: 561,
        w: 272,
        h: 496,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1573,
        y: 1058,
        w: 598,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1573,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 495,
        w: 325,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 495,
        w: 325,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 324,
        w: 258,
        h: 170,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 370,
        h: 323,
      },
    ],
    aliNazar: [
      {
        name: 'patientBack',
        x: 1180,
        y: 1094,
        w: 287,
        h: 442,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1655,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1494,
        y: 555,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 893,
        y: 561,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1094,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1494,
        y: 1116,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1494,
        y: 0,
        w: 600,
        h: 554,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 521,
        h: 532,
      },
      {
        name: 'patientLeftArmPit',
        x: 893,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 522,
        y: 0,
        w: 370,
        h: 520,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 521,
        h: 532,
      },
      {
        name: 'patientRightArmPit',
        x: 893,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 522,
        y: 0,
        w: 370,
        h: 520,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1094,
        w: 578,
        h: 558,
      },
      {
        name: 'patientTorso',
        x: 441,
        y: 533,
        w: 389,
        h: 459,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1655,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1655,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 533,
        w: 440,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 533,
        w: 440,
        h: 560,
      },
    ],
    ronChan: [
      {
        name: 'patientLeftArmPit',
        x: 1174,
        y: 561,
        w: 585,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1174,
        y: 561,
        w: 585,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 0,
        w: 572,
        h: 527,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1683,
        w: 596,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1174,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1174,
        y: 1122,
        w: 600,
        h: 547,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 426,
        h: 558,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 426,
        h: 558,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 601,
        y: 528,
        w: 299,
        h: 426,
      },
      {
        name: 'patientTorsoLeft',
        x: 597,
        y: 1683,
        w: 494,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1092,
        y: 1683,
        w: 318,
        h: 486,
      },
      {
        name: 'patientTorsoRight',
        x: 597,
        y: 1683,
        w: 494,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1092,
        y: 1683,
        w: 318,
        h: 486,
      },
      {
        name: 'patientTorso',
        x: 1411,
        y: 1683,
        w: 348,
        h: 418,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 955,
        w: 248,
        h: 166,
      },
      {
        name: 'patientHead',
        x: 1028,
        y: 1122,
        w: 102,
        h: 118,
      },
    ],
    adelineYenMah: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1122,
        w: 228,
        h: 447,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 860,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1461,
        y: 561,
        w: 548,
        h: 556,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 537,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1683,
        w: 600,
        h: 530,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 1683,
        w: 460,
        h: 530,
      },
      {
        name: 'patientLeftArmPit',
        x: 1461,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1663,
        y: 1683,
        w: 375,
        h: 521,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 1683,
        w: 460,
        h: 530,
      },
      {
        name: 'patientRightArmPit',
        x: 1461,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1663,
        y: 1683,
        w: 375,
        h: 521,
      },
      {
        name: 'patientFeet',
        x: 1461,
        y: 1118,
        w: 515,
        h: 559,
      },
      {
        name: 'patientTorso',
        x: 488,
        y: 0,
        w: 371,
        h: 517,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 860,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 487,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 487,
        h: 560,
      },
    ],
    alexJones: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    amyReed: [
      {
        name: 'patientHead',
        x: 542,
        y: 0,
        w: 97,
        h: 157,
      },
      {
        name: 'patientTorso',
        x: 884,
        y: 561,
        w: 328,
        h: 339,
      },
      {
        name: 'patientBody',
        x: 436,
        y: 554,
        w: 183,
        h: 193,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 554,
        w: 435,
        h: 494,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 554,
        w: 435,
        h: 494,
      },
      {
        name: 'patientTorsoLeft',
        x: 884,
        y: 0,
        w: 329,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 884,
        y: 0,
        w: 329,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 640,
        y: 0,
        w: 243,
        h: 407,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1049,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1610,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1151,
        y: 1610,
        w: 564,
        h: 552,
      },
      {
        name: 'patientHeadRight',
        x: 1151,
        y: 1610,
        w: 564,
        h: 552,
      },
      {
        name: 'patientHeadFront',
        x: 1214,
        y: 500,
        w: 557,
        h: 527,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 541,
        h: 553,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1049,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 1214,
        y: 1028,
        w: 539,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1214,
        y: 0,
        w: 557,
        h: 499,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1610,
        w: 549,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1610,
        w: 549,
        h: 560,
      },
    ],
    alexSanders: [
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1100,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1755,
        y: 1122,
        w: 253,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 1755,
        y: 1122,
        w: 253,
        h: 485,
      },
      {
        name: 'patientBack',
        x: 1142,
        y: 1100,
        w: 242,
        h: 412,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 539,
        w: 303,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 539,
        w: 303,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1417,
        y: 1122,
        w: 337,
        h: 521,
      },
      {
        name: 'patientHeadRight',
        x: 1417,
        y: 1122,
        w: 337,
        h: 521,
      },
      {
        name: 'patientTorsoBack',
        x: 1417,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1100,
        w: 540,
        h: 559,
      },
      {
        name: 'patientDown',
        x: 1417,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 326,
        y: 0,
        w: 521,
        h: 499,
      },
      {
        name: 'patientHeadFront',
        x: 848,
        y: 0,
        w: 568,
        h: 478,
      },
      {
        name: 'patientHead',
        x: 216,
        y: 345,
        w: 84,
        h: 122,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 0,
        w: 325,
        h: 344,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 345,
        w: 215,
        h: 193,
      },
      {
        name: 'patientLeftArmPit',
        x: 848,
        y: 479,
        w: 529,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 848,
        y: 479,
        w: 529,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 304,
        y: 539,
        w: 543,
        h: 549,
      },
    ],
    stevenFoster: [
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1422,
        y: 2029,
        w: 84,
        h: 120,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1683,
        w: 332,
        h: 345,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 2029,
        w: 219,
        h: 182,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 559,
        w: 278,
        h: 497,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 559,
        w: 278,
        h: 497,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 435,
        h: 551,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 435,
        h: 551,
      },
      {
        name: 'patientBack',
        x: 880,
        y: 559,
        w: 249,
        h: 441,
      },
      {
        name: 'patientHeadBack',
        x: 1131,
        y: 1107,
        w: 600,
        h: 558,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 529,
        h: 558,
      },
      {
        name: 'patientLeftArmPit',
        x: 1131,
        y: 546,
        w: 576,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1131,
        y: 546,
        w: 576,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1131,
        y: 0,
        w: 600,
        h: 545,
      },
    ],
    johnWalker: [
      {
        name: 'patientBack',
        x: 1781,
        y: 1636,
        w: 288,
        h: 440,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1636,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1473,
        y: 1077,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1075,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 561,
        w: 600,
        h: 513,
      },
      {
        name: 'patientHeadFront',
        x: 1473,
        y: 561,
        w: 600,
        h: 515,
      },
      {
        name: 'patientHeadLeft',
        x: 986,
        y: 0,
        w: 486,
        h: 513,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 1075,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 561,
        w: 361,
        h: 513,
      },
      {
        name: 'patientHeadRight',
        x: 986,
        y: 0,
        w: 486,
        h: 513,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 1075,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 561,
        w: 361,
        h: 513,
      },
      {
        name: 'patientFeet',
        x: 1202,
        y: 1636,
        w: 578,
        h: 558,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 0,
        w: 384,
        h: 475,
      },
      {
        name: 'patientTorsoBack',
        x: 1473,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 986,
        y: 514,
        w: 477,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 986,
        y: 514,
        w: 477,
        h: 560,
      },
    ],
    aliceRoberts: [
      {
        name: 'patientBack',
        x: 587,
        y: 533,
        w: 241,
        h: 465,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 533,
        w: 586,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 511,
        y: 1094,
        w: 548,
        h: 555,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 852,
        y: 0,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1453,
        y: 1122,
        w: 600,
        h: 518,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 494,
        h: 532,
      },
      {
        name: 'patientLeftArmPit',
        x: 1453,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1060,
        y: 1094,
        w: 381,
        h: 506,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 494,
        h: 532,
      },
      {
        name: 'patientRightArmPit',
        x: 1453,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1060,
        y: 1094,
        w: 381,
        h: 506,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1094,
        w: 510,
        h: 556,
      },
      {
        name: 'patientTorso',
        x: 495,
        y: 0,
        w: 356,
        h: 514,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1651,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1453,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 852,
        y: 521,
        w: 403,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 852,
        y: 521,
        w: 403,
        h: 560,
      },
    ],
    richardMcklain: [
      {
        name: 'patientHands',
        x: 966,
        y: 1122,
        w: 363,
        h: 548,
      },
      {
        name: 'patientLeftSide',
        x: 1104,
        y: 0,
        w: 244,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 1104,
        y: 0,
        w: 244,
        h: 485,
      },
      {
        name: 'patientBack',
        x: 1104,
        y: 486,
        w: 219,
        h: 421,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 364,
        h: 559,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 377,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 364,
        h: 559,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 377,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1349,
        y: 0,
        w: 548,
        h: 559,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 598,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1349,
        y: 560,
        w: 495,
        h: 559,
      },
      {
        name: 'patientTorsoBack',
        x: 378,
        y: 561,
        w: 525,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 599,
        y: 0,
        w: 504,
        h: 557,
      },
      {
        name: 'patientLeftArmPit',
        x: 1349,
        y: 1120,
        w: 543,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1349,
        y: 1120,
        w: 543,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1104,
        y: 908,
        w: 222,
        h: 199,
      },
      {
        name: 'patientTorso',
        x: 904,
        y: 703,
        w: 194,
        h: 338,
      },
      {
        name: 'patientHead',
        x: 904,
        y: 561,
        w: 118,
        h: 141,
      },
    ],
    amyRichardson: [
      {
        name: 'patientBack',
        x: 601,
        y: 530,
        w: 202,
        h: 412,
      },
      {
        name: 'patientBody',
        x: 598,
        y: 1652,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1652,
        w: 597,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 871,
        y: 561,
        w: 556,
        h: 509,
      },
      {
        name: 'patientHead',
        x: 871,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1472,
        y: 561,
        w: 574,
        h: 546,
      },
      {
        name: 'patientHeadFront',
        x: 1472,
        y: 1108,
        w: 600,
        h: 532,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 0,
        w: 494,
        h: 529,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 530,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1116,
        y: 1091,
        w: 351,
        h: 490,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 0,
        w: 494,
        h: 529,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 530,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1116,
        y: 1091,
        w: 351,
        h: 490,
      },
      {
        name: 'patientFeet',
        x: 540,
        y: 1091,
        w: 575,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 495,
        y: 0,
        w: 375,
        h: 499,
      },
      {
        name: 'patientTorsoBack',
        x: 1472,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1199,
        y: 1652,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1091,
        w: 539,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1091,
        w: 539,
        h: 560,
      },
    ],
    anaBoyt: [
      {
        name: 'patientBack',
        x: 1117,
        y: 1122,
        w: 222,
        h: 442,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 1660,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 1660,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 559,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 1660,
        w: 600,
        h: 535,
      },
      {
        name: 'patientHeadFront',
        x: 1417,
        y: 1122,
        w: 600,
        h: 535,
      },
      {
        name: 'patientHeadLeft',
        x: 972,
        y: 0,
        w: 444,
        h: 545,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 370,
        h: 516,
      },
      {
        name: 'patientHeadRight',
        x: 972,
        y: 0,
        w: 444,
        h: 545,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 370,
        h: 516,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 558,
        h: 537,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 517,
        w: 364,
        h: 482,
      },
      {
        name: 'patientTorsoBack',
        x: 1417,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1417,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 972,
        y: 546,
        w: 444,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 972,
        y: 546,
        w: 444,
        h: 560,
      },
    ],
    litaDamokosh: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    melissaMoore: [
      {
        name: 'patientBack',
        x: 945,
        y: 1122,
        w: 211,
        h: 406,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 561,
        w: 236,
        h: 446,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1153,
        y: 1680,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 869,
        y: 0,
        w: 301,
        h: 405,
      },
      {
        name: 'patientHeadBack',
        x: 1171,
        y: 1122,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadFront',
        x: 557,
        y: 1680,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 582,
        y: 1122,
        w: 362,
        h: 544,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1680,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 596,
        y: 0,
        w: 272,
        h: 445,
      },
      {
        name: 'patientHeadRight',
        x: 582,
        y: 1122,
        w: 362,
        h: 544,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1680,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 596,
        y: 0,
        w: 272,
        h: 445,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 869,
        y: 406,
        w: 301,
        h: 305,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1171,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1171,
        y: 561,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1171,
        y: 561,
        w: 435,
        h: 560,
      },
    ],
    jessicaWood: [
      {
        name: 'patientBack',
        x: 964,
        y: 558,
        w: 211,
        h: 406,
      },
      {
        name: 'patientBody',
        x: 1183,
        y: 1113,
        w: 236,
        h: 446,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 1122,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 558,
        y: 1122,
        w: 301,
        h: 405,
      },
      {
        name: 'patientHeadBack',
        x: 1183,
        y: 0,
        w: 600,
        h: 551,
      },
      {
        name: 'patientHeadFront',
        x: 436,
        y: 1622,
        w: 595,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 558,
        w: 362,
        h: 544,
      },
      {
        name: 'patientLeftArmPit',
        x: 1183,
        y: 552,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1420,
        y: 1113,
        w: 272,
        h: 445,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 558,
        w: 362,
        h: 544,
      },
      {
        name: 'patientRightArmPit',
        x: 1183,
        y: 552,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1420,
        y: 1113,
        w: 272,
        h: 445,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorso',
        x: 860,
        y: 1122,
        w: 301,
        h: 305,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 0,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1032,
        y: 1622,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1622,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1622,
        w: 435,
        h: 560,
      },
    ],
    evaHollander: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1122,
        w: 212,
        h: 412,
      },
      {
        name: 'patientBody',
        x: 1575,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1575,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 993,
        y: 558,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1575,
        y: 561,
        w: 599,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 561,
        w: 389,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 561,
        w: 389,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 556,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1683,
        w: 595,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 993,
        y: 0,
        w: 581,
        h: 557,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 557,
        y: 0,
        w: 435,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 557,
        y: 0,
        w: 435,
        h: 560,
      },
    ],
    malieIokina: [
      {
        name: 'patientBack',
        x: 1202,
        y: 1122,
        w: 205,
        h: 388,
      },
      {
        name: 'patientBody',
        x: 1210,
        y: 1682,
        w: 287,
        h: 415,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 1682,
        w: 548,
        h: 541,
      },
      {
        name: 'patientHead',
        x: 1498,
        y: 1682,
        w: 214,
        h: 397,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 600,
        h: 549,
      },
      {
        name: 'patientHeadFront',
        x: 587,
        y: 1122,
        w: 583,
        h: 559,
      },
      {
        name: 'patientHeadLeft',
        x: 549,
        y: 1682,
        w: 386,
        h: 541,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 0,
        w: 547,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 936,
        y: 1682,
        w: 273,
        h: 445,
      },
      {
        name: 'patientHeadRight',
        x: 549,
        y: 1682,
        w: 386,
        h: 541,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 0,
        w: 547,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 936,
        y: 1682,
        w: 273,
        h: 445,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 586,
        h: 559,
      },
      {
        name: 'patientTorso',
        x: 1408,
        y: 1122,
        w: 292,
        h: 297,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 550,
        w: 595,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 561,
        w: 445,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 561,
        w: 445,
        h: 560,
      },
    ],
    akiyamaKyoumi: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    badruNajat: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 1803,
        y: 1122,
        w: 557,
        h: 499,
      },
    ],
    hadiDerkhshani: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    maryWashington: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    jamesAnderson: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    gracielaBeinvenida: [
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    kimHoffman: [
      {
        name: 'patientBack',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
    johnChan: [
      {
        name: 'patientBack',
        x: 601,
        y: 561,
        w: 155,
        h: 208,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 1007,
        w: 133,
        h: 100,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 862,
        y: 0,
        w: 386,
        h: 480,
      },
      {
        name: 'patientHands',
        x: 496,
        y: 0,
        w: 365,
        h: 549,
      },
      {
        name: 'patientHead',
        x: 735,
        y: 1007,
        w: 69,
        h: 81,
      },
      {
        name: 'patientHeadBack',
        x: 1249,
        y: 561,
        w: 547,
        h: 548,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 862,
        y: 481,
        w: 370,
        h: 558,
      },
      {
        name: 'patientHeadRight',
        x: 862,
        y: 481,
        w: 370,
        h: 558,
      },
      {
        name: 'patientLeftArmPit',
        x: 1249,
        y: 1110,
        w: 564,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1249,
        y: 1110,
        w: 564,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 770,
        w: 114,
        h: 236,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 770,
        w: 114,
        h: 236,
      },
      {
        name: 'patientTorso',
        x: 716,
        y: 770,
        w: 144,
        h: 99,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1249,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 495,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 495,
        h: 560,
      },
    ],
    roseannLever: [
      {
        name: 'patientBack',
        x: 1803,
        y: 1641,
        w: 275,
        h: 438,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 596,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 984,
        y: 0,
        w: 557,
        h: 499,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 1080,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1542,
        y: 1080,
        w: 600,
        h: 520,
      },
      {
        name: 'patientHeadFront',
        x: 1542,
        y: 0,
        w: 600,
        h: 518,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 600,
        h: 518,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1080,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 561,
        w: 379,
        h: 512,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 600,
        h: 518,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1080,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 561,
        w: 379,
        h: 512,
      },
      {
        name: 'patientFeet',
        x: 984,
        y: 500,
        w: 555,
        h: 538,
      },
      {
        name: 'patientTorso',
        x: 597,
        y: 0,
        w: 386,
        h: 512,
      },
      {
        name: 'patientTorsoBack',
        x: 601,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1641,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1542,
        y: 519,
        w: 580,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1542,
        y: 519,
        w: 580,
        h: 560,
      },
    ],
    pacorroArrellanes: [
      {
        name: 'patientFeet',
        x: 1590,
        y: 561,
        w: 600,
        h: 557,
      },
      {
        name: 'patientLeftArmPit',
        x: 1590,
        y: 1119,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1590,
        y: 1119,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 573,
        y: 0,
        w: 415,
        h: 553,
      },
      {
        name: 'patientHeadRight',
        x: 573,
        y: 0,
        w: 415,
        h: 553,
      },
      {
        name: 'patientHeadFront',
        x: 310,
        y: 646,
        w: 583,
        h: 485,
      },
      {
        name: 'patientHeadBack',
        x: 0,
        y: 0,
        w: 572,
        h: 457,
      },
      {
        name: 'patientTorsoFront',
        x: 1590,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 1132,
        w: 433,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 1132,
        w: 433,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 989,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 989,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 0,
        y: 646,
        w: 309,
        h: 485,
      },
      {
        name: 'patientRightSide',
        x: 0,
        y: 646,
        w: 309,
        h: 485,
      },
      {
        name: 'patientBack',
        x: 978,
        y: 1132,
        w: 265,
        h: 404,
      },
      {
        name: 'patientBody',
        x: 0,
        y: 458,
        w: 242,
        h: 187,
      },
      {
        name: 'patientTorso',
        x: 1244,
        y: 1132,
        w: 339,
        h: 382,
      },
      {
        name: 'patientHead',
        x: 978,
        y: 1537,
        w: 114,
        h: 129,
      },
      {
        name: 'patientHands',
        x: 434,
        y: 1132,
        w: 543,
        h: 549,
      },
    ],
    joeFernandes: [
      {
        name: 'patientBack',
        x: 1202,
        y: 415,
        w: 285,
        h: 407,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 844,
        w: 301,
        h: 199,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 1122,
        w: 540,
        h: 528,
      },
      {
        name: 'patientHead',
        x: 903,
        y: 844,
        w: 109,
        h: 127,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 0,
        w: 600,
        h: 444,
      },
      {
        name: 'patientHeadFront',
        x: 601,
        y: 445,
        w: 572,
        h: 398,
      },
      {
        name: 'patientHeadLeft',
        x: 1202,
        y: 0,
        w: 367,
        h: 414,
      },
      {
        name: 'patientHeadRight',
        x: 1202,
        y: 0,
        w: 367,
        h: 414,
      },
      {
        name: 'patientLeftArmPit',
        x: 1570,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1570,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1202,
        y: 823,
        w: 263,
        h: 481,
      },
      {
        name: 'patientRightSide',
        x: 1202,
        y: 823,
        w: 263,
        h: 481,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 1305,
        w: 365,
        h: 374,
      },
      {
        name: 'patientTorsoBack',
        x: 1570,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 1570,
        y: 561,
        w: 438,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 1570,
        y: 561,
        w: 438,
        h: 560,
      },
    ],
    jenniferLawson: [
      {
        name: 'patientBack',
        x: 601,
        y: 0,
        w: 159,
        h: 212,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 301,
        w: 139,
        h: 114,
      },
      {
        name: 'patientDown',
        x: 1238,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 761,
        y: 561,
        w: 419,
        h: 496,
      },
      {
        name: 'patientHands',
        x: 380,
        y: 561,
        w: 365,
        h: 552,
      },
      {
        name: 'patientHead',
        x: 601,
        y: 213,
        w: 79,
        h: 87,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 508,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1238,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 0,
        y: 561,
        w: 379,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 0,
        y: 561,
        w: 379,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 1238,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1238,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1110,
        y: 1122,
        w: 122,
        h: 233,
      },
      {
        name: 'patientRightSide',
        x: 1110,
        y: 1122,
        w: 122,
        h: 233,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 416,
        w: 154,
        h: 107,
      },
      {
        name: 'patientTorsoBack',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 761,
        y: 0,
        w: 476,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 761,
        y: 0,
        w: 476,
        h: 560,
      },
    ],
    michaelJefferson: [
      {
        name: 'patientBack',
        x: 486,
        y: 762,
        w: 154,
        h: 207,
      },
      {
        name: 'patientBody',
        x: 486,
        y: 650,
        w: 141,
        h: 111,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 729,
        y: 561,
        w: 522,
        h: 496,
      },
      {
        name: 'patientHead',
        x: 486,
        y: 561,
        w: 81,
        h: 88,
      },
      {
        name: 'patientLeftArmPit',
        x: 0,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 0,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1291,
        y: 560,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 924,
        y: 1122,
        w: 359,
        h: 549,
      },
      {
        name: 'patientHeadFront',
        x: 1291,
        y: 0,
        w: 600,
        h: 559,
      },
      {
        name: 'patientLeftSide',
        x: 601,
        y: 0,
        w: 127,
        h: 244,
      },
      {
        name: 'patientRightSide',
        x: 601,
        y: 0,
        w: 127,
        h: 244,
      },
      {
        name: 'patientHeadLeft',
        x: 571,
        y: 1122,
        w: 352,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 571,
        y: 1122,
        w: 352,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 486,
        y: 970,
        w: 153,
        h: 105,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 561,
        w: 485,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 561,
        w: 485,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 1291,
        y: 1121,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 729,
        y: 0,
        w: 561,
        h: 560,
      },
    ],
    lawrenceClark: [
      {
        name: 'patientBack',
        x: 601,
        y: 561,
        w: 153,
        h: 211,
      },
      {
        name: 'patientBody',
        x: 601,
        y: 880,
        w: 143,
        h: 111,
      },
      {
        name: 'patientDown',
        x: 1277,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 846,
        y: 0,
        w: 430,
        h: 488,
      },
      {
        name: 'patientHands',
        x: 475,
        y: 0,
        w: 370,
        h: 550,
      },
      {
        name: 'patientHead',
        x: 1136,
        y: 1122,
        w: 79,
        h: 91,
      },
      {
        name: 'patientHeadBack',
        x: 601,
        y: 1122,
        w: 534,
        h: 535,
      },
      {
        name: 'patientHeadFront',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 846,
        y: 489,
        w: 388,
        h: 559,
      },
      {
        name: 'patientHeadRight',
        x: 846,
        y: 489,
        w: 388,
        h: 559,
      },
      {
        name: 'patientLeftArmPit',
        x: 1277,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 1277,
        y: 1122,
        w: 570,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1136,
        y: 1214,
        w: 126,
        h: 240,
      },
      {
        name: 'patientRightSide',
        x: 1136,
        y: 1214,
        w: 126,
        h: 240,
      },
      {
        name: 'patientTorso',
        x: 601,
        y: 773,
        w: 156,
        h: 106,
      },
      {
        name: 'patientTorsoBack',
        x: 1277,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 0,
        y: 0,
        w: 474,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 0,
        y: 0,
        w: 474,
        h: 560,
      },
    ],
    johnGorgin: [
      {
        name: 'patientTorsoFront',
        x: 0,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientDown',
        x: 0,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHands',
        x: 601,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightArmPit',
        x: 601,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientFeet',
        x: 0,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoLeft',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoRight',
        x: 601,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHead',
        x: 1202,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorso',
        x: 1202,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBody',
        x: 1202,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientBack',
        x: 0,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadLeft',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadRight',
        x: 601,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadFront',
        x: 1202,
        y: 1683,
        w: 600,
        h: 560,
      },
      {
        name: 'patientTorsoBack',
        x: 1803,
        y: 0,
        w: 600,
        h: 560,
      },
      {
        name: 'patientHeadBack',
        x: 1803,
        y: 561,
        w: 600,
        h: 560,
      },
      {
        name: 'patientLeftSide',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
      {
        name: 'patientRightSide',
        x: 1803,
        y: 1122,
        w: 600,
        h: 560,
      },
    ],
  };

  views = {
    default: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 200,
            y: 240,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 220,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 18,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 11,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 160,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    amrSaleh: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 275,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 195,
            y: 400,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 95,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -70,
            y: 0,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 565,
            y: 50,
            scaleX: -1.5,
            scaleY: 1.5,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 70,
            y: 50,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: -60,
            y: 70,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    linaAlAndalusi: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 260,
            y: 195,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 183,
            y: 32,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 172,
            y: 405,
            scaleX: 1.5,
            scaleY: 1.5,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 330,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 330,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 270,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 250,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 250,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 240,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 240,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: -10,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    peterWhite: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 70,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 125,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: -30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 650,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 18,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnOak: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 240,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 170,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 6,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 20,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 15,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    harryKing: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 13,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 1,
            y: 26,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 40,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 350,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 25,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 5,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    amyReed: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 240,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 249,
            y: 155,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 195,
            y: 447,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 125,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 200,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 6,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 31,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 60,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 40,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 50,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    enricoPAccoro: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 245,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 190,
            y: 360,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 330,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 5,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 10,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jennaMartin: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 230,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 250,
            y: 116,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 205,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 540,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 210,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -20,
            y: -30,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jessicaAnderson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 64,
            y: 28,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 3,
            y: 28,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -100,
            y: -130,
            scaleX: 1.4,
            scaleY: 1.4,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: -20,
            y: 0,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    devinAtkins: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 185,
            y: 235,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 455,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 10,
            y: 0,
            scaleX: 1.1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 430,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 10,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 28,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 70,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    josephRodriguez: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 64,
            y: 28,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 36,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 3,
            y: 28,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -100,
            y: -130,
            scaleX: 1.4,
            scaleY: 1.4,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: -20,
            y: 0,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    andrewEvans: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 240,
            y: 215,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 275,
            y: 122,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 170,
            y: 380,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 540,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 105,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 140,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 185,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    lemondPolk: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 185,
            y: 215,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 275,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 158,
            y: 425,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 540,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 15,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 140,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    richardMcklain: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 260,
            y: 215,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 245,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 420,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 540,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 210,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -20,
            y: -30,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    andrewParker: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 230,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 150,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 435,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -15,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    songZhi: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 230,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 135,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1.1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 8,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 5,
            y: 8,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 145,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adamPeterson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 74,
            y: 38,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 25,
            y: 46,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 13,
            y: 38,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -15,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: -20,
            y: 0,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    tomSimpson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 48,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    royMendez: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 74,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jayGarret: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 208,
            y: 226,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 275,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 204,
            y: 407,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 16,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 430,
            y: 85,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 130,
            y: 95,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    seanCooper: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 155,
            y: 84,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 55,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 55,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    muhanadKattan: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 6,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    markWarlegan: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 30,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 15,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 85,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    daveMark: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 185,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 244,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 155,
            y: 415,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 570,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 6,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 60,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 10,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jerryCarter: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 16,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 2,
            y: 44,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -54,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 50,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    leonSheldon: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 195,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 400,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 55,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 55,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 20,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnBloom: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 195,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 45,
            y: 63,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 4,
            y: 21,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    angelDickinson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 77,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 11,
            y: 72,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -20,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    roseannLever: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 77,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 22,
            y: 72,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 12,
            y: 59,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 42,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 42,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 42,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    brandiGraham: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 198,
            y: 240,
            scaleX: 0.91,
            scaleY: 0.91,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 235,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 219,
            y: 404,
            scaleX: 0.8,
            scaleY: 0.8,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 190,
            y: 190,
            scaleX: 0.8,
            scaleY: 0.8,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 190,
            scaleX: -0.8,
            scaleY: 0.8,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 133,
            scaleX: 0.75,
            scaleY: 0.75,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 30,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    lillianGreen: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 11,
            y: 72,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    kristenMeyer: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 58,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -60,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    billClark: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 195,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 259,
            y: 111,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 165,
            y: 410,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 15,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 515,
            y: 85,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 45,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 620,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -50,
            y: -5,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 580,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    cindyDervis: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 77,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 42,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 26,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -60,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    saraRay: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 97,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 8,
            y: 36,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -70,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    charlotteHeys: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 210,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 13,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -30,
            y: -60,
            scaleX: 1.1,
            scaleY: 1.11,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    anyaSimmons: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 200,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 262,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 100,
            y: 442,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    melisentMccraw: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 250,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 140,
            y: 150,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 145,
            y: 150,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 120,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -30,
            y: -55,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    evaSpencer: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 9,
            y: 57,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 42,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -30,
            y: -38,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    emaliaDahill: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientBody',
            x: 170,
            y: 170,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientTorso',
            x: 225,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 2,
            y: 36,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 30,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 360,
            y: 75,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 115,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 525,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 5,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 560,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 40,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    danielaAlcon: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 166,
            y: 150,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 6,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 140,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -30,
            y: -46,
            scaleX: 1.1,
            scaleY: 1.11,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    dianeJames: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 14,
            y: 82,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 25,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    cattyMars: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 232,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 210,
            y: 400,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 11,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 5,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 160,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    aliciaBolts: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 238,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 210,
            y: 420,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 11,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    kenHo: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 43,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 520,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    tomBarber: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 52,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    rogerSheperd: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 150,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 55,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 45,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adamXu: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 61,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 25,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    davidMason: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 146,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 120,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jeremyRamzi: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 56,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    lesiaOldberry: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientBody',
            x: 210,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientTorso',
            x: 68,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 225,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    lillianMcKay: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 61,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 50,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 50,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    shahrzadDickinson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 198,
            y: 210,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 245,
            y: 125,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 215,
            y: 380,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 110,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 110,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 175,
            y: 130,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 50,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 20,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    meganSliva: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 41,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 30,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 550,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 35,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 40,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    nazaninPahlevan: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 12,
            y: 102,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 95,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -85,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],

    emilianoParis: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 48,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 120,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    joshuaJameson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 185,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 12,
            y: 18,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    sarahYang: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 9,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 58,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 70,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 5,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adhaHunt: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 200,
            y: 180,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 26,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 125,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 25,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    susanHarrison: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 195,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 195,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 168,
            y: 428,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    annaShermin: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 245,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 170,
            y: 420,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 20,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 20,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    isabellaJohnson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 200,
            y: 240,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 250,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 208,
            y: 440,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 145,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -50,
            y: -80,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    magganSpencer: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 246,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 190,
            y: 420,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -28,
            y: 0,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jamesMetler: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 150,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 14,
            y: 34,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 5,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 175,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    javadBanivand: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 140,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 13,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adrianColeman: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 250,
            y: 124,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 160,
            y: 415,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 155,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 40,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    steveMarshall: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    simonRobins: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 104,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 2,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 40,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 550,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    josephTsai: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 140,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 7,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 46,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    albertoDouglas: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 193,
            y: 230,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 436,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 145,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 15,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -50,
            y: -90,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 5,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    anthonyBrandon: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 230,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 275,
            y: 150,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 436,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 135,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    brandonGreen: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 259,
            y: 86,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 162,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -70,
            y: 0,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 15,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnRay: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 40,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 72,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -70,
            y: 0,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    edwinJohnson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 188,
            y: 200,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 258,
            y: 112,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 182,
            y: 400,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 20,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 60,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 130,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 25,
            y: 62,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    rebeccaRamirez: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 188,
            y: 260,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 210,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 174,
            y: 435,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -35,
            y: -110,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    shadiMaha: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 300,
            y: 70,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -20,
            y: -58,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 2,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    donnaWilliams: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 80,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 28,
            y: 78,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 20,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: -40,
            y: 0,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 11,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 4,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    elizabethKnightly: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 67,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -20,
            y: -55,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    stevenLampard: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 153,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 54,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 7,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 105,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 560,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 15,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    sueChenju: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 65,
            y: 48,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 17,
            y: 55,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 5,
            y: 48,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    alexandraBrandon: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 176,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 88,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 5,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 5,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    aliAhmad: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 30,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 20,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 30,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 350,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 50,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    selbyMartin: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 168,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 14,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 2,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: +0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    ernestStabile: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 162,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 95,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: -10,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 25,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    claytonTodd: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 192,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 43,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 52,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -70,
            y: -20,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    maryJames: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 200,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 125,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 15,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jackRobinson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 5,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    ericThompson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 135,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 43,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 50,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 350,
            y: 110,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    davidJones: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 180,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 257,
            y: 128,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 435,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 300,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 170,
            y: 115,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 15,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    anthonyBaker: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 155,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 2,
            y: 102,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: -10,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 115,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 340,
            y: 115,
            scaleX: -0.95,
            scaleY: 0.95,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 120,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnWilson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 155,
            y: 130,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: -10,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 135,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 340,
            y: 135,
            scaleX: -0.95,
            scaleY: 0.95,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 140,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -70,
            y: -60,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    alexJohnson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 185,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 400,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 125,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 290,
            y: 125,
            scaleX: -0.95,
            scaleY: 0.95,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 120,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adamBeach: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 220,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 175,
            y: 440,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 85,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    aliNazar: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 22,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 115,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 290,
            y: 115,
            scaleX: -0.95,
            scaleY: 0.95,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 150,
            y: 120,
            scaleX: 0.95,
            scaleY: 0.95,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    amyRichardson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 115,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: +0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -30,
            y: -26,
            scaleX: 1.1,
            scaleY: 1.1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 32,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 32,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 20,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    ronChan: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientBody',
            x: 170,
            y: 440,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientTorso',
            x: 120,
            y: 122,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 252,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 120,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 3220,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 155,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    adelineYenMah: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 175,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 90,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -70,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    alexJones: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 80,
            y: 95,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 29,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 20,
            y: 95,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 30,
            y: 60,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 370,
            y: 60,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -50,
            y: -100,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    anaBoyt: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 170,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 15,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 55,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 120,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 120,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    alexSanders: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 183,
            y: 195,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 105,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 395,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 130,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 130,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      // {
      //   persp: 'LeftArmPit',
      //   sprites: [{
      //     name: 'patientLeftArmPit',
      //     x: 0,
      //     y: 0,
      //     scaleX: 1,
      //     scaleY: 1,
      //     regX: 0,
      //     regY: 0
      //   }]
      // },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 115,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 485,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 15,
            y: 85,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 490,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 110,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    stevenFoster: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 183,
            y: 205,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 405,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 70,
            y: 110,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 330,
            y: 110,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 165,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: -10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 615,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 505,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    litaDamokosh: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientBody',
            x: 60,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientTorso',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -50,
            y: -100,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    melissaMoore: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 195,
            y: 270,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 146,
            y: 172,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 152,
            y: 168,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 160,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 182,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jessicaWood: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 205,
            y: 217,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 158,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 160,
            y: 115,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 160,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    evaHollander: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 90,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 90,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 198,
            y: 140,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnWalker: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 166,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 8,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 550,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    malieIokina: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 193,
            y: 250,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 172,
            y: 154,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 130,
            y: 175,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 160,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 195,
            y: 170,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    akiyamaKyoumi: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 88,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 520,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 80,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    badruNajat: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 75,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    hadiDerkhshani: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 70,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 69,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    maryWashington: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 32,
            y: 91,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 20,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 50,
            y: 55,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 350,
            y: 55,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 520,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 80,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jamesAnderson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 32,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    gracielaBeinvenida: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 61,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 90,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 81,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 70,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 70,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 30,
            y: 80,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    kimHoffman: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 25,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 12,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 40,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 560,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 20,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: -60,
            y: -70,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 30,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 40,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnChan: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 272,
            y: 270,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 205,
            y: 365,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 210,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 170,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jamesRodriguez: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 278,
            y: 290,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 265,
            y: 225,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 205,
            y: 385,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 250,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 130,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    nadiaSolanski: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 269,
            y: 280,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 267,
            y: 215,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 175,
            y: 380,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 100,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    michaelRose: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 280,
            y: 290,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 220,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 386,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 160,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 500,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    kevinWhinery: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 292,
            y: 260,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 270,
            y: 190,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 215,
            y: 360,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 190,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 235,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 7,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    martinHarris: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 174,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 160,
            y: 126,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 165,
            y: 410,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 0,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 600,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 140,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 10,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 10,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    vanHarrison: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 35,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 300,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    larrySimpsons: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 155,
            y: 200,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 160,
            y: 445,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 145,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 300,
            y: 145,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 140,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: -10,
            y: 5,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 50,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    // Megan Sliva
    aliceRoberts: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 185,
            y: 67,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 20,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 10,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 50,
            y: 120,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 400,
            y: 120,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 200,
            y: 140,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 45,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 550,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 50,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    pacorroArrellanes: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 225,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 253,
            y: 133,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 180,
            y: 430,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 60,
            y: 145,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 145,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 182,
            y: 150,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 10,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 600,
            y: 500,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    joeFernandes: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 190,
            y: 200,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 263,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 165,
            y: 405,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 160,
            scaleX: 0.9,
            scaleY: 0.9,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 320,
            y: 160,
            scaleX: -0.9,
            scaleY: 0.9,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 160,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 115,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 485,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 15,
            y: 145,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 140,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 120,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 110,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    jenniferLawson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 270,
            y: 290,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 220,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 200,
            y: 380,
            scaleX: 1.3,
            scaleY: 1.3,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 160,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 430,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: -8,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 450,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 150,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 60,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    etienneShanker: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 165,
            y: 210,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 125,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 175,
            y: 445,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 80,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 280,
            y: 80,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 190,
            y: 120,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 5,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 20,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 30,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 40,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    michaelJefferson: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 270,
            y: 290,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 255,
            y: 220,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 205,
            y: 380,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 190,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 235,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    lawrenceClark: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 270,
            y: 290,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 260,
            y: 220,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 205,
            y: 380,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 190,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 235,
            y: 200,
            scaleX: -1.2,
            scaleY: 1.2,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 180,
            y: 200,
            scaleX: 1.2,
            scaleY: 1.2,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: -10,
            scaleX: 0.93,
            scaleY: 0.93,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 30,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    johnGorgin: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 60,
            y: 65,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 10,
            y: 70,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 0,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: -40,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 470,
            y: 40,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 50,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 690,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 18,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 600,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 0,
            y: 40,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 160,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
    arthurKelly: [
      {
        persp: 'Front',
        sprites: [
          {
            name: 'patientTorso',
            x: 232,
            y: 275,
            scaleX: 1,
            scaleY: 1,
            regX: 60,
            regY: 0,
          },
          {
            name: 'patientHead',
            x: 263,
            y: 190,
            scaleX: 1,
            scaleY: 1,
            regX: 10,
            regY: 0,
          },
          {
            name: 'patientBody',
            x: 185,
            y: 465,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightSide',
        sprites: [
          {
            name: 'patientRightSide',
            x: 50,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftSide',
        sprites: [
          {
            name: 'patientLeftSide',
            x: 310,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 200,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Back',
        sprites: [
          {
            name: 'patientBack',
            x: 175,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Down',
        sprites: [
          {
            name: 'patientDown',
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'RightArmPit',
        sprites: [
          {
            name: 'patientRightArmPit',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'LeftArmPit',
        sprites: [
          {
            name: 'patientLeftArmPit',
            x: 20,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoFront',
        sprites: [
          {
            name: 'patientTorsoFront',
            x: 0,
            y: 2,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoRight',
        sprites: [
          {
            name: 'patientTorsoRight',
            x: 100,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoLeft',
        sprites: [
          {
            name: 'patientTorsoLeft',
            x: 500,
            y: 0,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'TorsoBack',
        sprites: [
          {
            name: 'patientTorsoBack',
            x: 10,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadFront',
        sprites: [
          {
            name: 'patientHeadFront',
            x: 20,
            y: 140,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadLeft',
        sprites: [
          {
            name: 'patientHeadRight',
            x: 480,
            y: 100,
            scaleX: -1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadRight',
        sprites: [
          {
            name: 'patientHeadLeft',
            x: 130,
            y: 100,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'HeadBack',
        sprites: [
          {
            name: 'patientHeadBack',
            x: 40,
            y: 80,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Hands',
        sprites: [
          {
            name: 'patientHands',
            x: 0,
            y: 60,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
      {
        persp: 'Feet',
        sprites: [
          {
            name: 'patientFeet',
            x: 120,
            y: 20,
            scaleX: 1,
            scaleY: 1,
            regX: 0,
            regY: 0,
          },
        ],
      },
    ],
  };

  palpation = {
    default: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    aliceRoberts: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 583,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1075,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 625,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 615,
            y: 753,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 810,
            y: 615,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 885,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 30,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 200,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 30,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 280,
              y: 0,
              w: 60,
              h: 700,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 320,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    songZhi: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 275,
              y: 130,
              w: 80,
              h: 40,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 275,
              y: 130,
              w: 80,
              h: 40,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 515,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 845,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 695,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 799,
            y: 615,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 865,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 545,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 610,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 384,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 374,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 460,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 544,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 354,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 510,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 510,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    amrSaleh: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 585,
            y: 850,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 305,
            y: 750,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 585,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 550,
            y: 720,
            scaleX: 1.15,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 955,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 835,
            y: 860,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 750,
            y: 630,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 720,
            y: 690,
            scaleX: -1.15,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 965,
            scaleX: -1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 425,
            y: 848,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 510,
            y: 650,
            scaleX: -1.15,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 500,
            y: 890,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 320,
            y: 115,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 720,
            y: 150,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 374,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 364,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 210,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 534,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 344,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 50,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 10,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 120,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 70,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    linaAlAndalusi: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 360,
            y: 600,
            scaleX: 0.88,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 415,
            y: 480,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 230,
            y: 660,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 1550,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 360,
            y: 630,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 430,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 660,
            y: 700,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 1090,
            y: 740,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 835,
            y: 638,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 800,
            y: 590,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 610,
            y: 530,
            scaleX: 1.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 275,
              r: 70,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      // {
      //   type: 'superficialpalpation',
      //   isDefault: false,
      //   persp: 'TorsoBack',
      //   hitArea: {
      //     image: {
      //       name: 'Back.Torso.Palp.Lungs',
      //       x: 345,
      //       y: 340,
      //       scaleX: 1
      //     },
      //     shapes: null
      //   },
      //   sounds: ['patient.palp.lungs'],
      //   loopSound: false
      // },
      // {
      //   type: 'percussion',
      //   isDefault: false,
      //   persp: 'TorsoBack',
      //   hitArea: {
      //     image: {
      //       name: 'Back.Torso.Perc.LungsDeadSpace',
      //       x: 290,
      //       y: 300,
      //       scaleY: 1
      //     },
      //     shapes: null
      //   },
      //   sounds: ['patient.perc.lungsDead'],
      //   loopSound: false
      // },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 145,
            y: 270,
            scaleX: 1.5,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 64,
            y: 500,
            scaleX: 0.5,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      // {
      //   type: 'percussion',
      //   isDefault: false,
      //   persp: 'TorsoBack',
      //   hitArea: {
      //     image: {
      //       name: 'Back.Torso.Perc.Lungs',
      //       x: 345,
      //       y: 210,
      //       scaleY: 1
      //     },
      //     shapes: null
      //   },
      //   sounds: ['patient.perc.lungs'],
      //   loopSound: false
      // },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 130,
            y: 505,
            scaleX: 0.82,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 844,
            y: 500,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 790,
            y: 490,
            scaleX: 0.82,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 354,
            y: 500,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 374,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 364,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 290,
            scaleX: 1.9,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 534,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 344,
            y: 50,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 460,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 460,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 50,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 30,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 130,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 155,
              r: 45,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 70,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    amyReed: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 525,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 695,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 945,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 695,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 945,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 250,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 470,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 590,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 135,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 130,
              r: 45,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    brandonGreen: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 265,
            y: 755,
            scaleX: 1.25,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 360,
            y: 555,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 350,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 350,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    vanHarrison: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 225,
              r: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 325,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 260,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 325,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 0,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 325,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 220,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 590,
            y: 270,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 590,
            y: 270,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    devinAtkins: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 699,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 510,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 420,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 420,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    nazaninPahlevan: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 375,
            y: 540,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 630,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 875,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 620,
            y: 745,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 650,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 875,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 760,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    anthonyBrandon: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 210,
            y: 1005,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 210,
            y: 900,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 245,
            y: 690,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 405,
            y: 560,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jayGarret: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 320,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 320,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 320,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 635,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 590,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 665,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 899,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 510,
            y: 799,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 469,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 235,
              y: 80,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 312,
              y: 43,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 143,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    seanCooper: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 630,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 630,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    simonRobins: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 655,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 860,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 715,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 480,
            y: 820,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    larrySimpsons: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 130,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 130,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 404,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 394,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 480,
            y: 220,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 564,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 374,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 570,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 570,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    anyaSimmons: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 990,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 910,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 305,
            y: 735,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 615,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 665,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 905,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 695,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 915,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 790,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 560,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    markWarlegan: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 605,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 593,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 40,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 50,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 50,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    shadiMaha: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 645,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 350,
            y: 885,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 850,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 890,
            y: 895,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 790,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 620,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    alexPatterson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 555,
            scaleX: 1.4,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    peterWhite: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    dianeJames: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 535,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 625,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 515,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 445,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 105,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnOak: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 740,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 374,
            y: -80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 364,
            y: -90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 130,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 534,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 344,
            y: -80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 250,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 250,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 0,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 50,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    harryKing: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 655,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 370,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 300,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 630,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 850,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 880,
            y: 900,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 670,
            y: 620,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 530,
              r: 25,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 530,
              r: 25,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    ericThompson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 570,
            y: 780,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 285,
            y: 720,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 510,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 670,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 770,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 530,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 280,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 200,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 115,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 130,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    tomSimpson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1010,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 450,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 720,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 720,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    emilianoParis: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 235,
              y: 278,
              r: 35,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 278,
              r: 35,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1010,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 265,
            y: 750,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 340,
            y: 590,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 450,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    selbyMartin: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1010,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 255,
            y: 710,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 450,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    muhanadKattan: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1010,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 320,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 450,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 740,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 150,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 75,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 374,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 334,
            y: -20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 420,
            y: 200,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 504,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 314,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 500,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 500,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 275,
              y: 15,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    joshuaJameson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1010,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 320,
            y: 755,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 490,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 760,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 660,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 705,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 440,
            y: 830,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 540,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 150,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 75,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 670,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 304,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 294,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 484,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 274,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 210,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    anthonyBaker: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 540,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 276,
            y: 740,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsLeft',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsRight',
            x: 600,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsRight',
            x: 645,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsLeft',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 280,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 480,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 650,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 694,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 800,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 800,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    andrewEvans: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 240,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 240,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 455,
            y: 635,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 380,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 620,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: -30,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 300,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 630,
            y: -55,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 700,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jessicaAnderson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 600,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 235,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 235,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 365,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 450,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 470,
            y: 660,
            scaleX: 0.7,
            scaleY: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 450,
            y: 840,
            scaleX: 0.7,
            scaleY: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 650,
            y: 760,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 640,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 735,
            y: 685,
            scaleX: -0.7,
            scaleY: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 730,
            y: 840,
            scaleX: -0.7,
            scaleY: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 560,
            y: 770,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 635,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 490,
            y: 720,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 320,
            y: 500,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 420,
            y: 70,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 570,
            y: 180,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 380,
            y: 530,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 420,
            y: 70,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 210,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 425,
              y: 210,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 145,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 285,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 390,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 600,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 530,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 604,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 480,
            y: 270,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 560,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 560,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 50,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    lemondPolk: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    enricoPAccoro: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 235,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 235,
            y: 955,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 300,
            y: 765,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 645,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 380,
            y: 1500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 90,
              r: 55,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 48,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 299,
              r: 55,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 180,
              r: 45,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 90,
              r: 55,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 280,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 524,
            y: 605,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 640,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 444,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 434,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 495,
            y: 290,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 634,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 414,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 380,
            scaleX: 0.99,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 380,
            scaleX: 0.99,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 75,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 35,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 130,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 85,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    adelineYenMah: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 360,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 360,
              r: 45,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 635,
            scaleX: 0.8,
            scaleY: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 350,
            y: 845,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 590,
            y: 760,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 640,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 685,
            scaleX: -0.8,
            scaleY: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 865,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 595,
            y: 785,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 640,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 445,
            y: 760,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 230,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 270,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 514,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 390,
            y: 185,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 584,
            y: 755,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 520,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 624,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 380,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 370,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 564,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 354,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 470,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 460,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 230,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    sueChenju: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 300,
              r: 90,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 300,
              r: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 180,
              y: 330,
              w: 90,
              h: 150,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 180,
              y: 330,
              w: 90,
              h: 150,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 670,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 535,
            scaleX: 1.05,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    cindyDervis: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 410,
              r: 65,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 410,
              r: 65,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 350,
            y: 635,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 875,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 580,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 840,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 890,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 600,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
    ],
    susanHarrison: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 200,
              h: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 200,
              h: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 350,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 600,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 850,
            y: 680,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 860,
            y: 900,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    cattyMars: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 335,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 515,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 340,
            y: 635,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 320,
            y: 925,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 590,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 870,
            y: 620,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 860,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 620,
            y: 770,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    aliciaBolts: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 570,
            y: 745,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 535,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 300,
            y: 620,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 965,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 570,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 860,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 870,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 610,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    roseannLever: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Hands',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 310,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Hands',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 310,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1020,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 940,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 605,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 470,
            y: 665,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 715,
            y: 805,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 620,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 730,
            y: 685,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 915,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 820,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    kristenMeyer: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 125,
              y: 80,
              r: 45,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 475,
              y: 80,
              r: 45,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 125,
              y: 80,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 475,
              y: 80,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 170,
              y: 200,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 200,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 170,
              y: 200,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 200,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 540,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 540,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 40,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 40,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 450,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 85,
              y: 380,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 85,
              y: 380,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 85,
              y: 60,
              r: 75,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 85,
              y: 60,
              r: 75,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 385,
              y: 60,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 385,
              y: 60,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },

      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 80,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 540,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 80,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 540,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },

      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 30,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },

      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 30,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 350,
            y: 505,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 635,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 500,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 500,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 346,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    billClark: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 600,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 355,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 560,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 680,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 820,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      } /*
    {
      type: 'superficialpalpation',
      isDefault: false,
      persp: 'HeadRight',
      hitArea: {
        image: {
          name: 'Right.Head.Palp.SuperClavicular',
          x: 820,
          y: 825,
          scaleX: -1
        },
        shapes: null
      },
      sounds: ['patient.palp.superclavicular'],
      loopSound: false
    }, */,
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 870,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 510,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 400,
            y: 630,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 135,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 325,
              y: 200,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 135,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 270,
              y: 390,
              w: 200,
              h: 500,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    alexSanders: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 470,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 425,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 600,
              y: 380,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 600,
              y: 380,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 50,
              y: 390,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 50,
              y: 390,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 360,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 304,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 294,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 484,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 274,
            y: 0,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 210,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 275,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 130,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 520,
              y: 185,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 520,
              y: 185,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    stevenFoster: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 855,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1045,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 965,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 765,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 655,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1155,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 370,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 860,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 690,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 805,
            y: 935,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 515,
            y: 870,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 170,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 145,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 295,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 290,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 215,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 145,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 120,
              y: 135,
              r: 30,
            },
          ],
        },
        sounds: ['patient.no'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 375,
              y: 395,
              r: 90,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 375,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    angelDickinson: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 130,
              y: 20,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 20,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 105,
              y: 50,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 485,
              y: 50,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 295,
              y: 10,
              r: 90,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 130,
              y: 20,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 20,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 105,
              y: 50,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 485,
              y: 50,
              r: 70,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 295,
              y: 10,
              r: 90,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 360,
            y: 530,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 335,
            y: 615,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 345,
            y: 875,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 590,
            y: 745,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 870,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 860,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 630,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 560,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 40,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 105,
              y: 20,
              w: 100,
              h: 250,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 105,
              y: 20,
              w: 100,
              h: 250,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 405,
              y: 20,
              w: 100,
              h: 250,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 405,
              y: 20,
              w: 100,
              h: 250,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    adhaHunt: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 320,
              r: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 855,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 820,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 135,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    sarahYang: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 350,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1020,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 940,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 415,
            y: 585,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 430,
            y: 695,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 880,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 600,
            y: 780,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 580,
            y: 640,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 770,
            y: 685,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 865,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 600,
            y: 780,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 630,
            y: 620,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 465,
            y: 720,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 270,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 245,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 260,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 260,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 180,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 380,
            y: 165,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 614,
            y: 755,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 530,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 574,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 550,
            y: 330,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 245,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 50,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 375,
              y: 105,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    brandiGraham: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 735,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 560,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 744,
            y: 735,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 400,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 454,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 324,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 314,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 230,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 484,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 294,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 190,
              y: 50,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 10,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 70,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 140,
              r: 35,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    lillianGreen: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 575,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 140,
              y: 100,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 140,
              y: 100,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 100,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 180,
              y: 120,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 100,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 90,
              y: 70,
              r: 80,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    meganSliva: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 400,
            y: 895,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 620,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 900,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 140,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 10,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 50,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 200,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 50,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 660,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 660,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 650,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 500,
            y: 480,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 500,
            y: 480,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 65,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 150,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 160,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 110,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 140,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 140,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 315,
              y: 110,
              r: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 315,
              y: 110,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    lesiaOldberry: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 795,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 635,
            scaleX: 1.15,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1155,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 530,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 480,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 775,
            y: 865,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 660,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 660,
            y: 770,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 660,
            y: 1020,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 420,
            y: 865,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 500,
            y: 690,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 180,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 60,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 210,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 60,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 130,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 60,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 480,
              y: 330,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 480,
              y: 330,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 455,
              y: 150,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    emaliaDahill: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 560,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1080,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 795,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 665,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 430,
            y: 685,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 430,
            y: 945,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 650,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 635,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 770,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 720,
            y: 975,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 550,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 615,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 429,
              y: 320,
              r: 46,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 432,
              y: 325,
              r: 77,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
    ],
    claytonTodd: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 300,
              r: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 300,
              r: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 330,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 330,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 400,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 400,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 510,
              y: 210,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 510,
              y: 210,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 310,
            y: 500,
            scaleX: 1.5,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    josephTsai: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 315,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 465,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 920,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 745,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 735,
            y: 670,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 935,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 460,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 200,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    adrianColeman: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 735,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 540,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 480,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jeremyRamzi: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    aliNazar: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 160,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 344,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 720,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 764,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 464,
            y: 30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 444,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 240,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 424,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 48,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 20,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 110,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 100,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 65,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnWilson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 355,
            y: 505,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 630,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 674,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 464,
            y: 30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 444,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 240,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 424,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 278,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 326,
              y: 35,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 387,
              y: 115,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 100,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 70,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    daveMark: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 635,
            scaleX: 0.8,
            scaleY: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 350,
            y: 845,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 630,
            y: 790,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 640,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 685,
            scaleX: -0.8,
            scaleY: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 800,
            y: 875,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 515,
            y: 785,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 610,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 465,
            y: 680,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 230,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 270,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 514,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 325,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 390,
            y: 185,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 584,
            y: 755,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 520,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 624,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 380,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 370,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 350,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 564,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 354,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 590,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 590,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 230,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jerryCarter: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 365,
            y: 550,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 550,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 470,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 770,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 700,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 660,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 750,
            y: 960,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 420,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 500,
            y: 610,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 160,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 344,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 785,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 794,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 464,
            y: 30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 444,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 240,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 424,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 48,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 20,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 110,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 100,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 65,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    alexandraBrandon: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 860,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 955,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 595,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 480,
            y: 630,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 410,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 755,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 630,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 730,
            y: 670,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 790,
            y: 865,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 420,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 540,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 200,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 290,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 424,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 695,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 755,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 444,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 464,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 550,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 614,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 464,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 539,
            y: 425,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 539,
            y: 425,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 470,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation', //
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    alexJohnson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1175,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 390,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 620,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 810,
            y: 835,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    melisentMccraw: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 340,
              r: 70,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 490,
            scaleX: 1.14,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 455,
            y: 585,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 865,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 685,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 760,
            y: 605,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 865,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 520,
            y: 720,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 530,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 255,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    charlotteHeys: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 560,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 330,
            y: 625,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 320,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 582,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 890,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 900,
            y: 935,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 630,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 630,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 405,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 560,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 255,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 45,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    stevenLampard: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 30,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 140,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 30,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 620,
            y: 150,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 660,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 480,
            y: 30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 445,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 280,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 624,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 430,
            y: 25,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 130,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    maryJames: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 855,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 810,
            y: 590,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 740,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 560,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 180,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 50,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 390,
            y: 160,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 604,
            y: 705,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 560,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 404,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 394,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 450,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 564,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 374,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 500,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 500,
            y: 370,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    lillianMcKay: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 575,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 350,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 425,
            y: 505,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 655,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 670,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 720,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 970,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 550,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 620,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 280,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 110,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 115,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 614,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 570,
            y: 130,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 584,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 500,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 674,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 474,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 570,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 570,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 495,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 300,
              y: 210,
              w: 120,
              h: 80,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 300,
              y: 210,
              w: 120,
              h: 80,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    shahrzadDickinson: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 365,
            y: 630,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 428,
            y: 470,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 450,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 970,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 680,
            y: 790,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 625,
            y: 585,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 730,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 970,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 590,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 370,
            y: 520,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 250,
            y: 640,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 75,
            scaleX: 1.25,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 200,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 250,
            y: 640,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 75,
            scaleX: 1.25,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 305,
            y: 120,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 250,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 305,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 305,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 270,
            y: 80,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 614,
            y: 725,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 630,
            y: 90,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 584,
            y: 610,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 500,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 674,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 474,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 630,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 630,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 495,
              y: 150,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    elizabethKnightly: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 200,
              h: 100,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 200,
              h: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 350,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 660,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 670,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 960,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 530,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 270,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 310,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 588,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 650,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 565,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 387,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    donnaWilliams: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 320,
              r: 140,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 320,
              r: 140,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 430,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1090,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 440,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 400,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 685,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 640,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 825,
            y: 900,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 540,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 270,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 166,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 160,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 760,
            y: 120,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 387,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 445,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnRay: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 350,
            y: 570,
            scaleX: 1.35,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1155,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 695,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 750,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 430,
            y: 670,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 590,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 590,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 45,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 356,
              y: 95,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    davidMason: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 580,
              y: 330,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 580,
              y: 330,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 910,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 370,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 420,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 860,
            y: 880,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 780,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 75,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 450,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    martinHarris: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 520,
              y: 280,
              r: 66,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 520,
              y: 280,
              r: 66,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 870,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 530,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 270,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    javadBanivand: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 585,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 910,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 630,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 570,
            y: 590,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 820,
            y: 660,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 885,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 790,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 650,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 380,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 45,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 385,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 130,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 310,
              r: 130,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 460,
              y: 310,
              r: 130,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    leonSheldon: [
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 160,
              y: 500,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 160,
              y: 500,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 400,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 525,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 290,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 700,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 780,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 255,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 175,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 260,
            y: 20,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 230,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 700,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    isabellaJohnson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 795,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 270,
            y: 685,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 365,
            y: 515,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1045,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 440,
            y: 615,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 855,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 675,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 770,
            y: 635,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 800,
            y: 875,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 530,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 530,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 430,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 240,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 130,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 240,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 255,
              y: 110,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 180,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 110,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 345,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 575,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 470,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 460,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 545,
            y: 260,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 630,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 440,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 415,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 415,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 285,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 65,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 405,
              y: 155,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 455,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 375,
              y: 115,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    magganSpencer: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 805,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 105,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 215,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 550,
              r: 65,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 340,
              r: 65,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 550,
              r: 65,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 340,
              r: 65,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 120,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    jamesMetler: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 990,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 280,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 240,
              y: 200,
              w: 120,
              h: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    albertoDouglas: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 510,
              y: 220,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 510,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 520,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 420,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 625,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 350,
            y: 875,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 645,
            y: 760,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 580,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 820,
            y: 660,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 880,
            y: 920,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 780,
            scaleX: -1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 580,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 280,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 380,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 380,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 370,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 315,
              y: 20,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 395,
              y: 130,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 130,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 90,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    litaDamokosh: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 585,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 320,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 520,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1080,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 370,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 845,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 615,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 870,
            y: 845,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 400,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 580,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 600,
            y: 750,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    melissaMoore: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 560,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1000,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 260,
            y: 670,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1080,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 370,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 845,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 615,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 870,
            y: 845,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 550,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 430,
            y: 740,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 240,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 300,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 550,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 620,
            y: 750,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jessicaWood: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 343,
            y: 692,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 406,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1020,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 340,
            y: 845,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 810,
            y: 595,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 845,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 530,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 420,
            y: 740,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 275,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 110,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 275,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 90,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 550,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 620,
            y: 750,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 165,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 463,
              y: 160,
              r: 33,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    edwinJohnson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 765,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 322,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 575,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 850,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 675,
            y: 710,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 490,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 575,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 845,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 520,
            y: 710,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 490,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 280,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 594,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 595,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    ronChan: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 470,
              y: 430,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 470,
              y: 430,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1030,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 575,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 850,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 720,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 490,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 575,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 845,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 470,
            y: 760,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 490,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 75,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 47,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 594,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 595,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 344,
            y: 10,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 334,
            y: 20,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 440,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 504,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 304,
            y: 10,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 590,
            y: 460,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 590,
            y: 460,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 210,
              y: 90,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 60,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 170,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 415,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 316,
              y: 120,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    evaHollander: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 200,
              y: 520,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 350,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 200,
              y: 520,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 550,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 295,
            y: 675,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 405,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 535,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 340,
            y: 785,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 670,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 620,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 810,
            y: 545,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 785,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 670,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 580,
            y: 430,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 400,
            y: 650,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 280,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 280,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 220,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 280,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 280,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 550,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 620,
            y: 750,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnWalker: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 545,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 515,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 750,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 730,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 680,
            y: 585,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 440,
            y: 740,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 470,
            y: 500,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 280,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsLeft',
            x: 280,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsRight',
            x: 580,
            y: 85,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 255,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 245,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 405,
              y: 265,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 110,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 280,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 230,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsLeft',
            x: 295,
            y: 120,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsRight',
            x: 600,
            y: 120,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 524,
            y: 70,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 275,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 260,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 514,
            y: 730,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 620,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 650,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 360,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 360,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 440,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 430,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 345,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 625,
            y: 360,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 625,
            y: 360,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 305,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 315,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    malieIokina: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 550,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 289,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 385,
            y: 530,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 395,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 340,
            y: 885,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 635,
            y: 745,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 620,
            y: 565,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 820,
            y: 615,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 875,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 580,
            y: 565,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 400,
            y: 650,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 290,
            y: 90,
            scaleX: 1.35,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 270,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 280,
            y: 90,
            scaleX: 1.35,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 105,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 280,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 448,
              y: 295,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 185,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 367,
              y: 93,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 200,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 160,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 200,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 460,
            y: 200,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 650,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 490,
            y: 220,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 530,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 540,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    akiyamaKyoumi: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 910,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 830,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 305,
            y: 650,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 290,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 270,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 560,
            y: 755,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 420,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 900,
            y: 660,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 900,
            y: 860,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 640,
            y: 780,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 750,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 430,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 55,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 200,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 55,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 235,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 260,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 165,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 340,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 480,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 740,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    rebeccaRamirez: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 570,
            y: 755,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 985,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 285,
            y: 680,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 370,
            y: 480,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 340,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 480,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 740,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    badruNajat: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 805,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 380,
            y: 560,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 525,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 800,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 675,
            y: 660,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 430,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 525,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 795,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 520,
            y: 660,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 430,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 380,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 690,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 480,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 525,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 406,
              y: 450,
              r: 85,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 406,
              y: 450,
              r: 85,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    hadiDerkhshani: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 390,
            y: 525,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 800,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 675,
            y: 660,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 430,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 525,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 795,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 520,
            y: 660,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 430,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 75,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsLeft',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.LungsRight',
            x: 600,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 260,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsRight',
            x: 645,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsright'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.LungsLeft',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungsleft'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 380,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 690,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 480,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 525,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    maryWashington: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 400,
              r: 65,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 310,
              r: 65,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 550,
            y: 740,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 210,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 210,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 310,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 406,
            y: 510,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 320,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 300,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 560,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 600,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 880,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 900,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 630,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 680,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 340,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 580,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 620,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 346,
              y: 100,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jamesAnderson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 440,
            y: 650,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 900,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 730,
            y: 780,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 650,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 620,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 900,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 470,
            y: 790,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 550,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 470,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 280,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 380,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 660,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 480,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 525,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 480,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 480,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 80,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 310,
              r: 120,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 310,
              r: 120,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 145,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 95,
              r: 27,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    gracielaBeinvenida: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 585,
            y: 705,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 210,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 210,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 370,
            y: 640,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 469,
            y: 510,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1080,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 330,
            y: 845,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 645,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 540,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 790,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 870,
            y: 845,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 540,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 740,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 240,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 280,
              r: 50,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 400,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 580,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 600,
            y: 750,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 560,
            y: 470,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 560,
            y: 470,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    alexJones: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 200,
            y: 980,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 200,
            y: 900,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 625,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 840,
            y: 870,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 720,
            y: 670,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 340,
            y: 860,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 480,
            y: 670,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 75,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 190,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 570,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 220,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 70,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 340,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 480,
            y: 725,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 170,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 740,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 620,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    kimHoffman: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 580,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 235,
            y: 920,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 235,
            y: 840,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 340,
            y: 645,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 430,
            y: 505,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1025,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 470,
            y: 645,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 450,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 695,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 660,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 730,
            y: 635,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 740,
            y: 925,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 780,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 570,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 550,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 150,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 155,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 260,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 155,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 230,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 290,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 290,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 135,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 565,
            y: 740,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 590,
            y: 160,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 640,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 590,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 590,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 105,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 65,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 130,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnChan: [
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 300,
              r: 45,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Pediatric.Ausc.Lungs',
            x: 330,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 370,
            y: 600,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 565,
            y: 705,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 550,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 610,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 640,
            y: 720,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 625,
            y: 550,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 410,
            y: 740,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 280,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 215,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 280,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 255,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 170,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 514,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 585,
            y: 660,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 540,
            y: 200,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 620,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 220,
            y: -40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 210,
            y: -20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 350,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 380,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 190,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 380,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 405,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 150,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 15,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 75,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    kevinWhinery: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 830,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Pediatric.Ausc.Lungs',
            x: 330,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      } /*
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1
          },
          shapes: null
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false
      },
      */,
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      } /*
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1
          },
          shapes: null
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false
      },
      */,
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 500,
            y: 680,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 90,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 170,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 180,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 316,
              y: 100,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 385,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 330,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 385,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 574,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 385,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 200,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 274,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 264,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 300,
            y: 320,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 410,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 244,
            y: 10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 390,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 390,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 170,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 40,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 120,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 180,
              y: 320,
              w: 80,
              h: 130,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 180,
              y: 320,
              w: 80,
              h: 130,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 230,
              y: 270,
              w: 90,
              h: 120,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 230,
              y: 270,
              w: 90,
              h: 120,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    pacorroArrellanes: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 410,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 745,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 620,
            y: 550,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 645,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 570,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 384,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 570,
            y: 410,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    joeFernandes: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 605,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 495,
            y: 660,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 690,
            y: 765,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 585,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 700,
            y: 650,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 510,
            y: 760,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 570,
            y: 570,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 270,
            y: 110,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 290,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 270,
            y: 110,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 290,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 290,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 210,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 140,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 260,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 504,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 250,
              y: 380,
              w: 80,
              h: 150,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 250,
              y: 380,
              w: 80,
              h: 150,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 715,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 600,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 390,
            y: 50,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 390,
            y: 40,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 550,
            y: 140,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 360,
            y: 50,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    adamPeterson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1130,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 860,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 655,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 930,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 660,
            y: 790,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 590,
            y: 590,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 645,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 925,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 530,
            y: 775,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 590,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 350,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 380,
            y: 145,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 690,
            y: 760,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 480,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 525,
            y: 760,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 430,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 366,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 206,
              y: 110,
              r: 85,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 206,
              y: 110,
              r: 55,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    jamesRodriguez: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 790,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 525,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 905,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 360,
            y: 505,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 585,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 570,
            y: 480,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 850,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 630,
            y: 630,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 630,
            y: 460,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 630,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 60,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 210,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 190,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 710,
            y: 545,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 730,
            y: 150,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 500,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 224,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 214,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 300,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 384,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 194,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 350,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 375,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 160,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 130,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jennaMartin: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 530,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 215,
            y: 1020,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 215,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 290,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 370,
            y: 595,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1075,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 480,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 470,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 710,
            y: 815,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 655,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 700,
            y: 685,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 740,
            y: 955,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 470,
            y: 820,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 550,
            y: 590,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 380,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 270,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 490,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 270,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 130,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 290,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 410,
              y: 280,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 210,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 335,
              y: 130,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 330,
            y: 130,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 275,
            y: 90,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 330,
            y: 130,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 520,
            y: 70,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 330,
            y: 130,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 300,
            y: 135,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 705,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 640,
            y: 150,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 660,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 420,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 410,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 580,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 390,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    michaelRose: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 525,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 855,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 400,
            y: 470,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 640,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 490,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 610,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 220,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 270,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 480,
            y: 210,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 220,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 270,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 235,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 170,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 325,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 260,
            y: 135,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 510,
            y: 540,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 790,
            y: 170,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 490,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 220,
            y: -40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 210,
            y: -20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 350,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 380,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 190,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 380,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 405,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 150,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 15,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 75,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    richardMcklain: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 530,
            y: 840,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 220,
            y: 1070,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 220,
            y: 990,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 250,
            y: 780,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 250,
            y: 480,
            scaleX: 1.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 575,
            y: 760,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 755,
            y: 825,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 630,
            y: 650,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 630,
            y: 755,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 440,
            y: 835,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 660,
            scaleX: -0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 400,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 200,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 90,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 220,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 220,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 90,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 305,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 250,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 305,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 494,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 305,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 310,
            y: 120,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 540,
            y: 705,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 620,
            y: 130,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 665,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 350,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 340,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 220,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 510,
            y: 70,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 330,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 425,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    nadiaSolanski: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 280,
            y: 770,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 280,
            y: 690,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 425,
            y: 480,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 300,
            y: 855,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 470,
            y: 530,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 650,
            y: 630,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 760,
            y: 520,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 560,
            y: 630,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 220,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 270,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 480,
            y: 210,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 220,
            y: 600,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 270,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 235,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 170,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 325,
              y: 100,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 260,
            y: 135,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 510,
            y: 540,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 790,
            y: 170,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 490,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 220,
            y: -40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 210,
            y: -20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 350,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 380,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 190,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 380,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 405,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 150,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 15,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 270,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 75,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 55,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    josephRodriguez: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 610,
            y: 790,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 240,
            y: 1050,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 240,
            y: 950,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 370,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 360,
            y: 470,
            scaleX: 1.5,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 630,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 600,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 530,
            y: 530,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 785,
            y: 615,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 600,
            y: 730,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 650,
            y: 530,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 300,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 180,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 240,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 300,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 300,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 160,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 335,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 280,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 335,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 524,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 335,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 120,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 600,
            y: 705,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 560,
            y: 130,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 600,
            y: 685,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 350,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 340,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 220,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 510,
            y: 70,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 330,
            y: 0,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 580,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 580,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 425,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    jenniferLawson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 350,
            y: 545,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 990,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 425,
            y: 630,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 585,
            y: 725,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 810,
            y: 610,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 620,
            y: 720,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 380,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 535,
            y: 210,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 170,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 285,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 529,
            y: 65,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 235,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 340,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 640,
            y: 250,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 180,
            y: -30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 180,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 330,
            y: 190,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 350,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 160,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 350,
            y: 360,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 350,
            y: 360,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 135,
              y: 70,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 208,
              y: 30,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 130,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 312,
              y: 112,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 245,
              y: 80,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    michaelJefferson: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 295,
              y: 340,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 295,
              y: 340,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 215,
              r: 50,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 215,
              r: 50,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 285,
            y: 845,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 285,
            y: 765,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 350,
            y: 500,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 310,
            y: 930,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 530,
            y: 560,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 695,
            y: 660,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 710,
            y: 540,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 520,
            y: 660,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 535,
            y: 210,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 170,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 285,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 529,
            y: 65,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 235,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 340,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 640,
            y: 250,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 180,
            y: -30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 180,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 330,
            y: 190,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 350,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 160,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 380,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 405,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 135,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 200,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 135,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 225,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    lawrenceClark: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 235,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 235,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 270,
            y: 550,
            scaleX: 1.5,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 485,
            y: 600,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 700,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 720,
            y: 610,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 530,
            y: 720,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 535,
            y: 210,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 580,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 325,
            y: 60,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 250,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 170,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 100,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 285,
            y: 85,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 529,
            y: 65,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 340,
            y: 125,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 235,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 340,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 640,
            y: 250,
            scaleX: 0.6,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 580,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 180,
            y: -30,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 180,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 330,
            y: 190,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 350,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 160,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 380,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 405,
            y: 340,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 135,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 200,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 265,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 295,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 225,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnGorgin: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 300,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 250,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 330,
              r: 45,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },

      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 785,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 715,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1040,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 665,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 180,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 220,
              r: 60,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 465,
              y: 250,
              r: 40,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 310,
              r: 90,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 700,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 670,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 725,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 850,
            y: 940,
            scaleX: -1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 540,
            y: 605,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 440,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 365,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 310,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 365,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 554,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 365,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 400,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 640,
            y: 745,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 560,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 530,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 420,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 420,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 115,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 415,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 465,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 395,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    arthurKelly: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 555,
            y: 770,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 340,
            y: 710,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 425,
            y: 580,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 810,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 640,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 800,
            y: 685,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 840,
            y: 945,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 560,
            y: 810,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 600,
            y: 590,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 600,
            scaleX: 0.9,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 300,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 330,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 540,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 300,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 330,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 60,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 320,
            y: 125,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 745,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 620,
            y: 150,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 355,
            y: -10,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 345,
            y: -20,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 505,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 515,
            y: 100,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 325,
            y: -10,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 565,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 565,
            y: 360,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 230,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 50,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 375,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 100,
              y: 100,
              r: 120,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 100,
              y: 100,
              r: 70,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 100,
              r: 120,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 240,
              y: 100,
              r: 70,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    rogerSheperd: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 875,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 1050,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 640,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 640,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    aliAhmad: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 950,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 870,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 460,
            y: 700,
            scaleX: 0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      // {
      //   type: 'superficialpalpation',
      //   isDefault: false,
      //   persp: 'HeadLeft',
      //   hitArea: {
      //     image: {
      //       name: 'Left.Head.Palp.SuperClavicular',
      //       x: 410,
      //       y: 825,
      //       scaleX: 0.8
      //     },
      //     shapes: null
      //   },
      //   sounds: ['patient.palp.superclavicular'],
      //   loopSound: false
      // },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 610,
            y: 795,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 620,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 750,
            y: 700,
            scaleX: -0.7,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      // {
      //   type: 'superficialpalpation',
      //   isDefault: false,
      //   persp: 'HeadRight',
      //   hitArea: {
      //     image: {
      //       name: 'Right.Head.Palp.SuperClavicular',
      //       x: 820,
      //       y: 825,
      //       scaleX: -1
      //     },
      //     shapes: null
      //   },
      //   sounds: ['patient.palp.superclavicular'],
      //   loopSound: false
      // },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 590,
            y: 790,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 595,
            y: 620,
            scaleX: -0.8,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 325,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 270,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 325,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 514,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 325,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 650,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 725,
            y: 360,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 725,
            y: 360,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    adamXu: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 205,
            y: 960,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 205,
            y: 860,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 585,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 395,
            y: 1045,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 780,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 260,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 505,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 315,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 280,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 675,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 620,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 690,
            y: 370,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 690,
            y: 370,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    davidJones: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 775,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 705,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 580,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 420,
            y: 665,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 280,
            y: 95,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 253,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 280,
            y: 95,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 255,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 425,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 350,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 275,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 520,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 330,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 390,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 680,
            y: 720,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 550,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 615,
            y: 400,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 615,
            y: 400,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 325,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 125,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    tomBarber: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 370,
            y: 565,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 210,
            y: 685,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 260,
            y: 110,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 500,
            y: 280,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 210,
            y: 685,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 260,
            y: 110,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 135,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 285,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 285,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 205,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 135,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 270,
            y: 140,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 215,
            y: 100,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 270,
            y: 140,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 460,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 270,
            y: 140,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 435,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 430,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 510,
            y: 250,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 605,
            y: 130,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 410,
            y: 40,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 680,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 680,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 280,
              y: 85,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 130,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 125,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    evaSpencer: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 895,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 620,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 895,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 580,
            y: 780,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 500,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 500,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    johnBloom: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 390,
            y: 525,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 650,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 895,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 620,
            y: 780,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 830,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 895,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 550,
            y: 780,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 600,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 660,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 660,
            y: 400,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    annaShermin: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 550,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 210,
            y: 960,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 210,
            y: 880,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 310,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 560,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 410,
            y: 675,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 370,
            y: 925,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 800,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 665,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 915,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 530,
            y: 800,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 450,
            y: 690,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 210,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 620,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 80,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 240,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 160,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 365,
              y: 90,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 120,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 420,
            y: 95,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 650,
            y: 695,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 530,
            y: 140,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 554,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 490,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 480,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 550,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 650,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 460,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 600,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 600,
            y: 390,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 290,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 370,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 485,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 110,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    ernestStabile: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 570,
            y: 820,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 980,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 900,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 330,
            y: 735,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 420,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 435,
            y: 625,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 680,
            y: 760,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 615,
            y: 520,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 765,
            y: 625,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 525,
            y: 760,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 590,
            y: 510,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 430,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 90,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 220,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 660,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 90,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 110,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 260,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 425,
              y: 280,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 180,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 110,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 570,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 640,
            y: 670,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 650,
            y: 390,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 90,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 330,
              y: 60,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 395,
              y: 145,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 140,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 355,
              y: 105,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 150,
              y: 130,
              w: 100,
              h: 200,
            },
          ],
        },
        sounds: ['patient.yesPainIs'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Feet',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Rect',
              x: 150,
              y: 130,
              w: 100,
              h: 200,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
    ],
    kenHo: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 555,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 70,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 315,
              y: 40,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 385,
              y: 135,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 440,
              y: 115,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 95,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    anaBoyt: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 795,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 730,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 400,
            y: 585,
            scaleX: 1.05,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 380,
            y: 610,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 360,
            y: 875,
            scaleX: 1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 625,
            y: 725,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 580,
            y: 565,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 820,
            y: 580,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 865,
            y: 865,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 575,
            y: 720,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 645,
            y: 520,
            scaleX: -1.1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 455,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 300,
              y: 100,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 80,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 400,
              y: 160,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 380,
              y: 120,
              r: 25,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    etienneShanker: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 750,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 930,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 410,
            y: 515,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 700,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 975,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 705,
            y: 850,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 640,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 700,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 975,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 490,
            y: 850,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 650,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 240,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 110,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 270,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 190,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 120,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 20,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 260,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 514,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 650,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 684,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 424,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 414,
            y: 40,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 500,
            y: 260,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 584,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 394,
            y: 50,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 420,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 250,
              y: 60,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 320,
              y: 45,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 390,
              y: 155,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 450,
              y: 150,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 349,
              y: 99,
              r: 30,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
    danielaAlcon: [
      {
        type: 'percussion',
        isDefault: true,
        persp: null,
        hitArea: null,
        sounds: ['patient.percussionDull'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Thyroid',
            x: 565,
            y: 770,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Subclavicular',
            x: 225,
            y: 970,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.subclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Superclavicular',
            x: 225,
            y: 890,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Cervical',
            x: 325,
            y: 715,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Palp.Submandipular',
            x: 375,
            y: 535,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'HeadFront',
        hitArea: {
          image: {
            name: 'Front.Head.Ausc.Lungs',
            x: 370,
            y: 1055,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Cervical',
            x: 420,
            y: 575,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.SuperClavicular',
            x: 380,
            y: 825,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Thyroid',
            x: 665,
            y: 710,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadLeft',
        hitArea: {
          image: {
            name: 'Left.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Cervical',
            x: 780,
            y: 575,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.SuperClavicular',
            x: 820,
            y: 825,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.superclavicular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Thyroid',
            x: 540,
            y: 710,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.thyroid'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadRight',
        hitArea: {
          image: {
            name: 'Right.Head.Palp.Submandipular',
            x: 610,
            y: 500,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.submandibular'],
        loopSound: false,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'HeadBack',
        hitArea: {
          image: {
            name: 'Back.Head.Palp.Cervical',
            x: 460,
            y: 720,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.cervical'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Perc.Heart',
            x: 520,
            y: 200,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Bowels',
            x: 260,
            y: 680,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: {
            name: 'Front.Torso.Ausc.Lungs',
            x: 300,
            y: 70,
            scaleX: 1.2,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 260,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 420,
              y: 230,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 150,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoFront',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 345,
              y: 80,
              r: 40,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'superficialpalpation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Palp.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.palp.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.LungsDeadSpace',
            x: 290,
            y: 100,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Ausc.Bronc',
            x: 534,
            y: 80,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bronchial'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'TorsoBack',
        hitArea: {
          image: {
            name: 'Back.Torso.Perc.Lungs',
            x: 345,
            y: 140,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Lungs',
            x: 360,
            y: 145,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoRight',
        hitArea: {
          image: {
            name: 'Right.Torso.Ausc.Bowels',
            x: 544,
            y: 775,
            scaleX: -1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Lungs',
            x: 510,
            y: 170,
            scaleX: 0.8,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'TorsoLeft',
        hitArea: {
          image: {
            name: 'Left.Torso.Ausc.Bowels',
            x: 654,
            y: 600,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Lungs',
            x: 384,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungs'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.LungsDead',
            x: 374,
            y: -10,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.lungsDead'],
        loopSound: false,
      },
      {
        type: 'deeppalpation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 465,
              y: 320,
              r: 100,
            },
          ],
        },
        sounds: ['patient.reallyHurt'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Liver',
            x: 560,
            y: 210,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.liver'],
        loopSound: false,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Heart',
            x: 544,
            y: 90,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.perc.heart'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Lungs',
            x: 354,
            y: 0,
            scaleX: 1,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.lungs'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Ausc.Bowels',
            x: 550,
            y: 380,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.ausc.bowels'],
        loopSound: true,
      },
      {
        type: 'percussion',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: {
            name: 'Down.Perc.Bowels',
            x: 550,
            y: 380,
            scaleX: 1.3,
          },
          shapes: null,
        },
        sounds: ['patient.perc.bowels'],
        loopSound: false,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 220,
              y: 50,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.aortic'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 310,
              y: 20,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.pulmonary'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 360,
              y: 130,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.epigastric'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 430,
              y: 130,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.apex'],
        loopSound: true,
      },
      {
        type: 'auscultation',
        isDefault: false,
        persp: 'Down',
        hitArea: {
          image: null,
          shapes: [
            {
              shape: 'Circle',
              x: 340,
              y: 70,
              r: 35,
            },
          ],
        },
        sounds: ['patient.ausc.rightventrical'],
        loopSound: true,
      },
    ],
  };

  getPalp(id) {
    switch (id) {
      case '5b3bfd79b07ee9000f216374':
        return this.palpation['stevenLampard'];
      case '5b635891fb3bf200198a69e7':
        return this.palpation['maryJames'];
      case '5a79ff172890d4519eade938':
      case '5ad51a8cb58d7474937e5901':
      case '5cd9d78c3b5b500011ed0d89':
        return this.palpation['lillianMcKay'];
      case '5bfdb2c8341d8d000f82cf88':
        return this.palpation['muhanadKattan'];
      case '609d98e6cd386400129dbe10':
        return this.palpation['shahrzadDickinson'];
      case '5bfdb565341d8d000f82cf9b':
        return this.palpation['alexPatterson'];
      case '5e7e47ca6d334c001164844c':
        return this.palpation['etienneShanker'];
      case '5b3bfdaeb07ee9000f216378':
        return this.palpation['meganSliva'];
      case '5ea36ce9fa90860012efb1c3':
        return this.palpation['linaAlAndalusi'];
      case '5e8396bb6d334c0011648462':
        return this.palpation['amrSaleh'];
      case '5c081cac1ad175000f58d225':
        return this.palpation['danielaAlcon'];
      case '5b6346f0fb3bf200198a69a9': // Alice Roberts
        return this.palpation['aliceRoberts'];
      case '5b3bfba3b07ee9000f216370':
      case '5b185b2bb59664000fbc2f0c':
        return this.palpation['simonRobins'];
      case '5b6350f9fb3bf200198a69cb':
        return this.palpation['selbyMartin'];
      case '5b633f17fb3bf200198a6995':
        return this.palpation['vanHarrison'];
      case '5b634778fb3bf200198a69af': // Ana Boyt
        return this.palpation['anaBoyt'];
      case '5b633ef4fb3bf200198a6993':
        return this.palpation['anthonyBrandon'];
      case '5c0817d31ad175000f58d213':
        return this.palpation['kenHo'];
      case '5b634083fb3bf200198a699f':
        return this.palpation['brandonGreen'];
      case '5b635848fb3bf200198a69e1':
        return this.palpation['seanCooper'];
      case '5c17f09a9020b900168e58b3':
        return this.palpation['jayGarret'];
      case '5b3bf91db07ee9000f216354':
        return this.palpation['larrySimpsons'];
      case '5bf7007e12af9b000f3f4c74':
        return this.palpation['joshuaJameson'];
      case '5bf5ab6f2d31650015dfeace':
        return this.palpation['anyaSimmons'];
      case '5c13e1749020b900168e572f':
        return this.palpation['lemondPolk'];
      case '5b634706fb3bf200198a69ab':
        return this.palpation['markWarlegan'];
      case '5bfdb429341d8d000f82cf93':
        return this.palpation['andrewEvans'];
      case '5b6357e3fb3bf200198a69d9':
        return this.palpation['ericThompson'];
      case '5c081c131ad175000f58d223':
        return this.palpation['nazaninPahlevan'];
      case '5c0819d31ad175000f58d21b':
        return this.palpation['jessicaAnderson'];
      case '5b63513dfb3bf200198a69d1':
        return this.palpation['alexandraBrandon'];
      case '5bfdb358341d8d000f82cf8d': // john oak
        return this.palpation['johnOak'];
      case '5e6fc729aa23d900117c1da8':
        return this.palpation['enricoPAccoro']; // EPA case - Enrico
      case '5b6340abfb3bf200198a69a1':
        return this.palpation['albertoDouglas'];
      case '5bfdb3c2341d8d000f82cf91':
        return this.palpation['dianeJames'];
      case '5bbfca79c05bca000f5e1028':
        return this.palpation['peterWhite'];
      case '5b635879fb3bf200198a69e5':
        return this.palpation['jerryCarter'];
      case '5bf6ffea12af9b000f3f4c71':
        return this.palpation['daveMark'];
      case '5b635110fb3bf200198a69cd':
        return this.palpation['aliNazar'];
      case '5bf5ed83398a60000f9b14c0':
        return this.palpation['tomSimpson'];
      case '5b635066fb3bf200198a69c3':
        return this.palpation['litaDamokosh'];
      case '5bfc4320341d8d000f82cf19':
        return this.palpation['melissaMoore'];
      case '5bf5b7a22d31650015dfeae8':
        return this.palpation['evaSpencer'];
      case '5b634684fb3bf200198a69a3':
        return this.palpation['emilianoParis'];
      case '5b63585efb3bf200198a69e3':
        return this.palpation['anthonyBaker'];
      case '5b6357fbfb3bf200198a69db':
        return this.palpation['jessicaWood'];
      case '5b633f4afb3bf200198a6997':
        return this.palpation['edwinJohnson'];
      case '5c17f0659020b900168e58b1': // Ron Chan Aarav
        return this.palpation['ronChan'];
      case '5c081d181ad175000f58d228': // Eva Hollander
        return this.palpation['evaHollander'];
      case '5b9af00ca78729000f00d68b': // John Walker
        return this.palpation['johnWalker'];
      case '5c081a511ad175000f58d21d': // Malie Iokina
        return this.palpation['malieIokina'];
      case '5bf6ff5612af9b000f3f4c6d':
        return this.palpation['akiyamaKyoumi'];
      case '5b6357bbfb3bf200198a69d7':
        return this.palpation['rebeccaRamirez'];
      case '5b633faffb3bf200198a699d':
        return this.palpation['johnWilson'];
      case '5be48a518dc041000fc4cf32':
        return this.palpation['badruNajat'];
      case '5bfdb315341d8d000f82cf8a':
        return this.palpation['hadiDerkhshani'];
      case '5b3bfb8cb07ee9000f21636e':
        return this.palpation['maryWashington'];
      case '5b635772fb3bf200198a69d3':
        return this.palpation['jamesAnderson'];
      case '5c081ad81ad175000f58d21f':
        return this.palpation['gracielaBeinvenida'];
      case '5b63479ffb3bf200198a69b1':
        return this.palpation['charlotteHeys'];
      case '5b3bf984b07ee9000f21635a':
        return this.palpation['elizabethKnightly'];
      case '5c081b791ad175000f58d221': // Kim Hoffman
        return this.palpation['kimHoffman'];
      case '5c0715573c024f000f745ff4': // John Chan
        return this.palpation['johnChan'];
      case '5c0818411ad175000f58d215': // Kevin Whinery
        return this.palpation['kevinWhinery'];
      case '5b3bf937b07ee9000f216356':
      case '5b185531b59664000fbc2ef2':
        return this.palpation['jamesMetler'];
      case '5b3bf94fb07ee9000f216358':
        return this.palpation['magganSpencer'];
      case '5b6347bafb3bf200198a69b3':
        return this.palpation['isabellaJohnson'];
      case '5c13e8bd9020b900168e5733':
        return this.palpation['donnaWilliams'];
      case '5c100d5170f7c4001650e032':
        return this.palpation['lesiaOldberry'];
      case '5c05b2d2341d8d000f82d093':
        return this.palpation['emaliaDahill'];
      case '5b633eb3fb3bf200198a6991': // javadKhiabani
        return this.palpation['javadBanivand'];
      case '5b3bf9d5b07ee9000f21635e': // Leon Sheldon
        return this.palpation['leonSheldon'];
      case '5bf5cabc398a60000f9b14b9':
        return this.palpation['melisentMccraw'];
      case '5b6346b4fb3bf200198a69a7':
        return this.palpation['martinHarris'];
      case '5b3bfa47b07ee9000f216368':
        return this.palpation['jeremyRamzi'];
      case '5b63481dfb3bf200198a69b5':
        return this.palpation['alexJohnson'];
      case '5b3bfb73b07ee9000f21636c':
        return this.palpation['adrianColeman'];
      case '5b3bfb5eb07ee9000f21636a': // joseph Tsai
        return this.palpation['josephTsai'];
      case '5b3bf88fb07ee9000f216352':
        return this.palpation['davidMason'];
      case '5bfc54aa341d8d000f82cf34':
        return this.palpation['aliciaBolts'];
      case '5b633f62fb3bf200198a6999':
        return this.palpation['claytonTodd'];
      case '5b635830fb3bf200198a69df':
        return this.palpation['lillianGreen'];
      case '61798f41e3c74c00149236ab':
        return this.palpation['brandiGraham'];
      case '5b3bf9efb07ee9000f216360':
        return this.palpation['sarahYang'];
      case '5b634ddffb3bf200198a69bd':
        return this.palpation['angelDickinson'];
      case '5bfdb480341d8d000f82cf95':
        return this.palpation['roseannLever'];
      case '5b3bfdeab07ee9000f21637e':
        return this.palpation['sueChenju'];
      case '5b3bfdc4b07ee9000f21637a':
        return this.palpation['adhaHunt'];
      case '5b634dc8fb3bf200198a69bb':
        return this.palpation['cindyDervis'];
      case '5bfdb385341d8d000f82cf8f':
        return this.palpation['cattyMars'];
      case '5bfd820f341d8d000f82cf73':
        return this.palpation['billClark'];
      case '5b634f30fb3bf200198a69bf': // Alex Sanders
        return this.palpation['alexSanders'];
      case '5c116049ae1dea00125e64d6':
        return this.palpation['adamPeterson'];
      case '5c17f0d39020b900168e58b5':
        return this.palpation['stevenFoster'];
      case '5bf7026a4bfc93000f15d400':
        return this.palpation['kristenMeyer'];
      case '5b633e47fb3bf200198a698f':
        return this.palpation['shadiMaha'];
      case '5b2a84d9b2d69d0019caca78':
        return this.palpation['susanHarrison'];
      case '5bfdb4e3341d8d000f82cf97':
        return this.palpation['pacorroArrellanes'];
      case '5bbfc7a2c05bca000f5e1026': // Joe
        return this.palpation['joeFernandes'];
      case '5bfdb527341d8d000f82cf99':
        return this.palpation['jamesRodriguez'];
      case '5c0816461ad175000f58d20e':
        return this.palpation['jennaMartin'];
      case '5c0817431ad175000f58d210':
        return this.palpation['richardMcklain'];
      case '5c13e2d59020b900168e5731':
        return this.palpation['michaelRose'];
      case '5efe5acec1b27c0011e2aed3':
        return this.palpation['devinAtkins'];
      case '5c17f1209020b900168e58ba':
        return this.palpation['nadiaSolanski'];
      case '5c0818c31ad175000f58d217':
        return this.palpation['josephRodriguez'];
      case '5c08197e1ad175000f58d219':
        return this.palpation['jenniferLawson'];
      case '5b3bfdd8b07ee9000f21637c':
        return this.palpation['johnBloom'];
      case '5c17f16e9020b900168e58bd':
        return this.palpation['michaelJefferson'];
      case '5c081def1ad175000f58d22a':
        return this.palpation['lawrenceClark'];
      case '5b63509efb3bf200198a69c7': // Adeline Yen Mah
        return this.palpation['adelineYenMah'];
      case '5b63507dfb3bf200198a69c5': // John
        return this.palpation['johnGorgin'];
      case '5bf6ff9d12af9b000f3f4c6f':
        return this.palpation['adamXu'];
      case '5bfc9f7a341d8d000f82cf71':
        return this.palpation['arthurKelly'];
      case '5b63469dfb3bf200198a69a5':
        return this.palpation['songZhi'];
      case '5b63504afb3bf200198a69c1':
        return this.palpation['aliAhmad'];
      case '5ea36b3bfa90860012efb1c1': // Amy reed
        return this.palpation['amyReed'];
      case '5b63578dfb3bf200198a69d5': // David
        return this.palpation['davidJones'];
      case '5b3bfd8cb07ee9000f216376':
        return this.palpation['rogerSheperd'];
      case '5bf702ad4bfc93000f15d402': // tomBarber
        return this.palpation['tomBarber'];
      case '5b635816fb3bf200198a69dd': // Harry King
        return this.palpation['harryKing'];
      case '5bf5ef6e398a60000f9b14c4':
        return this.palpation['annaShermin'];
      case '5b634afcfb3bf200198a69b9':
        return this.palpation['ernestStabile'];
      case '5b6350e0fb3bf200198a69c9': // Roy
      case '5b634838fb3bf200198a69b7':
        return this.palpation['johnRay'];
      default:
        return this.palpation['default'];
    }
  }
  getSheet(id) {
    this.pediatric = false;
    switch (id) {
      case '5b3bfd79b07ee9000f216374':
        return this.sprites['stevenLampard'];
      case '5b635891fb3bf200198a69e7':
        return this.sprites['maryJames'];
      case '5e8396bb6d334c0011648462':
        return this.sprites['amrSaleh'];
      case '5ea36ce9fa90860012efb1c3':
        return this.sprites['linaAlAndalusi'];
      case '5a79ff172890d4519eade938':
      case '5ad51a8cb58d7474937e5901':
      case '5cd9d78c3b5b500011ed0d89':
        return this.sprites['lillianMcKay'];
      case '5c0819d31ad175000f58d21b':
        return this.sprites['jessicaAnderson'];
      case '609d98e6cd386400129dbe10':
        return this.sprites['shahrzadDickinson'];
      case '5c13e2d59020b900168e5731':
        return this.sprites['michaelRose'];
      case '5e6fc729aa23d900117c1da8':
        return this.sprites['enricoPAccoro']; // EPA case - Enrico
      case '5c17f1209020b900168e58ba':
        return this.sprites['nadiaSolanski'];
      case '5e7e47ca6d334c001164844c':
        return this.sprites['etienneShanker'];
      case '5c0818c31ad175000f58d217':
        return this.sprites['josephRodriguez'];
      case '5ea36b3bfa90860012efb1c1': // Amy reed
        return this.sprites['amyReed'];
      case '5c13e1749020b900168e572f':
        return this.sprites['lemondPolk'];
      case '5b63469dfb3bf200198a69a5':
        return this.sprites['songZhi'];
      case '5bfdb358341d8d000f82cf8d':
        return this.sprites['johnOak'];
      case '5bfdb429341d8d000f82cf93':
        return this.sprites['andrewEvans'];
      case '5efe5acec1b27c0011e2aed3':
        return this.sprites['devinAtkins'];
      case '5c0816461ad175000f58d20e':
        return this.sprites['jennaMartin'];
      case '5b3bfa1eb07ee9000f216364':
        return this.sprites['georgeHarrison'];
      case '5b635126fb3bf200198a69cf':
        return this.sprites['saraRay'];
      case '5bf6ffea12af9b000f3f4c71':
        return this.sprites['daveMark'];
      case '5b635879fb3bf200198a69e5': // Jerry
        return this.sprites['jerryCarter'];
      case '5b2a84d9b2d69d0019caca78':
      case '5b3c04e80c9a9f000f8d5f85':
        return this.sprites['susanHarrison'];
      case '5b3bfb5eb07ee9000f21636a':
      case '5b185a54b59664000fbc2f06':
        return this.sprites['josephTsai'];
      case '5b3bfba3b07ee9000f216370':
      case '5b185b2bb59664000fbc2f0c':
        return this.sprites['simonRobins'];
      case '5b3bf937b07ee9000f216356':
      case '5b185531b59664000fbc2ef2':
        return this.sprites['jamesMetler'];
      case '5b634706fb3bf200198a69ab': // Marky W.
        return this.sprites['markWarlegan'];
      case '5c081cac1ad175000f58d225':
        return this.sprites['danielaAlcon'];
      case '5b3bfcf7b07ee9000f216372':
      case '5b3bfd050c9a9f000f8d5f82':
        return this.sprites['steveMarshall'];
      case '5b3bf9d5b07ee9000f21635e': // Leon Sheldon
        return this.sprites['leonSheldon'];
      case '5bfdb527341d8d000f82cf99': // Leon Sheldon
        return this.sprites['jamesRodriguez'];
      case '5b3bfb73b07ee9000f21636c':
      case '5b185a7db59664000fbc2f08':
        return this.sprites['adrianColeman'];
      case '5b633ef4fb3bf200198a6993':
        return this.sprites['anthonyBrandon'];
      case '5b6340abfb3bf200198a69a1':
        return this.sprites['albertoDouglas'];
      case '5b634083fb3bf200198a699f':
        return this.sprites['brandonGreen'];
      case '5b3bfdaeb07ee9000f216378':
        return this.sprites['meganSliva'];
      case '5b633f4afb3bf200198a6997':
        return this.sprites['edwinJohnson'];
      case '5b634ddffb3bf200198a69bd':
        return this.sprites['angelDickinson'];
      case '5b3bfd8cb07ee9000f216376':
        return this.sprites['rogerSheperd'];
      case '5bf702ad4bfc93000f15d402':
        return this.sprites['tomBarber'];
      case '5c0817d31ad175000f58d213':
        return this.sprites['kenHo'];
      case '5b3bfdc4b07ee9000f21637a':
        return this.sprites['adhaHunt'];
      case '5b3bf9efb07ee9000f216360':
        return this.sprites['sarahYang'];
      case '5b633e47fb3bf200198a698f':
        return this.sprites['shadiMaha'];
      case '5b3bf88fb07ee9000f216352':
        return this.sprites['davidMason'];
      case '5b6357bbfb3bf200198a69d7':
        return this.sprites['rebeccaRamirez'];
      case '5b3bfdeab07ee9000f21637e':
        return this.sprites['sueChenju'];
      case '5b63513dfb3bf200198a69d1':
        return this.sprites['alexandraBrandon'];
      case '5c13ece49020b900168e5738':
        return this.sprites['andrewParker'];
      case '5be5cdf98dc041000fc4cf51': //
        return this.sprites['amyRichardson'];
      case '5b635110fb3bf200198a69cd': //
        return this.sprites['aliNazar'];
      case '5b63504afb3bf200198a69c1':
        return this.sprites['aliAhmad'];
      case '5bf7026a4bfc93000f15d400':
        return this.sprites['kristenMeyer'];
      case '5b6350f9fb3bf200198a69cb':
        return this.sprites['selbyMartin'];
      case '5b6346b4fb3bf200198a69a7':
        return this.sprites['martinHarris'];
      case '5b634afcfb3bf200198a69b9':
        return this.sprites['ernestStabile'];
      case '5b633f62fb3bf200198a6999':
        return this.sprites['claytonTodd'];
      case '5b634684fb3bf200198a69a3':
        return this.sprites['emilianoParis'];
      case '5bf7007e12af9b000f3f4c74':
        return this.sprites['joshuaJameson'];
      case '5bfdb2c8341d8d000f82cf88':
        return this.sprites['muhanadKattan'];
      case '5bf5ed83398a60000f9b14c0':
        return this.sprites['tomSimpson'];
      case '5bfc9f7a341d8d000f82cf71':
        return this.sprites['arthurKelly'];
      case '5c081c131ad175000f58d223':
        return this.sprites['nazaninPahlevan'];
      case '5bf5cabc398a60000f9b14b9':
        return this.sprites['melisentMccraw']; // MELISENT MCCRAW
      case '5b635830fb3bf200198a69df':
        return this.sprites['lillianGreen'];
      case '61798f41e3c74c00149236ab':
        return this.sprites['brandiGraham'];
      case '5bf5ef6e398a60000f9b14c4':
        return this.sprites['annaShermin'];
      case '5bfc54aa341d8d000f82cf34':
        return this.sprites['aliciaBolts'];

      case '5bfd820f341d8d000f82cf73':
        return this.sprites['billClark'];
      // ALL THE CASES THAT DO NOT HAVE THEIR SPRITESHEETS MADE YET WILL BE EITHER ADRIAN COLEMAN OR LILLIAN.
      // DEFAULT FOR MALE (MUST HAND SET)
      case '5b63578dfb3bf200198a69d5': // David
        return this.sprites['davidJones'];
      case '5b634739fb3bf200198a69ad': // Jack
        return this.sprites['jackRobinson'];
      case '5b3bfdd8b07ee9000f21637c':
        return this.sprites['johnBloom'];
      case '5b6357e3fb3bf200198a69d9': // Eric
        return this.sprites['ericThompson'];
      case '5b63479ffb3bf200198a69b1': //
        return this.sprites['charlotteHeys'];
      case '5b63585efb3bf200198a69e3': // Anthony
        return this.sprites['anthonyBaker'];
      case '5c116049ae1dea00125e64d6':
        return this.sprites['adamPeterson'];
      case '5be5ce288dc041000fc4cf53': // Adam
        return this.sprites['adamBeach'];
      case '5b6347bafb3bf200198a69b3': // Alex
        return this.sprites['isabellaJohnson'];
      case '5b63481dfb3bf200198a69b5': // Alex
        return this.sprites['alexJohnson'];
      case '5c17f0659020b900168e58b1': // Ron Chan Aarav
        return this.sprites['ronChan'];
      case '5b63509efb3bf200198a69c7': // Adeline
        return this.sprites['adelineYenMah'];
      case '5bfdb565341d8d000f82cf9b': // Alex Jones
        return this.sprites['alexJones'];
      case '5b634f30fb3bf200198a69bf': // Alex Sanders
        return this.sprites['alexSanders'];
      case '5c17f0d39020b900168e58b5':
        return this.sprites['stevenFoster'];
      case '5b9af00ca78729000f00d68b': // John Walker
        return this.sprites['johnWalker'];
      case '5b6346f0fb3bf200198a69a9': // Alice Roberts
        return this.sprites['aliceRoberts'];
      case '5b634778fb3bf200198a69af': // Ana Boyt
        return this.sprites['anaBoyt'];
      case '5b635066fb3bf200198a69c3':
        return this.sprites['litaDamokosh'];
      case '5bfc4320341d8d000f82cf19':
        return this.sprites['melissaMoore'];
      case '5b6357fbfb3bf200198a69db':
        return this.sprites['jessicaWood'];
      case '5c081d181ad175000f58d228': // Eva Hollander
        return this.sprites['evaHollander'];
      case '5c081a511ad175000f58d21d': // Malie Iokina
        return this.sprites['malieIokina'];
      case '5bfdb3c2341d8d000f82cf91':
        return this.sprites['dianeJames'];
      case '5bbfca79c05bca000f5e1028':
        return this.sprites['peterWhite']; // peter white
      case '5bf6ff5612af9b000f3f4c6d':
        return this.sprites['akiyamaKyoumi'];
      case '5be48a518dc041000fc4cf32':
        return this.sprites['badruNajat'];
      case '5bf5b7a22d31650015dfeae8':
        return this.sprites['evaSpencer'];
      case '5bfdb315341d8d000f82cf8a':
        return this.sprites['hadiDerkhshani'];
      case '5b3bfb8cb07ee9000f21636e':
        return this.sprites['maryWashington'];
      case '5b635772fb3bf200198a69d3':
        return this.sprites['jamesAnderson'];
      case '5bfdb480341d8d000f82cf95':
        return this.sprites['roseannLever'];
      case '5c081ad81ad175000f58d21f':
        return this.sprites['gracielaBeinvenida'];
      case '5c081b791ad175000f58d221': // Kim Hoffman
        return this.sprites['kimHoffman'];
      case '5b3bf94fb07ee9000f216358':
        return this.sprites['magganSpencer'];
      case '5b633f17fb3bf200198a6995':
        return this.sprites['vanHarrison'];
      case '5b633eb3fb3bf200198a6991':
        return this.sprites['javadBanivand'];
      case '5b3bfa47b07ee9000f216368':
        return this.sprites['jeremyRamzi'];
      case '5b3bf91db07ee9000f216354':
        return this.sprites['larrySimpsons'];
      case '5c0818411ad175000f58d215':
        return this.sprites['kevinWhinery'];
      case '5b3bf984b07ee9000f21635a':
        return this.sprites['elizabethKnightly'];
      case '5c13e8bd9020b900168e5733':
        return this.sprites['donnaWilliams'];
      case '5b633faffb3bf200198a699d':
        return this.sprites['johnWilson'];
      case '5c100d5170f7c4001650e032':
        return this.sprites['lesiaOldberry'];
      case '5b634dc8fb3bf200198a69bb':
        return this.sprites['cindyDervis'];
      case '5bf5ab6f2d31650015dfeace':
        return this.sprites['anyaSimmons'];
      case '5bfdb385341d8d000f82cf8f':
        return this.sprites['cattyMars'];
      case '5bf6ff9d12af9b000f3f4c6f':
        return this.sprites['adamXu'];
      case '5c0715573c024f000f745ff4': // John Chan
        return this.sprites['johnChan'];
      case '5c05b2d2341d8d000f82d093':
        return this.sprites['emaliaDahill'];
      case '5b6350e0fb3bf200198a69c9': // Roy
        return this.sprites['royMendez'];
      case '5b635848fb3bf200198a69e1': // Sean
        return this.sprites['seanCooper'];
      case '5c17f09a9020b900168e58b3': // Jay Garret
        return this.sprites['jayGarret'];
      case '5bfdb4e3341d8d000f82cf97':
        return this.sprites['pacorroArrellanes'];
      case '5bbfc7a2c05bca000f5e1026': // Joe
        return this.sprites['joeFernandes'];
      case '5c08197e1ad175000f58d219':
        return this.sprites['jenniferLawson'];
      case '5b635816fb3bf200198a69dd': // Harry King
        return this.sprites['harryKing'];
      case '5c17f16e9020b900168e58bd':
        return this.sprites['michaelJefferson'];
      case '5c081def1ad175000f58d22a':
        return this.sprites['lawrenceClark'];
      case '5c0817431ad175000f58d210':
        return this.sprites['richardMcklain'];
      case '5b63507dfb3bf200198a69c5': // John
        return this.sprites['johnGorgin'];
      case '5b634838fb3bf200198a69b7':
      case 'defaultMale': // Copy Cases to build temp men
        return this.sprites['johnRay'];
      // DEFAULT FOR FEMALE (NO CUSTOMIZATION NEEDED)
      default:
        return this.sprites['default'];
    }
  }

  getViews(id) {
    this.pediatric = false;
    switch (id) {
      case '5b3bfd79b07ee9000f216374':
        return this.views['stevenLampard'];
      case '5b3bfdaeb07ee9000f216378':
        return this.views['meganSliva'];
      case '5b635891fb3bf200198a69e7':
        return this.views['maryJames'];
      case '5a79ff172890d4519eade938':
      case '5ad51a8cb58d7474937e5901':
      case '5cd9d78c3b5b500011ed0d89':
        return this.views['lillianMcKay'];
      case '5c0819d31ad175000f58d21b':
        return this.views['jessicaAnderson'];
      case '609d98e6cd386400129dbe10':
        return this.views['shahrzadDickinson'];
      case '5e6fc729aa23d900117c1da8':
        return this.views['enricoPAccoro']; // EPA case - Enrico
      case '5e8396bb6d334c0011648462':
        return this.views['amrSaleh'];
      case '5ea36ce9fa90860012efb1c3':
        return this.views['linaAlAndalusi'];
      case '5c17f1209020b900168e58ba':
        return this.views['nadiaSolanski'];
      case '5e7e47ca6d334c001164844c':
        return this.views['etienneShanker'];
      case '5c13e2d59020b900168e5731':
        return this.views['michaelRose'];
      case '5ea36b3bfa90860012efb1c1': // Amy reed
        return this.views['amyReed'];
      case '5c0818c31ad175000f58d217':
        return this.views['josephRodriguez'];
      case '5c081cac1ad175000f58d225':
        return this.views['danielaAlcon'];
      case '5c13e1749020b900168e572f':
        return this.views['lemondPolk'];
      case '5b63469dfb3bf200198a69a5':
        return this.views['songZhi'];
      case '5bfdb429341d8d000f82cf93':
        return this.views['andrewEvans'];
      case '5bfdb358341d8d000f82cf8d':
        return this.views['johnOak'];
      case '5c05b2d2341d8d000f82d093':
        return this.views['emaliaDahill'];
      case '5b3bf9d5b07ee9000f21635e': // Leon Sheldon
        return this.views['leonSheldon'];
      case '5b2a84d9b2d69d0019caca78':
      case '5b3c04e80c9a9f000f8d5f85':
        return this.views['susanHarrison'];
      case '5bbfca79c05bca000f5e1028': // peter white
        return this.views['peterWhite'];
      case '5c0816461ad175000f58d20e':
        return this.views['jennaMartin'];
      case '5b3bfdd8b07ee9000f21637c':
        return this.views['johnBloom'];
      case '5b6347bafb3bf200198a69b3':
        return this.views['isabellaJohnson'];
      case '5b3bfb5eb07ee9000f21636a':
      case '5b185a54b59664000fbc2f06':
        return this.views['josephTsai'];
      case '5c116049ae1dea00125e64d6':
        return this.views['adamPeterson'];
      case '5bf7026a4bfc93000f15d400':
        return this.views['kristenMeyer'];
      case '5b3bfba3b07ee9000f216370':
      case '5b185b2bb59664000fbc2f0c':
        return this.views['simonRobins'];
      case '5bf5b7a22d31650015dfeae8':
        return this.views['evaSpencer'];
      case '5efe5acec1b27c0011e2aed3':
        return this.views['devinAtkins'];
      case '5b3bf937b07ee9000f216356':
      case '5b185531b59664000fbc2ef2':
        return this.views['jamesMetler'];
      case '5b635126fb3bf200198a69cf':
        return this.views['saraRay'];
      case '5bf5ef6e398a60000f9b14c4':
        return this.views['annaShermin'];
      case '5b6346b4fb3bf200198a69a7':
        return this.views['martinHarris'];
      case '5b3bfcf7b07ee9000f216372':
      case '5b3bfd050c9a9f000f8d5f82':
        return this.views['steveMarshall'];
      case '5b634ddffb3bf200198a69bd':
        return this.views['angelDickinson'];
      case '5bfdb480341d8d000f82cf95':
        return this.views['roseannLever'];
      case '5b3bf984b07ee9000f21635a':
        return this.views['elizabethKnightly'];
      case '5bfdb3c2341d8d000f82cf91':
        return this.views['dianeJames'];
      case '5b634dc8fb3bf200198a69bb':
        return this.views['cindyDervis'];
      case '5c0818411ad175000f58d215':
        return this.views['kevinWhinery'];
      case '5bf5ab6f2d31650015dfeace':
        return this.views['anyaSimmons'];
      case '5b3bfb73b07ee9000f21636c':
      case '5b185a7db59664000fbc2f08':
        return this.views['adrianColeman'];
      case '5b633faffb3bf200198a699d':
        return this.views['johnWilson'];
      case '5b3bf88fb07ee9000f216352':
        return this.views['davidMason'];
      case '5c13e8bd9020b900168e5733':
        return this.views['donnaWilliams'];
      case '5bfd820f341d8d000f82cf73':
        return this.views['billClark'];
      // ALL THE CASES THAT DO NOT HAVE THEIR SPRITESHEETS MADE YET WILL BE EITHER ADRIAN COLEMAN OR LILLIAN.
      // DEFAULT FOR MALE (MUST HAND SET)
      case '5b633eb3fb3bf200198a6991':
        return this.views['javadBanivand'];
      case '5bf702ad4bfc93000f15d402':
        return this.views['tomBarber'];
      case '5b3bfa47b07ee9000f216368':
        return this.views['jeremyRamzi'];
      case '5b633ef4fb3bf200198a6993':
        return this.views['anthonyBrandon'];
      case '5b6340abfb3bf200198a69a1':
        return this.views['albertoDouglas'];
      case '5c13ece49020b900168e5738':
        return this.views['andrewParker'];
      case '5b634083fb3bf200198a699f':
        return this.views['brandonGreen'];
      case '5b633f4afb3bf200198a6997':
        return this.views['edwinJohnson'];
      case '5b3bfd8cb07ee9000f216376':
        return this.views['rogerSheperd'];
      case '5c0817d31ad175000f58d213':
        return this.views['kenHo'];
      case '5b634838fb3bf200198a69b7':
        return this.views['johnRay'];
      case '5b3bfdc4b07ee9000f21637a':
        return this.views['adhaHunt'];
      case '5b3bf9efb07ee9000f216360':
        return this.views['sarahYang'];
      case '5b633e47fb3bf200198a698f':
        return this.views['shadiMaha'];
      case '5b6357bbfb3bf200198a69d7':
        return this.views['rebeccaRamirez'];
      case '5b3bfdeab07ee9000f21637e':
        return this.views['sueChenju'];
      case '5b63513dfb3bf200198a69d1':
        return this.views['alexandraBrandon'];
      case '5b63504afb3bf200198a69c1':
        return this.views['aliAhmad'];
      case '5b6350f9fb3bf200198a69cb':
        return this.views['selbyMartin'];
      case '5b634afcfb3bf200198a69b9':
        return this.views['ernestStabile'];
      case '5b633f62fb3bf200198a6999':
        return this.views['claytonTodd'];
      case '5be5cdf98dc041000fc4cf51': //
        return this.views['amyRichardson'];
      case '5b635110fb3bf200198a69cd': //
        return this.views['aliNazar'];
      case '5b63578dfb3bf200198a69d5': // David
        return this.views['davidJones'];
      case '5b634739fb3bf200198a69ad': // Jack
        return this.views['jackRobinson'];
      case '5b6357e3fb3bf200198a69d9': // Eric
        return this.views['ericThompson'];
      case '5b63585efb3bf200198a69e3': // Anthony
        return this.views['anthonyBaker'];
      case '5be5ce288dc041000fc4cf53': // Adam
        return this.views['adamBeach'];
      case '5b63481dfb3bf200198a69b5': // Alex
        return this.views['alexJohnson'];
      case '5c17f0659020b900168e58b1': // Ron Chan Aarav
        return this.views['ronChan'];
      case '5b63509efb3bf200198a69c7': // Adeline Yen Mah
        return this.views['adelineYenMah'];
      case '5b6346f0fb3bf200198a69a9': // Alice Roberts
        return this.views['aliceRoberts'];
      case '5bfdb565341d8d000f82cf9b': // Alex Jones
        return this.views['alexJones'];
      case '5b634f30fb3bf200198a69bf': // Alex Sanders
        return this.views['alexSanders'];
      case '5c17f0d39020b900168e58b5':
        return this.views['stevenFoster'];
      case '5b9af00ca78729000f00d68b': // John Walker
        return this.views['johnWalker'];
      case '5b634778fb3bf200198a69af': // Ana Boyt
        return this.views['anaBoyt'];
      case '5b635066fb3bf200198a69c3':
        return this.views['litaDamokosh'];
      case '5bfc4320341d8d000f82cf19':
        return this.views['melissaMoore'];
      case '5b6357fbfb3bf200198a69db':
        return this.views['jessicaWood'];
      case '5c081d181ad175000f58d228': // Eva Hollander
        return this.views['evaHollander'];
      case '5c081a511ad175000f58d21d': // Malie Iokina
        return this.views['malieIokina'];
      case '5bf6ff5612af9b000f3f4c6d':
        return this.views['akiyamaKyoumi'];
      case '5be48a518dc041000fc4cf32':
        return this.views['badruNajat'];
      case '5bfdb315341d8d000f82cf8a':
        return this.views['hadiDerkhshani'];
      case '5bf6ff9d12af9b000f3f4c6f':
        return this.views['adamXu'];
      case '5b3bfb8cb07ee9000f21636e':
        return this.views['maryWashington'];
      case '5b635772fb3bf200198a69d3':
        return this.views['jamesAnderson'];
      case '5c081ad81ad175000f58d21f':
        return this.views['gracielaBeinvenida'];
      case '5bfdb385341d8d000f82cf8f':
        return this.views['cattyMars'];
      case '5b3bf94fb07ee9000f216358':
        return this.views['magganSpencer'];
      case '5c081b791ad175000f58d221': // Kim Hoffman
        return this.views['kimHoffman'];
      case '5b633f17fb3bf200198a6995':
        return this.views['vanHarrison'];
      case '5b634684fb3bf200198a69a3':
        return this.views['emilianoParis'];
      case '5bf7007e12af9b000f3f4c74':
        return this.views['joshuaJameson'];
      case '5bfdb2c8341d8d000f82cf88':
        return this.views['muhanadKattan'];
      case '5bf5ed83398a60000f9b14c0':
        return this.views['tomSimpson'];
      case '5bfc9f7a341d8d000f82cf71':
        return this.views['arthurKelly'];
      case '5c081c131ad175000f58d223':
        return this.views['nazaninPahlevan'];
      case '5bf5cabc398a60000f9b14b9':
        return this.views['melisentMccraw']; // MELISENT
      case '5b635830fb3bf200198a69df':
        return this.views['lillianGreen'];
      case '61798f41e3c74c00149236ab':
        return this.views['brandiGraham'];
      case '5b3bf91db07ee9000f216354':
        return this.views['larrySimpsons'];
      case '5c0715573c024f000f745ff4': // John Chan
        return this.views['johnChan'];
      case '5c100d5170f7c4001650e032':
        return this.views['lesiaOldberry'];
      case '5c0817431ad175000f58d210':
        return this.views['richardMcklain'];
      case '5b63479ffb3bf200198a69b1': //
        return this.views['charlotteHeys'];
      case '5bfc54aa341d8d000f82cf34':
        return this.views['aliciaBolts'];
      case '5bf6ffea12af9b000f3f4c71':
        return this.views['daveMark'];
      case '5b635879fb3bf200198a69e5': // Jerry
        return this.views['jerryCarter'];
      case '5b634706fb3bf200198a69ab': // Marky W.
        return this.views['markWarlegan'];
      case '5b6350e0fb3bf200198a69c9': // Roy
        return this.views['royMendez'];
      case '5b635848fb3bf200198a69e1': // Sean
        return this.views['seanCooper'];
      case '5c17f09a9020b900168e58b3': // Jay Garret
        return this.views['jayGarret'];
      case '5bfdb4e3341d8d000f82cf97':
        return this.views['pacorroArrellanes'];
      case '5bbfc7a2c05bca000f5e1026': // Joe
        return this.views['joeFernandes'];
      case '5c08197e1ad175000f58d219':
        return this.views['jenniferLawson'];
      case '5c17f16e9020b900168e58bd':
        return this.views['michaelJefferson'];
      case '5b635816fb3bf200198a69dd': // Harry King
        return this.views['harryKing'];
      case '5c081def1ad175000f58d22a':
        return this.views['lawrenceClark'];
      case '5bfdb527341d8d000f82cf99': // Leon Sheldon
        return this.views['jamesRodriguez'];
      case '5b63507dfb3bf200198a69c5': // John
        return this.views['johnGorgin'];
      // DEFAULT FOR FEMALE (NO CUSTOMIZATION NEEDED)
      default:
        return this.views['default'];
    }
  }
}
