<div>
  <p class="text-description text-color-secondary">CyberPatient works best on...</p>
  <button (click)="dismiss()" class="snackbar-close-button" mat-icon-button aria-label="close">
    <mat-icon>clear</mat-icon>
  </button>
  <div>
    <a mat-button href="https://www.google.com/intl/en_ca/chrome/" target="_blank"
      ><img class="browser-logo" src="assets/icons/chrome.svg" />Chrome</a
    >
    <a mat-button href="https://www.mozilla.org/en-CA/firefox/products/" target="_blank"
      ><img class="browser-logo" src="assets/icons/firefox.svg" />Firefox</a
    >
    <a mat-button href="https://www.microsoft.com/en-us/edge" target="_blank"
      ><img class="browser-logo" src="assets/icons/edge.svg" />Edge</a
    >
  </div>
</div>
