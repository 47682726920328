import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

export interface LabElement {
  test: string;
  value: string;
  standard: string;
  unit: string;
}

@Component({
  selector: 'app-nursing-dialog',
  styleUrls: ['./nursing-dialog.component.scss'],
  templateUrl: './nursing-dialog.component.html',
})
export class NursingDialogComponent {
  labDataSource;
  displayedColumns: string[] = ['test', 'value', 'standard', 'unit'];

  constructor(public dialogRef: MatDialogRef<NursingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.labData) {
      this.labDataSource = new MatTableDataSource<LabElement>(data.labData);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
