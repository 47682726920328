import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SimpleGlobal
} from 'ng2-simple-global';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  error;
  path;
  errorName;
  type;
  routeTo;
  totalError = 3;
  skillset;
  errorsLeft;
  navigateTo;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, private router: Router, private sg: SimpleGlobal) { }


  ngOnInit() {
    this.error = this.data.error;
    this.errorsLeft = (this.totalError - Number(this.error));
    this.errorName = this.data.name;
    this.type = this.data.type;
    // this.navigateTo = "/".concat(this.data.routeTo);
    this.skillset = this.sg['skillset'];
    if (this.type === 'dberror') {
      this.routeTo = this.data.routeTo;
    }
  }

  returnToDashboard() {
    this.router.navigate(['/'], { skipLocationChange: true });
  }
  returnToSettings() {
    this.router.navigate(['/account'], { skipLocationChange: false });
  }
  returnToLogin() {
    this.router.navigate(['/login'], { skipLocationChange: false });
  }
  goToBilling() {
    this.router.navigate(['/billing'], { skipLocationChange: true });


  }
  dbErrorRoute() {
    this.router.navigate([this.routeTo], { skipLocationChange: false });
  }



}
