<section id="content-wrap" class="row">
  <div class="hero flex">
    <div class="flex-1">
      <div class="login-form-container">
        <div class="form-header">
          <h1>Log In</h1>
        </div>
        <div class="form-subheader">
          <!-- <p><a routerLink='/recover' routerLinkActive="active">Create a new account</a></p> -->
        </div>
        <form [formGroup]="loginform" class="login-form" (ngSubmit)="login()">
          <div class="flex-row">
            <div class="form-element">
              <label> Email </label>
              <input id="email" type="email" class="lf--input" formControlName="username" username="ngModel" required />
              <div fxLayout="row-reverse" class="resend-email">
                <a routerLink="/resend" routerLinkActive="active">Resend activation email</a>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="form-element">
              <label> Password </label>
              <input
                id="password"
                autocomplete="current-password"
                type="password"
                class="lf--input"
                formControlName="password"
                password="ngModel"
                required
              />
            </div>
          </div>
          <div class="flex submit">
            <button
              type="submit"
              [disabled]="!loginform.valid"
              value="LOGIN"
              class="action mat-button mat-raised-button mat-primary"
            >
              Log In
            </button>
          </div>
        </form>
        <a routerLink="/recover" routerLinkActive="active" class="forgot-password">Forgot password?</a>

        <p>
          Don't have an account?
          <a routerLink="/register" routerLinkActive="active" class="forgot-password">Register here</a>
        </p>

        <!-- <button (click)="auth0Login()" class="auth0-button">
                  <img
                    class="icon"
                    src="/assets/icons/auth0_logo.png"
                    style="height: 25px; width: 25px;"
                  />Auth0 Log In
                </button> -->

        <p class="copyright">© {{ currYear }} All Rights Reserved to Interactive Health International.</p>
      </div>
    </div>
    <div class="flex-1 flex side-image">
      <img src="assets/img/app/login/Login_side.png" class="hero__image" />
    </div>
    <ngx-loading [show]="loadingAndSaving"></ngx-loading>
  </div>
</section>
