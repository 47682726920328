import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
@Component({
  selector: 'app-browser-suggestion-snackbar',
  templateUrl: './browser-suggestion-snackbar.component.html',
  styleUrls: ['./browser-suggestion-snackbar.component.scss'],
})
export class BrowserSuggestionSnackbarComponent {
  constructor(private _snackRef: MatSnackBarRef<BrowserSuggestionSnackbarComponent>) {}

  dismiss() {
    this._snackRef.dismiss();
  }
}
