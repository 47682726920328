import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { camelCase } from 'lodash';

@Component({
  selector: 'app-csv-uploader',
  templateUrl: './csv-uploader.component.html',
  styleUrls: ['./csv-uploader.component.scss']
})
export class CsvUploaderComponent implements OnInit {
  @Output() onUpload = new EventEmitter<{}[]>();
  @ViewChild('uploadInput', { static: true }) uploadInputRef: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  handleOnUpload(files: FileList) {
    if (files && files.length > 0) {
      const file = files.item(0);
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const content = reader.result as string;
        this.onUpload.emit(this.convertCsvToObj(content));
      };
    }
  }

  onUploadBtnClick() {
    this.uploadInputRef.nativeElement.click();
  }

  private convertCsvToObj(csv: string) {
    const lines = csv.split('\n');
    const headers = lines[0].split(',').map(camelCase);
    const results = lines.map((line, index) => {
      if (index === 0) {
        return null;
      }

      const values = line.split(',');
      return headers.reduce((obj, header, i) => ({
        ...obj,
        [header]: values[i].trim(),
      }), {});
    });

    return results.filter((result) => !!result);
  }
}
