/* eslint no-use-before-define: 0 */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelectChange } from '@angular/material/select';
import { MatSliderChange } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleGlobal } from 'ng2-simple-global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { CheckPoint, CheckPointMessage } from './_models';
import { AuthenticationService, FetchAssignmentDataService, UserService } from './_services';
import { InstanceNameService } from './_services/index';
import { UpdateCheckpointMessage } from './actions/checkpoint.action';
import { DebugService } from './debug.service';
import { PopUpService } from './pop-ups/pop-up.service';
import { RouteEventsService } from './route-events.service';
import { LoadService } from './theme/loading/load-service.service';
import { MobileDialogComponent } from './mobile-dialog/mobile-dialog.component';
import { BrowserSuggestionSnackbarComponent } from './browser-suggestion-snackbar/browser-suggestion-snackbar.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cyberpatient',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: 180,
        })
      ),
      state(
        'out',
        style({
          width: 50,
        })
      ),
      transition('in => out', animate('400ms')),
      transition('out => in', animate('400ms')),
    ]),
    trigger('c-slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(100px, 0, 0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('bc-slideInOut', [
      state(
        'in',
        style({
          left: 50,
        })
      ),
      state(
        'out',
        style({
          left: 100,
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('patientChart', [
      state(
        'in',
        style({
          transform: 'translateY(0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(-200%)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterContentInit {
  loadAudio = false;
  menuState = 'in';
  contentState = 'in';
  public width = 180;
  role;
  patientChartToggle = 'out';
  pos1;
  pos4;
  elmnt;
  showFiller = false;
  debugClosed = false;
  placeholder;
  position = { x: 100, y: 500 };
  draggable = true;
  debug = false;
  openCase;
  subscription: Subscription;
  onboarding: any = {};
  loadingAnswers: boolean;
  hidelocal: boolean;
  arrowButtonEnable: boolean;
  value = 50;
  checkPointComponents: Set<string>;

  checkPointInstances: Set<string>;
  checkpoints: CheckPoint[];
  selectedInstance: string;
  selectedComponent: string;
  debugData: Record<string, unknown>;

  constructor(
    private popUpService: PopUpService,
    private matIconRegistry: MatIconRegistry,
    private deviceService: DeviceDetectorService,
    private _snackBar: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private sg: SimpleGlobal,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    public loader: LoadService,
    private store: Store,
    private fetchAssignmentDataService: FetchAssignmentDataService,
    private routeEventsService: RouteEventsService,
    private debugService: DebugService,
    private nameService: InstanceNameService,
    private authService: AuthenticationService,
    private userService: UserService
  ) {
    // SET THE ICONS FOR THE APP
    this.matIconRegistry.addSvgIcon(
      'clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/Clock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'clockDark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/ClockDark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/Search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'peer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/peer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'peerDark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/peerDark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'control_point-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/control_point-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'local_hotel-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/local_hotel-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_1-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_1-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_2-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_2-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_3-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_3-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_4-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_4-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_5-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_5-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_6-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_6-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_7-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_7-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_8-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_8-24px.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_9-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/app/filter_9-24px.svg')
    );
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const caseOpen = event.url.slice(1, 11);
        if (caseOpen === 'assignment' && this.sg['session_id'] != null) {
          this.patientChartToggle = 'in';
        } else {
          this.patientChartToggle = 'out';
        }
      }
      if (event instanceof NavigationStart) {
        this.debugService.updateDebugData('');
      }
    });

    this.authService.init();
  }

  setLoadAudio() {
    this.loader.loadAudioStatus.next(this.loadAudio);
  }

  loadCheckPoints() {
    if (this.sg['case_id'] === undefined || this.sg['case_id'] === null) {
      return;
    } else {
      this.checkpoints = null;
      this.checkPointInstances = null;

      this.fetchAssignmentDataService.getCheckPoints().subscribe((result: CheckPointMessage) => {
        this.checkpoints = result.message.checkpoints;
        this.checkPointInstances = new Set(this.checkpoints.map((checkpoint) => checkpoint.instance));
        this.store.dispatch([new UpdateCheckpointMessage(result)]);
      });
    }
  }

  instanceChange(e: MatSelectChange): void {
    this.arrowButtonEnable = true;
    this.selectedInstance = e.value as string;
    const filteredCheckPoints = this.checkpoints.filter((checkpoint) => checkpoint.instance === e.value);
    this.checkPointComponents = new Set(filteredCheckPoints.map((checkpoint) => checkpoint.component));
  }

  comChanged(_e: MatSelectChange): void {
    this.arrowButtonEnable = true;
  }

  ngAfterContentInit(): void {
    this.checkBrowerCompatability();
    //Function needs to run after survicate script is loaded which is controlled on survicate
    setTimeout(this.openSurvicateHelp, 5000);
    this.loadCheckPoints();
  }

  formatLabel(value: number) {
    return value;
  }

  updateSliderValue(_event: MatSliderChange) {
    this.debugService.updateAlphaDebug(this.value);
  }

  ngOnInit() {
    this.debug = environment.debug;
    this.debugService.updateShowHideDebug(this.debug);
    this.debugService.updateAlphaDebug(this.value);
    this.checkUser();
    this.debugService.getLoadingFlag().subscribe((f) => (this.loadingAnswers = f));
    this.debugService.getDebugData().subscribe((data) => (this.debugData = data));
    this.debugService.getshowDebugWindow().subscribe((f) => (this.debug = f));
    this.debugService.getLocalShowHideDebug().subscribe((t) => (this.hidelocal = t));
  }

  checkUser() {
    const user = localStorage.getItem('currentUser');
    if (user === null) {
      this.menuState = 'out';
      this.contentState = 'out';
    } else {
      this.menuState = 'in';
      this.contentState = 'in';
    }
  }

  checkBrowerCompatability() {
    const recommendedBrowers = ['Chrome', 'MS-Edge-Chromium', 'Firefox'];
    if (this.deviceService.isMobile()) {
      this.dialog.open(MobileDialogComponent);
    } else if (!recommendedBrowers.includes(this.deviceService.getDeviceInfo().browser)) {
      this._snackBar.openFromComponent(BrowserSuggestionSnackbarComponent);
    }
  }

  debugInstance() {
    this.sg['instance_id'] = this.selectedInstance;
    this.sg['instance_name'] = this.nameService.getInstance();
    this.arrowButtonEnable = false;
    if (this.placeholder === this.selectedComponent) {
      this.router.navigateByUrl('/');
    } else {
      this.placeholder = this.selectedComponent;
    }
    this.sg['instance_id'] = this.selectedInstance;
    const path = '/assignment/'.concat(this.selectedComponent);
    if (this.selectedComponent === 'generalOrder') {
      this.sg['mistake_counter'] = {
        activity: 0,
        diet: 0,
        monitoring: 0,
        respiratoryMonitoring: 0,
        outputMonitoring: 0,
      };
    } else {
      this.sg['mistake_counter'] = 0;
    }
    this.router.navigate([path], { skipLocationChange: true });
  }

  private openSurvicateHelp() {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    (<any>window)._sva = (<any>window)._sva || {};
    const _sva = (<any>window)._sva;
    _sva.disableTargeting = true;

    window.addEventListener('SurvicateReady', () => {
      _sva.destroyVisitor();
      _sva.showSurvey(environment.helpSurveyId, { forceDisplay: true });
    });
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  }
}
