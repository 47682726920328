import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private loadingdebugData$ = new Subject<boolean>();
  private showDebugWindow$ = new Subject<boolean>();
  private showLocalDebug$ = new Subject<boolean>();
  private alphaDebug$ = new Subject<number>();
  private debugData$ = new Subject<Record<string, unknown>>();

  updateDebugData(data) {
    this.loadingdebugData$.next(true);
    this.debugData$.next(data);
    this.loadingdebugData$.next(false);
  }
  updateAlphaDebug(val: number) {
    this.alphaDebug$.next(val);
  }

  updateShowHideDebug(flag: boolean) {
    this.showDebugWindow$.next(flag);
  }

  updateLocalShowHideDebug(flag: boolean) {
    this.showLocalDebug$.next(flag);
  }
  getalphaDebug(): Observable<number> {
    return this.alphaDebug$.asObservable();
  }

  getLocalShowHideDebug(): Observable<boolean> {
    return this.showLocalDebug$.asObservable();
  }

  getshowDebugWindow(): Observable<boolean> {
    return this.showDebugWindow$.asObservable();
  }

  getDebugData(): Observable<Record<string, unknown>> {
    return this.debugData$.asObservable();
  }

  getLoadingFlag(): Observable<boolean> {
    return this.loadingdebugData$.asObservable();
  }
}
