export enum MEMBERSHIP {
  PREMIUM = 'premium',
  FREE = 'free',
}

export interface UserOnboarding {
  skillset: boolean;
  dashboard: boolean;
}

export enum InstitutionRole {
  PROFESSOR = 'professor',
  ADMIN = 'admin',
  OWNER = 'owner',
  STUDENT = 'student',
}

export interface Institution {
  name: string;
  _id: string;
  paid: boolean;
}

export interface InstitutionMember {
  _id?: string;
  institution: Institution;
  memberId: string;
  institutionRole: string;
}

export interface User {
  email: string;
  admin: boolean;
  firstName: string;
  lastName: string;
  institution: string;
  institutionId: string;
  institutionRole: InstitutionRole;
  institutions?: InstitutionMember[];
  selectedInstitutionId: string;
  permissions: string;
  role: string;
  skillset: string;
  membership: MEMBERSHIP;
  occupation: string;
  country: string;
  onboarding?: UserOnboarding;
  memberId?: string;
  suUser?: string;
  _id?: string;
  payment_valid_until?: any;
  accountStatus?: any;
}
