<mat-dialog-content class="accent-color dialogue-content blood-dialogue">
  <div class="pop-up mat-typography">
    <div class="row">
      <div class="large-12 equalize outer-card">
        <div class="description">
          <div class="">
            <p style="text-align:center; display: block; margin: 0 auto;">
              {{h1text}}
            </p>
          </div>
        </div>
        <section class="response medication inputs blood">
          <p style="text-align:center; margin-top: 20px;">
            <span class="quick">{{h3text}}</span>
            <br>
            {{h5text}}
          </p>
        </section>
        <section class="actions">
          <button (click)="close()" class="action mat-button mat-raised-button mat-primary success">{{btnNo}}
          </button>
          <button (click)="resume()" class="action mat-button mat-raised-button mat-primary success">{{btnYes}}
          </button>
        </section>
      </div>
    </div>
  </div>
</mat-dialog-content>
