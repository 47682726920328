import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../_models';
import { Course } from '../../lms/interfaces/course';

@Component({
  selector: 'app-course-header',
  templateUrl: './course-header.component.html',
  styleUrls: ['./course-header.component.scss'],
})
export class CourseHeaderComponent {
  @Input() student: User;
  @Input() course: Course;
  @Output() back = new EventEmitter();
  public skillLevel: number;
  public requiredPassingScore: number;

  constructor(private _location: Location) {}

  displayRequiredPassingScore() {
    this.requiredPassingScore = this.course.requiredPassingPercent;
  }

  displaySkillLevel() {
    if (!this.course?.recommendationLevel) {
      return;
    }
    if (this.course.recommendationLevel === 'HIST') {
      return (this.skillLevel = 1);
    }
    if (this.course.recommendationLevel === 'HTPE') {
      return (this.skillLevel = 2);
    }
    if (this.course.recommendationLevel === 'NOMR') {
      return (this.skillLevel = 3);
    }
    if (this.course.recommendationLevel === 'FULL') {
      return (this.skillLevel = 4);
    }
  }
  backClicked() {
    if (this.back.observers.length > 0) {
      this.back.emit();
    } else {
      this._location.back();
    }
  }
}
