<div style="text-align: center;"><h1 mat-dialog-title>Before you continue...</h1>
<mat-dialog-content><mat-card style="margin: 10px;"><p class="top">Are you sure?</p><p class="middle">You haven't submitted anything for this step. <br>
  <p class="bottom">
    Please note that some patients may not require input on all steps.
  </p>
</mat-card>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close color="warn" mat-raised-button>No</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true" style="margin-left: auto;" mat-raised-button color="primary" >Yes</button>
</mat-dialog-actions>
</div>
