import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TutorialStepDirective } from './directives/tutorial-step.directive';
import { TutorialsService } from './tutorials.service';

@NgModule({
  declarations: [TutorialStepDirective],
  imports: [
    CommonModule,
    MatButtonModule,
  ],
  providers: [TutorialsService],
  exports: [
    TutorialStepDirective
  ]
})
export class TutorialsModule {
}
