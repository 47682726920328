import { Injectable } from '@angular/core';
import {SimpleGlobal} from 'ng2-simple-global';

@Injectable({
    providedIn: 'root',
  })
export class BloodPressureTestService {

  constructor(private sg: SimpleGlobal) { }
  id = this.sg['case_id'];
//  Window = $window;
  FrameRate = 30;
  Container = new createjs.Container();
  ShowSystolic = false;
  ShowDiastolic = false;
  stageheight = 616;
  stagewidth = 600;
  CuffPressure = 0;
  patientimg;
  Scale;
  Cuff;
  Pump;
  Valve;
  Interval = null;
  ReadyToMeasure = false;
  IsVisible = false;
  tickSound = [
    { id: "bloodPressure.marker", src: "./assets/default/physical/Props/BloodPressure/blood-pressure.mp3" },
  ];
  SoundInstances = [];
  Component = new createjs.Container();
  Background = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/Background.png');
  patient;
  Stethoscope = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/Stethoscope.png');
  PressureIndicator = new createjs.Shape();
  GraduationMarks = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/GraduationMarks.png');

  SystolicPressure = 120;
  DiastolicPressure = 60;

  Blocker = new createjs.Shape();

        getContainer = function() {
            return this.Container;
        };

        init(bloodOver, bloodUnder){
            this.SystolicPressure = bloodOver;
            this.DiastolicPressure = bloodUnder;
        this.patientimg = "./assets/patients/" + this.sg['case_id'] + "/physical-exam/images/"+ this.sg["instance_id"] +"/bloodpressure.png";
          this.patient = new createjs.Bitmap(this.patientimg);
     //   createjs.Sound.registerSounds(this.tickSound);
     //     this.SoundInstances['marker'] = createjs.Sound.createInstance('bloodPressure.marker');
//console.log("Testing sound for Blood Pressure", this.SoundInstances['marker'])
            this.Stethoscope.y = 393;

            // Pump Sprite
            var pumpAnimations = {
                framerate: this.FrameRate,
                images: ['./assets/default/physical/Props/BloodPressure/Pump.png'],
                frames: {
                    width: 175,
                    height: 105,
                    count: 3
                }
            };

            this.Pump = new createjs.Sprite(new createjs.SpriteSheet(pumpAnimations));

            this.Pump.x = 507.6;
            this.Pump.y = 553;

            // Valve Sprite
            var valveAnimations = {
                framerate: this.FrameRate,
                images: ['./assets/default/physical/Props/BloodPressure/Valve.png'],
                frames: {
                    width: 52,
                    height: 61,
                    count: 2
                }
            };

            this.Valve = new createjs.Sprite(new createjs.SpriteSheet(valveAnimations));
            this.Valve.x = 468;
            this.Valve.y = 575;

            // Cuff Sprite
            var cuffAnimations = {
                framerate: this.FrameRate,
                images: ['./assets/default/physical/Props/BloodPressure/Sleeve.png'],
                frames: {
                    width: 198,
                    height: 305,
                    count: 11
                }
            };

            this.Cuff = new createjs.Sprite(new createjs.SpriteSheet(cuffAnimations));
            this.Cuff.x = 16;
            this.Cuff.y = 104.05;

            // ----------------------------------

            this.Blocker.graphics.beginFill('#000')
                .rect(0, 0, 1024, 1024);
            this.Blocker.alpha = .8;


            this.PressureIndicator.x = 387;
            this.PressureIndicator.y = 654;


            this.GraduationMarks.x = 385;
            this.GraduationMarks.y = 40;


            this.Component.x = 100;   //1024 is the width of the canvas
            this.Component.addChild(this.Background);
            this.Component.addChild(this.patient);
            this.Component.addChild(this.Stethoscope);
            this.Component.addChild(this.Pump);
            this.Component.addChild(this.Valve);
            this.Component.addChild(this.Cuff);
            this.Component.addChild(this.PressureIndicator);
            this.Component.addChild(this.GraduationMarks);

            this.Container.addChild(this.Blocker);
            this.Container.addChild(this.Component);
            this.Container.visible = false;

            // Event listeners
            var that = this;


                this.Pump.addEventListener('mouseover', function (e) { return that.Pump_MouseOver(e); });
                this.Pump.addEventListener('mouseout', function (e) { return that.Pump_MouseOut(e); });
                this.Valve.addEventListener('mouseover', function (e) { return that.Valve_MouseOver(e); });
                this.Valve.addEventListener('mouseout', function (e) { return that.Valve_MouseOut(e); });


            this.Pump.addEventListener('mousedown', function (e) { that.Pump_MouseDown(e); });
            this.Valve.addEventListener('click', function (e) { return that.Valve_Click(e); });
            this.Pump.addEventListener('click', function (e) { that.Pump_MouseUp(e); });
        };

        Show() {

            this.Scale = 1;

            this.Blocker.graphics.clear();
            this.Blocker.graphics.beginFill('#000')
                .rect(0, 0, this.stagewidth, this.stageheight);
            this.Blocker.alpha = .8;

            // Setting the component's dimensions
            var padding = 0;
            var heightOfPaddedCanvas = 616 - padding - padding;
            var componentScale = this.Background.image.height > heightOfPaddedCanvas
                ? heightOfPaddedCanvas / this.Background.image.height : 1;

            this.Component.scaleX = componentScale;
            this.Component.scaleY = componentScale;

            this.Component.x = 600 - componentScale * this.Background.image.width - 2 * padding;
            this.Component.y = -10;
            // End of component initialization

            this.Container.scaleX = this.Scale;
            this.Container.scaleY = this.Scale;
            //stage.addChild(this.Container);

            //stage.enableMouseOver(this.FrameRate);
            //stage.update();



            this.Container.visible = true;
            this.IsVisible = true;
        };


        Hide() {
            if (this.Interval)
                clearInterval(this.Interval);

            this.ChangeCuffPressure(0);
            this.ReadyToMeasure = false;
            this.Container.visible = false;
            this.IsVisible = false;
        };

        ChangeCuffPressure(cuffPressure) {
            this.CuffPressure = cuffPressure < 0 ? 0 : cuffPressure;

            var index = Math.floor(this.CuffPressure / 20);

            if (index >= 0 && index < 11)
                this.Cuff.gotoAndStop(index);

            if (this.CuffPressure >= 200 && !this.Interval)
                this.ReadyToMeasure = this.CuffPressure >= 200;

            var h = this.CuffPressure * 410 / 200; // 410 is the height of the bar at a 200 cuff pressure

            this.PressureIndicator.graphics.clear();

            if (this.CuffPressure > 0)
                this.PressureIndicator.graphics.beginFill('#000').rect(0, -h, 12, h);
        };

        Pump_MouseOver(e) {
            this.Pump.gotoAndStop(1);
            e.preventDefault();
        };

        Pump_MouseOut(e) {
            this.Pump.gotoAndStop(0);
            e.preventDefault();
        };

        Pump_MouseDown(e) {
            this.Pump.gotoAndStop(2);
            var that = this;
            // console.log('mouseDown');
            e.preventDefault();

      //      this.Pump.addEventListener('mouseup', function () { return that.Pump_MouseUp(); });
        };

        Pump_MouseUp(e) {
           this.Pump.gotoAndStop(0);
            // console.log('mouseUp');
            e.preventDefault();

           if (this.CuffPressure >= 220)
                return;

            this.ShowSystolic = true;
            this.ShowDiastolic = true;
            this.ChangeCuffPressure(this.CuffPressure + 20);
        };

        Valve_Click = function (e) {
            if (!this.ReadyToMeasure)
                return;

            this.ReadyToMeasure = false;
            this.Valve.gotoAndStop(0);
            this.ShowSystolic = true;
            this.ShowDiastolic = true;

            var that = this;
            this.Interval = setInterval(function (e) { return that.Pressure_Tick(e); }, 60);
        };

        Valve_MouseOver = function (e) {
            if (this.ReadyToMeasure)
                this.Valve.gotoAndStop(1);
        };

        Valve_MouseOut = function (e) {
            this.Valve.gotoAndStop(0);
        };

        Pressure_Tick = function (e) {
            var audio;
            // Slow down while between the diastolic and systolic meassurements
            // console.log("diastolic and systolic", this.ShowDiastolic, this.ShowSystolic);
            var pressure = this.ShowDiastolic && !this.ShowSystolic
                ? this.CuffPressure - 1
                : this.CuffPressure - 1.6;

            if (pressure <= 0) {
                pressure = 0;
                clearInterval(this.Interval);
                this.Interval = null;
            }

            if (this.ShowSystolic) {
                if (pressure <= this.SystolicPressure) {
                    this.ShowSystolic = false;
                    pressure += 10;
                    var audio = createjs.Sound.play("bloodPressure.marker", {loop:-1});
                  //  this.SoundInstances['marker'].play({loop:-1});
                }
                  else if(pressure >=this.SystolicPressure){
      //              this.SoundInstances['marker'].stop();
      createjs.Sound.stop("bloodPressure.marker");
                }
            }
            else if (this.ShowDiastolic) {
                if (pressure <= this.DiastolicPressure) {
                    this.ShowDiastolic = false;
                    pressure += 10;
          //          this.SoundInstances['marker'].stop();
          createjs.Sound.stop("bloodPressure.marker");
                }

            }

            this.ChangeCuffPressure(pressure);
        };

        reset(){
            this.SystolicPressure = 120;
            this.DiastolicPressure = 60;
            this.patientimg = undefined;
            this.patient = undefined
            this.Blocker = new createjs.Shape();
            this.Stethoscope = undefined;
            this.Pump = undefined;
            this.Valve = undefined;
            this.Cuff = undefined;
            
            this.Background = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/Background.png');
            this.Stethoscope = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/Stethoscope.png');
            this.PressureIndicator = new createjs.Shape();
            this.GraduationMarks = new createjs.Bitmap('./assets/default/physical/Props/BloodPressure/GraduationMarks.png');
            // ----------------------------------
            this.Component.removeAllChildren();
            this.Container.removeAllChildren();
        
        };
    }
