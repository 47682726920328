import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from "../_services";

interface FormModel {
  email?: string;
  captcha?: string;
}

@Component({
  selector: 'app-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss']
})
export class ResendActivationComponent implements OnInit {
  error = false;
  formModel: FormModel = {};

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
  }

  submitRequest() {
    this.error = false
    this.authService.resendActivationEmail(this.formModel.email).subscribe(() => {
      this.router.navigate(['/login']);
    }, () => {
      this.error = true;
    });
  }
}
